<script setup lang="ts">
import { T } from "@/classes/i18n"
import products from "@/classes/objectTypes/index"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import { useStore } from "@/store/vuex.store"
import utf8 from "utf8"
import { computed, onMounted, ref, watch } from "vue"
import loader from "../components/loader.vue"
const timeFormat = timeHelpers.getDateFormatI18n(true)

// Props
const props = defineProps<{
    properties: {
        certificateId: string
    }
}>()

// Const Values
const RDNAttributes: any = {
    DC: "Domain",
    CN: "Common Name",
    OU: "Unit",
    O: "Organization",
    STREET: "Address",
    L: "Region",
    ST: "State",
    C: "Country",
    UID: "User ID",
    EMAILADDRESS: "Email"
}

// Computed Values
const activeAccountId = computed(() => {
    return useStore()?.state.session.activeAccountId || ""
})
const activeTenantDomain = computed(() => {
    return tenantHelpers.getTenantDomain(activeAccountId.value)
})

// Ref Values
const certificate = ref()
const certInfo = ref("")

// Lifecycle Hooks
onMounted(async () => {
    let thisCert = await products.mobileSecurity.certificates.queries.getObjectFromApi(
        tenantHelpers.getAccountId(activeTenantDomain.value),
        props.properties.certificateId
    )
    thisCert = thisCert
    certificate.value = thisCert
})

// Watches
watch(certificate, () => {
    certInfo.value = ""
    if (certificate.value?.certificateId) {
        let thisCertinfo: string = ""
        certificate.value.dnComponents?.forEach(function (component: any) {
            var idx = component.indexOf("=")
            var name = component.substr(0, idx)
            var value = component.substr(idx + 1)

            thisCertinfo += `
                    <div class= "row form-group border-bottom padding-xs-y">
                        <div class="col-lg-6" >
                            <label class="control-label" for="edit-certificate-${name}" >
                                ${T(RDNAttributes[name] || name)}
                            </label>
                        </div>
                        <div class="col-lg-18" >
                            <input id="edit-certificate-${name}" name="${name}" type="text" class="form-control" disabled value="${utf8.decode(value)}" >
                        </div>
                    </div>`
        })
        certInfo.value = thisCertinfo
    }
})
</script>
<template>
    <div class="padding-xs-2">
        <template v-if="!certificate">
            <div>
                <div class="text-size-3 text-center padding-xs-t-4">
                    <loader class="color-red" />
                </div>
                <div class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity: 0.8">
                    <span>
                        {{ T("Loading....") }}
                    </span>
                </div>
            </div>
        </template>
        <template v-else>
            <form
                class="form-horizontal"
                enctype="multipart/form-data"
                action="/sms-mgt-api/api/1.1/tenants/${certificate.tenantDomain}/certificates"
                method="POST"
            >
                <div class="row form-group border-bottom padding-xs-b">
                    <div class="col-lg-6">
                        <label class="control-label" for="edit-certificate-name">
                            {{ T("Name") }}
                        </label>
                    </div>
                    <div class="col-lg-18">
                        <input
                            id="edit-certificate-name"
                            name="name"
                            type="text"
                            class="form-control"
                            disabled
                            :value="certificate.name"
                        />
                    </div>
                </div>
                <div class="row form-group border-bottom padding-xs-y">
                    <div class="col-lg-6">
                        <label class="control-label" for="edit-certificate-type">
                            {{ T("Type") }}
                        </label>
                    </div>
                    <div class="col-lg-18">
                        <input
                            id="edit-certificate-type"
                            name="type"
                            type="text"
                            class="form-control"
                            disabled
                            :value="certificate.type"
                        />
                    </div>
                </div>
                <div class="row form-group border-bottom padding-xs-y">
                    <div class="col-lg-6">
                        <label class="control-label" for="edit-certificate-cn">
                            {{ T("CN") }}
                        </label>
                    </div>
                    <div class="col-lg-18">
                        <input
                            id="edit-certificate-cn"
                            name="cn"
                            type="text"
                            class="form-control"
                            disabled
                            :value="certificate.cn"
                        />
                    </div>
                </div>
                <div class="row form-group border-bottom padding-xs-y">
                    <div class="col-lg-6">
                        <label class="control-label" for="edit-certificate-not-before">
                            {{ T("Not Before") }}
                        </label>
                    </div>
                    <div class="col-lg-18">
                        <input
                            id="edit-certificate-not-before"
                            name="not-before"
                            type="text"
                            class="form-control"
                            disabled
                            :value="
                                timeHelpers.formatDate(certificate.notBefore * 1000, timeFormat)
                            "
                        />
                    </div>
                </div>
                <div class="row form-group border-bottom padding-xs-y">
                    <div class="col-lg-6">
                        <label class="control-label" for="edit-certificate-not-after">
                            {{ T("Not After") }}
                        </label>
                    </div>
                    <div class="col-lg-18">
                        <input
                            id="edit-certificate-not-after"
                            name="not-after"
                            type="text"
                            class="form-control"
                            disabled
                            :value="timeHelpers.formatDate(certificate.notAfter * 1000, timeFormat)"
                        />
                    </div>
                </div>
                <div v-html="certInfo"></div>
            </form>
        </template>
    </div>
</template>

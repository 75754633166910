import config from "@/classes/config"
import { T } from "@/classes/i18n"
import type { ZeroTouchDevice } from "@/classes/mobileSecurity/zeroTouchDevices"
import products from "@/classes/objectTypes"
import type { EnterpriseDevicePlusZeroTouch } from "@/classes/objectTypes/mobileSecurity/android/androidDevices"
import dialogs from "@/dialogs/dialogs"
import encodingHelpers from "@/helpers/helpers.encoding"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store"
import moment from "moment"
import apis from "../classes/apis"
import jsonHelpers from "../helpers/helpers.json"
import queries from "./queries"
import requestHandler from "./requests"

const mobileSecurityQueries = {
    downloadCsr: async function (accountid: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            await queries.misc.download(
                apis.getTenantApiUrl(tenantDomain, "appleAPNs") + "/apns/csr",
                "securepoint-mobile-security-apns.csr"
            )
            await timeHelpers.sleep(300)
            result = true
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = e
        }
        return apis.parseApiResponse(result)
    },

    assignDepProfile: async function (
        accountid: string,
        profile_uuid: string,
        deviceSerials: string[]
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            let payload: any = {
                profile_uuid: profile_uuid,
                devices: deviceSerials
            }
            result = await requestHandler.request(
                "POST",
                apis.getTenantApiUrl(tenantDomain, "appleDEP") + "/dep/profile/devices",
                payload
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = e
        }
        return apis.parseApiResponse(result)
    },

    unassignDepProfile: async function (
        accountid: string,
        profile_uuid: string,
        deviceSerials: string[]
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            let payload: any = {
                profile_uuid: profile_uuid,
                devices: deviceSerials
            }
            result = await requestHandler.request(
                "DELETE",
                apis.getTenantApiUrl(tenantDomain, "appleDEP") + "/dep/profile/devices",
                payload
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = e
        }
        return apis.parseApiResponse(result)
    },

    setDefaultDeviceLicenseUUID: async function (
        accountId: string,
        uuid: string,
        withModal = true
    ) {
        let result = false
        try {
            const apiResult = await queries.unifiedSecurity.setTenantProperty(
                accountId,
                "defaultDeviceLicenseUUID",
                uuid
            )
            if (apiResult == false) {
                result = false
                if (withModal == true) {
                    dialogs.misc.errorDialog(
                        accountId,
                        T("An error has occurred"),
                        T("Beim Speichern der Default Lizenz ist ein Fehler aufgetreten.")
                    )
                }
            } else {
                useStore().state.session.accounts[
                    accountId || ""
                ].mobileSecurity.settings.defaultDeviceLicenseUUID = uuid
                result = true
            }
        } catch (e: unknown) {
            console.error(e)
        }
        return result
    },

    downloadApplePushCert: async function (accountid: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            await queries.misc.download(
                apis.getTenantApiUrl(tenantDomain, "appleAPNs") + "/apns/cert",
                "securepoint-mobile-security-apple-push-certificate-" + tenantDomain + ".pem"
            )
            await timeHelpers.sleep(300)
            result = true
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = e
        }
        return apis.parseApiResponse(result)
    },

    getDepToken: async function (accountid: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            if (apis.getApiVersionForFeature("appleDEPToken") == "1.1") {
                result = await requestHandler.request(
                    "GET",
                    apis.getTenantApiUrl(tenantDomain, "appleDEPToken") + "/dep/token"
                )
            } else {
                result = await requestHandler.request(
                    "GET",
                    apis.getTenantApiUrl(tenantDomain, "appleDEPToken") + "/dep"
                )
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                mobileSecurityQueries.dep.doDepErrorCheck(e, "getDepToken")
                console.error(e)
            }
            result = false
        }
        return apis.parseApiResponse(result)
    },

    getEnterpriseInfo: async function (accountid: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "GET",
                apis.getTenantApiUrl(tenantDomain, "androidEnterprise") + "/android/emm/enterprise"
            )
            if (result.code == 403) {
                throw new Error(result.message)
            }
        } catch (e: any) {
            dialogs.mobileSecurity.enterpriseErrorDialog(accountid, e)
            useStore()?.commit(MutationTypes.setTenants, {
                [accountid]: {
                    mobileSecurity: {
                        settings: {
                            android: {
                                enterprise: {
                                    enabled: false,
                                    disabledForThisSession: true
                                }
                            }
                        }
                    }
                }
            })
            console.error(e)

            result = false
        }
        return apis.parseApiResponse(result)
    },

    getEnterpriseWebtoken: async function (accountid: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            result = await requestHandler.request(
                "POST",
                apis.getTenantApiUrl(accountid, "androidEnterprise") +
                    "/android/emm/enterprise/webTokens"
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return apis.parseApiResponse(result)
    },

    getEnterpriseAppInfo: async function (accountid: string, packageName: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)

            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 400 }
            }
            result = await requestHandler.request(
                "GET",
                apis.getTenantApiUrl(accountid, "androidEnterprise") +
                    "/android/emm/enterprise/applications/" +
                    packageName
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return apis.parseApiResponse(result)
    },

    getZeroTouchInfo: async function (accountid: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "GET",
                apis.getTenantApiUrl(tenantDomain, "zeroTouch") +
                    "/android/androiddeviceprovisioning"
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = {
                enabled: false
            }
        }
        return apis.parseApiResponse(result)
    },

    deleteZeroTouchDeviceConfiguration: async function (
        accountid: string,
        customerId: string,
        deviceId: string
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "DELETE",
                apis.getTenantApiUrl(tenantDomain, "zeroTouch") +
                    "/android/androiddeviceprovisioning/customers/" +
                    customerId +
                    "/devices/" +
                    deviceId +
                    "/configuration"
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return apis.parseApiResponse(result)
    },
    UpdateZeroTouchDeviceConfiguration: async function (
        accountid: string,
        customerId: string,
        deviceId: string,
        configurationId: string
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)

            result = await requestHandler.request(
                "PUT",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantDomain +
                    "/android/androiddeviceprovisioning/customers/" +
                    customerId +
                    "/devices/" +
                    deviceId +
                    "/configuration/" +
                    configurationId
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return apis.parseApiResponse(result)
    },

    getObjectCount: async function (accountid: string, objectType: string, customerId?: string) {
        let result: any = null
        let countSelectStr = ""
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)

            switch (objectType) {
                case "iosDevices":
                    countSelectStr =
                        apis.getApiVersionForFeature("apple") == "1.1"
                            ? "?props[]=deviceId&select=devices|length(@)"
                            : "?props[]=deviceId&select=data.devices|length(@)"
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "apple") + "/devices" + countSelectStr
                    )
                    break
                case "iosProfiles":
                    countSelectStr =
                        apis.getApiVersionForFeature("apple") == "1.1"
                            ? "?select=count"
                            : "?select=data.count"

                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "apple") + "/profiles" + countSelectStr
                    )
                    break
                case "androidProfiles":
                case "enterpriseProfiles":
                    countSelectStr =
                        apis.getApiVersionForFeature("androidEnterprisePolicies") == "1.1"
                            ? "&select=count"
                            : "&select=data.count"
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "androidEnterprisePolicies") +
                            "/android/emm/enterprise/policies?fields=policies.name&props[]=null" +
                            countSelectStr
                    )
                    break
                case "apps":
                    countSelectStr =
                        apis.getApiVersionForFeature("apple") == "1.1"
                            ? "?props[]=null&select=count"
                            : "?props[]=null&select=data.count"

                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "apple") + "/apps" + countSelectStr
                    )
                    break
                case "vpns":
                    result = await requestHandler.request(
                        "GET",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/vpns?props[]=null&select=count"
                    )
                    break
                case "certificates":
                    result = await requestHandler.request(
                        "GET",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/certificates?props[]=null&select=count"
                    )
                    break
                case "depProfiles":
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "appleDEP") + "/dep/profile"
                    )
                    if (apis.parseApiResponse(result)) {
                        result = apis.parseApiResponse(result).length
                    }
                    break
                case "vppassets":
                    result = await mobileSecurityQueries.vpp.getVPPAssetsSrv(tenantDomain)
                    if (result) {
                        result = result.totalCount
                    }
                    break
                case "zeroTouchConfigurations":
                    result = 0

                    let zeroTouchCustomers: any = config.canUseNewObjectType("zeroTouchCustomers")
                        ? products.mobileSecurity.zeroTouchCustomers
                              .useStore?.()
                              .getObjectStoreObjects(accountid)
                        : useStore()?.getters.getObjects({
                              accountId: accountid,
                              objectType: "zeroTouchCustomers",
                              productType: "mobileSecurity"
                          })

                    let getNextConfigsBatch: Function = async function (
                        nextPageToken: string,
                        companyId: string
                    ) {
                        if (companyId) {
                            let currentResult: any = await requestHandler.request(
                                "GET",
                                apis.getTenantApiUrl(tenantDomain, "zeroTouch") +
                                    "/android/androiddeviceprovisioning/customers/" +
                                    companyId +
                                    "/configurations?pageSize=75" +
                                    (nextPageToken ? "&nextPageToken=" + nextPageToken : "")
                            )
                            currentResult = apis.parseApiResponse(currentResult)
                            if (currentResult.configurations.length) {
                                result = result + currentResult.configurations.length
                            }
                            if (currentResult.nextPageToken != null) {
                                await getNextConfigsBatch(currentResult.nextPageToken)
                            }
                        }
                    }

                    for (let i: number = 0; (zeroTouchCustomers?.length || 0) > i; i++) {
                        await getNextConfigsBatch("", zeroTouchCustomers[i].companyId)
                    }

                    break
                case "zeroTouchDevices":
                    result = 0
                    let getNextDevicesBatch: Function = async function (nextPageToken: string) {
                        if (customerId) {
                            let currentResult: any = await requestHandler.request(
                                "GET",
                                apis.getTenantApiUrl(tenantDomain, "zeroTouch") +
                                    "/android/androiddeviceprovisioning/customers/" +
                                    customerId +
                                    "/devices?pageSize=75" +
                                    (nextPageToken ? "&nextPageToken=" + nextPageToken : "")
                            )
                            currentResult = apis.parseApiResponse(currentResult)
                            if (currentResult.devices.length) {
                                result = result + currentResult.devices.length
                            }
                            if (currentResult.nextPageToken != null) {
                                await getNextDevicesBatch(currentResult.nextPageToken)
                            }
                        }
                    }
                    if (customerId) {
                        await getNextDevicesBatch("")
                    } else {
                        let zeroTouchCustomers: any = config.canUseNewObjectType(
                            "zeroTouchCustomers"
                        )
                            ? products.mobileSecurity.zeroTouchCustomers
                                  .useStore?.()
                                  .getObjectStoreObjects(accountid)
                            : useStore()?.getters.getObjects({
                                  accountId: accountid,
                                  objectType: "zeroTouchCustomers",
                                  productType: "mobileSecurity"
                              })
                        for (let i: number = 0; (zeroTouchCustomers?.length || 0) > i; i++) {
                            customerId = zeroTouchCustomers[i].companyId
                            await getNextDevicesBatch("")
                        }
                    }
                    break
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return apis.parseApiResponse(result)
    },

    getObjectInfos: async function (
        accountid: string,
        objectType: string,
        props?: any[],
        force: boolean = false,
        customerId?: string
    ) {
        let result: any = null
        let result2: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            let timeStamp: number = moment().unix()

            let propertiesString: string = ""

            if (force === true) {
                propertiesString += "?_=" + timeStamp
            }

            ;(props || []).forEach(function (prop: any) {
                if (propertiesString.length == 0) {
                    propertiesString += "?" + prop.property + "=" + prop.value
                } else {
                    propertiesString += "&" + prop.property + "=" + prop.value
                }
            })

            switch (objectType) {
                case "devices":
                case "iosDevices":
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "apple") + "/devices" + propertiesString
                    )
                    break
                case "historyDevices":
                    result = await requestHandler.request(
                        "GET",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersionNext +
                            "/tenants/" +
                            tenantDomain +
                            "/apple/history/devices"
                    )
                    if (tenantHelpers.hasFunctionality(accountid, "enterprise")) {
                        let result2: any = await requestHandler.request(
                            "GET",
                            "/sms-mgt-api/api/" +
                                apis.getApiVersionForFeature("androidEnterprise") +
                                "/tenants/" +
                                tenantDomain +
                                "/android/emm/enterprise/history/devices"
                        )
                        for (let i: number = 0; result2.devices.length > i; i++) {
                            result2.devices[i].enterpriseDevice = true
                            result.devices.push(result2.devices[i])
                        }
                    }
                    break
                case "depDevices":
                    result = await requestHandler.request(
                        "POST",
                        apis.getTenantApiUrl(tenantDomain, "appleDEP") +
                            "/dep/server/devices" +
                            propertiesString
                    )
                    break
                case "profiles":
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "apple") + "/profiles" + propertiesString
                    )
                    break
                case "androidProfiles":
                case "enterpriseProfiles":
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "androidEnterprisePolicies") +
                            "/android/emm/enterprise/policies" +
                            propertiesString
                    )
                    break
                case "depProfiles":
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "appleDEP") +
                            "/dep/profile" +
                            propertiesString
                    )
                    break
                case "apps":
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "apple") + "/apps" + propertiesString
                    )
                    break
                case "vpns":
                    result = await requestHandler.request(
                        "GET",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/vpns" +
                            propertiesString
                    )
                    break
                case "historyVpns":
                    result = await requestHandler.request(
                        "GET",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersionNext +
                            "/tenants/" +
                            tenantDomain +
                            "/history/vpns"
                    )
                    break
                case "certificates":
                    result = await requestHandler.request(
                        "GET",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/certificates" +
                            propertiesString
                    )
                    break
                case "tags":
                    let results = await Promise.all([
                        requestHandler.request(
                            "GET",
                            "/sms-mgt-api/api/" +
                                config.mgtApiVersion +
                                "/tenants/" +
                                tenantDomain +
                                "/devices?props[]=tags&select=devices[?tags].tags[]"
                        ),
                        requestHandler.request(
                            "GET",
                            "/sms-mgt-api/api/" +
                                config.mgtApiVersion +
                                "/tenants/" +
                                tenantDomain +
                                "/profiles?props[]=tags&select=profiles[?tags].tags[]"
                        ),
                        requestHandler.request(
                            "GET",
                            "/sms-mgt-api/api/" +
                                config.mgtApiVersion +
                                "/tenants/" +
                                tenantDomain +
                                "/apps?props[]=tags&select=apps[?tags].tags[]"
                        )
                    ])

                    let tags: string[] = []
                    tags = results[0]?.length > 0 ? tags.concat(results[0]) : tags
                    tags = results[1]?.length > 0 ? tags.concat(results[1]) : tags
                    tags = results[2]?.length > 0 ? tags.concat(results[2]) : tags

                    // filter out duplicates
                    result = tags.filter(function (value: any, index: any, self: any) {
                        return self.indexOf(value) === index
                    })
                    break
                case "zeroTouchCustomers":
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "zeroTouch") +
                            "/android/androiddeviceprovisioning/customers" +
                            (propertiesString ? propertiesString + "&" : "")
                    )
                    let customers: any = []
                    result = apis.parseApiResponse(result)
                    ;(result.accounts || result.customers)?.forEach((accountInfo: any) => {
                        if (accountInfo.customers.length == 0) {
                            customers.push({
                                customerId: accountInfo.id
                            })
                        } else {
                            accountInfo.customers?.forEach((customerInfo: any) => {
                                customerInfo.customerId = accountInfo.id
                                customers.push(customerInfo)
                            })
                        }
                    })
                    result = customers
                    break
                case "zeroTouchConfigurations":
                    result = []
                    let getNextConfigsBatch: Function = async function (nextPageToken: string) {
                        let currentResult: any = await requestHandler.request(
                            "GET",
                            apis.getTenantApiUrl(tenantDomain, "zeroTouch") +
                                "/android/androiddeviceprovisioning/customers/" +
                                customerId +
                                "/configurations" +
                                (propertiesString ? propertiesString + "&" : "?") +
                                "pageSize=75" +
                                (nextPageToken ? "&nextPageToken=" + nextPageToken : "")
                        )
                        currentResult = apis.parseApiResponse(currentResult)
                        if (currentResult.configurations.length) {
                            if (currentResult.configurations != null) {
                                result = result.concat(currentResult.configurations)
                            }
                        }
                        if (currentResult.nextPageToken != null) {
                            await getNextConfigsBatch(currentResult.nextPageToken)
                        }
                    }

                    if (customerId) {
                        await getNextConfigsBatch("")
                    } else {
                        let zeroTouchCustomers: any = config.canUseNewObjectType(
                            "zeroTouchCustomers"
                        )
                            ? products.mobileSecurity.zeroTouchCustomers
                                  .useStore?.()
                                  .getObjectStoreObjects(accountid)
                            : useStore()?.getters.getObjects({
                                  accountId: accountid,
                                  objectType: "zeroTouchCustomers",
                                  productType: "mobileSecurity"
                              })

                        for (let i: number = 0; (zeroTouchCustomers?.length || 0) > i; i++) {
                            customerId = zeroTouchCustomers[i].companyId
                            if (customerId) {
                                await getNextConfigsBatch("")
                            }
                        }
                    }

                    break
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
                if (["depDevices", "depProfiles"].indexOf(objectType) != -1) {
                    mobileSecurityQueries.dep.doDepErrorCheck(e, "getObjectInfos:" + objectType)
                }
            }
            result = e
        }
        return apis.parseApiResponse(result)
    },

    getObjectInfo: async function (
        accountid: string,
        objectType: string,
        objectId: string,
        property?: string,
        withProperties: boolean = false,
        customerId?: string,
        props?: any[]
    ) {
        let result: any = null
        let propertiesResult: any = null
        let propertiesString: string = ""
        ;(props || []).forEach(function (prop: any) {
            if (propertiesString.length == 0) {
                propertiesString += "?" + prop.property + "=" + prop.value
            } else {
                propertiesString += "&" + prop.property + "=" + prop.value
            }
        })
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            switch (objectType) {
                case "devices":
                case "iosDevices":
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "appleSingleDevice") +
                            "/devices/" +
                            objectId +
                            propertiesString
                    )
                    if (withProperties) {
                        propertiesResult = await requestHandler.request(
                            "GET",
                            apis.getTenantApiUrl(tenantDomain, "appleSingleDevice") +
                                "/devices/" +
                                objectId +
                                "/properties" +
                                propertiesString
                        )
                        result.properties = propertiesResult
                    }
                    break
                case "DeviceProperties":
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "apple") +
                            "/devices/" +
                            objectId +
                            "/properties" +
                            (property ? "/" + property : "") +
                            propertiesString
                    )
                    break
                case "enterpriseDeviceProperties":
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "androidEnterprise") +
                            "/android/emm/enterprise/devices/" +
                            objectId +
                            "/properties" +
                            (property ? "/" + property : "") +
                            propertiesString
                    )
                    break
                case "profiles":
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "apple") +
                            "/profiles/" +
                            encodingHelpers.encodeURI(objectId) +
                            propertiesString
                    )
                    break
                case "androidProfiles":
                case "enterpriseProfiles":
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "androidEnterprisePolicies") +
                            "/android/emm/enterprise/policies/" +
                            encodingHelpers.encodeURI(objectId) +
                            propertiesString
                    )
                    break
                case "depProfiles":
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "appleDEP") +
                            "/dep/profile" +
                            (objectId
                                ? "?profile_uuid=" + encodingHelpers.encodeURI(objectId)
                                : "") +
                            propertiesString
                    )
                    break
                case "apps":
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "apple") +
                            "/apps/" +
                            objectId +
                            propertiesString
                    )
                    break
                case "vpns":
                    result = await requestHandler.request(
                        "GET",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/vpns/" +
                            objectId +
                            propertiesString
                    )
                    break

                case "certificates":
                    result = await requestHandler.request(
                        "GET",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/certificates/" +
                            objectId +
                            propertiesString
                    )
                    break
                case "vppassets":
                    result = await mobileSecurityQueries.vpp.getVPPAssetsSrv(tenantDomain)
                    break
                case "zeroTouchConfigurations":
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "zeroTouch") +
                            "/android/androiddeviceprovisioning/customers/" +
                            customerId +
                            "/configurations/" +
                            objectId +
                            propertiesString
                    )
                    result = apis.parseApiResponse(result)
                    if (result) {
                        result.customerId = result.name.split("/")[1]
                    }
                    break
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                if (["depDevices", "depProfiles"].indexOf(objectType) != -1) {
                    if (e.responseText != "NOT_FOUND")
                        mobileSecurityQueries.dep.doDepErrorCheck(e, "getObjectInfo:" + objectType)
                }
                console.error(e)
            }
            result = e
        }
        return apis.parseApiResponse(result)
    },

    addObjectInfo: async function (
        accountid: string,
        objectType: string,
        object: any,
        customerId?: string
    ) {
        let result: any = false
        let results: any = []
        let updateMaskString: string = ""
        let payload: any = ""
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw {
                    status: 401
                }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            switch (objectType) {
                case "profiles":
                    result = await requestHandler.request(
                        "POST",
                        apis.getTenantApiUrl(tenantDomain, "apple") + "/profiles/",
                        object
                    )
                    break
                case "androidProfiles":
                case "enterpriseProfiles":
                    let devices: any = []
                    if (object.devices) {
                        devices = JSON.parse(JSON.stringify(object.devices))
                    }
                    let policyName: string = object.name.trim()

                    delete object.platform
                    delete object.devices

                    result = await requestHandler.request(
                        "PUT",
                        apis.getTenantApiUrl(tenantDomain, "androidEnterprisePolicies") +
                            "/android/emm/enterprise/policies/" +
                            encodingHelpers.encodeURI(object.name.trim()),
                        object
                    )

                    for (let i: number = 0; devices.length > i; i++) {
                        try {
                            let thisDevice = useStore()?.getters.getObject({
                                accountId: accountid,
                                productType: "mobileSecurity",
                                objectType: "enterpriseDevices",
                                objectId: devices[i]
                            })
                            let fullPolicyName = thisDevice.policyName.split("/")
                            fullPolicyName =
                                fullPolicyName[0] +
                                "/" +
                                fullPolicyName[1] +
                                "/" +
                                fullPolicyName[2] +
                                "/" +
                                policyName
                            let newPolicy: any = {
                                policyName: fullPolicyName
                            }
                            await useStore()?.dispatch(ActionTypes.updateObjectProperty, {
                                accountId: accountid,
                                productType: "mobileSecurity",
                                objectType: "enterpriseDevices",
                                objectPropertyPath: [],
                                objectId: devices[i],
                                object: newPolicy,
                                property: "policyName",
                                value: fullPolicyName
                            })
                        } catch (e: any) {
                            console.error(e)
                        }
                    }
                    break
                case "depProfiles":
                    let addedDevices = object.devices.filter(function (serial: string) {
                        return serial != undefined
                    })
                    delete object.devices
                    delete object.devicesBefore
                    let thisResult: any = await requestHandler.request(
                        "POST",
                        apis.getTenantApiUrl(tenantDomain, "appleDEP") + "/dep/profile",
                        object
                    )
                    thisResult = apis.parseApiResponse(thisResult)
                    if (thisResult.profile_uuid && addedDevices.length) {
                        mobileSecurityQueries.assignDepProfile(
                            accountid,
                            thisResult.profile_uuid,
                            addedDevices
                        )
                    }
                    break
                case "apps":
                    result = await requestHandler.request(
                        "POST",
                        apis.getTenantApiUrl(tenantDomain, "apple") + "/apps/",
                        object
                    )
                    break
                case "vpns":
                    result = await requestHandler.request(
                        "POST",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/vpns/",
                        object
                    )
                    break
                case "certificates":
                    result = await requestHandler.request(
                        "POST",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/certificates/",
                        object
                    )
                    break
                case "utms":
                    result = await requestHandler.request(
                        "POST",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/laas/utms/",
                        object
                    )
                    break
                case "reports":
                    result = await requestHandler.request(
                        "POST",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/laas/reports/",
                        object
                    )
                    break
                case "zeroTouchConfigurations":
                    object = jsonHelpers.copyObject(object)

                    if (object.configurationName) {
                        object.configurationName = object.configurationName.trim()
                    }

                    let thisDevices: string[] = []
                    if (object.devices) {
                        thisDevices = object.devices
                        delete object.devices
                    }

                    result = await requestHandler.request(
                        "POST",
                        apis.getTenantApiUrl(tenantDomain, "zeroTouch") +
                            "/android/androiddeviceprovisioning/customers/" +
                            customerId +
                            "/configurations/",
                        object
                    )
                    result = apis.parseApiResponse(result)
                    if (result.configurationId && typeof customerId == "string")
                        thisDevices.forEach(function (zeroTouchDeviceId: any) {
                            mobileSecurityQueries.UpdateZeroTouchDeviceConfiguration(
                                accountid,
                                customerId,
                                zeroTouchDeviceId,
                                result.configurationId
                            )
                        })
                    break
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = e
        }
        return apis.parseApiResponse(result)
    },

    updateObjectInfo: async function (
        accountid: string,
        objectType: string,
        objectId: string,
        object: any,
        updateMask?: string[],
        customerId?: string
    ) {
        let result: any = false
        let results: any = []
        let updateMaskString: string = ""
        let payload: any = ""
        let deletedDevices = []
        let addedDevices = []
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw {
                    status: 401
                }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            switch (objectType) {
                case "devices":
                case "iosDevices":
                    if (updateMask) {
                        for (let i in updateMask) {
                            let property: string = updateMask[i]
                            payload = object[property]

                            results[i] = await requestHandler.request(
                                "POST",
                                apis.getTenantApiUrl(tenantDomain, "apple") +
                                    "/devices/" +
                                    objectId +
                                    "/" +
                                    property,
                                payload
                            )
                        }
                    } else {
                        result = await requestHandler.request(
                            "PUT",
                            apis.getTenantApiUrl(tenantDomain, "apple") +
                                "/devices/" +
                                objectId +
                                "/",
                            object
                        )
                    }
                    break
                case "deviceProperties":
                    if (updateMask) {
                        results = []
                        for (let i in updateMask) {
                            let property: string = updateMask[i]
                            payload = {}
                            payload[property] = object[property]

                            if (property == "username" && object[property] == "") {
                                results[i] = await requestHandler.request(
                                    "DELETE",
                                    apis.getTenantApiUrl(tenantDomain, "apple") +
                                        "/devices/" +
                                        objectId +
                                        "/properties/" +
                                        property
                                )
                            } else {
                                results[i] = await requestHandler.request(
                                    "PUT",
                                    apis.getTenantApiUrl(tenantDomain, "apple") +
                                        "/devices/" +
                                        objectId +
                                        "/properties/" +
                                        property,
                                    payload
                                )
                            }
                        }
                    } else {
                        result = await requestHandler.request(
                            "POST",
                            apis.getTenantApiUrl(tenantDomain, "apple") +
                                "/devices/" +
                                objectId +
                                "/properties/",
                            object
                        )
                    }
                    break
                case "profiles":
                    result = await requestHandler.request(
                        "PUT",
                        apis.getTenantApiUrl(tenantDomain, "apple") + "/profiles/" + objectId,
                        object
                    )
                    break
                case "androidProfiles":
                case "enterpriseProfiles":
                    let devices: any = []
                    if (object.devices) {
                        devices = JSON.parse(JSON.stringify(object.devices))
                    }
                    let policyName: string = object.name

                    delete object.platform
                    delete object.devices

                    result = await requestHandler.request(
                        "PUT",
                        apis.getTenantApiUrl(tenantDomain, "androidEnterprisePolicies") +
                            "/android/emm/enterprise/policies/" +
                            encodingHelpers.encodeURI(object.name),
                        object
                    )

                    for (let i: number = 0; devices.length > i; i++) {
                        let thisDevice = useStore()?.getters.getObject({
                            accountId: accountid,
                            productType: "mobileSecurity",
                            objectType: "enterpriseDevices",
                            objectId: devices[i]
                        })
                        try {
                            if (thisDevice?.policyName?.split("/")[3] != policyName) {
                                //await mobileSecurityQueries.updateObjectInfo(accountid,'enterpriseDevice',devices[i],newPolicy,['policyName'])
                                let fullPolicyName = thisDevice.policyName.split("/")
                                fullPolicyName =
                                    fullPolicyName[0] +
                                    "/" +
                                    fullPolicyName[1] +
                                    "/" +
                                    fullPolicyName[2] +
                                    "/" +
                                    policyName
                                let newPolicy: any = {
                                    policyName: fullPolicyName
                                }
                                await useStore()?.dispatch(ActionTypes.updateObjectProperty, {
                                    accountId: accountid,
                                    productType: "mobileSecurity",
                                    objectType: "enterpriseDevices",
                                    objectPropertyPath: [],
                                    objectId: devices[i],
                                    object: newPolicy,
                                    property: "policyName",
                                    value: fullPolicyName
                                })
                            }
                        } catch (e: any) {
                            console.error(e)
                        }
                    }
                    break
                case "depProfiles":
                    let devicesBeforeEdit = object.devicesBefore || []
                    let devicesAfterEdit = object.devices || []
                    deletedDevices = devicesBeforeEdit.filter(function (serial: string) {
                        return devicesAfterEdit.indexOf(serial) == -1
                    })
                    addedDevices = devicesAfterEdit.filter(function (serial: string) {
                        return devicesBeforeEdit.indexOf(serial) == -1
                    })

                    if (objectId) {
                        if (addedDevices.length) {
                            mobileSecurityQueries.assignDepProfile(
                                accountid,
                                objectId,
                                addedDevices
                            )
                        }
                        if (deletedDevices.length) {
                            //mobileSecurityQueries.unassignDepProfile(accountid,objectId,deletedDevices)
                        }
                    }

                    break
                case "apps":
                    result = await requestHandler.request(
                        "PUT",
                        apis.getTenantApiUrl(tenantDomain, "apple") + "/apps/" + objectId,
                        object
                    )
                    break
                case "vpns":
                    result = await requestHandler.request(
                        "PUT",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/vpns/" +
                            objectId,
                        object
                    )
                    break
                case "vpnProperties":
                    if (updateMask) {
                        results = []
                        for (let i in updateMask) {
                            let property: string = updateMask[i]
                            payload = {}
                            payload[property] = object[property]

                            if (property == "username" && object[property] == "") {
                                results[i] = await requestHandler.request(
                                    "DELETE",
                                    "/sms-mgt-api/api/" +
                                        config.mgtApiVersion +
                                        "/tenants/" +
                                        tenantDomain +
                                        "/vpns/" +
                                        objectId +
                                        "/properties/" +
                                        property
                                )
                            } else {
                                results[i] = await requestHandler.request(
                                    "PUT",
                                    "/sms-mgt-api/api/" +
                                        config.mgtApiVersion +
                                        "/tenants/" +
                                        tenantDomain +
                                        "/vpns/" +
                                        objectId +
                                        "/properties/" +
                                        property,
                                    payload
                                )
                            }
                        }
                    } else {
                        result = await requestHandler.request(
                            "POST",
                            "/sms-mgt-api/api/" +
                                config.mgtApiVersion +
                                "/tenants/" +
                                tenantDomain +
                                "/vpns/" +
                                objectId +
                                "/properties/",
                            object
                        )
                    }
                    break
                case "certificates":
                    result = await requestHandler.request(
                        "PUT",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/certificates/" +
                            objectId,
                        object
                    )
                    break
                case "utms":
                    result = await requestHandler.request(
                        "PUT",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/laas/utms/" +
                            objectId,
                        object
                    )
                    break
                case "reports":
                    result = await requestHandler.request(
                        "PUT",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/laas/reports/" +
                            objectId,
                        object
                    )
                    break
                case "zeroTouchConfigurations":
                    object = jsonHelpers.copyObject(object)

                    if (object.enrollmentTokenId == "") {
                        delete object.enrollmentTokenId
                    }

                    let newDevicesList: string[] = object.devices
                        ? jsonHelpers.copyObject(object.devices)
                        : []
                    delete object.devices

                    let oldDevicesList: string[] = (
                        products.mobileSecurity.androidDevices
                            .useStore?.()
                            .getObjectStoreObjects(tenantHelpers.getAccountId(accountid)) || []
                    )
                        .filter((device) => {
                            return (
                                (<EnterpriseDevicePlusZeroTouch>(
                                    device
                                )).zeroTouchInfo?.configuration?.split("/")[3] == objectId ||
                                (<ZeroTouchDevice>device).configuration?.split("/")[3] == objectId
                            )
                        })
                        .map((device) => {
                            return (<EnterpriseDevicePlusZeroTouch>device).zeroTouchInfo
                                ? (<EnterpriseDevicePlusZeroTouch>(
                                      device
                                  )).zeroTouchInfo?.name?.split("/")[3]
                                : (<ZeroTouchDevice>device).name
                        })

                    deletedDevices =
                        (oldDevicesList || [])?.filter(function (ztDeviceId: string) {
                            return newDevicesList.indexOf(ztDeviceId) == -1
                        }) || []

                    addedDevices =
                        (newDevicesList || [])?.filter(function (ztDeviceId: string) {
                            return oldDevicesList.indexOf(ztDeviceId) == -1
                        }) || []

                    if (updateMask) {
                        for (let i: number = 0; updateMask.length > i; i++) {
                            if (i == 0) {
                                updateMaskString += "?updateMask[]="
                            } else {
                                updateMaskString += "&updateMask[]="
                            }
                            updateMaskString += updateMask[i]
                        }
                    } else {
                        let i: number = 0
                        for (let key in object) {
                            if (i == 0) {
                                updateMaskString += "?updateMask[]="
                            } else {
                                updateMaskString += "&updateMask[]="
                            }
                            updateMaskString += key
                            i++
                        }
                    }
                    result = await requestHandler.request(
                        "PUT",
                        apis.getTenantApiUrl(tenantDomain, "zeroTouch") +
                            "/android/androiddeviceprovisioning/customers/" +
                            customerId +
                            "/configurations/" +
                            objectId +
                            updateMaskString,
                        object
                    )
                    if (typeof customerId == "string") {
                        addedDevices.forEach(function (zeroTouchDeviceId: any) {
                            mobileSecurityQueries.UpdateZeroTouchDeviceConfiguration(
                                accountid,
                                customerId,
                                zeroTouchDeviceId,
                                objectId
                            )
                        })
                        deletedDevices.forEach(function (zeroTouchDeviceId: any) {
                            mobileSecurityQueries.deleteZeroTouchDeviceConfiguration(
                                accountid,
                                customerId,
                                zeroTouchDeviceId
                            )
                        })
                    }
                    break
            }
            result = true
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = e
        }
        return apis.parseApiResponse(result)
    },

    deleteObject: async function (
        accountid: string,
        objectType: string,
        objectId: string,
        property?: string,
        customerId?: string
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            switch (objectType) {
                case "devices":
                case "iosDevices":
                    result = await requestHandler.request(
                        "DELETE",
                        apis.getTenantApiUrl(tenantDomain, "apple") + "/devices/" + objectId
                    )
                    break
                case "deviceProperties":
                    result = await requestHandler.request(
                        "DELETE",
                        apis.getTenantApiUrl(tenantDomain, "apple") +
                            "/devices/" +
                            objectId +
                            "/properties/" +
                            property
                    )
                    break
                case "enterpriseDeviceProperties":
                    result = await requestHandler.request(
                        "DELETE",
                        apis.getTenantApiUrl(tenantDomain, "androidEnterprise") +
                            "/android/emm/enterprise/devices/" +
                            encodingHelpers.encodeURI(objectId) +
                            "/properties/" +
                            property
                    )
                    break
                case "profiles":
                    result = await requestHandler.request(
                        "DELETE",
                        apis.getTenantApiUrl(tenantDomain, "apple") + "/profiles/" + objectId
                    )
                    break
                case "androidProfiles":
                case "enterpriseProfiles":
                    result = await requestHandler.request(
                        "DELETE",
                        apis.getTenantApiUrl(tenantDomain, "androidEnterprisePolicies") +
                            "/android/emm/enterprise/policies/" +
                            encodingHelpers.encodeURI(objectId)
                    )
                    break
                case "depProfiles":
                    result = await requestHandler.request(
                        "DELETE",
                        apis.getTenantApiUrl(tenantDomain, "appleDEP") +
                            "/dep/profile?profile_uuid=" +
                            objectId
                    )
                    break
                case "apps":
                    result = await requestHandler.request(
                        "DELETE",
                        apis.getTenantApiUrl(tenantDomain, "apple") + "/apps/" + objectId
                    )
                    break
                case "vpns":
                    result = await requestHandler.request(
                        "DELETE",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/vpns/" +
                            objectId
                    )
                    break
                case "certificates":
                    result = await requestHandler.request(
                        "DELETE",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/certificates/" +
                            objectId
                    )
                    break
                case "licenses":
                    result = await requestHandler.request(
                        "DELETE",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/licenses/" +
                            objectId
                    )
                    break
                case "zeroTouchCustomers":
                    result = await requestHandler.request(
                        "DELETE",
                        apis.getTenantApiUrl(tenantDomain, "zeroTouch") +
                            "/android/androiddeviceprovisioning/" +
                            objectId
                    )
                    break
                case "zeroTouchConfigurations":
                    result = await requestHandler.request(
                        "DELETE",
                        apis.getTenantApiUrl(tenantDomain, "zeroTouch") +
                            "/android/androiddeviceprovisioning/customers/" +
                            customerId +
                            "/configurations/" +
                            objectId
                    )
                    break
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return apis.parseApiResponse(result)
    },

    downloadMobileConfig: async function (accountid: string, objectId: string) {
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            queries.misc.download(
                apis.getTenantApiUrl(tenantDomain, "apple") +
                    "/devices/" +
                    objectId +
                    "/" +
                    objectId +
                    ".mobileconfig",
                objectId + ".mobileconfig",
                undefined
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
        }
    },

    getProfilesHint: function (accountid: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = requestHandler.request(
                "GET",
                apis.getTenantApiUrl(tenantDomain, "apple") + "/profiles/hint"
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return apis.parseApiResponse(result)
    },

    publishProfiles: function (accountid: string): JQueryPromise<any> {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = requestHandler.request(
                "PUT",
                apis.getTenantApiUrl(tenantDomain, "apple") + "/profiles/"
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return apis.parseApiResponse(result)
    },

    publishProfile: function (accountid: string, profileId: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = requestHandler.request(
                "POST",
                apis.getTenantApiUrl(tenantDomain, "apple") +
                    "/profiles/" +
                    encodingHelpers.encodeURI(profileId) +
                    "/publish"
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return apis.parseApiResponse(result)
    },
    revokeProfile: function (accountid: string, profileId: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = requestHandler.request(
                "POST",
                apis.getTenantApiUrl(tenantDomain, "apple") +
                    "/profiles/" +
                    encodingHelpers.encodeURI(profileId) +
                    "/revoke"
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return apis.parseApiResponse(result)
    },

    downloadCA: async function (
        accountid: string,
        objectId: string,
        suffix: "pem" | "crt" = "pem"
    ) {
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            queries.misc.download(
                "/sms-mgt-api/api/" +
                    config.mgtApiVersion +
                    "/tenants/" +
                    tenantDomain +
                    "/vpns/" +
                    objectId +
                    "/mobile_security_ca." +
                    suffix,
                "mobile_security_ca." + suffix
            )
        } catch (e: any) {
            console.error(e)
        }
    },

    downloadVPNConfig: async function (accountid: string, objectId: string, fileName: string) {
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            let cleanVPNName = decodeURI(fileName).replace(/[^a-zA-Z0-9-_]/g, "_")
            queries.misc.download(
                "/sms-mgt-api/api/" +
                    config.mgtApiVersion +
                    "/tenants/" +
                    tenantDomain +
                    "/vpns/" +
                    objectId +
                    "/" +
                    cleanVPNName +
                    ".tblk.zip",
                cleanVPNName + ".tblk.zip"
            )
        } catch (e: any) {
            console.error(e)
        }
    },

    setVPNProperty: async function (
        tenantDomain: string,
        vpnId: string,
        property: string,
        value: string
    ) {
        let data: any = {}
        data[property] = value
        return requestHandler.request(
            "PUT",
            "/sms-mgt-api/api/1.1/tenants/" +
                tenantDomain +
                "/vpns/" +
                vpnId +
                "/properties/" +
                property,
            data
        )
    },

    installApp: async function (accountid: string, objectId: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "POST",
                apis.getTenantApiUrl(tenantDomain, "apple") + "/apps/" + objectId + "/install"
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },

    uninstallApp: async function (accountid: string, objectId: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "POST",
                apis.getTenantApiUrl(tenantDomain, "apple") + "/apps/" + objectId + "/uninstall"
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return result
    },

    unlinkEnterprise: async function (accountid: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            await requestHandler.request(
                "DELETE",
                apis.getTenantApiUrl(tenantDomain, "androidEnterprise") + "/android/emm/enterprise"
            )

            result = true
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },

    deleteDEPToken: async function (accountid: string) {
        let result: boolean = true
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            if (apis.getApiVersionForFeature("appleDEPToken") == "1.1") {
                await requestHandler.request(
                    "DELETE",
                    apis.getTenantApiUrl(tenantDomain, "appleDEPToken") + "/dep/token"
                )
            } else {
                await requestHandler.request(
                    "DELETE",
                    apis.getTenantApiUrl(tenantDomain, "appleDEPToken") + "/dep"
                )
            }
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return result
    },
    deleteVPPToken: async function (accountid: string) {
        let result: boolean = true
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            if (apis.getApiVersionForFeature("appleVPP") == "1.1") {
                await requestHandler.request(
                    "DELETE",
                    apis.getTenantApiUrl(tenantDomain, "appleVPP") + "/vpp/token"
                )
            } else {
                await requestHandler.request(
                    "DELETE",
                    apis.getTenantApiUrl(tenantDomain, "appleVPP") + "/vpp"
                )
            }
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return result
    },

    unlinkZeroTouch: async function (accountid: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            await requestHandler.request(
                "DELETE",
                apis.getTenantApiUrl(tenantDomain, "zeroTouch") +
                    "/android/androiddeviceprovisioning"
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },

    setEnterpriseDeviceProperty: async function (
        tenantDomain: string,
        deviceId: string,
        updateMask: any,
        object: any
    ) {
        let updateMaskString: string = ""
        if (typeof updateMask == "string") {
            updateMask = [updateMask]
        }
        for (let i: number = 0; updateMask.length > i; i++) {
            if (i == 0) {
                updateMaskString += "?updateMask[]="
            } else {
                updateMaskString += "&updateMask[]="
            }
            updateMaskString += updateMask[i]
        }
        return await requestHandler.request(
            "PUT",
            apis.getTenantApiUrl(tenantDomain, "androidEnterprise") +
                "/android/emm/enterprise/devices/" +
                deviceId +
                "/properties/" +
                updateMaskString,
            object
        )
    },

    setEnterpriseDeviceProperties: async function (
        tenantDomain: string,
        deviceId: string,
        properties: any
    ) {
        return await requestHandler.request(
            "PUT",
            apis.getTenantApiUrl(tenantDomain, "androidEnterprise") +
                "/android/emm/enterprise/devices/" +
                deviceId +
                "/properties",
            properties
        )
    },

    getEnterpriseEnrollmentTokens: async function (accountid: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "GET",
                apis.getTenantApiUrl(tenantDomain, "androidEnterprise") +
                    "/android/emm/enterprise/enrollmentTokens"
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },
    createEnterpriseEnrollmentToken: async function (
        accountid: string,
        policyId: string,
        enrollmentTokenObj: any
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "POST",
                apis.getTenantApiUrl(tenantDomain, "androidEnterprisePolicies") +
                    "/android/emm/enterprise/policies/" +
                    encodingHelpers.encodeURI(policyId) +
                    "/enrollmentTokens",
                enrollmentTokenObj
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },
    deleteEnterpriseEnrollmentToken: async function (accountid: string, enrollmentToken: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "DELETE",
                apis.getTenantApiUrl(tenantDomain, "androidEnterprise") +
                    "/android/emm/enterprise/enrollmentTokens/" +
                    enrollmentToken
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },

    pingDevice: async function (accountid: string, deviceId: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "POST",
                apis.getTenantApiUrl(accountid, "apple") + "/devices/" + deviceId
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },

    getDeviceMessages: async function (accountid: string, deviceId: string, message: string = "") {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            result = await requestHandler.request(
                "GET",
                apis.getTenantApiUrl(accountid, "apple") +
                    "/devices/" +
                    deviceId +
                    "/messages" +
                    message
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },

    sendDeviceMessage: async function (accountid: string, deviceId: string, message: any) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            result = await requestHandler.request(
                "POST",
                apis.getTenantApiUrl(accountid, "apple") + "/devices/" + deviceId + "/messages",
                message
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },
    sendDeviceLockMessage: async function (accountid: string, deviceId: string, message: any) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            result = await requestHandler.request(
                "POST",
                apis.getTenantApiUrl(accountid, "apple").replace("1.1", "2.0") +
                    "/devices/" +
                    deviceId +
                    "/messages",
                message
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },

    revokeDeviceMessage: async function (accountid: string, deviceId: string, messageId: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            result = await requestHandler.request(
                "DELETE",
                apis.getTenantApiUrl(accountid, "apple") +
                    "/devices/" +
                    deviceId +
                    "/messages/" +
                    messageId
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },

    getDeviceApps: async function (accountid: string, deviceId: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            result = await requestHandler.request(
                "GET",
                apis.getTenantApiUrl(accountid, "apple") + "/devices/" + deviceId + "/apps"
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },

    getVPNInfoFromVPNHistory: async function (
        accountid: string,
        anonymousVPNId: string,
        username: string,
        password: string,
        totp: string
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)

            result = await requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" + tenantDomain + "/history/vpns/" + anonymousVPNId,
                { username: username, password: password, totp: totp }
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },

    getDeviceInfoFromDeviceHistory: async function (
        accountid: string,
        anonymousDeviceId: string,
        username: string,
        password: string,
        totp: string,
        isEnterprise: boolean = false
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            if (isEnterprise) {
                result = await requestHandler.request(
                    "POST",
                    apis.getTenantApiUrl(tenantDomain, "androidEnterpriseHistory") +
                        "/android/emm/enterprise/history/devices/" +
                        anonymousDeviceId,
                    { username: username, password: password, totp: totp }
                )
                result = apis.parseApiResponse(result)
            } else {
                result = await requestHandler.request(
                    "POST",
                    "/sms-mgt-api/api/2.0/tenants/" +
                        tenantDomain +
                        "/apple/history/devices/" +
                        anonymousDeviceId,
                    { username: username, password: password, totp: totp }
                )
            }
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },

    getDeviceProperties: function (accountId: string, deviceId: string) {
        let result: any = null
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore()?.getters.hasAccountid(accountId) == false) {
                throw { status: 401 }
            }
            result = requestHandler.request(
                "GET",
                apis.getTenantApiUrl(accountId, "apple") + "/devices/" + deviceId + "/properties"
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },

    setDeviceProperty: async function (
        tenantDomain: string,
        deviceId: string,
        property: string,
        value: string
    ) {
        let data: any = {}
        data[property] = value
        let result = await requestHandler.request(
            "PUT",
            apis.getTenantApiUrl(tenantDomain, "apple") +
                "/devices/" +
                deviceId +
                "/properties/" +
                property,
            data
        )
        return apis.parseApiResponse(result)
    },
    setDeviceProperties: async function (tenantDomain: string, deviceId: string, properties: any) {
        let result = requestHandler.request(
            "POST",
            apis.getTenantApiUrl(tenantDomain, "apple") + "/devices/" + deviceId + "/properties",
            properties
        )
        return apis.parseApiResponse(result)
    },
    getLastDeviceBatteryInfo: async function (accountId: string, deviceId: string) {
        let result: any = null
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore()?.getters.hasAccountid(accountId) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountId)
            result = await mobileSecurityQueries.getDeviceMessages(
                tenantDomain,
                deviceId,
                "?type=BATTERY&limit=1"
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },

    getLastDeviceStorageInfo: async function (accountId: string, deviceId: string) {
        let result: any = null
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore()?.getters.hasAccountid(accountId) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountId)
            result = await mobileSecurityQueries.getDeviceMessages(
                tenantDomain,
                deviceId,
                "?type=STORAGE&limit=1"
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    },

    getQRCodeURL: function (tenantDomain: string, platform: string) {
        if (apis.getApiVersionForFeature("apple") == "2.0") {
            return apis.getTenantApiUrl(tenantDomain, "apple") + "/qrcode/"
        } else {
            return apis.getTenantApiUrl(tenantDomain, "apple") + "/qrcode/" + platform
        }
    },

    sendPushNotification: async function (tenantDomain: string, deviceId: string) {
        return requestHandler.request(
            "POST",
            apis.getTenantApiUrl(tenantDomain, "apple") + "/devices/" + deviceId
        )
    },

    dep: {
        getDEPDeviceInfos: async function (tenantDomain: string) {
            let theReturn: any = false
            try {
                let result: any = await requestHandler.request(
                    "POST",
                    apis.getTenantApiUrl(tenantDomain, "appleDEP") + "/dep/server/devices"
                )
                theReturn = apis.parseApiResponse(result)
            } catch (e: any) {
                console.error(e)
                mobileSecurityQueries.dep.doDepErrorCheck(e, "getDEPDeviceInfos")
                theReturn = { devices: [] }
            }
            return theReturn
        },
        syncDEPDevices: async function (tenantDomain: string) {
            let theReturn: any = false
            try {
                let result: any = await requestHandler.request(
                    "POST",
                    apis.getTenantApiUrl(tenantDomain, "appleDEP") +
                        "/dep/server/devices?action=assign_profiles"
                )
                theReturn = apis.parseApiResponse(result)
            } catch (e: any) {
                console.error(e)
                mobileSecurityQueries.dep.doDepErrorCheck(e, "syncDEPDevices")
                theReturn = {}
            }
            return theReturn
        },
        doDepErrorCheck: function (error: any, requestType?: string) {
            let accountId: string = useStore().state.session.activeAccountId || ""
            let expiryDate: string | undefined = jsonHelpers.getObjectProperty(
                useStore()?.state.session.accounts[accountId],
                "mobileSecurity.settings.ios.dep.expiry"
            )
            let expiryTimestamp = moment(expiryDate).unix()
            let timestamp = timeHelpers.getUnixTimeStamp()
            if (timestamp > expiryTimestamp) {
                dialogs.mobileSecurity.renderDepErrorDialog("expired")
            } else if (error?.status == 401) {
                dialogs.mobileSecurity.renderDepErrorDialog("unauthorized")
            } else if (requestType == "getObjectInfo") {
                dialogs.mobileSecurity.renderDepErrorDialog("errorGettingObject", error.data)
            } else if (error?.status == 403) {
                dialogs.mobileSecurity.renderDepErrorDialog("invalidToken", error.data)
            } else {
                dialogs.mobileSecurity.renderDepErrorDialog("unknown")
            }
        }
    },

    vpp: {
        hasResponseError: function (response: any) {
            return response.errorNumber != undefined || response.errorMessage != undefined
        },

        /**
         * completeAssetInfos
         * @param {[any]} assets
         *
         **/
        completeAssetInfos: async function (assets: any[], idProperty: string = "adamIdStr") {
            let result: any = {}
            let resultsByAdamIdStr: any = {}

            const ids: string[] = assets
                .map(function (asset: any) {
                    if (asset[idProperty]) {
                        return asset[idProperty].toString()
                    }
                })
                .filter((value: any) => {
                    return value != undefined
                })
            //@ts-ignore
            const countryCode: string | undefined =
                useStore()?.state.session.accounts[useStore().state.session.activeAccountId]
                    ?.mobileSecurity?.settings?.ios?.vpp?.countryCode || "DE"

            const baseUrl: string =
                "https://itunes.apple.com/lookup?" +
                (idProperty == "Identifier" ? "bundleId" : "id") +
                "=$idList$&media=software&entity=software,iPadSoftware&country=" +
                countryCode
            const baseUrlLength = baseUrl.length - 8
            const maxUrlLength = 1024

            let chunks: string[] = []

            let thisChunk = ""
            for (let i: number = 0; i < ids.length; i++) {
                let id = ids[i]
                let thisChunkLength = thisChunk.length
                let thisIdLength = id.length
                if (baseUrlLength + thisChunkLength + thisIdLength < maxUrlLength) {
                    // URL is shorter than max length
                    if (thisChunk.length == 0) {
                        thisChunk += id
                    } else {
                        thisChunk += "," + id
                    }
                    // push thisChunk to chunks array, if it is the last one
                    if (i == ids.length - 1) {
                        chunks.push(thisChunk)
                    }
                } else {
                    // URL has reached max length
                    // push thisChunk to chunks array and clear it
                    chunks.push(thisChunk)
                    thisChunk = id
                }
            }

            try {
                // build promise.all array
                let promises: any = []

                chunks.forEach((chunk: string) => {
                    promises.push(
                        requestHandler.request("GET", baseUrl.replace("$idList$", escape(chunk)))
                    )
                })
                let results = await Promise.all(promises)

                result.results = []
                results.forEach((thisResult: any) => {
                    if (typeof thisResult == "string") {
                        thisResult = JSON.parse(thisResult)
                    }
                    if (thisResult?.results?.length) {
                        result.results = result.results.concat(thisResult.results)
                    }
                })
            } catch (e: any) {
                dialogs.mobileSecurity.renderVPPErrorDialog(e)
                console.error(e)
            }

            if (result?.results?.length) {
                result.results.forEach(function (entry: any) {
                    if (idProperty == "Identifier") {
                        resultsByAdamIdStr[entry.bundleId] = entry
                    } else {
                        resultsByAdamIdStr[entry.trackId] = entry
                    }
                })
            }

            assets.forEach(function (asset: any) {
                if (asset[idProperty] && resultsByAdamIdStr[asset[idProperty]]) {
                    asset.assetInfo = resultsByAdamIdStr[asset[idProperty]] || undefined
                    asset.assetInfo.isInStore = true
                } else if (result?.results?.length && asset.assetInfo == undefined) {
                    asset.assetInfo = {
                        isInStore: false
                    }
                }
            })

            return assets
        },

        getAssetInfosMapFromAppList: async function (
            bundleIds: string[],
            os: "tvOS" | "iOS" = "iOS",
            addNativeApps = true
        ) {
            const entities = os === "tvOS" ? "tvSoftware" : "software,iPadSoftware"
            let result: any = { results: [] }
            //@ts-ignore
            let countryCode: string | undefined =
                useStore()?.state.session.accounts[useStore().state.session.activeAccountId]
                    ?.mobileSecurity?.settings?.ios?.vpp?.countryCode || "DE"
            const baseUrl: string = `https://itunes.apple.com/lookup?bundleId=$idList$&media=software&entity=${entities}&country=${countryCode}`
            const baseUrlLength = baseUrl.length - 8
            const maxUrlLength = 1024

            let assetInfosMap: any = {}

            try {
                let chunks: string[] = []

                let thisChunk = ""
                for (let i: number = 0; i < bundleIds.length; i++) {
                    let bundleId = bundleIds[i]
                    let thisChunkLength = thisChunk.length
                    let thisIdLength = bundleId.length
                    if (baseUrlLength + thisChunkLength + thisIdLength < maxUrlLength) {
                        // URL is shorter than max length
                        if (thisChunk.length == 0) {
                            thisChunk += bundleId
                        } else {
                            thisChunk += "," + bundleId
                        }
                        // push thisChunk to chunks array, if it is the last one

                        if (i == bundleIds.length - 1) {
                            chunks.push(thisChunk)
                        }
                    } else {
                        // URL has reached max length
                        // push thisChunk to chunks array and clear it
                        chunks.push(thisChunk)
                        thisChunk = bundleId
                    }
                }

                // build promise.all array
                let promises: any = []
                chunks.forEach((chunk: string) => {
                    chunks.forEach((chunk: string) => {
                        promises.push(
                            requestHandler.request(
                                "GET",
                                baseUrl.replace("$idList$", escape(chunk))
                            )
                        )
                    })
                })
                let results = await Promise.all(promises)
                result.results = []
                results.forEach((thisResult: any) => {
                    if (typeof thisResult == "string") {
                        thisResult = JSON.parse(thisResult)
                    }
                    if (thisResult?.results?.length) {
                        result.results = result.results.concat(thisResult.results)
                    }
                })
            } catch (e: any) {
                console.error(e)
            }
            if (result?.results == undefined) {
                result = { results: [] }
            }

            if (addNativeApps) {
                result.results = result.results.concat(
                    useStore()?.state.resources.apple.nativeAppInfos.apps
                )

                if (os == "tvOS") {
                    result.results = result.results.concat(
                        useStore()?.state.resources.apple.tvOsNativeApps.apps
                    )
                } else {
                    useStore()?.state.resources.apple.tvOsNativeApps.apps.forEach(
                        (appInfo: any) => {
                            const hasApp = result.results.some((existingAppInfo: any) => {
                                return existingAppInfo.bundleId == appInfo.bundleId
                            })
                            if (!hasApp) {
                                result.results.push(appInfo)
                            }
                        }
                    )
                }
            }

            result?.results?.forEach(function (entry: any) {
                assetInfosMap[entry.bundleId] = entry
            })
            return assetInfosMap
        },

        syncApps: async function (accountId: string) {
            let tenantDomain: string = accountId + ".sms"
            let result: any = undefined
            try {
                result = await requestHandler.request(
                    "POST",
                    apis.getTenantApiUrl(tenantDomain, "appleVPP") + "/vpp/synchronize"
                )
                if (typeof result == "string") {
                    result = JSON.parse(result)
                }
            } catch (e: any) {
                result = e
            }
            return result
        },

        /**
         * invitationEmail
         * @param {string} tenantDomain - Not required
         * @param {string} inviteCode
         */
        associateVPPUserWithITSAccount: async function (
            tenantDomain: any = undefined,
            inviteCode: string
        ) {
            let result: any = null
            tenantDomain = tenantDomain
                ? tenantDomain
                : tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")
            try {
                if (tenantDomain != undefined) {
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "appleVPP") +
                            "/vpp/invitationEmailUrl?inviteCode=" +
                            inviteCode +
                            "&mt=8&cc=DE"
                    )
                    if (mobileSecurityQueries.vpp.hasResponseError(apis.parseApiResponse(result))) {
                        throw apis.parseApiResponse(result)
                    }
                } else {
                    throw {
                        errorNumber: 1337,
                        errorMessage: "Missing tenantDomain"
                    }
                }
            } catch (e: any) {
                dialogs.mobileSecurity.renderVPPErrorDialog(e)
                result = e
            }
            return result
        },

        /**
         * registerUser
         * @param {string} tenantDomain - Not required
         * @param {string} email - Not required. ”user1@someorg.com”
         * @param {string} managedAppleIDStr - Not required. ”user1@someorg.com”
         * @param {string} facilitatorMemberId - Not required. See Program Facilitators
         * @param {string} clientUserIdStr - not required (set by mgt-api) - ”810C9B91-DF83-41DA-80A1-408AD7F081A8”
         */
        registerVPPUserSrv: async function (
            tenantDomain: any = undefined,
            email?: string,
            managedAppleIDStr?: string,
            facilitatorMemberId?: string,
            clientUserIdStr?: string
        ) {
            let result: any = null
            tenantDomain = tenantDomain
                ? tenantDomain
                : tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")
            let payload: any = {
                email: email || null,
                managedAppleIDStr: managedAppleIDStr || null,
                facilitatorMemberId: facilitatorMemberId || null
            }
            if (clientUserIdStr) {
                payload.clientUserIdStr = clientUserIdStr
            }

            try {
                if (tenantDomain != undefined) {
                    result = await requestHandler.request(
                        "POST",
                        apis.getTenantApiUrl(tenantDomain, "appleVPP") + "/vpp/registerUserSrvUrl",
                        payload
                    )
                    if (mobileSecurityQueries.vpp.hasResponseError(apis.parseApiResponse(result))) {
                        throw apis.parseApiResponse(result)
                    }
                } else {
                    throw {
                        errorNumber: 1337,
                        errorMessage: "Missing tenantDomain"
                    }
                }
            } catch (e: any) {
                dialogs.mobileSecurity.renderVPPErrorDialog(e)
                result = e
            }
            return apis.parseApiResponse(result)
        },

        /**
         * getUsers
         * @param {string} tenantDomain - Not required
         * @param {string} to - email of the recipient
         * @param {string} inviteCode - the inviteCode
         */

        sendVppInvite: async function (
            tenantDomain: any = undefined,
            to: string,
            inviteCode: string
        ) {
            let result: any = null
            tenantDomain = tenantDomain
                ? tenantDomain
                : tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")
            let payload: any = {
                inviteCode: inviteCode || null,
                to: to || null
            }
            try {
                if (tenantDomain != undefined) {
                    result = {
                        errorNumber: 1338,
                        errorMessage: "API Endpunkt fehlt"
                    }
                    await requestHandler.request(
                        "POST",
                        apis.getTenantApiUrl(tenantDomain, "appleVPP") + "/vpp/invite",
                        payload
                    )
                } else {
                    throw {
                        errorNumber: 1337,
                        errorMessage: "Missing tenantDomain"
                    }
                }
            } catch (e: any) {
                dialogs.mobileSecurity.renderVPPErrorDialog(e)
                result = e
            }
            return apis.parseApiResponse(result)
        },

        /**
         * getUsers
         * @param {string} tenantDomain - Not required
         * @param {string} batchToken - Not required
         * @param {string} sinceModifiedToken - Not required
         * @param {number} includeRetired - Not required. 1 or 0
         * @param {boolean} includeRetiredOnly - Not required
         */
        getVPPUsersSrv: async function (
            tenantDomain: any = undefined,
            batchToken?: string,
            sinceModifiedToken?: string,
            includeRetired?: string,
            includeRetiredOnly?: string
        ) {
            let result: any = null
            tenantDomain = tenantDomain
                ? tenantDomain
                : tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")

            let payload: any = {
                batchToken: batchToken || null,
                sinceModifiedToken: sinceModifiedToken || null,
                includeRetired: includeRetired || 1,
                includeRetiredOnly: includeRetiredOnly || false,
                ifModifiedSince: null,
                overrideIndex: null
            }

            try {
                if (tenantDomain != undefined) {
                    result = await requestHandler.request(
                        "POST",
                        apis.getTenantApiUrl(tenantDomain, "appleVPP") + "/vpp/getUsersSrvUrl",
                        payload
                    )
                    if (mobileSecurityQueries.vpp.hasResponseError(apis.parseApiResponse(result))) {
                        throw apis.parseApiResponse(result)
                    }
                } else {
                    throw {
                        errorNumber: 1337,
                        errorMessage: "Missing tenantDomain"
                    }
                }
            } catch (e: any) {
                dialogs.mobileSecurity.renderVPPErrorDialog(e)
                result = e
            }
            return apis.parseApiResponse(result)
        },

        /**
         * getUser
         * @param {string} tenantDomain - Not required
         * @param {string} userId - Not required if clientUserIdStr is set. ”100014”
         * @param {string} clientUserIdStr - Not required if userId is set. ”810C9B91-DF83-41DA-80A1-408AD7F081A8”
         * @param {string} itsIdHash - Not required. May be used, if clientUserIdStr is used
         */
        getVPPUserSrv: async function (
            tenantDomain: any = undefined,
            userId?: string,
            clientUserIdStr?: string,
            itsIdHash?: string
        ) {
            let result: any = null
            tenantDomain = tenantDomain
                ? tenantDomain
                : tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")

            let payload: any = {}
            if (userId) {
                payload.userId = userId
            }
            if (clientUserIdStr) {
                payload.clientUserIdStr = clientUserIdStr
            }
            if (itsIdHash) {
                payload.itsIdHash = itsIdHash
            }

            try {
                if (tenantDomain != undefined) {
                    if (payload.userId == undefined && payload.clientUserIdStr == undefined) {
                        throw {
                            errorNumber: 1337,
                            errorMessage: "Missing userId or clientUserIdStr"
                        }
                    }
                    result = await requestHandler.request(
                        "POST",
                        apis.getTenantApiUrl(tenantDomain, "appleVPP") + "/vpp/getUserSrvUrl",
                        payload
                    )
                    if (mobileSecurityQueries.vpp.hasResponseError(apis.parseApiResponse(result))) {
                        throw apis.parseApiResponse(result)
                    }
                } else {
                    throw {
                        errorNumber: 1337,
                        errorMessage: "Missing tenantDomain"
                    }
                }
            } catch (e: any) {
                dialogs.mobileSecurity.renderVPPErrorDialog(e)
                result = e
            }
            return apis.parseApiResponse(result)
        },

        /**
         * editUser
         * @param {string} tenantDomain - Not required
         * @param {string} clientUserIdStr - Not required if userId is set. ”810C9B91-DF83-41DA-80A1-408AD7F081A8”
         * @param {string} email - Not required. ”user1@someorg.com”
         * @param {string} managedAppleIDStr - Not required. Managed Apple ID
         */
        editVPPUserSrv: async function (
            tenantDomain: any = undefined,
            clientUserIdStr?: string,
            email?: string,
            managedAppleIDStr?: string
        ) {
            let result: any = null
            tenantDomain = tenantDomain
                ? tenantDomain
                : tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")

            let payload: any = {
                email: email,
                managedAppleIDStr: managedAppleIDStr,
                clientUserIdStr: clientUserIdStr
            }

            try {
                if (tenantDomain != undefined) {
                    if (payload.clientUserIdStr == undefined) {
                        throw {
                            errorNumber: 1337,
                            errorMessage: "Missing clientUserIdStr"
                        }
                    }
                    result = await requestHandler.request(
                        "POST",
                        apis.getTenantApiUrl(tenantDomain, "appleVPP") + "/vpp/editUserSrvUrl",
                        payload
                    )
                    if (mobileSecurityQueries.vpp.hasResponseError(apis.parseApiResponse(result))) {
                        throw apis.parseApiResponse(result)
                    }
                } else {
                    throw {
                        errorNumber: 1337,
                        errorMessage: "Missing tenantDomain"
                    }
                }
            } catch (e: any) {
                dialogs.mobileSecurity.renderVPPErrorDialog(e)
                result = e
            }
            return apis.parseApiResponse(result)
        },

        /**
         * retireUser
         * @param {string} tenantDomain - Not required
         * @param {string} userId - Not required if clientUserIdStr is set. ”100014”
         * @param {string} clientUserIdStr - Not required if userId is set. ”810C9B91-DF83-41DA-80A1-408AD7F081A8”
         * @param {string} facilitatorMemberId - Not required.
         */

        retireVPPUserSrv: async function (
            tenantDomain: any = undefined,
            userId?: string,
            clientUserIdStr?: string,
            facilitatorMemberId?: string
        ) {
            let result: any = null
            tenantDomain = tenantDomain
                ? tenantDomain
                : tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")

            let payload: any = {}
            if (userId) {
                payload.userId = userId
            }
            if (clientUserIdStr) {
                payload.clientUserIdStr = clientUserIdStr
            }
            if (facilitatorMemberId) {
                payload.facilitatorMemberId = facilitatorMemberId
            }

            try {
                if (tenantDomain != undefined) {
                    if (payload.userId == undefined && payload.clientUserIdStr == undefined) {
                        throw {
                            errorNumber: 1337,
                            errorMessage: "Missing userId or clientUserIdStr"
                        }
                    }
                    result = await requestHandler.request(
                        "POST",
                        apis.getTenantApiUrl(tenantDomain, "appleVPP") + "/vpp/retireUserSrvUrl",
                        payload
                    )
                    if (mobileSecurityQueries.vpp.hasResponseError(apis.parseApiResponse(result))) {
                        throw apis.parseApiResponse(result)
                    }
                } else {
                    throw {
                        errorNumber: 1337,
                        errorMessage: "Missing tenantDomain"
                    }
                }
            } catch (e: any) {
                dialogs.mobileSecurity.renderVPPErrorDialog(e)
                result = e
            }
            return apis.parseApiResponse(result)
        },

        /**
         * getAssignments
         * @param {string} tenantDomain - Not required
         * @param {string} adamIdStr - Not required - The unique identifier for a product in the iTunes Store.
         * @param {string} clientUserIdStr - Not required - If specified, returns only assignments assigned to the given client user ID.
         */
        getAssignments: async function (
            tenantDomain: any = undefined,
            adamIdStr?: string,
            clientUserIdStr?: string
        ) {
            let result: any = null
            tenantDomain = tenantDomain
                ? tenantDomain
                : tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")

            let payload: any = {}
            if (adamIdStr) {
                payload.adamIdStr = adamIdStr
            }
            if (clientUserIdStr) {
                payload.clientUserIdStr = clientUserIdStr
            }

            try {
                if (tenantDomain != undefined) {
                    result = await requestHandler.request(
                        "POST",
                        apis.getTenantApiUrl(tenantDomain, "appleVPP") +
                            "/vpp/getAssignmentsSrvUrl",
                        payload
                    )
                    if (mobileSecurityQueries.vpp.hasResponseError(apis.parseApiResponse(result))) {
                        throw apis.parseApiResponse(result)
                    }
                } else {
                    throw {
                        errorNumber: 1337,
                        errorMessage: "Missing tenantDomain"
                    }
                }
            } catch (e: any) {
                dialogs.mobileSecurity.renderVPPErrorDialog(e)
                result = e
            }
            return apis.parseApiResponse(result)
        },

        /**
         * getVPPLicensesSrv
         * You can use this endpoint to obtain a list of licenses from the server and to keep your MDM system up-to-date with changes made on the server.
         * @param {string} batchToken - Not required - EkZQCWOwhDFCwgQsUFJZkA
         * @param {string} adamId - Not required - 408709785.
         * @param {string} facilitatorMemberId - Not required - See Program Facilitators.
         * @param {boolean} assignedOnly - Not required - Defaults to false. Recommended value is true
         * @param {string} pricingParam - Not required - ”PLUS”
         * @param {string} serialNumber - Not required - ”C9JQ5QWMXRGH”
         * @param {boolean} userAssignedOnly - Not required - Defaults to false.
         * @param {boolean} deviceAssignedOnly - Not required - Defaults to false.
         */
        getVPPLicensesSrv: async function (
            tenantDomain: any = undefined,
            batchToken?: string,
            adamId?: string,
            facilitatorMemberId?: string,
            assignedOnly?: boolean,
            pricingParam?: string,
            serialNumber?: string,
            userAssignedOnly?: boolean,
            deviceAssignedOnly?: boolean,
            force?: boolean
        ) {
            let result: any = null
            let secondResult: any = null
            tenantDomain = tenantDomain
                ? tenantDomain
                : tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")

            let payload: any = {
                batchToken: batchToken || null,
                adamId: adamId || null,
                facilitatorMemberId: facilitatorMemberId || null,
                assignedOnly: assignedOnly || false,
                pricingParam: pricingParam || "STDQ",
                serialNumber: serialNumber || null,
                userAssignedOnly: userAssignedOnly || false,
                deviceAssignedOnly: deviceAssignedOnly || false
            }

            let timeStamp: number = moment().unix()
            let forceString = force ? "?_=" + timeStamp : ""

            try {
                if (tenantDomain != undefined) {
                    result = await requestHandler.request(
                        "POST",
                        apis.getTenantApiUrl(tenantDomain, "appleVPP") +
                            "/vpp/getLicensesSrvUrl" +
                            forceString,
                        payload
                    )
                    if (mobileSecurityQueries.vpp.hasResponseError(apis.parseApiResponse(result))) {
                        throw apis.parseApiResponse(result)
                    }
                    if (result.batchToken) {
                        await timeHelpers.sleep(1000)
                        secondResult = await mobileSecurityQueries.vpp.getVPPLicensesSrv(
                            tenantDomain,
                            result.batchToken
                        )
                    }
                    if (secondResult && secondResult.licenses) {
                        result.licenses = result.licenses.concat(secondResult.licenses)
                    }
                } else {
                    throw {
                        errorNumber: 1337,
                        errorMessage: "Missing tenantDomain"
                    }
                }
            } catch (e: any) {
                if (e.errorNumber != 9602) dialogs.mobileSecurity.renderVPPErrorDialog(e)
                result = e
            }
            return apis.parseApiResponse(result)
        },

        /**
         * manageVPPLicensesByAdamIdSrv
         * @param adamIdStr {string} - The unique identifier for a product in the iTunes Store.
         * @param associateClientUserIdStrs {[string]} - An array of client-user IDs to associate licenses with. When passing a value for associateClientUserIdStrs, you may not also pass a value for associateSerialNumbers.
         * @param associateSerialNumbers {[string]} - An array of device serial numbers to associate licenses with. When passing a value for associateSerialNumbers, you may not also pass a value for associateClientUserIdStrs.
         * @param disassociateClientUserIdStrs {[string]} - An array of client-user IDs to disassociate licenses from. When passing a value for disassociateClientUserIdStrs, you may not also pass a value for disassociateLicenseIdStrs or disassociateSerialNumbers.
         * @param disassociateLicenseIdStrs {[string]} - An array of license IDs to disassociate from the user ID or device. When passing a value for disassociateLicenseIdStrs, you may not also pass a value for disassociateClientUserIdStrs or disassociateSerialNumbers.
         * @param disassociateSerialNumbers {[string]} - An array of device serial numbers to disassociate licenses from. When passing a value for disassociateSerialNumbers, you may not also pass a value for disassociateClientUserIdStrs or disassociateLicenseIdStrs.
         * @param notifyDisassociation {boolean} - If true, sends notifications when licenses are disassociated. The default is true.
         * @param pricingParam {string} - The quality of a product in the iTunes Store. The default is STDQ. Possible values are: "STDQ": Standard quality, "PLUS": High quality. When assigning licenses for books, it is especially important to pass the correct pricingParam to the request, so the correct version of the product is assigned. The Get Assets endpoint will return the correct pricingParam for the purchased version of the book. If an organization purchases both the standard-quality and high-quality versions of a book, Get Assets will return two records with the same adamId but different pricingParams.
         */
        manageVPPLicensesByAdamIdSrv: async function (
            tenantDomain: any = undefined,
            adamIdStr: string,
            associateClientUserIdStrs: string[],
            associateSerialNumbers: string[],
            disassociateClientUserIdStrs: string[],
            disassociateLicenseIdStrs: string[],
            disassociateSerialNumbers: string[],
            notifyDisassociation?: boolean,
            pricingParam?: string
        ) {
            let result: any = null
            tenantDomain = tenantDomain
                ? tenantDomain
                : tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")

            let payload: any = {
                adamIdStr: adamIdStr || null,
                associateClientUserIdStrs: associateClientUserIdStrs || null,
                associateSerialNumbers: associateSerialNumbers || null,
                disassociateClientUserIdStrs: disassociateClientUserIdStrs || null,
                disassociateLicenseIdStrs: disassociateLicenseIdStrs || null,
                disassociateSerialNumbers: disassociateSerialNumbers || null,
                notifyDisassociation: notifyDisassociation || null,
                pricingParam: pricingParam || null
            }

            try {
                if (tenantDomain != undefined) {
                    result = await requestHandler.request(
                        "POST",
                        apis.getTenantApiUrl(tenantDomain, "appleVPP") +
                            "/vpp/manageVPPLicensesByAdamIdSrvUrl",
                        payload
                    )
                    if (mobileSecurityQueries.vpp.hasResponseError(apis.parseApiResponse(result))) {
                        throw apis.parseApiResponse(result)
                    }
                } else {
                    throw {
                        errorNumber: 1337,
                        errorMessage: "Missing tenantDomain"
                    }
                }
            } catch (e: any) {
                dialogs.mobileSecurity.renderVPPErrorDialog(e)
                result = e
            }
            return apis.parseApiResponse(result)
        },

        /**
         * getVPPAssetsSrv
         * @param includeLicenseCounts {boolean} - If true, returns the total number of licenses, the number of assigned licenses, and the number of unassigned licenses in the response for each asset.
         * @param pricingParam {string} - The quality of a product in the iTunes Store. The default is STDQ. Possible values are: "STDQ": Standard quality, "PLUS": High quality. When assigning licenses for books, it is especially important to pass the correct pricingParam to the request, so the correct version of the product is assigned. The Get Assets endpoint will return the correct pricingParam for the purchased version of the book. If an organization purchases both the standard-quality and high-quality versions of a book, Get Assets will return two records with the same adamId but different pricingParams.
         */
        getVPPAssetsSrv: async function (
            tenantDomain: any = undefined,
            includeLicenseCounts: boolean = true,
            pricingParam?: string,
            force?: boolean
        ) {
            let result: any = null
            tenantDomain = tenantDomain
                ? tenantDomain
                : tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")
            let payload: object = {
                includeLicenseCounts: includeLicenseCounts,
                pricingParam: pricingParam || "STDQ"
            }
            let timeStamp: number = moment().unix()
            let forceString = force ? "?_=" + timeStamp : ""
            try {
                if (tenantDomain != undefined) {
                    result = await requestHandler.request(
                        "POST",
                        apis.getTenantApiUrl(tenantDomain, "appleVPP") +
                            "/vpp/getVPPAssetsSrvUrl" +
                            forceString,
                        payload
                    )
                    if (mobileSecurityQueries.vpp.hasResponseError(apis.parseApiResponse(result))) {
                        throw apis.parseApiResponse(result)
                    }
                } else {
                    throw {
                        errorMessage: "Missing tenantDomain"
                    }
                }
            } catch (e: any) {
                if (e.responseText != "NOT_FOUND") dialogs.mobileSecurity.renderVPPErrorDialog(e)
                result = e
            }
            return apis.parseApiResponse(result)
        },

        /**
         * vppClientConfigSrv
         * @param clientContext {string} - Not required. (any string less than 256 bytes)
         * @param verbose {boolean} - Not required. ”true”.
         */
        vppClientConfigSrv: async function (
            tenantDomain: any = undefined,
            clientContext?: string,
            verbose?: boolean
        ) {
            let result: any = null
            tenantDomain = tenantDomain
                ? tenantDomain
                : tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")
            let payload: any = {
                clientContext: clientContext || null,
                verbose: verbose || false
            }
            if (!clientContext) {
                delete payload.clientContext
            }
            try {
                if (tenantDomain != undefined) {
                    result = await requestHandler.request(
                        "POST",
                        apis.getTenantApiUrl(tenantDomain, "appleVPP") + "/vpp/clientConfigSrvUrl",
                        payload
                    )
                    result = apis.parseApiResponse(result)
                    if (mobileSecurityQueries.vpp.hasResponseError(result)) {
                        throw result
                    }

                    if (!result.clientContext) {
                        mobileSecurityQueries.vpp.vppClientConfigSrv(
                            tenantDomain,
                            '{"hostname":"' +
                                config.hostname +
                                '", "guid":"' +
                                config.mobileSecurity.vppGuid +
                                '"}'
                        )
                    } else {
                        let thisClientContext: any = ""
                        try {
                            thisClientContext = JSON.parse(result.clientContext)
                        } catch (e: any) {
                            thisClientContext = result.clientContext
                        }
                        if (
                            typeof thisClientContext == "object" &&
                            (thisClientContext.hostname != config.hostname ||
                                thisClientContext.guid != config.mobileSecurity.vppGuid) &&
                            thisClientContext.hostname.indexOf("Apple Configurator") == -1
                        ) {
                            dialogs.mobileSecurity.renderUnknownVppClientContext(
                                tenantHelpers.getAccountId(tenantDomain),
                                thisClientContext
                            )
                        } else if (
                            typeof thisClientContext == "string" &&
                            thisClientContext.indexOf("Apple Configurator") == -1
                        ) {
                            dialogs.mobileSecurity.renderUnknownVppClientContext(
                                tenantHelpers.getAccountId(tenantDomain),
                                {
                                    guid: "",
                                    hostname: thisClientContext
                                }
                            )
                        }
                    }
                } else {
                    throw {
                        errorNumber: 1337,
                        errorMessage: "Missing tenantDomain"
                    }
                }
            } catch (e: any) {
                dialogs.mobileSecurity.renderVPPErrorDialog(e)
                result = e
            }
            return apis.parseApiResponse(result)
        },

        VPPServiceConfigSrv: async function (tenantDomain: any = undefined) {
            let result: any = null
            tenantDomain = tenantDomain
                ? tenantDomain
                : tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")
            try {
                if (tenantDomain != undefined) {
                    result = await requestHandler.request(
                        "GET",
                        apis.getTenantApiUrl(tenantDomain, "appleVPP") + "/vpp/VPPServiceConfigSrv"
                    )
                    if (mobileSecurityQueries.vpp.hasResponseError(apis.parseApiResponse(result))) {
                        throw apis.parseApiResponse(result)
                    }
                } else {
                    throw {
                        errorNumber: 1337,
                        errorMessage: "Missing tenantDomain"
                    }
                }
            } catch (e: any) {
                dialogs.mobileSecurity.renderVPPErrorDialog(e)
                result = e
            }
            return apis.parseApiResponse(result)
        }
    }
}

export default mobileSecurityQueries

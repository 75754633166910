<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from "vue"
//@ts-ignore
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import products from "@/classes/objectTypes"
import TaskLooper from "@/classes/taskLooper"
import type { License } from "@/classes/unifiedSecurity/licenses"
import type { UscUtm } from "@/classes/unifiedSecurityConsole/uscUtms"
import encodingHelpers from "@/helpers/helpers.encoding"
import jsonHelpers from "@/helpers/helpers.json"
import licenseHelpers from "@/helpers/helpers.license"
import sessionHelpers from "@/helpers/helpers.session"
import stringHelpers from "@/helpers/helpers.strings"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import validationHelpers from "@/helpers/helpers.validation"
import requestHandler from "@/queries/requests"
import { MutationTypes, useStore } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import Label from "@/templates/components/label/label"
import type Highcharts from "highcharts"
import moment from "moment"
import { sprintf } from "sprintf-js"
import loaderComponent from "../components/loader.vue"
import tableNext, { type TableEntryInfo, type TableEntryStatus } from "../components/tableNext.vue"

const dateFormat = timeHelpers.getDateFormatI18n(false)
const props = defineProps<{
    tenantWide: boolean
}>()
//@ts-ignore
let gaugeOptions = <Highcharts.Options>{
    chart: {
        type: "solidgauge",
        height: "80%"
    },
    title: null,
    exporting: false,
    tooltip: false,
    pane: {
        center: ["50%", "65%"],
        size: "120%",
        startAngle: -120,
        endAngle: 120,
        border: null,
        background: {
            backgroundColor: "none",
            innerRadius: "80%",
            outerRadius: "100%",
            shape: "arc",
            borderColor: "none",
            borderWidth: 0
        }
    },

    // the value axis

    yAxis: {
        allowDecimals: false,
        stops: [[0.1, "#E74C3C"]],

        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        min: 0,
        max: 0,
        tickPositions: [0, 0],
        labels: {
            enabled: true,
            y: 0,
            distance: 10
        },
        tickAmount: 2,
        showLastLabel: true
    },
    series: [
        {
            name: "Speed",
            color: "#f10",
            data: [0],
            innerRadius: "80%",
            outerRadius: "100%",
            dataLabels: {
                y: 20,
                format: '<div><span style="font-size:3em;">{y}</span></div>',
                position: "center",
                useHtml: true,
                verticalAlign: "middle"
            }
        }
    ],
    credits: false
}
//@ts-ignore
let basicBarOptions = <Highcharts.Options>{
    chart: {
        type: "bar",
        height: "80%"
    },
    title: {
        text: null,
        align: "left"
    },
    subtitle: {
        text: "",
        align: "left"
    },
    xAxis: {
        allowDecimals: false,
        categories: [],
        title: {
            text: null
        },
        gridLineWidth: 1,
        lineWidth: 1
    },
    yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
            text: null
        },
        labels: {
            overflow: "justify"
        },
        gridLineWidth: 0
    },
    tooltip: {
        valueSuffix: ""
    },
    plotOptions: {
        bar: {
            borderRadius: "50%",
            dataLabels: {
                enabled: true
            },
            groupPadding: 0.1,
            color: "#E74C3C",
            borderWidth: 0,
            borderColor: "none"
        }
    },
    legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "top",
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor: "none",
        shadow: true,
        enabled: false
    },
    credits: {
        enabled: false
    },
    series: [
        {
            name: "UTMs",
            borderWidth: 0,
            borderColor: "none",
            pointWidth: 16,
            data: []
        }
    ]
}

let onlineUtmsCounter = ref({
    loading: true,
    chartOptions: jsonHelpers.copyObject(gaugeOptions)
})
onlineUtmsCounter.value.chartOptions.yAxis.stops[0][1] = "#2DCC71"

let offlineUtmsCounter = ref({
    loading: true,
    chartOptions: jsonHelpers.copyObject(gaugeOptions)
})

let firmwareChart = ref({
    loading: true,
    chartOptions: jsonHelpers.copyObject(basicBarOptions)
})

const activeAccountId = computed(() => {
    return useStore().state.session.activeAccountId
})
const isTenantReseller = computed(() => {
    return (
        useStore().state.session.accounts[activeAccountId.value || ""]?.unifiedSecurity.account
            .isReseller || false
    )
})
const searchTenantWide = computed(() => {
    return props.tenantWide && isTenantReseller.value
})

const allAccountIds = computed(() => {
    const allAccounts = useStore().state.session.accounts
    return searchTenantWide.value ? Object.keys(allAccounts) : [activeAccountId.value]
})

const accountIdsWithUtms = computed(() => {
    let filteredAccountIds = allAccountIds.value.filter((accountId) => {
        return licenseHelpers.hasLicense(accountId || "", "unifiedSecurityConsole")
    })
    return filteredAccountIds
})

const utms = computed(() => {
    let thisUtms: any = []
    accountIdsWithUtms.value.forEach((accountId) => {
        thisUtms = thisUtms
            .concat(
                useStore().getters.getObjects({
                    accountId: accountId,
                    productType: "unifiedSecurityConsole",
                    objectType: "uscUtms"
                })
            )
            .filter((utm: UscUtm) => {
                return utm.license?.state == "valid"
            })
    })
    return thisUtms
})
const utmStates = computed(() => {
    let states: any = []
    accountIdsWithUtms.value.forEach((accountId) => {
        states = states.concat(
            useStore().getters.getObjects({
                accountId: accountId,
                productType: "unifiedSecurityConsole",
                objectType: "ccutmStates"
            })
        )
    })
    return states
})

const utmsCount = computed(() => {
    return utms.value?.length
})

const onlineUtmsCount = computed(() => {
    return (
        utms.value.filter((item: UscUtm) => {
            let objectId = item["utmId"]
            let thisState = utmStates.value.find((state: any) => {
                return objectId == state.deviceId
            })
            return thisState?.online == true
        }).length || 0
    )
})

const offlineUtmsCount = computed(() => {
    return utmsCount.value ? utmsCount.value - onlineUtmsCount.value : 0
})

const licenses = computed(() => {
    let thisLicenses: any = []
    accountIdsWithUtms.value.forEach((accountId) => {
        thisLicenses = config.canUseNewObjectType("licenses")
            ? thisLicenses.concat(
                  products.unifiedSecurity.licenses
                      .useStore?.()
                      .getObjectStoreObjects(accountId || "")
              )
            : thisLicenses.concat(
                  useStore().getters.getObjects({
                      accountId: accountId,
                      productType: "unifiedSecurity",
                      objectType: "licenses"
                  })
              )
    })
    return jsonHelpers.copyObject(thisLicenses)
})

const utmLicenses = computed(() => {
    return licenses.value.filter((license: License) => {
        return license.type != "Mobile Security" && license.type != "MDM"
    })
})

const calcFirmware = () => {
    let versionMap: any = {}
    let chartTitles2: any = []
    let chartData2: any = []
    utms.value.forEach((utm: UscUtm) => {
        let version =
            objectStores.uscUtms.getUtmMessageData(utm, "spcli-system-info")?.version ||
            T("Unknown")
        if (!versionMap[version]) {
            versionMap[version] = 0
        }
        versionMap[version]++
    })

    let keys = Object.keys(versionMap)
        .sort((a, b) => {
            if (a == T("Unknown")) return 1
            if (b == T("Unknown")) return -1
            let result = validationHelpers.versionCompare(a, b)
            if (result == false) result = 1
            return result
        })
        .reverse()
    for (const key in keys) {
        let firmware = keys[key]
        let thisData: any = {
            name: firmware,
            y: versionMap[firmware]
        }
        if (firmware == T("Unknown")) thisData.color = "rgba(0, 0, 0, 0.1)"
        chartTitles2.push(firmware)
        chartData2.push(versionMap[firmware])
    }
    firmwareChart.value.chartOptions.series[0].data = chartData2
    firmwareChart.value.chartOptions.xAxis.categories = chartTitles2
}

watch(utmsCount, (newCount) => {
    calcFirmware()
    onlineUtmsCounter.value.chartOptions.yAxis.max = newCount
    onlineUtmsCounter.value.chartOptions.series[0].data[0] = onlineUtmsCount.value
    offlineUtmsCounter.value.chartOptions.yAxis.max = newCount
    offlineUtmsCounter.value.chartOptions.series[0].data[0] = offlineUtmsCount.value
    onlineUtmsCounter.value.loading = false
    offlineUtmsCounter.value.loading = false
    firmwareChart.value.loading = false
})

watch(utmStates, () => {
    calcFirmware()
    onlineUtmsCounter.value.chartOptions.yAxis.max = utmsCount.value
    onlineUtmsCounter.value.chartOptions.series[0].data[0] = onlineUtmsCount.value
    offlineUtmsCounter.value.chartOptions.yAxis.max = utmsCount.value
    offlineUtmsCounter.value.chartOptions.series[0].data[0] = offlineUtmsCount.value
    onlineUtmsCounter.value.loading = false
    offlineUtmsCounter.value.loading = false
    firmwareChart.value.loading = false
})

const utmsSelectableColumns = <TableEntryInfo[]>[
    {
        property: "license:accountname",
        width: 200,
        text: T("Tenant"),
        displayType: "text",
        getValue: (utm: UscUtm) => {
            return utm.license.accountName
        },
        getSortValue: (utm: UscUtm) => {
            return utm.license.accountName
        }
    },
    {
        property: "utm:utmname",
        width: 200,
        text: T("UTM"),
        displayType: "link",
        getValue: (utm: UscUtm) => {
            return {
                text: utm.utmname,
                href:
                    "#show-tenant-" + String(utm.license.accountId) + ".sms-usc-utms-" + utm.utmId,
                target: "_self"
            }
        },
        getSortValue: (utm: UscUtm) => {
            return utm.utmname
        }
    },
    {
        property: "messages.spcli-system-info:hostname",
        width: 200,
        text: T("Hostname"),
        displayType: "link",
        getValue: (utm: UscUtm) => {
            return {
                text:
                    objectStores.uscUtms.getUtmMessageData(utm, "spcli-system-info")?.hostname ||
                    "",
                href: objectStores.uscUtms.getUtmMessageData(utm, "spcli-system-info")?.hostname
                    ? "https://" +
                      objectStores.uscUtms.getUtmMessageData(utm, "spcli-system-info")?.hostname
                    : undefined,
                target: "_blank"
            }
        },
        getSortValue: (utm: UscUtm) => {
            return objectStores.uscUtms.getUtmMessageData(utm, "spcli-system-info")?.hostname || ""
        }
    },

    {
        property: "messages.spcli-system-info:version",
        width: 90,
        text: T("Version"),
        displayType: "text",
        getValue: (utm: UscUtm) => {
            return objectStores.uscUtms.getUtmMessageData(utm, "spcli-system-info")?.version || ""
        },
        getSortValue: (utm: UscUtm) => {
            return objectStores.uscUtms.getUtmMessageData(utm, "spcli-system-info")?.version || ""
        }
    },
    {
        property: "messages.merged-update-info:new",
        width: 90,
        text: T("Updates"),
        displayType: "text",
        getValue: (utm: UscUtm) => {
            let result = ""
            if (
                objectStores.uscUtms.getUtmMessageData(utm, "merged-update-info")?.new &&
                objectStores.uscUtms.getUtmMessageData(utm, "merged-update-info")?.new != "none"
            ) {
                result = objectStores.uscUtms.getUtmMessageData(utm, "merged-update-info").new
            }
            return result
        },
        getSortValue: (utm: UscUtm) => {
            let result = ""
            if (
                objectStores.uscUtms.getUtmMessageData(utm, "merged-update-info")?.new &&
                objectStores.uscUtms.getUtmMessageData(utm, "merged-update-info")?.new != "none"
            ) {
                result = objectStores.uscUtms.getUtmMessageData(utm, "merged-update-info").new
            }
            return result
        }
    },
    {
        property: "messages.spcli-system-info:productname",
        width: 150,
        text: T("Model"),
        displayType: "text",
        getValue: (utm: UscUtm) => {
            return (
                objectStores.uscUtms.getUtmMessageData(utm, "spcli-system-info")?.productname || ""
            )
        },
        getSortValue: (utm: UscUtm) => {
            return (
                objectStores.uscUtms.getUtmMessageData(utm, "spcli-system-info")?.productname || ""
            )
        }
    },

    {
        property: "utm:tags",
        width: 200,
        text: T("Tags"),
        displayType: "labels",
        getValue: (utm: UscUtm) => {
            return (
                utm?.tags?.map(function (tag: string) {
                    return new Label({
                        class: "label",
                        text: tag,
                        title: tag,
                        displayType: "label"
                    })
                }) || []
            )
        },
        getSortValue: (utm: UscUtm) => {
            return Array.isArray(utm?.tags) && utm.tags.length > 0 ? utm.tags[0] : ""
        }
    },

    {
        property: "#status",
        width: 70,
        text: T("Connected"),
        displayType: "status",
        getValue: (utm: UscUtm) => {
            let isOnline = utmStates.value.some((state: any) => {
                return utm.utmId == state.deviceId && state.online
            })
            return {
                color: isOnline ? "green" : "grey",
                tooltip: isOnline ? T("Connected") : T("Disconnected"),
                icon: isOnline ? undefined : "fa-circle-xmark"
            }
        },
        getSortValue: (utm: UscUtm) => {
            let isOnline = utmStates.value.some((state: any) => {
                return utm.utmId == state.deviceId && state.online
            })
            return isOnline ? "A" : "Z"
        }
    },
    {
        property: "license:license_expiration_date",
        width: 70,
        text: T("License"),
        displayType: "status",
        getSortValue: (utm: UscUtm) => {
            return utm.license.expirationTimestamp
        },
        htmlTooltip: true,
        getValue: (utm: UscUtm) => {
            let color = utm.license.state == "valid" ? "green" : "red"
            let tooltipText =
                utm.license.state == "valid"
                    ? T("Valid until:") +
                      " " +
                      timeHelpers.formatDate(utm.license.expirationTimestamp * 1000, dateFormat)
                    : T(stringHelpers.capitalizeFirstLetter(utm.license.state || ""))
            if (
                utm.license.state == "valid" &&
                utm.license.expirationTimestamp &&
                moment.unix(utm.license.expirationTimestamp).diff(moment(), "days") + 1 <= 14
            ) {
                color = "yellow"
                // Today
                if (
                    moment
                        .unix(utm.license.expirationTimestamp)
                        .startOf("day")
                        .diff(moment().startOf("day"), "days") == 0
                ) {
                    tooltipText = T("License expires today")
                }
                // Tomorow
                else if (
                    moment
                        .unix(utm.license.expirationTimestamp)
                        .startOf("day")
                        .diff(moment().startOf("day"), "days") == 1
                ) {
                    tooltipText = T("License expires tomorrow")
                }
                // Others
                else {
                    tooltipText =
                        sprintf(
                            T("License expires in %s days"),
                            moment.unix(utm.license.expirationTimestamp).diff(moment(), "days") + 1
                        ) +
                        " (" +
                        timeHelpers.formatDate(utm.license.expirationTimestamp * 1000, dateFormat) +
                        ")"
                }
            }
            return {
                color: color,
                tooltip: tooltipText
            }
        }
    },
    {
        property: "#lastContact",
        width: 130,
        text: T("Last contact"),
        displayType: "text",
        getValue: (utm: UscUtm) => {
            let lastContact = utm?.messages?.["spcli-system-info"]?.timestamp || -1
            return lastContact == -1
                ? T("Never")
                : timeHelpers.formatDate(lastContact * 1000, timeHelpers.getDateFormatI18n(true))
        },
        getSortValue: (utm: UscUtm) => {
            let lastContact = utm?.messages?.["spcli-system-info"]?.timestamp || -1
            return lastContact
        }
    },
    {
        property: "#actions",
        width: 140,
        text: T("Actions"),
        displayType: "buttons",
        getValue: (utm: UscUtm) => {
            let isOnline = utmStates.value.some((state: any) => {
                return utm.utmId == state.deviceId && state.online
            })
            return [
                ...(function () {
                    let result = []

                    if (
                        isOnline &&
                        sessionHelpers.hasOneOfScopes(["usc:administration", "usc:monitoring"])
                    ) {
                        result.push(
                            new Button({
                                loading: false,
                                disabled: false,
                                title: T("Start new websession"),
                                text: T("Websession"),
                                icon: "fal fa-laptop",
                                onClick: () => {
                                    objectStores.uscUtms.dialogs.renderNewWebSessionDialog(
                                        String(utm.license.accountId),
                                        utm.utmId
                                    )
                                },
                                topRightCircle: { icon: "fa fa-lock" }
                            })
                        )
                    }

                    return result
                })()
            ]
        },
        getSortValue: (utm: UscUtm) => {
            let isOnline = utmStates.value.some((state: any) => {
                return utm.utmId == state.deviceId && state.online
            })
            return isOnline &&
                sessionHelpers.hasOneOfScopes(["usc:administration", "usc:monitoring"])
                ? "A"
                : "Z"
        }
    }
]

const licensesSelectableColumns = <TableEntryInfo[]>[
    {
        property: "accountname",
        width: 200,
        text: T("Tenant"),
        displayType: "text",
        getValue: (license: License) => {
            return license.accountName
        },
        getSortValue: (license: License) => {
            return license.accountName
        }
    },
    {
        property: "license",
        width: 200,
        text: T("License"),
        displayType: "link",
        getValue: (license: License) => {
            return {
                text: license.name,
                href:
                    "#show-tenant-" + license.accountId + ".sms-license-" + license.id + "-details",
                target: "_self"
            }
        },
        getSortValue: (license: License) => {
            return license.name
        }
    },
    {
        property: "status",
        width: 90,
        text: T("Status"),
        displayType: "status",
        getSortValue: (license: License) => {
            return license.expirationTimestamp
        },
        getValue: (license: License) => {
            let color = license.state == "valid" ? "green" : "red"
            let tooltipText =
                license.state == "valid"
                    ? '<span style="display:none">1</span>' +
                      T("Valid until:") +
                      " " +
                      timeHelpers.formatDate(license.expirationTimestamp * 1000, dateFormat) +
                      ""
                    : '<span style="display:none">3</span>' +
                      T(stringHelpers.capitalizeFirstLetter(license.state || ""))
            if (
                license.state == "valid" &&
                license.expirationTimestamp &&
                moment.unix(license.expirationTimestamp).diff(moment(), "days") + 1 <= 14
            ) {
                color = "yellow"
                // Today
                if (
                    moment
                        .unix(license.expirationTimestamp)
                        .startOf("day")
                        .diff(moment().startOf("day"), "days") == 0
                ) {
                    tooltipText = '<span style="display:none">2</span>' + T("License expires today")
                }
                // Tomorow
                else if (
                    moment
                        .unix(license.expirationTimestamp)
                        .startOf("day")
                        .diff(moment().startOf("day"), "days") == 1
                ) {
                    tooltipText =
                        '<span style="display:none">2</span>' + T("License expires tomorow")
                }
                // Others
                else {
                    tooltipText =
                        '<span style="display:none">2</span>' +
                        sprintf(
                            T("License expires in %s days"),
                            moment.unix(license.expirationTimestamp).diff(moment(), "days") + 1
                        ) +
                        "<br> (" +
                        timeHelpers.formatDate(license.expirationTimestamp * 1000, dateFormat) +
                        ")"
                }
            } else if (tooltipText.includes("script")) {
                tooltipText = encodingHelpers.escapeHTML(tooltipText)
            }
            return {
                color: color,
                tooltip: tooltipText,
                htmlTooltip: true
            } as TableEntryStatus
        }
    },
    {
        property: "id",
        width: 200,
        text: "ID",
        displayType: "text",
        getValue: (license: License) => {
            return license.id
        },
        getSortValue: (license: License) => {
            return license.id
        }
    },
    {
        property: "type",
        width: 90,
        text: T("Type"),
        displayType: "text",
        getValue: (license: License) => {
            return license.type
        },
        getSortValue: (license: License) => {
            return license.type
        }
    }
]

const updateStates = async () => {
    // GET states
    let statesUrl =
        "/sms-mgt-api/api/2.0/metrics?version=2.2&metrics=utm_usc_online_status" +
        (searchTenantWide.value ? "" : "&domains=" + activeAccountId.value + ".sms")
    let statesResponse = await requestHandler.request("GET", statesUrl)
    let states: any = {}
    const prometheusData = statesResponse
    try {
        prometheusData.split("\n").forEach((line: string) => {
            let lineResult: any = {}
            line = line.trim()
            line = line.length > 0 && line.charAt(0) != "#" ? line : "ignoreMe"
            if (line != "ignoreMe") {
                const firstBraceIndex = line.indexOf("{")
                const lastBraceIndex = line.lastIndexOf("}")
                // set onlinestatus
                lineResult.onlineStatus = Number(line.substring(lastBraceIndex + 1)) == 1
                // separate keyValuesString
                const keyValuesString = line.substring(firstBraceIndex, lastBraceIndex)
                // get keyValuePairs
                let keyValuePairs = keyValuesString.match(/(\w+)\s*=\s*("[^"]*"|[^,]*)/g) || []
                // loop through keyValuePairs and insert to lineResult
                keyValuePairs.forEach((keyValuePair) => {
                    const equalIndex = keyValuePair.indexOf("=")
                    if (equalIndex !== -1) {
                        try {
                            const key = keyValuePair.slice(0, equalIndex).trim()
                            const value = keyValuePair
                                .slice(equalIndex + 1)
                                .trim()
                                .replace(/\"/g, "")
                            lineResult[key] = value
                        } catch (e) {
                            console.error(e)
                        }
                    }
                })
                // Set values
                if (!states[tenantHelpers.getAccountId(lineResult["tenant_domain"])]) {
                    states[tenantHelpers.getAccountId(lineResult["tenant_domain"])] = []
                }
                states[tenantHelpers.getAccountId(lineResult["tenant_domain"])].push({
                    deviceId: lineResult["device_id"],
                    online: lineResult.onlineStatus,
                    lastContact: -1
                })
            }
        })

        // SET states
        Object.keys(states).forEach((accountId) => {
            if (accountId) {
                useStore().commit(MutationTypes.addOrUpdateObjects, {
                    accountId: accountId,
                    items: states[accountId],
                    objectType: "ccutmStates",
                    productType: "unifiedSecurityConsole"
                })
            }
        })
    } catch (e) {
        console.error(e)
    }
}

const taskLooper = new TaskLooper(60000, {
    enabled: true,
    rate: 1.5
})
taskLooper.addTask({
    id: "getUtmStates",
    method: updateStates,
    preventTaskOnHiddenTab: true
})

const licensesLoading = ref(true)
const utmsLoading = ref(true)

onMounted(async () => {
    // GET UTMs
    let utmsUrl =
        "/sms-mgt-api/api/2.0/tenants/" +
        tenantHelpers.getTenantDomain(activeAccountId.value || "") +
        "/utms" +
        (searchTenantWide.value ? "?with_subtenants=true" + "&" : "?") +
        "props[]=utmId" +
        "&props[]=utmname" +
        "&props[]=tags" +
        "&props[]=messages" +
        "&props[]=license" +
        "&select=data.utms[?(license.licenseScopes[?contains(@,%27cloud:messaging%27)%20==`true`])]"
    let allUtms = await requestHandler.request("GET", utmsUrl)

    allUtms.forEach((utm: UscUtm) => {
        objectStores.uscUtms.addOrUpdateObjectInStore(String(utm.license.accountId), utm)
    })

    await updateStates()

    if (utmsCount.value != undefined && utmsCount.value > 0) {
        onlineUtmsCounter.value.chartOptions.yAxis.max = utmsCount.value
        onlineUtmsCounter.value.chartOptions.series[0].data[0] = onlineUtmsCount.value
        offlineUtmsCounter.value.chartOptions.yAxis.max = utmsCount.value
        offlineUtmsCounter.value.chartOptions.series[0].data[0] = offlineUtmsCount.value
        onlineUtmsCounter.value.loading = false
        offlineUtmsCounter.value.loading = false
        firmwareChart.value.loading = false
    }

    onlineUtmsCounter.value.loading = false
    offlineUtmsCounter.value.loading = false
    firmwareChart.value.loading = false

    licensesLoading.value = false
    utmsLoading.value = false

    watch(utms, () => {
        calcFirmware()
    })
    calcFirmware()

    await timeHelpers.sleep(60000)
    taskLooper.startLimiter()
})

onUnmounted(() => {
    taskLooper.removeTask("getUtmStates")
})
</script>
<template>
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
        <div class="row">
            <div class="col-xs-24 col-lg-8 padding-xs-b">
                <div class="box box-shadow" style="height: 100%">
                    <div class="box-content-2">
                        <h5>{{ T("Online UTMs") }}</h5>
                        <template v-if="!onlineUtmsCounter.loading">
                            <highcharts
                                class="highcharts-nobg highcharts-gauge"
                                :options="onlineUtmsCounter.chartOptions"
                            ></highcharts>
                        </template>
                        <template v-else>
                            <div style="text-align: center; padding: 122px 0">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="col-xs-24 col-lg-8 padding-xs-b">
                <div class="box box-shadow" style="height: 100%">
                    <div class="box-content-2">
                        <h5>{{ T("Offline UTMs") }}</h5>
                        <template v-if="!offlineUtmsCounter.loading">
                            <highcharts
                                class="highcharts-nobg highcharts-gauge"
                                :options="offlineUtmsCounter.chartOptions"
                            ></highcharts>
                        </template>
                        <template v-else>
                            <div style="text-align: center; padding: 122px 0">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-lg-8 padding-xs-b">
                <div class="box box-shadow" style="height: 100%">
                    <div class="box-content-2">
                        <h5>{{ T("Firmware distribution") }}</h5>
                        <template v-if="!firmwareChart.loading">
                            <highcharts
                                class="highcharts-nobg"
                                style="height: 100%"
                                :options="firmwareChart.chartOptions"
                            ></highcharts>
                        </template>
                        <template v-else>
                            <div style="text-align: center; padding: 122px 0">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="row padding-xs-y">
            <div class="col-xs-24">
                <div class="box box-shadow">
                    <div class="box-content-2">
                        <h5>UTMs</h5>
                        <template v-if="!utmsLoading">
                            <tableNext
                                :object-list="utms"
                                :selectable-columns="utmsSelectableColumns"
                                :is-searchable="true"
                                :has-options="true"
                                :min-height="320"
                                :max-height="320"
                            ></tableNext>
                        </template>
                        <template v-else>
                            <div style="text-align: center; padding: 122px 0">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="row padding-xs-y">
            <div class="col-xs-24">
                <div class="box box-shadow">
                    <div class="box-content-2">
                        <h5>{{ T("Licenses") }}</h5>
                        <template v-if="!licensesLoading">
                            <tableNext
                                :object-list="utmLicenses"
                                :selectable-columns="licensesSelectableColumns"
                                :is-searchable="true"
                                :has-options="true"
                                :min-height="320"
                                :max-height="320"
                                :initialSortProperty="'status'"
                                :initialSortDirection="'ASC'"
                            ></tableNext>
                        </template>
                        <template v-else>
                            <div style="text-align: center; padding: 122px 0">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style lang="scss"></style>

<template>
    <div class="elementContainer">
        <div class="elementContainerTitleContainer">
            <div class="elementContainerTitle">
                <p class="elementContainerTitleText" v-if="currentDisplay">
                    <i class="fal fa-chart-bar"></i>
                    {{ T("Country attack statistics (historical)") }}
                </p>
                <p class="elementContainerTitleText" v-else>
                    <i class="fal fa-table"></i> {{ T("Country attack statistics (tabular)") }}
                </p>
                <div style="display: flex; height: 50px; line-height: 50px">
                    <p class="elementContainerTitleLHistoryTime"></p>
                    <div class="ElementContainerInfoContainer hasChangeDisplayButton">
                        <i
                            class="fal fa-info-circle gtvInfoCircle"
                            @click="callPopUp()"
                            :title="T('Further Information')"
                        ></i>
                        <i
                            v-if="currentDisplay"
                            class="fal fa-table switchDisplayIcon"
                            @click="currentDisplay = !currentDisplay"
                            :title="T('switch display')"
                        ></i>
                        <i
                            v-else
                            class="fal fa-chart-bar switchDisplayIcon"
                            @click="currentDisplay = !currentDisplay"
                            :title="T('switch display')"
                        ></i>
                    </div>
                </div>
            </div>
            <hr />
        </div>
        <!-- @vue-ignore -->
        <template v-if="historicalChart.chartOptions.series![0].data.length == 0">
            <div
                style="
                    text-align: center;
                    font-size: 2em;
                    padding: 130px 0;
                    opacity: 0.7;
                    color: var(--font-Color);
                "
            >
                {{ T("No Data available") }}
            </div>
        </template>
        <template v-else>
            <template v-if="currentDisplay">
                <div class="historyMainDiv">
                    <div class="chartDiv">
                        <highcharts
                            class="highcharts-historical"
                            :options="historicalChart.chartOptions"
                        ></highcharts>
                    </div>

                    <div class="controlDiv">
                        <div class="sliderDiv" v-if="props.apiRoute!.includes('livehistory')">
                            <input
                                step="1"
                                type="range"
                                :min="0"
                                :max="DataRaw.length - 1"
                                class="slider"
                                id="liveSlide"
                                v-model="sliderValue"
                            />
                            <button class="animationButton" @click="runAnimation('liveSlide')">
                                <i class="fa-solid fa-play" v-if="!animationRunning"></i>
                                <i class="fa-solid fa-stop" v-else></i>
                            </button>
                        </div>
                    </div>
                </div>
            </template>

            <template v-else>
                <table
                    class="portHistoryTableHead portHistoryTable"
                    v-bind:class="!currentDisplay ? 'tableViewActive' : ''"
                >
                    <thead>
                        <tr>
                            <th
                                class="colSpan-1"
                                colspan="2"
                                @click="sortNum('Country', countrySort)"
                            >
                                {{ T("Geo-IP Area") }}
                                <i
                                    class="fal fa-sort sortCountryButton"
                                    v-if="currentSortMethod == countrySort"
                                ></i>
                                <i class="fal fa-sort sortButtonUnselected" v-else></i>
                            </th>
                            <th class="col-2" @click="sortNum('IPs', susIPSort)">
                                {{ T("Suspicious IPs") }}
                                <i
                                    class="fal fa-sort sortSusIPButton"
                                    v-if="currentSortMethod == susIPSort"
                                ></i>
                                <i class="fal fa-sort sortButtonUnselected" v-else></i>
                            </th>
                        </tr>
                    </thead>
                </table>
                <div
                    class="portHistoryTableBodyWrapper"
                    v-bind:class="!currentDisplay ? 'tableViewActive' : ''"
                >
                    <table class="portHistoryTable">
                        <tbody>
                            <tr
                                v-for="(i, index) in myData"
                                :style="'--index: ' + index"
                                v-bind:class="!currentDisplay ? 'tableViewActive' : ''"
                            >
                                <td class="col-1">
                                    <span
                                        style="font-size: 25px"
                                        :class="`fi fi-${i[2]} flag`"
                                    ></span>
                                    {{ T(i[0]) }}
                                </td>
                                <td class="col-2">{{ i[1] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </template>
    </div>
</template>

<script setup lang="ts">
import i18n, { T } from "@/classes/i18n"
import dialogs from "@/dialogs/dialogs"
import getterHelpers from "@/helpers/helpers.getters"
import requestHandler from "@/queries/requests"
import type { Options } from "highcharts"
import { computed, onMounted, Ref, ref, watch } from "vue"
import countrycodes from "../assets/dictonaries/countryCodes.json"

interface iIpObj {
    country: string
    blockedIps: number
    countryName: string
}
interface iIpObjo {
    country: string
    blockedIps: number
}

const props = defineProps({
    apiRoute: String
})
const fetchProp = ref()
if (props.apiRoute) {
    fetchProp.value = props.apiRoute
}
const sortedIP = ref(true)
const currentSortMethod: Ref<string> = ref("")
const countrySort = ".sortCountryButton"
const susIPSort = ".sortSusIPButton"
const currentDisplay = ref(true)
const animationRunning: Ref<Boolean> = ref(false)
const DataRaw = ref<any[]>([])
const sliderValue = ref(0)
const chartTitle = ref("")
const ipAddressArray = ref<(number | string)[][]>([])
const countries = ref<string[]>([])
const LANG = i18n.getLanguage()
const activeAccountId = computed(() => {
    return getterHelpers.useStore()?.getters.getActiveAccountId
})

let animationInterval
let animationIterationCount = 0
let maxSliderLength = computed(() => DataRaw.value.length - 1)

try {
    DataRaw.value = await getAPIData()
    //remove broken indexes in case of backend failure
    DataRaw.value.forEach((element, index) => {
        if (Object.keys(element.data).length == 0) {
            DataRaw.value.splice(index, 1)
        }
    })
} catch (err) {
    console.error(err)
}

sliderValue.value = DataRaw.value.length - 1

onMounted(() => {
    sliderValue.value = 0
    updateHistoricalChart()
    getTimestamp(sliderValue.value)
})

watch(sliderValue, (newVal) => {
    updateHistoricalChart()
    getTimestamp(newVal)
})

const myData = computed(() => {
    const top20 = getData()
    ipAddressArray.value = top20.map((element) => [
        T(countrycodes[element.country as keyof typeof countrycodes]),
        element.blockedIps,
        element.country
    ])
    return ipAddressArray.value
})

async function getAPIData() {
    const data: any[] = await requestHandler.request("GET", fetchProp.value)
    return data
}
function getHighestValue() {
    if (!DataRaw.value.length) return []

    let filteredBlockedIp: iIpObjo[] = []
    let ipArray: number[] = []

    DataRaw.value.forEach((data) => {
        const blockedIp: iIpObjo[] = Object.entries(data.data).map(([countryCode, ipBlocked]) => {
            return {
                country: countryCode,
                blockedIps: ipBlocked as number
            }
        })
        blockedIp.sort((a, b) => b.blockedIps - a.blockedIps)
        filteredBlockedIp = blockedIp.filter((ipData) => ipData.country !== "timestamp")
        filteredBlockedIp.sort((a, b) => b.blockedIps - a.blockedIps)
        filteredBlockedIp = filteredBlockedIp.slice(0, 1)
        if (filteredBlockedIp.length > 0) {
            ipArray.push(filteredBlockedIp[0].blockedIps)
        }
    })

    ipArray.sort((a, b) => b - a)

    return ipArray[0]
}
function runAnimation(sliderElementId: string) {
    try {
        clearInterval(animationInterval)
        const sliderElement = document.getElementById(sliderElementId) as HTMLInputElement
        if (!animationRunning.value) {
            animationRunning.value = true
            animationIterationCount = 0
            animationInterval = setInterval(() => {
                if (animationIterationCount > maxSliderLength.value || !currentDisplay.value) {
                    clearInterval(animationInterval)
                    animationIterationCount = 0
                    animationRunning.value = false
                } else {
                    sliderElement.value = animationIterationCount.toString()
                    sliderValue.value = animationIterationCount
                    animationIterationCount++
                }
            }, 500)
        } else {
            clearInterval(animationInterval)
            animationRunning.value = false
        }
    } catch (err) {
        console.error(err)
    } finally {
        animationIterationCount = 0
    }
}

function callPopUp() {
    dialogs.misc.confirmDialog(
        activeAccountId.value,
        T("Country attack statistics"),
        T(
            "This histogram shows the live data from the last 100 hours. The data records can be switched through hourly using the slider. By pressing the button at the top right, the current data record will be displayed as a table."
        ),
        () => {
            return true
        },
        "",
        T("Close"),
        "",
        false,
        "fal fa-info-circle"
    )
}
function getTimestamp(dataIndex: number) {
    if (!DataRaw.value.length) return []
    let liveDate = DataRaw.value[dataIndex].timeStamp
    let historyDate = DataRaw.value[dataIndex].timeStamp * 1000

    function convertUnixTimetoDataTime(time: number | Date) {
        const dateObj = new Date(time)
        const year = dateObj.getFullYear()
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0")
        const day = dateObj.getDate().toString().padStart(2, "0")
        const hour = dateObj.getHours().toString().padStart(2, "0")
        const minute = dateObj.getMinutes().toString().padStart(2, "0")
        const ustime = dateObj.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true
        })
        let timeDataDate: string
        if (LANG === "de") {
            timeDataDate = `${day}.${month}.${year} ${hour}:${minute}`
        } else {
            timeDataDate = `${day}/${month}/${year} ${ustime}`
        }
        chartTitle.value = timeDataDate
    }

    if (props.apiRoute!.includes("livehistory")) {
        convertUnixTimetoDataTime(liveDate)
        const timeElements = document.querySelectorAll(".elementContainerTitleLHistoryTime")
        timeElements.forEach((el) => {
            el.textContent = `${chartTitle.value}`
        })
    }

    if (props.apiRoute!.includes("iphistory")) {
        convertUnixTimetoDataTime(historyDate)
        const timeElements = document.querySelectorAll(".elementContainerTitleHistoryTime")
        timeElements.forEach((el) => {
            el.textContent = `${chartTitle.value}`
        })
    }
}
function getData() {
    if (!DataRaw.value.length) return []
    const valueSlider = sliderValue.value
    const data = DataRaw.value[valueSlider].data

    const blockedIp: iIpObj[] = Object.entries(data).map(([countryCode, ipBlocked]) => {
        return {
            country: countryCode,
            blockedIps: ipBlocked as number,
            countryName: countrycodes[countryCode as keyof typeof countrycodes] || countryCode
        }
    })

    blockedIp.sort((a, b) => b.blockedIps - a.blockedIps)
    let filteredBlockedIp = blockedIp.filter((ipData) => ipData.country !== "timestamp")
    filteredBlockedIp.sort((a, b) => b.blockedIps - a.blockedIps)
    if (currentDisplay.value) {
        return filteredBlockedIp.slice(0, 15)
    } else {
        return filteredBlockedIp
    }
}
function getLabels() {
    const top20 = getData()
    countries.value = top20.map((element) => element.countryName)
    return countries.value
}
function updateHistoricalChart() {
    if (historicalChart.value.chartOptions.series && historicalChart.value.chartOptions.xAxis) {
        //@ts-ignore
        historicalChart.value.chartOptions.series[0].data = myData.value
        //@ts-ignore
        historicalChart.value.chartOptions.xAxis.categories = getLabels()
        historicalChart.value.loading = false
    }
}

// @ts-ignore
let barOptions = (<Highcharts.Options>{
    chart: {
        type: "bar"
    },
    accessibility: {
        enabled: false
    },
    title: {
        text: ""
    },
    xAxis: {
        categories: [],
        labels: {
            useHTML: true,
            step: 1,
            x: -40,
            formatter: function () {
                const _this = this as any
                let ret = ""
                ret += `<div align="center" style="word-wrap: break-word; word-break: break-all; font-size:12px; color:var(--font-Color); width:130px">${T(_this.value)}</div>`
                return ret
            }
        }
    },
    yAxis: {
        min: 0,
        max: getHighestValue(),
        title: {
            text: ""
        }
    },
    plotOptions: {
        bar: {
            borderRadius: "50%",
            color: "rgb(231, 76, 60)"
        }
    },
    legend: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    tooltip: {
        enabled: true,
        formatter: function () {
            const _this = this as any
            let ret = ""
            ret += `<b><span style="font-size:12px; color:var(--font-Color)">${T(_this.x)}</span></b><br>`
            ret += `<b><span style="font-size:12px; color:var(--font-Color)">${T("Blocked IPs")}:</span></b> <span style="font-size:12px; color:var(--font-Color)">${new Intl.NumberFormat("de-DE").format(_this.point.y)}</span>`
            return ret
        },
        positioner: function (labelWidth, labelHeight, point) {
            if (point.plotX > 500) {
                return {
                    x: point.plotX + labelWidth - 130,
                    y: point.plotY - labelHeight / 3
                }
            } else {
                return {
                    x: point.plotX + labelWidth + 20,
                    y: point.plotY - labelHeight / 3
                }
            }
        },
        useHTML: true
    },
    series: [
        {
            name: "Blocked IPs",
            data: []
        }
    ]
}) as Options & { series: (Options["series"] & { data: number[] })[] }

let historicalChart = ref({
    loading: true,
    chartOptions: barOptions
})

function sortNum(columnPortName: string, sortName: string) {
    if (columnPortName === "Country") {
        if (sortedIP.value) {
            sortedIP.value = false
            sortIcon(sortedIP.value, sortName)

            let sort1 = ipAddressArray.value.sort((a, b) =>
                String(a[0]).localeCompare(String(b[0]))
            )
            return sort1
        } else {
            sortedIP.value = true
            sortIcon(sortedIP.value, sortName)
            let sort2 = ipAddressArray.value?.sort((a, b) =>
                String(b[0]).localeCompare(String(a[0]))
            )
            return sort2
        }
    } else {
        if (sortedIP.value) {
            sortedIP.value = false
            sortIcon(sortedIP.value, sortName)
            let sort1 = ipAddressArray.value.sort((a, b) => Number(a[1]) - Number(b[1]))
            return sort1
        } else {
            sortedIP.value = true
            sortIcon(sortedIP.value, sortName)
            let sort2 = ipAddressArray.value?.sort((a, b) => Number(b[1]) - Number(a[1]))
            return sort2
        }
    }
}

function sortIcon(isSorted: boolean, columnName: string) {
    currentSortMethod.value = columnName

    let sortIconDiv: any = document.querySelector(columnName)

    const closest = sortIconDiv.closest("i")

    closest.classList.add("sortIcon")

    if (closest.classList.contains("fa-sort")) closest.classList.remove("fa-sort")

    if (isSorted) {
        if (closest.classList.contains("fa-sort-up")) closest.classList.toggle("fa-sort-up")

        closest.classList.toggle("fa-sort-down")
    } else {
        if (closest.classList.contains("fa-sort-down")) closest.classList.toggle("fa-sort-down")

        closest.classList.toggle("fa-sort-up")
    }
}
</script>

<style scoped>
.portHistoryTableBodyWrapper {
    overflow: hidden scroll;
    width: 95%;
    margin-bottom: 25px;
}

.portHistoryTableHead {
    margin: 0;
    width: 95%;
}

.portHistoryTable {
    border-collapse: collapse;
    background-color: var(--table-Background);
    color: var(--font-Color);
    margin: 0;

    td,
    th,
    tr {
        padding: 8px;
    }

    th:hover {
        cursor: pointer;
        background-color: var(--table-Background-nth);

        .sortCountryButton {
            opacity: 100%;
        }
        .sortSusIPButton {
            opacity: 100%;
        }
    }
    th {
        background-color: var(--table-Background);
    }
    tr:nth-child(odd) {
        background-color: var(--table-Background-nth);
    }

    tr {
        text-align: left;
    }
}
.sortButtonUnselected {
    opacity: 20%;
    float: right;
}

.sortCountryButton {
    opacity: 20%;
    float: right;
}
.sortSusIPButton {
    opacity: 20%;
    float: right;
}

.col-1 {
    display: flex;
    align-items: center;
    text-align: left;
    span {
        margin-right: 10px;
    }
}

.col-2 {
    width: 50%;
}

.colSpan-1 {
    width: 50%;
}

.th {
    font-weight: bold;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>

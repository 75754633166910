<template>
    <center-dialog disable-any-navigation>
        <div class="content-2" v-if="errorMsg">
            <div
                id="user-login-failed-message"
                class="notification color-white bg-red content text-center"
            >
                {{ errorMsg }}
            </div>
        </div>

        <form v-if="context === 'login'" autocomplete="on" method="POST" @submit.prevent="login">
            <label class="form-field">
                <span class="form-text-prefix text-center">
                    <i class="fal fa-globe"></i>
                </span>
                <input
                    v-model="tenantDomain"
                    disabled
                    :placeholder="T('Tenant domain (e.g. 123456.sms)')"
                    id="login-domain"
                    type="text"
                    class="form-control"
                    tabindex="0"
                />
            </label>
            <label class="form-field">
                <span class="form-text-prefix text-center">
                    <i class="fal fa-user"></i>
                </span>
                <input
                    v-model="username"
                    disabled
                    :placeholder="T('Username')"
                    id="username"
                    name="username"
                    autocomplete="username"
                    type="text"
                    class="form-control"
                    ref="usernameInput"
                    tabindex="1"
                />
            </label>
            <label class="form-field">
                <span class="form-text-prefix text-center">
                    <i class="fal fa-key"></i>
                </span>
                <input
                    v-model="password"
                    id="password"
                    autocomplete="password"
                    type="password"
                    name="password"
                    class="form-control"
                    :placeholder="T('Password')"
                    v-on:keyup.enter="login"
                    ref="passwordInput"
                    tabindex="3"
                />
            </label>

            <label class="form-field">
                <span class="form-text-prefix text-center">
                    <i class="fal fa-key"></i>
                </span>
            </label>

            <div class="btn-center">
                <button-component :button-options="loginButton" />
            </div>
        </form>
        <div v-else-if="context === 'totp'" class="content-2">
            <p class="text-bold">
                {{ T("Two-Factor-Authentication") }}
            </p>
            <p class="margin-xs-y-2">
                {{
                    T(
                        "Open the two-factor-authentication app on your device to view your authentication code."
                    )
                }}
            </p>

            <p style="display: block; width: fit-content; margin: 0 auto">
                <input-pin
                    :numbers-only="true"
                    :focus-on-mount="true"
                    :pin-length="6"
                    v-model="totp"
                    :totp="true"
                />
            </p>
        </div>
    </center-dialog>
</template>

<script setup lang="ts">
import { T } from "@/classes/i18n"
import { onMounted, ref, watch } from "vue"
import Button from "../components/button/button"
import ButtonComponent from "../components/button/button.vue"
import Icon from "../components/icon/icon"
import InputPin from "../inputtypes/input-pin.vue"
import CenterDialog from "../templates/center-dialog.vue"

const search = new URLSearchParams(window.location.search)
const loading = ref(false)
const context = ref("login")

const tenantDomain = ref("")
const username = ref("")
const password = ref("")
const totp = ref("")

const errorMsg = ref("")

const loginButton = ref(
    new Button({
        loading: () => loading.value,
        icon: new Icon({
            class: "fal fa-sign-in-alt"
        }),
        text: T("Sign in"),
        onClick: login
    })
)

async function login() {
    loading.value = true
    errorMsg.value = ""

    try {
        const ret = await fetch(`/sms-mgt-api/api/2.0/enroll/${tenantDomain.value}/byod/auth`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: username.value,
                password: password.value,
                code: totp.value
            })
        })

        const body = await ret.json()

        if (ret.ok) {
            window.location.href = body.data.redirectURL
        } else {
            handleLoginError(body)
        }
    } catch (e: any) {
        console.error(e)
    }

    loading.value = false
}

function handleLoginError(ret: any) {
    if (ret.data === "TOTP-Token is missing.") {
        context.value = "totp"
    } else if (ret.data === "TOTP-Token is invalid.") {
        errorMsg.value = T("TOTP-Token is invalid.")
    } else {
        errorMsg.value = T("Something went wrong, please try again.")
    }
}

onMounted(() => {
    tenantDomain.value = search.get("tenantDomain") || ""
    username.value = search.get("username") || ""
})

watch(totp, (val) => {
    if (val.length === 6) {
        login()
    }
})
</script>

<template>
    <div class="content-2">
        <p v-html="T('<b>We encountered an issue</b>.')"></p>

        <p>
            {{
                T(
                    "If the problem persists, please include the following Error ID in your support ticket to help us resolve it."
                )
            }}
        </p>

        <collapsed-box
            v-for="(info, title) in infoMap"
            :key="title"
            :title="T(title)"
            :default-open="info.opened"
        >
            <div class="width-100" style="display: flex; align-items: center">
                <pre class="margin-xs-r width-100">{{ info.data }}</pre>
                <button
                    @click.prevent="copy(info.data.value)"
                    class="btn btn-primary"
                    style="flex-shrink: 0"
                    :title="T('Copy to clipboard')"
                >
                    <i class="fal fa-copy"></i>
                </button>
            </div>
        </collapsed-box>
    </div>
</template>

<script setup lang="ts">
import ClipboardHandler from "@/classes/clipboard"
import { T } from "@/classes/i18n"
import CollapsedBox from "@/templates/components/collapsed-box.vue"
import { ref } from "vue"

const props = defineProps<{
    properties: {
        data: any
    }
}>()

const url = ref(props.properties.data.config.url || T("Unknown"))
const method = ref(props.properties.data.config.method?.toUpperCase() || T("Unknown"))
const errId = ref(props.properties.data.response?.data?.data?.errId || T("Unknown"))
const errData = ref(props.properties.data.response?.data?.data?.data)

const infoMap = {
    ["Error ID"]: {
        opened: true,
        data: errId
    },
    Method: {
        opened: false,
        data: method
    },
    URL: {
        opened: false,
        data: url
    },
    ...(errData.value && {
        ["Error data"]: {
            opened: true,
            data: errData
        }
    })
}

function copy(data: any) {
    new ClipboardHandler().copy(JSON.stringify(data))
}
</script>

<style lang="scss" scoped>
.width-100 {
    width: 100%;
}
</style>

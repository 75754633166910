<template>
    <div v-if="readIntro" class="mainGridContainer">
        <Suspense>
            <Worldmap :api-route="config.worldMap" :live-data-obj="liveData"></Worldmap>
        </Suspense>
        <Livedata :data-obj="liveData"></Livedata>
        <Suspense>
            <Historicaldata
                :api-route="config.historicalLiveData"
                class="historicaldata2"
            ></Historicaldata>
            <template #fallback>
                <div class="elementContainer historicaldata2"></div>
            </template>
        </Suspense>
        <Topdata />
        <Suspense>
            <HistoricalPort class="portdata"></HistoricalPort>
            <template #fallback> <div class="elementContainer portdata"></div> </template>
        </Suspense>
    </div>
    <div class="gtvInfoContainer" v-else>
        <div>
            <div class="col-xs-24 col-lg-16">
                <div class="box-shadow padding-xs-2" style="height: 500px">
                    <div style="height: 90%">
                        <h4 class="margin-xs-b-2">
                            <i
                                class="fal fa-map-location-dot fa-fw"
                                style="color: var(--header-Background)"
                            ></i
                            >Threat Visualizer
                        </h4>
                        <p>
                            {{
                                T(
                                    "The Threat Visualizer visualises the evaluation of attacks on systems operated by Securepoint. Securepoint operates systems throughout Germany that simulate lucrative targets in order to attract potential attackers. As soon as one of these so-called honeypots is attacked, useful data about the attacker, such as IP addresses and actions, is analysed and stored in our databases. In addition to the honeypots, we also operate the Securepoint Threat Intelligence Feed, a regularly updated list of IP addresses categorised as malicious."
                                )
                            }}
                        </p>
                        <p>
                            {{
                                T(
                                    "This TIF list is based on threat analyses and includes known command-and-control servers, malware distributors and other malicious infrastructures. The threat intelligence filter is used in the Securepoint services. This filter uses the TIF list to recognise, log and block connections to these IP addresses - regardless of the protocol used. In the Threat Visualiser, the threat data from the threat intelligence feed is displayed visually as a heat map on the map and as top lists in tables. The live data comes from our honeypots and is continuously fed into the threat analysis. "
                                )
                            }}
                        </p>
                        <div class="col-xs-24 text-center">
                            <br />
                        </div>
                    </div>
                    <div style="display: flex; justify-content: center">
                        <button @click="setIntroductionCookie()" class="gtvInfoButton">
                            <i class="fal fa-map-location-dot fa-fw"></i>
                            {{ T("Start Threat-Visualizer") }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-lg-8">
                <div
                    class="box-shadow padding-xs-2"
                    style="
                        height: 500px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    "
                >
                    <img
                        src="./assets/images/introPageWorldmap.svg"
                        alt=""
                        style="filter: brightness(70%)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { T } from "@/classes/i18n"
import { onMounted, onUnmounted, ref, Ref } from "vue"
import Historicaldata from "./components/historicaldata.vue"
import Livedata from "./components/livedata.vue"
import HistoricalPort from "./components/portdata.vue"
import Topdata from "./components/topdata.vue"
import Worldmap from "./components/worldmap.vue"
import config from "./config/frontendConfig.json"
const readIntro: Ref<boolean> = ref(false)
if (localStorage.SP_userReadGtvIntro == "true") {
    readIntro.value = true
} else {
    readIntro.value = false
}
function setIntroductionCookie() {
    readIntro.value = true
    localStorage.setItem("SP_userReadGtvIntro", "true")
}

const evtSource = new EventSource("/sms-mgt-api/api/2.0/gtv/livedata")
const liveData = ref()

onMounted(() => {
    evtSource.onmessage = (event) => {
        liveData.value = event
    }
})

onUnmounted(() => {
    evtSource.close()
})
</script>
<style>
.gtvInfoContainer {
    margin: 20px;
}

.gtvInfoTextContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.gtvInfoText {
    font-weight: 300;
    font-size: 15px;
    width: 90%;
}

.gtvInfoLink {
    margin: 25px 0;
    font-size: 14px;
}
</style>
<style src="./assets/stylesheets/gtv.css"></style>

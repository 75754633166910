<template>
    <div class="livedata elementContainer">
        <div class="elementContainerTitleContainer">
            <div class="elementContainerTitle">
                <p class="elementContainerTitleText">
                    <i class="fal fa-signal-stream"></i>{{ T("Live-data") }}
                </p>
                <div class="ElementContainerInfoContainer" @click="callPopUp()">
                    <i
                        class="fal fa-info-circle gtvInfoCircle"
                        :title="T('Further Information')"
                    ></i>
                </div>
            </div>
            <hr />
        </div>
        <div class="liveTableWrapper">
            <table class="liveDataTable">
                <thead>
                    <tr>
                        <th class="col-1">{{ T("Geo-IP Area") }}</th>
                        <th class="col-2">{{ T("Suspicious IPs") }}</th>
                        <th class="col-4">{{ T("Protocol") }}</th>
                        <th class="col-3">{{ T("Time") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="liveDataArray.length === 0">
                        <td colspan="4">{{ T("No Data available") }}</td>
                    </tr>
                    <tr v-for="i in liveDataArray" class="liveDataTableRow">
                        <td class="col-1">
                            <span :class="`fi fi-${i[0]} flag`"></span>
                            {{ T(countryCodes[i[0]]) }}
                        </td>
                        <td class="col-2">{{ T(i[1]) }}</td>
                        <td class="col-4">
                            <span
                                >{{ T("Attack on port") }} <b>{{ i[3] }}</b></span
                            >
                        </td>
                        <td class="col-3">
                            {{ getTimestamp(i[2]) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script setup lang="ts">
import i18n, { T } from "@/classes/i18n"
import dialogs from "@/dialogs/dialogs"
import getterHelpers from "@/helpers/helpers.getters"
import { computed, onMounted, ref, toRef, watch, type Ref } from "vue"
import _countryCodes from "../assets/dictonaries/countryCodes.json"

interface countryNames {
    [index: string]: string
}

const countryCodes: countryNames = _countryCodes
const LANG = i18n.getLanguage()
const liveData = ref([])
const liveDataArray: Ref<Array<any>> = ref([])
const props = defineProps(["dataObj"])
const activeAccountId = computed(() => {
    return getterHelpers.useStore()?.getters.getActiveAccountId
})
onMounted(() => {
    watch(
        toRef(() => props.dataObj),
        (newVal) => {
            liveDataCallback(newVal.data)
        }
    )
})
function callPopUp() {
    dialogs.misc.confirmDialog(
        activeAccountId.value,
        T("Live-data"),
        T(
            "This table provides a real-time overview of registered attacks based on geo-IP data. It lists the source IP addresses, the attacked protocol and the time of the attack. This information is continuously updated."
        ),
        () => {
            return true
        },
        "",
        T("Close"),
        "",
        false,
        "fal fa-info-circle"
    )
}
function getTimestamp(timestamp: number) {
    const dateObj = new Date(timestamp)

    if (LANG == "de") {
        const year = dateObj.getFullYear()
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0")
        const day = dateObj.getDate().toString().padStart(2, "0")

        const hour = dateObj.getHours().toString().padStart(2, "0")
        const minute = dateObj.getMinutes().toString().padStart(2, "0")
        const second = dateObj.getSeconds().toString().padStart(2, "0")

        return `${day}.${month}.${year} ${hour}:${minute}:${second}`
    } else {
        const ustime = dateObj.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true
        })
        const year = dateObj.getFullYear()
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0")
        const day = dateObj.getDate().toString().padStart(2, "0")

        return `${day}/${month}/${year} ${ustime}`
    }
}
function liveDataCallback(event: any) {
    liveData.value = JSON.parse(event)
    try {
        if (liveData.value[0] !== "unknown" && liveData.value[0] !== "") {
            liveDataArray.value.unshift(liveData.value)
            if (liveDataArray.value.length > 12) {
                liveDataArray.value.pop()
            }
            document.getElementsByClassName("liveDataTableRow")[0].classList.add("highlight")
            setTimeout(
                () =>
                    document
                        .getElementsByClassName("liveDataTableRow")[0]
                        .classList.remove("highlight"),
                500
            )
        }
    } catch (err) {}
}
</script>
<style scoped>
.liveTableWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.liveDataTable {
    width: 98%;
    height: 50px;
    margin-top: 10px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-collapse: collapse;
    background-color: var(--table-Background);
    color: var(--font-Color);
}

tr:nth-child(odd) {
    background-color: var(--table-Background-nth);
}

th {
    background-color: var(--table-Background-nth);
}

td,
th,
tr {
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 8px 8px;
    height: 18px !important;
    text-align: left;
}

.col-1 {
    width: 30%;
}

.col-2 {
    width: 20%;
}

.col-3 {
    width: 25%;
}

.col-4 {
    width: 25%;
}

.highlight {
    opacity: 1;
    animation: fade 0.5s linear;
}

@keyframes fade {
    0%,
    100% {
        background-color: var(--table-Background-nth);
    }

    50% {
        background-color: var(--header-Background);
    }
}

.flag {
    outline: 1px solid #a5a4a4b9;
    outline-offset: -1px;
}
</style>

const de = {
    "r/(.*) is not an allowed value./": "$1 ist kein gültiger Wert.",
    "r/Value (.*) is not a multiple of (.*)/": "Wert $1 muss ein Vielfaches von $2 sein.",
    "r/Value (.*) is less than minimum (.*)/": "Wert $1 darf nicht kleiner sein als $2.",
    "r/Value (.*) is equal to exclusive minimum (.*)/": "Wert $1 darf nicht kleiner sein als $2.",
    "r/Value (.*) is greater than maximum (.*)/": "Wert $1 darf nicht größer sein als $2.",
    "r/Value (.*) is equal to exclusive maximum (.*)/": "Wert $1 darf nicht größer sein als $2.",
    "r/Value (.*) is not a valid number/": "Wert $1 ist keine gültige Zahl.",
    "r/Please insert at least (.*) characters/": "Bitte mindestens $1 Zeichen eingeben.",
    "r/Please insert not more than (.*) characters/": "Bitte nicht mehr als $1 Zeichen eingeben.",
    "r/Invalid characters in content. Pattern: (.*)/":
        "Ungültige Zeichen enthalten. Erlaubte Zeichen: $1",
    "r/Missing required property: (.*)/": "Fehlende notwendige Eigenschaft: $1",
    "r/Format validation failed ((.*))/": "Format ungültig: ($1)",
    "r/Device (.*) has been depseudonymized by administrator (.*) and auditor (.*)./":
        "Das Gerät $1 wurde von dem Administrator $2 und Auditor $3 depseudonymisiert.",
    "r/VPN (.*) has been depseudonymized by administrator (.*) and auditor (.*)./":
        "Das VPN $1 wurde von dem Administrator $2 und Auditor $3 depseudonymisiert.",
    "r/Privacy level changed to (.*) by administrator (.*). Reason:/":
        "Datenschutzstufe wurde durch den Administrator $2 auf $1 gesetzt. Begründung:",
    "r/Privacy level changed to (.*) by administrator (.*). Reason: (.*)/":
        "Datenschutzstufe wurde durch den Administrator $2 auf $1 gesetzt. Begründung: $3",
    "r/Privacy level changed to (.*) by administrator (.*)./":
        "Datenschutzstufe wurde durch den Administrator $2 auf $1 gesetzt.",
    "Format validation failed (Data is not a valid email address.)":
        "Validierung fehlgeschlagen (Eingabe ist keine gültige E-Mail-Adresse)",
    "No license agreement": "Keine Lizenzvereinbarung",
    "Terms not accepted": "Bedingungen nicht akzeptiert",
    "Passwords do not match.": "Passwörter stimmen nicht überein",
    Yes: "Ja",
    Valid: "Gültig",
    "Max. devices": "Max. Geräte",
    "License type": "Lizenztyp",
    Stale: "Nicht mehr gültig",
    Licensee: "Lizenznehmer",
    "Enrolled at": "Angemeldet am",
    "Confirm assignment": "Zuordnung bestätigen",
    "Unknown Device": "Unbekanntes Gerät",
    Application: "Anwendung",
    "Delete application": "Anwendung Löschen",
    "Do you want to assign that device to this license":
        "Wollen Sie dieses Gerät, dieser Lizenz, zuordnen?",
    "No valid license found": "Keine gültige Lizenz gefunden",
    "Visit reseller portal": "Besuchen Sie unser Reseller-Portal",
    "Assign devices": "Geräte zuordnen",
    "Update licenses": "Lizenzen aktualisieren",
    "Assigned to another license": "Einer anderen Lizenz zugewiesen",
    "Incompatible with license": "Nicht kompatibel mit dieser Lizenz",
    "Any of the following conditions has to be fulfilled:":
        "Folgenden Bedingungen müssen erfüllt sein:",
    "Exactly one of the following conditions has to be fulfilled:":
        "Genau eine der folgenden Bedingungen muss erfüllt sein:",
    "Invalid content. Check if the ressource already exists.":
        "Ungültige Eingabe. Prüfen Sie ob die Ressource bereits existiert.",
    "Select recipients for this report. Double-Opt-In: the recipients have to accept that they receive reports.":
        "Wählen Sie die Empfänger für diesen Bericht aus. Double-Opt-In: die Empfänger müssen bestätigen, dass sie die Berichte erhalten wollen.",
    "Do you want to delete the notification": "Möchten Sie die Benachrichtigung löschen",
    "The name of the service": "Der Name des Dienstes",
    Generated: "Generiert",
    Template: "Vorlage",
    "Deleting token...": "Lösche Token...",
    "Delete expired tokens": "Lösche abgelaufene Token",
    New: "Neu",
    "the user can't be changed for BYOD-Devices.":
        "der Benutzer kann für BYOD-Geräte nicht mehr geändert werden.",
    Dashboard: "Übersicht",
    dashboard: "Übersicht",
    search: "suchen",
    Save: "Speichern",
    Locate: "Lokalisieren",
    Lock: "Sperren",
    Mute: "Stummschalten",
    Message: "Nachricht",
    "Message Text": "Nachrichtentext",
    Image: "Bild",
    Package: "Paket",
    Applications: "Anwendungen",
    Appconfiguration: "Appkonfiguration",
    "Operations log": "Protokoll",
    "Profile compliance": "Richtlinienkonformität",
    "The license that will be used for new enrolled devices.":
        "Die Lizenz die für neu angemeldete Geräte verwendet wird.",
    "More options": "Weitere Optionen",
    "Delete Token": "Token löschen",
    "Device details": "Gerätedetails",
    Active: "Aktiv",
    Inactive: "Inaktiv",
    "Add app": "App hinzufügen",
    "Add user": "Benutzer hinzufügen",
    "Add role": "Rolle hinzufügen",
    "Add profile": "Profil hinzufügen",
    "Add Tenant": "Kunden hinzufügen",
    "USR active": "USR aktiv",
    "USC active": "USC aktiv",
    "USR inactive": "USR inaktiv",
    "USC inactive": "USC inaktiv",
    "Add users": "Benutzer hinzufügen",
    "Add roles": "Rollen hinzufügen",
    "Add profiles": "Profile hinzufügen",
    "Add devices": "Geräte hinzufügen",
    "Add tags": "Tags hinzufügen",
    "Add entries": "Einträge hinzufügen",
    "Add SSIDs": "SSIDs hinzufügen",
    "Select UUID": "UUID wählen",
    "Select user": "Benutzer wählen",
    "Select users": "Benutzer wählen",
    "Enter a link": "Geben Sie einen Link ein",
    "Device profiles": "Geräteprofile",
    "Device Profiles": "Geräteprofile",
    "Device profile": "Geräteprofil",
    "Device Profile": "Geräteprofil",
    UNBIND: "AUFHEBEN",
    "Apple Push Certificate": "Apple Push Zertifikat",
    Link: "Verknüpfen",
    Linked: "Verknüpft",
    Unlink: "Verknüpfung aufheben",
    "To manage iOS devices, you need to use an Apple Push Certificate. The certificate establishes a trusted connection between iOS devices and Securepoint Mobile Security. You need to renew the certificate yearly.":
        "Um iOS Geräte zu verwalten brauchen Sie ein Apple Push Zertifikat. Das Zertifikat stellt eine vertrauenswürdige Verbindung zwischen der Securepoint Mobile Security und den iOS Geräten her. Das Zertifikat muss jährlich verlängert werden.",
    "Device Enrollment Program lets you automate the enrollment and simplify initial device setup. You can supervise devices during activation without touching them.":
        "Mit dem Geräteregistrierungsprogramm (DEP) können Sie die Geräteregistrierung automatisieren und die Ersteinrichtung des Geräts vereinfachen. Sie können Geräte während der Aktivierung überwachen, ohne sie zu berühren.",
    "Android 7.0 Nougat and later devices come with built-in enterprise features to power mobile, connected workforces. Securepoint Mobile Security enables organizations to manage these features.":
        "Android 7.0 Nougat und neuere Geräte bieten integrierte Funktionen für Unternehmen, die speziell auf mobil vernetzte Mitarbeiter ausgerichtet sind. Securepoint Mobile Security erlaubt es den Unternehmen diese Funktionen zu verwalten.",
    Edit: "Bearbeiten",
    Export: "Exportieren",
    Rolename: "Rollenname",
    Comment: "Kommentar",
    Password: "Passwort",
    password: "Passwort",
    Delete: "Löschen",
    "E-Mail": "E-Mail",
    email: "E-Mail",
    model: "Modell",
    Firstname: "Vorname",
    firstname: "Vorname",
    Lastname: "Nachname",
    lastname: "Nachname",
    Close: "Schließen",
    "Loading...": "Lade...",
    Search: "Suche",
    Preferences: "Einstellungen",
    Services: "Dienste",
    Devices: "Geräte",
    devices: "Geräte",
    Parts: "Teile",
    Profiles: "Profile",
    Profile: "Profil",
    User: "Benutzer",
    Users: "Benutzer",
    Roles: "Rollen",
    Certificates: "Zertifikate",
    Licenses: "Lizenzen",
    License: "Lizenz",
    "Sign out": "Abmelden",
    Tenants: "Mandanten",
    Tenant: "Mandant",
    "Add new app": "App hinzufügen",
    "Add VPN": "VPN hinzufügen",
    "Install apps": "Apps installieren",
    "Uninstall apps": "Apps deinstallieren",
    "Hide Details": "Details ausblenden",
    "Hide details": "Details ausblenden",
    "Show/Hide details": "Details anzeigen/verstecken",
    "Toggle View": "Ansicht ändern",
    Install: "Installieren",
    Done: "Fertig",
    "No entries found": "Keine Einträge gefunden",
    "No valid license found.": "Keine gültige Lizenz gefunden.",
    "This website is using cookies to provide the best user experience. By visiting and using this site, you agree to the use of cookies.":
        "Die Webseite verwendet Cookies, um Ihnen den bestmöglichen Service zu gewährleisten. Durch den Besuch und der Nutzung dieser Seite stimmen Sie der Cookie-Nutzung zu.",
    '<a target="_blank" href="https://www.securepoint.de/en/company/data-protection.html">More infos</a>':
        '<a target="_blank" href="https://www.securepoint.de/unternehmen/datenschutz.html">Mehr Infos</a>',
    "Upload File": "Datei hochladen",
    "Upload file": "Datei hochladen",
    Understood: "Verstanden",
    Warning: "Warnung",
    'Setting the SSL interception to "always" will limit the operability of your device (Google Play Store, Whatsapp etc.).':
        'Wenn Sie SSL abfangen auf "immer" setzen, wird die Funktionalität Ihres Geräts eingeschränkt (Google Play Store, Whatsapp etc.).',
    '<span class="confirm">Enter</span>': '<span class="confirm">Geben Sie</span>',
    '<span class="confirm">to confirm</span>': '<span class="confirm">ein um fortzufahren</span>',
    "Latest alerts": "Neue Warnungen",
    Description: "Beschreibung",
    "App filesize": "App-Dateigröße",
    "Select certificate": "Zertifikat auswählen",
    "Enter host": "Hostnamen eingeben",
    "Select E-Mail-address": "E-Mail-Adresse auswählen",
    "Select E-Mail": "E-Mail-Adresse auswählen",
    "App for audio calls": "App für Audioanrufe",
    "S/MIME Signing Certificate": "S/MIME Signierungszertifikat",
    "S/MIME Encryption Certificate": "S/MIME Verschlüsselungszertifikat",
    "The PayloadUUID of the identity certificate used to sign messages sent from this account":
        "Die UUID des Zertifikats welches benutzt wird, um die, von diesem Benutzer, gesendeten Nachrichten zu signieren",
    "The PayloadUUID of the identity certificate used to decrypt messages coming into this account":
        "Die UUID des Zertifikats welches benutzt wird, um die erhaltenen Nachrichten zu entschlüsseln",
    "Add IPs": "IPs hinzufügen",
    Confirm: "Bestätigen",
    "Renew your Apple Push Certificate": "Verlängern Sie Ihr Apple Push Zertifikat",
    "It seems that you don't have an Apple push certificate or it needs to be renewed":
        "Es scheint als hätten Sie kein Apple Push Zertifikat oder es bedarf einer Verlängerung",
    "Update now": "Jetzt aktualisieren",
    "Certificate generation": "Zertifikat erzeugen",
    "Follow these steps to generate your Apple Push Certificate and upload it":
        "Folgen Sie diesen Schritten, um Ihr Apple Push Zertifikat zu erzeugen und es hochzuladen",
    "Follow these steps to renew your Apple Push Certificate":
        "Folgen Sie diesen Schritten, um Ihr Apple Push Zertifikat zu verlängern",
    "Download your certificate signing request file (.csr):":
        'Laden Sie Ihre "certificate signing request"-Datei herunter (.csr):',
    "Download file": "Datei herunterladen",
    "Upload your .csr file to the": "Laden Sie Ihre .csr Datei zum",
    "Renew your Apple Push Certificate in the": "Verlängern Sie Ihr Apple Push Zertifikat im",
    "in order to download your Apple Push Certificate":
        "hoch, um Ihr Apple Push Zertifikat herunterladen zu können",
    "in order to download your renewed Apple Push Certificate":
        "hoch, um Ihr aktualisiertes Apple Push Zertifikat herunterladen zu können",
    "Enter the Apple ID that you used to generate the Apple Push Certificate:":
        "Tragen Sie die Apple ID ein, mit der Sie Ihr Apple Push Zertifikat erstellt haben:",
    "Upload your Apple Push Certificate (MDM_Securepoint GmbH_Certificate.pem):":
        "Laden sie Ihr Apple Push Zertifikat hoch (MDM_Securepoint GmbH_Certificate.pem):",
    "Upload Apple Push Certificate (.pem)": "Apple Push Zertifikat hochladen (.pem):",
    "Choose a file": "Wählen Sie eine Datei aus",
    "Sign in with your Securepoint Unified Security Account":
        "Mit Securepoint Unified Security Konto anmelden",
    "Remember me": "Anmeldung merken",
    "Sign in": "Anmelden",
    "Or:": "Oder:",
    "Sign in with a Reseller Account": "Melden Sie sich mit Ihrem Reseller-Konto an",
    "Incorrect username or password.": "Benutzername oder Passwort falsch.",
    "Tenant domain (e.g. 123456.sms)": "Tenant Domäne (z.B. 123456.sms)",
    Navigation: "Navigation",
    Apps: "Apps",
    Alerts: "Warnungen",
    Info: "Info",
    FAQ: "FAQ",
    "Reseller portal": "Reseller-Portal",
    "I agree to the terms of use": "Ich akzeptiere die Nutzungsbedingungen",
    "Application log": "Anwendungs-Log",
    "Add apps to this profile": "Fügen Sie Apps zu diesem Profil hinzu",
    "View tenants": "Tenants anzeigen",
    "View dashboard": "Dashboard anzeigen",
    "View devices": "Geräte anzeigen",
    "View profiles": "Profile anzeigen",
    "View apps": "Apps anzeigen",
    "View other device": "Anderes Gerät anzeigen",
    "View other devices": "Andere Geräte anzeigen",
    "Add other device (VPN)": "Anderes Gerät (VPN) hinzufügen",
    "Edit other device (VPN)": "Anderes Gerät (VPN) bearbeiten",
    "View users": "Benutzer anzeigen",
    "View roles": "Rollen anzeigen",
    "View certificates": "Zertifikate anzeigen",
    "View statistics": "Statistiken anzeigen",
    "View alerts": "Warnungen anzeigen",
    "View account": "Konto anzeigen",
    "View account info": "Kontoinformationen anzeigen",
    "View licenses": "Lizenzen anzeigen",
    "View privacy": "Datenschutz anzeigen",
    "Frequently asked questions": "Häufig gestellte Fragen",
    "View tutorials": "Tutorials ansehen",
    "Toggle log": "Log anzeigen/ausblenden",
    "View reseller portal": "Reseller Portal öffnen",
    Refresh: "Aktualisieren",
    "Sent invite to": "Einladung gesendet an",
    available: "verfügbar",
    of: "von",
    active: "aktiv",
    free: "frei",
    used: "benutzt",
    Back: "Zurück",
    back: "zurück",
    "Value:": "Wert:",
    "License:": "Lizenz:",
    "Profile:": "Profil:",
    Unspecified: "Nicht spezifiziert",
    Available: "Verfügbar",
    Blocked: "Blockiert",
    Enabled: "Aktiviert",
    Disabled: "Deaktiviert",
    Ignore: "Ignorieren",
    "AC charger": "AC-Ladegerät",
    "Wireless charger": "Kabelloses Ladegerät",
    "Expires on:": "Läuft ab am:",
    Both: "Beide",
    Device: "Gerät",
    "Work Profile": "Arbeitsprofil",
    Biometric: "Biometrisch",
    Something: "Etwas",
    Numeric: "Numerisch",
    "Numeric (complex)": "Numerisch (komplex)",
    Alphabetic: "Alphabetisch",
    Alphanumeric: "Alphanumerisch",
    Complex: "Komplex",
    "New devices": "Neue Geräte",
    "Enroll new Device": "Neues Gerät anmelden",
    "toggle view": "Ansicht ändern",
    "Filter devices": "Geräte filtern",
    "Hidden devices": "Ausgeblendet",
    "Filter signed out devices": "Abgemeldete Geräte verbergen",
    stale: "inaktiv",
    "Signed in": "Eingeloggt",
    "Signed out": "Ausgeloggt",
    "signed in": "eingeloggt",
    "signed out": "ausgeloggt",
    inactive: "inaktiv",
    Supervised: "Betreut",
    "Not supervised": "Nicht betreut",
    Rooted: "Rooted",
    "Not rooted": "Nicht gerooted",
    "View Device": "Gerät anzeigen",
    "Delete Device": "Gerät löschen",
    Model: "Modell",
    Ownership: "Eigentum",
    Phone: "Telefon",
    Account: "Konto",
    Contact: "Kontakt",
    "Last Contact": "Letzter Kontakt",
    "Last contact": "Letzter Kontakt",
    Summary: "Zusammenfassung",
    "Device Status": "Gerätestatus",
    "Battery Level": "Batteriestatus",
    "Storage Capacity": "Speicherkapazität",
    "Device ID": "Geräte ID",
    "Device Type": "Gerätetyp",
    Manufacturer: "Hersteller",
    "IMEI Number": "IMEI-Nummer",
    "Network Summary": "Netzwerkzusammenfassung",
    "Phone Number": "Telefonnummer",
    "Network Groups": "Netzwerkgruppen",
    "Network Objects": "Netzwerkobjekte",
    "Network Operator": "Netzbetreiber",
    "Bluetooth MAC": "Bluetooth-MAC",
    "Wifi MAC": "Wifi-MAC",
    "Roaming Enabled": "Roaming an",
    "OS Summary": "Betriebssystemzusammenfassung",
    OS: "Betriebssystem",
    "OS Version": "Betriebssystemversion",
    "Serial Number": "Seriennummer",
    "App Version": "App-Version",
    "Request Info": "Information anfordern",
    "Enable Lost Mode": "Lost-Modus aktivieren",
    "Enterprise Wipe": "Enterprise Löschen",
    "Wipe Data": "Daten löschen",
    "Show permissions": "Berechtigungen anzeigen",
    "Send uninstall request": "Deinstallations-Anfrage senden",
    Build: "Bauen",
    Paket: "Paket",
    Permissions: "Berechtigungen",
    Actions: "Aktionen",
    Received: "Erhalten",
    "Enroll new device": "Neues Gerät anmelden",
    "Send invite": "Einladung senden",
    "Clear Password": "Passwort entfernen",
    Shutdown: "Herunterfahren",
    shutdown: "herunterfahren",
    Reboot: "Neustarten",
    "Device Summary": "Gerätezusammenfassung",
    No: "Nein",
    "Device Operations": "Geräteoperationen",
    Operations: "Operationen",
    Pemissions: "Berechtigungen",
    Date: "Datum",
    Direction: "Richtung",
    "Delete device": "Gerät löschen",
    "Confirm Delete": "Löschen bestätigen",
    "Please enter": "Bitte geben Sie",
    "XPlease enter": "Bitte geben Sie",
    "Xto confirm": "ein, um zu bestätigen",
    XLink: "Verknüpfen",
    Cancel: "Abbrechen",
    "Do you want to delete device %s?": "Möchten Sie das Gerät %s löschen?",
    "When you delete this device in the MDM portal, the following happens:<br>BYOD: Profile is removed<br>COPE/COBO/COSU: Device is being reset":
        "Wenn Sie dieses Gerät im MDM Portal löschen passiert folgendes:<br>BYOD: Arbeitsprofil wird entfernt<br>COPE/COBO/COSU: Gerät wird zurückgesetzt",
    "This action deletes the device from the MDM portal. This action does not reset the device":
        "Diese Aktion löscht das Gerät aus dem MDM Portal. Durch diese Aktion wird das Gerät nicht zurückgesetzt",
    "Do you want to delete app %s?": "Möchten Sie die App %s löschen?",
    "Show details": "Details anzeigen",
    "Download profile": "Profil herunterladen",
    "Add custom Profiles (*.mobileconfig) via drag and drop or":
        "Fügen Sie benutzerdefinierte Profile (.mobileconfig) via Drag and Drop hinzu oder",
    "choose a file": "wählen Sie eine Datei aus",
    Error: "Fehler",
    Total: "Gesamt",
    "Choose your operating system": "Wählen Sie das Betriebssystem",
    "Assign tags to device": "Tags dem Gerät zuweisen",
    "Assign user to device": "Benutzer dem Gerät zuweisen",
    "Start Ringtone": "Klingelton starten",
    "Disable Lost Mode": "Lost-Modus deaktivieren ",
    "Do you really want to wipe this device? Your personal data wont be deleted.":
        "Möchten Sie dieses Gerät wirklich löschen? Ihre persönlichen Daten werden nicht gelöscht.",
    "Do you really want to wipe this device? All data that has not been backed up, will be lost.":
        "Möchten Sie dieses Gerät wirklich löschen? Alle Daten die nicht gesichert wurden, werden verloren gehen.",
    "Ping device": "Gerät anpingen",
    "Device location": "Gerätestandort",
    "Add device location": "Gerätestandort hinzufügen",
    "Set device location": "Gerätestandort festlegen",
    "Set inventory device location": "Inventargerätestandort festlegen",
    "Change device location": "Gerätestandort ändern",
    "Could not find location": "Ort konnte nicht lokalisiert werden",
    Warranty: "Garantie",
    Overview: "Übersicht",
    Inventory: "Inventar",
    "Inventory Link": "Inventar-Link",
    "Select a date": "Wählen Sie ein Datum",
    "last update": "letztes Update",
    "last update:": "Letztes Update:",
    "Bought on": "Gekauft am",
    "Warranty ends on": "Garantie läuft ab am",
    "Set warranty": "Garantie informationen setzen",
    "Warranty starts on": "Garantie beginnt am",
    "Warranty start": "Beginn der Garantie",
    "Warranty end": "Garantieende",
    "Warranty until": "Garantie bis",
    "Set link to inventory software": "Link zur Inventarsoftware setzen",
    "External inventory link": "Externer Inventar-Link",
    "ends on": "läuft ab am",
    "Open link": "Link öffnen",
    Contract: "Vertrag",
    Provider: "Anbieter",
    "The name of the provider": "Der Name des Anbieters",
    Tariff: "Tarif",
    Hardware: "Gerät",
    Conclusion: "Vertragsabschluss",
    "start of contract": "Vertragsbeginn",
    "Start of contract": "Vertragsbeginn",
    "Contract term": "Vertragslaufzeit",
    "Contract number": "Vertragsnummer",
    "End of contract": "Vertragsende",
    "Customer number": "Kundennummer",
    Handover: "Übergabe",
    "Handover date": "Übergabedatum",
    Months: "Monate",
    "In months": "In Monaten",
    "Contract term (In months)": "Vertragslaufzeit (In Monaten)",
    "Bill number": "Rechnungsnummer",
    "Date of purchase": "Kaufdatum",
    "Bill nr.": "Rechnungsnr.",
    "Bill date": "Datum der Rechnung",
    "Device description": "Gerätebeschreibung",
    "Device- and contract data": "Gerät- und Vertragsdaten",
    "You have reached the devices limit": "Sie haben das Gerätelimit erreicht",
    "You have reached the devices limit. If you want to add more Devices, you have to upgrade your Mobile Security License.":
        "Sie haben das Gerätelimit erreicht. Wenn Sie weitere Geräte hinzufügen möchten, müssen Sie Ihre Mobile Security Lizenz erweitern.",
    "Send to": "Senden an",
    "Send invitation": "Einladung senden",
    "Choose users which shall be invited or enter email addresses":
        "Benutzer auswählen oder E-Mail-Adresse eingeben",
    "Invite more": "Mehr einladen",
    "E-Mail(s) have been sent!": "E-Mail(s) wurden versendet!",
    "Device ownership and license agreement": "Geräteeigentümer und Lizenzvereinbarung",
    "This device is still unconfigured. Please set the device ownership and accept the appropriate license agreement:":
        "Dieses Gerät ist bislang unkonfiguriert. Bitte legen Sie den Geräteeigentümer fest und bestätigen die jeweilige Lizenzvereinbarung.",
    "Please accept the license agreement:": "Bitte bestätigen Sie die Lizenzvereinbarung.",
    "License agreement": "Lizenzvereinbarung",
    "Terms of use": "Nutzungsbedingungen",
    "I agree to the license agreement and the privacy conditions":
        "Ich akzeptiere die Lizenzbedingungen und die Datenschutzbestimmungen",
    Disagree: "Widersprechen",
    Agree: "Zustimmen",
    "License agreement for tenant": "Lizenzvereinbarung für Kunde",
    "Please read and accept the license agreement:":
        "Bitte lesen und akzeptieren Sie die Lizenzvereinbarung:",
    "License agreement:": "Lizenzvereinbarung:",
    Unconfigured: "Nicht konfiguriert",
    Used: "Genutzt",
    "Show not used profiles": "Zeige nicht genutzte Profile",
    "Show only used profiles": "Zeige nur genutzte Profile",
    "Add new profile": "Neues Profil hinzufügen",
    "Publish profiles": "Profile veröffentlichen",
    "Do you want to delete profile %s?": "Möchten Sie das Profil %s löschen?",
    Publish: "Veröffentlichen",
    "View profile": "Profil ansehen",
    "Edit profile": "Profil bearbeiten",
    "Delete profile": "Profil löschen",
    General: "Allgemein",
    Restrictions: "Einschränkungen",
    "Exchange Active Sync": "Exchange Active-Sync",
    "Activate exchange": "Exchange aktivieren",
    "After setting this, you can set exchange policies":
        "Nach der Aktivierung, können Sie Einstellungen für Exchange Active-Sync vornehmen",
    Email: "E-Mail",
    Security: "Sicherheit",
    "Custom Profiles": "Benutzerdefinierte Profile",
    Plattform: "Plattform",
    close: "schließen",
    "Profile name": "Profilname",
    "Unique profile name. Can not be changed later.":
        "Eindeutiger Profilname. Kann nicht nachträglich geändert werden.",
    "Device OS": "Gerätebetriebssystem",
    "Search for": "Suche nach",
    Reset: "Zurücksetzen",
    "Select webclip": "Webclip auswählen",
    "The higher the number, the higher the priority":
        "Je höher die Nummer, desto höher die Priorität.",
    "The profile will be assigned to all devices of all users with these roles":
        "Das Profil wird allen Geräten von allen Benutzern mit diesen Rollen zugewiesen.",
    "The profile will be assigned to all devices from these users":
        "Das Profil wird allen Geräten von diesen Benutzern zugewiesen.",
    "The profile will be assigned to all devices with these Tags":
        "Das Profil wird allen Geräten mit diesen Tags zugewiesen.",
    "The profile will be assigned to these devices": "Geräte, welche dem Profil zugeordnet sind.",
    "The profile will be assigned to all Clients with these Tags.":
        "Das Profil wird allen Clients mit diesen Tags zugewiesen.",
    "The profile will be assigned to these devices. Assignments may take several minutes before they take affect.":
        "Das Profil wird diesen Geräten zugewiesen. Die Zuweisungen können einige Minuten in anspruch nehmen, bevor sie wirksam werden.",
    "Make sure that the Securepoint Mobile Security app is installed on affected Devices!":
        "Stellen Sie sicher dass die Securepoint Mobile Security App auf den betroffenden Geräten installiert ist!",
    "Install Securepoint Mobile Security App":
        "Installieren Sie die Securepoint Mobile Security App",
    "It seems that following devices do not have the Securepoint Mobile Security App installed:":
        "Es scheint als wäre die Securepoint Mobile Security App auf folgenden Geräten nicht installiert:",
    "Install and close": "Installieren und schließen",
    "Priority: ": "Priorität: ",
    Priority: "Priorität",
    Platform: "Plattform",
    "Force Publish": "Veröffentlichung erzwingen",
    "Profile-Device assignment": "Profil-Gerät-Zuordnung",
    "Once the publishing-process has started, you can close this window. The profiles will still be published.":
        "Sobald der Veröffentlichungsprozess gestartet wurde, können sie dieses Fenster schließen. Die Profile werden weiterhin veröffentlicht.",
    "You can now close this window": "Sie können dieses Fenster jetzt schließen",
    "Import profile": "Profil importieren",
    "Don't wipe": "Nicht löschen",
    "NO PROFILE RULES": "KEINE PROFILREGELN",
    "Choose wether to use the OpenVPN-Client or the Securepoint Mobile Security":
        "Wählen Sie ob Sie den OpenVPN-Client oder die Securepoint Mobile Security verwenden möchten",
    "The legacy OpenVPN App is depricated and unsupported. Please use the new Securepoint Mobile Security App.":
        "Die OpenVPN-App ist veraltet und wird nicht mehr unterstützt. Bitte verwenden Sie die neue Securepoint Mobile Security App.",
    "Select E-Mail address": "Wählen Sie eine E-Mail-Adresse",
    "Allow other VPN profiles": "Andere VPN Profile erlauben",
    "Allows adding other VPN profiles in addition to the security profile":
        "Erlaubt das Hinzufügen von anderen VPN Profilen, zusätzlich zu dem Security-Profil",
    "No Password Required": "Kein Passwort benötigt",
    "Numeric complex": "Numerisch komplex",
    "Edit Template": "Vorlage bearbeiten",
    "Numeric (complex): the password has to contain at least numeric characters with no repeating (4444) or ordered (1234, 4321, 2468) sequences.":
        "Numerisch (Komplex): Das Passwort muss numerische Zeichen ohne wiederholte (4444) oder geordnete (1234, 4321, 2468) Sequenzen enthalten.",
    "Complex: the password has to contain at least a letter, a numerical digit and a special symbol, by default. With this password quality, passwords can be restricted to contain various sets of characters, like at least an uppercase letter, etc.":
        "Complex: Das Passwort muss standardmäßig mindestens einen Buchstaben, eine Ziffer und ein Symbol enthalten. Mit dieser Passwortqualität können Passwörter eingeschränkt werden, um verschiedene Zeichensätze zu enthalten, wie zum Beispiel einen Großbuchstaben usw.",
    "Allow Suspend Always-On-VPN": "Erlaube das Unterbrechen von Always-On-VPN",
    "Choose a user or enter an email address":
        "Wählen Sie einen Benutzer oder geben Sie eine E-Mail-Adresse ein",
    "The higher the number, the higher the priority. Is only used if a device is affected by multiple profiles":
        "Je höher die Nummer, desto höher ist die Priorität. Diese wird nur genutzt, wenn ein Gerät mehreren Profilen zugewiesen ist..",
    "Activate restrictions": "Einschränkungen aktivieren",
    "After setting this, you can select restrictions.":
        "Nach dem Aktivieren können Sie Einschränkungen auswählen.",
    "Restrict App Usage": "App Benutzung einschränken",
    "Allow all Apps": "Alle Apps erlauben",
    "Do not allow specific Apps": "Bestimmte Apps nicht erlauben",
    "Do allow only specific Apps": "Nur bestimmte Apps erlauben",
    "Allow all apps, allowlist or blocklist": "Alle Apps erlauben, Blocklist oder Allowlist",
    "Allow Siri User Generated Content": "Siri benutzergenerierten Inhalt erlauben",
    "When false, prevents Siri from querying user-generated content from the web":
        "Wenn inaktiv, wird verhindert, dass Siri Anfragen mit benutzergenerierten Inhalt abfragt",
    "Activate Exchange ActiveSync": "Exchange ActiveSync aktivieren",
    "After setting this, you can set Activate Exchange ActiveSync configurations.":
        "Nachdem Sie dies festgelegt haben, können Sie Exchange ActiveSync konfigurieren.",
    "After setting this, you can set Email configurations":
        "Nachdem Sie dies festgelegt haben, können Sie die E-Mail konfigurieren",
    "After setting this, you can set Web Security Configuration":
        "Nachdem Sie dies festgelegt haben, können Sie die Web-Security konfigurieren",
    "Filter network traffic based on network ports":
        "Netzwerkverkehr filtern aufgrund von Netzwerkports",
    "Filter network traffic based on network ports.":
        "Netzwerkverkehr filtern aufgrund von Netzwerkports.",
    "Defines which port collections are opened for network traffic":
        "Festlegen, welche Port-Collections für den Netzwerkverkehr geöffnet sind",
    "Payload Certificate UUID": "Payload-Zertifikats-UUID",
    "Exchange ActiveSync Host": "Exchange ActiveSync-Host",
    "The Exchange server host name or IP address": "Der Exchange-Server Hostname oder IP-Adresse",
    "Past Days of Mail to Sync":
        "Anzahl der Tage, in denen die E-Mails aus der Vergangenheit synchronisiert werden",
    "Communication Service Rules": "Kommunikations-Service-Regeln",
    "The Communication Service Rules contain an Application that handles audio calls made to contacts from this account.":
        "Die Kommunikations-Service-Regeln beinhalten eine App, welche die Anrufe zu den Kontakten des Benutzers steuert.",
    "Select apps": "Apps auswählen",
    "Select Web clips": "Web clips auswählen",
    Forever: "Für immer",
    "Operating mode": "Betriebsart",
    "1 Day": "1 Tag",
    "2 Days": "2 Tage",
    "3 Days": "3 Tage",
    "1 week": "1 Woche",
    "2 weeks": "2 Wochen",
    "1 month": "1 Monat",
    "1 year": "1 Jahr",
    Infinite: "Unendlich",
    "Domain/User": "Domain/Benutzer",
    "Domain/User for the account. Domain and User must be blank for device to prompt for user":
        "Domain/Benutzer des Kontos. Domäne und Benutzer müssen leer bleiben, wenn das Gerät fragen soll",
    "If set to true, this account will support S/MIME":
        "Wenn aktiv, wird der Account S/MIME unterstützen",
    "If set to true, enable the per-message encryption switch":
        "Aktiviert die Verschlüsselung pro Nachricht",
    Prompt: "Nachfragen",
    "Activate Email": "E-Mail aktivieren",
    "Port number for incoming mail": "Port-Nummer für eingehende E-Mail",
    "The port number for outgoing mail": "Port-Nummer für ausgehende E-Mail",
    "The password for the outgoing mail server": "Das Passwort für den ausgehenden E-Mail-Server",
    "After setting this, you can set Email configurations.":
        "Nachdem Sie dies festgelegt haben, können Sie E-Mail-Konfigurationen festlegen",
    "Account Description": "Kontobeschreibung",
    "Account Name": "Kontoname",
    'The display name of the user (e.g. "John Appleseed"). You also can use following variables: $username$, $firstname$, $lastname$':
        'Der Anzeigename des Benutzers (z. B. "John Appleseed"). Sie können zudem auch folgende Variablen verwenden: $username$, $firstname$, $lastname$',
    "Email Address": "E-Mail-Adresse",
    "Prevent Move": "Bewegen verhindern",
    "If set to true, messages may not be moved out of this email account into another account":
        'Wenn dieser Wert auf "true" gesetzt ist, ist es nicht möglich Nachrichten aus diesem E-Mail-Konto in ein anderes Konto zu verschieben',
    "Disable Mail Recents Syncing": "E-Mail-Empfänger-Synchronisierung deaktivieren",
    "If set to true, this account is excluded from address Recents syncing":
        'Wenn dieser Wert auf "true" gesetzt ist, wird dieses Konto von der Synchronisierung der letzten Adressen ausgeschlossen',
    "If set to true, this account is allowed to use Mail Drop":
        'Wenn dieser Wert auf "true" festgelegt ist, darf dieser Account "Mail Drop" verwenden',
    "If set to true, this account will not be available for sending mail in third party applications":
        'Wenn dieser Wert auf "true" gesetzt ist, ist dieses Konto nicht zum Senden von E-Mails in Anwendungen von Drittanbietern verfügbar.',
    "S/MIME Signing Enabled": "S/MIME Signierung aktiviert",
    "If set to true, this account will enable message signing":
        'Wenn dieser Wert auf "true" festgelegt ist, aktiviert dieses Konto die Signierung von Nachrichten',
    "S/MIME Encryption Enabled": "S/MIME Verschlüsselung aktiviert",
    "If set to true, this account will support message encryption":
        'Wenn dieser Wert auf "true" gesetzt ist, unterstützt dieses Konto die Nachrichtenverschlüsselung',
    "Hostname or IP Address": "Hostname oder IP-Adresse",
    "Port number for incoming email": "Portnummer für eingehende E-Mail",
    "Account Type": "Kontotyp",
    "The protocol for accessing the email account":
        "Das Protokoll für den Zugriff auf das E-Mail-Konto ",
    Username: "Benutzername",
    "The username used to connect to the server for incoming mail":
        "Der Benutzername, der für die Verbindung mit dem Server für eingehende E-Mails verwendet wird",
    "Path Prefix": "Pfadpräfix",
    "Path prefix for IMAP mail server": "Pfadpräfix für IMAP-E-Mailserver",
    "Incoming Mail Server Authentification": "Eingehende E-Mail-Server-Authentifizierung",
    "The authentication method for the incoming mail server":
        "Die Authentifizierungsmethode für den Posteingangsserver ",
    "The password for the incoming mail server": "Das Passwort für den Posteingangsserver",
    "Use SSL": "SSL verwenden",
    "Hostname or IP address for outgoing mail": "Hostname oder IP-Adresse für ausgehende E-Mail",
    "The username used to connect to the server for outgoing mail":
        "Der Benutzername, der für die Verbindung mit dem Server für ausgehende E-Mails verwendet wird.",
    "Authentication Type": "Authentifizierungsart",
    "The authentication method for the outgoing mail server":
        "Die Authentifizierungsmethode für den Postausgangsserver",
    "Outgoing Password Same As Incoming": "Ausgehendes Passwort: Gleich wie Eingehendes",
    "SMTP authentication uses the same password as POP/IMAP":
        "SMTP-Authentifizierung verwendet dasselbe Passwort wie POP/IMAP",
    "Mail Server": "E-Mail-Server",
    "S/MIME  Enabled": "S/MIME  aktivieren",
    "Allow Mail Drop": "Mail-Drop erlauben",
    "Prevent App Sheet": "App-Sheet verhindern",
    "Send mail": "E-Mail senden",
    "Send outgoing mail through Secure Socket Layer":
        "Senden Sie ausgehende E-Mails über Secure Socket Layer",
    "Allow App Removal": "Apps entfernen erlauben",
    "is not an allowed value.": "ist kein erlaubter Wert",
    "Allow Trusting Enterprise Apps": "Das Vertrauen von Enterprise-Apps erlauben",
    "Allow the user to trust enterprise apps":
        "Dem Benutzer erlauben Enterprise-Apps zu vertrauen.",
    "Allow Explicit Content": "Anstößige Inhalte erlauben",
    "Allow Screenshots and Screen Recording": "Screenshots und Screenaufzeichnung erlauben",
    "Allow the user to take screenshots or screen recordings":
        "Dem Benutzer erlauben Screenshots und Screenaufzeichnungen zu erstellen.",
    "Allow Remote Screen Observation": "Remote-Screen-Observation erlauben",
    "Allow Classroom or similar to observe the screen":
        "In einem Klassenraum o.ä. erlauben, den Bildschirm zu observieren.",
    "Allow iBookstore": "iBookstore erlauben",
    "Allow iBookstore Erotica": "Erotik im iBookstore erlauben",
    "Allow Apple Music": "Apple Music erlauben",
    "If set to false, Apple Music will be disabled in the Music app":
        "Wenn inaktiv, wird Apple Music in der Musik-App deaktiviert",
    "Allow iTunes Radio": "iTunes Radio erlauben",
    "If set to false, iTunes Radio will be disabled in the Music app":
        "Wenn inaktiv, wird iTunes Radio in der Musik-App deaktiviert",
    "Allow Shared Stream": "Shared-Stream erlauben",
    "If set to false, disabled Shared Stream": "Wenn inaktiv, wird Shared-Stream deaktiviert",
    "Allow Wallet While Locked": "Wallet erlauben, wenn Gerät gesperrt",
    "If set to false, Wallet notifications will not be shown on the lock screen":
        "Wenn inaktiv, werden Wallet-Informationen nicht auf dem Sperrbildschirm gezeigt",
    "Allow UI Configuration Profile Installation":
        "Installation von UI-Konfigurations-Profilen erlauben",
    "Allow use of iTunes": "iTunes-Nutzung erlauben",
    "Allow use of News": "News-Benutzung erlauben",
    "Allow the user to access and use News": "Dem Benutzer erlauben News zu benutzen.",
    "Allow use of Safari": "Safari-Benutzung erlauben.",
    "Allow Game Center": "Game-Center erlauben",
    "Allow Adding Game Center Friends": "Hinzufügen von Game-Center-Freunden erlauben",
    "Allow modifying Bluetooth settings": "Änderungen der Bluetooth-Einstellungen erlauben.",
    "Allow Modifying Cellular Data Usage for Apps Settings":
        "Änderung der mobilen Datennutzung für App-Einstellungen erlauben.",
    "Allow modifying cellular data usage for apps settings":
        "Änderung der mobilen Datennutzung für App-Einstellungen erlauben.",
    "Allow Modifying Device Name": "Änderung des Gerätenamens erlauben.",
    "Allow modifying device name": "Änderung des Gerätenamens erlauben.",
    "Allow Modifying Wallpaper": "Hintergrundbild ändern erlauben.",
    "Allow Configuring screen time": "Konfigurieren der Bildschirmzeit erlauben.",
    "Allow Automatic Sync While Roaming": "Automatisches Synchronisieren bei Roaming erlauben.",
    "Allow automatic sync while roaming": "Automatisches Synchronisieren bei Roaming erlauben.",
    "Allow iCloud Sync for Managed Apps": "iCloud-Synchronisation für Managed-Apps erlauben.",
    "Allow iCloud sync for managed apps": "iCloud-Synchronisation für Managed-Apps erlauben.",
    "Allow Enterprise Books Backup": "Enterprise-Bücher Sicherung erlauben.",
    "Allow enterprise books to be backed up": "Erlauben, dass Enterprise-Bücher gesichert werden.",
    "Allow Enterprise Books Notes and Highlights Sync":
        "Synchronisation von Enterprise-Büchern und Hervorhebungen erlauben.",
    "Allow enterprise books notes and highlights to be synced":
        "Synchronisation von Enterprise-Büchern und Hervorhebungen erlauben.",
    "Allow In App Purchases": "In-App-Käufe erlauben.",
    "Add Google Accounts": "Google Accounts hinzufügen",
    "Add Google accounts": "Google Accounts hinzufügen",
    "Allow the user to make purchases within applications":
        "Dem Käufer erlauben in Apps Käufe zu tätigen.",
    "Allow Multiplayer Gaming": "Multiplayer-Gaming erlauben.",
    "Allow multiplayer gaming": "Multiplayer-Gaming erlauben.",
    "Allow Voice Dialing While Device is Locked":
        "Sprachwahl erlauben, wenn das Gerät gesperrt ist.",
    "Allow voice dialing while device is locked":
        "Sprachwahl erlauben, wenn das Gerät gesperrt ist.",
    "Force Apple Watch Wrist Detection": "Apple Watch Handgelenkserkennung erzwingen.",
    "Force Apple Watch wrist detection": "Apple Watch Handgelenkserkennung erzwingen.",
    "Allow Pairing With Apple Watch": "Pairing mit Apple Watch erlauben.",
    "Allow pairing with Apple Watch": "Pairing mit Apple Watch erlauben.",
    "Allow Erase All Content and Settings": "Löschen aller Inhalte und Einstellungen erlauben",
    "Allow Internet results in Spotlight": "Internet Ergebnisse in Spotlight erlauben",
    "If set to false, search results from the web will not be shown in Spotlight":
        "Wenn inaktiv, werden keine Ergebnisse aus dem Internet bei Spotlight gezeigt",
    "Allow iCloud Document Sync": "iCloud Dokumentensynchronisation erlauben",
    "Allow user to accept untrusted TLS certificates":
        "Benutzer erlauben, nicht vertrauenswürdige Zertifikate bei TLS zu akzeptieren",
    "Allow Photo Stream": "Photo-Stream erlauben.",
    "Allow Photo Stream to be used on the device":
        "Die Benutzung von Photo-Stream auf dem Gerät erlauben.",
    "Allow iCloud Photo Library": "iCloud-Photo-Library erlauben.",
    "Allow iCloud Photo Library to be used on the device":
        "Die Nutzung der iCloud-Photo-Library auf dem Gerät erlauben",
    "Allow iClould Backup": "iCloud-Sicherungen erlauben.",
    "Allow backup using iCloud": "Sicherung mit der iCloud erlauben.",
    "Require iTunes password for all purchases": "iTunes Passwort für alle Käufe benötigt.",
    "Require the user\\'s iTunes password to be entered for every purchase":
        "Erfordert iTunes Passwort des Benutzers für alle Käufe.",
    "Apps Ranking Number": "Apps-Ranking-Nummer",
    "Ranking number for apps": "Ranking-Nummer für Apps",
    "Movies Ranking Number": "Movie-Ranking-Nummer",
    "Ranking number for movies": "Ranking-Nummer für Movies",
    "TV Shows Ranking Number": "TV-Serien-Ranking-Nummer",
    "Ranking number for TV Shows": "Ranking-Nummer für TV-Serien",
    "Region Code": "Regionscode",
    "Two-character code for the region used to specify ratings":
        "2-Buchstaben-Code für Regionen die in bestimmten Ratings benutzt werden.",
    "Accept Cookies in Safari": "Cookies in Safari akzeptiert",
    "Possibilities to set cookie acceptance in Safari":
        "Möglichkeiten zur Einstellung der Cookie Akzeptanz in Safari",
    "Accept cookies: 0 - Never, 1 - From current website only (iOS 8) or visited sites (pre-iOS 8), 1.5 - From websites I visit, 2 - Always":
        "Cookies akzeptieren: 0 - Nie, 1 - Nur von aktueller Webseite (iOS 8) oder besuchten Seiten (pre-iOS 8), 1.5 - Webseiten die ich besucht habe, 2 - Immer",
    "From current website only (iOS 8) or visited sites (pre-iOS 8)":
        "Nur von aktueller Webseite (iOS 8) oder besuchten Seiten (pre-iOS 8)",
    "From websites I visit": "Webseiten die ich besucht habe",
    "Allow JavaScript": "JavaScript erlauben.",
    "Allow JavaScript in Safari": "JavaScript in Safari erlauben.",
    "Allow Pop-ups": "Pop-ups erlauben.",
    "Allow Pop-ups in Safari": "Pop-ups in Safari erlauben.",
    "Enable Fraud Warning": "Betrugswarnung aktivieren.",
    "Enable fraud warning in Safari": "Aktiviert die Betrugswarnung in Safari.",
    "Allow Predictive Keyboard": "Vorhersagende Tastatur erlauben.",
    "Allow Keyboard Shortcuts": "Tastatur Shortcuts erlauben.",
    "Allow Auto Correction": "Autokorrektur erlauben.",
    "Allow Spell Check": "Korrekturhilfe erlauben.",
    "Allow Define": "Definition erlauben.",
    "Enable allow open from unmanaged to managed":
        "Nicht verwaltete Dokumente in verwalteten Apps erlauben.",
    "Enable allow open from managed to unmanaged":
        "Verwaltete Dokumente in nicht verwalteten Apps erlauben.",
    "Treat AirDrop as Unmanaged Destination": "AirDrop als nicht verwaltetes Ziel behandeln.",
    "Allow Handoff": "Handoff erlauben.",
    "If set to false, Handoff will be disabled": "Wenn inaktiv, wird Handoff deaktiviert",
    "Allow Touch ID/Face ID to Unlock Device": "Touch ID/Face ID zum Entsperren erlauben.",
    "Allow Modifying Notifications Settings": "Ändern der Benachrichtigungseinstellungen erlauben.",
    "Allow incoming AirPlay requests": "Eingehende AirPlay-Anfrage erlauben.",
    "Allow pairing with Remote app": "Pairing mit Remote-App erlauben.",
    "Allow dictation": "Diktat erlauben.",
    "Allow Camera Use": "Kameranutzung erlauben.",
    "Allow the user to use the camera": "Dem Benutzer erlauben die Kamera zu benutzen.",
    "Allow Video Conferencing": "Video-Konferenz erlauben.",
    "Allow Siri": "Siri erlauben.",
    "Allow Siri While Locked": "Siri während der Sperre erlauben.",
    "Allow Siri while device is locked": "Siri erlauben während das Gerät gesperrt ist.",
    "Enable Siri Profanity Filter": "Siri Obszönitätsfilter aktivieren.",
    "Allow App Installation from Apple Configurator and iTunes":
        "App-Installation vom Apple Configurator und iTunes erlauben.",
    "Allow Automatic App Downloads": "Automatische App-Downloads erlauben.",
    "Allow App Installation from App Store": "App-Installation aus dem App-Store erlauben.",
    "Allow Modifying Passcode": "Ändern des Passcodes erlauben.",
    "Allow Modifying Touch ID/Face ID": "Das Ändern der Touch ID/Face ID erlauben.",
    "Allow modifying Touch ID/Face ID": "Das Ändern der Touch ID/Face ID erlauben.",
    "Allow diagnostic submission": "Diagnoseübermittlung erlauben.",
    "Send diagnostic and usage stats to Apple": "Diagnose- und Benutzungsdaten an Apple senden.",
    "Allow modifying diagnostics settings": "Ändern der Diagnoseeinstellungen erlauben.",
    "After setting this, you can set Passcode policies.":
        "Nachdem Sie dies aktiviert haben, können Sie Passcode-Richtlinien festlegen.",
    "Permit the use of repeating, ascending, and descending character sequences":
        "Die Verwendung von sich wiederholenden, aufsteigenden und absteigenden Zeichenfolgen erlauben.",
    "Enforce the use of a passcode before using the device":
        "Erzwingen Sie die Verwendung eines Passcodes",
    "Number of passcode entry attempts allowed before all data on device will be erased":
        "Anzahl der zulässigen Eingabeversuche, bevor alle Daten auf dem Gerät gelöscht werden.",
    "The number of minutes for which the device can be idle (without being unlocked by the user) before it gets locked by the system":
        "Die Anzahl an Minuten, für die das Gerät inaktiv sein kann (ohne vom Benutzer entsperrt zu werden), bevor es vom System gesperrt wird.",
    "The number of days for which the passcode can remain unchanged":
        "Die Anzahl der Tage, für die der Passcode unverändert bleiben darf",
    "Smallest number of non-alphanumeric characters allowed":
        "Kleinste Anzahl erlaubter nicht-alphanumerischer Zeichen",
    "Smallest allowable number of characters in passcode":
        "Kleinste zulässige Anzahl von Zeichen im Passcode",
    "Require passcodes to contain at least one letter":
        "Passwörter müssen mindestens einen Buchstaben enthalten",
    "Enables you to set the number of unique passcodes required between passcode reuse":
        "Das Definieren der Anzahl an unterschiedlichen Passcodes, die zwischen der Wiederverwendung von Passcodes erforderlich sind, erlauben.",
    "Number of unique passcodes required between passcode reuse":
        "Anzahl an unterschiedlichen Passcodes, die zwischen der Wiederverwendung von Passcodes erforderlich sind.",
    "Enables you to set the maximum grace period, in minutes, to unlock the phone without entering a passcode":
        "Das Definieren der maximalen Zeit in Minuten, um das Gerät zu entsperren, erlauben.",
    "The maximum grace period, in minutes, to unlock the phone without entering a passcode":
        "Die maximale Zeit in Minuten, um das Gerät zu entsperren.",
    "Activate Passcode": "Passcode aktivieren",
    "Allow Simple Value": "Einfache Passcodes erlauben",
    "Require Passcode on Device": "Passcode auf dem Gerät anfordern",
    "Set maximum number of failed attempts": "Maximale Anzahl der Fehlversuche setzen",
    "Maximum Number of Failed Attempts": "Maximale Anzahl fehlgeschlagener Versuche",
    "Set auto-lock": "Aktiviere automatische Sperre",
    "Auto-Lock": "Automatische Sperre",
    "Set maximum passcode age": "Maximales Passcode-Alter setzen",
    "Maximum passcode age": "Maximales Passcode-Alter",
    "Allows you to restrict the password complexity":
        "Erlaubt das Beschränken der Passwortkomplexität",
    "Restrict password complexity": "Passwortkomplexität beschränken",
    "Minimum Number of Complex Characters": "Mindestanzahl komplexer Zeichen",
    "Minimum Passcode Length": "Mindestlänge des Passcodes",
    "Require Alphabetic Value": "Bedingt einen alphabetischen Wert",
    "Use Passcode History": "Passwort-Verlauf verwenden",
    "Passcode History": "Passwort-Verlauf",
    "Use grace period for device lock": "Zeitraum für das Entsperren definieren",
    "Grace period for device lock": "Zeitraum für das Entsperren",
    "No results found": "Keine Einträge gefunden",
    "No restrictions": "Keine Einschränkungen",
    "No enrollment": "Kein Enrollment",
    "No enrollment, no VPN": "Kein Enrollment, kein VPN",
    "Activate Security": "Sicherheit aktivieren",
    "Portfilter Type": "Portfilter-Typ",
    closed: "Geschlossen",
    open: "Offen",
    selection: "Auswahl",
    Always: "Immer",
    Never: "Niemals",
    "Defines whether or not to intercept SSL traffic. The default value is to intercept traffic based on content filter response":
        "Definiert, ob SSL-Datenverkehr abgefangen wird oder nicht. Der Standardwert ist das Abfangen von Datenverkehr basierend auf der Antwort des Content-Filters",
    "Defines whether or not to intercept SSL traffic. The default value is to intercept traffic based on content filter response.":
        "Definiert, ob SSL-Datenverkehr abgefangen wird oder nicht. Der Standardwert ist das Abfangen von Datenverkehr basierend auf der Antwort des Content-Filters.",
    "Contentfilter Allowlist": "Content-Filter Allowlist",
    "Contentfilter Blocklist": "Content-Filter Blocklist",
    "Disable VPN for SSIDs": "VPN für SSIDs deaktivieren",
    "Enter WLAN SSIDs for which the security features shall be disabled":
        "Geben Sie WLAN-SSIDs ein, für die die Sicherheitsfunktionen deaktiviert werden sollen",
    "Exclude IP addresses from VPN": "IP-Adressen von VPN ausschließen",
    "Disable VPN for IP addresses": "VPN für IP-Adressen deaktivieren",
    "Enter IP addresses or networks which shall bypass the VPN service. i.e. single host 222.222.222.222/32 or whole subnetwork 123.123.123.0/24. Use the cursor buttons to navigate inside the mask":
        "Geben Sie IP-Adressen oder Netzwerke ein, für die die Sicherheitsfunktionen umgangen werden sollen, d.h. Der einzelne Host 222.222.222.222/32 oder das gesamte Subnetz 123.123.123.0/24. Verwenden Sie die Cursortasten, um innerhalb der Maske zu navigieren",
    "Enter IP addresses or networks which shall bypass the VPN service. i.e. single host 222.222.222.222/32 or whole subnetwork 123.123.123.0/24. Use the cursor buttons to navigate inside the mask.":
        "Geben Sie IP-Adressen oder Netzwerke ein, für die die Sicherheitsfunktionen umgangen werden sollen, d.h. Der einzelne Host 222.222.222.222/32 oder das gesamte Subnetz 123.123.123.0/24. Verwenden Sie die Cursortasten, um innerhalb der Maske zu navigieren",
    "Activate Custom Profile": "Aktiviere benutzerdefiniertes Profil",
    "After setting this, you can append a custom mobile configs.":
        "Nachdem Sie dies festgelegt haben, können Sie eine benutzerdefinierte mobile Konfiguration hinzufügen.",
    "Activate certificates": "Aktiviere Zertifikate",
    "The ID of the customer this configuration will be assigned to":
        "Die Kunden-ID, welcher diese Konfiguration zugewiesen wird",
    "After setting this, you can set certificates":
        "Nachdem Sie dies festgelegt haben, können Sie Zertifikate hinzufügen",
    "Input data here": "Daten hier eingeben",
    "Website categories that should be allowed": "Webseiten, welche erlaubt sein sollen",
    "Website categories that should be allowed.": "Webseiten, welche erlaubt sein sollen.",
    "Website categories that should be blocked": "Webseiten, welche blockiert werden sollen",
    "Website categories that should be blocked.": "Webseiten, welche blockiert werden sollen.",
    "Allows the user to temporary disable the VPN-Connection. If not activated manually, the VPN will resume at a time chosen by the user":
        "Erlaubt es dem Benutzer das VPN vorübergehend zu deaktivieren. Wenn der Benutzer es nicht selbst wieder aktiviert, geschieht dies um die vom Benutzer gewählte Uhrzeit",
    "Enable or disable the security settings.":
        "Ein- oder ausschalten der Sicherheitseinstellungen.",
    "Activate Restrictions": "Einschränkungen aktivieren",
    "After setting this, you can set Restrictions": "Ermöglicht das Setzen von Einschränkungen.",
    "Enable Camera Restrictions": "Kamera Einschränkungen aktivieren",
    "After setting this, no applications will be able to access any cameras on the device.":
        "Nach Aktivierung kann keine App mehr auf die Kamera des Gerätes zugreifen",
    "Enable Storage Encryption": "Speicherverschlüsselung aktivieren",
    "This profile controls encryption of the secure (application data) storage area. Data written to other storage areas may or may not be encrypted, and this profile does not require or control the encryption of any other storage areas. Important Note: On some devices, it is possible to encrypt storage without requiring the user to create a device PIN or Password. In this case, the storage is encrypted, but the encryption key may not be fully secured. For maximum security, the administrator should also require a password.":
        "Diese Option kontrolliert die Verschlüsselung des sicheren Speicherortes (Anwendungsdaten). Daten, welche an andere Speicherorte geschrieben werden, können, müssen aber nicht, verschlüsselt sein, und diese Option kontrolliert nicht die Verschlüsselung von anderen Speicherorten. Wichtiger Hinweis: Auf manchen Geräten ist es möglich die Verschlüsselung zu aktivieren ohne einen Pin oder ein Passwort zu konfigurieren. In diesem Fall sind die Anwendungsdaten verschlüsselt, der Verschlüsselungsschlüssel kann aber auslesbar sein. Um maximale Sicherheit zu gewährleisten sollte immer ein Passwort konfiguriert werden.",
    "Enable WIFI Restrictions": "WLAN Einschränkungen aktivieren",
    "After setting this, WIFI will be disabled permanently.":
        "Nach Aktivierung kann das WLAN dauerhaft nicht genutzt werden.",
    "Enable Bluetooth Restrictions": "Bluetooth Einschränkungen aktivieren",
    "After setting this, Bluetooth will be disabled permanently.":
        "Nach Aktivierung kann Bluetooth dauerhaft nicht genutzt werden.",
    "Minimum Password Length": "Minimale Passwortlänge",
    "After setting this, the user will not be able to enter a new password that is not at least as restrictive as what has been set. Note that the current password will remain until the user has set a new one, so the change does not take place immediately.":
        "Nach Aktivierung kann der User sein Passwort nur anpassen, wenn es den Vorgaben entspricht. Bitte beachten Sie, dass das aktuelle Passwort bestehen bleibt, bis der User ein neues setzt, sodass die Änderung nicht sofort in Kraft tritt.",
    "Password Quality": "Passwortqualität",
    "After setting this, the user will not be able to enter a new password that is not at least as restrictive as what has been set. Note that the current password will remain until the user has set a new one, so the change does not take place immediately. Quality constants are ordered so that higher values are more restrictive; thus the highest requested quality constant (between the profile set here, the user's preference, and any other considerations) is the one that is in effect.":
        "Nach Aktivierung kann der User sein Passwort nur anpassen, wenn es den Vorgaben entspricht.Bitte beachten Sie, dass das aktuelle Passwort bestehen bleibt, bis   der User ein neues setzt, sodass die Änderung nicht sofort in Kraft tritt. Qualitätskonstanten werden so angeordnet, dass höhere Werte restriktiver sind; sodass die höchste gesetzte Qualitätskonstante (zwischen Profil, Benutzereinstellungen und Anderen), die ist, welche genutzt wird.",
    "Maximum Failed Passwords For Wipe": "Maximale Anzahl Versuche bevor Löschung",
    "Setting this to a value greater than zero enables a built-in profile that will perform a device wipe after too many incorrect device-unlock passwords have been entered.":
        "Wenn hier ein Wert größer Null gesetzt wird, wird nach einer entsprechenden Anzahl Fehlversuche beim Entsperren das Gerät gelöscht.",
    "<deleted>": "<gelöscht>",
    "&lt;deleted&gt;": "&lt;gelöscht&gt;",
    Copy: "Kopieren",
    "Please choose": "Bitte wählen",
    "Action for selected items": "Aktion für ausgewählte Objekte",
    "The name of the App": "Der Name der App",
    "The name that will be displayed in the portal.": "Der Name der im Portal angezeigt wird",
    "The name of the Web Clip as displayed on the Home screen.":
        "Der Name des Webclips, wie er auf dem Home-Bildschirm angezeigt wird.",
    "App platform": "Plattform der App",
    "The app will be assigned to these devices": "Die App wird diesen Geräten zugewiesen",
    "The app will be assigned to all devices from these users":
        "Die App wird allen Geräten von diesen Benutzern zugewiesen",
    "The app will be assigned to all devices of all users with these roles":
        "Die App wird allen Geräten von allen Benutzern mit diesen Rollen zugewiesen",
    "View app": "App anzeigen",
    Uninstall: "Deinstallieren",
    Icon: "Symbol",
    Folders: "Ordner",
    "The app will be assigned to all devices with these tags":
        "Die App wird allen Geräten mit diesen Tags zugewiesen",
    "Choose an icon for this app": "Wählen Sie ein Symbol für diese App",
    "Describe the app": "Beschreiben Sie die App",
    Source: "Quelle",
    Send: "Gesendet",
    Sent: "Gesendet",
    "App source": "App-Quelle",
    Identifier: "Identifikator",
    identifier: "identifikator",
    "Enter the identifier. It should look like this: com.manufacturer.appname":
        "Geben Sie den Identifikator ein. Es sollte so aussehen: com.manufacturer.appname",
    "Enter the identifier (Or the name of the app)":
        "Geben Sie den Identifikator ein (Oder den Namen der App)",
    "(Or the name of the app)": "(Oder den Namen der App)",
    "Enter the itunes store ID": "Geben Sie die iTunes Store-ID ein ",
    "Enter the manifest URL": "Geben Sie die Manifest-URL ein",
    "Remove with profile": "Entfernen mit Profil",
    "Remove app when MDM profile is removed": "App löschen, wenn das MDM-Profil gelöscht wurde",
    "No backups": "Keine Sicherungen",
    "Prevent backups of the app data": "Verhindern Sie Sicherungen der App-Daten",
    "Take management": "Management übernehmen",
    "Set the app as managed or take management if the user has installed it already. The managed state is necessary to uninstall the app from remote.":
        "Aktivieren Sie den Betreuten-Zustand für die App oder übernehmen sie die Betreuung falls die App bereits installiert ist. Der Betreute-Zustand ist notwendig um die App aus der Ferne zu löschen.",
    "Do you really want to delete this application?": "Möchten Sie diese App wirklich löschen?",
    "Do you really want to delete this home screen layout?":
        "Möchten Sie dieses Homescreenlayout wirklich löschen?",
    "Do you really want to delete this device?": "Möchten Sie dieses Gerät wirklich löschen?",
    "Do you really want to delete this certificate?":
        "Möchten Sie dieses Zertifikat wirklich löschen?",
    "Delete without uninstall": "Entfernen ohne Deinstallation",
    "Uninstall and delete": "Deinstallieren und löschen",
    "No app with affected devices.": "Keine zugewiesende Geräte für die App",
    "You can search for package names in Google Play Store. For example: https://play.google.com/store/apps/details?id=de.blinkt.openvpn . The package name for OpenVPN for Android is de.blinkt.openvpn . You can copy the whole URL into the field and the application will strip the package name automatically.":
        "Sie können im Google Play Store nach Paketnamen suchen. Zum Beispiel: https://play.google.com/store/apps/details?id=de.blinkt.openvpn . Der Paketname für OpenVPN für Android ist de.blinkt.openvpn . Sie können die komplette URL in das Feld kopieren und die Anwendung wird den Paketnamen automatisch extrahieren.",
    "Insert URL for selfhosted APK.": "URL für ein selbst gehostetes APK.",
    "Choose an icon for this app (JPEG or PNG)":
        "Wählen Sie ein Icon für diese App (JPEG oder PNG)",
    "Once the install-process has started, you can close this window. The apps will still be installed.":
        "Sobald der Installationsprozess gestartet wurde, können sie dieses Fenster schließen. Die Apps werden weiterhin installiert.",
    "Once the uninstall-process has started, you can close this window. The apps will still be uninstalled.":
        "Sobald der Deinstallationsprozess gestartet wurde, können sie dieses Fenster schließen. Die Apps werden weiterhin deinstalliert.",
    "Install application": "App installieren",
    "Uninstall application": "App deinstallieren",
    "Edit app": "App bearbeiten",
    "Interface settings": "Anzeigeeinstellungen",
    Darkmode: "Dunkler Modus",
    "Import app": "App importieren",
    "Remove icon": "Icon entfernen",
    "Add new User": "Neuen Benutzer hinzufügen",
    "Add new user": "Neuen Benutzer hinzufügen",
    "View user": "Benutzer anzeigen",
    "Confirm password": "Passwort bestätigen",
    "Edit user": "Benutzer bearbeiten",
    "Edit users": "Benutzer bearbeiten",
    "Do you want to delete user %s?": "Möchten Sie den Benutzer %s löschen?",
    "You are currently logged in with this user and will be logged out after deleting!\n":
        "Sie sind aktuell mit dem Benutzer angemeldet und werden nach dem Löschen abgemeldet!\n",
    "Assign roles to this user": "Weisen Sie diesem Benutzer Rollen zu.",
    "Add new role": "Neue Rolle hinzufügen",
    "Delete role": "Rolle löschen",
    "Do you want to delete role %s?": "Möchten Sie die Rolle %s löschen?",
    "If you delete the role admin, only users with Securepoint Reseller Portal accounts are able to login!":
        "Wenn Sie die Rolle admin löschen, können sich nur Benutzer mit einem Securepoint Reseller-Portal-Konto anmelden!",
    "View role": "Rolle anzeigen",
    "Edit Role": "Rolle bearbeiten",
    "Add Role": "Rolle hinzufügen",
    "Add new certificate": "Neues Zertifikat hinzufügen",
    File: "Datei",
    "View certificate": "Zertifikat anzeigen",
    Organization: "Unternehmen",
    State: "Bundesland",
    "Not After": "Gültig bis",
    "Not Before": "Gültig ab",
    "Show certificate": "Zertifikat anzeigen",
    Add: "Hinzufügen",
    "Add certificate": "Zertifikat hinzufügen",
    "Add Certificate": "Zertifikat hinzufügen",
    "Upload a file": "Datei hochladen",
    "Do you want to delete certificate %s?": "Möchten Sie das Zertifikat %s löschen?",
    Unit: "Abteilung",
    "Other devices (VPN)": "Andere Geräte (VPN)",
    "Other device (VPN)": "Anderes Gerät (VPN)",
    "Add new device": "Gerät hinzufügen",
    "Add device": "Gerät hinzufügen",
    "IP addresses #": "IP-Adressen #",
    "Allowlist #": "Allowlist #",
    "Blocklist #": "Blocklist #",
    "Portfilter Rules Selection": "Portfilter-Regelauswahl",
    "The name of the device": "Der Name des Geräts",
    "SSL Bump Mode": "SSL-Bump-Modus",
    "SSL interception": "SSL-Interception",
    "SSL Interception": "SSL-Interception",
    Open: "Offen",
    Closed: "Geschlossen",
    Selection: "Auswahl",
    Protocol: "Protokoll",
    "Protocol used for VPN tunnel": "Das Protokoll, das für den VPN Tunnel verwendet wird",
    "Protocol used for VPN tunnel. If the protocol changes, the configuration-file has to be updated too.":
        "Das Protokoll, das für den VPN Tunnel verwendet wird. Wenn das Protokoll geändert wird, muss die Konfigurationsdatei ebenfalls aktualisiert werden.",
    "If the protocol changes, the configuration-file has to be updated too.":
        "Wenn das Protokoll geändert wird, muss die Konfigurationsdatei neu aufgespielt werden.",
    "Last VPN login on UTM": "Letzter VPN-Login auf UTM",
    "View device": "Gerät anzeigen",
    Bumpmode: "Bump-Modus",
    "Edit device": "Gerät bearbeiten",
    "Edit alias": "Alias bearbeiten",
    "Edit role": "Rolle bearbeiten",
    "Assign alias to device": "Alias zuweisen",
    "Edit tags": "Tags bearbeiten",
    "Download config": "Konfiguration herunterladen",
    "Download CSV": "CSV herunterladen",
    "The following roles could not be found and therefore were not assigned:":
        "Die folgenden Rollen konnten nicht gefunden werden und wurden daher nicht zugewiesen:",
    "Failed to assign user roles": "Zuweisung von Benutzerrollen fehlgeschlagen",
    "Download ca.pem": "CA.pem herunterladen",
    "Do you want to delete vpn %s?": "Möchten Sie das VPN %s löschen?",
    "Import configuration": "Konfiguration importieren",
    Statistics: "Statistiken",
    Period: "Zeitraum",
    Today: "Heute",
    Yesterday: "Gestern",
    "Current Week": "Diese Woche",
    "Current Month": "Dieser Monat",
    "Current week": "Diese Woche",
    "Current month": "Dieser Monat",
    "Virus found": "Viren gefunden",
    "Last 30 days": "Letzte 30 Tage",
    "Last 90 days": "Letzte 90 Tage",
    "Hits total": "Anfragen gesamt",
    "Requests total": "Anfragen gesamt",
    "Devices used": "Benutzte Geräte",
    Hits: "Anfragen",
    "Blocked domains": "Blockierte Domains",
    "Permitted requests": "Erlaubte Anfragen",
    "Blocked requests": "Blockierte Anfragen",
    "Most used devices": "Meistverwendete Geräte",
    Percentage: "Prozent",
    "No devices found": "Keine Geräte gefunden",
    "No %s found": "Keine %s gefunden",
    Position: "Position",
    "Most visited domains": "Häufigste besuchte Domänen",
    "Most blocked domains": "Häufigste blockierte Domänen",
    "Most visited categories": "Meistbesuchte Kategorien",
    "Most blocked categories": "Meistblockierte Kategorien",
    "No hits found": "Keine Treffer gefunden",
    "Stats dashboard": "Statistiken-Dashboard",
    "Search results": "Suchergebnisse",
    "Search results for:": "Suchergebnisse für:",
    " Stats search": "Statistikensuche",
    "Stats search": "Statistikensuche",
    "e.g. securepoint.de": "z.B. securepoint.de",
    All: "Alle",
    Type: "Typ",
    Key: "Schlüssel",
    "Anonymized device": "Pseudonymisiertes Gerät",
    "Alerts total": "Warnungen gesamt",
    Locked: "Gesperrt",
    Domain: "Domäne",
    Reason: "Begründung",
    Alert: "Warnung",
    "Top alerts": "Top Warnungen",
    "Top virus": "Top Viren",
    Viruses: "Viren",
    Deanonymize: "Depseudonymisieren",
    "Deanonymize device": "Gerät depseudonymisieren",
    "Auditor password": "Auditor Passwort",
    "No alerts found": "Keine Warnung gefunden",
    "Switch to grid-view": "Zur Rasteransicht wechseln",
    "Switch to list-view": "Zur Listenansicht wechseln",
    "Traffic per domain": "Traffic pro Domäne",
    "Traffic per category": "Traffic pro Kategorie",
    "Total traffic": "Traffic gesamt",
    "(GMT) Western Europe Time": "(GMT) Westeuropäische Zeit",
    "(GMT +1:00) Central European Time": "(GMT +1:00) Mitteleuropäische Zeit",
    "(GMT +2:00) Central European Summer Time": "(GMT +2:00) Mitteleuropäische Zeit(Sommerzeit)",
    "(GMT +2:00) Eastern European Time": "(GMT +2:00) Osteuropäische Zeit",
    "(GMT -12:00) Eniwetok, Kwajalein": "(GMT -12:00) Eniwetok, Kwajalein",
    "(GMT -11:00) Midway Island, Samoa": "(GMT -11:00) Midway Inseln, Samoa",
    "(GMT -10:00) Hawaii": "(GMT -10:00) Hawaii",
    "(GMT -9:00) Alaska": "(GMT -9:00) Alaska",
    "(GMT -8:00) Pacific Time (US &amp; Canada)": "(GMT -8:00) Pacific Time (US &amp; Canada)",
    "(GMT -7:00) Mountain Time (US &amp; Canada)": "(GMT -7:00) Mountain Time (US &amp; Canada)",
    "(GMT -6:00) Central Time (US &amp; Canada), Mexico City":
        "(GMT -6:00) Central Time (US &amp; Canada), Mexico City",
    "(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima":
        "(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima",
    "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz":
        "(GMT -4:00) Atlantic Standard Time (Canada), Caracas, La Paz",
    "(GMT -3:00) Brazil, Buenos Aires, Georgetown": "(GMT -3:00) Brazil, Buenos Aires, Georgetown",
    "(GMT -2:00) Mid-Atlantic": "(GMT -2:00) Mittel Atlantik",
    "(GMT -1:00) Azores": "(GMT -1:00) Azoren",
    "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg":
        "(GMT +3:00) Bagdad, Riyadh, Moskau, St. Petersburg",
    "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi": "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi",
    "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent":
        "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
    "(GMT +6:00) Almaty, Dhaka, Colombo": "(GMT +6:00) Almaty, Dhaka, Colombo",
    "(GMT +7:00) Bangkok, Hanoi, Jakarta": "(GMT +7:00) Bangkok, Hanoi, Jakarta",
    "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong":
        "(GMT +8:00) Peking, Perth, Singapore, Hong Kong",
    "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk":
        "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
    "(GMT +10:00) Eastern Australia, Guam, Vladivostok":
        "(GMT +10:00) Ostaustralische Zeit, Guam, Vladivostok",
    "(GMT +11:00) Magadan, Solomon Islands, New Caledonia":
        "(GMT +11:00) Magadan, Salomonen, Neukaledonien",
    "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka":
        "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
    "(GMT +13:00) Apia, Nukualofa": "(GMT +13:00) Apia, Nukuʻalofa",
    "(GMT +14:00) Line Islands, Tokelau": "(GMT +14:00) Zentralpolynesische Sporaden, Tokelau",
    "Not set": "Nicht gesetzt",
    "Add alert": "Warnung hinzufügen",
    Notifications: "Benachrichtigungen",
    Immediately: "Sofort",
    Time: "Zeit",
    Count: "Anzahl",
    "The name of the alert": "Der Name der Warnung",
    "State of the alert": "Status der Warnung",
    "Send an email notification if the alert is triggered":
        "Eine E-Mail senden, wenn die Warnung ausgelöst wird",
    "A short description": "Eine kurze Beschreibung",
    "Send an email": "Eine E-Mail senden",
    Condition: "Bedingung",
    Advanced: "Erweitert",
    "Edit Alert": "Warnung bearbeiten",
    "View alert": "Warnung anzeigen",
    "Only use messages from this source": "Nur Nachrichten von dieser Quelle",
    "Filter on this attribute": "Auf diesem Attribut filtern",
    "Filter on": "Filtern auf",
    "The operator of the filter": "Der Operator für den Filter",
    Equal: "Gleich",
    "Not equal": "Ungleich",
    Notify: "Benachrichtigen",
    "Notify immediately, on a count or in a period":
        "Sofort benachrichtigen, nach einer Anzahl oder nach einem Zeitraum",
    "Notify after a period": "Nach einem Zeitraum benachrichtigen",
    "Emit alert after a period": "Warnung nach einem Zeitraum auslösen",
    Emit: "Auslösen",
    "Please enter a number": "Bitte geben Sie eine Zahl an",
    "Lock a new alert": "Neue Warnungen sperren",
    "Lock alert": "Warnung sperren",
    "Lock period": "Sperrzeitraum",
    "Lock alerting for this period": "Warnungen für diesen Zeitraum sperren",
    "5 Minutes": "5 Minuten",
    "15 Minutes": "15 Minuten",
    "30 Minutes": "30 Minuten",
    Minutes: "Minuten",
    Hour: "Stunde",
    "1 Hour": "1 Stunde",
    "6 Hours": "6 Stunden",
    "12 Hours": "12 Stunden",
    Throttle: "Drosseln",
    Threshold: "Schwellenwert",
    "Trottle alerts for this period": "Warnungen für diesen Zeitraum drosseln",
    "Event threshold to trigger the alert": "Ereignisschwellenwert um die Warnung auszulösen",
    "Delete alert": "Warnung löschen",
    "Edit alert": "Warnung bearbeiten",
    Value: "Wert",
    Path: "Pfad",
    "No options found": "Keine Optionen gefunden",
    "The value to match": "Wenn dieser Wert gefunden wird, wird die Warnung ausgelöst",
    "Description:": "Beschreibung:",
    "Do you want to delete alert %s?": "Möchten Sie die Warnung %s löschen?",
    "Privacy mode": "Privatsphären-Modus",
    "Which users get a notification if the alert will be triggered":
        "Welche Benutzer sollen eine E-Mail-Benachrichtigung bekommen, wenn die Warnung ausgelöst wird?",
    "Lock alerting for this period. If this setting is set to true, no alert will be emitted in this period. The alert will be only updated internally(increment a count), after the lock period has exceeded. The internal count will be visible, in the lock column, after the next alert occurs.":
        'Warnungen für diesen Zeitraum sperren. Wenn die Einstellung auf "true" gesetzt ist, werden keine weiteren Warnungen für diesen Zeitraum ausgelöst. Die Warnungen werden nur intern aktualisiert (Ein Zähler wird hochgezählt). Wenn der Sperrzeitraum überschritten ist, wird der interne Zähler bei der nächsten Warnung in der Spalte "Gesperrt" sichtbar.',
    Network: "Netzwerk",
    "The name of the configuration": "Der Name der Konfiguration",
    "The configuration type": "Der Konfigurationstyp",
    "The SSID of the network": "Die SSID des Netzwerks",
    None: "Keine",
    "Select the security level": "Wählen Sie die Sicherheitsstufe",
    Passphrase: "Passwort",
    "The networks passphrases": "Das Netzwerk-Passwort",
    "Hidden SSID": "Versteckte SSID",
    "Indicating if the SSID will be broadcast": "Legt fest ob die SSID versteckt ist",
    Autoconnect: "Automatisch verbinden",
    Keyword: "Stichwort",
    List: "Liste",
    "Domain all": "Alle Domänen",
    "Domain visited": "Besuchte Domänen",
    "Domain blocked": "Blockierte Domänen",
    Category: "Kategorie",
    Requests: "Anfragen",
    Request: "Anfrage",
    Visited: "Besucht",
    Blcoked: "Blcokiert",
    "Account name": "Kontoname",
    "Customer No.": "Kunden-Nr.",
    "Tenant domain": "Kundendomäne",
    Street: "Straße",
    "Postal code": "Postleitzahl",
    "Postal-Code": "Postleitzahl",
    City: "Stadt",
    Country: "Land",
    Timezone: "Zeitzone",
    "Apple Push Certificate expiry": "Apple Push Zertifikat Ablaufdatum",
    "Apple Push Certificate generation": "Apple Push Zertifikat Generierung",
    "Update Apple push certificate": "Apple Push Zertifikat aktualisieren",
    "Renew Apple push certificate": "Apple Push Zertifikat erneuern",
    "Renew Apple Push certificate": "Apple Push Zertifikat erneuern",
    "Renew Apple Push Certificate": "Apple Push Zertifikat erneuern",
    "Your Apple push certificate will expire in": "Ablauf des Apple Push Zertifikats in",
    "day(s)": "Tag(en)",
    "You have to update your Apple push cetificate":
        "Sie müssen Ihr Apple Push Zertifikat aktualisieren",
    "Wrong Apple Push Certificate": "Falsches Apple Push Zertifikat",
    "It seems that you have not uploaded the correct Apple Push Certificate.":
        "Es scheint als hätten Sie nicht das richtige Apple Push Zertifikat hochgeladen.",
    "Your Apple Push Certificate will expire in less than 14 days. Please extend your Apple Push Certificate.":
        "Ihr Apple Push Zertifikat wird in weniger als 14 Tagen ablaufen. Bitte verlängern Sie Ihr Apple Push Zertifikat.",
    "Your Apple push cetificate needs to be updated.":
        "Ihr Apple Push Zertifikat muss aktualisiert werden.",
    "Your Apple Push Certificate needs to be extended.":
        "Ihr Apple Push Zertifikat muss verlängert werden.",
    "Apple Push Certificate needs to be extended": "Apple Push Zertifikat muss verlängert werden",
    'IMPORTANT: Please use the "Renew" function in Apple\\\'s Push Certificate Portal to extend your existing certificate.':
        'WICHTIG: Bitte nutzen Sie die "Erneuern" Funktion in Apples Push Certificate Portal um Ihr bestehendes Zertifikat zu verlängern.',
    "Set up Android Enterprise or your Apple push certificate":
        "Richten Sie Android Enterprise oder Ihr Apple Push Zertifikat ein",
    "Go to Mobile Security settings in order to set up either one of them.":
        "Öffnen Sie die Mobile Security Einstellungen um eines von beidem einzurichten.",
    "Apple DEP-Token expiry": "Apple DEP-Token Ablaufdatum",
    "Apple VPP-Token expiry": "Apple VPP-Token Ablaufdatum",
    "Update DEP-Token": "DEP-Token aktualisieren",
    "Apple Device Enrollment Program": "Apple Device Enrollment Programm",
    "Follow these steps to connect to the Apple Device Enrollment Programm":
        "Folgen Sie diesen Schritten um sich mit dem Apple Device Enrollment Programm zu verbinden",
    "Download your Apple Push Certificate": "Laden Sie Ihr Apple Push Zertifikat herunter",
    "Upload your *.pem file to the": "Laden Sie Ihre *.pem-Datei zu dem",
    "in order to generate your DEP-token-file (*.p7m)":
        "hoch, um Ihre DEP-Token-Datei (*.p7m) zu generieren",
    "Upload your DEP-token-file (.p7m)": "Laden Sie Ihre DEP-Token-Datei (.p7m) hoch",
    "Upload DEP-token-file (.p7m)": "DEP-Token-Datei (.p7m) hochladen",
    "Add Google Enterprise account": "Google Enterprise Benutzerkonto hinzufügen",
    'Press "Add now" to create or connect to your Google Enterprise account':
        'Klicken Sie auf "Jetzt hinzufügen", um Ihr Google Enterprise Benutzerkonto zu verknüpfen oder um eines zu erstellen',
    "Add Enterprise account": "Enterprise Benutzerkonto hinzufügen",
    "Add now": "Jetzt hinzufügen",
    "You successfully added a Google Enterprise account":
        "Sie haben erfolgreich Ihr Google Enterprise Benutzerkonto hinzugefügt",
    "Successfully added Google Enterprise": "Google Enterprise erfolgreich hinzugefügt",
    "You may now close this window": "Sie können dieses Fenster jetzt schließen",
    Privacy: "Datenschutz",
    "Privacy Policy": "Datenschutzrichtlinie",
    Pseudonymization: "Pseudonymisierung",
    "Here you can setup the privacy settings. If the pseudonymization is activated, the statistics in the portal are pseudonymized and the Unified Security Report is anonymized. By default, this setting is enabled.":
        "Hier können Sie Einstellungen zum Datenschutz vornehmen. Ist die Pseudonymisierung aktiviert, werden die Statistiken im Portal pseudonymisiert und der Unified Security Report anonymisiert. Standardmäßig ist diese Einstellung aktiviert.",
    "Pseudonymization enabled": "Pseudonymisierung aktiviert",
    "Confirm disabling pseudonymization": "Bestätigen Sie das Deaktivieren der Pseudonymisierung",
    "Do you really want to disable the pseudonymization? Please obey your local law!":
        "Sind Sie sicher, dass Sie die Pseudonymisierung deaktivieren wollen? Bitte beachten Sie die bei Ihnen geltenden Gesetze!",
    "Privacy level changed to": "Privatsphären-Level wurde geändert auf",
    "by administrator": "durch den Administrator",
    "and auditor": "und Auditor",
    "has been depseudonymized": "wurde depseudonymisiert",
    Depseudonymized: "Depseudonymisiert",
    Privacylevel: "Datenschutzstufe",
    Expires: "Läuft ab",
    "View license": "Lizenz anzeigen",
    "Log initialized": "Log initialisiert",
    "Sent message of type": "Sende Nachricht vom Typen",
    "Received message of type": "Erhalte Nachricht vom Typen",
    "to device": "zum Gerät",
    "from device": "vom Gerät",
    PENDING: "AUSSTEHEND",
    Pending: "Ausstehend",
    RECEIVED: "ERHALTEN",
    PUBLISHED: "VERÖFFENTLICHT",
    Published: "Veröffentlicht",
    PUBLISH: "VERÖFFENTLICHEN",
    UPDATE: "AKTUALISIEREN",
    Update: "Aktualisieren",
    UPDATED: "AKTUALISIERT",
    Updated: "Aktualisiert",
    REVOKED: "WIDERRUFEN",
    Revoked: "Widerrufen",
    REVOKE: "WIDERRUFEN",
    Revoke: "Widerrufen",
    ACKNOWLEDGED: "Bestätigt",
    Acknowledged: "Bestätigt",
    SENT: "GESENDET",
    ERROR: "FEHLER",
    APPLICATION_LIST: "APP LISTE",
    DEVICE: "GERÄT",
    BATTERY: "AKKU",
    STORAGE: "SPEICHER",
    LOCATION: "STANDORT",
    INSTALL: "INSTALLIEREN",
    INSTALLED: "INSTALLIERT",
    Installed: "installiert",
    "NOT AFFECTED": "NICHT BETROFFEN",
    "No profile rules": "Keine Profilregeln",
    LOST: "VERLOREN",
    lost: "verloren",
    LOCK: "SPERREN",
    SIGNIN: "EINLOGGEN",
    OWNERSHIP: "EIGENTUM",
    ENABLE_LOST_MODE: "VERLOREN MODUS AKTIVIEREN",
    DISABLE_LOST_MODE: "VERLOREN MODUS DEAKTIVIEREN",
    INSTALL_PROFILE_EMAIL: "EMAIL PROFIL INSTALLIEREN",
    REMOVE_PROFILE_EMAIL: "EMAIL PROFIL DEINSTALLIEREN",
    INSTALL_PROFILE_RESTRICTIONS: "EINSCHRÄNKUNGSPROFIL INSTALLIEREN",
    REMOVE_PROFILE_RESTRICTIONS: "EINSCHRÄNKUNGSPROFIL DEINSTALLIEREN",
    INSTALL_PROFILE_SECURITY: "SICHERHEITSPROFIL INSTALLIEREN",
    REMOVE_PROFILE_SECURITY: "SICHERHEITSPROFIL DEINSTALLIEREN",
    APPLICATION_INSTALL_REQUEST: "APP INSTALLATIONS ANFRAGE",
    APPLICATION_UNINSTALL_REQUEST: "APP DEINSTALLATIONS ANFRAGE",
    PROFILE_LIST: "PROFILLISTE",
    START_RINGTONE: "KLINGELTON ABSPIELEN",
    REBOOT: "NEUSTART",
    SHUTDOWN: "HERUNTERFAHREN",
    CLEAR_PASSCODE: "PASSWORT LÖSCHEN",
    WIPE: "LÖSCHEN",
    INSTALL_PROFILE_CUSTOM: "BENUTZERDEFINIERTES PROFIL INSTALLIEREN",
    REMOVE_PROFILE_CUSTOM: "BENUTZERDEFINIERTES PROFIL DEINSTALLIEREN",
    REMOVE_PROFILE_LEGACY: "GEERBTES PROFIL DEINSTALLIEREN",
    TOKEN_UPDATE: "TOKEN AKTUALISIEREN",
    "Profile Installation Failed": "Die Profilinstallation ist fehlgeschlagen",
    "The profile": "Das Profil",
    "is not installed": "ist nicht installiert",
    "Toggle autoscroll": "Autoscroll an-/ausschalten",
    "Clear log": "Log leeren",
    "Close log": "Log schließen",
    "These settings will be applied to all Reports for your clients. Specific client-reports can be excluded.":
        "Diese Einstellungen werden auf alle Berichte Ihrer Kunden angewandt. Bestimmte Kundenberichte können davon ausgenommen werden.",
    "Select reports": "Berichte auswählen",
    Report: "Bericht",
    Reports: "Berichte",
    "Edit report": "Bericht bearbeiten",
    Theme: "Thema",
    Recipients: "Empfänger",
    month: "Monat",
    week: "Woche",
    Week: "Woche",
    Month: "Monat",
    Reportname: "Berichtname",
    "Enter a name for this report": "Tragen Sie einen Namen für diesen Bericht ein",
    "Select report type": "Wählen Sie einen Berichttypen",
    "Select a theme": "Wählen Sie ein Thema",
    "Select recipients": "Wählen Sie Empfänger",
    "Select recipients for this report": "Wählen Sie Empfänger für diesen Bericht",
    "Select the period for this report": "Wählen Sie den Zeitraum für diesen Bericht",
    Configured: "Konfiguriert",
    "Substitute Wildcard variables": "Platzhalter-Variablen ersetzen",
    "Use the user variables in the managed configuration templates.":
        "Nutzen Sie die Benutzer-Variablen in den verwalteten Konfigurationsvorlagen.",
    "FILE SUCCESSFULLY UPLOADED": "DATEI ERFOLGREICH HOCHGELADEN",
    "ERROR UPLOADING FILE": "FEHLER BEIM HOCHLADEN DER DATEI",
    "Wrong file type": "Falsches Dateiformat",
    "Upload an Apple Push Certificate first": "Laden Sie zuerst ein Apple Push Zertifikat hoch",
    "invalid Apple Push Certificate": "ungültiges Apple Push Zertifikat",
    "Only new devices": "Nur neue Geräte",
    Million: "Million(en)",
    Billion: "Milliarde(n)",
    Trillion: "Billion(en)",
    Basic: "Grundeinstellungen",
    Networks: "Netzwerke",
    "Maximum time to lock": "Maximale Zeit zum Sperren",
    "Maximum time in seconds for user activity until the device locks. This limits the length that the user can set. A value of 0 means there is no restriction.":
        "Maximale Zeit in Sekunden für Benutzeraktivität, bis das Gerät gesperrt wird. Dies limitiert die definierbare Zeit, die der Nutzer setzen kann. Ein Wert von 0 bedeutet, dass keine Einschränkung vorliegt.",
    Encryption: "Verschlüsselung",
    "enabled without password": "Aktiviert ohne Passwort",
    "Enabled without password": "Aktiviert ohne Passwort",
    "Enabled with password": "Mit Passwort aktiviert",
    "Whether encryption is enabled": "Gibt an, ob die Verschlüsselung aktiviert ist.",
    "Automatic app updates": "Automatische App-Updates",
    "User Choice": "Benutzerauswahl",
    "Over Wifi only": "Nur über Wifi",
    "Over Wi-Fi only": "Nur über WLAN",
    "The app auto-update policy enforced on a device.":
        "Die auf einem Gerät erzwungene Richtlinie zur automatischen Aktualisierung der Apps.",
    "Stay on plugged modes": "Modi in denen das Gerät an bleibt",
    "Select modes": "Modi wählen",
    "The battery plugged in modes for which the device stays on. When using this setting, it is recommended to clear maximumTimeToLock so that the device doesn't lock itself while it stays on.":
        "Die Akkulade-Modi, in denen das Gerät eingeschaltet bleibt. Bei Verwendung dieser Einstellung wird empfohlen, die maximale Zeit zum Entsperren zu deaktivieren, damit sich das Gerät nicht selbst sperrt, solange es eingeschaltet bleibt.",
    "Persistent preferred activities": "Anhaltende bevorzugte Aktivitäten",
    "Add activity": "Aktivität hinzufügen",
    Activity: "Aktivität",
    "Default intent handler activities.": "Standard-Intent-Handler-Aktivitäten.",
    "Setup actions": "Setup-Aktionen",
    "Setup action": "Setup-Aktion",
    Action: "Aktion",
    "Should be English!?!": "Dieser Name sollte Englisch sein!?!",
    "add actions": "Aktionen hinzufügen",
    "Add actions": "Aktionen hinzufügen",
    "Add action": "Aktion hinzufügen",
    Hostnames: "Hostnamen",
    "Receiver activity": "Empfänger Aktivität",
    "The activity that should be the default intent handler. This should be an Android component name, e.g. com.android.enterprise.app/.MainActivity. Alternatively, the value may be the package name of an app, which causes Android Device Policy to choose an appropriate activity from the app to handle the intent.":
        "Die Aktivität, die der Standard-Intent-Handler sein soll. Dies sollte ein Android-Komponentenname sein, z.B. com.android.enterprise.app/.MainActivity. Alternativ kann der Wert der Paketname einer App sein, wodurch die Android-Geräterichtlinie eine geeignete Aktivität aus der App auswählt, um das Vorhaben zu verarbeiten.",
    "The intent actions to match in the filter. If any actions are included in the filter, then an intent's action must be one of those values for it to match. If no actions are included, the intent action is ignored.":
        "Die absichtlichen Aktionen, die im Filter abgeglichen werden sollen. Wenn der Filter Aktionen enthält, muss die Aktion einer Absicht einer dieser Werte sein, damit sie übereinstimmt. Wenn keine Aktionen enthalten sind, wird die Absichtsaktion ignoriert.",
    Categories: "Kategorien",
    "Add categories": "Kategorien hinzufügen",
    "The intent categories to match in the filter. An intent includes the categories that it requires, all of which must be included in the filter in order to match. In other words, adding a category to the filter has no impact on matching unless that category is specified in the intent.":
        "Die Absichtskategorien, die im Filter übereinstimmen sollen. Eine Absicht enthält die erforderlichen Kategorien, die alle im Filter enthalten sein müssen, damit sie übereinstimmen. Mit anderen Worten, das Hinzufügen einer Kategorie zum Filter hat keine Auswirkungen auf die Übereinstimmung, es sei denn, diese Kategorie ist in der Absicht angegeben.",
    Title: "Titel",
    "Title of this action.": "Titel der Aktion.",
    "Description of this action.": "Beschreibung der Aktion.",
    "Launch app": "App starten",
    "Package name of app to be launched": "Paketname der App die gestartet werden soll",
    "Select app": "App auswählen",
    "Actions to take during the setup process.":
        "Aktionen, die während des Installationsvorgangs ausgeführt werden sollen.",
    "Device owner lockscreen info": "Sperrbildschirm Informationen zum Gerätebesitzer",
    "Activate lockscreen info": "Aktiviere die Sperrbildschirm-Informationen",
    "After setting this, you can set the lockscreen info":
        "Nachdem Sie dies aktiviert haben, können Sie die Sperrbildschirminformationen einstellen.",
    "The device owner information to be shown on the lock screen. The maximum message length is 4096 characters.":
        "Die Gerätebesitzerinformationen, die auf dem Sperrbildschirm angezeigt werden sollen. Die maximale Nachrichtenlänge beträgt 4096 Zeichen.",
    "Add application": "Anwendung hinzufügen",
    "Policy applied to apps.": "Richtlinie für Apps.",
    "Install type": "Installationstyp",
    "The package name of the app. For example, com.google.android.youtube for the YouTube app.":
        "Der Paketname der App. Zum Beispiel com.google.android.youtube für die YouTube-App.",
    "Always on VPN": "VPN immer aktiv",
    "Activate always on VPN": 'Aktiviere "Always-On-VPN"',
    "After setting this, you can set the always on VPN settings. Negligible for Mobile Security. This option is set implicitly by the app.":
        'Aktiviert die "Always-On-VPN"-Konfiguration. Vernachlässigbar für Mobile Security. Diese Option wird von der App implizit gesetzt.',
    "Package name": "Paketname",
    "The package name of the VPN app.": "Der Paketname der VPN-App.",
    "Lockdown enabled": "Sperre aktiviert",
    "Disallows networking when the VPN is not connected.":
        "Netzwerkverbindungen unterbinden, wenn das VPN nicht verbunden ist.",
    "Recommended global proxy": "Empfohlener globaler Proxy",
    "Activate global proxy": "Aktivieren Sie den globalen Proxy",
    "After setting this, you can set a global proxy":
        "Nachdem Sie dies festgelegt haben, können Sie einen globalen Proxy festlegen.",
    Host: "Host",
    Hostname: "Hostname",
    "The host of the direct proxy.": "Der Host des direkten Proxys.",
    Portnumber: "Port Nummer",
    "The port of the direct proxy.": "Der Port des direkten Proxys.",
    "Excluded host": "Ausgeschlossener Host",
    "For a direct proxy, the hosts for which the proxy is bypassed. The host names may contain wildcards such as *.example.com.":
        "Bei einem direkten Proxy die Hosts, für die der Proxy umgangen wird. Die Hostnamen können Platzhalter wie * .example.com enthalten.",
    "PAC URI": "PAC URI",
    "Configure application": "Anwendung konfigurieren",
    "The URI of the PAC script used to configure the proxy.":
        "Der URI des PAC-Skripts, mit dem der Proxy konfiguriert wurde.",
    "Open network configuration": "Offene Netzwerkkonfiguration",
    "Network configuration": "Netzwerkkonfiguration",
    "network configurations": "Netzwerkkonfigurationen",
    "Network configurations": "Netzwerkkonfigurationen",
    "Add configuration": "Konfiguration hinzufügen",
    "Add network configurations": "Netzwerkkonfigurationen hinzufügen",
    "Support Messages": "Support-Meldungen",
    "Activate support messages": "Support-Meldungen aktivieren",
    "After setting this, you can set support messages":
        "Nachdem Sie dies aktiviert haben, können Sie Support-Meldungen einstellen",
    "Short support message": "Kurze Supportnachricht",
    "A message displayed to the user in the settings screen wherever functionality has been disabled by the admin. The maximum message length is 4096 characters.":
        "Eine Meldung, die dem Benutzer auf dem Einstellungsbildschirm angezeigt wird, wenn die Funktionalität vom Administrator deaktiviert wurde. Die maximale Nachrichtenlänge beträgt 4096 Zeichen.",
    "Long support message": "Lange Supportnachricht",
    "A message displayed to the user in the device administators settings screen. The maximum message length is 4096 characters.":
        "Eine Nachricht, die dem Benutzer auf dem Einstellungsbildschirm des Geräteadministrators angezeigt wird. Die maximale Nachrichtenlänge beträgt 4096 Zeichen.",
    "Permitted input methods": "Zulässige Eingabemethoden",
    "Permitted Accessibility Services": "Zugelassene Eingabehilfedienste",
    "Add package nan": "Packet nan hinzufügen",
    "If present, only the input methods provided by packages in this list are permitted. If this field is present, but the list is empty, then only system input methods are permitted.":
        "Falls vorhanden, sind nur die Eingabemethoden zulässig, die von Paketen in dieser Liste bereitgestellt werden. Wenn dieses Feld vorhanden ist, die Liste jedoch leer ist, sind nur Systemeingabemethoden zulässig.",
    "Factory Reset Email": "Konten zum Entsperren nach Zurücksetzen auf Werkseinstellung",
    "select E-Mail": "Wählen Sie die E-Mail-Adresse",
    "Email addresses of device administrators for factory reset protection. When the device is factory reset, it will require one of these admins to log in with the Google account email and password to unlock the device. If no admins are specified, the device won't provide factory reset protection.":
        "Factory Reset Protection (FRP). Wenn das Gerät auf die Werkseinstellungen zurückgesetzt wurde, muss sich einer dieser Administratoren mit der E-Mail-Adresse und dem Passwort des Google-Kontos anmelden, um das Gerät zu entsperren. Wenn keine Administratoren angegeben sind, bietet das Gerät keinen Schutz nach dem Zurücksetzen auf die Werkseinstellungen.",
    "Default permission policy": "Standardberechtigungsrichtlinie",
    "Global default permission policy": "Globale Standardberechtigungsrichtlinie",
    "Unspecified (Prompt)": "Nicht spezifiziert (Eingabeaufforderung)",
    prompt: "Eingabeaufforderung",
    Grant: "Gewähren",
    Deny: "Verweigern",
    "The default permission policy for runtime permission requests.":
        "Die Standardberechtigungsrichtlinie für Laufzeitberechtigungsanforderungen.",
    "Disable installing apps": "Installation von Apps deaktivieren",
    "Whether installation of apps is disabled.":
        "Gibt an, ob die Installation von Anwendungen deaktiviert ist.",
    "Disable uninstalling apps": "Deinstallation von Apps deaktivieren",
    "Whether user uninstallation of applications is disabled.":
        "Gibt an, ob die Deinstallation von Anwendungen deaktiviert ist.",
    "Location mode": "Ortungsmodus",
    "high accuracy": "hohe Genauigkeit",
    "High accuracy": "Hohe Genauigkeit",
    "Sensors only": "Nur Sensoren",
    "Battery saving": "Batterieschonung",
    "Whether screen capture is disabled.": "Ob die Bildschirmaufnahme deaktiviert ist.",
    "The degree of location detection enabled. The user may change the value unless the user is otherwise blocked from accessing device settings.":
        "Der Grad der Standorterkennung ist aktiviert. Der Benutzer kann den Wert ändern, es sei denn, der Benutzer kann nicht auf Geräteeinstellungen zugreifen.",
    "Disable screen capture": "Deaktivieren Sie die Bildschirmaufnahme",
    "Disable camera": "Kamera deaktivieren",
    "Whether the camera is disabled.": "Ob die Kamera deaktiviert ist",
    "Accounttypes with management disabled": "Kontotypen mit deaktivierter Verwaltung",
    "Account types that can't be managed by the user.":
        "Kontotypen, die vom Benutzer nicht verwaltet werden können.",
    "Disable adding users": "Deaktivieren Sie das Hinzufügen von Benutzern",
    "Whether adding new users and profiles is disabled.":
        "Ob das Hinzufügen neuer Benutzer und Profile deaktiviert ist.",
    "Disable adjusting volume": "Deaktivieren Sie die Einstellung der Lautstärke",
    "Whether adjusting the master volume is disabled.":
        "Ob das Anpassen der Hauptlautstärke deaktiviert ist.",
    "Disable factory reset": "Deaktivieren Sie den Werksreset",
    "Whether factory resetting from settings is disabled.":
        "Gibt an, ob das Zurücksetzen auf Werkseinstellungen deaktiviert ist.",
    "Disable mounting physical media": "Deaktivieren Sie die Bereitstellung physischer Medien",
    "Whether the user mounting physical external media is disabled.":
        "Gibt an, ob der Benutzer, der physische externe Medien bereitstellt, deaktiviert ist.",
    "Disable modifying accounts": "Deaktivieren Sie das Ändern von Konten",
    "Whether adding or removing accounts is disabled.":
        "Ob das Hinzufügen oder Entfernen von Konten deaktiviert ist.",
    "Disable safe boot": "Abgesicherten Modus deaktivieren",
    "Whether rebooting the device into safe boot is disabled.":
        "Gibt an, ob der Neustart des Geräts im abgesicherten Modus deaktiviert ist.",
    "Disable status bar": "Statusleiste deaktivieren",
    "Whether the status bar is disabled. This disables notifications, quick settings, and other screen overlays that allow escape from full-screen mode.":
        "Ob die Statusleiste deaktiviert ist. Dadurch werden Benachrichtigungen, Schnelleinstellungen und andere Bildschirmüberlagerungen deaktiviert, die das Verlassen des Vollbildmodus ermöglichen.",
    "Disable keyguard": "Tastensperre deaktivieren",
    "Whether the keyguard is disabled.": "Gibt an, ob die Tastensperre deaktiviert ist.",
    "Disable bluetooth contact sharing": "Deaktivieren Sie die Bluetooth-Kontaktfreigabe",
    "Whether bluetooth contact sharing is disabled. Work profile only.":
        "Gibt an, ob die Bluetooth-Kontaktfreigabe deaktiviert ist. Nur für Arbeitsprofile.",
    "Disable bluetooth configuration": "Deaktivieren Sie die Bluetooth-Konfiguration.",
    "Whether configuring bluetooth is disabled.":
        "Gibt an, ob die Bluetooth-Konfiguration deaktiviert ist.",
    "Disable cell broadcast configuration": "Deaktivieren Sie die Cell Broadcast-Konfiguration",
    "Whether configuring cell broadcast is disabled.":
        "Gibt an, ob die Konfiguration von Cell Broadcast deaktiviert ist.",
    "Disable credentials configuration":
        "Deaktivieren Sie die Konfiguration der Anmeldeinformationen",
    "Whether configuring user credentials is disabled. Note: when disabled, certificates can't be installed anymore. If you want to use security settings we suggest to disable credential configuration after the security features are set up on all devices.":
        "Gibt an, ob die Konfiguration von Benutzeranmeldeinformationen deaktiviert ist. Anmerkung: Wenn deaktiviert, können keine Zertifikate mehr installiert werden. Falls Sie die Sicherheitseinstellungen verwenden möchten, empfehlen wir die Konfiguration der Anmeldeinformationen erst zu deaktivieren, nachdem die Sicherheitseinstellungen auf allen Geräten umgesetzt wurden.",
    "Disable mobile network configuration": "Deaktivieren Sie die Mobilfunknetzkonfiguration",
    "Whether configuring mobile networks is disabled.":
        "Gibt an, ob die Konfiguration von Mobilfunknetzen deaktiviert ist.",
    "Disable tethering configuration": "Deaktivieren Sie die Tethering-Konfiguration",
    "Whether configuring tethering and portable hotspots is disabled.":
        "Gibt an, ob die Konfiguration von Tethering und portablen Hotspots deaktiviert ist.",
    "Disable VPN configuration": "Deaktivieren Sie die VPN-Konfiguration",
    "Whether configuring VPN is disabled.":
        "Gibt an, ob die Konfiguration von VPN deaktiviert ist.",
    "Disable Wi-Fi configuration": "Deaktivieren Sie die Wi-Fi-Konfiguration",
    "Whether configuring Wi-Fi access points is disabled.":
        "Ob die Konfiguration von Wi-Fi-Zugangspunkten deaktiviert ist.",
    "Whether configuring Wi-Fi access points is disabled. IMPORTANT: Devices without an installed simcard and disabled Wi-Fi can't communicate with the Securepoint servers":
        "Ob die Konfiguration von Wi-Fi-Zugangspunkten deaktiviert ist. WICHTIG: Geräte ohne installierte Simkarte and deaktiviertem Wi-Fi können nicht mit den Securepoint servern kommunizieren.",
    "The device will be linked to this license": "Das Gerät wird an diese Lizenz gebunden",
    "Choose topics": "Wählen Sie einen Auslöser",
    "Choose a type": "Wählen Sie einen Typen",
    "Disable creating windows": "Deaktivieren Sie das Erstellen von Fenstern",
    "Whether creating windows besides app windows is disabled.":
        "Ob das Erstellen von Fenstern neben App-Fenstern deaktiviert ist.",
    "Disable resetting network settings":
        "Deaktivieren Sie das Zurücksetzen der Netzwerkeinstellungen",
    "Whether resetting network settings is disabled.":
        "Gibt an, ob das Zurücksetzen der Netzwerkeinstellungen deaktiviert ist.",
    "Disable sending via NFC": "Versenden via NFC deaktivieren",
    "Whether using NFC to send data from apps is disabled.":
        "Ob die Verwendung von NFC zum Senden von Daten aus Apps deaktiviert ist.",
    "Disable outgoing calls": "Ausgehende Anrufe deaktivieren",
    "Whether outgoing calls are disabled.": "Gibt an, ob ausgehende Anrufe deaktiviert sind.",
    "Disable removing users": "Deaktivieren Sie das Entfernen von Benutzern",
    "Whether removing other users is disabled.":
        "Ob das Entfernen anderer Benutzer deaktiviert ist.",
    "Disable location sharing": "Deaktivieren Sie die Standortfreigabe",
    "Whether location sharing is disabled.": "Gibt an, ob die Standortfreigabe deaktiviert ist.",
    "Disable SMS": "SMS deaktivieren",
    "Whether sending and receiving SMS messages is disabled.":
        "Ob das Senden und Empfangen von SMS-Nachrichten deaktiviert ist.",
    "Disable unmuting microphone": "Mikrofon einschalten verhindern",
    "Whether the microphone is muted and adjusting microphone volume is disabled.":
        "Ob das Mikrofon stummgeschaltet ist und die Mikrofonlautstärke nicht eingestellt werden kann.",
    "Disable USB filetransfer": "Deaktivieren Sie die USB-Datenübertragung",
    "Whether transferring files over USB is disabled.":
        "Ob die Übertragung von Dateien über USB deaktiviert ist.",
    "Force app verification": "App-Überprüfung durch 'Google Play Protect' erzwingen",
    "Whether app verification is force-enabled.":
        "Gibt an, ob die App-Überprüfung durch 'Google Play Protect' erzwungen wird.",
    "Disable setting user icon": "Einstellungsbenutzersymbol deaktivieren",
    "Whether changing the user icon is disabled.":
        "Gibt an, ob das Ändern des Benutzersymbols deaktiviert ist.",
    "Disable setting wallpapers": "Deaktivieren Sie die Hintergrundeinstellungen",
    "Whether changing the wallpaper is disabled.":
        "Ob das Ändern des Hintergrundbilds deaktiviert ist.",
    "Disable roaming": "Roaming deaktivieren",
    "Whether roaming data services are disabled.":
        "Gibt an, ob Roaming-Datendienste deaktiviert sind.",
    "Disable network escape hatch": "Deaktivieren Sie den Netzwerk Escape Hatch",
    "Whether the network escape hatch is enabled. If a network connection can't be made at boot time, the escape hatch prompts the user to temporarily connect to a network in order to refresh the device policy. After applying policy, the temporary network will be forgotten and the device will continue booting. This prevents being unable to connect to a network if there is no suitable network in the last policy and the device boots into an app in lock task mode, or the user is otherwise unable to reach device settings.":
        "Gibt an, ob der Netzwerk Escape Hatch aktiviert ist. Wenn beim Booten keine Netzwerkverbindung hergestellt werden kann, fordert der Escape Hatch den Benutzer auf, vorübergehend eine Verbindung zu einem Netzwerk herzustellen, um die Geräterichtlinie zu aktualisieren. Nach dem Anwenden der Richtlinie wird das temporäre Netzwerk vergessen und das Gerät fährt mit dem Booten fort. Auf diese Weise wird verhindert, dass keine Verbindung zu einem Netzwerk hergestellt werden kann, wenn in der letzten Richtlinie kein geeignetes Netzwerk vorhanden ist und das Gerät im Task-Sperrmodus eine App startet oder der Benutzer ansonsten die Geräteeinstellungen nicht erreichen kann.",
    "Disable bluetooth": "Bluetooth deaktivieren",
    "Whether bluetooth is disabled. Prefer this setting over bluetooth_config_disabled because bluetooth_config_disabled can be bypassed by the user.":
        "Ob Bluetooth deaktiviert ist. Ziehen Sie diese Einstellung Bluetooth-Konfiguration deaktivieren vor, da Bluetooth-Konfiguration deaktivieren vom Benutzer umgangen werden kann.",
    "Enable blocking other apps": "Aktivieren Sie die Blockierung anderer Apps",
    "Whether applications other than the ones configured in applications are blocked from being installed. When set, applications that were installed under a previous policy but no longer appear in the policy are automatically uninstalled.":
        "Gibt an, ob andere als die in Anwendungen konfigurierten Anwendungen für die Installation gesperrt sind. Wenn diese Option aktiviert ist, werden Anwendungen, die unter einer früheren Richtlinie installiert wurden, jedoch nicht mehr in der Richtlinie angezeigt werden, automatisch deinstalliert.",
    "Select application": "Anwendung auswählen",
    "Pre install": "Vorinstallation",
    "Force install": "Installation erzwingen",
    Block: "Blockieren",
    "Required for setup": "Für die Einrichtung erforderlich",
    "The type of installation to perform.": "Die Art wie die Installation durchgeführt wird.",
    "Managed configuration": "Verwaltete Konfiguration",
    "Managed Configuration Template": "Verwaltete Konfigurationsvorlage",
    "Managed Configuration": "Verwaltete Konfiguration",
    "Manage configuration": "Konfiguration verwalten",
    "Please set username and password": "Bitte Benutzername und Passwort eingeben",
    "Manage configuration template": "Konfigurationsvorlage verwalten",
    "Minimum version code": "Minimaler Versionscode",
    "Delegate scopes": "Bereiche delegieren",
    "Print QR-Code": "QR-Code drucken",
    "Specifies permitted accessibility services. If the field is not set, any accessibility service can be used. If the field is set, only the accessibility services in this list and the system's built-in accessibility services can be used. In particular, if the field is set to empty, only the system's built-in accessibility services can be used.":
        "Gibt die zulässigen Eingabehilfedienste an. Wenn das Feld nicht gesetzt ist, kann jeder Eingabehilfedienst verwendet werden. Wenn das Feld festgelegt ist, können nur die in dieser Liste enthaltenen Eingabehilfedienste und die im System integrierten Eingabehilfedienste verwendet werden. Insbesondere wenn das Feld leer ist, können nur die integrierten Eingabehilfedienste des Systems verwendet werden.",
    "Allow unknown sources": "Erlaube unbekannte Quellen",
    'Whether the user is allowed to enable the "Unknown Sources" setting, which allows installation of apps from unknown sources.':
        'Gibt an, ob der Benutzer die Einstellung "Unbekannte Quellen" aktivieren darf, mit der Apps aus unbekannten Quellen installiert werden können.',
    "Allow debbuging features": "Debugging-Funktionen zulassen",
    "Whether the user is allowed to enable debugging features.":
        "Gibt an, ob der Benutzer Debugging-Funktionen aktivieren darf.",
    "Disable fun": "Eastereggs deaktivieren",
    "Whether the user is allowed to have fun. Controls whether the Easter egg game in Settings is disabled.":
        "Ob der Benutzer Spaß haben darf. Steuert, ob das Easteregg-Spiel in den Einstellungen deaktiviert ist.",
    "Require auto time": "Automatische Zeit erzwingen",
    "Whether auto time is required, which prevents the user from manually setting the date and time.":
        "Gibt an, ob die automatische Zeit erforderlich ist, sodass der Benutzer Datum und Uhrzeit nicht manuell einstellen kann.",
    "Enable custom kiosk launcher": "Aktivieren Sie den benutzerdefinierten Kiosk-Starter",
    "Whether the kiosk custom launcher is enabled. This replaces the home screen with a launcher that locks down the device to the apps installed via the applications setting. The apps appear on a single page in alphabetical order. It is recommended to also disable the statusbar to block access to device settings.":
        "Gibt an, ob der benutzerdefinierte Kiosk-Starter aktiviert ist. Dadurch wird der Home-Bildschirm durch einen Starter ersetzt, der das Gerät für die über die Anwendungseinstellung installierten Apps sperrt. Die Apps werden auf einer einzelnen Seite in alphabetischer Reihenfolge angezeigt. Es wird empfohlen, die Statusleiste zu deaktivieren, um den Zugriff auf Geräteeinstellungen zu blockieren.",
    "Skip hints on first user": "Hinweise zum ersten Benutzer überspringen",
    "Flag to skip hints on the first use. Enterprise admin can enable the system recommendation for apps to skip their user tutorial and other introductory hints on first start-up.":
        "Flag, um Hinweise zur ersten Verwendung zu überspringen. Der Unternehmensadministrator kann die Systemempfehlung für Apps aktivieren, um das Benutzer-Tutorial und andere einführende Hinweise beim ersten Start zu überspringen.",
    "Enable private key selection": "Aktiviere Auswahl privater Schlüssel",
    "Allows showing UI on a device for a user to choose a private key alias if there are no matching rules in ChoosePrivateKeyRules. For devices below Android P, setting this may leave enterprise keys vulnerable.":
        "Ermöglicht die Anzeige der Benutzeroberfläche auf einem Gerät, damit ein Benutzer einen privaten Schlüsselalias auswählen kann, wenn in ChoosePrivateKeyRules keine übereinstimmenden Regeln vorhanden sind. Bei Geräten unter Android P können durch diese Einstellung Unternehmensschlüssel angegriffen werden.",
    "Play Store Mode": "Play Store Modus",
    "This mode controls which apps are available to the user in the Play Store and the behavior on the device when apps are removed from the policy.":
        "Mit diesem Modus wird gesteuert, welche Apps dem Benutzer im Play Store zur Verfügung stehen und wie sich das Gerät verhält, wenn Apps aus der Richtlinie entfernt werden.",
    "Disable Keyguard features": "Deaktivieren Sie die Keyguard-Funktionen",
    Camera: "Kamera",
    Face: "Gesicht",
    Iris: "Iris",
    Biometrics: "Biometrie",
    "Unredacted notifications": "Nicht umgesetzte Benachrichtigungen",
    "Ignore trust agents": "Vertrauensagenten ignorieren",
    Fingerprint: "Fingerabdruck",
    "Remote input": "Remote-Eingang",
    Everything: "Alles",
    "This value is ignored": "Dieser Wert wird ignoriert",
    "Disabled keyguard customizations, such as widgets.":
        "Deaktivierte Keyguard-Anpassungen, z. B. Widgets.",
    "Status reporting": "Statusmeldung",
    "Activate status reporting": "Aktivieren Sie die Statusmeldung",
    "After setting this, you can set status reporting configuration":
        "Nachdem Sie dies aktiviert haben, können Sie die Konfiguration der Statusberichte festlegen",
    "After setting this, you can set status reporting configuration. If you have activated Mobile Security, status reporting is also activated automatically and cannot be deactivated.":
        "Nachdem Sie dies aktiviert haben, können Sie die Konfiguration der Statusberichte festlegen. Wenn Sie Mobile Security aktiviert haben, werden auch die Statusmeldungen automatisch aktiviert und können nicht deaktiviert werden.",
    'One of the selected tags is the "utms" tag. Please note that the selected action is executed on all your UTMs when this tag is selected.':
        "Eines der ausgewählten Tags ist das „utms“-Tag. Bitte beachten Sie, dass die gewählte Aktion bei Auswahl dieses Tags auf all Ihren UTMs ausgeführt wird.",
    "Confirm Saving": "Speichern bestätigen",
    "Hardware status": "Hardwarestatus",
    "Whether hardware status reporting is enabled.":
        "Gibt an, ob die Hardware-Statusmeldung aktiviert ist.",
    "Application reports": "Anwendungsberichte",
    "Whether app reports are enabled.": "Gibt an, ob App-Berichte aktiviert sind.",
    "Software info": "Softwareinformationen",
    "Whether software info reporting is enabled.":
        "Gibt an, ob die Software-Info-Berichterstattung aktiviert ist.",
    "Memory info": "Arbeitsspeicherinformationen",
    "Whether memory reporting is enabled.":
        "Gibt an, ob die Speicherberichterstattung aktiviert ist.",
    "Display info": "Anzeigeinformationen",
    "Whether displays reporting is enabled.":
        "Gibt an, ob die Anzeigeinformationen aktiviert sind.",
    "Network info": "Netzwerkinformationen",
    "Whether network info reporting is enabled.":
        "Gibt an, ob die Netzwerkinfomeldung aktiviert ist.",
    "Device settings": "Geräteeinstellungen",
    "Whether device settings reporting is enabled.":
        "Gibt an, ob die Berichterstellung für Geräteeinstellungen aktiviert ist.",
    "Power management events": "Energieverwaltungsereignisse",
    "Whether power management event reporting is enabled.":
        "Gibt an, ob die Energieverwaltungsereignisberichterstattung aktiviert ist.",
    "System updates": "Systemaktualisierung",
    "Activate system update configuration":
        "Aktivieren Sie die Konfiguration der Systemaktualisierungen",
    "After setting this, you can set system update configuration":
        "Nachdem Sie dies aktiviert haben, können Sie die Systemaktualisierungskonfiguration einstellen",
    Automatic: "Automatisch",
    Windowed: "Im Fenster",
    Postpone: "Verschieben",
    "Update type": "Aktualisierungsart",
    "The type of system update to configure.":
        "Die Art der zu konfigurierenden Systemaktualisierung.",
    "Private key rules": "Regeln für private Schlüssel",
    "add Rule": "Regel hinzufügen",
    "Rules for automatically choosing a private key and certificate to authenticate the device to a server. The rules are ordered by increasing precedence, so if an outgoing request matches more than one rule, the last rule defines which private key to use.":
        "Regeln für die automatische Auswahl eines privaten Schlüssels und Zertifikats zur Authentifizierung des Geräts bei einem Server. Die Regeln sind nach Priorität geordnet. Wenn also eine ausgehende Anforderung mehr als einer Regel entspricht, definiert die letzte Regel, welcher private Schlüssel verwendet werden soll.",
    "URL pattern": "URL-Muster",
    "The URL pattern to match against the URL of the outgoing request. The pattern may contain asterisk (*) wildcards. Any URL is matched if unspecified.":
        "Das URL-Muster, das mit der URL der ausgehenden Anforderung abgeglichen werden soll. Das Muster kann Platzhalter mit Sternchen (*) enthalten. Jede URL stimmt überein, wenn sie nicht angegeben ist.",
    "Add package names": "Paketnamen hinzufügen",
    "Package names": "Paketnamen",
    "The package names for which outgoing requests are subject to this rule. If no package names are specified, then the rule applies to all packages. For each package name listed, the rule applies to that package and all other packages that shared the same Android UID. The SHA256 hash of the signing key signatures of each packageName will be verified against those provided by Play":
        "Die Paketnamen, für die ausgehende Anforderungen dieser Regel unterliegen. Wenn keine Paketnamen angegeben sind, gilt die Regel für alle Pakete. Für jeden aufgelisteten Paketnamen gilt die Regel für dieses Paket und alle anderen Pakete, die dieselbe Android-UID verwendet haben. Der SHA256-Hash der Signaturschlüsselsignaturen jedes Paketnamens wird mit den von Play bereitgestellten verglichen",
    "Private key alias": "Alias für privaten Schlüssel",
    Allias: "Allias",
    "The alias of the private key to be used.":
        "Der Alias des zu verwendenden privaten Schlüssels.",
    "Compliance rules": "Compliance-Regeln",
    "Add Rule": "Regel hinzufügen",
    "Rules declaring which mitigating actions to take when a device is not compliant with its policy. When the conditions for multiple rules are satisfied, all of the mitigating actions for the rules are taken. There is a maximum limit of 100 rules.":
        "Regeln, die festlegen, welche Abhilfemaßnahmen zu ergreifen sind, wenn ein Gerät nicht mit seiner Richtlinie übereinstimmt. Wenn die Bedingungen für mehrere Regeln erfüllt sind, werden alle Abhilfemaßnahmen für die Regeln ergriffen. Es gibt eine Höchstgrenze von 100 Regeln.",
    "Disable apps": "Apps deaktivieren",
    "If set to true, the rule includes a mitigating action to disable apps so that the device is effectively disabled, but app data is preserved. If the device is running an app in locked task mode, the app will be closed and a UI showing the reason for non-compliance will be displayed.":
        'Bei Festlegung auf "true" enthält die Regel eine Abschwächungsmaßnahme zum Deaktivieren von Apps, sodass das Gerät effektiv deaktiviert wird, die App-Daten jedoch erhalten bleiben. Wenn auf dem Gerät eine App im gesperrten Task-Modus ausgeführt wird, wird die App geschlossen und eine Benutzeroberfläche mit dem Grund für die Nichteinhaltung wird angezeigt.',
    "Package names to disable": "Zu deaktivierende Paketnamen",
    "TIf set, the rule includes a mitigating action to disable apps specified in the list, but app data is preserved.":
        "Wenn festgelegt, enthält die Regel eine Abhilfemaßnahme zum Deaktivieren der in der Liste angegebenen Apps, die App-Daten bleiben jedoch erhalten.",
    "Non compliance detail condition": "Nichteinhaltungsdetailbedingung",
    Activate: "Aktivieren",
    "A compliance rule condition which is satisfied if there exists any matching NonComplianceDetail for the device. A NonComplianceDetail matches a NonComplianceDetailCondition if all the fields which are set within the NonComplianceDetailCondition match the corresponding NonComplianceDetail fields.":
        "Eine Konformitätsregelbedingung, die erfüllt ist, wenn für das Gerät ein übereinstimmendes NonComplianceDetail vorhanden ist. Ein NonComplianceDetail entspricht einer NonComplianceDetailCondition, wenn alle in der NonComplianceDetailCondition festgelegten Felder mit den entsprechenden NonComplianceDetail-Feldern übereinstimmen.",
    "Setting name": "Einstellungsname",
    "The name of the policy setting. If not set, then this condition matches any setting name.":
        "Der Name der Richtlinieneinstellung. Wenn nicht festgelegt, entspricht diese Bedingung einem beliebigen Einstellungsnamen.",
    "Non compliance reason": "Grund der Nichteinhaltung",
    "API level": "API-Ebene",
    "Management mode": "Verwaltungsmodus",
    "User action": "Benutzeraktion",
    "Invalid value": "Ungültiger Wert",
    "App not installed": "App nicht installiert",
    unsupported: "nicht unterstützt",
    "App installed": "App installiert",
    "App incompatible": "App nicht kompatibel",
    "App not updated": "App nicht aktualisiert",
    "The reason the device is not in compliance with the setting. If not set, then this condition matches any reason.":
        "Der Grund, warum das Gerät nicht mit der Einstellung übereinstimmt. Wenn nicht festgelegt, entspricht diese Bedingung einem beliebigen Grund.",
    "The package name of the app that's out of compliance. If not set, then this condition matches any package name.":
        "Der Paketname der App, die nicht konform ist. Wenn nicht festgelegt, entspricht diese Bedingung einem beliebigen Paketnamen.",
    "Global permission grants": "Globale Erlaubnisgewährung",
    "Add permission": "Berechtigung hinzufügen",
    "Explicit permission or group grants or denials for all apps. These values override the defaultPermissionPolicy.":
        "Explizite Erlaubnis oder Gruppengewährung oder -verweigerung für alle Apps. Diese Werte überschreiben die Standardberechtigungsrichtlinie.",
    Permission: "Berechtigung",
    "The Android permission or group, e.g. android.permission.READ_CALENDAR or android.permission_group.CALENDAR.":
        "Die Android-Berechtigung oder -Gruppe, z. android.permission.READ_CALENDAR oder android.permission_group.CALENDAR.",
    "The policy for granting the permission.": "Die Richtlinie zum Erteilen der Berechtigung.",
    "Passcode aktivieren": "Passcode aktivieren",
    "After setting this, you can set passcode policies":
        "Nachdem Sie dies aktiviert haben, können Sie Passcode-Richtlinien festlegen",
    "Passcode quality": "Passcode-Qualität",
    "The required password quality.": "Die erforderliche Passwortqualität.",
    "Password history length": "Länge des Passwortverlaufs",
    "The length of the password history. After setting this field, the user won't be able to enter a new password that is the same as any password in the history. A value of 0 means there is no restriction.":
        "Die Länge des Passwortverlaufs. Nach dem Einstellen dieses Felds kann der Benutzer kein neues Passwort eingeben, das mit dem Passwort im Verlauf identisch ist. Ein Wert von 0 bedeutet, dass keine Einschränkung vorliegt.",
    "Maximum failed attempts": "Maximal fehlgeschlagene Versuche",
    "Number of incorrect device-unlock passwords that can be entered before a device is wiped. A value of 0 means there is no restriction.":
        "Anzahl der falschen Passwörter zum Entsperren von Geräten, die eingegeben werden können, bevor ein Gerät gelöscht wird. Ein Wert von 0 bedeutet, dass keine Einschränkung vorliegt.",
    "Expiration timeout": "Gültigkeit",
    "A duration in days until the password has to be changed.":
        "Eine Dauer in Tagen, bis das Passwort geändert werden muss.",
    both: "beides",
    "Password requirement policies. Different policies can be set for work profile or fully managed devices by setting the passwordScope field in the policy.":
        "Passwortrichtlinien. Fügen sie verschiedene Passwortrichtlinien hinzu. Diese Richtlinien können für Arbeitsprofile und komplett verwaltete Geräte verwendet werden.",
    "The scope that the password requirement applies to.":
        "Der Bereich, für den die Passwortanforderung gilt.",
    "Activate Securepoint Mobile Security": "Aktivieren Sie Securepoint Mobile Security",
    "Adds/Removes the Securepoint Mobile Security app. This is needed in order to configure the Security settings. This setting activates the status reporting enabling the Mobile Security app to send application reports as feedback.":
        "Fügt die Securepoint Mobile Security-App hinzu bzw. entfernt sie. Dies ist erforderlich, um die Sicherheitseinstellungen zu konfigurieren. Diese Einstellung aktiviert die Statusmeldungen, damit die Mobile Security-App Anwendungsberichte als Feedback schicken kann.",
    Region: "Region",
    "Select your region": "Wählen Sie Ihre Region.",
    "Do you want to use an existing enrollment token?":
        "Möchten Sie einen vorhandenen Registrierungstoken verwenden?",
    Emails: "E-Mail-Adressen",
    "Create a new enrollment token": "Erstellen Sie einen neuen Registrierungstoken",
    "Create enrollment token": "Registrierungstoken erstellen",
    expired: "abgelaufen",
    Expired: "Abgelaufen",
    Policy: "Richtlinie",
    "This Profile will be applied to the enrolled device":
        "Dieses Profil wird auf das registrierte Gerät angewendet",
    Duration: "Dauer",
    "30 minutes": "30 Minuten",
    "One hour": "Eine Stunde",
    "One day": "Ein Tag",
    "One week": "Eine Woche",
    "One year": "Ein Jahr",
    "15 days": "15 Tage",
    "30 days": "30 Tage",
    "60 days": "60 Tage",
    "90 days": "90 Tage",
    "Determines how long this token can be used":
        "Legt fest, wie lange dieser Token verwendet werden kann",
    "Additional data": "Zusätzliche Daten",
    "Arbitrary data associated with the enrollment token.":
        "Mit dem Registrierungstoken verknüpfte beliebige Daten.",
    "One time only": "Nur einmal",
    "Whether the enrollment token is for one time use only.":
        "Gibt an, ob der Registrierungstoken nur einmal verwendet werden darf.",
    "How do you want to enroll this device? ": "Wie möchten Sie dieses Gerät registrieren?",
    "Enroll as device owner": "Registrieren Sie den Gerätebesitzer",
    "Enroll as profile owner": "Melden Sie sich als Profilbesitzer an",
    "Expires on": "Läuft aus am",
    "Legacy enrollment will be deprecated soon.": "Die alte Geräteanmeldung wird bald eingestellt.",
    Legacy: "Alte Geräteanmeldung",
    "Password policy": "Passwortrichtlinie",
    "Minimum length": "Mindestlänge",
    "Minimum letters": "Mindestanzahl an Buchstaben",
    "Minimum lowercase letters": "Mindestanzahl an Kleinbuchstaben",
    "Minimum uppercase letters": "Mindestanzahl an Großbuchstaben",
    "Minimum non letter characters": "Mindestanzahl an Nichtbuchstaben",
    "Minimum numeric characters": "Mindestanzahl an numerischen Zeichen",
    "Minimum symbols": "Mindestanzahl an Symbolen",
    "The minimum allowed password length. A value of 0 means there is no restriction. Only enforced when passwordQuality is NUMERIC, NUMERIC_COMPLEX, ALPHABETIC, ALPHANUMERIC, or COMPLEX.":
        "Die minimal zulässige Passwortlänge. Ein Wert von 0 bedeutet, dass keine Einschränkung vorliegt. Wird nur erzwungen, wenn die Passwortqualität Numerisch, Numerisch (Komplex), Alphabetisch, Alphanumerisch oder Komplex ist.",
    "Minimum number of letters required in the password. Only enforced when passwordQuality is COMPLEX.":
        'Mindestanzahl der Buchstaben im Passwort. Wird nur erzwungen, wenn die Passwortqualität "Komplex" ist.',
    "Minimum number of lower case letters required in the password. Only enforced when passwordQuality is COMPLEX.":
        'Minimale Anzahl an Kleinbuchstaben im Passwort erforderlich. Wird nur erzwungen, wenn die Passwortqualität "Komplex" ist.',
    "Minimum number of upper case letters required in the password. Only enforced when passwordQuality is COMPLEX.":
        'Mindestanzahl an Großbuchstaben im Passwort. Wird nur erzwungen, wenn die Passwortqualität "Komplex" ist.',
    "Minimum number of non-letter characters (numerical digits or symbols) required in the password. Only enforced when passwordQuality is COMPLEX.":
        'Minimale Anzahl an Nichtbuchstaben (numerische Ziffern oder Symbole), die im Passwort erforderlich sind. Wird nur erzwungen, wenn die Passwortqualität "Komplex" ist.',
    "Minimum number of numerical digits required in the password. Only enforced when passwordQuality is COMPLEX.":
        'Minimale Anzahl an Ziffern im Passwort. Wird nur erzwungen, wenn die Passwortqualität "Komplex" ist.',
    "Minimum number of symbols required in the password. Only enforced when passwordQuality is COMPLEX.":
        'Minimale Anzahl an Symbolen im Passwort. Wird nur erzwungen, wenn die Passwortqualität "Komplex" ist.',
    "Policy enforcement rule": "Regeln zum Erzwingen des Profils",
    "Add rule": "Regel hinzufügen",
    "A rule that defines the actions to take if a device or work profile is not compliant with the policy specified in settingName":
        'Eine Regel, die die auszuführenden Aktionen definiert, wenn ein Gerät oder ein Arbeitsprofil nicht der in "Einstellungsname" angegebenen Richtlinie entspricht',
    "Enforcement rule": "Durchsetzungsregel",
    "The top-level policy to enforce. For example, applications or passwordPolicies.":
        "Die zu erzwingende Richtlinie der obersten Ebene. Zum Beispiel Anwendungen oder Passwortrichtlinien.",
    "Password policies": "Passwortrichtlinien",
    "Add policy": "Richtlinie hinzufügen",
    "Block action": "Blockieren",
    "Block after x days": "Blockieren nach x Tagen",
    "Number of days the policy is non-compliant before the device or work profile is blocked. To block access immediately, set to 0. blockAfterDays must be less than wipeAfterDays.":
        "Anzahl der Tage, an denen die Richtlinie nicht konform ist, bevor das Gerät oder das Arbeitsprofil blockiert wird. Um den Zugriff sofort zu blockieren, setzen Sie den Wert auf 0. Blockieren muss kleiner als Löschen sein.",
    "Wipe action": "Löschen",
    "Preserve FRP": "Werkeinstellungs-Reset-Schutz erhalten",
    "Whether the factory-reset protection data is preserved on the device. This setting doesn’t apply to work profiles.":
        "Wenn aktiviert, wird der Werkeinstellungs-Reset-Schutz im Profil erhalten. Diese Einstellung funktioniert nicht mit Arbeitsprofilen.",
    "Wipe after x days": "Löschen nach x Tagen",
    "Number of days the policy is non-compliant before the device or work profile is wiped. wipeAfterDays must be greater than blockAfterDays.":
        "Anzahl der Tage, bevor das Gerät oder das Arbeitsprofil gelöscht wird. Löschen muss größer sein als Blockieren.",
    "In order to fully delete your Enterprise account visit":
        "Um Ihr komplettes Enterprise Benutzerkonto zu löschen, besuchen Sie die",
    "Google Play Adminsettings": "Google Play Administration",
    "Only lost devices": "Nur verlorene Geräte",
    "Only Enterprise devices": "Nur Enterprise Geräte",
    "Enterprise devices": "Enterprise Geräte",
    Confirmed: "Bestätigt",
    "Not confirmed": "Nicht bestätigt",
    Product: "Produkt",
    Products: "Produkte",
    "Changes on profiles will be applied Immediately. You don't need to publish them manually anymore.":
        "Änderungen an Profilen werden direkt umgesetzt. Sie müssen diese nicht mehr veröffentlichen.",
    Unknown: "Unbekannt",
    "Websites, which are not categorized": "Webseiten, welche nicht kategorisiert sind",
    Casting: "Casting",
    "Pages relating to models, casting, actors and singers":
        "Seiten zum Thema Models, Casting, Schauspielen, Singen",
    Pornography: "Porno und Erotik",
    "Pornographic or primarily sexual content": "Pornographische oder vorwiegend sexuelle Inhalte",
    "Open image search": "Offene Bilder Suche",
    "Image- and video search engines including pornographic search but without 'safe search' switch":
        "Bilder- und Video Suchmaschinen inklusive pornografischer Suche aber ohne 'Safe Search' Schalter",
    "Erotic risk": "Erotik möglich",
    "Reputable websites with potential pornographic content. No porn- or erotic sites.":
        "Seriöse Webseiten mit möglicherweise sexuellen Inhalten. Keine Porno- oder Erotikseiten",
    Weapons: "Waffen",
    "Gun shops, military souvenirs, military content, marksmanship, hunting":
        "Waffenshops, Waffeninfos, Militaria, militärische Inhalte, Sportschützen, Jagd",
    Rotten: "Abstoßend",
    "Extremely bloody, call for or hints on murder, suicide and violence, disgusting content":
        "Extrem blutig, Anleitung oder Aufruf zu Mord, Selbstmord und Gewalt, Ekelerregend",
    "BPJM parental safety": "BPJM Kinder-/Jugendschutz",
    "Content not suitable for children or adolescents according to German BPjM regulation":
        "Content nicht geeignet für Kinder und Jugendliche nach deutscher BPjM",
    Games: "Spiele",
    "Pages related to gaming, gambling, games to download, online games, lottery":
        "Seiten zum Thema Spiele, Spiele zum Download, Online Spiele, Gewinnspiele, Lotto, Glücksspiele",
    "Blogs and communities": "Blogs und Online-Communities",
    "Chats, blogs, forums, boards and communities for any topic":
        "Chatanbieter, Blogs, Foren, Boards und Communities zu diversen Themen",
    Events: "Events",
    "Cultural performances, tickets, events, leisure time activities":
        "Kulturelle Veranstaltungen, Freizeitplanung, Tickets, Events",
    Investments: "Investments",
    "Stocks, bonds, credits, insurances, investments and banking":
        "Aktien, Börse, Investments, Kredite, Versicherungen, Banken",
    "Dubious money making": "Unseriös Geld verdienen",
    "Make money fast, earn money, get rich, equity holdings":
        "Geld schnell verdienen, reich werden, Beteiligungen, Kettenbriefe",
    Education: "Ausbildung",
    "Education, schools, universities, colleges": "Schulen und Ausbildungsinstitute, Universitäten",
    "Health and beauty": "Gesundheit und Schönheit",
    "Health and beauty topics, wellness, diet, cosmetics, clinics, doctors and medical info, clothes and fashion":
        "Themen Gesundheit und Schönheit, medizinische Infos, Ärzte, Kliniken, Abnehmen/Diät, Kosmetik, Wellness, Kleidung und Mode",
    "House and garden": "Haus und Garten",
    "Home, decoration, furniture, gardening and plants, do-it-yourself, hobbies":
        "Themen zur Hauseinrichtung, Garten, Pflanzen, Möbel, Dekoration, Hobbythemen",
    "Software downloads, file downloads, pages offering downloads of any kind":
        "Software Downloads, Dateidownloads",
    Computers: "Computer",
    "Computer advisors, computer technology, computer topics":
        "Computer Ratgeber, Computertechnologie, Computer Themen",
    "Remote maintenance": "Fernwartung",
    "Software for remote administration, desktop sharing":
        "Fernwartungsprogramme, entfernter Desktop, Desktop-Sharing",
    Hacking: "Hacking",
    "Pages regarding hacking, warez, building malware, subscription scams":
        "Ratgeber zum Thema Hacking, Warez, Malware bauen, Systeme überlisten, Abofallen",
    "Threat Intelligence Feed": "Threat Intelligence Feed",
    "Currently dangerous URLs (phishing, malware, botnets, crimeware etc.)":
        "Aktuell als schädlich eingestufte URLs (Phishing, Malware, Botnetze, Crimeware usw.)",
    Proxy: "Proxy",
    "Anon proxies and proxy lists": "Anonymisierungsproxies und Listen dieser Rechner",
    Communication: "Kommunikation",
    "E-Mail providers, SMS and mobile phone services, communication services, provider":
        "E-Mail Anbieter, SMS Dienste, Kommunikationsdienste, Provider",
    "Social bookmarking": "Soziale Lesezeichen",
    "Bookmark services for the WWW": "Lesezeichendienste für das WWW",
    "People search": "Personensuche",
    "Find people, ancestry research": "Personensuchdienste, Ahnenforschung",
    "Social networks": "Soziale Netzwerke",
    Jobs: "Jobs",
    "Find work, jobs, job offerings and trainee programs":
        "Arbeit suchen, Jobsuche, Stellenangebote, Arbeitnehmersuche",
    "Daily news, celebrity news and news of any sort":
        "Nachrichten, News, Tagesthemen, Prominews, Branchennews",
    Dating: "Dating",
    "Dating, find friends, looking for a partner, fall in love":
        "Dating, Kennenlernen, Partnerschaftssuche, Freunde finden",
    Dictionaries: "Lexika",
    "Dictionaries, references, translators, bundled information":
        "Lexika, Nachschlagewerke, Übersetzer, gesammelte Informationen",
    Directories: "Portale",
    "Internet portals and search engines": "Internetportale, Suchmaschinen",
    "Religion and occult": "Religion und Kult",
    "Religion, occult, astrology, esoteric content, horoscopes, ideology, conspiracy theories":
        "Religion, Kult, Astrologie, Esoterik, Horoskope, Ideologie, Verschwörungstheorien",
    Shopping: "Shopping",
    "Shopping sites, buy online": "Shoppingseiten, online Einkaufen",
    Auctions: "Auktionen",
    "Internet auctions, want ads and sell ads": "Internet Auktionen, Kleinanzeigen, Annoncenmarkt",
    "Real estate": "Immobilien",
    "Real estate, houses, apartments, land for sale, rentals":
        "Wohnungs/Haus-Kauf, Vermietungen, Grundstücke",
    "Food and beverages": "Essen und Getränke",
    "Recipes and food, nutrition tips and restaurants": "Rezepte und Ernährung, Essen, Restaurants",
    Sports: "Sport",
    "Sports of any kind, fan sites, sport clubs":
        "Sport jeglicher Art, Ratgeber, Fanseiten, Clubs und Vereine",
    Travel: "Reisen",
    "Travel, online booking, last-minute travels, travel information":
        "Reisen, Buchungsdienste, Last-Minute, Reiseinfos",
    Vehicles: "Fahrzeuge",
    "Vehicles of any kind, car magazines, boating, planes, motorcycles, bicycles":
        "Fahrzeuge jeglicher Art, Automagazine, Motorrad, Boote, Fahrrad",
    Humor: "Humor",
    "Humorous and funny pages": "Witzige Seiten, Satire",
    "Video and images": "Video und Bilder",
    "Video downloads, films, life streams, comics, cinema and theatre, show images":
        "Videos, Filme, Bilder, Comics, Kino- und Filmthemen sowie kategoriebezogene Downloads",
    Audio: "Audio",
    "Audio/music downloads, listen to music, music news, bands":
        "Audio/Musik, Bands und Musikthemen sowie kategoriebezogene Downloads",
    Children: "Kinder",
    "Babys and children, raising kids": "Babys und Kinder, Erziehung",
    Animals: "Tiere",
    "Animal tips and hints, breeding": "Tierratgeber, Tierforen, Züchtung",
    "Consumer technology": "Consumertechnik",
    "Home entertainment, electronics, reviews on cameras, mobiles phones, MP3-players":
        "Home Entertainment, Elektronik, Testberichte und Ratgeber für Digitalkameras, Handys, MP3-Player, Navis,...",
    Shortener: "Kurz-URL-Dienst",
    "URL shorteners": "URL Kürzungs Dienste",
    "Ad server": "Werbe Dienste",
    "Ad server and services": "Werbe Dienste",
    "Update server": "Update Server",
    "Tracking strict": "Tracking strict",
    "Servers that track private information by observing activities on web sites":
        "Server welche private Informationen durch das Beobachten von Aktivitäten auf Webseiten sammeln",
    "Parked websites": "Geparkte Webseiten",
    "Unused domains which are currently for sale":
        "Nicht weiter genutzte Domänen die zum Verkauf stehen",
    "Pages which can't be categorized": "Nicht identifizierte oder nicht kategorisierbare Seiten",
    White: "White",
    "Serious pages, public and corporation-/company sites, technical services which shouldn't be blocked":
        "Seriöse Adressen, öffentliche- und Unternehmensseiten, technische Dienste welche nicht geblockt werden sollten",
    "UTM License expires on this date": "Ablaufdatum der UTM-Lizenz",
    "UTM Model Type": "UTM-Modell",
    Notification: "Benachrichtigung",
    "Add notification": "Benachrichtigung hinzufügen",
    "Edit notification": "Benachrichtigung bearbeiten",
    "delete notification": "Benachrichtigung löschen",
    Topics: "Auslöser",
    "Enrolled device": "Angemeldetes Gerät",
    "Unenrolled device": "Abgemeldetes Gerät",
    "The name of the notification": "Name der Benachrichtigung",
    "Which topics shall trigger a notification?": "Was soll eine Benachrichtigung auslösen?",
    "Choose a type of notification": "Wählen Sie die Art der Benachrichtigung",
    "Enter your pushover username": "Geben Sie Ihren Pushover Benutzernamen ein ",
    "Enter your token": "Geben Sie Ihren Token ein",
    "Select recipients for this notification":
        "Wählen Sie die Empfänger für diese Benachrichtigung",
    "If you want to see usernames, e-mail addresses, IPs etc. in plain text, You have to disable the privacy setting in the Unified Security Portal. This changes the following facts:":
        "Für diesen Tenant ist die Pseudonymisierung aktiv. Wenn Sie Benutzernamen, E-Mail-Adressen, IPs etc. in Klartext sehen möchten, müssen Sie die Datenschutzeinstellung im Unified Security Portal deaktivieren. Dadurch ändern sich folgende Sachverhalte:",
    "In the Securepoint Mobile Security dashboard, the devices in the statistics are no longer pseudonymized.":
        "Im Dashboard der Securepoint Mobile Security sind die Geräte in den Statistiken nicht mehr pseudonymisiert.",
    "Usernames, e-mail addresses, IPs, etc. are no longer anonymous in the report.":
        "Die Benutzernamen, E-Mail-Adressen, IPs etc. sind im Bericht nicht mehr anonymisiert.",
    "In Mobile Security it is possible to create alerts for specific devices.":
        "In der Mobile Security ist es möglich Warnungen für bestimmte Devices anzulegen.",
    "In addition, the anonymization of the services must be disabled on the UTM, otherwise the data will be anonymized on the UTM before being sent to the Unified Security Cloud.":
        "Zusätzlich muss auf der UTM die Anonymisierung der Dienste deaktiviert sein, da ansonsten die Daten auf der UTM anonymisiert werden bevor sie an die Unified Security Cloud gesendet werden.",
    "These settings can not be made automatically on the UTM and must therefore be performed by the administrator.":
        "Diese Einstellungen können nicht automatisch auf der UTM vorgenommen werden und müssen deshalb vom Administrator durchgeführt werden.",
    "Starting with the Android 10, the Legacy Enrollment can not be used anymore. Please upgrade to Android Enterprise.":
        "Ab Android 10 kann die alte Geräteanmeldung nicht mehr verwendet werden. Bitte steigen Sie auf Android Enterprise um.",
    "The app tracks for Android Device Policy the device can access. The device receives the latest version among all accessible tracks. If no tracks are specified, then the device only uses the production track.":
        "Die App-Spur auf die das Geräte mit der Richtlinie zugreifen kann. Das Gerät empfängt die neueste Version aller verfügbaren Titel. Wenn keine Spuren angegeben sind, verwendet das Gerät nur die Produktionsspur.",
    "The default policy for all permissions requested by the app. If specified, this overrides the policy-level defaultPermissionPolicy which applies to all apps. It does not override the permissionGrants which applies to all apps.":
        "Die Standardrichtlinie für alle von der App angeforderten Berechtigungen. Wenn angegeben, wird die für alle Apps geltende Standardberechtigungsrichtlinie auf Richtlinienebene außer Kraft gesetzt. Es überschreibt nicht die Globale Erlaubnisgewährung, die für alle Apps gilt.",
    "Explicit permission grants or denials for the app. These values override the defaultPermissionPolicy and permissionGrants which apply to all apps.":
        "Erteilt explizite Erlaubnis oder Verweigerung für die App. Diese Werte überschreiben die Standardberechtigungsrichtlinie und die globalen Berechtigungsbeschränkungen, die für alle Apps gelten.",
    "Managed configuration applied to the app. The format for the configuration is dictated by the ManagedProperty values supported by the app. Each field name in the managed configuration must match the key field of the ManagedProperty. The field value must be compatible with the type of the ManagedProperty":
        "Verwaltete Konfiguration, die auf die App angewendet wird. Das Format für die Konfiguration richtet sich nach den von der App unterstützten ManagedProperty-Werten. Jeder Feldname in der verwalteten Konfiguration muss mit dem Schlüsselfeld der verwalteten Eigenschaft übereinstimmen. Der Feldwert muss mit dem Typ der ManagedProperty kompatibel sein",
    "This field is ignored if managedConfiguration is set.":
        "Dieses Feld wird ignoriert, wenn die verwaltete Konfiguration festgelegt ist.",
    "Whether the app is disabled. When disabled, the app data is still preserved.":
        "Ob die App deaktiviert ist. Bei Deaktivierung bleiben die App-Daten weiterhin erhalten.",
    "The minimum version of the app that runs on the device. If set, the device attempts to update the app to at least this version code. If the app is not up-to-date, the device will contain a Non Compliance Detail with non Compliance Reason set to APP_NOT_UPDATED. The app must already be published to Google Play with a version code greater than or equal to this value. At most 20 apps may specify a minimum version code per policy.":
        "Die Mindestversion der App, die auf dem Gerät ausgeführt wird. Wenn festgelegt, versucht das Gerät, die App mindestens auf diesen Versionscode zu aktualisieren. Wenn die App nicht auf dem neuesten Stand ist, enthält das Gerät ein Nicht-Konformitätsdetail mit dem Nicht-Konformitätsgrund APP_NOT_UPDATED. Die App muss bereits mit einem Versionscode größer oder gleich diesem Wert in Google Play veröffentlicht sein. Maximal 20 Apps können einen Mindestversionscode pro Richtlinie festlegen.",
    "The minimum version of the app that runs on the device. If set, the device attempts to update the app to at least this version code. If the app is not up-to-date, the device will contain a NonComplianceDetail with nonComplianceReason set to APP_NOT_UPDATED. The app must already be published to Google Play with a version code greater than or equal to this value. At most 20 apps may specify a minimum version code per policy.":
        "Die Mindestversion der App, die auf dem Gerät ausgeführt wird. Wenn festgelegt, versucht das Gerät, die App mindestens auf diesen Versionscode zu aktualisieren. Wenn die App nicht auf dem neuesten Stand ist, enthält das Gerät ein Nicht-Konformitätsdetail mit dem Nicht-Konformitätsgrund APP_NOT_UPDATED. Die App muss bereits mit einem Versionscode größer oder gleich diesem Wert in Google Play veröffentlicht sein. Maximal 20 Apps können einen Mindestversionscode pro Richtlinie festlegen.",
    "Non Compliance": "Nichteinhaltung",
    "Non compliance": "Nichteinhaltung",
    "The scopes delegated to the app from Android Device Policy.":
        "Die hier ausgewählten Berechtigungen werden vom Device Policy Controller an die App delegiert.",
    "Check your Apple Business Account": "Überprüfen Sie Ihren Apple Business Account",
    "We retrieved an error while fetching your data from Apple":
        "Wir haben eine Fehlermeldung von Apple beim Abholen Ihrer Daten erhalten",
    "This could happen due to updated software license agreements.":
        "Dies könnte passieren, wenn sich der Softwarelizenzvertrag geändert hat.",
    "Please check your Apple Business Account, for further information.":
        "Bitte überprüfen Sie Ihren Apple Business Account, um weitere Informationen zu erhalten.",
    "Please extend your Apple DEP-token": "Bitte verlängern Sie Ihren Apple DEP-Token",
    "Policy not specified. If no policy is specified for a permission at any level, then the prompt behavior is used by default.":
        "Richtlinie nicht angegeben. Wenn für eine Berechtigung auf keiner Ebene eine Richtlinie angegeben ist, wird standardmäßig 'Nachfragen' verwendet.",
    "Your apple DEP-Token is invalid": "Ihr apple DEP-Token ist ungültig",
    "It seems like your Apple DEP-token is invalid":
        "Es scheint als wenn Ihr Apple DEP-Token abgelaufen ist",
    "Upload a new one": "Einen neuen hochladen",
    "Hide password": "Passwort verstecken",
    "Show password": "Passwort anzeigen",
    "Please add an Android Enterprise Account":
        "Bitte fügen Sie einen Android Enterprise Account hinzu",
    "In order to use Securepoint Mobile Security with Android Enterprise you need to add an Android Enterprise Account first.":
        "Um Securepoint Mobile Security mit Android Enterprise benutzen zu können, müssen Sie zuerst einen Android Enterprise Account hinzufügen.",
    "Please go to the account info page and add your Android Enterprise Account or create a new one.":
        "Bitte gehen Sie auf die Kontoinformationsseite und fügen Sie einen Android Enterprise Account hinzu oder erstellen Sie einen neuen.",
    Accountinfo: "Kontoinformationen",
    Header: "Kopfzeile",
    "Cover-Sheet": "Deckblatt",
    Footer: "Fußzeile",
    Color: "Farbe",
    "Select a color for the header": "Wählen Sie eine Farbe für die Kopfzeile",
    "Select an image for the header. Accepted filetypes: jpg, jpeg & png (300x30px). Larger images will be rescaled.":
        "Wählen Sie ein Bild für die Kopfzeile. Akzeptierte Dateiformate: jpg, jpeg & png (300x30px). Größere Bilder werden skaliert.",
    "Select an image for the cover-sheet. Accepted filetypes: jpg, jpeg & png (280x140px). Larger images will be rescaled.":
        "Wählen Sie ein Bild für das Deckblatt. Akzeptierte Dateiformate: jpg, jpeg & png (280x140px). Größere Bilder werden skaliert.",
    "Define the text for the cover-sheet of this report":
        "Definieren Sie einen Text für das Deckblatt",
    "Select a color for the footer": "Wählen Sie eine Farbe für die Fußzeile",
    "Add logo": "Logo hinzufügen",
    "Change logo": "Logo ändern",
    "Delete logo": "Logo löschen",
    "Edit UTM": "UTM bearbeiten",
    "UTM Name": "UTM-Name",
    "Shows wether the UTM is licensed or not": "Zeigt, ob die UTM lizenziert wurde.",
    Licensed: "Lizenziert",
    "Not specified": "Nicht spezifiziert",
    "No encryption required": "Keine Verschlüsselung erforderlich",
    "Encryption required but no password required to boot":
        "Verschlüsselung ist erforderlich, jedoch wird kein Passwort zum Booten benötigt",
    "Encryption required with password required to boot":
        "Verschlüsselung ist erforderlich und ein Passwort zum Booten wird benötigt",
    "This value is ignored.": "Dieser Wert wird ignoriert",
    "Power source is an AC charger.": "Die Energiequelle ist ein Ladegerät",
    "Power source is a USB port.": "Die Energiequelle ist ein USB-Port",
    "Power source is wireless.": "Die Energiequelle ist Kabellos",
    "Unspecified. Defaults to Available.": "Nicht definiert. Gleichzusetzen mit Verfügbar.",
    "The app is automatically installed and can be removed by the user.":
        "Die App wird automatisch installiert und kann vom Benutzer gelöscht werden.",
    "The app is automatically installed and can't be removed by the user.":
        "Die App wird automatisch installiert und kann nicht vom Benutzer gelöscht werden.",
    "The app is blocked and can't be installed. If the app was installed under a previous policy, it will be uninstalled.":
        "Die App wird blockiert und kann nicht installiert werden. Wenn die App über ein altes Profil installiert wurde, wird diese deinstalliert.",
    "The app is available to install.": "Die App steht zur installation bereit.",
    "The app is automatically installed and can't be removed by the user and will prevent setup from completion until installation is complete.":
        "Die App wird automatisch installiert, kann nicht vom Benutzer gelöscht werden und verhindert die Einrichtung des Geräts, bis die App installiert wurde.",
    "The app is automatically installed in kiosk mode: it's set as the preferred home intent and allowed for lock task mode. Device setup won't complete until the app is installed. After installation, users won't be able to remove the app. You can only set this installType for one app per policy. When this is present in the policy, status bar will be automatically disabled.":
        "Die App wird automatisch im Kioskmodus installiert: Sie ist als bevorzugte Ausgangsart festgelegt und für den Sperren-Task-Modus auf die allowlist gesetzt. Das Geräte-Setup wird erst abgeschlossen, wenn die App installiert ist. Nach der Installation können Benutzer die App nicht mehr entfernen. Sie können diesen installationstyp nur für eine App pro Richtlinie festlegen. Wenn dies in der Richtlinie vorhanden ist, wird die Statusleiste automatisch deaktiviert.",
    "Automatically grant a permission.": "Berechtigung automatisch erteilen",
    "Automatically deny a permission.": "Berichtigung automatisch verweigern",
    "No delegation scope specified.": "Kein Delegierungsbereich angegeben.",
    "Grants access to certificate installation and management.":
        "Gewährt Zugriff auf die Installation und Verwaltung von Zertifikaten.",
    "Grants access to managed configurations management.":
        "Gewährt Zugriff auf die Verwaltung verwalteter Konfigurationen.",
    "Grants access to blocking uninstallation.":
        "Gewährt Zugriff auf das Blockieren der Deinstallation.",
    "Grants access to permission policy and permission grant state.":
        "Gewährt Zugriff auf die Berechtigungsrichtlinie und den Berechtigungsstatus.",
    "Grants access to package access state.": "Gewährt Zugriff auf den Paketzugriffsstatus.",
    "Grants access for enabling system apps.":
        "Erteilt den Zugriff zum Aktivieren von System-Apps.",
    "Users choice": "Benutzerauswahl",
    "The auto-update policy is not set. Equivalent to Users choice.":
        "Die Auto-Update-Richtlinie ist nicht festgelegt. Entspricht der Benutzerauswahl.",
    "The user can control auto-updates.": "Der Benutzer kann die automatischen Updates steuern.",
    "Apps are never auto-updated.": "Apps werden niemals automatisch aktualisiert.",
    "Apps are auto-updated over Wi-Fi only.": "Apps werden nur über WLAN automatisch aktualisiert.",
    "Apps are auto-updated at any time. Data charges may apply.":
        "Apps werden jederzeit automatisch aktualisiert. Datengebühren können anfallen.",
    "Prompt the user to grant a permission.":
        "Fordern Sie den Benutzer auf, eine Berechtigung zu erteilen.",
    "The production track, which provides the latest stable release.":
        "Die Produktionsspur, die die neueste stabile Version bietet.",
    "The beta track, which provides the latest beta release.":
        "Der Beta-Track, der die neueste Beta-Version enthält.",
    "Unspecified. Defaults to Allowlist.": "Nicht spezifiziert. Standardmäßig Allowlist.",
    "Only apps that are in the policy are available and any app not in the policy will be automatically uninstalled from the device.":
        "Es sind nur Apps in der Richtlinie verfügbar. Alle Apps, die nicht in der Richtlinie enthalten sind, werden automatisch vom Gerät deinstalliert.",
    "All apps are available and any app that should not be on the device should be explicitly marked as 'Blocked' in the applications policy.":
        "Alle Apps sind verfügbar. Alle Apps, die sich nicht auf dem Gerät befinden sollten, sollten in der Anwendungsrichtlinie ausdrücklich als 'Blockiert' gekennzeichnet werden.",
    "The current device value is not modified.": "Der aktuelle Gerätewert wird nicht geändert.",
    "The current device value is not modified. The user can enable or disable the flight mode.":
        "Der aktuelle Gerätewert wird nicht geändert. Der Nutzer kann den Flugmodus aktivieren oder deaktivieren.",
    "All location detection methods are enabled, including GPS, networks, and other sensors.":
        "Alle Methoden zur Standorterkennung sind aktiviert, einschließlich GPS, Netzwerke und anderer Sensoren.",
    "Only GPS and other sensors are enabled.": "Nur GPS und andere Sensoren sind aktiviert.",
    "Only the network location provider is enabled.":
        "Nur der Netzwerkstandortanbieter ist aktiviert.",
    "Location detection is disabled.": "Die Standorterkennung ist deaktiviert.",
    "Disable the camera on secure keyguard screens (e.g. PIN).":
        "Deaktivieren Sie die Kamera auf sicheren Keyguard-Bildschirmen (z. B. PIN).",
    "Disable showing all notifications on secure keyguard screens.":
        "Deaktivieren Sie die Anzeige aller Benachrichtigungen auf sicheren Keyguard-Bildschirmen.",
    "Disable unredacted notifications on secure keyguard screens.":
        "Deaktivieren Sie nicht umgesetzte Benachrichtigungen auf sicheren Keyguard-Bildschirmen.",
    "Ignore trust agent state on secure keyguard screens.":
        "Ignorieren Sie den Status des Vertrauensagenten auf sicheren Keyguard-Bildschirmen.",
    "Disable fingerprint sensor on secure keyguard screens.":
        "Deaktivieren Sie den Fingerabdrucksensor auf sicheren Keyguard-Bildschirmen.",
    "Disable text entry into notifications on secure keyguard screens.":
        "Deaktivieren Sie die Texteingabe in Benachrichtigungen auf sicheren Keyguard-Bildschirmen.",
    "Disable face authentication on secure keyguard screens.":
        "Deaktivieren Sie die Gesichtsauthentifizierung auf sicheren Keyguard-Bildschirmen.",
    "Disable iris authentication on secure keyguard screens.":
        "Deaktivieren Sie die Iris-Authentifizierung auf sicheren Keyguard-Bildschirmen.",
    "Disable all biometric authentication on secure keyguard screens.":
        "Deaktivieren Sie die gesamte biometrische Authentifizierung auf sicheren Keyguard-Bildschirmen.",
    "Disable all current and future keyguard customizations.":
        "Deaktivieren Sie alle aktuellen und zukünftigen Keyguard-Anpassungen.",
    "Follow the default update behavior for the device, which typically requires the user to accept system updates.":
        "Befolgen Sie das Standard-Update-Verhalten für das Gerät, für das der Benutzer normalerweise System-Updates akzeptieren muss.",
    "Install automatically as soon as an update is available.":
        "Automatisch installieren, sobald ein Update verfügbar ist.",
    "Install automatically within a daily maintenance window. This also configures Play apps to be updated within the window. This is strongly recommended for kiosk devices because this is the only way apps persistently pinned to the foreground can be updated by Play.":
        "Automatische Installation innerhalb eines täglichen Wartungsfensters. Dadurch wird auch konfiguriert, dass Play-Apps innerhalb des Fensters aktualisiert werden. Dies wird für Kioskgeräte dringend empfohlen, da nur so Apps, die dauerhaft im Vordergrund bleiben, von Play aktualisiert werden können.",
    "Postpone automatic install up to a maximum of 30 days.":
        "Verschieben Sie die automatische Installation auf maximal 30 Tage.",
    "There are no password requirements.": "Es gibt keine Passwortanforderungen.",
    "The device must be secured with a low-security biometric recognition technology, at minimum. This includes technologies that can recognize the identity of an individual that are roughly equivalent to a 3-digit PIN (false detection is less than 1 in 1,000":
        "Das Gerät muss mindestens mit einer biometrischen Erkennungstechnologie mit geringer Sicherheit gesichert werden. Dies schließt Technologien ein, die die Identität einer Person erkennen können, die in etwa einer dreistelligen PIN entspricht (falsche Erkennung ist weniger als 1 von 1.000)",
    "A password is required, but there are no restrictions on what the password must contain.":
        "Ein Passwort ist erforderlich, es gibt keine Einschränkungen, was das Passwort enthalten muss.",
    "The password must contain numeric characters.":
        "Das Passwort muss numerische Zeichen enthalten.",
    "The password must contain numeric characters with no repeating (4444) or ordered (1234, 4321, 2468) sequences.":
        "Das Passwort muss numerische Zeichen ohne wiederholte (4444) oder geordnete (1234, 4321, 2468) Folgen enthalten.",
    "The password must contain alphabetic (or symbol) characters.":
        "Das Passwort muss Buchstaben (oder Symbole) enthalten.",
    "The password must contain both numeric and alphabetic (or symbol) characters.":
        "Das Passwort muss sowohl numerische als auch alphabetische (oder symbolische) Zeichen enthalten.",
    "The password must contain at least a letter, a numerical digit and a special symbol. Other password constraints, for example, Minimum letters are enforced.":
        "Das Passwort muss mindestens einen Buchstaben, eine Ziffer und ein spezielles Symbol enthalten. Andere Passworteinschränkungen wie z. B. Mindestlänge, werden erzwungen.",
    "Unspecified: this policy has no requirements for the password.":
        "Nicht angegeben: Diese Richtlinie stellt keine Anforderungen an das Passwort.",
    "Something: this policy requires some kind of password or pattern, but doesn't care what it is.":
        "Etwas: Diese Richtlinie erfordert eine Art Passwort oder Muster, aber es ist egal, was es ist.",
    "Numeric: the password has to contain at least numeric characters.":
        "Numerisch: Das Passwort muss mindestens numerische Zeichen enthalten.",
    "Numeric complex: the password has to contain at least numeric characters with no repeating (4444) or ordered (1234, 4321, 2468) sequences.":
        "Numerischer Komplex: Das Passwort muss mindestens numerische Zeichen ohne wiederholte (4444) oder geordnete (1234, 4321, 2468) Folgen enthalten.",
    "Alphabetic: the password has to contain at least alphabetic (or other symbol) characters.":
        "Alphabetisch: Das Passwort muss mindestens alphabetische (oder andere Symbol-) Zeichen enthalten.",
    "Alphanumeric: the password has to contain at least numeric and alphabetic (or other symbol) characters.":
        "Alphanumerisch: Das Passwort muss mindestens numerische und alphabetische Zeichen (oder andere Zeichen) enthalten.",
    'Saving this profile removes the Securepoint Mobile Security App from all assigned devices. Change the profile for all devices without a "Mobile Security" license in order to be able to edit this profile without errors.':
        'Das Speichern des Profils entfernt die Securepoint Mobile Security App von allen zugewiesenen Geräten. Ändern Sie das Profil für alle Geräte ohne "Mobile Security"-Lizenz, um dieses Profil fehlerfrei bearbeiten zu können.',
    "Warning! Mobile Security is activated in this profile, but it contains devices that are not licensed for Mobile Security. Please note that these devices will not receive Mobile Security.":
        "Warnung! In diesem Profil ist Mobile Security aktiviert, es enthält jedoch Geräte, die nicht für Mobile Security lizenziert sind. Bitte beachten Sie, dass diese Geräte kein Mobile Security erhalten werden.",
    "Report branding settings": "Bericht-Branding-Einstellungen",
    Settings: "Einstellungen",
    "Use branding": "Branding nutzen",
    "Defines wether these branding settings are used or not":
        "Legt fest ob diese Branding-Einstellungen genutzt werden",
    "Exclude client-reports": "Kundenberichte ausnehmen",
    "Selected reports won't use this branding":
        "Auf ausgewählten Berichten wird das Branding nicht angewandt",
    "Select Reports": "Berichte auswählen",
    "Download preview": "Vorschau herunterladen",
    "Branding preview": "Branding Vorschau",
    "Current preview": "Aktuelle Vorschau",
    Download: "Herunterladen",
    "Generate new Preview": "Neue Vorschau generieren",
    "Creation date": "Erstellungsdatum",
    "PARTIALLY INSTALLED": "TEILWEISE INSTALLIERT",
    "Device policy": "Geräte Profil",
    "Choose a policy for this device.": "Wählen Sie ein Profil für dieses Gerät.",
    Setting: "Einstellung",
    "Installation failure reason": "Ursache des Installationsfehlers",
    "select features": "Funktionen auswählen",
    "Select features": "Funktionen auswählen",
    "Please enter 2 or more characters": "Geben Sie mindestens 2 Zeichen ein",
    "Incoming mails ": "Eingehende E-Mails",
    "Outgoing mails ": "Ausgehende E-Mails",
    "Incoming mails": "Eingehende E-Mails",
    "Outgoing mails": "Ausgehende E-Mails",
    "These apps can't be used with Android Enterprise devices.":
        "Diese Apps können nicht mit Android Enterprise Geräten verwendet werden.",
    "Non compliance details": "Details zur Nichteinhaltung der Richtlinien",
    "Edit licenses": "Lizenzen bearbeiten",
    "Show expired licenses": "Abgelaufene Lizenzen anzeigen",
    "Hide expired licenses": "Abgelaufene Lizenzen ausblenden",
    "Please select a license": "Bitte wählen Sie eine Lizenz",
    "Please select a profile": "Bitte wählen Sie ein Profil",
    "Select a web clip": "Wählen Sie einen Web clip",
    "Enrollment of a user-owned device": "Anmeldung eines benutzereigenen Geräts",
    "The enrollment of user-owned devices is possible with the help of a managed Apple ID.":
        "Die Anmeldung von benutzereigenen Geräten ist mit Hilfe eine verwalteten Apple-ID möglich.",
    "Enter your managed Apple ID": "Geben Sie Ihre verwaltete Apple-ID ein",
    "Please enter a valid managed Apple ID": "Bitte geben Sie eine gültige verwaltete Apple-ID ein",
    "An unexpected error occurred": "Ein unerwarteter Fehler ist aufgetreten",
    "Android Enterprise Services experienced an unexpected error":
        "In dem Android Enterprise Dienst ist ein unerwarteter Fehler aufgetreten",
    "Enrollment type": "Anmeldungstyp",
    "iOS device enrollment": "iOS Geräteanmeldung",
    "iOS-Profiles": "iOS Profile",
    "iOS-Devices": "iOS Geräte",
    "iOS Devices": "iOS Geräte",
    "iOS-Apps": "iOS Apps",
    "Enterprise-Devices": "Enterprise-Geräte",
    "Android-Profiles": "Android Profile",
    "iOS user enrollment": "iOS Benutzeranmeldung",
    "Android Enterprise enrollment": "Android Enterprise Anmeldung",
    "Delete Device Limit": "Gerätelimit Löschen",
    "If you delete the device limit the tenant can enroll the maximum amount of devices from the license. The maximum amount is %s":
        "Wenn Sie das Gerätelimit löschen, können Kunden die maximale Anzahl von Geräten aus der Lizenz registrieren. Die maximale Anzahl ist %s",
    "Choose an enrollent token": "Wählen Sie einen Enrollmenttoken",
    "Please select an enrollment token": "Bitte wählen Sie einen Enrollmenttoken",
    "Choose or enter a managed Apple ID":
        "Wählen Sie eine verwaltete Apple-ID oder geben Sie eine ein",
    "Not licensed": "Nicht lizenziert",
    "Waiting for device info": "Warte auf Geräteinfo",
    "License conflict": "Lizenzkonflikt",
    "Disable VPN for apps": "VPN für Apps deaktivieren",
    "Exclude apps from VPN": "Apps von VPN ausschließen",
    "Enter packagenames of apps that should bypass the VPN service":
        "Tragen Sie die Paketnamen der Apps ein, die den VPN-Dienst umgehen sollen",
    "Enter packagename": "Paketnamen eingeben",
    Packagename: "Paketname",
    "Add packagenames": "Paketnamen hinzufügen",
    "Creates or links an Apple VPP User to this user":
        "Erstellt oder verknüpft einen Apple VPP Benutzer mit diesem Benutzer",
    "Apple VPP assets": "Apple VPP Bestand",
    "Assign licenses": "Lizenzen zuweisen",
    "Assign license": "Lizenz zuweisen",
    "Available licenses": "Verfügbare Lizenzen",
    Pricing: "Preisgestaltung",
    "Apple VPP users": "Apple VPP Benutzer",
    "Apple VPP User": "Apple VPP Benutzer",
    "Add Apple VPP user": "Apple VPP Benutzer hinzufügen",
    "Retire Apple VPP user": "Apple VPP Benutzer abmelden",
    "The user's email address. After creating a new Apple VPP User an invitation will be sent to this users email adress.":
        "Die E-Mail-Adresse des Benutzers. Nach dem Erstellen des neuen Benutzers, wird eine Einladung an die E-Mail-Adresse des Apple VPP Nutzers gesendet.",
    "The Apple ID associated with the user. This ID's organization must match your organization.":
        "Die Apple ID die mit diesem Benutzer verknüpft sein soll. Die Organisiation der ID muss mit Ihrer übereinstimmen.",
    "Link with Apple VPP": "Mit Apple VPP verknüpfen",
    "Create a new Apple VPP User": "Einen neuen Apple VPP Benutzer erstellen",
    "After creating a new Apple VPP User an invitation will be sent to this users email adress.":
        "Nach dem Erstellen des neuen Apple VPP Benutzers, wird eine Einladung an die E-Mail-Adresse des Nutzers gesendet.",
    "Apple VPP Licenses": "Apple VPP Lizenzen",
    "Apple VPP License": "Apple VPP Lizenz",
    "VPP Licenses": "VPP Lizenzen",
    "VPP License": "VPP Lizenz",
    Price: "Preis",
    Free: "Gratis",
    News: "Neuigkeiten",
    "Developer Website": "Entwickler Website",
    "License assignment": "Lizenzzuordnung",
    "Unknown device": "Unbekanntes Gerät",
    "Edit Apple VPP user": "Apple VPP Benutzer bearbeiten",
    Assignment: "Zuordnung",
    "Assignable devices": "Zuordenbare Gertäte",
    "Assigned devices": "Zugeordnete Geräte",
    "Do you want to assign that device to this license?":
        "Wollen Sie das Gerät wirklich dieser Lizenz zuordnen?",
    "Assigned users": "Zugeordnete Benutzer",
    "Max. users": "Max. Benutzer",
    "Allowed users": "Erlaubte Benutzer",
    "Set up": "Eingerichtet",
    'Use "Volume Purchase Program App Assignment" to install 1st party apps (Mail, Safari, etc.) without users being logged into the iTunes Store.':
        'Verwenden Sie das "Volume Purchase Program App Assignment", um Apps von Drittanbietern (Mail, Safari usw.) zu installieren, ohne dass Benutzer im iTunes Store angemeldet sind.',
    "The bitwise OR of the following flags: 1:Remove app when MDM profile is removed. 4:Prevent backup of the app data.":
        "Das bitweise ODER der folgenden Flags: 1: App entfernen, wenn das MDM-Profil entfernt wird. 4: Verhindern Sie die Sicherung der App-Daten.",
    "Both disabled": "Beides deaktiviert",
    "Both enabled": "Beides aktiviert",
    "Prevent backup of the app data": "Ein Backup der Appdaten verhindern",
    "Purchase Method": "Kaufmethode",
    "No enrollment token found": "Kein Enrollment Token gefunden",
    "Create an enrollment token first, in order to invite/enroll android enterprise devices.":
        "Erstellen Sie zuerst einen Enrollment Token um ein Android Enterprise Gerät anzumelden",
    "Open enrollment dialog": "Geräteanmeldung öffnen",
    DELETE: "LÖSCHEN",
    "Do you really want to delete your VPP-Token?": "Wollen Sie Ihren VPP-Token wirklich löschen?",
    "Do you really want to delete your DEP-Token?": "Wollen Sie Ihren DEP-Token wirklich löschen?",
    "Allow Account Modification": "Erlaube die Account Modifizierung",
    "If set to false, account modification is disabled.":
        "Wenn inaktiv, wird die Account Modifizierung deaktiviert.",
    "Allow QuickPath Keyboard": "QuickPath-Tastatur zulassen",
    "If set to false, disables QuickPath keyboard":
        "Wenn inaktiv, wird die QuickPath-Tastatur deaktiviert",
    "Allow Network access for Files": "Netzwerkzugriff für Dateien zulassen",
    "If set to false, prevents connecting to network drives in the Files app":
        "Wenn inaktiv, wird die Verbindung zu Netzwerklaufwerken in der Datei-App verhindert",
    "Allow USB drive for Files": "USB-Laufwerk für Dateien zulassen",
    "If set to false, prevents connecting to any connected USB devices in the Files app":
        "Wenn inaktiv, wird das Herstellen einer Verbindung zu angeschlossenen USB-Geräten in der Datei-App verhindert",
    "Allow Find My Device": "Find My Device erlauben",
    "If set to false, disables Find My Device in the Find My app":
        "Wenn inaktiv, wird Find My Device in der Find My App deaktiviert",
    "Allow Find My Friends": "Find My Friends erlauben",
    "If set to false, disables Find My Friends in the Find My app":
        "Wenn inaktiv, wird Find My Friends in der Find My App deaktiviert",
    "Force WiFi on": "Erzwinge aktiviertes Wi-Fi",
    "If set to true, prevents Wi-Fi from being turned off in Settings or Control Center, even by entering or leaving Airplane Mode. It does not prevent selecting which Wi-Fi network to use":
        "Verhindert, dass Wi-Fi in den Einstellungen oder im Control Center deaktiviert wird. Dies gilt auch, wenn der Flugzeugmodus aktiviert oder deaktiviert wird. Es verhindert nicht die Auswahl des zu verwendenden Wi-Fi-Netzwerks",
    "Do you really  want to delete selected objects?":
        "Wollen Sie die ausgewählten wirklich löschen?",
    "Sync with Apple DEP": "Mit Apple DEP synchronisieren",
    "Sync with Apple VPP": "Mit Apple VPP synchronisieren",
    "Shared device configuration": "Konfiguration des gemeinsam genutzten Geräts",
    "Shared device": "Gemeinsam genutztes Gerät",
    "After setting this, you can set the shared device configuration. The Shared Device Configuration Payload allows you to specify optional text displayed on the login window and lock screen (i.e. a ”If Lost, Return To” message and Asset Tag Information). It is supported on iOS 9.3 and later.":
        'Nach der Aktivierung, können Sie die Konfiguration des gemeinsam genutzten Geräts festlegen. Mit den Optionen für die Konfiguration gemeinsam genutzter Geräte können Sie optionalen Text angeben, der im Anmeldefenster und im Sperrbildschirm angezeigt wird (z. B. Eine Meldung "Wenn verloren, Zurück zu" und Informationen zu Asset-Tags). Es wird ab iOS 9.3 und höher unterstützt.',
    "Asset Tag Information": "Asset-Tag-Informationen",
    "Optional. Asset tag information for the device, displayed on the login window and lock screen":
        "Optional. Asset-Tag-Informationen für das Gerät, die im Anmeldefenster und im Sperrbildschirm angezeigt werden",
    "Lockscreen footnote": "Fußnote des Sperrbildschirms",
    "Optional. A footnote displayed on the login window and lock screen":
        "Optional. Eine Fußnote, die im Anmeldefenster und im Sperrbildschirm angezeigt wird",
    "Enter infos": "Geben Sie Infos ein",
    "Allign license": "Lizenz zuweisen",
    "One or more devices are unlicensed, please assign a license to each of your devices.":
        "Ein oder mehrere Geräte sind unlizenziert, bitte weisen Sie allen Ihren Geräten eine Lizenz zu.",
    "If set to true, delays user visibility of Software Updates.":
        "Wenn aktiv, wird die Sichtbarkeit von Software-Updates durch den Benutzer verzögert.",
    "Software Update Delay in days": "Verzögerung der Software-Updates in Tagen",
    "This restriction allows the admin to set how many days a software or app update on the device will be delayed. With this restriction in place, the user will not see a software update until the specified number of days after the software update release date.":
        "Mit dieser Einschränkung kann der Administrator festlegen, um wie viele Tage sich ein Software- oder App-Update auf dem Gerät verzögert. Mit dieser Einschränkung wird dem Benutzer ein Software-Update erst nach der angegebenen Anzahl von Tagen nach dem Veröffentlichungsdatum des Software-Updates angezeigt..",
    "Classroom app": "Classroom-App",
    "Allow remote screen observation": "Remote-Bildschirmbeobachtung zulassen",
    "If disallowed, disables remote screen observation by the Classroom app. If screenshots are disabled, the Classroom app doesn\\'t observe remote screens.":
        "Wenn nicht erlaubt, wird die Remote-Bildschirmbeobachtung durch die Classroom-App deaktiviert. Wenn Screenshots deaktiviert sind, beobachtet die Classroom-App keine Remote-Bildschirme.",
    "Force to automatically join Classes": "Automatischen Beitritt von Kursen erzwingen",
    "If forced, automatically gives permission to the teacher\\'s requests without prompting the student.":
        "Wenn erzwungen, werden die Anfragen des Lehrers automatisch akzeptiert, ohne dass der Schüler dazu aufgefordert wird.",
    "Force permission to leave classes": "Verlassen von Kursen nur mit Genehmigung",
    "If forced, a student enrolled in an unmanaged course through Classroom requests permission from the teacher when attempting to leave the course":
        "Wenn aktiviert, muss ein Schüler, um den Kurs zu Verlassen, den Lehrer um Erlaubnis bitten. Gilt nur für nicht verwaltete Kurse.",
    "Force app & device lock": "Erzwingen der App- und Gerätesperre",
    "If forced, allows the teacher to lock apps or the device without prompting the student":
        "Wenn erzwungen, kann der Lehrer Apps oder das Gerät sperren, ohne den Schüler dazu aufzufordern",
    "Force screen observation": "Bildschirmbeobachtung erzwingen",
    "If forced and remote screen observation is allowed in the Education payload, a student enrolled in a managed course via the Classroom app automatically gives permission to that course teacher\\'s requests to observe the student\\'s screen without prompting the student.":
        "Wenn erzwungen wird und eine Fernüberwachung des Bildschirms erlaubt ist, erteilt ein Schüler, der über die Classroom-App in einem verwalteten Kurs eingeschrieben ist, automatisch die Erlaubnis, den Bildschirm zu beobachten, ohne aufgefordert zu werden.",
    Seconds: "Sekunden",
    Lost: "Verloren",
    "Freeze periods": "Einfrierperioden",
    "Add period": "Periode hinzufügen",
    "Start of Period": "Beginn der Periode",
    "End of period": "Ende der Periode",
    Start: "Start",
    End: "Ende",
    "An annually repeating time period in which over-the-air (OTA) system updates are postponed to freeze the OS version running on a device. To prevent freezing the device indefinitely, each freeze period must be separated by at least 60 days.":
        "Ein sich jährlich wiederholender Zeitraum, in dem OTA-Systemaktualisierungen (Over-the-Air) verschoben werden, um die auf einem Gerät ausgeführte Betriebssystemversion einzufrieren. Um ein unbegrenztes Einfrieren des Geräts zu verhindern, muss jede Einfrierperiode mindestens 60 Tage voneinander entfernt sein.",
    "Untrusted apps policy": "Richtlinie für nicht vertrauenswürdige Apps",
    "The policy for untrusted apps (apps from unknown sources) enforced on the device.":
        "Die Richtlinie für nicht vertrauenswürdige Apps (Apps aus unbekannten Quellen), die auf dem Gerät durchgesetzt werden.",
    "Unspecified. Defaults to disallow.": "Nicht spezifiziert. Standardmäßig nicht erlaubt.",
    Allow: "Erlauben",
    "Allow untrusted app installs on entire device.":
        "Nicht vertrauenswürdige App-Installationen auf dem gesamten Gerät zulassen.",
    Disallow: "Nicht erlauben",
    "Default. Disallow untrusted app installs on entire device.":
        "Standard. Nicht vertrauenswürdige App-Installationen auf dem gesamten Gerät verbieten.",
    "Allow in personal profiles only": "Nur in persönlichen Profilen zulassen",
    "For devices with work profiles, allow untrusted app installs in the device\\'s personal profile only.":
        "Lassen Sie bei Geräten mit Arbeitsprofilen nicht vertrauenswürdige App-Installationen nur im persönlichen Profil des Geräts zu.",
    "Power button actions": "Power-Button-Aktionen",
    "Sets the behavior of a device in kiosk mode when a user presses and holds the Power button.":
        "Legt das Verhalten eines Geräts im Kioskmodus fest, wenn ein Benutzer die Ein- / Aus-Taste drückt und gedrückt hält.",
    "Unspecified, defaults to available.": "Nicht angegeben, standardmäßig verfügbar.",
    "The power menu (e.g. Power off, Restart) is shown when a user long-presses the Power button of a device in kiosk mode.":
        "Das Ein- / Ausschaltmenü (z. B. Ausschalten, Neustart) wird angezeigt, wenn ein Benutzer im Kioskmodus die Ein- / Aus-Taste eines Geräts lange drückt.",
    "The power menu (e.g. Power off, Restart) is not shown when a user long-presses the Power button of a device in kiosk mode. Note: this may prevent users from turning off the device.":
        "Das Ein / Aus-Menü (z. B. Ausschalten, Neustart) wird nicht angezeigt, wenn ein Benutzer im Kioskmodus lange auf die Ein- / Aus-Taste eines Geräts drückt. Hinweis: Dies kann Benutzer daran hindern, das Gerät auszuschalten.",
    "System error warnings": "Systemfehlerwarnungen",
    "Specifies whether system error dialogs for crashed or unresponsive apps are blocked in kiosk mode.":
        "Gibt an, ob Systemfehlerdialoge für abgestürzte oder nicht reagierende Apps im Kioskmodus blockiert werden.",
    Muted: "Stumm",
    "Unspecified, defaults to muted.": "Nicht angegeben, standardmäßig stummgeschaltet.",
    "All system error dialogs such as crash and app not responding (ANR) are displayed.":
        "Alle Systemfehlerdialoge wie Absturz und App reagiert nicht (ANR) werden angezeigt.",
    "All system error dialogs, such as crash and app not responding (ANR) are blocked. When blocked, the system force-stops the app as if the user closes the app from the UI.":
        "Alle Systemfehlerdialoge wie Absturz und nicht reagierende App (ANR) werden blockiert. Wenn es blockiert ist, stoppt das System die App zwangsweise, als ob der Benutzer die App über die Benutzeroberfläche schließt.",
    "System navigation": "Systemnavigation",
    "Specifies which navigation features are enabled (e.g. Home, Overview buttons) in kiosk mode.":
        "Gibt an, welche Navigationsfunktionen im Kioskmodus aktiviert sind (z. B. Home, Übersichtstasten).",
    "Unspecified, defaults to disabled.": "Nicht angegeben, standardmäßig deaktiviert.",
    "Home and overview buttons are enabled.": "Home- und Übersichtsschaltflächen sind aktiviert.",
    "The home and Overview buttons are not accessible.":
        "Auf die Schaltflächen Home und Übersicht kann nicht zugegriffen werden.",
    "Only the home button is enabled.": "Nur die Home-Taste ist aktiviert.",
    "Home button only": "Nur Home-Taste",
    Statusbar: "Statusleiste",
    "Specifies whether system info and notifications are disabled in kiosk mode.":
        "Gibt an, ob Systeminformationen und Benachrichtigungen im Kioskmodus deaktiviert sind.",
    "Notifications & system info enabled": "Benachrichtigungen und Systeminformationen aktiviert",
    "Notifications & system info disabled":
        "Benachrichtigungen und Systeminformationen deaktiviert",
    "System info only": "Nur Systeminformationen",
    "Unspecified, defaults to notifications & system info disabled.":
        "Nicht angegeben, standardmäßig Benachrichtigungen und Systeminformationen deaktiviert.",
    "System info and notifications are shown on the status bar in kiosk mode.":
        "Systeminformationen und Benachrichtigungen werden im Kioskmodus in der Statusleiste angezeigt.",
    "System info and notifications are disabled in kiosk mode.":
        "Systeminformationen und Benachrichtigungen sind im Kioskmodus deaktiviert.",
    "Only system info is shown on the status bar.":
        "In der Statusleiste werden nur Systeminformationen angezeigt.",
    Devicesettings: "Geräteeinstellungen",
    "Specifies whether a user can access the device's Settings app while in kiosk mode.":
        "Gibt an, ob ein Benutzer im Kioskmodus auf die App Einstellungen des Geräts zugreifen kann.",
    "Unspecified, defaults to allowed.": "Nicht angegeben, standardmäßig zulässig.",
    "Access to the Settings app is allowed in kiosk mode.":
        "Der Zugriff auf die Einstellungen-App ist im Kioskmodus zulässig.",
    "Access to the Settings app is not allowed in kiosk mode.":
        "Der Zugriff auf die Einstellungen-App ist im Kioskmodus nicht zulässig.",
    "Disable touch": "Touch deaktivieren",
    "Disable device rotation": "Gerätedrehung deaktivieren",
    "Disable volume buttons": "Deaktivieren Sie die Lautstärketasten",
    "Disable ringer switch": "Klingelschalter deaktivieren",
    "Disable sleep wake button": "Deaktivieren Sie den Sleep-Wake-Button",
    "Disable auto lock": "Deaktivieren Sie die automatische Sperre",
    "Enable voice over": "Voice-Over aktivieren",
    "Enable zoom": "Zoom aktivieren",
    "Enable invert colors": "Invertieren von Farben aktivieren",
    "Enable AssistiveTouch": "AssistiveTouch aktivieren",
    "Enable speak selection": "Sprachauswahl aktivieren",
    "Enable mono audio": "Mono-Audio aktivieren",
    "Voice over": "Voice-Over",
    Zoom: "Zoom",
    "Invert colors": "Farben invertieren",
    AssistiveTouch: "AssistiveTouch",
    "Exchange accounts": "Exchange Konten",
    "Email accounts": "E-Mail Konten",
    "Add exchange accounts": "Exchange Konto hinzufügen",
    "Add email accounts": "E-Mail Konto hinzufügen",
    "Add account": "Konto hinzufügen",
    "Select certificates": "Zertifikate auswählen",
    or: "oder",
    "Follow these steps to connect to the Apple Volume Purchase Program":
        "Befolgen Sie diese Schritte, um eine Verbindung zum Apple Volume Purchase Program herzustellen",
    "Follow these steps to reconnect to the Apple Volume Purchase Program":
        "Befolgen Sie diese Schritte, um die Verbindung zum Apple Volume Purchase Program wiederherzustellen",
    "in order to generate your Token-file.": "um Ihre Token-Datei zu generieren.",
    "Activate Classroom configuration": "Classroom Konfiguration aktivieren",
    "Activate configuration": "Konfiguration aktivieren",
    "The bundle identifier of the application. WARNING: entering an unknown bundleId my cause problems.":
        "Die BundleId der App. WARNUNG: Die Eingabe einer unbekannten BundleId kann zu Problemen führen.",
    "Enter identifier": "ID eingeben",
    Options: "Optionen",
    "User Enabled Options": "Benutzeraktivierte Optionen",
    "App lock": "App-Sperre",
    "Please import your .CSV with following shema:":
        "Bitte importieren Sie Ihre .CSV mit folgendem Schema:",
    "username, firstname, lastname, email, password, roles(optional, comma seperated), comment(optional)":
        "username, firstname, lastname, email, password, roles(optional, Komma getrennt), comment(optional)",
    "Import users": "Benutzer importieren",
    "Select .csv File": "CSV-Datei auswählen",
    "Export .csv": ".csv exportieren",
    "Import .csv": ".csv importieren",
    "csv parsing failed": "Die csv Zergliederung ist fehlgeschlagen",
    "not available for Android Enterprise": "für Android Enterprise nicht verfügbar",
    Paste: "Einfügen",
    "Upload your Token-file": "Laden Sie Ihren Token hoch",
    "Hide generated profiles": "Generierte Profile ausblenden",
    "Rename profiles": "Profile umbenennen",
    "Following profilenames have to be unique:": "Folgende Profilnamen müssen einzigartig sein:",
    "The following %s have to be unique:": "Folgende %s müssen einzigartig sein:",
    "vpn names": "VPN Namen",
    appnames: "Appnamen",
    profilenames: "Profilnamen",
    Copy_of_: "Kopie_von_",
    "Paste items": "Objekte einfügen",
    "Please visit": "Bitte besuchen Sie",
    "Applications are only available for enterprise devices that have application reporting activated in their profile.":
        "Anwendungen sind für Enterprise Geräte verfügbar, bei denen Anwendungsberichte im Profil aktiviert sind.",
    poweroff: "herunterfahren",
    "Reset factory settings": "Werkseinstellungen wiederherstellen",
    reset: "wiederherstellen",
    "Rollback possible": "Zurücksetzen möglich",
    "Dry run possible": "Probelauf möglich",
    "Finalize possible": "Finalisierung möglich",
    "Eula needs to be confirmed": "EULA muss akzeptiert werden",
    "Privacy needs to be confirmed": "Datenschutzbestimmungen müssen akzeptiert werden",
    "Blocked threats": "Abgewehrte Bedrohungen",
    "malicious HTTP-Requests blocked": "bösartige HTTP-Requests abgewehrt",
    "E-Mail viruses blocked": "E-Mail-Viren abgewehrt",
    "C&C-Accesses logged": "C&C-Zugriffe geloggt",
    "C&C-Accesses blocked": "C&C-Zugriffe abgewehrt",
    "harmful URLs blocked": "schädliche URLs abgewehrt",
    "No traffic": "Kein Traffic",
    "Go to operations": "Zu Operationen wechseln",
    "Shut down": "Herunterfahren",
    "Set new password": "Neues Passwort setzen",
    "Create a new backup": "Neues Backup erstellen",
    Restore: "Wiederherstellen",
    "Repeat password": "Passwort wiederholen",
    "Scanner is disabled": "Scanner ist deaktiviert",
    "Not in use": "Nicht in Verwendung",
    "Pattern is up to date": "Pattern ist aktuell",
    "Last Update:": "Letztes Update:",
    "Update is older than 2 days": "Update ist älter als 2 Tage",
    "Scanner has been stopped": "Scanner ist gestoppt",
    "Update has been deactivated": "Update ist deaktiviert",
    "No drops": "Keine Drops",
    "No rejects": "Keine Rejects",
    "No alerts occured": "Keine Warnung aufgetreten",
    "alerts occured": "Alerts aufgetreten",
    "Failed logins": "Fehlgeschlagene Logins",
    "No logins": "Keine Logins",
    "No Logins": "Keine Logins",
    logins: "Logins",
    "Cluster devices aren't supported": "Cluster-Devices werden nicht unterstützt",
    "Unified Reporting is not licensed for you": "Unified Reporting ist nicht für Sie lizenziert",
    "Unified Reporting is not activated for your UTM":
        "Unified Reporting ist nicht für Ihre UTM aktiviert",
    "License now!": "Jetzt lizenzieren!",
    "Please contact your IT system house to license this product":
        "Kontaktieren Sie bitte Ihr IT-Systemhaus um dieses Produkt zu lizenzieren",
    "All devices from Android 7.0 with access to the Google Play API are supported":
        "Es werden alle Geräte ab Android 7.0 mit Zugriff auf die Google Play API unterstützt",
    "Allow personal usage": "Private Nutzung zulassen",
    "Personal usage is allowed": "Private Nutzung ist erlaubt",
    "Personal usage is disallowed": "Private Nutzung ist nicht erlaubt",
    "Controls whether personal usage is allowed on a device provisioned with this enrollment token.":
        "Legt fest, ob die private Nutzung auf einem mit diesem Registrierungstoken angemeldeten Gerät zulässig ist.",
    "For company-owned devices": "Für Firmengeräte",
    "Enabling personal usage allows the user to set up a work profile on the device. Disabling personal usage requires the user provision the device as a fully managed device.":
        "Durch Aktivieren der privaten Nutzung kann der Benutzer ein Arbeitsprofil auf dem Gerät einrichten. Um die private Nutzung zu deaktivieren, muss der Benutzer das Gerät als vollständig verwaltetes Gerät bereitstellen.",
    "For personally-owned devices": "Für private Geräte",
    "Enabling personal usage allows the user to set up a work profile on the device. Disabling personal usage will prevent the device from provisioning. Personal usage cannot be disabled on personally-owned device.":
        "Durch Aktivieren der persönlichen Nutzung kann der Benutzer ein Arbeitsprofil auf dem Gerät einrichten. Durch Deaktivieren der privaten Nutzung wird die Bereitstellung des Geräts verhindert. Die private Nutzung kann auf einem privaten Gerät nicht deaktiviert werden.",
    "Last Info update": "Letzte Aktualisierung der Info",
    "Global HTTP Proxy": "Globaler HTTP Proxy",
    "Global HTTP proxy": "Globaler HTTP Proxy",
    "Use global HTTP proxy": "Globalen HTTP Proxy benutzen",
    "Captive login allowed": "Captive-Login erlauben",
    "PAC fallback allowed": "PAC Fallback erlauben",
    Manual: "Manuell",
    Auto: "Automatisch",
    "The proxy type. For a manual proxy type, the profile contains the proxy server address, including its port, and optionally a user name and password. For an auto proxy type, you can enter a PAC URL":
        "Der Proxy-Typ. Bei einem manuellen Proxy-Typ enthält das Profil die Proxy-Server-Adresse einschließlich des Ports sowie optional einen Benutzernamen und ein Passwort. Für einen Auto-Proxy-Typ können Sie eine PAC-URL eingeben",
    "If true, allows the device to bypass the proxy server to display the login page for captive networks":
        "Wenn aktiv, kann das Gerät den Proxyserver umgehen, um die Anmeldeseite für Captive-Netzwerke anzuzeigen",
    "If true, allows connecting directly to the destination if the proxy autoconfiguraiton (PAC) file is unreachable":
        "Wenn aktiv, kann eine direkte Verbindung zum Ziel hergestellt werden, wenn die PAC-Datei (Proxy Autoconfiguraiton) nicht erreichbar ist",
    "The URL of the PAC file that defines the proxy configuration. Starting in iOS 13 and macOS 10.15, only URLs that begin with http:// or https:// are allowed":
        "Die URL der PAC-Datei, die die Proxy-Konfiguration definiert. Ab iOS 13 und macOS 10.15 sind nur URLs zulässig, die mit http: // oder https: // beginnen",
    Calendar: "Kalender",
    "Add account to calendar": "Benutzer zum Kalender hinzufügen",
    Accounts: "Benutzer",
    "Optional. The base URL to the userʼs calendar. In macOS this URL is required if the user doesnʼt provide a password, because auto-discovery of the service will fail and the account wonʼt be created":
        "Optional. Die URL zum Kalender des Benutzers. In macOS ist diese URL erforderlich, wenn der Benutzer kein Passwort angibt, da die automatische Erkennung des Dienstes fehlschlägt und das Konto nicht erstellt wird",
    "Account description": "Benutzer Beschreibung",
    "Principal URL": "Haupt-URL",
    "Add subscriptions to calendar. A calendar subscription adds a subscribed calendar to the userʼs calendars list":
        "Abonnements zum Kalender hinzufügen. Ein Kalenderabonnement fügt der Kalenderliste des Benutzers einen abonnierten Kalender hinzu",
    "Add subscription": "Abonnement hinzufügen",
    Subscription: "Abonnement",
    Subscriptions: "Abonnements",
    "You have copied a profile with a managed configuration template. Changes in that template will affect all profiles using this template.":
        "Sie haben ein Profil mit einer verwalteten Konfigurationsvorlage kopiert. Änderungen an dieser Vorlage betreffen alle Profile, die diese Vorlage benutzen.",
    "System information": "Systeminformation",
    Cloudbackups: "Cloud-Backups",
    "Cloudbackup setting": "Cloud-Backup-Einstellung",
    "Allow App Clips": "Erlaube App-Clips",
    "If disabled, prevents a user from adding any App Clips, and removes any existing App Clips on the device. Available in iOS 14.0 and later.":
        "Wenn diese Option deaktiviert ist, kann ein Benutzer keine App-Clips hinzufügen und vorhandene App-Clips auf dem Gerät entfernen. Verfügbar in iOS 14.0 und höher.",
    "Allow Personalized Advertising": "Erlaube personalisierte Werbung",
    "If disabled, limits Apple personalized advertising. Available in iOS 14 and later":
        "Wenn deaktiviert, wird die personalisierte Werbung von Apple eingeschränkt. Verfügbar in iOS 14 und höher",
    "Do you want to delete the device from Mobile Security and remove the work profile on this device?":
        "Wollen Sie das Gerät von der Mobile Security löschen und das Arbeitsprofil entfernen?",
    "Do you want to delete the device from Mobile Security and reset the Device to factory settings?":
        "Wollen Sie das Gerät von der Mobile Security löschen und das Gerät auf Werkseinstellungen zurücksetzen?",
    "Set UTM Model": "UTM Modell definieren",
    Interfaces: "Schnittstellen",
    Connections: "Verbindungen",
    "Disable VPN for domains": "VPN für Domains deaktivieren",
    "Add domains": "Domains hinzufügen",
    "Enter domains for which the security features shall be disabled. i.e. foo.bar.de":
        "Geben Sie Domains ein, für die die Sicherheitsfunktionen deaktiviert werden sollen.",
    "Assign DEP-Profile": "DEP-Profil zuweisen",
    "DEP-Profile": "DEP-Profil",
    "Apple DEP Profiles": "DEP-Profil",
    "This value can be used with $variable1$ in profiles.":
        "Dieser Wert kann mit $variable1$ in Profilen verwendet werden.",
    "This value can be used with $variable2$ in profiles.":
        "Dieser Wert kann mit $variable2$ in Profilen verwendet werden.",
    "This value can be used with $variable3$ in profiles.":
        "Dieser Wert kann mit $variable3$ in Profilen verwendet werden.",
    "Known installations": "Bekannte Installationen",
    'This profile will be active for these Apple IDs on the devices selected on the "general" tab':
        'Dieses Profil wird auf allen unter "Allgemein" ausgewählten Geräten, für diese Apple IDs verfügbar sein',
    "Add Apple IDs": "Apple IDs hinzufügen",
    "If active, the profile will be used as the default profile.":
        "Wenn aktiviert, wird das Profil als Standardprofil verwendet.",
    "If active, pairing will be allowed.": "Wenn aktiviert, wird das pairing erlaubt.",
    "If active, the devices will be supervised.":
        "Wenn aktiviert, werden neue Geräte als Betreut registriert.",
    "If active, tells the device to configure for Shared iPad. This key is valid only for Apple School Manager or Apple Business Manager organizations using X-Server-Protocol-Version 2 and later. Devices that do not meet the Shared iPad minimum requirements do not honor this command.":
        "Wenn aktiviert, weist es das Gerät an, es für Shared iPad zu konfigurieren. Dieser Schlüssel ist nur für Apple School Manager oder Apple Business Manager Organisationen gültig, die X-Server-Protocol-Version 2 und höher verwenden. Geräte, die die Mindestanforderungen für das freigegebene iPad nicht erfüllen, setzen diesen Befehl nicht um.",
    "If active, the user may not skip applying the profile returned by the MDM server":
        "Wenn aktiviert, darf der Benutzer das Anwenden des vom MDM-Server zurückgegebenen Profils nicht überspringen",
    Mandatory: "Verpflichtend",
    "Is MDM removable": "Ist MDM entfernbar",
    "Skip setup items": "Setup-Elemente überspringen",
    "A list of setup panes to skip. WARNING: Disabling the location setup may cause unexpected behaviour.":
        'Eine Liste der zu überspringenden Ersteinrichtungsschritte. WARNUNG: Das Deaktivieren der "Location" Einrichtung kann zu unerwartetem Verhalten führen.',
    Department: "Abteilung",
    "Department or location name.": "Abteilungs- oder Standortname.",
    "Support phone number": "Support-Telefonnummer",
    "A support phone number for the organization.":
        "Eine Support-Telefonnummer für die Organisation.",
    "Support email address": "Support-E-Mail-Adresse",
    "A support e-mail address for the organization.":
        "Eine Support-E-Mail-Adresse für die Organisation.",
    "uniquely identifies various services that are managed by a single organization.":
        "Identifiziert eindeutig verschiedene Dienste, die von einer einzelnen Organisation E-Mail-Adresse werden.",
    "If inactive, the MDM payload delivered by the configuration URL cannot be removed by the user via the user interface on the device; that is, the MDM payload is locked onto the device.":
        "Wenn diese Option deaktiviert ist, kann der Benutzer die von der Konfigurations-URL gelieferten MDM-Payload nicht über die Benutzeroberfläche des Geräts entfernen. Das heißt, die MDM-Payload ist an das Gerät gebunden.",
    "Allow pairing": "Pairing erlauben",
    "Phone number": "Telefonnummer",
    "Select items": "Elemente auswählen",
    "Add account to CardDAV": "Konto zu CardDAV hinzufügen",
    "Upload your custom mobileconfig": "Laden Sie Ihre eigene mobileconfig hoch",
    Upload: "Hochladen",
    Replace: "Ersetzen",
    "User profiles": "Benutzerprofile",
    "Shared iPad user": "Shared iPad Benutzer",
    "Wrong filetype. Please upload a .csv file.":
        "Falsches Dateiformat. Bitte laden Sie eine .csv Datei hoch.",
    "An undefined error has occurred. Please check the formatting of the file":
        "Es ist ein unerwarteter Fehler aufgetreten. Bitte überprüfen Sie die Formatierung der Datei",
    "Error with the %s property:": "Fehler mit der %s Eigenschaft:",
    "Please insert at least 2 characters": "Bitte geben Sie mindestens 2 Zeichen ein",
    "Please insert at least 3 characters": "Bitte geben Sie mindestens 3 Zeichen ein",
    "Please insert at least 5 characters": "Bitte geben Sie mindestens 5 Zeichen ein",
    "User %s already exists": "Benutzer %s exisitiert bereits",
    "Format validation failed (data is not a valid email address.)":
        "Formatvalidierung fehlgeschlagen (Daten sind keine gültige E-Mail-Adresse.)",
    "Do you really want to delete this user?": "Wollen Sie diesen Benutzer wirklich löschen?",
    "Do you really want to delete this role?": "Wollen Sie diese Rolle wirklich löschen?",
    "Do you really want to delete this report?": "Wollen Sie diesen Bericht wirklich löschen?",
    "Do you really want to log out this user?": "Wollen Sie diesen Benutzer wirklich ausloggen?",
    "Defines wether you want to force delete or not.":
        "Definiert ob Sie das Löschen erzwingen wollen.",
    Force: "Erzwingen",
    Unlock: "Freischalten",
    "Do you really want to unlock this user?": "Wollen Sie diesen Benutzer wirklich freischalten?",
    "User skipped because of missing properties:":
        "Benutzer übersprungen, weil folgende Eigenschaften fehlen:",
    "Error importing user: ": "Fehler beim importieren des Benutzers: ",
    "Assigns users to this role": "Ordnet Benutzer dieser Rolle zu",
    "Full administration access": "Voller Administrationszugriff",
    "Access to the monitoring functions": "Zugriff auf die Überwachungsfunktionen",
    "Only read only jobs can be created": "Es können nur schreibgeschützte Jobs erstellt werden",
    "Jobs which changes data can be created": "Jobs, die Daten ändern, können erstellt werden",
    "Application management jobs can be created":
        "Anwendungsverwaltungsjobs können erstellt werden",
    "EXTC management jobs can be created": "EXTC-Verwaltungsjobs können erstellt werden",
    "VPN management jobs can be created": "VPN-Verwaltungsjobs können erstellt werden",
    "Backup management jobs can be created": "Backup-Verwaltungsjobs können erstellt werden",
    "Upgrade management jobs can be created": "Upgrade-Verwaltungsjobs können erstellt werden",
    "Access to the administration webinterface": "Zugriff auf das Administrations-Webinterface",
    "Go to Mobile Security settings": "Zu den Mobile Security Einstellungen wechseln",
    "Unknown error": "Unbekannter Fehler",
    "Changes on apps will be applied Immediately. You don't need to install them manually anymore.":
        "Änderungen an Apps werden sofort übernommen. Sie müssen sie nicht mehr manuell installieren.",
    "Single app installations can still be triggered, if you wish to force an app update.":
        "Einzelne App-Installationen können weiterhin ausgelöst werden, wenn Sie ein App-Update erzwingen möchten.",
    "There are no recipients defined for your reports":
        "In Ihren Berichten sind keine Empfänger definiert",
    "Update your report-settings in order to receive reports via e-mail":
        "Aktualisieren Sie die Bericht-Einstellungen um Berichte per E-Mail zu erhalten",
    "Edit now": "Jetzt bearbeiten",
    "Your Apple DEP-Token is invalid": "Ihr Apple DEP-Token ist falsch",
    "It seems like your apple DEP-token is invalid.":
        "Es scheint als hätten Sie den falschen DEP-Token.",
    "Connected work & personal app": "Verbundene Arbeits- & persönliche App",
    "Controls whether the app can communicate with itself across a device’s work and personal profiles, subject to user consent.":
        "Steuert, ob die App mit Zustimmung des Benutzers über die Arbeits- und persönlichen Profile eines Geräts mit sich selbst kommunizieren kann.",
    "Defaults to disallowed": "Standardmäßig nicht erlaubt",
    Disallowed: "Nicht erlaubt",
    "Default. Prevents the app from communicating cross-profile.":
        "Standard. Verhindert eine profilübergreifende Kommunikation der App.",
    Allowed: "Erlaubt",
    "Allows the app to communicate across profiles after receiving user consent.":
        "Ermöglicht der App die profilübergreifende Kommunikation nach Erhalt der Zustimmung des Benutzers.",
    "Add config": "Konfiguration hinzufügen",
    "Add Zero-Touch configuration": "Zero-Touch Konfiguration hinzufügen",
    "Edit Zero-Touch configuration": "Zero-Touch Konfiguration bearbeiten",
    "The name of this configuration": "Der Name der Konfiguration",
    "The selected enrollmenttoken will be applied to devices that are going to be enrolled with this configuration.":
        "Der ausgewählte Enrollmenttoken wird auf alle Geräte angewandt, die mit dieser Konfiguration enrolled werden.",
    "Select an enrollmenttoken": "Wählen Sie einen Enrollmenttoken",
    Customer: "Kunde",
    "Select a customer": "Wählen Sie einen Kunden",
    Default: "Standard",
    "Defines wether this configuration is default or not. Note: at least one configuration should be default.":
        "Definiert ob diese Konfiguration der Standard ist oder nicht. Hinweis: Es sollte mindestens eine Konfiguration als Standard definiert sein.",
    Company: "Unternehmen",
    "The name of the company this configuration belongs to.":
        "Der Name des Unternehmens, zu dem diese Konfiguration gehört.",
    "The contact e-mail address": "Die Kontakt-E-Mail-Adresse",
    "The contact phone number": "Die Kontakt-Telefonnummer",
    "A custom message, that will be displayed in the device Setup Wizard to help users.":
        "Im Setup-Assistenten des Geräts wird eine benutzerdefinierte Nachricht angezeigt, die den Benutzer unterstützen soll.",
    "Custom message": "Benutzerdefinierte Nachricht",
    "Message...": "Nachricht...",
    "A message that will be displayed in the device Setup Wizard to help users.":
        "Eine Nachricht, die bei der Geräteeinrichtung angezeigt wird, um den Nutzern zu helfen.",
    "Select devices": "Geräte auswählen",
    "The selected devices will have this configuration assigned to them.":
        "Den ausgewählten Geräten wird diese Konfiguration zugeordnet",
    Activated: "Aktiviert",
    "Add Google account": "Google Account hinzufügen",
    'Press "Add now" to create or connect to your Google account':
        'Klicken Sie auf "Jetzt hinzufügen", um Ihr Google Benutzerkonto zu verknüpfen oder um eines zu erstellen',
    Unbind: "Aufheben",
    "You successfully added your Google account":
        "Sie haben Ihr Google Benutzerkonto erfolgreich hinzugefügt",
    "Added item to clipboard": "Objekt wurde in die Zwischenablage kopiert",
    "Auto date & time zone": "Automatisches Datums & Zeitzone",
    "Whether auto date, time, and time zone are enabled on a company-owned device.":
        "Gibt an, ob auf einem firmeneigenen Gerät automatisches Datum, Uhrzeit und Zeitzone aktiviert sind.",
    "This value is ignored. Defaults to users's choice.":
        "Dieser Wert wird ignoriert. Standardmäßig wird die Wahl des Benutzers verwendet.",
    "User's choice": "Wahl des Benutzers",
    "Auto date, time, and time zone are left to user's choice.":
        "Das automatische Datum, die Uhrzeit und die Zeitzone bleiben der Wahl des Benutzers überlassen.",
    "Enforce auto": "Automatisch erzwingen",
    "Enforce auto date, time, and time zone on the device.":
        "Erzwingen Sie das automatische Datum, die Uhrzeit und die Zeitzone auf dem Gerät.",
    "Location setting is not restricted on the device. No specific behavior is set or enforced.":
        "Die Standorteinstellung ist auf dem Gerät nicht eingeschränkt. Es wird kein bestimmtes Verhalten festgelegt oder erzwungen.",
    Enforced: "Erzwungen",
    "Enable location setting on the device.":
        "Aktivieren Sie die Standorteinstellung auf dem Gerät.",
    "Disable location setting on the device.":
        "Deaktivieren Sie die Standorteinstellung auf dem Gerät.",
    "Personal usage": "Persönlicher Gebrauch",
    "Max days with work off": "Max. Tage ohne Arbeit",
    "Controls how long the work profile can stay off.":
        "Steuert, wie lange das Arbeitsprofil ausgeschaltet bleiben kann.",
    "Personal Play Store mode": "Persönlicher Play Store-Modus",
    'Used together with "personal applications" to control how apps in the personal profile are allowed or blocked.':
        'Wird zusammen mit "Persönliche Anwendungen" verwendet, um zu steuern, wie Apps im persönlichen Profil zugelassen oder blockiert werden.',
    "Unspecified. Defaults to Block list.": "Nicht spezifiziert. Standardmäßig Blockliste.",
    "Block list": "Blockliste",
    'All Play Store apps are available for installation in the personal profile, except those whose installType is "Blocked" in "Personal Applications".':
        'Alle Play Store-Apps können im persönlichen Profil installiert werden, mit Ausnahme derjenigen, deren Installationstyp unter "Persönliche Anwendungen" "Blockiert" ist.',
    "Allow list": "Allowliste",
    Blocklist: "Blockliste",
    'Only apps explicitly specified in "Personal Applications" with "Install Type" set to "Available" are allowed to be installed in the personal profile.':
        'Im persönlichen Profil dürfen nur Apps installiert werden, die explizit in "Persönliche Anwendungen" angegeben sind und deren "Installationstyp" auf "Verfügbar" eingestellt ist.',
    "Policies for apps in the personal profile of a company-owned device with a work profile.":
        "Richtlinien für Apps im persönlichen Profil eines unternehmenseigenen Geräts mit einem Arbeitsprofil.",
    "Assigned in DEP": "Im DEP zugewiesen",
    "Pushed to device": "Zum Gerät gepusht",
    "Personal applications": "Persönliche Anwendungen",
    Pushed: "Gepusht",
    "Removed from device": "Vom Gerät entfernt",
    "No DEP Profile assigned.": "Kein DEP Profil zugewiesen",
    "The profile has been assigned to the device, but will not be applied until the next reset":
        "Das Profil wurde dem Gerät zugewiesen, wird aber erst beim nächsten Zurücksetzen angewendet",
    "The profile has been applied to the device": "Das Profil wurde auf dem Gerät angewendet",
    "The profile has been removed from the device": "Das Profil wurde von dem Gerät entfernt",
    "Profile assignment time": "Profilzuweisungszeit",
    "Profile status": "Profilstatus",
    at: "am",
    " at ": " am ",
    "Please locate the Apple Push Certificate with the following subject line:":
        "Bitte suchen Sie das zu verlängernde Apple Push Zertifikat mit folgenden Betreffzeilen:",
    "Please renew the latest of the following Apple Push Certificates with the following subject lines:":
        "Bitte verlängern Sie das aktuellste der folgenden Apple Push Zertifikate mit den folgenden Betreffzeilen:",
    "Are you sure to delete this object?":
        "Sind sie sich sicher dass Sie dieses Objekt löschen wollen?",
    "USC monitoring": "USC Monitoring",
    "Full USC administration": "USC Adminstration: voll",
    "Partial USC administration": "USC Adminstration: partiell",
    "License conditions": "Lizenzbedingungen",
    "License Conditions": "Lizenzbedingungen",
    "I agree with the license conditions": "Ich akzeptiere die Lizenzbedingungen",
    "Don't ask me again to accept these license conditions":
        "Frage mich nicht erneut nach der Bestätigung der Lizenzbedingungen",
    "I agree with the privacy policy": "Ich akzeptiere die Datenschutzerklärung",
    "Privacy policy": "Datenschutzerklärung",
    "Don't ask me again to accept this privacy policy":
        "Frage mich nicht erneut nach der Bestätigung der Datenschutzerklärung",
    Accept: "Akzeptieren",
    "Finalize new version": "Neue Version finalisieren",
    "Are you sure to finalize the current version":
        "Sind Sie sicher, dass Sie die aktuelle Version finalisieren wollen",
    "If enabled, a route will be added which exlcudes the connected WLAN IP range from the tunnel":
        "Wenn aktiviert, wird eine Route hinzugefügt, die den lokalen WLAN-IP-Bereich vom Tunnel ausschließt",
    "We have detected that one or more devices deny app installation with state NOT_ENROLLED. This either means the enterprise is no longer enrolled with Managed Google Play or the admin has not accepted the latest Managed Google Play Terms of Service.":
        "Wir haben festgestellt, dass ein oder mehrere Geräte die App-Installation mit dem Status NOT_ENROLLED verweigern. Dies bedeutet entweder, dass das Unternehmen nicht mehr bei Managed Google Play registriert ist oder der Administrator die neuesten Nutzungsbedingungen für Managed Google Play nicht akzeptiert hat.",
    "Click here": "Klicken Sie hier",
    "to accept the new Terms of Service.": "um die neuen Nutzungsbedingungen zu aktzeptieren.",
    "Exclude local WLAN from the VPN": "Lokales WLAN vom VPN ausnehmen",
    "If enabled, VPN will be disabled for as long as the device is connected to WIFI.":
        "Wenn aktiviert, wird VPN deaktiviert, solange das Gerät mit dem WLAN verbunden ist.",
    Filetype: "Dateityp",
    "Max. Filesize": "Max. Dateigröße",
    "Max. Resolution": "Max. Auflösung",
    "Select image": "Bild auswählen",
    "App type": "App Typ",
    "The webclip URL": "Die Webclip URL",
    "Defines wether the user can remove this webclip or not.":
        "Legt fest, ob der Benutzer diesen Webclip entfernen kann oder nicht.",
    "If true, launches the web clip as a full-screen web app.":
        "Wenn aktiv, wird der Webclip als Vollbild-Web-App gestartet.",
    "If true, a full screen web clip can navigate to an external web site without showing Safari UI. Otherwise, Safari UI appears when navigating away from the web clip’s URL. This key has no effect when FullScreen is false.":
        "Wenn aktiv, kann ein Vollbild-Webclip zu einer externen Website navigieren, ohne die Safari-Benutzeroberfläche anzuzeigen. Andernfalls wird die Safari-Benutzeroberfläche angezeigt, wenn Sie von der URL des Webclips weg navigieren. Dieser Schlüssel hat keine Auswirkung, wenn Vollbild deaktiviert ist.",
    'If true, prevents SpringBoard from adding "shine" to the icon.':
        'Wenn aktiv, wird verhindert, dass das SpringBoard dem Symbol "Glanz" hinzufügt.',
    "The application bundle identifier that specifies the application which opens the URL. To use this property, the profile must be installed through an MDM.":
        "Die BundleID, die die Anwendung angibt, welche die URL öffnet. Um diese Eigenschaft verwenden zu können, muss das Profil über ein MDM installiert werden.",
    "Target Application BundleID": "Zielanwendungs-BundleID",
    Precomposed: "Vorkomponiert",
    "Ignore Manifest Scope": "Manifest Scope ignorieren",
    Fullscreen: "Vollbild",
    Removable: "Entfernbar",
    "Upload your push certificate (MDM_Securepoint GmbH_Certificate.pem):":
        "Laden sie Ihr Push Zertifikat hoch (MDM_Securepoint GmbH_Certificate.pem):",
    "Upload Certificate": "Zertifikat hochladen",
    "Drop File (*.pem) here": "Datei (*.pem) hier ablegen",
    "Enter the Apple ID that you used to generate the certificate:":
        "Geben Sie die Apple ID ein, mit der Sie das Zertifikat generiert haben:",
    "Lockscreen message": "Nachricht auf Sperrbildschirm",
    "Getting select field options...": "Hole Optionen für Auswahlfelder...",
    "Setting select field options...": "Setze Optionen für Auswahlfelder...",
    "Getting licenses...": "Hole Lizenzen...",
    "Getting license...": "Hole Lizenz...",
    "Getting devices...": "Hole Geräte...",
    "Getting form...": "Hole Formulardaten...",
    "Getting object info...": "Hole Objektdaten...",
    "Filling form with object data...": "Fülle Formular mit Objektdaten...",
    "Getting registered Email addresses...": "Hole registrierte E-Mail-Adressen...",
    "Finished loading...": "Laden beendet...",
    "Getting tenant info...": "Hole Kundeninformationen...",
    "Getting QR-Codes...": "Hole QR-Codes...",
    "Getting enterprise policies...": "Hole Enterprise Profile...",
    "Getting enterprise devices...": "Hole Enterprise Geräte...",
    "Getting enrollment tokens...": "Hole enrollment tokens...",
    "Loading token data...": "Lade Token informationen...",
    "Ready!": "Fertig!",
    "Getting DEP-Profiles...": "Hole DEP-Profile...",
    "<i class='fas fa-exclamation-triangle color-red'></i> Something went wrong":
        "<i class='fas fa-exclamation-triangle color-red'></i> Es ist ein Fehler aufgetreten",
    "Getting reports...": "Hole Berichte...",
    "Getting scans...": "Hole Scans...",
    "Getting other devices...": "Hole andere Geräte...",
    "Getting application infos...": "Hole App infos...",
    "Getting UTM info...": "Hole UTM infos...",
    "Getting Operationslog info...": "Hole Operationslog infos...",
    "Getting Scans info...": "Hole Scan infos...",
    "Getting Jobs info...": "Hole Job infos...",
    "Initiating inventory form...": "Initialisiere Inventar Formular...",
    "Initiating USR info...": "Initialisiere USR info...",
    "Loading data...": "Lade daten...",
    "Reset password": "Passwort zurücksetzen",
    "Disable Device": "Gerät deaktivieren",
    "Activate Device": "Gerät aktivieren",
    "Selected Apps will be installed on the assigned devices":
        "Ausgewählte Apps werden auf den zugewiesenen Geräten installiert",
    "Selected Web clips will be installed on the assigned devices":
        "Ausgewählte Web clips werden auf den zugewiesenen Geräten installiert",
    "Activate Home Screen Layout": "Home-Bildschirmlayout aktivieren",
    "After setting this, you can configure the Home Screen Layout":
        "Nach der Aktivierung können Sie Einstellungen am Home-Bildschirmlayout vornehmen",
    "Select App": "App auswählen",
    "Backup rule": "Backup regel",
    "The automatic backup will be made daily at": "Das automatische Backup wird täglich um",
    "The automatic backup is scheduled daily at": "Das automatische Backup wird täglich um",
    "Sign in with a Reseller Account:": "Mit einem Reseller-Konto anmelden:",
    "Xo'clock": "Uhr erstellt",
    "Update available": "Update verfügbar",
    "The update to": "Das Update auf",
    "has been downloaded and can be activated":
        "wurde heruntergeladen und kann nun aktiviert werden",
    Connected: "Verbunden",
    Disconnected: "Getrennt",
    "It was found that the wrong license is installed on the UTM.":
        "Es wurde festgestellt, dass auf der UTM die falsche Lizenz installiert ist.",
    "Please get in touch with your contact person to clarify this problem or install the required license.":
        "Bitte setzen Sie sich mit Ihrem Ansprechpartner in Verbindung, um dieses Problem zu klären oder spielen Sie die benötigte Lizenz ein.",
    "Something went wrong, please try again.":
        "Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
    "Require password unlock": "Passwort entsperren erforderlich",
    "The length of time after a device or work profile is unlocked using a strong form of authentication (password, PIN, pattern) that it can be unlocked using any other authentication method (e.g. fingerprint, trust agents, face). After the specified time period elapses, only strong forms of authentication can be used to unlock the device or work profile.":
        "Die Zeitspanne nach dem Entsperren eines Geräts oder Arbeitsprofils mithilfe einer starken Authentifizierungsform (Passwort, PIN, Muster), die mit einer anderen Authentifizierungsmethode (z. B. Fingerabdruck, Vertrauensagenten, Gesicht) entsperrt werden kann. Nach Ablauf des angegebenen Zeitraums können nur noch starke Authentifizierungsformen zum Entsperren des Geräts oder des Arbeitsprofils verwendet werden.",
    "Unspecified. Defaults to Device timeout.":
        "Nicht spezifiziert. Standardmäßig wird das Geräte-Timeout verwendet.",
    "Device timeout": "Geräte-Timeout",
    "The timeout period is set to the device’s default.":
        "Die Zeitüberschreitung ist auf die Standardeinstellung des Geräts eingestellt.",
    "The timeout period is set to 24 hours.": "Die Zeitüberschreitung beträgt 24 Stunden.",
    "Every day": "Täglich",
    "Memory usage": "Speichernutzung",
    Storage: "Speicher",
    "Storage used": "Verwendeter Speicher",
    "Rollback to version": "Rollback zu der Version",
    "Dryrun the new Version": "Dryrun der neuen Version",
    "Finalize the new Version": "Finalisiere die neue Version",
    "Accept eula": "Eula akzeptieren",
    "Accept privacy": "Datenschutzbestimmungen akzeptieren",
    Restart: "Neustart",
    Interface: "Schnittstelle",
    Result: "Ergebnis",
    "Found %s open ports": "%s offene Ports gefunden",
    "Found 1 open port": "1 offenen Port gefunden",
    "Time to live exceeded": "Lebenszeit überschritten",
    "Invalid scan IP. Request IP %s is not equal to %s.":
        "Falsche Scan-IP. Die Request-IP %s unterscheidet sich von %s.",
    "No applications loaded yet. Please click refresh.":
        "Es wurden noch keine Anwendungen geladen. Bitte klicken Sie auf aktualisieren.",
    "Cloud backup is disabled for this UTM": "Das Cloud-Backup ist für diese UTM deaktiviert",
    "Do you really want to do a rollback to the last installed version of %s?":
        "Wollen Sie wirklich einen Rollback auf die letzte Version von %s machen?",
    "Are you sure to boot the new version %s?":
        "Sind Sie sich sicher dass Sie die neue Version %s starten wollen?",
    "Are you sure to finalize the current version %s?":
        "Sind Sie sich sicher dass Sie die aktuelle Version %s finalisieren möchten?",
    "Do you really want to reboot the UTM %s?": "Wollen Sie die UTM %s wirklich neustarten?",
    "Do you really want to shut down the UTM %s?": "Wollen Sie die UTM %s wirklich herunterfahren?",
    "Do you really want to reset: %s?": "Wollen Sie die UTM %s wirklich zurücksetzen?",
    "Are you sure?": "Sind Sie sicher?",
    "Do you really want to restore this backup?":
        "Wollen Sie dieses Backup wirklich wiederherstellen?",
    "Do you really want to delete this backup?": "Wollen Sie dieses Backup wirklich löschen?",
    "Start new websession": "Neue Websession starten",
    "Start New Websession": "Neue Websession starten",
    "Start session": "Session starten",
    "Go to webinterface": "Webinterface öffnen",
    "Remove DEP-Profile": "DEP-Profil entfernen",
    "No notifications": "Keine Benachrichtigungen",
    "Do you really want to locate this device?": "Möchten Sie dieses Gerät wirklich lokalisieren?",
    unspecified: "Nicht spezifiziert",
    "This setting defines were this device is usually beeing used":
        "Diese Einstellung definiert, wo das Gerät normalerweise genutzt wird",
    "Check address": "Adresse überprüfen",
    "Activating...": "Aktiviere...",
    "Disabling...": "Deaktiviere...",
    "Download mobileconfig": "Mobileconfig herunterladen",
    "Download CA.crt": "CA.crt herunterladen",
    "Terms and conditions or privacy policy not accepted":
        "ABG oder Datenschutzbestimmungen wurden nicht akzeptiert",
    "Location authorization is missing": "Ortungsberechtigung fehlt",
    "GPS / location is system-wide deactivated": "GPS/Ortung ist systemweit deaktiviert",
    "Android reported a location error": "Android meldet einen Fehler bei der Ortung",
    "Inventory location": "Inventarstandort",
    "Set inventory location": "Inventarstandort setzen",
    "Update inventory location": "Inventarstandort setzen/aktualisieren",
    "New password": "Neues Passwort",
    "Require entry": "Eingabe erforderlich",
    "Don't allow other admins to change the password again until the user has entered it.":
        "Erlauben Sie anderen Administratoren nicht, das Passwort erneut zu ändern, bis der Benutzer es eingegeben hat.",
    "No credentials": "Keine Anmeldeinformationen",
    "Don't ask for user credentials on device boot.":
        "Frage beim Booten des Geräts nicht nach Benutzeranmeldeinformationen.",
    "Lock device": "Gerät sperren",
    "Lock the device after password reset.":
        "Das Gerät nach dem Zurücksetzen des Passworts sperren.",
    Printers: "Drucker",
    Printer: "Drucker",
    "IP address": "IP Adresse",
    "The IP Address of the AirPrint destination": "Die IP-Adresse des AirPrint-Ziels",
    "Resource Path": "Ressourcenpfad",
    "The Resource Path associated with the printer. This corresponds to the rp parameter of the _ipps.tcp Bonjour record. For example: printers/Canon_MG5300_series, printers/Xerox_Phaser_7600 or ipp/print":
        "Der dem Drucker zugeordnete Ressourcenpfad. Dies entspricht dem Parameter rp des Bonjour-Datensatzes _ipps.tcp. Zum Beispiel: printers/Canon_MG5300_series, printers/Xerox_Phaser_7600 oder ipp/print",
    "Force TLS": "TLS erzwingen",
    "If active AirPrint connections are secured by Transport Layer Security (TLS)":
        "Ob aktive AirPrint-Verbindungen durch Transport Layer Security (TLS) gesichert sind",
    "Add printer": "Drucker hinzufügen",
    "Add printers that should always be shown":
        "Fügen Sie Drucker hinzu, die immer angezeigt werden sollen",
    "Allow auto unlock": "Automatisches Entsperren zulassen",
    "Allow cloud address book": "Cloud-Adressbuch zulassen",
    "Allow cloud bookmarks": "Cloud-Lesezeichen zulassen",
    "Allow cloud calendar": "Cloud-Kalender zulassen",
    "Allow cloud desktop & documents": "Cloud-Desktop & Dokumente zulassen",
    "Allow cloud mail": "Cloud-Mail zulassen",
    "Allow cloud notes": "Cloud-Notizen zulassen",
    "Allow cloud reminders": "Cloud-Erinnerungen zulassen",
    "Allow content caching": "Inhalts-Caching zulassen",
    "Allow iTunes filesharing": "iTunes-Dateifreigabe zulassen",
    "Allow lockscreen ControlCenter": "Sperrbildschirm ControlCenter zulassen",
    "Allow lockscreen notifications view":
        "Anzeige von Sperrbildschirm-Benachrichtigungen zulassen",
    "Allow lockscreen today view": "Heute Sperrbildschirmansicht zulassen",
    "Allow managed to write unmanaged contacts":
        "Erlauben Sie verwalteten Apps unverwalteten Kontakten zu schreiben.",
    "iOS Restrictions": "iOS Einschränkungen",
    "Allow unmanaged to read managed contacts":
        "Erlauben Sie unverwalteten Apps verwaltete Kontakte zu lesen.",
    "Allow unmanaged to read managed contacts.":
        "Erlauben Sie unverwalteten Apps verwaltete Kontakte zu lesen.",
    "Allow managed apps to write unmanaged contacts.":
        "Erlauben Sie verwalteten Apps unverwalteten Kontakten zu schreiben.",
    "Allow managed apps to write unmanaged contacts":
        "Erlauben Sie verwalteten Apps unverwalteten Kontakten zu schreiben.",
    "Allow unmanaged apps to read managed contacts":
        "Erlauben Sie unverwalteten Apps verwaltete Kontakte zu lesen.",
    "Allow unmanaged apps to read managed contacts.":
        "Erlauben Sie unverwalteten Apps verwaltete Kontakte zu lesen.",
    "Allow OTAPKI updates": "OTAPKI-Updates zulassen",
    "Allow shared device temporary session": "Temporäre Sitzung des freigegebenen Geräts zulassen",
    "Force AirPlay incoming requests pairing password":
        "Erzwingen Sie das Pairing-Passwort für eingehende AirPlay-Anfragen",
    "Force AirPlay outgoing requests pairing password":
        "Passwort für ausgehende AirPlay-Anfragen erzwingen",
    "Force limit ad tracking": "Anzeigen-Tracking begrenzen",
    "Only dictation": "Nur Diktat",
    "Disable Siri Dictation": "Kein Siri-Diktieren",
    "Force WiFi allowlist": "WLAN-Allowlist erzwingen",
    "Allow AirDrop": "AirDrop zulassen",
    "Allow AirPrint": "AirPrint zulassen",
    "Allow AirPrint credentials storage": "Speichern von AirPrint-Anmeldeinformationen zulassen",
    "Allow AirPrint iBeacon discovery": "AirPrint iBeacon-Erkennung zulassen",
    "Allow cellular plan modification": "Änderung des Mobilfunktarifs zulassen",
    "Allow Cloud keychain sync": "Cloud-Schlüsselbund-Synchronisierung zulassen",
    "Allow device sleep": "Gerät in den Ruhezustand zulassen",
    "Allow ESIM modification": "ESIM-Änderung zulassen",
    "Allow Files USB Drive access": "Zugriff auf Dateien auf USB-Laufwerk zulassen",
    "Allow Find My Friends modification": "Änderung an meine Freunde finden zulassen",
    "Allow host pairing": "Host-Kopplung zulassen",
    "Allow NFC": "NFC zulassen",
    "Allow password autofill": "Automatisches Ausfüllen des Passworts zulassen",
    "Allow password proximity requests": "Anfragen zur Passwortnähe zulassen",
    "Allow password sharing": "Passwortfreigabe zulassen",
    "Allow personal hotspot modification": "Änderung des persönlichen Hotspots zulassen",
    "Allow Podcasts": "Podcasts zulassen",
    "Allow proximity setup to new device": "Erlaube Näherungseinstellungen für neues Gerät",
    "Allow system app removal": "Entfernen von System-Apps zulassen",
    "Allow unpaired external boot to recovery":
        "Nicht gekoppeltes externes Booten zur Wiederherstellung zulassen",
    "Allow USB restricted mode": "Eingeschränkten USB-Modus zulassen",
    "Allow VPN creation": "VPN-Erstellung zulassen",
    "Single App Mode Permitted Apps": "Zulässige Apps im Einzel-App-Modus",
    "Force delayed app updates": "Verzögerte App-Updates erzwingen",
    "Force AirPrint trusted TLS requirement": "AirPrint Trusted TLS-Anforderung erzwingen",
    "Force authentication before autofill": "Authentifizierung vor Autofill erzwingen",
    "Force automatic date and time": "Automatisches Datum und Uhrzeit erzwingen",
    "Force WiFi to allowed networks only": "WLAN nur auf zugelassene Netzwerke erzwingen",
    "Force Delayed Software Updates": "Verzögerte Software-Updates erzwingen",
    "If set to false, disables auto unlock":
        "Wenn inaktiv, wird die automatische Entsperrung deaktiviert",
    "If set to false, disables cloud address book":
        "Wenn inaktiv, wird das Cloud-Adressbuch deaktiviert",
    "If set to false, disables cloud bookmarks":
        "Wenn inaktiv, werden Cloud-Lesezeichen deaktiviert",
    "If set to false, disables cloud calendar": "Wenn inaktiv, wird der Cloud-Kalender deaktiviert",
    "If set to false, disables cloud desktop and documents":
        "Wenn inaktiv, werden Cloud-Desktop und -Dokumente deaktiviert",
    "If set to false, disables cloud mail": "Wenn inaktiv, wird Cloud-Mail deaktiviert",
    "If set to false, disables cloud notes": "Wenn inaktiv, werden Cloud-Notizen deaktiviert",
    "If set to false, disables cloud reminders":
        "Wenn inaktiv, werden Cloud-Erinnerungen deaktiviert",
    "If set to false, disables content caching":
        "Wenn inaktiv, wird das Inhalts-Caching deaktiviert",
    "If set to false, disables iTunes filesharing":
        "Wenn inaktiv, wird die iTunes-Dateifreigabe deaktiviert",
    "If set to false, disables lockscreen ControlCenter":
        "Wenn inaktiv, wird das ControlCenter für den Sperrbildschirm deaktiviert",
    "If set to false, disables lockscreen notifications view":
        "Wenn inaktiv, wird die Benachrichtigungsansicht des Sperrbildschirms deaktiviert",
    "If set to false, disables lockscreen today view":
        "Wenn inaktiv, wird die heutige Sperrbildschirmansicht deaktiviert",
    "If set to false, disables managed to write unmanaged contacts":
        "Wenn inaktiv, wird das Schreiben von nicht verwalteten Kontakten deaktiviert",
    "These restrictions prevent unmanaged apps from accessing contacts on managed accounts and prevent managed apps from storing contacts in the local Contacts app":
        "Diese Einschränkungen verhindern, dass nicht verwaltete Apps auf Kontakte verwalteter Accounts zugreifen und dass verwaltete Apps Kontakte in der lokalen Kontakte-App speichern",
    "If set to false, disables OTAPKI updates": "Wenn inaktiv, werden OTAPKI-Updates deaktiviert",
    "If set to false, disables shared device temporary session":
        "Wenn inaktiv, wird die temporäre Sitzung des gemeinsam genutzten Geräts deaktiviert",
    "If set to true, forces all devices sending AirPlay requests to this device to use a pairing password":
        "Wenn aktiviert, werden alle Geräte, die AirPlay-Anfragen an dieses Gerät senden, gezwungen, ein Pairing-Passwort zu verwenden",
    "If set to true, forces all devices receiving AirPlay requests from this device to use a pairing password":
        "Wenn aktiviert, werden alle Geräte, die AirPlay-Anfragen von diesem Gerät erhalten, gezwungen, ein Pairing-Passwort zu verwenden",
    "If set to true, limits ad tracking":
        "Wenn aktiviert, wird das Anzeigen-Tracking eingeschränkt",
    "If set to true, disables connections to Siri servers for the purposes of dictation":
        "Wenn aktiviert, werden Verbindungen zu Siri-Servern zum Diktieren deaktiviert",
    "If set to false, disables AirDrop": "Wenn inaktiv, wird AirDrop deaktiviert",
    "If set to false, disables AirPrint": "Wenn inaktiv, wird AirPrint deaktiviert",
    "If set to false, disables AirPrint credentials storage":
        "Wenn inaktiv, wird die Speicherung von AirPrint-Anmeldeinformationen deaktiviert",
    "If set to false, disables AirPrint iBeacon discovery":
        "Wenn inaktiv, wird die AirPrint iBeacon-Erkennung deaktiviert",
    "If set to false, disables cellular plan modification":
        "Wenn inaktiv, wird die Änderung des Mobilfunktarifs deaktiviert",
    "If set to false, disables Cloud keychain sync":
        "Wenn inaktiv, wird die Cloud-Schlüsselbund-Synchronisierung deaktiviert",
    "If set to false, disables device Sleep":
        "Wenn inaktiv, wird der Ruhezustand des Geräts deaktiviert",
    "If set to false, disables ESIM modification":
        "Wenn inaktiv, wird die ESIM-Änderung deaktiviert",
    "If set to false, disables Files USB Drive access":
        "Wenn inaktiv, wird der Zugriff auf das Dateien-USB-Laufwerk deaktiviert",
    "If set to false, disables Find My Friends modification":
        "Wenn inaktiv, wird die Modifikation für meine Freunde suchen deaktiviert",
    "If set to false, disables host pairing": "Wenn inaktiv, wird das Host-Pairing deaktiviert",
    "If set to false, disables NFC": "Wenn inaktiv, wird NFC deaktiviert",
    "If set to false, disables password autofill":
        "Wenn inaktiv, wird das automatische Ausfüllen des Passworts deaktiviert",
    "If set to false, disables password proximity requests":
        "Wenn inaktiv, werden Anfragen zur Passwortnähe deaktiviert",
    "If set to false, disables password sharing":
        "Wenn inaktiv, wird die Passwortfreigabe deaktiviert",
    "If set to false, disables personal hotspot modification":
        "Wenn inaktiv, wird die Änderung des persönlichen Hotspots deaktiviert",
    "If set to false, disables Podcasts": "Wenn inaktiv, werden Podcasts deaktiviert",
    "If set to false, disables proximity setup to new device":
        "Wenn inaktiv, wird die Näherungseinrichtung für das neue Gerät deaktiviert",
    "If set to false, disables system app removal":
        "Wenn inaktiv, wird das Entfernen von System-Apps deaktiviert",
    "If set to false, disables unpaired external boot to recovery":
        "Wenn inaktiv, wird das ungepaarte externe Booten zur Wiederherstellung deaktiviert",
    "If set to false, disables USB restricted mode":
        "Wenn inaktiv, wird der eingeschränkte USB-Modus deaktiviert",
    "If set to false, disables VPN creation": "Wenn inaktiv, wird die VPN-Erstellung deaktiviert",
    "If set to true, forces delayed app updates":
        "Wenn aktiviert, werden verzögerte App-Updates erzwungen",
    "If set to true, forces AirPrint trusted TLS requirement":
        "Wenn aktiviert, erzwingt AirPrint die vertrauenswürdige TLS-Anforderung",
    "If set to true, forces authentication before autofill":
        "Wenn aktiviert, wird die Authentifizierung vor dem automatischen Ausfüllen erzwungen",
    "If set to true, forces automatic date and time":
        "Wenn aktiviert, werden Datum und Uhrzeit automatisch erzwungen",
    "If set to true, forces WiFi to allowed networks only":
        "Wenn aktiviert, wird WLAN nur auf zugelassene Netzwerke gezwungen",
    "Last update:": "Letzte Aktualisierung:",
    "Data Protection Officer": "Datenschutzbeauftragter",
    "EU Representative": "EU-Vertreter",
    "Confirm update": "Update bestätigen",
    "Do you really want to update all devices to the latest version?":
        "Wollen Sie wirklich alle Geräte auf die aktuellste Version aktualisieren?",
    "Do you really want to update this device to the latest version?":
        "Sind Sie sicher dass Sie dieses Geräte auf die aktuellste Version aktualisieren wollen?",
    "Update device": "Gerät aktualisieren",
    "Update devices": "Geräte aktualisieren",
    "The update process is a two-step process. Depending on the current status, the download or the installation of the update is initiated.":
        "Der Aktualisierungsprozess ist ein zweistufiger Prozess. Je nach aktuellem Stand wird der Download oder die Installation des Updates angestoßen.",
    "Update to": "Aktualisiere auf",
    "Activate wallpaper": "Wallpaper aktivieren",
    "After activation, you can configure the wallpaper settings. <b>Attention</b>: Wallpapers are not reset to the original wallpaper after deactivating the option.":
        "Nach Aktivierung, können Sie die Wallpaper-Einstellungen konfigurieren. <b>Achtung</b>: Wallpaper werden nach Deaktivierung der Option nicht auf das ursprüngliche Wallpaper zurückgesetzt.",
    "Select wallpaper": "Wallpaper auswählen",
    "Select lockscreen": "Sperrbildschirm auswählen",
    "Use wallpaper as lockscreen, too": "Wallpaper auch als Sperrbildschirm nutzen",
    "Upload new image": "Neues Bild hochladen",
    "Select an image": "Ein Bild auswählen",
    Select: "Auswählen",
    "OS update": "OS aktualisieren",
    "Only available for supervised devices": "Nur für betreute Geräte verfügbar",
    "Update from iOS": "Aktualisierung von iOS",
    to: "zu",
    "Notification settings": "Benachrichtigungseinstellungen",
    "Alert type": "Warnungstyp",
    "Badges enabled": "Badges aktiviert",
    "Enable critical alerts": "Kritische Warnungen aktivieren",
    "Grouping type": "Gruppierungstyp",
    "Enable notifications": "Benachrichtigungen aktivieren",
    "Preview type": "Vorschautyp",
    "Show in CarPlay": "In CarPlay anzeigen",
    "Show in lockscreen": "Im Sperrbildschirm anzeigen",
    "Show in notification center": "Im Benachrichtigungscenter anzeigen",
    "Sounds enabled": "Klänge aktiviert",
    "Add notification setting": "Benachrichtigungseinstellung hinzufügen",
    "The bundle identifier of the application. WARNING: entering an unknown bundleId may cause problems":
        "Die Bundle-ID der Anwendung. WARNUNG: Eingabe einer unbekannten Bundle-ID kann Probleme verursachen",
    'If true, enables critical alerts that can ignore "Do not disturb" and ringer settings for this app':
        'Wenn aktiv, werden kritische Warnungen aktiviert, die "Bitte nicht stören" und Klingeleinstellungen für diese App ignorieren können',
    "The notifications grouping type": "Der Benachrichtigungsgruppierungstyp",
    "Group notifications into app-specified groups":
        "Gruppieren Sie Benachrichtigungen in App-spezifische Gruppen",
    "Group notifications into one group": "Gruppieren Sie Benachrichtigungen in eine Gruppe",
    "Don't group notifications": "Benachrichtigungen nicht gruppieren",
    "The type previews for notifications": "Typen für die Vorschau von Benachrichtigungen",
    "If true, notifications will shown in CarPlay":
        "Wenn aktiv, werden Benachrichtigungen in CarPlay angezeigt",
    "Select alert type": "Alarmtyp auswählen",
    "Select grouping type": "Gruppierungstyp auswählen",
    "Select preview type": "Vorschautyp auswählen",
    "The bundle identifier of the application that handles audio calls made to contacts from this Account":
        "Die Bundle-ID der Anwendung, die Audioanrufe verarbeitet, die an Kontakte von diesem Konto getätigt werden",
    "Audio Calls": "Audioanrufe",
    "Add setting": "Einstellung hinzufügen",
    "Allowed Apps": "Erlaubte Apps",
    "Blocked Apps": "Blockierte Apps",
    profiles: "Profile",
    "other devices (vpn)": "andere Geräte (VPN)",
    certificates: "Zertifikate",
    users: "Benutzer",
    roles: "Rollen",
    notifications: "Benachrichtigungen",
    licenses: "Lizenzen",
    utms: "UTMs",
    reports: "Berichte",
    apps: "Apps",
    "vpp licenses": "VPP Lizenzen",
    "dep profiles": "DEP Profile",
    "DEP Profiles": "DEP Profile",
    "For this tenant the pseudonymization is active. If you want to see usernames, e-mail addresses, IPs etc. in plain text, You have to disable the privacy setting in the Unified Security Portal. This changes the following facts:":
        "Für diesen Tenant ist die Pseudonymisierung aktiv. Wenn Sie Benutzernamen, E-Mail-Adressen, IPs etc. im Klartext sehen möchten, müssen Sie die Datenschutzeinstellung im Unified Security Portal deaktivieren. Dies ändert folgende Punkte:",
    "Do you really want to relinquish this device?":
        "Wollen Sie wirklich auf dieses Gerät verzichten?",
    "This removes the work profile and all policies from a company-owned Android 8.0+ device, relinquishing the device for personal use. Apps and data associated with the personal profile(s) are preserved. The device will be deleted from the server after it acknowledges the command.":
        "Dadurch werden das Arbeitsprofil und alle Richtlinien von einem unternehmenseigenen Android 8.0+-Gerät entfernt und das Gerät für den persönlichen Gebrauch freigegeben. Apps und Daten, die mit dem/den persönlichen Profil(en) verknüpft sind, bleiben erhalten. Das Gerät wird vom Server gelöscht, nachdem es den Befehl bestätigt hat.",
    RELINQUISH: "ENTFERNEN",
    "Relinquish ownership": "Eigentum abgeben",
    "Force encrypted backups": "Verschlüsselte Backups erzwingen",
    "Only join Wi-Fi networks installed by profiles":
        "Nur von Profilen installierten Wi-Fi-Netzwerken beitreten",
    "Allow the user to remove apps": "Erlauben Sie dem Benutzer, Apps zu entfernen",
    "Allow user access of explicit content": "Benutzerzugriff auf explizite Inhalte zulassen",
    "Allow use of iMessage": "Verwendung von iMessage zulassen",
    "If set to false, iBookstore will be disabled": "Wenn deaktiviert, wird iBookstore deaktiviert",
    "If set to false, the user will not be able to download media from the iBookstore that is tagged as erotica":
        "Wenn deaktiviert, kann der Benutzer keine Medien aus dem iBookstore herunterladen, die als Erotik gekennzeichnet sind",
    "Allow the user to access and use iTunes":
        "Erlauben Sie dem Benutzer, auf iTunes zuzugreifen und es zu verwenden",
    "Allow the user to use Safari": "Erlauben Sie dem Benutzer, Safari zu verwenden",
    "Allow the user to add Friends on Game Center":
        "Erlaube dem Benutzer, Freunde zum Game Center hinzuzufügen",
    "Allow modifying Wallpaper": "Ändern des Hintergrundbilds zulassen",
    "Allow configuring restrictions": "Konfigurationseinschränkungen zulassen",
    "Allow document syncing with iCloud": "Dokumentsynchronisierung mit iCloud zulassen",
    "Allow AutoFill in Safari": "Automatisches Ausfüllen in Safari zulassen",
    "Allow video conferencing": "Videokonferenzen zulassen",
    "Allow only a connected Mac host to install applications":
        "Nur einem verbundenen Mac-Host erlauben, Anwendungen zu installieren",
    "Allow automatic app downloads": "Automatische App-Downloads zulassen",
    "Allow the user to install applications": "Erlaubt dem Benutzer Anwendungen zu installieren.",
    "Allow modifying passcode": "Ändern des Passcodes zulassen",
    "If set to false, the user is prohibited from installing configuration profiles and certificates interactively":
        "Wenn inaktiv, ist es dem Benutzer untersagt, Konfigurationsprofile und Zertifikate interaktiv zu installieren",
    'If set to false, the user cannot choose the option "Erase All Content and Settings" in Settings > General > Reset':
        'Wenn deaktiviert, kann der Benutzer die Option "Alle Inhalte und Einstellungen löschen" in Einstellungen > Allgemein > Zurücksetzen nicht auswählen',
    "Whether notifications are allowed for this app":
        "Ob Benachrichtigungen für diese App erlaubt sind",
    "The type of alert for notifications for this app: None/Banner/Alert":
        "Die Art der Benachrichtigung für Benachrichtigungen für diese App: Keine/Temporär/Permanent",
    "Whether badges are allowed for this app": "Ob Badges für diese App erlaubt sind",
    "Whether notifications can be shown in the lock screen":
        "Ob Benachrichtigungen im Sperrbildschirm angezeigt werden können",
    "Whether notifications can be shown in notification center":
        "Ob Benachrichtigungen im Benachrichtigungscenter angezeigt werden können",
    "Whether sounds are allowed for this app": "Ob Klänge für diese App erlaubt sind",
    "Disable Touch Screen": "Touchscreen deaktivieren",
    "Disable Device Rotation": "Geräterotation deaktivieren",
    "Disable Volume Buttons": "Lautstärketasten deaktivieren",
    "Disable Ringer Switch": "Klingelschalter deaktivieren",
    "Disable Sleep Wake Button": "Schlaf-Weck-Taste deaktivieren",
    "Disable Auto Lock": "Automatische Sperre deaktivieren",
    "Enable Voice Over": "Voiceover aktivieren",
    "Enable Zoom": "Zoom aktivieren",
    "Enable Inverted Colors": "Invertierte Farben aktivieren",
    "Enable Speak Selection": "Sprachauswahl aktivieren",
    "Enable Mono Audio": "Mono-Audio aktivieren",
    "Allow user to enable Voice Over": "Benutzer erlauben, Voice Over zu aktivieren",
    "Allow user to enable Zoom": "Benutzer darf Zoom aktivieren",
    "Allow user to enable Inverted Colors":
        "Erlauben Sie dem Benutzer, invertierte Farben zu aktivieren",
    "Allow user to enable AssistiveTouch": "Benutzer darf AssistiveTouch aktivieren",
    "The username for proxy server authentication":
        "Der Benutzername für die Proxyserver-Authentifizierung",
    "The password to authenticate with the proxy": "Das Passwort zur Authentifizierung beim Proxy",
    "The hostname of the proxy server": "Der Hostname des Proxyservers",
    "The port used to connect to the proxy server":
        "Der Port, der für die Verbindung zum Proxy-Server verwendet wird",
    "Retrieve incoming mail through Secure Socket Layer":
        "Eingehende E-Mails über Secure Socket Layer abrufen",
    "The number of past days of mail to synchronzie":
        "Die Anzahl der zu synchronisierenden E-Mails der letzten Tage",
    "Send all communication through Secure Socket Layer":
        "Senden Sie die gesamte Kommunikation über Secure Socket Layer",
    'The address of the account (e.g. "john@company.com")':
        'Die Adresse des Kontos (z. B. "john@company.com")',
    "The Server account password": "Das Serverkonto-Passwort",
    "UUID of the certificate payload containing an identity used as the client credential":
        "UUID der Zertifikatspayload, die eine Identität enthält, die als Client-Anmeldeinformationen verwendet wird",
    "The CalDAV server hostname or IP address":
        "Der Hostname oder die IP-Adresse des CalDAV-Servers",
    "The CalDAV username": "Der CalDAV-Benutzername",
    "The CalDAV password": "Das CalDAV-Passwort",
    "Enable Secure Socket Layer communication with CalDAV server":
        "Aktivieren Sie die Secure Socket Layer-Kommunikation mit dem CalDAV-Server",
    "The port on which to connect to the server":
        "Der Port, über den eine Verbindung zum Server hergestellt werden soll",
    "The URL of the calendar file": "Die URL der Kalenderdatei",
    "The username for this subscription": "Der Benutzername für dieses Abonnement",
    "The password for this subscription": "Das Passwort für dieses Abonnement",
    "Use Secure Socket Layer for this subscription":
        "Verwenden Sie Secure Socket Layer für dieses Abonnement",
    "The description of the calendar subscription": "Die Beschreibung des Kalender-Abonnements",
    "The CardDAV server hostname or IP address": "Der CardDAV-Server-Hostname oder die IP-Adresse",
    "The CardDAV username": "Der CardDAV-Benutzername",
    "The CardDAV password": "Das CardDAV-Passwort",
    "Enable Secure Socket Layer communication with CardDAV server":
        "Aktivieren Sie die Secure Socket Layer-Kommunikation mit dem CardDAV-Server",
    "The port number to connect to the CardDAV server":
        "Die Portnummer zum Verbinden mit dem CardDAV-Server",
    "The Principal URL for the CardDAV account": "Die Haupt-URL für das CardDAV-Konto",
    'The display name of the account (e.g. "Company CalDAV Account")':
        'Der Anzeigename des Kontos (z. B. "Firmen-CalDAV-Konto")',
    'The display name of the account (e.g. "Company CardDAV Account")':
        'Der Anzeigename des Kontos (z. B. "Firmen-CardDAV Account")',
    'The display name of the account (e.g. "Company Server Account")':
        'Der Anzeigename des Kontos (z. B. "Unternehmensserverkonto")',
    'The display name of the account (e.g. "Company Mail Account")':
        'Der Anzeigename des Kontos (z. B. "Firmen E-Mail-Konto ")',
    "Full user name of the Google account": "Vollständiger Benutzername des Google-Kontos",
    "Reseller Portal User": "Reseller Portal Benutzer",
    "Connect RSP User": "RSP Benutzer verbinden",
    "Select license": "Lizenz auswählen",
    "Select licenses": "Lizenzen auswählen",
    Other: "Andere",
    "is available for this device": "ist für dieses Gerät verfügbar",
    "At risk": "In Gefahr",
    "Potentially compromised": "Potenziell kompromittiert",
    "Unknown os": "Unbekanntes OS",
    "Compromised os": "kompromittiertes OS",
    "The user should lock their device's bootloader.":
        "Der Benutzer sollte den Bootloader seines Geräts sperren.",
    Warnings: "Warnungen",
    "The profile is partially installed on this device.":
        "Das Profile ist teilweise auf dem Gerät installiert",
    "profile partially installed": "profil ist teilweise installiert",
    "Please confirm connecting your user from the Securepoint Reseller Portal to Securepoint Unified Security.":
        "Bitte bestätigen Sie die Verknüpfung Ihres Securepoint Reseller Portal Benutzers mit der Securepoint Unified Security.",
    "RSP-Account can be connected": "RSP-Account kann verknüpft werden",
    "Your useraccount from the Securepoint Reseller Portal can now be connected to Securepoint Unified Security.":
        "Ihr Benutzeraccount aus dem Securepoint Reseller Portal kann nun mit der Securepoint Unified Security verknüpft werden.",
    'Connecting your RSP-Account includes the creation of a user and a role called "USC-Administrator", that will be assigned to your user.':
        'Die Verknüpfung des RSP-Accounts beinhaltet die Erstellung eines Benutzers und einer Rolle mit dem Namen "USC-Administrator", welche dem Benutzer zugewiesen ist.',
    "Connect now": "Jetzt verknüpfen",
    "Your license has exceeded its user limit": "Ihre Lizenz hat das Benutzerlimit überschritten",
    "Please extend your Mobile Security license":
        "Bitte verlängern Sie Ihre Mobile Security Lizenz",
    "Please visit the Securepoint Reseller Portal in order to extend your license.":
        "Bitte besuchen Sie das Securepoint Reseller Portal, um Ihre Lizenz zu verlängern.",
    "Extend license": "Lizenz verlängern",
    "The Volume Purchase Program (VPP) makes it easy for businesses to purchase, distribute, and manage their apps and books in bulk.":
        "Das Volume Purchase Program(VPP) macht es Unternehmen einfach, ihre Apps und Bücher in großen Mengen zu kaufen, zu verteilen und zu verwalten.",
    "Two-factor authentication (2FA) is enabled for your account. Please provide a valid Time-based One-time Password (TOTP) code.":
        "Für Ihr Konto ist die Zwei-Faktor-Authentifizierung (2FA) aktiviert. Bitte geben Sie einen gültigen zeitbasierten Einmalpasswort-Code (TOTP) an.",
    "TOTP code": "TOTP-Code",
    "Enable 2FA": "2FA aktivieren",
    "Send Reset Password Mail": "Passwort-Reset-E-Mail versenden",
    "Sends an email to the given email address, asking the user to set a new password and possibly configure the Time-based One-time Password (TOTP) two-factor authentication.":
        "Sendet eine E-Mail an die angegebene E-Mail-Adresse und fordert den Benutzer auf, ein neues Passwort festzulegen und möglicherweise die Zwei-Faktor-Authentifizierung mit zeitbasiertem Einmalpasswort (TOTP) zu konfigurieren.",
    "Enables Time-based One-time Password (TOTP) two-factor authentication.":
        "Aktiviert die zeitbasierte Einmalpasswort (TOTP) Zwei-Faktor-Authentifizierung.",
    "CPU load": "CPU-Auslastung",
    "The device uses mobile data when it is not in the WIFI.":
        "Das Gerät nutzt mobile Daten, wenn es sich nicht im WLAN befindet.",
    "Confirm eula": "EULA akzeptieren",
    "Confirm privacy": "Datenschutzerklärung akzeptieren",
    "Here you can open the administrative web interface of the UTM. If the UTM is accessible via internet connection, no user credentials are required.":
        "Hier können Sie die administrative Weboberfläche der UTM öffnen. Wenn die UTM über eine Internetverbindung zugänglich ist, sind keine Benutzeranmeldeinformationen erforderlich.",
    "When the websession is started, an additional pop-up window opens. Please note that pop-up blockers may prevent the window from opening.":
        "Beim Start der Websession wird ein zusätzliches Popup-Fenster geöffnet. Bitte beachten Sie, dass Popup-Blocker das Öffnen des Fensters gegebenenfalls verhindern können.",
    "If the UTM is not accessible via internet connection, a link to the local administration web interface is made available. To sign in without the help of USC, you need user credentials with administrative rights for the UTM, your IP must be registered as manager IP on the UTM and you need a direct connection to the UTM.":
        "Wenn die UTM nicht über eine öffentliche Internetverbindung erreichbar ist, wird ein Link zum lokalen Administrations-Webinterface bereitgestellt. Um sich ohne Hilfe der USC anzumelden, benötigen Sie Benutzerdaten mit Administratorrechten für die UTM, Ihre IP muss als Manager-IP auf der UTM registriert sein und Sie benötigen eine direkte Verbindung zur UTM.",
    "Customized App ID": "Angepasste App ID",
    "Here you have the option of triggering a security scan of the UTM. The security scan helps you to quickly discover and correct possible misconfigurations. When you create a security scan, it is added to the queue.When the security scan has been completed, you will receive information in the tenant notifications.":
        "Hier haben Sie die Möglichkeit einen Security-Scan der UTM auszulösen. Der Security-Scan hilft Ihnen mögliche Fehlkonfigurationen schnell zu entdecken und zu beheben. Wenn Sie einen Security- Scan erstellen, wird dieser in die Warteschlange angefügt.Wenn der Security - Scan abgeschlossen wurde, bekommen Sie eine Information in den Tenant - Benachrichtigungen.",
    "Please note that the UTM needs a public IP for this.":
        "Bitte beachten Sie das die UTM hierfür eine öffentliche IP braucht.",
    "The IP that should be scanned": "Die IP die gescannt werden soll",
    "The profile that should be scanned": "Das Profil, mit dem gescannt werden soll",
    "Start new scan": "Neuen Scan starten",
    "Start scan": "Scan starten",
    "Allow iCloud Backup": "iCloud Backup erlauben",
    "Accessible Track IDs": "Zugängliche Track IDs",
    "List of the app’s track IDs that a device belonging to the enterprise can access. If the list contains multiple track IDs, devices receive the latest version among all accessible tracks. If the list contains no track IDs, devices only have access to the app’s production track.":
        "Liste der Track-IDs der App, auf die ein Gerät des Unternehmens zugreifen kann. Wenn die Liste mehrere Track-IDs enthält, erhalten Geräte die neueste Version unter allen zugänglichen Tracks. Enthält die Liste keine Track-IDs, haben Geräte nur Zugriff auf den Produktionstrack der App.",
    "Certificate installation": "Zertifikatinstallation",
    "Managed configurations": "Verwaltete Konfigurationen",
    "Block uninstall": "Deinstallation blockieren",
    "Permission grant": "Erlaubnis erteilen",
    "Package access": "Paketzugriff",
    "Enable system apps": "System-Apps aktivieren",
    "If the type is windowed, the start of the maintenance window, measured as the number of minutes after midnight in the device's local time. This value must be between 0 and 1439, inclusive.":
        'Wenn der Typ "Im Fenster" ist: der Beginn des Wartungsfensters, gemessen als Anzahl der Minuten nach Mitternacht in der Ortszeit des Geräts. Dieser Wert muss zwischen 0 und einschließlich 1439 liegen.',
    "If the type is WINDOWED, the end of the maintenance window, measured as the number of minutes after midnight in device's local time. This value must be between 0 and 1439, inclusive. If this value is less than start_minutes, then the maintenance window spans midnight. If the maintenance window specified is smaller than 30 minutes, the actual window is extended to 30 minutes beyond the start time.":
        'Wenn der Typ "Im Fenster" ist: das Ende des Wartungsfensters, gemessen als Anzahl der Minuten nach Mitternacht in der Ortszeit des Geräts. Dieser Wert muss zwischen 0 und 1439 (einschließlich) liegen. Wenn dieser Wert kleiner als die Startzeit ist, erstreckt sich das Wartungsfenster über Mitternacht. Wenn das angegebene Wartungsfenster kleiner als 30 Minuten ist, wird das tatsächliche Fenster auf 30 Minuten über die Startzeit hinaus verlängert.',
    "Temporary banner": "Temporäres Banner",
    "Persistant banner": "Permanentes Banner",
    "S/MIME Enabled": "S/MIME aktiviert",
    "No public IP was found on this UTM, which means that a security scan is unfortunately not possible.":
        "Auf dieser UTM wurde keine öffentliche IP gefunden, daher ist ein Security Scan leider nicht möglich.",
    "Enter the itunes store ID of the customized app (eg. used in Apple VPP).":
        "Geben Sie die iTunes Store- ID der angepassten App ein(z.B.in Apple VPP verwendet).",
    "Unlink account": "Accountverknüpfung aufheben",
    "Unlink Google account": "Google Accountverknüpfung aufheben",
    "Do you really want to unlink this Google Account?":
        "Wollen Sie wirklich die Google Accountverknüpfung aufheben?",
    "OAuth Sign In URL": "OAuth-Anmelde-URL",
    "The OAuth Sign In URL": "Die OAuth-Anmelde-URL",
    "OAuth Token Request URL": "OAuth-Token-Anforderungs-URL",
    "The OAuth Token Request URL": "Die OAuth-Token-Anforderungs-URL",
    "S/MIME Signing overrideable": "S/MIME-Signierung überschreibbar",
    "S/MIME Signing Certificate overrideable": "S/MIME-Signaturzertifikat überschreibbar",
    "S/MIME Encryption overrideable": "S/MIME-Verschlüsselung überschreibbar",
    "S/MIME Encryption Certificate overrideable":
        "S/MIME-Verschlüsselungszertifikat überschreibbar",
    "Enable calendars": "Kalender aktivieren",
    "Enable Calendars": "Kalender aktivieren",
    "Calendars overridable": "Kalender überschreibbar",
    "Enable contacts": "Kontakte aktivieren",
    "Contacts overridable": "Kontakte überschreibbar",
    "Enable mail": "E-Mail aktivieren",
    "Mail overridable": "Mail überschreibbar",
    "Enable notes": "Notizen aktivieren",
    "Enable Notes": "Notizen aktivieren",
    "Notes overridable": "Notizen überschreibbar",
    "Enable reminders": "Erinnerungen aktivieren",
    "Reminders overridable": "Erinnerungen überschreibbar",
    "Override previous password": "Vorheriges Passwort überschreiben",
    "Specifies whether the connection should use OAuth for authentication.If OAuth is specified, the password field should be left empty":
        "Angeben, ob die Verbindung OAuth für die Authentifizierung verwenden soll. Wenn OAuth festgelegt wird, sollte das Passwortfeld leer bleiben",
    "If set to true, S/MIME will be enabled by default for this account":
        "Wenn aktiv, wird S/MIME standardmäßig für dieses Konto aktiviert",
    "If set to true, you will be enabled to configure S/MIME":
        "Wenn aktiv, können Sie S/MIME konfigurieren",
    "Allow the user to enable or disable S/MIME signing":
        "Benutzern das Aktivieren oder Deaktivieren der S/MIME-Signierung erlauben",
    "Allow the user to enable or disable S/MIME encryption":
        "Benutzern das Aktivieren oder Deaktivieren der S/MIME-Verschlüsselung erlauben",
    "Allow the user to modify the S/MIME signing certificate":
        "Benutzern das Ändern des S/MIME-Signierungszertifikats erlauben",
    "Allow the user to modify the S/MIME encryption certificate":
        "Benutzern das Ändern des S/MIME-Verschlüsselungszertifikats erlauben",
    "Allow account to enable/disable Calendars":
        "Konto zum Aktivieren/Deaktivieren von Kalender zulassen",
    "Enable Contacts": "Kontakte aktivieren",
    "Allow account to enable/disable Contacts":
        "Konto zum Aktivieren/Deaktivieren von Kontakten zulassen",
    "Enable Mail": "Mail aktivieren",
    "Allow account to enable/disable Mail": "Konto zum Aktivieren/Deaktivieren von Mail zulassen",
    "Allow account to enable/disable Notes": "Konto zum Aktivieren/Deaktivieren von Notes zulassen",
    "Enable Reminders": "Erinnerungen aktivieren",
    "Allow account to enable/disable Reminders":
        "Erlaube dem Konto, Erinnerungen zu aktivieren/deaktivieren",
    "Specifies whether the connection should use OAuth for authentication. If OAuth is specified, the password field should be left empty":
        "Gibt an, ob die Verbindung OAuth zur Authentifizierung verwenden soll. Wenn OAuth angegeben ist, sollte das Passwortfeld leer bleiben",
    "Use OAuth": "OAuth benutzen",
    "Enable OAuth": "OAuth aktivieren",
    "Preference cookies enable this website to save information that affects the way the website behaves or looks, such as your preferred language or the region you are in.":
        "Präferenz-Cookies ermöglichen es dieser Website, Informationen zu speichern, die sich auf das Verhalten oder Aussehen der Website auswirken, wie z. B. Ihre bevorzugte Sprache.",
    Essential: "Notwendig",
    "Necessary cookies that help make this website usable by enabling basic functions such as access to secure areas of the website. This website cannot function properly without these cookies.":
        "Notwendige Cookies, die dazu beitragen, diese Website nutzbar zu machen, indem sie grundlegende Funktionen wie den Zugriff auf sichere Bereiche der Website ermöglichen. Diese Website kann ohne diese Cookies nicht richtig funktionieren.",
    "Cookie consent": "Cookie-Einwilligung",
    'This website uses cookies. In the following, you can determine which type of cookies may and may not be stored. By clicking on "Accept", you confirm the storage of the cookies according to your specifications.':
        'Diese Internetseite verwendet Cookies. Folgend können Sie festlegen, welche Art von Cookies gespeichert werden dürfen und welche nicht. Durch den Klick auf "Akzeptieren", bestätigen Sie die Speicherung der Cookies nach Ihren Vorgaben.',
    "Force automatic app updates": "Automatische App-Updates erzwingen",
    "Activate Apple Re-Enrollment": "Apple Re-Enrollment aktivieren",
    "If disallowed, disables remote screen observation by the Classroom app. If screenshots are disabled, the Classroom app doesn't observe remote screens":
        "Wenn nicht erlaubt, wird die Remote-Bildschirmüberwachung durch die Classroom-App deaktiviert. Wenn Screenshots deaktiviert sind, beobachtet die Classroom-App keine Remote-Bildschirme",
    "If forced, automatically gives permission to the teacher's requests without prompting the student":
        "Wenn aktiviert werden Kursanfragen des Lehrers automatisch genehmigt ohne den Schüler dazu aufzufordern",
    "If forced and remote screen observation is allowed in the Education payload, a student enrolled in a managed course via the Classroom app automatically gives permission to that course teacher's requests to observe the student's screen without prompting the student":
        "Wenn aktiv und Remote-Bildschirmbeobachtung zulässig ist, werden Anfragen des Lehrers den Bildschirm zu beobachten genehmigt ohne den Schüler dazu aufzufordern. Gilt nur für verwaltete Kurse.",
    "Supervised only": "Nur betreute Geräte",
    "Add system default apps": "Systemapps hinzufügen",
    "Require the user's iTunes password to be entered for every purchase":
        "Fordert bei jedem Kauf die Eingabe des iTunes-Passworts des Benutzers an",
    "Last week": "Letzte Woche",
    "No info": "Keine Info",
    "Use code": "Code nutzen",
    "Restore backup": "Backup wiederherstellen",
    "Must have required property 'username'":
        "Muss die erforderliche Eigenschaft 'Benutzername' haben",
    "Load configuration, set as bootconfiguration and reboot":
        "Konfiguration laden, als Bootkonfiguration setzen und neustarten.",
    Decline: "Ablehnen",
    "Unspecified. Defaults to enforced": "Nicht angegeben. Standardmäßig erzwungen",
    "Default. Force-enables app verification.": "Standard. App-Überprüfung erzwingen.",
    "Allows the user to choose whether to enable app verification.":
        "Erlaubt dem Benutzer zu wählen, ob die App-Überprüfung aktiviert werden soll.",
    "Developer settings": "Entwicklereinstellungen",
    "Controls access to developer settings: developer options and safe boot.":
        "Steuert den Zugriff auf Entwicklereinstellungen: Entwickleroptionen und sicherer Start.",
    "Unspecified.Defaults to disabled.": "Nicht spezifiziert. Standardmäßig deaktiviert.",
    "Allows all developer settings. The user can access and optionally configure the settings.":
        "Erlaubt alle Entwicklereinstellungen. Der Benutzer kann auf die Einstellungen zugreifen und diese optional konfigurieren.",
    "Default.Disables all developer settings and prevents the user from accessing them.":
        "Default.Disabled alle Entwicklereinstellungen und verhindert den Zugriff des Benutzers.",
    "Common criteria mode": "Common Criteria Modus",
    "Controls Common Criteria Mode - security standards defined in the Common Criteria for Information Technology Security Evaluation (CC). Enabling Common Criteria Mode increases certain security components on a device, including AES-GCM encryption of Bluetooth Long Term Keys, and Wi-Fi configuration stores. Warning: Common Criteria Mode enforces a strict security model typically only required for IT products used in national security systems and other highly sensitive organizations. Standard device use may be affected. Only enabled if required.":
        "Kontrolliert den Common Criteria Modus - Sicherheitsstandards, die in den Common Criteria for Information Technology Security Evaluation (CC) definiert sind. Das Aktivieren des Common Criteria Modus erhöht bestimmte Sicherheitskomponenten auf einem Gerät, einschließlich der AES-GCM-Verschlüsselung von Bluetooth-Langzeitschlüsseln und der Wi-Fi-Konfiguration Warnung: Der Common Criteria Modus erzwingt ein strenges Sicherheitsmodell, das normalerweise nur für IT-Produkte erforderlich ist, die in nationalen Sicherheitssystemen und anderen hochsensiblen Organisationen verwendet werden. Die Verwendung von Standardgeräten kann beeinträchtigt sein. Nur bei Bedarf aktiviert.",
    "Unspecified. Defaults to disabled.": "Nicht spezifiziert. Standardmäßig deaktiviert.",
    "Default. Disables Common Criteria Mode.": "Standard. Deaktiviert den Common Criteria-Modus.",
    "Enables Common Criteria Mode.": "Aktiviert den Common Criteria-Modus.",
    "Personal apps that can read work notifications":
        "Persönliche Apps, die Arbeitsbenachrichtigungen lesen können",
    "Personal apps that can read work profile notifications using a NotificationListenerService. By default, no personal apps (aside from system apps) can read work notifications. Each value in the list must be a package name.":
        "Persönliche Apps, die Benachrichtigungen zu Arbeitsprofilen mit einem NotificationListenerService lesen können. Standardmäßig können keine persönlichen Apps (außer System-Apps) Arbeitsbenachrichtigungen lesen. Jeder Wert in der Liste muss ein Paketname sein.",
    "Unlink Enterprise Account": "Verknüpfung mit Enterprise Account aufheben",
    "No Enrollmenttoken": "Kein Enrollmenttoken",
    "Enrollmenttoken expired": "Enrollmenttoken abgelaufen",
    "The UTM rebooted was successful": "Die UTM wurde erfolgreich neugestartet",
    "The reboot of the UTM took": "Der Neustart der UTM benötigte",
    seconds: "Sekunden",
    "A scan is already running. Please wait till the scan has been finished.":
        "Ein Scan läuft bereits. Bitte warten Sie, bis der Scan beendet wurde.",
    "Websession address differs from request source:":
        "Die Websession Adresse unterscheidet sich von der Eingabe:",
    "Error retrieving Google Enterprise info": "Fehler beim Abholen der Enterprise Infos",
    "Caller is not authorized to manage enterprise.":
        "Sie sind nicht berechtigt, das Unternehmen zu verwalten.",
    "Android Enterprise features will be disabled for this session":
        "Android Enterprise Funktionen werden für diese Session deaktiviert",
    "Do you really want to delete all notifications?":
        "Wollen Sie wirklich alle Benachrichtigungen löschen?",
    "Do you really want to delete all selected notifications?":
        "Wollen Sie wirklich alle ausgewählten Benachrichtigungen löschen?",
    "Confirm delete": "Löschen bestätigen",
    "Delete all": "Alle löschen",
    "Delete selected only": "Nur ausgewählte löschen",
    "Load configuration, set as bootconfiguration and reboot.":
        "Lade die Konfiguration, setze Sie als Bootkonfiguration und starte neu.",
    "Renew MDM-Profile": "MDM-Profil erneuern",
    "Expire time": "Ablaufdatum",
    "Last uploads": "Letze uploads",
    "Apple DEP/School: Error": "Apple DEP/School: Fehler",
    "It seems like the Apple DEP/School agreement has been updated.":
        "Es scheint als wären die Nutzungsbedingungen für Apple DEP/School aktualisiert worden.",
    "Please visit the": "Bitte besuchen Sie den",
    "in order to accept the new agreement.": "um die neuen Nutzungsbedingungen zu akzeptieren.",
    "Note:": "Beachten Sie:",
    "This action has to be done by an administrator.":
        "Dies muss von einem Administrator durchgeführt werden.",
    "Whether the enrollment requires a code or not.":
        "Legt fest ob beim Enrollment ein Code benötigt wird oder nicht.",
    "No data available": "Keine Daten verfügbar",
    "Is secure": "Ist sicher",
    "Development settings enabled": "Entwicklereinstellungen aktiv",
    "ADB enabled": "ADB aktiv",
    Encrypted: "Verschlüsselt",
    "Verify Apps": "Apps verifizieren",
    "Security posture": "Sicherheitsstatus",
    Secure: "Sicher",
    Unsecure: "Unsicher",
    "Block scope": "Blockumfang",
    "Specifies the scope of block action. Only applicable to devices that are company-owned.":
        "Gibt den Umfang der Blockierungsaktion an. Gilt nur für Geräte, die im Besitz des Unternehmens sind.",
    "Unspecified. Defaults to work profile.": "Nicht angegeben. Standardmäßig Arbeitsprofil.",
    "Work profile": "Arbeitsprofil",
    "Block action is only applied to apps in the work profile. Apps in the personal profile are unaffected.":
        "Die Blockierungsaktion wird nur auf Apps im Arbeitsprofil angewendet. Apps im persönlichen Profil sind nicht betroffen.",
    "Block action is applied to the entire device, including apps in the personal profile.":
        "Die Blockierungsaktion wird auf das gesamte Gerät angewendet, einschließlich der Apps im persönlichen Profil.",
    "Whether the device is secured with PIN/password.":
        "Ob das Gerät mit PIN/Passwort gesichert ist.",
    "Whether installing apps from unknown sources is enabled.":
        "Ob die Installation von Apps aus unbekannten Quellen aktiviert ist.",
    "Whether developer mode is enabled on the device.":
        "Ob der Entwicklermodus auf dem Gerät aktiviert ist.",
    "Whether ADB is enabled on the device.": "Ob ADB auf dem Gerät aktiviert ist.",
    "Whether the storage encryption is enabled.": "Ob die Speicherverschlüsselung aktiviert ist.",
    "Whether Google Play Protect verification is enforced on the device.":
        "Ob die Google Play Protect-Bestätigung auf dem Gerät erzwungen wird.",
    "Here is a list of your last uploads.": "Hier ist eine Liste Ihrer letzten Uploads.",
    "Company name must be set.": "Der Firmenname muss festgelegt werden.",
    "Contact E-mail is not valid.": "Die Kontakt-E-Mail ist nicht gültig.",
    "Cross profile policies": "Profilübergreifende Richtlinien",
    "Cross-profile policies applied on the device.":
        "Auf dem Gerät angewendete profilübergreifende Richtlinien.",
    "show work contacts in personal profile": "Arbeitskontakte im persönlichen Profil anzeigen",
    "Whether contacts stored in the work profile can be shown in personal profile contact searches and incoming calls.":
        "Ob Kontakte, die im Arbeitsprofil gespeichert sind, in der Kontaktsuche im persönlichen Profil und bei eingehenden Anrufen angezeigt werden können.",
    "Unspecified. Defaults to allowed.": "Nicht angegeben. Standardmäßig zulässig.",
    "Prevents work profile contacts from appearing in personal profile contact searches and incoming calls.":
        "Verhindert, dass Kontakte aus dem Arbeitsprofil bei der Suche nach persönlichen Profilkontakten und eingehenden Anrufen angezeigt werden.",
    "Default. Allows work profile contacts to appear in personal profile contact searches and incoming calls.":
        "Standard. Erlaubt das Erscheinen von Arbeitsprofilkontakten bei der Suche nach persönlichen Profilkontakten und eingehenden Anrufen.",
    "Cross profile copy&paste": "Profilübergreifendes Kopieren & Einfügen",
    "Whether text copied from one profile (personal or work) can be pasted in the other profile.":
        "Ob Text, der aus einem Profil (persönlich oder geschäftlich) kopiert wurde, in das andere Profil eingefügt werden kann.",
    "Unspecified. Defaults to disallowed.": "Nicht angegeben. Standardmäßig nicht zulässig.",
    "Default. Prevents users from pasting into the personal profile text copied from the work profile. Text copied from the personal profile can be pasted into the work profile, and text copied from the work profile can be pasted into the work profile.":
        "Standard. Verhindert, dass Benutzer aus dem Arbeitsprofil kopierten Text in das persönliche Profil einfügen. Aus dem persönlichen Profil kopierter Text kann in das Arbeitsprofil eingefügt werden und aus dem Arbeitsprofil kopierter Text kann in das Arbeitsprofil eingefügt werden.",
    "Text copied in either profile can be pasted in the other profile.":
        "Text, der in einem der Profile kopiert wurde, kann in das andere Profil eingefügt werden.",
    "Cross profile data sharing": "Profilübergreifende Datenfreigabe",
    "Whether data from one profile (personal or work) can be shared with apps in the other profile. Specifically controls simple data sharing via intents. Management of other cross-profile communication channels, such as contact search, copy/paste, or connected work & personal apps, are configured separately.":
        "Ob Daten aus einem Profil (persönlich oder geschäftlich) mit Apps im anderen Profil geteilt werden können. Steuert gezielt den einfachen Datenaustausch über Intents. Verwaltung anderer profilübergreifender Kommunikationskanäle, wie Kontaktsuche, Kopieren/Einfügen oder verbundene Arbeit & persönliche Apps, werden separat konfiguriert.",
    "Prevents data from being shared from both the personal profile to the work profile and the work profile to the personal profile.":
        "Verhindert, dass Daten sowohl vom persönlichen Profil an das Arbeitsprofil als auch vom Arbeitsprofil an das persönliche Profil weitergegeben werden.",
    "Default. Prevents users from sharing data from the work profile to apps in the personal profile. Personal data can be shared with work apps.":
        "Standard. Verhindert, dass Benutzer Daten aus dem Arbeitsprofil für Apps im persönlichen Profil freigeben. Persönliche Daten können mit Arbeits-Apps geteilt werden.",
    "Data from either profile can be shared with the other profile.":
        "Daten von einem der Profile können mit dem anderen Profil geteilt werden.",
    "Disallow from work to personal profile": "Von der Arbeit zum persönlichen Profil verweigern",
    "Google is reporting:": "Google meldet:",
    'If your Google-Account has been deleted, you can unlink it by clicking the "Unlink" button.':
        'Wenn Ihr Google-Account gelöscht wurde, können Sie die Verknüpfung aufheben, indem Sie auf "Verknüpfung aufheben" klicken.',
    "UTM Version has to be at least %s?": "Die UTM Version muss mindestens %s sein",
    "Websessions are not available": "Websessions sind nicht verfügbar",
    "Security-Scan is not available": "Security-Scan ist nicht verfügbar",
    "License sync": "Lizenz Synchronisierung",
    "The Securepoint Unified Security Portal can manage your Android Zero Touch configurations for you. This allows you to do this directly in the portal you are used to without having to switch to the Android Zero Touch portal. To do this, you must grant the Unified Security Portal permission to access your Google account.":
        "Das Securepoint Unified Security Portal kann Ihre Android Zero Touch Konfigurationen für Sie verwalten. Dadurch können Sie dies direkt im gewohnten Portal erledigen ohne in das Android Zero Touch Portal wechseln zu müssen. Hierfür müssen Sie dem Unified Security Portal die Berechtigung für den Zugriff auf Ihr Google Konto gewähren.",
    "Sign in to proceed": "Einloggen um fortzufahren",
    "Google Zero Touch seems to be linked already":
        "Google Zero Touch scheint bereits verknüpft zu sein",
    "Link Google account": "Google Account verknüpfen",
    "Grant permission": "Zugriff gewähren",

    "Conference Room Display": "Konferenzraum-Display",
    "Activate Conference Room Display": "Konferenzraum-Display aktivieren",
    "Conference Room Display mode locks Apple TV into that mode, to prevent other types of usage":
        "Konferenzraum-Displaymodus sperrt Apple TV in diesem Modus, um andere Nutzungsarten zu verhindern",
    "Enter message": "Nachricht eingeben",
    "The custom message displayed on the screen in Conference Room Display mode":
        "Die benutzerdefinierte Nachricht, die im Konferenzraum-Displaymodus auf dem Bildschirm angezeigt wird",
    "TV Remote": "TV-Fernbedienung",
    "Activate TV Remote": "TV-Fernbedienung aktivieren",
    "Enables configuring the Apple TV Remote": "Ermöglicht die Konfiguration der Apple TV Remote",
    "Allowed remotes": "Erlaubte Remotes",
    "A valid Device that Apple TV can connect to":
        "Ein gültiges Gerät, mit dem Apple TV eine Verbindung herstellen kann",
    "Allowed TVs": "Erlaubte TVs",
    "A valid Apple TV that the remotes can connect to":
        "Eine gültige Apple TV-Kennung, mit der die Remote eine Verbindung herstellen kann",
    "AirPlay security": "AirPlay-Sicherheit",
    "Activate AirPlay security": "AirPlay-Sicherheit aktivieren",
    "Activates AirPlay security settings": "Aktiviert AirPlay Sicherheitseinstellungen",
    "Access Type": "Zugangsart",
    "The access policy for AirPlay": "Die Zugriffsrichtlinie für AirPlay",
    Any: "Irgendein(e)",
    "Allows connections from both Ethernet/WiFi and Apple Wireless Direct Link":
        "Erlaubt Verbindungen sowohl über Ethernet/WiFi als auch über Apple Wireless Direct Link",
    "WIFI only": "Nur Wifi",
    "Allows connections only from devices on the same Ethernet/WiFi network as Apple TV":
        "Erlaubt Verbindungen nur von Geräten im selben Ethernet/WiFi-Netzwerk wie Apple TV",
    "Security Type": "Sicherheitsstufe",
    "The security policy for AirPlay": "Die Sicherheitsrichtlinie für AirPlay",
    "Requires a passphrase as specified in the Password key":
        "Erfordert eine Passphrase wie im Passwortschlüssel angegeben",
    "Passcode always": "Passcode immer",
    "Requires an onscreen passcode for every AirPlay connection":
        "Erfordert einen Bildschirm-Passcode für jede AirPlay-Verbindung",
    "Passcode once": "Passcode einmal",
    "Requires an onscreen passcode on first connection from a device. Subsequent connections from the same device aren't prompted":
        "Erfordert einen Bildschirm-Passcode bei der ersten Verbindung von einem Gerät. Nachfolgende Verbindungen von demselben Gerät werden nicht aufgefordert",
    "Activate locating functionality": "Aktiviere Lokalisierungsfunktion",
    "Adds functionality to locate the devices that are assigned to this policy. This functionality is limited to fully managed devices only.":
        "Fügt Funktionen zum Auffinden der Geräte hinzu, die dieser Richtlinie zugewiesen sind. Diese Funktionalität ist nur auf voll verwaltete Geräte beschränkt.",
    Locating: "Lokalisieren",
    "Please activate this feature for your UTM": "Bitte aktivieren Sie dieses Feature in Ihrer UTM",
    "Inventory location is not set": "Inventarstandort ist nicht gesetzt",
    "Click the following link to set the inventory location:":
        "Klicken Sie auf den folgenden Link, um den Inventarstandort festzulegen:",
    "Set location": "Standort festlegen",
    "Your Apple DEP-Token has expired": "Ihr Apple DEP-Token ist abgelaufen",
    "Please extend your Apple DEP-Token": "Bitte verlängern Sie Ihren Apple DEP-Token",
    "Extend now": "Jetzt verlängern",
    "Allow Mail privacy protection": "Erlaube E-Mail-Datenschutz",
    "Allow automatic screensaver": "Automatischen Bildschirmschoner zulassen",
    "Default. Disables all developer settings and prevents the user from accessing them.":
        "Standard. Deaktiviert alle Entwicklereinstellungen und verhindert, dass der Benutzer darauf zugreift.",
    "Do you want to remove the profile from this device?":
        "Möchten Sie das Profil von diesem Gerät entfernen?",
    "Do you really want to delete selected objects?":
        "Möchten Sie die ausgewählten Objekte wirklich löschen?",
    "Link Identity Provider": "Identitätsanbieter verknüpfen",
    Unlinked: "Nicht verknüpft",
    Maintenance: "Wartung",
    maintenance: "Wartung",
    "License Info": "Lizenzinformationen",
    "Missing Apple Push Certificate": "Apple Push Zertifikat fehlt",
    "Error getting object": "Fehler beim Holen des Objekts",
    "It seems like this object can't be requested with your current Apple DEP-Token.":
        "Anscheinend kann dieses Objekt nicht mit Ihrem aktuellen Apple DEP-Token angefordert werden.",
    "This could happen if the object you requested was managed with another Apple DEP-Token.":
        "Dies könnte passieren, wenn das von Ihnen angeforderte Objekt mit einem anderen Apple DEP-Token verwaltet wurde.",
    "Allow Automatic Screensaver": "Erlaube automatischen Bildschrimschoner",
    "Allows Automatic Screensaver. Available in tvOS 15.4 and later.":
        "Erlaubt automatische Bildschirmschoner. Verfügbar ab tvOS 15.4 und aufwärts.",
    Address: "Adresse",
    "Select sources": "Wählen Sie Quellen",
    "Enter an email address": "Geben Sie eine E-Mail-Adresse ein",
    "Edit blocklist address": "Blocklisten Adresse bearbeiten",
    "Update address": "Adresse aktualisieren",
    "Add new address": "Neue Adresse hinzufügen",
    "Add new blocklist address": "Neue Blocklisten Adresse hinzufügen",
    "Add address": "Adresse hinzufügen",
    "Generate import-code": "Import-Code generieren",
    "Copied code!": "Code kopiert!",
    "What to do next:": "Was als nächstes zu tun ist:",
    "After closing the window, this code can't be retrieved anymore.":
        "Nach dem Schließen des Fensters, kann dieser Code nicht mehr abgerufen werden.",
    "Add layout": "Layout hinzufügen",
    "Edit Home Screen Layout": "Home-Bildschirmlayout bearbeiten",
    "Add Home Screen Layout": "Home-Bildschirmlayout hinzufügen",
    "Home Screen Layout": "Home-Bildschirmlayout",
    "Home Screen Layouts": "Home-Bildschirmlayouts",
    "Add remote": "Remote hinzufügen",
    "Add remotes": "Remotes hinzufügen",
    "Add TVs": "TVs hinzufügen",
    "Add TV": "TV hinzufügen",
    "Name of the TV": "Name des TVs",
    "The name of the TV": "Der Name des TVs",
    "MAC-Address": "MAC-Adresse",
    "Remote device ID": "Remote Geräte ID",
    "The name of this layout": "Der Name des Layouts",
    "Add all system apps": "Alle Systemanwendungen hinzufügen",
    "Add all Mobile Security apps": "Alle Mobile Security Apps hinzufügen",
    "Add all apps": "Alle Apps hinzufügen",
    "System Application": "Systemanwendung",
    "The admin user": "Der Administrator",
    "once the period has expired": "sobald der Zeitraum abgelaufen ist",
    "Example: emails archived on %s will be deleted on %s.":
        "Beispiel: Am %s archivierte E-Mails werden am %s gelöscht.",
    Abort: "Abbrechen",
    "Keep email for": "Archiviere E-Mail für",
    "Delete the email immediately": "Lösche die E-Mail umgehend",
    "Keep the email until next": "Behalte die E-Mail bis zum nächsten",
    Rules: "Regeln",
    "All conditions apply": "Alle Bedingungen zutreffen",
    "Any condition applies": "Irgendeine Bedingung zutrifft",
    "No condition applies": "Keine Bedingung zutrifft",
    If: "Wenn",
    "Add Entry": "Eintrag hinzufügen",
    years: "Jahre",
    "months and": "Monate und",
    days: "Tage",
    "Delete page": "Seite löschen",
    "Do you really want to delete this page?": "Möchten Sie die Seite wirklich löschen?",
    Ascending: "Aufsteigend",
    Descending: "Absteigend",
    Order: "Reihenfolge",
    "Sort by": "Sortiere nach",
    "This will delete your apple DEP-Token from Mobile Security":
        "Dies wird Ihren apple DEP-Token von der Mobile Security löschen",
    "This will delete your apple VPP-Token from Mobile Security":
        "Dies wird Ihren apple VPP-Token von der Mobile Security löschen",
    "The entered address already exists": "Die eingegebene Adresse existiert bereits",
    "The entered name already exists": "Die eingegebene Name existiert bereits",
    String: "Zeichenfolge",
    Number: "Nummer",

    "Number/Integer": "Zahl/Ganzzahl",
    "Array of objects": "Array von Objekten",
    "Array of strings": "Array von Zeichenfolgen",
    "Add object": "Objekt hinzufügen",
    "Add field": "Feld hinzufügen",
    Property: "Eigenschaft",
    "Unassign licenses": "Lizenzzuweisung aufheben",
    "Assign/Unassign VPP licenses": "VPP Lizenzen zuweisen/aufheben",
    "Assign Licenses": "Lizenzen zuweisen",
    "Edit license": "Lizenz bearbeiten",
    "The system is protected": "Das System ist geschützt",
    "Reset to default": "Zurücksetzen",
    "The logo will be resetted to the default Securepoint-Logo":
        "Das Logo wird auf das Securepoint-Logo zurückgesetzt",
    "Device Locator Enabled": "Geräte-Lokalisierung aktiviert",
    "Network tethered": "Netzwerk geteilt",
    "Allow Cloud Private Relay": "Privates Cloud-Relay zulassen",
    "If set to false, disables iCloud Private Relay":
        "Wenn deaktiviert, wird iCloud Private Relay deaktiviert",
    "Require Managed Pasteboard": "Verwaltete Zwischenablage erforderlich",
    'If enabled, copy and paste functionality respects the "allow open from managed to unmanaged" and "allow open from unmanaged to managed" restrictions.':
        'Wenn aktiviert, beachtet die Funktion zum Kopieren und Einfügen die Einschränkungen "Öffnen von verwaltet zu nicht verwaltet zulassen" und "Öffnen von nicht verwaltet zu verwaltet zulassen".',
    "Fingerprint timeout": "Fingerabdruck-Zeitüberschreitung",
    "The time after which the fingerprint unlock will require a password to authenticate.":
        "Die Zeit, nach der das Entsperren des Fingerabdrucks ein Passwort zur Authentifizierung erfordert.",
    "Force on-device only translation": "Übersetzung nur auf dem Gerät erzwingen",
    "If enabled, the device won’t connect to Siri servers for the purposes of translation.":
        "Wenn diese Option aktiviert ist, verbindet sich das Gerät zu Übersetzungszwecken nicht mit Siri-Servern.",
    "Batch processing": "Stapelverarbeitung",
    Serial: "Seriennummer",
    "Login required.": "Anmeldung erforderlich.",
    "Internal error.": "Interner Fehler.",
    "Unable to find the registered user.": "Der registrierte Benutzer kann nicht gefunden werden.",
    "The token has expired. You need to generate a new token online using your organization’s account at either school.apple.com or business.apple.com.":
        "Das Token ist abgelaufen. Sie müssen online ein neues Token generieren, indem Sie das Konto Ihrer Organisation entweder unter school.apple.com oder business.apple.com verwenden.",
    "The server has revoked the sToken.": "Der Server hat das sToken widerrufen.",
    "This service is no longer available.": "Dieser Dienst ist nicht mehr verfügbar.",
    "There are too many requests for the current Organization and the request has been rejected, either due to high server volume or an MDM issue. Use an incremental/exponential backoff strategy to retry the request until successful.":
        "Es gibt zu viele Anfragen für die aktuelle Organisation und die Anfrage wurde abgelehnt, entweder aufgrund eines hohen Servervolumens oder eines MDM-Problems. Verwenden Sie eine inkrementelle/exponentielle Backoff-Strategie, um die Anfrage erneut zu versuchen, bis sie erfolgreich ist.",
    "The provided page index must be greater than 0, and less than the total number of pages.":
        "Der angegebene Seitenindex muss größer als 0 und kleiner als die Gesamtzahl der Seiten sein.",
    "This request exceeds the maximum assets limit. Change the request to stay within the specified limit.":
        "Diese Anfrage überschreitet das maximale Guthabenlimit. Ändern Sie die Anfrage so, dass sie innerhalb des angegebenen Limits bleibt.",
    "This request exceeds the maximum clientUserIds limit. Change the request to stay within the specified limit.":
        "Diese Anfrage überschreitet das maximale ClientUserIds-Limit. Ändern Sie die Anfrage so, dass sie innerhalb des angegebenen Limits bleibt.",
    "This request exceeds the maximum serialNumbers limit. Change the request to stay within the specified limit.":
        "Diese Anfrage überschreitet das maximale Limit für Seriennummern. Ändern Sie die Anfrage so, dass sie innerhalb des angegebenen Limits bleibt.",
    "This request contains duplicate assets. Change the request to send unique assets.":
        "Diese Anfrage enthält doppelte Assets. Ändern Sie die Anfrage, um eindeutige Assets zu senden.",
    "This request contains duplicate clientUserIds. Change the request to send unique client IDs.":
        "Diese Anfrage enthält doppelte clientUserIds. Ändern Sie die Anfrage, um eindeutige Client-IDs zu senden.",
    "This request contains duplicate serialNumbers. Change the request to send unique serial numbers.":
        "Diese Anfrage enthält doppelte Seriennummern. Ändern Sie die Anfrage, um eindeutige Seriennummern zu senden.",
    "The asset in the request isn’t revocable. Change the request to send revocable assets.":
        "Das Asset in der Anfrage ist nicht widerrufbar. Ändern Sie die Anfrage, um widerrufliche Assets zu senden.",
    "The asset in the request isn’t device-assignable. Change the request to send device-assignable assets.":
        "Das Asset in der Anfrage ist kein Gerät – zuweisbar. Ändern Sie die Anfrage zum Senden von Gerät – zuweisbare Assets.",
    "Both the notification URL and the authentication token are necessary.":
        "Sowohl die Benachrichtigungs-URL als auch das Authentifizierungstoken sind erforderlich.",
    "Either the notification URL or the authentication token exceeds its maximum length. Change the request to stay within the specified limit.":
        "Entweder die Benachrichtigungs-URL oder das Authentifizierungstoken überschreitet die maximale Länge. Ändern Sie die Anfrage so, dass sie innerhalb des angegebenen Limits bleibt.",
    "The provided notification URL must be a valid URL and use HTTPS as the protocol.":
        "Die angegebene Benachrichtigungs-URL muss eine gültige URL sein und HTTPS als Protokoll verwenden.",
    "The MDM metadata exceeds the maximum length. Change the request to stay within the specified limit.":
        "Die MDM-Metadaten überschreiten die maximale Länge. Ändern Sie die Anfrage so, dass sie innerhalb des angegebenen Limits bleibt.",
    "The number of users exceeds the maximum. Change the request to stay within the specified limit.":
        "Die Anzahl der Benutzer überschreitet das Maximum. Ändern Sie die Anfrage so, dass sie innerhalb des angegebenen Limits bleibt.",
    "This request contains duplicate users. Change the request to send unique users.":
        "Diese Anfrage enthält doppelte Benutzer. Ändern Sie die Anfrage, um eindeutige Benutzer zu senden.",
    "This request contains an invalid email address. Change the request to send valid email addresses.":
        "Diese Anfrage enthält eine ungültige E-Mail-Adresse. Ändern Sie die Anfrage, um gültige E-Mail-Adressen zu senden.",
    "This request doesn’t contain an asset, which is a required argument. Change the request to provide an asset.":
        "Diese Anfrage enthält kein Asset, was ein erforderliches Argument ist. Ändern Sie die Anfrage, um ein Asset bereitzustellen.",
    "Either clientUserIds or serialNumbers are required arguments. Change the request to provide assignable users and devices.":
        "Entweder clientUserIds oder serialNumbers sind erforderliche Argumente. Ändern Sie die Anfrage, um zuweisbare Benutzer und Geräte bereitzustellen.",
    "Users are a required argument. Change the request to provide a user.":
        "Benutzer sind ein erforderliches Argument. Ändern Sie die Anfrage, um einen Benutzer anzugeben.",
    "This request contains an invalid version ID. Change the request to send the version ID from the read APIs.":
        "Diese Anfrage enthält eine ungültige Versions-ID. Ändern Sie die Anfrage, um die Versions-ID von den gelesenen APIs zu senden.",
    "You must provide an MDM name, ID, and metadata when setting the MDM information.":
        "Beim Festlegen der MDM-Informationen müssen Sie einen MDM-Namen, eine ID und Metadaten angeben.",
    "The MDM ID exceeds the maximum length. Change the request to stay within the specified limit.":
        "Die MDM-ID überschreitet die maximale Länge. Ändern Sie die Anfrage so, dass sie innerhalb des angegebenen Limits bleibt.",
    "The MDM name exceeds the maximum length. Change the request to stay within the specified limit.":
        "Der MDM-Name überschreitet die maximale Länge. Ändern Sie die Anfrage so, dass sie innerhalb des angegebenen Limits bleibt.",
    "This request contains an unsupported HTTP method for the requested endpoint.":
        "Diese Anfrage enthält eine nicht unterstützte HTTP-Methode für den angeforderten Endpunkt.",
    "This request contains an unsupported Content-Type for the requested endpoint.":
        "Diese Anfrage enthält einen nicht unterstützten Inhaltstyp für den angeforderten Endpunkt.",
    "The provided notification URL is unreachable.":
        "Die angegebene Benachrichtigungs-URL ist nicht erreichbar.",
    Mon: "Mo",
    Tue: "Di",
    Wed: "Mi",
    Thu: "Do",
    Fri: "Fr",
    Sat: "Sa",
    Sun: "So",
    "o'clock": "Uhr",
    Folder: "Ordner",
    "Add all iOS Apps": "Alle iOS Apps hinzufügen",
    "Select type": "Typ auswählen",
    "Select a pre defined Home Screen Layout": "Ein vordefiniertes Home-Bildschirmlayout auswählen",
    "create a specific Home Screen Layout": "Ein spezifisches Home-Bildschirmlayout erstellen",
    "Defines wether to use a pre defined Home Screen Layout or create a profile specific one.":
        "Definiert ob ein vordefiniertes Home-Bildschirmlayout ausgewählt werden, oder ob eines erstellt werden soll.",
    "Do you really want to delete this Home Screen Layout?":
        "Wollen Sie dieses Home-Bildschirmlayout wirklich löschen?",
    "The name of this profile": "Der Name dieses Profils",
    "Updates on": "Aktualisiert an",
    "The profile will be assigned to these UTMs": "Das Profil wird diesen UTMs zugewiesen",
    "The profile will be assigned to these UTMs. <strong>Attention:</strong> If UTMs are selected that have less than 3GB of memory, then these are excluded from automatic updates":
        "Das Profil wird diesen UTMs zugewiesen. <strong>Achtung:</strong> Wenn UTMs ausgewählt werden, die weniger als 3GB Speicher haben, dann werden diese von automatischen Updates ausgeschlossen.",
    "The profile will be assigned to all UTMs with these Tags":
        "Das Profil wird allen UTMs mit diesen Tags zugewiesen",
    "Add UTMs": "UTMs hinzufügen",
    "By app": "Nach App",
    Off: "Aus",
    "When unlocked": "Wenn entsperrt",
    From: "Von",
    "The time refers to UTC": "Die Zeit bezieht sich auf UTC",
    "Select layout": "Layout auswählen",
    "Choose from existing Home Screen Layouts that will be used with this profile.":
        "Wählen Sie aus vorhandenen Home-Bildschirmlayouts aus, die mit diesem Profil verwendet werden.",
    "This corresponds to your local time:": "Dies entspricht Ihrer Ortszeit:",
    "<strong>Automatic updates:</strong><br>The UTM is restarted during the update process. All connections to the UTM (e.g. VPN, SSH) are interrupted.<br><strong>Period:</strong></br>The time is given in UTC. UTC does not use daylight saving time! The update is triggered by the portal. For better load distribution, only one period can be selected within which the process is triggered.<br><strong>Once a month:</strong><br>This option reduces the interval to once a month. For example, if you selected Mondays for installing updates, the installation will be performed only on the 1st Monday of the month.":
        "<strong>Automatische Updates:</strong><br>Die UTM wird während des Update-Vorgangs neu gestartet. Alle Verbindungen zur UTM (z. B. VPN, SSH) werden unterbrochen.<br><strong>Regelmäßigkeit:</strong></br>Die Uhrzeit wird in UTC angegeben. UTC verwendet keine Sommerzeit! Das Update wird vom Portal angestoßen. Zur besseren Lastverteilung kann nur ein Zeitraum ausgewählt werden, innerhalb dessen der Prozess ausgelöst wird.<br><strong>Einmal im Monat:</strong><br>Diese Option reduziert das Intervall auf einmal im Monat. Wenn Sie beispielsweise Montags für die Installation von Updates ausgewählt haben, wird die Installation nur am 1. Montag des Monats ausgeführt.",
    "<strong>Automatic updates:</strong><br>The UTM is restarted during the update process. All connections to the UTM (e.g. VPN, SSH) are interrupted.<br><strong>Period:</strong><br>The time is given in UTC. UTC does not use daylight saving time! The update is triggered by the portal. For better load distribution, only one period can be selected within which the process is triggered.<br><strong>Once a month:</strong><br>This option reduces the interval to once a month. For example, if you selected Mondays for installing updates, the installation will be performed only on the 1st Monday of the month.":
        "<strong>Automatische Updates:</strong><br>Die UTM wird während des Update-Vorgangs neu gestartet. Alle Verbindungen zur UTM (z. B. VPN, SSH) werden unterbrochen.<br><strong>Regelmäßigkeit:</strong><br>Die Uhrzeit wird in UTC angegeben. UTC verwendet keine Sommerzeit! Das Update wird vom Portal angestoßen. Zur besseren Lastverteilung kann nur ein Zeitraum ausgewählt werden, innerhalb dessen der Prozess ausgelöst wird.<br><strong>Einmal im Monat:</strong><br>Diese Option reduziert das Intervall auf einmal im Monat. Wenn Sie beispielsweise Montags für die Installation von Updates ausgewählt haben, wird die Installation nur am 1. Montag des Monats ausgeführt.",
    "<strong>Automatic updates:</strong><br>Changes to the automatic update due to extensions to the UTM profiles: From UTM version 12.6.2, the settings for automatic updates on the UTM are configured in the profiles. For these UTM versions, it is possible to specify that an additional endpoint must be accessible, before the new UTM version is finalised after the test run. For UTMs with an older version, the update is triggered via the Unified Security Console. With this type of update, no additional test endpoint can be specified for finalisation. The UTM is finalised automatically after the test run and the establishment of the cloud connection. The UTM is restarted during the update process. All connections to the UTM (e.g. VPN, SSH) are interrupted.<br><strong>Period:</strong><br>The time is given in UTC. UTC does not use daylight saving time! The update is triggered by the portal. For better load distribution, only one period can be selected within which the process is triggered.<br><strong>Once a month:</strong><br>This option reduces the interval to once a month. For example, if you selected Mondays for installing updates, the installation will be performed only on the 1st Monday of the month.":
        "<strong>Automatische Updates:</strong><br>Änderungen des automatischen Updates aufgrund von Erweiterungen der UTM-Profile: Ab UTM- Version 12.6.2 wird durch die Profile die Einstellung für automatische Updates auf der UTM konfiguriert. Für diese UTM - Versionen besteht die Möglichkeit einzustellen, dass ein zusätzlicher Endpunkt erreichbar sein muss, bevor die neue UTM - Version nach dem Probelauf finalisiert wird. Für UTMs mit einer älteren Version wird das Update über die Unified Security Console angestoßen. Bei dieser Art der Aktualisierung kann kein zusätzlicher Prüfungsendpunkt für die Finalisierung angegeben werden. Die UTM wird nach dem Probelauf und dem Aufbau der Cloud - Verbindung automatisch finalisiert. Die UTM wird während des Update-Vorgangs neu gestartet. Alle Verbindungen zur UTM (z. B. VPN, SSH) werden unterbrochen.<br><strong>Regelmäßigkeit:</strong><br>Die Uhrzeit wird in UTC angegeben. UTC verwendet keine Sommerzeit! Das Update wird vom Portal angestoßen. Zur besseren Lastverteilung kann nur ein Zeitraum ausgewählt werden, innerhalb dessen der Prozess ausgelöst wird.<br><strong>Einmal im Monat:</strong><br>Diese Option reduziert das Intervall auf einmal im Monat. Wenn Sie beispielsweise Montags für die Installation von Updates ausgewählt haben, wird die Installation nur am 1. Montag des Monats ausgeführt.",
    "Invalid scopes. Needed some of:":
        "Ungültige Berechtigungen. Sie benötigen eine der folgenden:",
    "Tags may not contain spaces or umlauts.":
        "Tags dürfen keine Leerzeichen oder Umlaute enthalten.",
    "Add some text": "Fügen Sie Text hinzu",
    "Enter some text": "Geben Sie einen Text ein",
    "Enter a number": "Geben Sie eine Zahl ein",
    "Add an object": "Objekt hinzufügen",
    "Enter an Entra ID Tenant-ID or an Entra ID Domain":
        "Geben Sie eine Entra ID Tenant-ID oder eine Entra ID Domain ein",
    "Click here for infos about this firmware.":
        "Für mehr Informationen zur Firmware klicken Sie bitte hier.",
    "Used space": "Belegter Speicher",
    "Execution time": "Ausführzeit",
    Issued: "Angesetzt",
    "Show history": "Verlauf anzeigen",
    Finished: "Erledigt",
    Scheduled: "Geplant",
    "The requested object could not be found":
        "Das angeforderte Objekt konnte nicht gefunden werden",
    "The requested page could not be found": "Die angeforderte Seite konnte nicht gefunden werden",
    "Only once a month": "Nur einmal im Monat",
    "If true, disables MAC address randomization for a Wi-Fi network while associated with that network. This feature also shows a privacy warning in Settings indicating that the network has reduced privacy protections. This value is only locked when the profile is installed by MDM. If the profile is manually installed, the value is set but the user can change it.":
        "Wenn aktiv, wird die Randomisierung von MAC-Adressen für ein Wi-Fi-Netzwerk deaktiviert, während es mit diesem Netzwerk verknüpft ist. Diese Funktion zeigt auch eine Datenschutzwarnung in den Einstellungen an, die darauf hinweist, dass das Netzwerk einen eingeschränkten Datenschutz hat. Dieser Wert wird nur gesperrt, wenn das Profil von MDM installiert wird . Wenn das Profil manuell installiert wird, ist der Wert festgelegt, aber der Benutzer kann ihn ändern.",
    "Disable MAC randomization": "Deaktiviere MAC Randomisierung",
    "Link with Managed Apple ID": "Mit verwalteter Apple-ID verknüpfen",
    "Link this user to an Managed Apple ID":
        "Diesen Benutzer mit einer verwalteten Apple-ID verknüpfen",
    "Managed Apple ID": "Verwaltete Apple-ID",
    "Enter a Managed Apple ID that was already created in the Apple Business Manager":
        "Geben Sie eine verwaltete Apple-ID ein, die bereits im Apple Business Manager erstellt wurde",
    "Choose a Managed Apple ID": "Wählen Sie eine verwaltete Apple-ID aus",
    "This value doesn’t work with the User Enrollment feature introduced in iOS 13.":
        "Dieser Wert funktioniert nicht mit der in iOS 13 eingeführten Benutzerregistrierungsfunktion.",
    "Tenant notification": "Tenant Benachrichtigung",
    "User Enrollment Profile": "Benutzerregistrierungs-Profil",
    "Last Archival Timestamp": "Letzter Archivierungszeitstempel",
    "1. Scan this QR-Code and go through the process to link your Apple ID to the managed Apple ID":
        "1. Scannen Sie den QR-Code und durchlaufen Sie den Prozess um Ihre Apple-ID mit der verwalteten Apple-ID zu verknüpfen",
    "2. Scan this device enrollment QR-Code": "2. Scannen Sie diesen Geräteregistrierungs QR-Code",
    Registered: "Registriert",
    Associated: "Zugeordnet",
    Retired: "Ruhestand",
    Deleted: "Gelöscht",
    "Copy to clipboard": "In Zwischenablage kopieren",
    "Download Profile": "Profil herunterladen",
    "Your Apple VPP Token has expired": "Ihr Apple VPP Token ist abgelaufen",
    "Please visit the Apple Volume Purchase Program in the":
        "Bitte besuchen Sie das Apple Volume Purchase Program in dem",
    "You can find more detailed instructions on how to do this here:":
        "Eine genauere Anleitung dafür finden Sie hier:",
    "Delete in Progress": "Löschvorgang läuft",
    "This process usually takes up to 24 hours": "Dieser Vorgang dauert in der Regel bis zu 24h",
    "Update strongly recommended!": "Update dringend empfohlen!",
    "UTM versions were detected for which a security update is available. Please install them immediately!":
        "Es wurden UTM-Versionen erkannt, für die ein Sicherheits-Update vorliegt. Bitte installieren Sie dieses umgehend!",
    "The following accounts are affected:": "Folgende Accounts sind Betroffen:",
    "More information can be found": "Weitere Informationen finden Sie",
    here: "hier",
    "The name is already taken": "Der Name ist bereits vergeben",
    "Use EAP Client": "EAP-Client verwenden",
    "EAP Types": "EAP-Typen",
    "Select EAP Types": "EAP-Typen auswählen",
    "Provision PAC": "PAC bereitstellen",
    "Provision anonymously": "Anonym bereitstellen",
    "Use existing PAC": "Vorhandene PAC verwenden",
    "EAP SIM Number Of RANDs": "EAP SIM-Anzahl der RANDs",
    "One time password": "Einmaliges Passwort",
    "If activated, the user will be prompted to enter the password each time they connect":
        "Wenn aktiviert, wird der Benutzer bei jeder Verbindung aufgefordert, das Passwort einzugeben.",
    "One time user password": "Einmaliges Benutzerpasswort",
    "Outer Identity": "Äußere Identität",
    "A name that hides the user's true name.":
        "Ein Name, der den wahren Namen des Benutzers verbirgt.",
    "Payload Certificate Anchor UUID": "Nutzlast-Zertifikat-Anker-UUID",
    "An array of the UUID of a certificate payload to trust for authentication.":
        "Ein Array mit der UUID einer Zertifikatsnutzlast, der für die Authentifizierung vertraut werden soll.",
    "System Mode Credentials Source": "Quelle für Systemmodus-Anmeldeinformationen",
    "Use Open Directory credentials": "Open Directory-Anmeldeinformationen verwenden",
    "Allow two-factor authentication": "Zwei-Faktor-Authentifizierung zulassen",
    "Max. TLS Version": "Max. TLS Version",
    "Min. TLS Version": "Min. TLS-Version",
    "Trusted certificates": "Vertrauenswürdige Zertifikate",
    "Trusted server names": "Vertrauenswürdige Servernamen",
    "TTLS Inner Authentication": "TTLS Innere Authentifizierung",
    "Apple VPP could not be intialized": "Apple VPP konnte nicht initialisiert werden",
    "It seems your Apple VPP token is assigned to another MDM":
        "Es scheint als wäre Ihr Apple VPP Token einem anderen MDM zugeordnet",
    "We recommend assigning the token explicitly to Securepoint Unified Security or replacing the token with a new one.":
        "Wir empfehlen, den Token explizit Securepoint Unified Security zuzuweisen oder den Token durch einen neuen Token zu ersetzen.",
    "Reassign token now": "Token jetzt neu zuweisen",
    "A security update has been released for older UTM versions. Installation of this update is strongly recommended. Do you want to check now which UTMs are affected? This process may take some time.":
        "Für ältere UTM-Versionen wurde ein Sicherheitsupdate veröffentlicht. Die Installation dieses Updates wird dringend empfohlen. Möchten Sie jetzt überprüfen, welche UTMs betroffen sind? Dieser Vorgang kann einige Zeit in Anspruch nehmen.",
    "Check for Updates": "Auf Updates prüfen",
    "Generated from template": "Aus Vorlage generiert",
    "Select tenants": "Mandanten auswählen",
    "Error Deleting DEP Devices": "Fehler beim Löschen von DEP-Geräten",
    "Some of the selected devices are DEP devices. To remove DEP enrollment please visit <a href='https://business.apple.com/' target='_blank'>Apple</a>.<br>The following devices are affected:":
        "Einige der ausgewählten Geräte sind DEP-Geräte. Um das DEP-Enrollment zu entfernen, besuchen Sie bitte <a href='https://business.apple.com/' target='_blank'>Apple</a>.<br>Die folgenden Geräte sind betroffen:",
    "Select tenant": "Mandanten auswählen",
    "Cross-tenant profile": "Mandantenübergreifendes Profil",
    "Generated from ross-tenant profile": "Generiert aus Mandantenübergreifendem Profil",
    "Use this profile as a cross-tenant profile in multiple accounts.":
        "Verwenden Sie dieses Profil als mandantenübergreifendes Profil in mehreren Konten.",
    "This profile is created and applied in the own tenant and also in the tenant specified here.":
        "Dieses Profil wird sowohl im eigenen Mandanten als auch in dem hier angegebenen Mandanten angelegt und angewendet.",
    "Do you really want to delete this profile?": "Möchten Sie dieses Profil wirklich löschen?",
    "This will also delete the automatic generated profiles for all affected tenants.":
        "Dies wird ebenso alle automatisch generierten Profile in untergeordneten Mandanten löschen.",
    "Select all": "Alle auswählen",
    "Deselect all": "Alle abwählen",
    "One-time update": "Einmaliges Update",
    "Activate to set a date for a non-repeating one-time update":
        "Aktivieren, um ein Datum für ein nicht wiederkehrendes einmaliges Update festzulegen",
    "Invalid argument.": "Ungültiges Argument.",
    "Unable to find eventId.": "EventId kann nicht gefunden werden.",
    "This request contains an invalid authorization header. Change the request to send an authorization header that matches the format ‘Bearer {sToken}’.":
        "Diese Anforderung enthält einen ungültigen Autorisierungs-Header. Ändern Sie die Anforderung, um einen Autorisierungs-Header zu senden, der dem Format ‘Bearer { sToken }’ entspricht.",
    "Unable to find registered user.": "Registrierter Benutzer kann nicht gefunden werden.",
    "There aren't enough assets available to complete this association.":
        "Es sind nicht genügend Assets verfügbar, um diese Zuordnung abzuschließen.",
    "A registered user already exists with the specified clientUserId.":
        "Es existiert bereits ein registrierter Benutzer mit der angegebenen clientUserId.",
    "This UTM is excluded from automatic updates due to the working memory limit":
        "Diese UTM ist aufgrund des Arbeitsspeicherlimits von automatischen Updates ausgenommen",
    Attention: "Achtung",
    "Attention: This UTM has less than 3GB of memory. If a update is performed, this UTM will no longer have a virus scanner.":
        "Achtung: Diese UTM hat weniger als 3GB Arbeitsspeicher. Wenn ein Update durchgeführt wird, dann hat diese UTM keinen Virenscanner mehr.",
    "Timeout: The device did not respond": "Zeitüberschreitung: Das Gerät hat nicht geantwortet",
    "Last update was on": "Letztes Update war am",
    "Trailing spaces will be removed": "Nachstehende Leerzeichen werden entfernt",
    "There is a lack of agreement to the terms of use for Android Zero-Touch.":
        "Es fehlt Ihre Zustimmung zu den Nutzungsbedingungen für Android Zero-Touch.",
    "Please visit $1 in order to agree to the terms of use.":
        "Bitte besuchen Sie $1, um den Nutzungsbedingungen zuzustimmen.",
    "Terms of Service not accepted": "Nutzungsbedingungen nicht akzeptiert",
    "Apple DEP-Token expired": "Apple DEP-Token abgelaufen",
    "Go to Mobile Security settings in order to configure Apple-DEP.":
        "Gehen Sie zu den Mobile Security Einstellungen, um Apple-DEP zu konfigurieren.",
    Configure: "Konfigurieren",
    "Apple VPP-Token expired": "Apple VPP-Token abgelaufen",
    "Go to Mobile Security settings in order to configure Apple-VPP.":
        "Gehen Sie zu den Mobile Security Einstellungen, um Apple-VPP zu konfigurieren.",
    "After setting this, you can set the shared device configuration.":
        "Nach dieser Einstellung können Sie die gemeinsame Gerätekonfiguration festlegen.",
    "Managed Apple ID default domains": "Verwaltete Apple-ID-Standarddomänen",
    "A list of domains that the Shared iPad login screen displays. The user can pick a domain from the list to complete their Managed Apple ID.":
        "Eine Liste von Domänen, die auf dem Anmeldebildschirm des Shared iPad angezeigt wird. Der Benutzer kann eine Domäne aus der Liste auswählen, um seine Managed Apple ID zu vervollständigen.",
    "Online authentication grace period": "Karenzzeit für die Online-Authentifizierung",
    "A grace period (in days) for Shared iPad online authentication. The Shared iPad only verifies the user’s passcode locally during login for users that already exist on the device. However, the system requires an online authentication (against Apple’s identity server) after the number of days specified by this setting.":
        "Eine Karenzzeit (in Tagen) für die Online-Authentifizierung des Shared iPad. Das Shared iPad prüft den Passcode des Benutzers bei der Anmeldung nur lokal für Benutzer, die bereits auf dem Gerät vorhanden sind. Das System erfordert jedoch eine Online-Authentifizierung (gegenüber dem Identitätsserver von Apple) nach der in dieser Einstellung angegebenen Anzahl von Tagen.",
    "Quota size": "Quotengröße",
    "The quota size, in megabytes (MB), for each user on the shared device, or if the quota size is too small, the minimum quota size.":
        "Die Quotengröße in Megabyte (MB) für jeden Benutzer auf dem freigegebenen Gerät oder, wenn die Quotengröße zu klein ist, die Mindestquotengröße.",
    "Resident users": "Residente Benutzer",
    "The expected number of users. If this value is greater than the value for the maximum possible number of users that the device supports, the MDM server uses that value instead.":
        "Die erwartete Anzahl der Benutzer. Wenn dieser Wert größer ist als der Wert für die maximal mögliche Anzahl von Benutzern, die das Gerät unterstützt, verwendet der MDM-Server stattdessen diesen Wert.",
    "Skip language setup": "Spracheinstellung überspringen",
    "If active, the system picks the system language and locale automatically for the new Shared iPad user.":
        "Wenn diese Option aktiviert ist, wählt das System automatisch die Systemsprache und das Gebietsschema für den neuen Shared iPad-Benutzer aus.",
    "Temporary session only": "Nur temporäre Sitzung",
    "If active, the user only sees the Guest Welcome pane and can only log in as a guest user.":
        "Wenn diese Option aktiviert ist, sieht der Benutzer nur den Begrüßungsbildschirm für Gäste und kann sich nur als Gastbenutzer anmelden.",
    "Temporary session timeout": "Zeitlimit für temporäre Sitzung",
    "The timeout, in seconds, for the temporary session. The temporary session logs out automatically after the specified period of inactivity.":
        "Die Zeitüberschreitung in Sekunden für die temporäre Sitzung. Die temporäre Sitzung wird nach dem angegebenen Zeitraum der Inaktivität automatisch abgemeldet.",
    "User session timeout": "Zeitüberschreitung der Benutzersitzung",
    "The timeout, in seconds, for the user session. The user session logs out automatically after the specified period of inactivity.":
        "Die Zeitüberschreitung in Sekunden für die Benutzersitzung. Die Benutzersitzung wird nach dem angegebenen Zeitraum der Inaktivität automatisch abgemeldet.",
    Monitoring: "Überwachung",
    "Monitoring access": "Zugriff auf Überwachungsfunktionen",
    "Full administration": "Voller Administrationszugriff",
    "Not found": "Nicht gefunden",
    "Unlink Account": "Verknüpfung aufheben",
    "Common Name": "Allgemeiner Name",
    Compatibility: "Kompatibilität",
    "Age Rating": "Altersempfehlung",
    Size: "Größe",
    Seller: "Verkäufer",
    "Creating enrollment token...": "Erstelle Registrierungstoken...",
    "Reset filter": "Filter zurücksetzen",
    "Only sent messages": "Nur gesendete Nachrichten",
    "Only incoming messages": "Nur eingehende Nachrichten",
    Scopes: "Geltungsbereiche",
    Scope: "Geltungsbereich",
    "Expiry Date": "Ablaufdatum",
    "Expiry date": "Ablaufdatum",
    Expiry: "Ablauf",
    "Select scopes": "Geltungsbereiche auswählen",
    "Do you really want to delete this api key?": "Wollen Sie diesen API Key wirklich löschen?",
    "Add API Key": "API Key hinzufügen",
    "Edit API Key": "API Key bearbeiten",
    "Shared Device configuration": "Shared Device Konfiguration",
    "The profile will be assigned to this Clients.": "Das Profil wird diesen Clients zugeordnet.",
    "The profile will be assigned to this UTMs. <strong>Attention:</strong> If UTMs are selected that have less than 3GB of memory, then these are excluded from automatic updates":
        "Das Profil wird diesen UTMs zugeordnet. <strong>Achtung:</strong> Wenn UTMs ausgewählt werden, die weniger als 3GB Speicher haben, dann werden diese von automatischen Updates ausgeschlossen.",
    "<strong>Automatic updates:</strong><br>The UTM is restarted during the update process. All connections to the UTM (e.g. VPN, SSH) are interrupted.<br><strong>One-time Update:</strong></br>The time is given in UTC. UTC does not use daylight saving time! The update is triggered by the portal.":
        "<strong>Automatische Aktualisierungen:</strong><br>Die UTM wird während des Update-Vorgangs neu gestartet. Alle Verbindungen zur UTM (z.B. VPN, SSH) werden unterbrochen.<br><strong>Einmaliges Update:</strong></br>Die Zeit wird in UTC angegeben. UTC verwendet keine Sommerzeit! Das Update wird durch das Portal ausgelöst.",
    "<strong>Automatic updates:</strong><br>The UTM is restarted during the update process. All connections to the UTM (e.g. VPN, SSH) are interrupted.<br><strong>One-time Update:</strong><br>The time is given in UTC. UTC does not use daylight saving time! The update is triggered by the portal.":
        "<strong>Automatische Aktualisierungen:</strong><br>Die UTM wird während des Update-Vorgangs neu gestartet. Alle Verbindungen zur UTM (z.B. VPN, SSH) werden unterbrochen.<br><strong>Einmaliges Update:</strong><br>Die Zeit wird in UTC angegeben. UTC verwendet keine Sommerzeit! Das Update wird durch das Portal ausgelöst.",
    "not linked": "Nicht verknüpft",
    "Link to Identity Provider": "Verbindung zum Identitätsanbieter",
    "Link to Microsoft Entra ID": "Verbindung zu Microsoft Entra ID",
    "Journal Addresses": "Journal-Adressen",
    "Archive Rules": "Archivregeln",
    "Add journal address": "Journal-Adresse hinzufügen",
    "Are you sure to add a new journal address?":
        "Sind Sie sicher, dass Sie eine neue Journal-Adresse hinzufügen wollen?",
    "Delete Journal Address": "Journal-Adresse löschen",
    "Are you sure to delete this journal address?":
        "Sind Sie sicher, dass Sie diese Journal-Adresse löschen wollen?",
    "Delete address": "Adresse löschen",
    Sources: "Quellen",
    Recipient: "Empfänger",
    Rule: "Regel",
    January: "Januar",
    Febuary: "Februar",
    March: "März",
    April: "April",
    May: "Mai",
    June: "Juni",
    July: "Juli",
    August: "August",
    September: "September",
    October: "Oktober",
    November: "November",
    December: "Dezember",
    Is: "Ist",
    Contains: "Beinhaltet",
    Matches: "Stimmt überein",
    "Open inventory": "Inventar öffnen",
    queued: "in der Warteschlange",
    "needs redemption": "Bedarf Einlösung",
    redeeming: "Einlösend",
    prompting: "Aufforderung",
    "prompting for login": "Aufforderung zur Anmeldung",
    "validating purchase": "Bestätige Kauf",
    "prompting for update": "Aufforderung zur Aktualisierung",
    "prompting for update login": "Aufforderung zum Update-Login",
    "prompting for management": "Aufforderung an das Management",
    "validating update": "Validiere Aktualisierung",
    updating: "Aktualisierend",
    installing: "Installierend",
    managed: "Verwaltet",
    "managed but uninstalled": "Verwaltet aber nicht installiert",
    unknown: "Unbekannt",
    "user installed app": "vom Benutzer installierte Anwendung",
    "user rejected": "Von Benutzer abgelehnt",
    "update rejected": "Update abgelehnt",
    "management rejected": "Verwaltung abgelehnt",
    failed: "Fehlgeschlagen",
    Failed: "Fehlgeschlagen",
    "manually removed": "Manuell entfernt",
    "Model family": "Modell-Familie",
    "Subscribe to Model family": "Abonnieren Sie die Modellfamilie",
    "Model identifier": "Modell-Bezeichner",
    "Subscribe to Model identifier": "Abonnieren Sie den Modellbezeichner",
    "Model name": "Modellname",
    "Subscribe to Model name": "Abonnieren Sie den Modellnamen",
    "OS build version": "OS-Build-Version",
    "Subscribe to OS build version": "Abonnieren der OS-Build-Version",
    "OS family": "OS-Familie",
    "Subscribe to OS family": "OS-Familie abonnieren",
    "OS version": "OS-Version",
    "Subscribe to OS version": "Abonnieren Sie die OS-Version",
    "OS name": "OS-Name",
    "Subscribe to OS name": "Abonnieren Sie den OS-Namen",
    "OS supplemental build version": "OS ergänzende Build-Version",
    "Subscribe to OS supplemental build version": "Abonnieren Sie die ergänzende OS-Build-Version",
    "OS supplemental extra version": "Ergänzende OS-Extraversion",
    "Subscribe to OS supplemental extra version": "Abonnieren Sie die zusätzliche OS-Extraversion",
    "Passcode compliance": "Passcode-Einhaltung",
    "Subscribe to Passcode compliance": "Abonnieren Sie Passcode-Einhaltung",
    "Passcode presence": "Passcode-Präsenz",
    "Subscribe to Passcode presence": "Abonnieren Sie Passcode-Präsenz",
    "MDM app": "MDM-Anwendung",
    "Product name": "Produktname",
    "Subscribe to MDM app": "Abonnieren Sie die MDM-App",
    "Declarative management": "Deklarative Verwaltung",
    "Declarative Management": "Deklarative Verwaltung",
    DeclarativeManagement: "Deklarative Verwaltung",
    "Declarative managed": "Deklarativ verwaltet",
    "The apple servers have reported following error:":
        "Die Apple Server haben folgenden Fehler zurückgeliefert:",
    "Automatic VPP license assignment": "Automatische VPP Lizenzzuweisung",
    "Choose wether the VPP app license should be automatically assigned to the device or not.":
        "Wählen Sie aus, ob die VPP-App-Lizenz automatisch dem Gerät zugewiesen werden soll oder nicht.",
    "A profile with this name already exists. Saving this profile would overwrite the existing one.":
        "Ein Profil mit diesem Namen existiert bereits. Speichern dieses Profils würde das bestehende überschreiben.",
    Productselection: "Produktauswahl",
    "Inventory list": "Inventarliste",
    "You must add at least one tag to be able to assign the profile to UTMs":
        "Sie müssen mindestens ein Tag hinzufügen, um das Profil den UTMs zuordnen zu können",
    "After setting this, you can set the Declarative Management configuration.":
        "Nach der Aktivierung können Sie die Konfiguration der deklarativen Verwaltung festlegen.",
    "Passcode set": "Passcode gesetzt",
    "Non-compliant": "Nicht konform",
    "Restrictions and features for managed apps":
        "Einschränkungen und Funktionen für verwaltete Apps",
    "This UTM requires a PIN for authentication.":
        "Diese UTM benötigt eine PIN zur Authentifizierung.",
    "This UTM does not require a PIN": "Diese UTM benötigt keine PIN",
    "Invalid websession pin": "Ungültiger Websession PIN",
    "Invalid wession pin": "Ungültiger Websession PIN",
    "Websession is locked": "Websession ist gesperrt",
    "Wesession is locked": "Websession ist gesperrt",
    "The USC websession has been blocked on this UTM due to multiple entries of an incorrect PIN. To unlock the UTM for the USC websession again, please log in to the administrator interface.":
        "Die USC-Websession wurde auf diese UTM aufgrund der mehrfachen Eingabe eines fehlerhaften PIN gesperrt. Um die UTM wieder für die USC- Websession freizugeben, melden Sie sich bitte im Administrator - Interface an.",
    "Select a update type:": "Wählen sie eine Update-Art",
    "Download or install the update, depending on the current state.":
        "Download oder Installation des Updates, je nach aktuellem Stand.",
    "Download only": "Nur herunterladen",
    "Download the software update without installing it.":
        "Das Software-Update herunterladen, ohne es zu installieren.",
    "Install ASAP": "So schnell wie möglich installieren",
    "In iOS and tvOS, install a previously downloaded software update.":
        "In iOS und tvOS ein zuvor heruntergeladenes Software-Update installieren.",
    "The selected .CSV file does not contain any users":
        "Die ausgewählte .CSV-Datei enthält keine Benutzer",
    "No backups found": "Keine Backups gefunden",
    booked: "gebucht",
    "The PIN you have entered is incorrect": "Die eingegebene PIN ist falsch",
    "Enter at least 3 characters in order to Search":
        "Geben Sie mindestens 3 Zeichen ein, um zu suchen",
    "Enter text to add Tags": "Text eingeben, um Tags hinzuzufügen",
    "Valid until:": "Gültig bis:",
    "Entra ID is a cloud-based identity and access management service that provides organizations with unified identity management and enhanced security. With Entra ID, users can log in to multiple applications and services with a single sign-on, while advanced security features such as multi-factor authentication and threat intelligence enhance the protection of corporate data.":
        "Entra ID ist ein Cloud-basierter Identitäts- und Zugriffsverwaltungsdienst, der Unternehmen eine einheitliche Identitätsverwaltung und verbesserte Sicherheit bietet. Mit Entra ID können sich Benutzer mit einer einzigen Anmeldung bei mehreren Anwendungen und Diensten anmelden, während fortschrittliche Sicherheitsfunktionen wie Multi-Faktor-Authentifizierung und Threat Intelligence den Schutz von Unternehmensdaten verbessern.",
    "Do you really want to unlink your Entra ID account?":
        "Möchten Sie die Verknüpfung zu Ihrem Entra ID-Konto wirklich aufheben?",
    "You successfully added your Entra ID account":
        "Sie haben Ihr Entra ID-Konto erfolgreich hinzugefügt",
    "An error occured while trying to add your Entra ID account":
        "Beim Versuch, Ihr Entra ID-Konto hinzuzufügen, ist ein Fehler aufgetreten",
    "Please try again later": "Bitte versuchen Sie es später noch einmal",
    "No Devices": "Keine Geräte",
    "No License": "Keine Lizenz",
    "Firmware distribution": "Firmwareverteilung",
    "Disable cloud-backup": "Cloud-Backup deaktivieren",
    "Enable cloud-backup": "Cloud-Backup aktivieren",
    "Are you sure to disable the cloud-backup functionality?":
        "Sind Sie sicher dass Sie die Cloud-Backup Funktionalität deaktivieren möchten?",
    "Are you sure to enable the cloud-backup functionality?":
        "Sind Sie sicher dass Sie die Cloud-Backup Funktionalität aktivieren möchten?",
    "Select tags": "Tags auswählen",
    "Invalid Webession pin": "Ungültiger Websession PIN",
    "Websession was locked": "Websession wurde gesperrt",
    "Wession is locked": "Websession wurde gesperrt",
    "An invalid Websession pin was entered on $1":
        "Bei $1 wurde ein ungültiger Websession PIN eingegeben.",
    "An invalid websession pin entered on $1":
        "Bei $1 wurde ein ungültiger Websession PIN eingegeben.",
    "The Websession functionality was locked due to too many incorrect pin entries. $1":
        "Die Websession-Funktionalitat wurde wegen zu vieler falscher PIN-Eingaben gesperrt. $1",
    "The PIN is used for authentication at the UTM via the Websession function. To increase security, the new authentication layer is mandatory from UTM version 12.5.0. The initial PIN is 000000 and must be changed after the first login.":
        "Die PIN dient zur Authentifizierung an der UTM über die Websession-Funktion. Um die Sicherheit zu erhöhen, ist die neue Authentifizierungsschicht ab der UTM Version 12.5.0 obligatorisch. Die initiale PIN ist 000000 und muss nach der ersten Anmeldung geändert werden.",
    Portion: "Anteil",
    "License expiry": "Lizenz-Ablaufdatum",
    "Next update on": "Nächstes Update am",
    "The next update time is unknown": "Die nächste Update-Zeit ist unbekannt",
    "Missing license": "Lizenz fehlt",
    "Text copied": "Text kopiert",
    "An update could be finalized": "Ein Update könnte finalisiert werden",
    "A rollback could be finalized": "Ein Rollback könnte finalisiert werden",
    "An update can be finalized": "Ein Update kann finalisiert werden",
    "A rollback can be finalized": "Ein Rollback kann finalisiert werden",
    "A scan has been finished": "Ein Scan wurde abgeschlossen",
    "Invalid Websession pin": "Ungültiger Websession PIN",
    "Invalid wesession pin": "Ungültiger Websession PIN",
    "A new UTM is now available": "Eine neue UTM ist verfügbar",
    "An update has been finalized": "Ein Update wurde finalisiert",
    "Copy schema": "Schema kopieren",
    "The users have been added successfully": "Die Benutzer wurden erfolgreich hinzugefügt",
    "Import successfull": "Import erfolgreich",
    "The following users existed multiple times and were not imported:":
        "Die folgenden Benutzer gab es mehrmals und wurden nicht importiert:",
    "Successfully added user %s": "Benutzer %s erfolgreich importiert",
    "The following roles could not be imported:":
        "Die folgenden Rollen konnten nicht importiert werden:",
    Copied: "Kopiert",
    "Please import your .CSV with following schema:":
        "Bitte importieren Sie Ihre .csv mit folgendem Schema:",
    "Failed OS Updates": "Fehlgeschlagene OS Updates",
    "The updates have been initiated. The following devices could not be updated, because there are no available updates:":
        "Die Updates wurden eingeleitet. Die folgenden Geräte konnten nicht aktualisiert werden, da es keine Updates gab:",
    "Initiated OS Updates": "OS Updates initiiert",
    "The Updates have been initiated successfully": "Die Updates wurden erfolgreich initiiert",
    "Since the websession PIN is deactivated, automatic login is not possible. You need to log in using your username and password.":
        "Da die Websession PIN deaktiviert ist, kann keine automatische Anmeldung erfolgen. Sie müssen sich mit Ihrem Benutzernamen und Passwort anmelden.",
    "The UTM initiates a connection to our cloud services and can be administered via these without a public IP address. Details can be found in our":
        "Die UTM initiiert eine Verbindung zu unseren Cloud-Diensten und kann über diese ohne öffentliche IP-Adresse administriert werden. Details finden Sie in unserem",
    "Since your last login, there have been portal updates.":
        "Seit Ihrem letzten Login gab es Portal-Updates.",
    wiki: "Wiki",
    "for more information.": "für weitere Informationen.",
    "Current Version": "Aktuelle Version",
    "Serial number": "Seriennummer",
    "License serial number": "Lizenz-Seriennummer",
    "Controls the current status of flight mode and indicates whether the user can turn it on or off. Supported on Android 9 and above.":
        "Steuert den aktuellen Status des Flugmodus und gibt an, ob der Nutzer ihn ein- oder ausschalten kann. Wird unter Android 9 und höher unterstützt.",
    "The user can enable or disable the flight mode.":
        "Der Nutzer kann den Flugmodus aktivieren oder deaktivieren.",
    "The flight mode is deactivated. The user is not allowed to activate the flight mode.":
        "Der Flugmodus ist deaktiviert. Der Nutzer darf den Flugmodus nicht aktivieren.",
    "Disable airplane mode": "Deaktivieren Sie den Flugzeugmodus",
    "Please select the user with whom the websession should be started.":
        "Bitte wählen Sie den Benutzer aus mit dem die Websession gestartet werden soll.",
    monday: "Montag",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    thursday: "Donnerstag",
    friday: "Freitag",
    saturday: "Samstag",
    sunday: "Sonntag",
    today: "heute",
    at1: "um1",
    "The next update is scheduled %s %y %z o'clock":
        "Das nächste Update ist am %s %y %z Uhr geplant",
    "The next update is scheduled next month, the first %s %y %z o'clock":
        "Das nächste Update ist nächsten Monat, am ersten %s %y %z Uhr geplant",
    between: "zwischen",
    "License SN": "Lizenz SN",
    "UTM update required!": "UTM-Update erforderlich!",
    "As of version 12.5.1, the UTM initiates a connection to our cloud services and can be administered via these without a public IP address.":
        "Ab Version 12.5.1 initiiert die UTM eine Verbindung zu unseren Cloud-Diensten und kann über diese ohne öffentliche IP-Adresse administriert werden.",
    "A former procedure for UTMs with version 12.2.2.8 or older will therefore be switched off from 30.11.2023 and the UTMs will no longer be accessible via websession.":
        "Ein älteres Verfahren für UTMs mit der Version 12.2.2.8 oder älter wird daher ab dem 30.11.2023 abgeschaltet und die UTMs werden nicht mehr per Websession erreichbar sein.",
    "Please perform the required update as soon as possible. The following tenants are affected:":
        "Bitte führen Sie die erforderliche Aktualisierung so bald wie möglich durch. Folgende Mandanten sind betroffen:",
    "Getting UTM settings...": "Hole UTM Einstellungen...",
    Productname: "Produktname",
    "Unspecified. Defaults to enforced.": "Nicht spezifiziert. Der Standardwert ist erzwungen.",
    "For devices with work profiles, allow untrusted app installs in the device's personal profile only.":
        "Für Geräte mit Arbeitsprofilen. Erlaubt nicht vertrauenswürdigen Apps Installationen ausschließlich im persönlichen Profil.",
    "User choice": "Wahl des Benutzers",
    "Scopes can be edited in roles by the administrator.":
        "Berechtigungen können vom Administrator in den Rollen bearbeitet werden.",
    "To increase security for actions that could disrupt ongoing operations, the new authentication layer is mandatory from UTM version 12.5.2.":
        "Um die Sicherheit für Aktionen zu erhöhen, die den laufenden Betrieb stören könnten, ist die neue Authentifizierungsschicht ab UTM Version 12.5.2 obligatorisch.",
    "The PIN is identical to the websession PIN and is initially 000000 until the first login to the administration web interface.":
        "Die PIN ist identisch mit der Websession PIN und ist bis zur ersten Anmeldung am Administrations-Webinterface initial 000000.",
    "This action requires a PIN for authentication.":
        "Diese Aktion erfordert eine PIN für die Authentifizierung.",
    "The PIN authentication method is disabled for this UTM and the action is therefore not available.":
        "Die PIN-Authentifizierungsmethode ist für diese UTM deaktiviert und die Aktion daher nicht verfügbar.",
    "This UTM does not support a PIN for authentication.":
        "Diese UTM unterstützt keine PIN für die Authentifizierung.",
    "The UTM does not support this function, therefore the action is not available. Please update the UTM to a version 12.5.2 or higher if you want to use this action.":
        "Die UTM unterstützt diese Funktion nicht, daher ist die Aktion nicht verfügbar. Bitte aktualisieren Sie die UTM auf eine Version 12.5.2 oder höher, wenn Sie diese Aktion verwenden möchten.",
    "To increase security, the new authentication layer is mandatory from UTM version 12.5.2.":
        "Um die Sicherheit zu erhöhen, ist die neue Authentifizierungsschicht ab UTM-Version 12.5.2 obligatorisch.",
    "To increase security, the new authentication layer is mandatory from UTM version 12.7.0.":
        "Um die Sicherheit zu erhöhen, ist die neue Authentifizierungsschicht ab UTM-Version 12.7.0 obligatorisch.",
    "The PIN is used for authentication at the UTM via the Websession function. To increase security, the new authentication layer is mandatory from UTM version 12.5.0. The initial PIN is 000000 and must be changed after the first login. This UTM requires a PIN for authentication. If the PIN is entered incorrectly several times, login is only possible via credentials until the PIN functionality is reactivated.":
        "Die PIN wird zur Authentifizierung an der UTM über die Websession-Funktion verwendet. Um die Sicherheit zu erhöhen, ist der neue Authentifizierungs-Layer ab UTM-Version 12.5.0 obligatorisch. Die anfängliche PIN lautet 000000 und muss nach der ersten Anmeldung geändert werden. Diese UTM erfordert eine PIN zur Authentifizierung. Nach mehrmaliger Falscheingabe der PIN ist ein Login nur noch über Credentials möglich, bis die PIN-Funktionalität wieder freigeschaltet wird.",
    "Missing permission scopes": "Fehlende Berechtigungen",
    "The UTM was locked due to too many failed attempts":
        "Die UTM wurde auf Grund von zu vielen Fehlversuchen gesperrt",
    "Waiting for UTM Info...": "Warte auf UTM Informationen...",
    "Invalid pin": "Ungültiger Pin",
    Weather: "Wetter",
    Contacts: "Kontakte",
    Files: "Dateien",
    "Find my": "Wo ist?",
    Magnifier: "Lupe",
    "Apple Maps": "Karten",
    Measure: "Maßband",
    Messages: "Nachrichten",
    Notes: "Notizen",
    Pages: "Seiten",
    Photos: "Fotos",
    Reminders: "Erinnerungen",
    Stocks: "Aktien",
    Tips: "Tipps",
    Translate: "Übersetzen",
    Calculator: "Rechner",
    Books: "Bücher",
    Music: "Musik",
    Shortcuts: "Kurzbefehle",
    Submit: "Senden",
    "The backup could not be restored, because the file already exists on the UTM.":
        "Das Backup konnte nicht wiederhergestellt werden, da eine Datei mit dem Dateinamen schon auf der UTM existiert.",
    vpnnames: "VPN Namen",
    "Enter a name": "Geben Sie einen Namen ein",
    "Select at least one tenant": "Wählen Sie mindestens einen Mandanten aus",
    "Select at least one scope": "Wählen Sie mindestens einen Geltungsbereich aus",
    "Enter the Entra ID primary domain you want to link (e.g. ****.onmicrosoft.com).":
        "Geben Sie die primäre Entra ID-Domäne ein, die Sie verknüpfen möchten (z. B. ****.onmicrosoft.com).",
    "This feature is no longer available for this UTM. Please update your UTM.":
        "Dieses Feature ist für diese UTM nicht mehr verfügbar. Bitte aktualisieren Sie Ihre UTM.",
    "Link Entra ID": "Entra ID verknüpfen",
    "Contacts Synchronisation Deactivatable": "Kontakt Synchronisation deaktivierbar",
    "Calendars Synchronisation Deactivatable": "Kalender Synchronisation deaktivierbar",
    "Mail Synchronisation Deactivatable": "Mail Synchronisation deaktivierbar",
    "Notes Synchronisation Deactivatable": "Notizen Synchronisation deaktivierbar",
    "Reminders Synchronisation Deactivatable": "Erinnerungen Synchronisation deaktivierbar",
    "Defines whether the calendar synchronisation can be deactivated by the user.":
        "Legt fest, ob die Kalender Synchronisation vom Benutzer deaktiviert werden darf.",
    "Defines whether the contact synchronisation can be deactivated by the user.":
        "Legt fest, ob die Kontakt Synchronisation vom Benutzer deaktiviert werden darf.",
    "Defines whether the mail synchronisation can be deactivated by the user.":
        "Legt fest, ob die Mail Synchronisation vom Benutzer deaktiviert werden darf.",
    "Defines whether the notes synchronisation can be deactivated by the user.":
        "Legt fest, ob die Notizen Synchronisation vom Benutzer deaktiviert werden darf.",
    "Defines whether the reminders synchronisation can be deactivated by the user.":
        "Legt fest, ob die Erinnerungen Synchronisation vom Benutzer deaktiviert werden darf.",
    "Total Rejects": "Gesamte Ablehnungen",
    "Current connection status": "Aktueller Verbindungsstatus",
    "This message disappears in": "Diese Nachricht verschwindet in",
    "Wrong file format": "Falsches Dateiformat",
    "The certificate was successfully uploaded": "Das Zertifikat wurde erfolgreich hochgeladen",
    "No Cluster UTM could be found": "Es konnte keine Cluster UTM gefunden werden",
    "Cluster version": "Cluster-Version",
    "Synchronisation status": "Synchronisations-Status",
    "This UTM was never active. Therefore the following values can not be displayed":
        "Diese UTM war nie aktiv. Die folgenden Werte können daher nicht angezeigt werden",
    "Aggregated from cluster members": "Aggregiert aus Cluster-Mitgliedern",
    "Initializing...": "Initialisiere...",
    "Do you really want to delete this notification?":
        "Wollen Sie diese Benachrichtigung wirklich löschen?",
    "This cluster member has never been active. Therefore, not all data can be displayed.":
        "Dieses Cluster-Mitglied war noch nicht aktiv. Daher können nicht alle Daten angezeigt werden.",
    "Error on create utm websession certificate":
        "Fehler beim Erstellen des UTM-Websession-Zertifikats",
    "Can't connect to jobqueue": "Es kann keine Verbindung zur Jobwarteschlange hergestellt werden",
    "Go to other cluster UTM": "Zur anderen Cluster UTM gehen",
    "Error Switchting Cluster Memeber": "Fehler beim Wechseln des Cluster Mitglieds",
    "An Error occurred": "Ein Fehler ist aufgetreten",
    "No other cluster UTM for this cluster could be found":
        "Es konnte keine andere Cluster-UTM für diesen Cluster gefunden werden",
    "Accepted file formats:": "Akzeptierte Dateiformate:",
    "Enter at least 3 characters": "Geben Sie mindestens 3 Zeichen ein",
    "Enter a maximum of 120 characters": "Geben Sie maximal 120 Zeichen ein",
    "This name is already being used": "Der Name ist bereits vergeben",
    "No data found": "Keine Daten gefunden",
    "Rejected connections": "Abgelehnte Verbindungen",
    "Latest rejects": "Letzte Ausschüsse",
    "UTM-State": "UTM-Status",
    "Last Update": "Letztes Update",
    "The name may not contain spaces": "Der Name darf keine Leerzeichen enthalten",
    "The cluster member must be active for this information":
        "Für diese Information muss das Cluster-Mitglied aktiv sein",
    "The UTM has not yet transmitted any data to the Unified Security Console, so not all information may be displayed here.":
        "Die UTM hat bisher keine Daten an die Unified Security Console übermittelt, deshalb werden hier möglicherweise nicht alle Informationen angezeigt.",
    "Process response: Command failed. You can only create a backup every 24h.":
        "Antwort des Prozesses: Befehl fehlgeschlagen. Sie können nur alle 24 Stunden ein Backup erstellen.",
    "Please select the groups to be imported from the Entra ID":
        "Bitte wählen Sie die Gruppen aus, die aus dem Entra ID importiert werden sollen",
    "Attention:": "Achtung:",
    'Local group names can have 3 to 30 characters and cannot contain any special characters except "-" and "_".':
        'Lokale Gruppennamen können 3 bis 30 Zeichen haben und keine Sonderzeichen außer "-" und "_" behinhalten.',
    "All users of the selected Entra ID groups are imported with all other assigned groups. Previous group assignments remain in place.":
        "Es werden alle Benutzer der ausgewählten Entra ID-Gruppen mit allen weiteren zugeordneten Gruppen importiert. Bisherige Gruppenzordnungen bleiben bestehen.",
    "Previous group assignments remain in place.": "Bisherige Gruppenzordnungen bleiben bestehen.",
    "Defines wether this API key grants access to all tenants or not":
        "Legt fest, ob dieser API Key Zugriff auf alle Mandanten gewährt oder nicht",
    "The import could not be completed successfully. Please start the import again.":
        "Der Import konnte nicht erfolgreich abgeschlossen werden. Bitte starten Sie den Import erneut.",
    "The import was completed successfully": "Der Import konnte erfolgreich abgeschlossen werden",
    "The import of the Entra ID groups into the roles %s was successfully completed.":
        "Der Import der Entra ID Gruppen in die Rollen %s wurde erfolgreich abgeschlossen.",
    "Some of the selected groups could not be imported from Entra ID. Please try again in a few minutes.":
        "Einige der ausgewählten Gruppen konnten nicht aus Entra ID importiert werden. Bitte versuchen Sie es in ein paar Minuten erneut.",
    "Entra ID-Group": "Entra ID-Gruppe",
    "Local Group (Role)": "Lokale Gruppe (Rolle)",
    "The import of the Entra ID groups has been started. Please wait until the process is complete before editing the roles.":
        "Der Import der Entra ID Gruppen wurde gestartet. Bitte warten Sie mit dem Bearbeiten der Rollen, bis der Vorgang abeschlossen ist.",
    "Import Started": "Import gestartet",
    "Import Successful": "Import erfolgreich",
    "Import Failed": "Import fehlgeschlagen",
    "There are no groups which can be imported from Entra ID.":
        "Es gibt keine Gruppen, die aus Entra ID importiert werden können.",
    "There have been errors importing your Entra ID groups. The following errors occured:":
        "Beim Importieren Ihrer Entra ID Gruppen sind Fehler aufgetreten. Die folgenden Fehler sind aufgetreten:",
    "All users of the selected Entra ID group are imported. If you want to import an Entra ID group into several local groups, you can start the import again.Previous group assignments remain in place.":
        "Alle Benutzer der ausgewählten Entra ID Gruppe werden importiert. Wenn Sie eine Entra ID Gruppe in mehrere lokale Gruppen importieren möchten, können Sie den Import erneut starten, wobei die bisherigen Gruppenzuordnungen erhalten bleiben.",
    "The Username must not contain any whitespace characters and its length has to be between 3 and 45 characters.":
        "Der Benutzername darf keine Leerzeichen enthalten und muss zwischen 3 und 45 Zeichen lang sein.",
    "The Password must not contain any whitespace characters and its length has to be between 5 and 128 characters.":
        "Das Passwort darf keine Leerzeichen enthalten und muss zwischen 5 und 128 Zeichen lang sein.",
    Show: "Zeige",
    entries: "Einträge",
    Entries: "Einträge",
    "All Tenants": "Alle Mandanten",
    "My Mobile Security": "Meine Mobile Security",
    "My Unified Security Console": "Meine Unified Security Console",
    "My USC": "Meine USC",
    "Unified Security Console Dashboard - All Tenants": "Unified Security Console - Alle Mandanten",
    "Mobile Security Dashboard - All Tenants": "Mobile Security - Alle Mandanten",
    "For all Tenants": "Für alle Mandanten",
    "Missing required user permission:": "Fehlende erforderliche Benutzerberechtigung:",
    "This feature is currently not available as the UTM has not yet transmitted all the necessary information. Please wait a few minutes until the information is available.":
        "Dieses Feature ist aktuell nicht verfügbar, da die UTM noch nicht alle notwendigen Informationen übermittelt hat. Bitte warten Sie einige Minuten, bis die Informationen verfügbar sind.",
    "Must NOT have more than 45 characters": "Darf NICHT mehr als 45 Zeichen enthalten",
    "Must NOT have more than 128 characters": "Darf NICHT mehr als 128 Zeichen enthalten",
    "Must NOT have more than 30 characters": "Darf NICHT mehr als 30 Zeichen enthalten",
    "Must NOT have fewer than 2 characters": "Darf NICHT weniger als 2 Zeichen enthalten",
    "Must NOT have more than 32 characters": "Darf NICHT mehr als 32 Zeichen enthalten",
    "Must NOT have more than 100 characters": "Darf NICHT mehr als 100 Zeichen enthalten",
    "Android-Devices": "Android Geräte",
    'The username must not contain any special characters, except for "@", "." or ",". Additionally the username must be between 3 and 100 characters long.':
        'Der Benutzername darf keine Sonderzeichen enthalten, außer "@", "." oder ",". Außerdem muss der Benutzername zwischen 3 und 100 Zeichen lang sein.',
    "License expires in %s days": "Lizenz läuft in %s Tagen ab",
    Extend: "Verlängern",
    "Must NOT have fewer than 5 characters": "Darf NICHT weniger als 5 Zeichen haben",
    "Must NOT have fewer than 3 characters": "Darf NICHT weniger als 3 Zeichen haben",
    "Do you really want to delete this other device (vpn)?":
        "Möchten Sie dieses andere Gerät (VPN) wirklich löschen?",
    "Please upload a file with the following file type:":
        "Bitte laden Sie eine Datei mit folgendem Dateiformat hoch:",
    "Do you really want to delete this configuration?":
        "Möchten Sie diese Konfiguration wirklich löschen?",
    "Do you really want to delete this app?": "Möchten Sie diese App wirklich löschen?",
    "File content is not supported": "Der Dateiinhalt wird nicht unterstützt",
    "Wrong content": "Falscher Inhalt",
    "MDM installed Apps": "MDM installierte Apps",
    "A status report of the client’s MDM-installed apps.":
        "Ein Statusbericht über die auf dem Gerät, vom MDM, installierten Anwendungen.",
    "Users are currently being imported from Entra ID. Editing users and roles is deactivated as long as the process is running.":
        "Es werden aktuell Benutzer von Entra ID importiert. Das Bearbeiten der Benutzer und Rollen ist deaktiviert, solange der Prozess läuft.",
    "License expires tomorow": "Lizenz läuft morgen ab",
    "License expires today": "Lizenz läuft heute ab",
    "The process takes unusually long. Please check your connection to the UTM.":
        "Der Prozess dauert ungewöhnlich lange. Bitte überprüfen Sie Ihre Verbindung zur UTM.",
    "Not Receiving Cloud-Backups": "Keine Cloud-Backups erhalten",
    "Receiving the websocket messages takes unusually long. Please check your connection to the UTM.":
        "Der Empfang der Websocket-Nachrichten dauert ungewöhnlich lange. Bitte überprüfen Sie Ihre Verbindung zur UTM.",
    "No UTM Connection": "Keine UTM Verbindung",
    "You can only select the %s application once for your %y.":
        "Sie können die Applikation %s nur einmal für Ihr %y Profil auswählen.",
    "You may only select applications once per profile.":
        "Sie dürfen Applikationen nur einmal pro Profil auswählen.",
    "The rolename may only contain letters, numbers, hyphens and underscores":
        "Der Rollenname darf nur Buchstaben, Zahlen, Bindestriche und Unterstriche enthalten",
    "Do you want to remove all previous group assignments of the imported groups?":
        "Möchten Sie alle früheren Gruppenzuweisungen der importierten Gruppen entfernen?",
    "Import from Entra ID": "Aus Entra ID importieren",
    'must match format "email"': 'Muss dem Format "E-Mail" entsprechen',
    'Must match format "ipv4cidr", "ipv6" or "fqdn"':
        'Muss dem Format  "ipv4cidr", "ipv6" oder "fqdn" entsprechen',
    "versions differ": "Versionen unterscheiden sich",
    "Attention: The assignment of licenses must be synchronized with Apple after saving. Changes may not be displayed for a few minutes":
        "Achtung: Die Zuweisung der Lizenzen muss nach dem Speichern mit Apple Synchronisiert werden. Änderungen werden evtl. erst in wenigen Minuten angezeigt ",
    "Background process": "Hintergrundprozess",
    "Open external inventory link": "Externen Inventar-Link öffnen",
    "No link is configured": "Es ist kein Link konfiguriert",
    "If you do not shut down the UTM after restoring the factory settings, malfunctions may occur. Further information can be found in the Wiki.":
        "Wenn Sie die UTM nach dem Wiederherstellen der Werkseinstellungen nicht herunterfahren, kann es zu Fehlfunktionen kommen. Weitere Informationen finden Sie im Wiki.",
    "As of version 1.24, USP administrators are required to use two-factor authentication as an additional security measure. You should activate the two-factor authentication in your user account before the release of version 1.24, otherwise the login to the USP will be blocked until the two-factor authentication is activated. Starting with version 1.24, you can also enable two-factor authentication from the login screen.":
        "Ab Version 1.24 sind USP-Administratoren verpflichtet, die Zwei-Faktor-Authentifizierung als zusätzliche Sicherheitsmaßnahme zu verwenden. Sie sollten die Zwei-Faktor-Authentifizierung in Ihrem Benutzerkonto vor der Veröffentlichung der Version 1.24 aktivieren, andernfalls ist der Login in das USP bis zur Aktivierung der Zwei-Faktor-Authentifizierung gesperrt. Ab Version 1.24 ist die Aktivierung auch über die Login-Maske möglich.",
    "Nameserver which is used for the VPN tunnel clients.":
        "Nameserver, welcher für die VPN-Tunnel-Clients benutzt wird.",
    "Primary nameserver which is used for the VPN tunnel clients.":
        "Primärer Nameserver, welcher für die VPN-Tunnel-Clients benutzt wird.",
    "Secondary nameserver which is used for the VPN tunnel clients.":
        "Sekundärer Nameserver, welcher für die VPN-Tunnel-Clients benutzt wird.",
    "The time zone in which the UTM is located.": "Die Zeitzone, in der sich die UTM befindet.",
    "In addition to a local backup of the configuration or via the Unified Security Console, the UTM offers the option of save a backup of the boot configuration on our cloud servers. This backup is performed at the time specified here.":
        "Zusätzlich zu einer lokalen Sicherung der Konfiguration oder über die Unified Security Console gibt es in der UTM die Möglichkeit, ein Backup der Boot-Konfiguration auf unseren Cloud-Servern zu speichern. Diese Sicherung wird zu der hier hinterlegten Stunde durchgeführt.",
    "IPs stored here are excluded from source GeoIP blocking.":
        "Hier hinterlegte IPs werden vom Quellen-GeoIP-Blocking ausgenommen.",
    "IP addresses can be assigned to a country via the associated IP networks, organizations and institutions. Countries stored here are active for source GeoIP blocking.":
        "IP-Adressen können über die zugehörigen IP-Netze den Organisationen und Institutionen, denen diese zugewiesen sind, einem Land zugeordnet werden. Hier hinterlegte Länder sind aktiv für das Quellen-GeoIP-Blocking.",
    "IPs stored here are excluded from the target GeoIP blocking.":
        "Hier hinterlegte IPs werden vom Ziel-GeoIP-Blocking ausgenommen.",
    "IP addresses can be assigned to a country via the associated IP networks, organizations and institutions. Countries stored here are active for target GeoIP blocking.":
        "IP-Adressen können über die zugehörigen IP-Netze den Organisationen und Institutionen, denen diese zugewiesen sind, einem Land zugeordnet werden. Hier hinterlegte Länder sind aktiv für das Ziel-GeoIP-Blocking.",
    "The name of the administrator or organization is entered in this field, which is later specified in the UTM error messages for queries.":
        "In diesem Feld wird der Name des Administrators oder der Organisation eingetragen, der später in den UTM Fehlermeldungen für Rückfragen angegeben wird.",
    "The important system messages are sent in this language.":
        "Die wichtigen Systemmeldungen werden in dieser Sprache versendet.",
    "Important system messages are sent to the e-mail address you have entered. Make sure that the e-mail address you have entered is correct.":
        "An die von Ihnen hinterlegte E-Mail-Adresse werden wichtige Systemmeldungen verschickt. Stellen Sie sicher, dass die angegebene E-Mail-Adresse korrekt ist.",
    "The local cache of the UTM first answers the DNS queries (corresponds to 127.0.0.1) as the primary name server. When activated, the entered name servers are checked for name resolution before the local cache of the UTM.":
        "Der lokale Cache der UTM beantwortet zunächst die DNS-Anfragen (entspricht 127.0.0.1) als primärer Nameserver. Bei Aktivierung werden die eingetragenen Nameserver die Namensauflösung vor dem lokalem Cache der UTM geprüft.",
    "The IP addresses of two external name servers to which the UTM should forward the DNS queries can be entered here. DNS servers that can be reached via the external interface should be entered here.":
        "An dieser Stelle können die IP-Adressen zweier externer Nameserver eingetragen werden, an welche die UTM die DNS-Anfragen weiterleiten soll. Hier sollten DNS-Server eingetragen werden, die über die externe Schnittstelle erreichbar sind.",
    "The primary IP address of an external name server to which the UTM should forward the DNS queries can be entered here. DNS servers that can be reached via the external interface should be entered here.":
        "An dieser Stelle kann die primäre IP-Adresse eines externen Nameservers eingetragen werden, an welche die UTM die DNS-Anfragen weiterleiten soll. Hier sollten DNS-Server eingetragen werden, die über die externe Schnittstelle erreichbar sind.",
    "The secondary IP address of an external name server to which the UTM should forward the DNS queries can be entered here. DNS servers that can be reached via the external interface should be entered here.":
        "An dieser Stelle kann die sekundäre IP-Adresse eines externen Nameservers eingetragen werden, an welche die UTM die DNS-Anfragen weiterleiten soll. Hier sollten DNS-Server eingetragen werden, die über die externe Schnittstelle erreichbar sind.",
    'Host names, IP addresses and networks can be enabled for administration. The network with the "internal" zone is always enabled.':
        'Für die Administration können Hostnamen, IP Adressen und Netzwerke freigeschaltet werden. Das Netzwerk mit der Zone "internal" ist immer freigeschaltet.',
    "General settings": "Allgemeine Einstellungen",
    "The NTP servers which the UTM uses for time synchronization.":
        "Die NTP-Server, welche die UTM für die Zeitsynchronisierung nutzt.",
    "Select Backuptime": "Backuptime auswählen",
    "Set DEP profile pin": "DEP Profil-Pin festlegen",
    "Switch to map-view": "Zur Kartenansicht wechseln",
    "Disable map-view": "Kartenansicht deaktivieren",
    "No IP selected": "Keine IP ausgewählt",
    "Between 8 and 256 Characters": "Zwischen 8 und 256 Zeichen",
    "Does not start or end with whitespaces": "Beginnt und endet nicht mit Leerzeichen",
    "No controlcharacters": "Keine Steuerzeichen",
    "Has at least 3 different character types (Lowercase, uppercase, numbers, special characters)":
        "Enthält mindestens 3 verschiedene Zeichentypen (Kleinbuchstaben, Großbuchstaben, Zahlen, Sonderzeichen)",
    "Passwords match": "Passwörter stimmen überein",
    "Define DEP Profile-PIN": "DEP Profil-PIN festlegen",
    "Cloud Backup": "Cloud-Backup",
    "Regular Cloud Backup: enabled/disabled": "Regelmäßiges Cloud-Backup: ein/aus",
    "Regular Cloud Backup": "Regelmäßiges Cloud-Backup",
    "Daily from:": "Täglich ab:",
    "Appliance Settings": "Servereinstellungen",
    "Global contact person": "Globaler Ansprechpartner",
    "Global email address": "Globale E-Mail-Adresse",
    "Report language": "Sprache der Berichte",
    "DNS Server": "DNS-Server",
    "Check Nameserver prior to local cache": "Nameserver vor lokalem Cache prüfen",
    "Primary Nameserver": "Primärer Nameserver",
    "Secondary Nameserver": "Sekundärer Nameserver",
    "Time Settings": "Zeiteinstellungen",
    "Network Time Server": "NTP-Server",
    Administration: "Administration",
    "Unlock administration access for:": "Administrativen Zugriff freigeben für:",
    "Global GeoIP": "Globale GeoIP",
    "Global GeoIP Settings": "Globale GeoIP-Einstellungen",
    "Activate GeoIP": "GeoIP aktivieren",
    "System-wide dropped sources": "Systemweit abgelehnte Quellen",
    Exceptions: "Ausnahmen",
    Destinations: "Ziele",
    "System-wide dropped destinations": "Systemweit abgelehnte Ziele",
    "Firmware Updates": "Firmware-Updates",
    "Automatic updates": "Automatische Updates",
    "Activate Automatic updates on the UTM": "Automatische Updates auf der UTM aktivieren",
    Weekdays: "Wochentage",
    "Additional validation endpoint": "Zusätzlicher Prüfungs-Endpunkt",
    Port: "Port",
    "Global VPN settings": "Globale VPN-Einstellungen",
    "Domain Name System": "Domain-Name-System",
    xFrom: "Ab",
    xDashboard: "Dashboard",
    xState: "Status",
    "No recipients": "Keine Empfänger",
    "Select destinations": "Wählen Sie Ziele aus",
    "Enter text, in order to add administrators": "Text eingeben, um Administratoren hinzuzufügen",
    "Add administrators": "Administratoren hinzufügen",
    "Enter text, in order to add NTP servers": "Text eingeben, um NTP-Server hinzuzufügen",
    "Add NTP servers": "NTP-Server hinzufügen",
    "Enter text, in order to add IP addresses": "Text eingeben, um IP-Adressen hinzuzufügen",
    "If activated, the use of a passcode is enforced and no simple passcode is allowed. The minimum length is 6.":
        "Wenn aktiviert, wird die Verwendung eines Passcodes erzwungen und kein einfacher Passcode zugelassen. Die Mindestlänge beträgt hierbei 6.",
    "Profile publish state": "Profil Publish-Status",
    "Last change": "Letzte aktualisierung",
    "Login to the MDM can be protected with a PIN. This must then be entered by the user of the end device during the initial setup. In the event of a lost or stolen device, the PIN provides an additional level of security, as the device cannot be put back into operation by a third party.":
        "Die Anmeldung am MDM kann mit einer PIN geschützt werden. Dieser muss dann durch den Benutzer des Endgerätes bei der Ersteinrichtung angegeben werden. Im Falle eines verlorenen oder gestohlenen Geräts bietet die PIN eine zusätzliche Sicherheitsebene, da das Gerät nicht von Dritten wieder in Betrieb genommen werden kann.",
    "<strong>Attention</strong>: The PIN is only used for newly created DEP profiles from version 1.23.0.":
        "<strong>Achtung</strong>: Die PIN wird nur für neu erstellte DEP-Profile ab Version 1.23.0 angewendet.",
    "PIN secured": "PIN-geschützt",
    English: "Englisch",
    German: "Deutsch",
    "The one-time update will be available in the UTM details instead of the profiles up from the next portal version.":
        "Das einmalige Update wird Ihnen ab der nächsten Portalversion in den UTM-Details anstatt in den Profilen zur Verfügung stehen.",
    "Wrong file format. Please upload .mobileconfig files.":
        "Falsches Dateiformat. Bitte laden Sie .mobileconfig Dateien hoch.",
    "PROFILE SUCCESSFULLY UPLOADED": "PROFIL ERFOLGREICH HOCHGELADEN",
    "Time settings": "Zeit-Einstellungen",
    "Enable cloud backup settings": "Cloud-Backup Einstellungen aktivieren",
    "Enable firewall settings": "Firewall-Einstellungen aktivieren",
    "Enable DNS Server settings": "DNS-Server Einstellungen aktivieren",
    "Enable time settings": "Zeit-Einstellungen aktivieren",
    "Enable Administration settings": "Administrations-Einstellungen aktivieren",
    "Enable GeoIP settings": "GeoIP-Einstellungen aktivieren",
    "Enable Global VPN settings": "Globale VPN-Einstellungen aktivieren",
    "By entering data in Plist format, you can create your own application configuration.":
        "Durch die Eingabe von Daten im Plist-Format können Sie Ihre eigene Anwendungskonfiguration erstellen.",
    "You can use <a href='https://wiki.securepoint.de/MS/Variablen'>user variables</a> to define a user-specific configuration.":
        "Sie können <a href='https://wiki.securepoint.de/MS/Variablen'>Benutzervariablen</a> verwenden, um eine benutzerspezifische Konfiguration zu definieren.",
    "Show configuration": "Konfiguration anzeigen",
    "App Configuration": "Konfiguration der Anwendung",
    "TOTP-Token is invalid.": "TOTP-Token ist ungültig.",
    "Cannot authenticate user %s for tenant %y. Check your tenant, password and username.":
        "Der Benutzer %s kann nicht für den Tenant %y authentifiziert werden. Überprüfen Sie Ihren Mandanten, Ihr Passwort und Ihren Benutzernamen.",
    "Tenant %s is inactive.": "Tenant %s ist inaktiv.",
    "Missing username and password.": "Fehlender Benutzername und Passwort.",
    "Missing username.": "Fehlender Benutzername.",
    "Missing password.": "Fehlendes Passwort.",
    "Cannot authenticate user %s for tenant. Check your tenant, password and username.":
        "Der Benutzer %s kann nicht für den Tenant authentifiziert werden. Überprüfen Sie Ihren Mandanten, Ihr Passwort und Ihren Benutzernamen.",
    "Deactivating this function disables the creation of new DEP devices for pre-configuration.":
        "Wenn Sie diese Funktion deaktivieren, werden für neue DEP-Geräte keine Geräte in Ihrer Übersicht zur Vorkonfiguration angelegt.",
    "An error has occurred. Please check that the selected user has the auditor role and the given password and TOTP-code for the user is correct.":
        "Es ist ein Fehler aufgetreten. Bitte überprüfen Sie, ob der ausgewählte Benutzer die Auditor Rolle hat und ob das angegebene Passwort und der TOTP-Code für den Benutzer korrekt sind.",
    "Firmware Update settings": "Firmware Update Einstellungen",
    "Manage cloud backup": "Cloud-Backup verwalten",
    "Manage firewall": "Firewall verwalten",
    "Manage DNS Server": "DNS-Server verwalten",
    "Manage time": "Zeit verwalten",
    "Manage Administration": "Administrations verwalten",
    "Manage GeoIP": "GeoIP verwalten",
    "Manage Global VPN": "Globale-VPN verwalten",
    "Manage Firmware Update": "Firmware-Update verwalten",
    "Enables setting firmware update settings":
        "Aktiviert das Definieren der Firmware-Update-Einstellungen",
    "Enable cloud backup on UTM": "Aktiviere Cloud-Backup auf der UTM",
    "Enables/Disables automatic updates on the UTM":
        "Aktiviert/Deaktiviert automatische Updates auf der UTM",
    "Enables/Disables cloud backup on the UTM": "Aktiviert/Deaktiviert Cloud-Backup auf der UTM",
    "Schedule a one-time update": "Einmaliges Update planen",
    Saved: "Gespeichert",
    "Here you have the possibility to plan a one-time update for your UTM. Enter a date and the UTM will check for updates on that day.":
        "Hier haben Sie die Möglichkeit ein einmaliges Update für Ihre UTM zu planen. Geben Sie ein Datum ein und die UTM wird an diesem Tag nach Updates suchen.",
    "Search for updates on": "Nach Updates suchen am",
    "One Time Update": "Einmaliges Update",
    'Information on the planned updates can be found in the "Cloud Scheduler Log" section of the UTM overview.':
        'Infos zu den geplanten Updates finden Sie in dem Bereich "Cloud Scheduler Log" der UTM-Übersicht',
    "<strong>Changes to the automatic update due to extensions to the UTM profiles:</strong><br/>From UTM version 12.6.2, the setting for automatic updates on the UTM is configured by the profiles. For these UTM versions, it is possible to set that an additional endpoint must be accessible before the new UTM version is finalized after the test run.<br/><br/>For UTMs with an older version, the update is triggered via the Unified Security Console. With this type of update, no additional test endpoint can be specified for the finalization. The UTM is finalized automatically after the test run and the establishment of the cloud connection.<br/><br/><strong>The following applies to all UTMs:</strong><br/>The UTM is restarted during the update process. All connections to the UTM (e.g. VPN, SSH) are interrupted.<br/><br/>The time is specified in UTC. UTC does not use a time zone! For better load distribution, only one hour can be selected within which the process is to be triggered.<br/><br/>From version 12.6.2. the update is performed at local time. This may differ from UTC time due to the time zone set on the UTM.":
        "<strong>Änderungen des automatischen Updates aufgrund von Erweiterungen der UTM-Profile:</strong><br/>Ab UTM- Version 12.6.2 wird durch die Profile die Einstellung für automatische Updates auf der UTM konfiguriert. Für diese UTM-Versionen besteht die Möglichkeit einzustellen, dass ein zusätzlicher Endpunkt erreichbar sein muss, bevor die neue UTM-Version nach dem Probelauf finalisiert wird.<br/><br/>Für UTMs mit einer älteren Version wird das Update über die Unified Security Console angestoßen. Bei dieser Art der Aktualisierung kann kein zusätzlicher Prüfungsendpunkt für die Finalisierung angegeben werden. Die UTM wird nach dem Probelauf und dem Aufbau der Cloud-Verbindung automatisch finalisiert.<br/><br/><strong>Für alle UTMs gilt:</strong><br/>Die UTM wird während des Update-Vorgangs neu gestartet. Alle Verbindungen zur UTM (z. B. VPN, SSH) werden unterbrochen.<br/><br/>Die Uhrzeit wird in UTC angegeben. UTC verwendet keine Zeitzone! Zur besseren Lastverteilung kann nur eine Stunde ausgewählt werden, innerhalb der der Prozess ausgelöst werden soll.<br/><br/>Ab der Version 12.6.2. erfolgt die Aktualisierung zur lokalen Zeit. Diese kann aufgrund der eingestellten Zeitzone auf der UTM von der UTC-Zeit abweichen.",
    "Additional success check endpoint": "Zusätzlicher Prüfungs-Endpunkt",
    "These settings can only be applied to UTMs from version 12.6.2.":
        "Diese Einstellungen können nur auf UTMs ab Version 12.6.2 angewendet werden.",
    "This field is required": "Pflichtfeld",
    "One requirement ist not met": "Eine Anforderung ist nicht erfüllt",
    "UTM profiles are disabled": "UTM Profile sind deaktiviert",
    "Request Password Reset": "Passwort zurücksetzen anfordern",
    "Email to set the password requested": "E-Mail zum Setzen des Passworts angefordert",
    "An Email to set the password has been sent to %s.":
        "Eine E-Mail zum Setzen des Passworts wurde an %s gesendet.",
    "Password reset": "Passwort zurücksetzen",
    "must match a schema in anyOf": "Muss einem der genannten Punkte entsprechen",
    "Enables configuring cloud backup settings":
        "Erlaubt die Konfiguration der Cloud-Backup-Einstellungen",
    "Enables configuring firewall settings": "Erlaubt die Konfiguration der Firewall-Einstellungen",
    "Enables configuring DNS Server settings":
        "Erlaubt die Konfiguration der DNS-Server Einstellungen",
    "Enables configuring time settings": "Erlaubt die Konfiguration der Zeit Einstellungen",
    "Enables configuring Administration settings":
        "Erlaubt die Konfiguration der Administrations Einstellungen",
    "Enables configuring GeoIP settings": "Erlaubt die Konfiguration der GeoIP-Einstellungen",
    "Enables configuring Global VPN settings":
        "Erlaubt die Konfiguration der Globale-VPN-Einstellungen",
    "Must match format %s": "Muss dem Format %s entsprechen",
    "Activate source GeoIP blocking": "Aktiviere Quellen-GeoIP-Blocking",
    "Activate target GeoIP blocking": "Aktiviere Ziel-GeoIP-Blocking",
    "No profile": "Kein Profil",
    "Action was not triggered by a profile": "Aktion wurde nicht anhand eines Profils ausgelöst",
    "Resuming job at label dryrun": 'Wiederaufnahme des Jobs bei "Dryrun".',
    "Resuming job at label reboot": 'Wiederaufnahme des Jobs bei "Reboot".',
    "Resuming job at label finalize": 'Wiederaufnahme des Jobs bei "Finalize".',
    "Job is parked with label dryrun. Waiting for resume response.":
        'Der Job ist geparkt bei "Dryrun". Warte auf Antwort zum Fortsetzen.',
    "Job is parked with label reboot. Waiting for resume response.":
        'Der Job ist geparkt bei "Reboot". Warte auf Antwort zum Fortsetzen.',
    "Job is parked with label finalize. Waiting for resume response.":
        'Der Job ist geparkt bei "Finalize". Warte auf Antwort zum Fortsetzen.',
    "Job is done [Update is done]": "Job ist erledigt (Update)",
    "Before a dryrun will be initialized and after an update has been installed and booted (but still before it is finalized), the appliance will ensure that the Securepoint update server can be reached. You may enter an additional endpoint (hostname or ip address and port) here, which also will be tested. A TCP handshake to a service on the specified server will be checked. If any connection tests fail, the appliance will not update the firmware (by executing a rollback to the previous version if necessary).":
        "Bevor ein Dryrun gestartet wird und auch nachdem ein Update installiert und gestartet worden ist (aber noch bevor das Update finalisiert wird), wird die Appliance testen, ob der Securepoint Update-Server erreicht werden kann. Sie können hier einen weiteren Endpunkt (Hostnamen oder IP-Adresse und Port) angeben, dessen Erreichbarkeit ebenfalls getestet wird. Es wird ein TCP Handshake zu einem Dienst auf dem angegebenen Server geprüft. Sollte ein Test fehlschlagen, wird kein Firmware Update ausgeführt (ggf. indem ein Rollback auf die vorherige Version durchgeführt wird).",
    "Once (deprecated)": "Einmalig (veraltet)",
    "Forgot password?": "Passwort vergessen?",
    'After clicking "Reset password" you will receive an email with further instructions.':
        'Nachdem Sie auf "Passwort zurücksetzen" geklickt haben, erhalten Sie eine E-Mail mit weiteren Anweisungen.',
    "Missing Two-Factor-Authentication": "Fehlende Zwei-Faktor-Authentifizierung",
    "As of version 1.24, USP administrators are required to use Two-Factor-Authentication as an additional security measure.":
        "Ab Version 1.24 sind USP-Administratoren verpflichtet, die Zwei-Faktor-Authentifizierung als zusätzliche Sicherheitsmaßnahme zu verwenden.",
    "To be able to use the portal again, please set up Two-Factor-Authentication (TOTP or Webauthn) for your account in the Securepoint Reseller Portal.":
        "Bitte besuchen Sie das Reseller-Portal, um die Zwei-Faktor-Authentifizierung (TOTP oder Webauthn) einzurichten.",
    "Back to login": "Zurück zum Login",
    "Two-Factor-Authentication": "Zwei-Faktor-Authentifizierung",
    "From version 1.24, login is only possible with activated 2-factor authentication (2FA).":
        "Ab Version 1.24 ist ein Login nur noch mit aktivierter 2-Faktor-Authentifizierung (2FA) möglich.",
    "Request a password reset email now": "Jetzt Passwort-Reset-Email anfordern",
    "to activate 2FA.": ", um 2FA zu aktivieren.",
    "By activating the re-enrollment function in Mobile Device Management (MDM), newly added DEP devices are automatically registered in the system and can be individually pre-configured before they are used for the first time.":
        "Mit der Aktivierung der Reenrollment-Funktion im Mobile Device Management (MDM) werden neu hinzugefügte DEP-Geräte automatisch im System registriert und können bereits vor ihrer ersten Nutzung individuell vorkonfiguriert werden.",
    "The settings for user profiles, applications and tags defined in this way are applied seamlessly the first time the device is registered.":
        "Die so festgelegten Einstellungen für Benutzerprofile, Anwendungen und Tags werden nahtlos bei der ersten Anmeldung des Gerätes angewendet.",
    "This function also extends to non-DEP devices. When a device is recommissioned, the configurations of the previous device are automatically transferred, provided it is still present in the system.":
        "Diese Funktion erstreckt sich auch auf Nicht-DEP-Geräte. Bei einer Wiederinbetriebnahme eines Gerätes erfolgt eine automatische Übertragung der Konfigurationen des vorherigen Gerätes, vorausgesetzt, dieses ist noch im System vorhanden.",
    "If you want to prevent the settings for a specific device from being adopted, it is necessary to remove the device in question from the system before it is registered again.":
        "Für den Fall, dass Sie die Übernahme der Einstellungen für ein spezifisches Gerät verhindern möchten, ist es notwendig, das betreffende Gerät aus dem System zu entfernen, bevor es erneut angemeldet wird.",
    "<strong>Attention:</strong> When transferring a device to a new user, it is important to update the user assignment in the system. Otherwise, the new user could gain access to the data and accounts of the previous user.":
        "<strong>Achtung:</strong> Bei einer Geräteweitergabe an einen neuen Benutzer ist es wichtig, die Zuordnung des Benutzers im System zu aktualisieren. Andernfalls könnte der neue Nutzer Zugang zu den Daten und Konten des vorherigen Benutzers erhalten.",
    "Too many requests. Please try again later.":
        "Zu viele Anfragen. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
    "Would you like to send the new user an Email to set the password?":
        "Möchten Sie dem neuen Benutzer eine E-Mail zum Setzen des Passworts senden?",
    "The Email will be sent to the address below.":
        "Die E-Mail wird an die unten stehende Adresse gesendet.",
    "Alternatively, the user can set the password using the password reset function in the login screen.":
        "Alternativ kann der Benutzer sein Passwort über die Passwort-Zurücksetzen-Funktionalität in der Login-Maske setzen.",
    "Send Email to set password?": "E-Mail zum Setzen des Passworts senden?",
    "Email sent": "E-Mail versendet",
    "An email with further instructions has been sent to the user.":
        "Eine E-Mail mit weiteren Anweisungen wurde an den Benutzer gesendet.",
    Metrics: "Metriken",
    "Open the two-factor-authentication app on your device to view your authentication code.":
        "Öffnen Sie die App für die Zwei-Faktor-Authentifizierung auf Ihrem Gerät, um Ihren Authentifizierungscode anzuzeigen.",
    Now: "Jetzt",
    "Add User only": "Benutzer nur hinzufügen",
    "Send Email to set password": "E-Mail zum Setzen des Passworts senden",
    "UTM Profiles": "UTM Profile",
    "This message only appears in the audit log and is used for traceability of the localization.":
        "Diese Nachricht erscheint lediglich im Auditlog und dient der Nachvollziehbarkeit der Lokalisierung.",
    "Message text": "Text der Nachricht",
    "Controls what files and/or data can be transferred via USB. Does not impact charging functions. Supported only on company-owned devices.":
        "Steuert, welche Dateien und/oder Daten über USB übertragen werden können. Wirkt sich nicht auf die Ladefunktionen aus. Wird nur auf unternehmenseigenen Geräten unterstützt.",
    'Unspecified. Defaults to "Disallow file transfer"':
        'Nicht angegeben Die Standardeinstellung ist "Dateiübertragung verbieten"',
    "Allow all": "Alles erlauben",
    "All types of USB data transfers are allowed.":
        "Alle Arten der USB-Datenübertragung sind zulässig.",
    "Disallow file transfer": "Dateiübertragung verbieten",
    "Transferring files over USB is disallowed. Other types of USB data connections, such as mouse and keyboard connection, are allowed.":
        "Die Übertragung von Dateien über USB ist nicht zulässig. Andere USB-Datenverbindungen, z. B. Maus und Tastatur, sind zulässig.",
    "Disallow all data transfer": "Alle Datenübertragungen verbieten",
    "When set, all types of USB data transfers are prohibited. Supported for devices running Android 12 or above with USB HAL 1.3 or above.":
        "Wenn die Richtlinie konfiguriert ist, sind alle Arten der USB-Datenübertragung unzulässig. Wird für Geräte mit Android 12 oder höher und USB HAL 1.3 oder höher unterstützt.",
    "License sync with RSP": "Lizenz-Sync mit RSP",
    "This is used to synchronize the license with the reseller portal":
        "Hiermit wird die Lizenz-Synchronisation mit dem Reseller-Portal durchgeführt",
    "This will trigger the license synchronization with the reseller portal":
        "Hiermit wird die Lizenz-Synchronisation mit dem Reseller-Portal durchgeführt",
    "This account cannot login due to missing roles.":
        "Dieses Konto kann sich aufgrund fehlender Rollen nicht anmelden.",
    "This account cannot login due to missing accounts.":
        "Dieses Konto kann sich aufgrund fehlender Konten nicht anmelden.",
    "This Account cannot login due to missing roles and accounts.":
        "Dieses Konto kann sich aufgrund fehlender Konten nicht anmelden.",
    "Choose a user": "Benutzer auswählen",
    "Sending emails...": "Versende E-Mails...",
    "An update from %1s to %2s has been finalized for %3s":
        "Ein Update von %1s auf %2s wurde für %3s finalisiert",
    "An update from %1s to %2s can be finalized for %3s":
        "Ein Update von %1s auf %2s kann für %3s finalisiert werden",
    "Only letters and numbers are allowed": "Es sind nur Buchstaben und Zahlen erlaubt",
    "Add Enrollmenttoken": "Registrierungstoken hinzufügen",
    "Edit Enrollmenttoken": "Registrierungstoken bearbeiten",
    Enrollmenttoken: "Registrierungstoken",
    xEnrollmenttoken: "Entrollmenttoken",
    Enrollmenttokens: "Registrierungstoken",
    xEnrollmenttokens: "Entrollmenttokens",
    "Device limit": "Gerätelimit",
    "The amount of devices that can be enrolled with this token":
        "Die Anzahl an Geräten die mit diesem Token registriert werden können",
    Validity: "Gültigkeit",
    "This message is displayed on the device.": "Diese Meldung wird auf dem Gerät angezeigt.",
    Destination: "Ziel",
    "Add Network Object": "Netzwerkobjekt hinzufügen",
    "Don't show this message again.": "Diese Nachricht nicht noch einmal anzeigen.",
    "Publish-State": "Veröffentlichungsstatus",
    "Client details": "Client-Details",
    'To be able to use Mobile Security, the "Securepoint VPN Client" app is first installed automatically. This requires either a VPP license from the Apple Business Manager or an Apple ID on the device.':
        'Um Mobile Security nutzen zu können, wird zunächst die App "Securepoint VPN Client" automatisch installiert. Dazu wird entweder eine VPP-Lizenz aus dem Apple Business Manager oder auf dem Gerät eine Apple ID benötigt.',
    "This dialog will be hidden for the next 45 days.":
        "Dieser Dialog wird für die nächsten 45 Tage ausgeblendet.",
    "If you have already hidden other dialogs, these will also only be displayed again after the 45 days have expired.":
        "Falls Sie bereits andere Dialoge ausgeblendet haben, werden diese ebenfalls erst nach Ablauf der 45 Tagen wieder angezeigt.",
    _Copy: "_Kopie",
    "The name is empty": "Der Name ist leer",
    "Please note that the automated device enrollment can only be used if a PIN is set.":
        "Bitte beachten Sie, dass die automatisierte Geräteanmeldung nur genutzt werden kann, wenn eine PIN gesetzt ist",
    "Some of the names entered are identical": "Einige der eingegebenen Namen sind identisch",
    "The name is too short. The minumum length is %s":
        "Der Name ist zu kurz. Die Mindestlänge beträgt %s",
    "In order to complete linking Android Enterprise, please provide the used e-mail address":
        "Um die Verknüpfung von Android Enterprise abzuschließen, geben Sie bitte die verwendete E-Mail-Adresse an",
    "The e-mail addresses entered do not match.":
        "Die eingegebenen E-Mail-Adressen stimmen nicht überein.",
    "The e-mail address entered does not correspond to the e-mail format.":
        "Die eingegeben E-Mail-Adresse entspricht dem E-Mail Format nicht.",
    "Confirm E-Mail Address": "E-Mail Adresse bestätigen",
    "E-Mail Address": "E-Mail-Adresse",
    "Please enter the e-mail address of your Google account that you used during setup. This information will help you to remember which Google account you used at a later date. Our system cannot automatically detect the e-mail address used.":
        "Bitte geben Sie die E-Mail-Adresse Ihres Google-Kontos ein, die Sie bei der Einrichtung verwendet haben. Diese Information hilft Ihnen, sich zu einem späteren Zeitpunkt daran zu erinnern, welches Google-Konto Sie verwendet haben. Unser System kann die verwendete E-Mail-Adresse nicht automatisch ermitteln.",
    "Set E-Mail Address": "E-Mail Adresse setzen",
    "Android Enterprise E-Mail Address": "Android Enterprise E-Mail Adresse",
    "An unexpected error occoured while setting the android enterprise e-mail address. Please ensure that the e-mail does not exceed 320 characters.":
        "Beim Setzen der Android Enterprise-E-Mail-Adresse ist ein unerwarteter Fehler aufgetreten. Bitte beachten Sie, dass die E-Mail nicht länger als 320 Zeichen sein darf.",
    "The name may comprise alphanumeric characters, spaces, periods, hyphens, underscores, parentheses, and German umlauts (ä, Ä, ö, Ö, ü, Ü, ß).":
        "Der Name darf alphanumerische Zeichen, Leerzeichen, Punkte, Bindestriche, Unterstriche, Klammern und deutsche Umlaute (ä, Ä, ö, Ö, ü, Ü, ß) enthalten.",
    "The name may comprise alphanumeric characters, spaces, periods, hyphens, underscores, parentheses, German umlauts (ä, Ä, ö, Ö, ü, Ü, ß) and must be between 3 and 120 characters long.":
        "Der Name darf alphanumerische Zeichen, Leerzeichen, Punkte, Bindestriche, Unterstriche, Klammern, deutsche Umlaute (ä, Ä, ö, Ö, ü, Ü, ß) enthalten und muss zwischen 3 und 120 Zeichen lang sein.",
    "The name is too long. The maximum length is %s":
        "Der Name ist zu lang. Die Maximallänge beträgt %s",
    "From version 12.5.1, the UTM initiates a connection to our cloud services and can be administered via these without a public IP address. The older way for UTMs with version 12.5.0 or older will therefore be disabled from portal version 1.28 and the UTMs will no longer be accessible via websession. Please install the necessary update as soon as possible.":
        "Ab Version 12.5.1 initiiert die UTM eine Verbindung zu unseren Cloud-Diensten und kann über diese ohne öffentliche IP-Adresse administriert werden. Das ältere Verfahren für UTMs mit der Version 12.5.0 oder älter wird daher ab der Portalversion 1.28 abgeschaltet und die UTMs werden nicht mehr per Websession erreichbar sein. Bitte führen Sie die erforderliche Aktualisierung so bald wie möglich durch.",
    "Whether the enrollment requires a code or not. Please note that due to security reasons only enrollment tokens with a code can be selected in the ZeroTouch enrollment.":
        "Legt fest ob beim Enrollment ein Code benötigt wird oder nicht. Bitte beachten Sie, dass aus Sicherheitsgründen für das ZeroTouch Enrollment nur Enrollmenttoken ausgewählt werden können, die mit einem Code versehen worden sind.",
    "Add all apps (without system apps)": "Alle Apps hinzufügen (ohne System-Apps)",
    "Phone number can only contain numbers, spaces, hyphen, and parentheses.":
        "Die Telefonnummer darf nur Zahlen, Leerzeichen, Bindestriche und Klammern enthalten.",
    "Permission Denied": "Erlaubnis verweigert",
    'The user must agree the terms of service for company id %s at <a href="https://partner.android.com/zerotouch" target="_blank">https://partner.android.com/zerotouch</a>':
        'Der Benutzer muss den Nutzungsbedingungen für die Firmen ID %s unter <a href="https://partner.android.com/zerotouch" target="_blank">https://partner.android.com/zerotouch</a> zustimmen.',
    copied: "kopiert",
    "Show expired tokens": "Abgelaufene Token anzeigen",
    "Enter a name that you can use to identify this token. This can be changed at any time.":
        "Geben Sie einen Namen ein, über den Sie dieses Token identifizieren können. Dieser kann jederzeit verändert werden.",
    "Period of validity of the token. After this period has expired, the token can no longer be used to log in.":
        "Dauer der Gültigkeit des Tokens. Nach Ablauf dieses Zeitraums kann das Token nicht mehr zur Anmeldung verwendet werden.",
    "These tags are assigned to all Windows VPN clients that are logged in via this token. You can also use configuration profile tags to configure restrictions, settings and VPN connections directly during enrollment.":
        "Diese Tags werden an alle Windows VPN Clients vergeben, die über dieses Token angemeldet werden. Sie können auch Tags von Konfigurationsprofilen verwenden, um Einschränkungen, Einstellungen und VPN-Verbindungen direkt beim Enrollment zu konfigurieren.",
    "Copy this key and paste it into the corresponding field of the VPN client to start the enrollment.":
        "Kopieren Sie diesen Schlüssel und fügen Sie ihn in das entsprechende Feld des VPN-Clients ein, um das Enrollment zu starten.",
    "Remaining term": "Restlaufzeit",
    hours: "Stunden",
    day: "Tag",
    minute: "Minute",
    minutes: "Minuten",
    "Devices enrolled": "Registrierte Geräte",
    "A model action": "Eine Model-Aktion",
    "Start a websession": "Websession starten",
    "Network view": "Netzwerkinformationen",
    "Add VPN Client": "VPN Client hinzufügen",
    hour: "Stunde",
    "Used After Expiry": "Nach Ablauf verwendet",
    "All types of tethering are allowed.": "Alle Arten von Tethering sind erlaubt.",
    "Prevents the user from using WLAN tethering. This setting is supported on company-owned devices with Android 13 and higher.":
        "Verhindert, dass der Nutzer WLAN-Tethering verwendet. Diese Einstellung wird auf unternehmenseigenen Geräten mit Android 13 und höher unterstützt.",
    "Prevents all forms of tethering.": "Verhindert alle Formen von Tethering.",
    "Tethering Settings": "Tethering Einstellungen",
    "This policy defines the extent to which the user may use various forms of tethering (e.g. Wi-Fi tethering or Bluetooth tethering).":
        "Mit dieser Richtlinie wird festgelegt, inwieweit der Nutzer verschiedene Formen des Tetherings (z. B. WLAN-Tethering oder Bluetooth-Tethering) verwenden darf.",
    "Not specified.": "Nicht spezifiziert.",
    "Disallow Wi-Fi tethering": "WLAN Tethering verbieten",
    "Disallow all tethering": "Tethering verbieten",
    "Prevents the configuration of Wi-Fi networks.": "Verhindert die Konfiguration von WLANs.",
    "Dissallow Configuring Wi-Fi": "WLAN Konfiguration nicht zulassen",
    "Disallow Add Wi-Fi Config": "WLAN Konfiguration hinzufügen verbieten",
    "Adding new WLAN configurations is not permitted. The user can only switch between already configured networks. This setting is supported on company-owned devices with Android 13 and higher.":
        "Das Hinzufügen neuer WLAN-Konfigurationen ist nicht zulässig. Der Nutzer kann nur zwischen bereits konfigurierten Netzwerken wechseln. Diese Einstellung wird auf unternehmenseigenen Geräten mit Android 13 und höher unterstützt.",
    "The user may configure the Wi-Fi.": "Der Nutzer darf das WLAN konfigurieren.",
    "Configure Wi-Fi": "WLAN Konfigurieren",
    "Defines the permissions for the Wi-Fi configuration. Depending on the option selected, the user can control the Wi-Fi configuration either completely, only to a limited extent or not at all.":
        "Legt die Berechtigungen für die WLAN-Konfiguration fest. Je nach ausgewählter Option kann der Nutzer die WLAN-Konfiguration entweder vollständig, nur eingeschränkt oder gar nicht steuern.",
    "USB data access": "USB-Datenzugriff",
    "Start a cloud scan": "Cloud-Scan starten",
    "Add Core-UTM": "Core-UTM hinzufügen",
    "Add satellite": "Satelliten hinzufügen",
    "Add a satellite": "Einen Satelliten hinzufügen",
    "Select an UTM": "Wählen Sie eine UTM aus",
    "No UTM available": "Keine UTM verfügbar",
    "Select a node": "Wählen Sie eine Node aus",
    "Select a service": "Wählen Sie einen Dienst aus",
    "Scroll back to top": "Zurück nach oben scrollen",
    "License expired": "Lizenz abgelaufen",
    "Missing rules": "Keine Regeln",
    "Kernel version": "Version des Kernel",
    "Policy version": "Version der Richtlinie",
    "Android version": "Android Version",
    "Update info": "Aktualisierungsinfo",
    "up to date": "Auf dem neuesten Stand",
    "Online UTMs": "Verbundene UTMs",
    "Offline UTMs": "Getrennte UTMs",
    "The UTM cannot be changed retrospectively.":
        "Die UTM kann nicht nachträglich geändert werden.",
    "Transfer network": "Transfernetz",
    "IPv4 or IPv6 address to be used as the transfer network.":
        "IPv4- oder IPv6-Adresse, die als Transfernetz verwendet werden soll.",
    "IPv4 or IPv6 address to be used as the transfer network or addresspool.":
        "IPv4- oder IPv6-Adresse, die als Transfernetz oder Adresspool verwendet werden soll.",
    "A license conflict occurs when the same license is installed on several UTMs. In addition to billing problems, this can lead to unpredictable side effects and incorrect configurations. Make sure that a unique license is installed on each UTM to ensure stable and reliable operation of the UTM.":
        "Ein Lizenzkonflikt entsteht, wenn dieselbe Lizenz auf mehreren UTMs installiert ist. Neben Abrechnungsproblemen kann dies zu unvorhersehbaren Seiteneffekten und fehlerhaften Konfigurationen führen. Stellen Sie sicher, dass auf jeder UTM eine eindeutige Lizenz installiert ist, um einen stabilen und zuverlässigen Betrieb der UTM zu gewährleisten.",
    "This UTM requires a PIN for authentication via the websession function.":
        "Diese UTM erfordert eine PIN zur Authentifizierung über die Websession-Funktion.",
    "If the PIN is entered incorrectly several times (default value 5, unless otherwise configured on the UTM), login is only possible via user name and password until the PIN functionality is reactivated.":
        "Nach mehrmaliger Falscheingabe der PIN (Standardwert 5, wenn nicht auf der UTM anders konfiguriert) ist ein Login nur noch über Benutzername und Passwort möglich, bis die PIN-Funktionalität wieder freigeschaltet wird.",
    "This authentication is mandatory from UTM version 12.5.0. The initial PIN is 000000 and must be changed after the first login.":
        "Diese Authentifizierung ist ab UTM-Version 12.5.0 obligatorisch. Die anfängliche PIN lautet 000000 und muss nach der ersten Anmeldung geändert werden.",
    "The specified transfer network overlaps with existing network ranges.":
        "Das angegebene Transfernetz überschneidet sich mit bestehenden Netzbereichen.",
    "The specified address does not match an ipv4 or ipv6 address.":
        "Die angegebene Adresse stimmt nicht mit einer ipv4- oder ipv6-Adresse überein.",
    "Enter a network mask between /24 and /30.":
        "Geben Sie eine Netzwerkmaske zwischen /24 und /30 ein.",
    "Enter a network mask between /120 and /126.":
        "Geben Sie eine Netzwerkmaske zwischen /120 und /126 ein.",
    "Missing network mask.": "Fehlende Netzwerkmaske.",
    "Operating system": "Betriebssystem",
    "Show expired API Keys": "Abgelaufene API Keys anzeigen",
    "Hide expired API Keys": "Abgelaufene API Keys ausblenden",
    "Dynamic DNS": "Dynamisches DNS",
    Service: "Dienst",
    "Revert changes": "Änderungen verwerfen",
    "Edit Core-UTM": "Core-UTM bearbeiten",
    "Edit rule": "Regel bearbeiten",
    "Update rule": "Regel aktualisieren",
    "Update UTM": "UTM aktualisieren",
    "Please select a network object": "Bitte wählen Sie ein Netzwerkobjekt aus",
    "Publish state": "Veröffentlichungsstatus",
    "Not published": "Nicht veröffentlicht",
    "Add a rule": "Eine Regel hinzufügen",
    "Enter a private IP address.": "Geben Sie eine private IP-Adresse ein.",
    "Switch source and destination": "Quelle und Ziel wechseln",
    "Do you really want to mark this satellite's connection to the core UTM for deletion?":
        "Wollen Sie die Verbindung dieses Satelliten zur Core-UTM wirklich zum Löschen markieren?",
    Configuration: "Konfiguration",
    Table: "Tabelle",
    Output: "Ausgabe",
    Graph: "Graph",
    "Revert not published changes": "Nicht veröffentlichte Änderungen verwerfen",
    "Missing an UTM that can used as a satellite. Please make sure that your UTMs are up-to-date and connected to the Unified Security Console in order to configure them.":
        "Fehlen einer UTM, welche als Satellit verwendet werden kann. Bitte stellen Sie sicher, dass Ihre UTMs auf dem neuesten Stand sind und mit der Unified Security Console verbunden sind, um sie zu konfigurieren.",
    "Currently only one UTM can be added.": "Derzeit kann nur eine UTM hinzugefügt werden.",
    "The Port that is used by the VPN service.":
        "Der Port, welcher von dem VPN-Dienst verwendet wird.",
    "Satellite-UTM": "Satelliten-UTM",
    "Satellite / Roadwarrior": "Satellit / Roadwarrior",
    "No summary available.": "Kein Zusammenfassung verfügbar.",
    "It is possible to connect new VPN clients to the Unified Security Portal via Email by sending an invitation Email.":
        "Es besteht die Möglichkeit, neue VPN Clients per E-Mail mit dem Unified Security Portal zu verbinden, indem eine Einladungs-E-Mail versendet wird.",
    "This invitation email contains a link to log the installed Securepoint VPN Client onto the portal.":
        "In dieser Einladungs-E-Mail befindet sich ein Link zur Anmeldung des installierten Securepoint VPN Clients am Portal.",
    "After clicking on the link, the VPN Client is opened, logged in and is then available in the Unified Security Portal.":
        "Nach einem Klick auf den Link wird der VPN Client geöffnet, angemeldet und steht anschließend im Unified Security Portal zur Verfügung.",
    "Select the Enrollmenttoken that is to be used to register the VPN client. Make sure that the token has a sufficient validity period.":
        "Wählen Sie das Entrollmenttoken aus, das für die Registrierung des VPN-Clients verwendet werden soll. Achten Sie darauf, dass das Token eine ausreichende Gültigkeitsdauer hat.",
    "Email-Recipients": "E-Mail-Empfänger",
    "Please select the Email addresses to which the Enrollmenttoken for enrolling on the portal should be sent by Email.":
        "Bitte wählen Sie die E-Mail-Adressen aus, an die das Registrierungs-Token für die Anmeldung am Portal per E-Mail gesendet werden soll.",
    "Enroll VPN clients to the portal via Email invitation":
        "VPN-Clients am Portal via E-Mail-Einladung registrieren",
    "Enroll new VPN Client": "Neuen VPN Client enrollen",
    "Getting Users...": "Hole Benutzer...",
    "Getting SecureDns Profiles...": "Hole SecureDns Profile...",
    "Getting Enrollmenttoken...": "Hole Enrollmenttoken...",
    "local time of the UTM from 12.6.2.": "lokale Zeit der UTM ab 12.6.2.",
    "corresponds to %s your local time": "entspricht %s Uhr Ihrer Ortszeit",
    "UTC for UTMs up to 12.6.1.": "UTC für UTMs bis 12.6.1.",
    "Delete Core-UTM": "Core-UTM löschen",
    "Deleting the core UTM removes the entire configuration from your UTMs.":
        "Durch das Löschen der Core-UTM wird die gesamte Konfiguration von Ihren UTMs entfernt.",
    "Please note that this setting is published directly so that a clean reconfiguration can be started and you do not have the option of restoring the current status.":
        "Bitte beachten Sie, dass diese Einstellung direkt veröffentlicht wird, damit eine saubere Neukonfiguration begonnen werden kann und Sie keine Möglichkeit haben, den aktuellen Zustand wiederherzustellen.",
    'To retain the current configuration, please select "<strong>Revert changes</strong>".':
        'Für den Erhalt der aktuellen Konfiguration wählen Sie bitte "<strong>Änderungen verwerfen</strong>".',
    'To accept the deletion, please click on "<strong>Delete</srong>".':
        'Zur Übernahme der Löschung klicken Sie bitte auf "<strong>Löschen</strong>".',
    "To increase security, please enter the PIN of the Core-UTM.":
        "Um die Sicherheit zu erhöhen, geben Sie dafür bitte den PIN der Core-UTM ein.",
    "Unpublished changes": "Unveröffentlichte Änderungen",
    "Delete UTM": "UTM löschen",
    "Last user": "Letzter Benutzer",
    "You still have unpublished changes. Please switch to the Unified Network Console to check your changes and publish them if necessary.":
        "Sie haben noch unveröffentlichte Änderungen. Bitte wechseln Sie zur Unified Network Console um Ihre Änderungen zu überprüfen und ggf. zu veröffentlichen.",
    "The currently configured connections of the VPN client are displayed here.":
        "Hier werden die aktuell konfigurierten Verbindungen des VPN-Clients angezeigt.",
    "In addition to the current settings and the status of these connections, the status at the time of the query is also displayed.":
        "Neben den aktuellen Einstellungen und dem Status dieser Verbindungen wird auch der Status zum Zeitpunkt der Abfrage angezeigt.",
    "The connections are sent automatically when the VPN client is opened and can be queried again if required.":
        "Die Verbindungen werden beim Öffnen des VPN-Clients automatisch gesendet und können bei Bedarf erneut abgefragt werden.",
    "Last Contact:": "Letzter Kontakt:",
    "Switch to config": "Zur Konfiguration wechseln",
    "Destination Port": "Ziel Port",

    //Apple DEP Profile Translations
    Accessibility: "Barrierefreiheit",
    Appearance: "Erscheinungsbild",
    "Device To Device Migration": "Gerät-zu-Gerät-Migration",
    Diagnostics: "Diagnose",
    "Display Tone (Deprecated in iOS 15)": "Anzeigeton (Veraltet in iOS 15)",
    "Homebutton Sensitivity (Deprecated in iOS 15)":
        "Homebutton-Empfindlichkeit (Veraltet in iOS 15)",
    "iMessage and FaceTime": "iMessage und FaceTime",
    Location: "Standort",
    "Messaging Activation": "Messaging-Aktivierung",
    "OnBoarding (Deprecated in iOS 14)": "OnBoarding (Veraltet in iOS 14)",
    "Restore Completed": "Wiederherstellung abgeschlossen",
    Safety: "Sicherheit",
    "ScreenSaver (tvOS)": "Bildschirmschoner (tvOS)",
    ScreenTime: "Bildschirmzeit",
    "SIM Setup": "SIM-Einrichtung",
    "Terms and Conditions": "Nutzungsbedingungen",
    "TV HomeScreen Sync (tvOS)": "TV-Startbildschirm-Synchronisierung (tvOS)",
    "TV Provider SignIn (tvOS)": "TV-Anbieter-Anmeldung (tvOS)",
    "Update Completed": "Aktualisierung abgeschlossen",
    Welcome: "Willkommen",
    "Zoom (Deprecated in iOS 17)": "Zoom (Veraltet in iOS 17)",
    "iMessage Activation": "iMessage-Aktivierung",

    "Publish configuration": "Konfiguration veröffentlichen",
    "Discard changes": "Änderungen verwerfen",
    Discard: "Verwerfen",
    Discarded: "Verworfen",
    "Do you really want to discard the changes on this configuration?":
        "Möchten Sie die Änderungen an dieser Konfiguration wirklich verwerfen?",
    "Add UTM": "UTM hinzufügen",
    "Service Groups": "Dienstgruppen",
    "Service Group": "Dienstgruppe",
    "Source Ports": "Quellports",
    "Destination Ports": "Zielports",
    "Protocol type": "Protokolltyp",
    "Single Port": "Einzelner Port",
    "Port Range": "Port-Bereich",
    "Port range": "Port-Bereich",
    all: "Alle",
    "Source port type": "Zielport Typ",
    "Source port end": "Zielport Ende",
    "Source port start": "Zielport Start",
    "Source port": "Zielport",
    "Destination port": "Quellport",
    "Destination port type": "Quellport Typ",
    "Destination port start": "Quellport Start",
    "Destination port end": "Quellport Ende",
    "Add to network group": "Zu Netzwerkgruppe hinzufügen",
    "Adds service to an existing service group or creates a new one":
        "Fügt den Dienst zu einer bestehenden Dienstgruppe hinzu oder erstellt eine neue Gruppe",
    "Service name": "Name des Dienstes",
    "Select a contract helper": "Conrtract Helper auswählen",
    "Network group name": "Netzwerkgruppenname",
    "Select a protocol": "Protokoll auswählen",
    "Select an UTM that will connect to the Core-UTM via VPN.":
        "Wählen Sie eine UTM aus, die sich über VPN mit der Core-UTM verbinden soll.",
    "The changes will be assigned to the UTMs as soon as the settings are published.":
        "Die Änderungen werden den UTMs zugewiesen, sobald die Einstellungen veröffentlicht sind.",
    "Delete Rule": "Regel löschen",
    "Do you really want to delete this rule?": "Möchten Sie diese Regel wirklich löschen?",
    Publishing: "Veröffentliche",
    "Categories #": "Kategorien #",
    "Add Service": "Dienst hinzufügen",
    "Network (address with custom mask)": "Netzwerk (Adresse mit beliebiger Subnetzmaske)",
    "Network (interface)": "Netzwerk (Schnittstelle)",
    "Network (address)": "Netzwerk (Adresse)",
    "VPN host": "VPN-Host",
    "VPN network": "VPN-Netzwerk",
    "Dynamic Interface": "Dynamische Schnittstelle",
    "Static interface": "Statische Schnittstelle",
    "Hostname/Public IP address": "Hostname/Öffentliche IP-Adresse",
    Unavailable: "Nicht verfügbar",
    "Getting Node Information...": "Hole Node Informationen...",
    "Please chose unique identifiers for name and service group name.":
        "Bitte wählen Sie eindeutige Bezeichnungen für den Namen und den Dienstgruppennamen,",
    "Some of the provided names collide with the reserved names of the UTM.":
        "Einige der angegebenen Namen kollidieren mit den reservierten Namen der UTM.",
    "Service group name": "Dienstgruppenname",
    "Some of the provided names contain backslashes or whitespaces.":
        "Einige der angegebenen Namen enthalten Backslashes oder Leerzeichen.",
    "An unknown error occurred while adding the service. Please try again in a short time or change the input values.":
        "Beim Hinzufügen des Dienstes ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es in kurzer Zeit erneut oder ändern Sie die Eingabewerte.",
    "An unknown error occurred while adding the network object. Please try again in a short time or change the input values.":
        "Beim Hinzufügen des Netzwerkobjekts ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es in kurzer Zeit erneut oder ändern Sie die Eingabewerte.",
    "End of servicing": "Support abgelaufen",
    "Support for the current Windows build version has expired":
        "Die Unterstützung für die aktuelle Windows-Build-Version ist abgelaufen",
    "Add Satellite / Roadwarrior": "Satellit / Roadwarrior hinzufügen",
    "Source ports": "Quell-Ports",
    "Destination ports": "Ziel-Ports",
    "There are currently no connections for this VPN":
        "Derzeit existieren keine Verbindungen für dieses VPN",
    Diagnosis: "Diagnose",
    "Page Redirection": "Weiterleitung",
    "If you are not redirected automatically in a few seconds, follow this":
        "Wenn Sie nicht automatisch innerhalb weniger Sekunden weitergeleitet werden, folgen Sie diesem ",
    "The link provided is not valid. You will be redirected to the Dashboard":
        "Der angegebene Link ist ungültig. Sie werden zum Dashboard weitergeleitet",
    "Change Password": "Passwort ändern",
    "Enroll Code": "Code einschreiben",
    "Subscribe to Email": "E-Mail abonnieren",
    "Unsubscribe from Email": "E-Mail abbestellen",
    "Your email address": "Ihre E-Mail-Adresse",
    "is registered to receive an Unified Security Report (USR). To remove your email address click the button below.":
        "ist für das Erhalten des Unified Security Reports (USR) registriert. Sie können ihre E-Mail-Adresse durch Anklicken des Buttons entfernen.",
    "was registered to receive an Unified Security Report (USR). Please confirm your email address by clicking the button below.":
        "wurde für das Erhalten des Unified Security Reports (USR) registriert. Bitte bestätigen Sie Ihre E-Mail-Adresse durch Anklicken des Buttons.",
    "Oops, something went wrong...": "Ups, etwas lief schief...",
    "I don't want to receive any further USR": "Ich möchte keine weiteren USR erhalten",
    "I agree and like to receive the USR": "Ich stimme zu und möchte den USR erhalten",
    "Thank you. Your email address has been removed.":
        "Vielen Dank. Ihre E-Mail-Adresse wurde entfernt.",
    "Thank you. Your email address has been confirmed.":
        "Vielen Dank. Ihre E-Mail-Adresse wurde bestätigt.",
    "Remove subscription": "Abonnement entfernen",
    "Confirm subscription": "Abonnement bestätigen",
    "Subscription status unavailable": "Abonnement-Status nicht verfügbar",
    "Oops, this link has expired!": "Hoppla, dieser Link ist abgelaufen!",
    "Here you can set a new password for the Securepoint Unified Security user $1.":
        "Hier können Sie ein neues Passwort für den Securepoint Unified Security Benutzer $1 festlegen.",
    "New Password:": "Neues Passwort:",
    "Confirm New Password:": "Neues Passwort bestätigen:",
    "Ensure that the password is at least 5 characters long and does not contain any spaces.":
        "Stellen Sie sicher, dass das Passwort mindestens 5 Zeichen lang ist und keine Leerzeichen enthält.",
    "Please enter a password!": "Bitte geben Sie ein Passwort ein!",
    "The password must be at least 5 characters long and must not contain any spaces!":
        "Das Passwort muss mindestens 5 Zeichen lang sein und darf keine Leerzeichen enthalten!",
    "The two passwords do not match!": "Die beiden Passwörter stimmen nicht überein!",
    "Your password has been changed": "Ihr Passwort wurde geändert",
    "Two-factor authentication (2FA) is activated for your account.":
        "Für Ihr Konto ist die Zwei-Faktor-Authentifizierung (2FA) aktiviert.",
    "This is a requirement for registration on the website.":
        "Dies ist eine Voraussetzung für die Anmeldung auf der Website.",
    "If you would like to set up 2FA now, please scan the following QR code with an authenticator app such as Google Authenticator or Microsoft Authenticator.":
        "Wenn Sie 2FA jetzt einrichten möchten, scannen Sie bitte den folgenden QR-Code mit einer Authenticator-App wie dem Google Authenticator oder Microsoft Authenticator.",
    "If 2FA has already been set up correctly, you can close this window.":
        "Wenn 2FA bereits korrekt eingerichtet wurde, können Sie dieses Fenster schließen.",
    "Your setup key:": "Ihr Einrichtungsschlüssel ist:",
    "Transfer the secret to your authenticator and then enter a generated code in the field below and click on Send. Otherwise, the secret will be discarded and the old secret will remain valid.":
        "Übernehmen Sie das Secret in Ihren Authenticator und geben Sie anschließend einen generierten Code in das unterliegende Feld ein und klicken Sie auf Senden. Andernfalls wird das Secret verworfen und das alte Secret bleibt gültig.",
    "Please click": "Bitte klicken Sie",
    "to go to the portal.": "um zum Portal zu gelangen.",
    "The code entered is invalid!": "Der eingegebene Code ist ungültig!",
    "The user was not found!": "Der Benutzer wurde nicht gefunden!",
    "The link has expired!": "Der Link ist abgelaufen!",
    "An error has occurred!": "Ein Fehler ist aufgetreten!",
    "You can now log in with the tenant": "Sie können sich jetzt anmelden mit dem Mandanten",
    "and username": "und Benutzernamen",
    "Set/Change password for": "Passwort setzen/ändern für",
    "Enter code": "Code eingeben",
    "Please enter the code, which has been provided by the IT admin":
        "Bitte geben Sie den von der IT-Administration bereitgestellten Code ein",
    "Active since": "Aktiv seit",
    "Adds a network object to an existing network group or creates a new group":
        "Fügt ein Netzwerkobjekt zu einer bestehenden Netzwerkgruppe hinzu oder erstellt eine neue Gruppe",
    "The specified address must be an ipv4 or ipv6 address.":
        "Die angegebene Adresse muss eine ipv4- oder ipv6-Adresse sein.",
    "This value must not have a cidr": "Dieser Wert darf keine Netzwerkmaske haben",
    Answer: "Antwort",
    "Remember PIN for the current session": "PIN für die aktuelle Sitzung merken",
    "Enable Logs": "Protokolle aktivieren",
    "Activates the recording of DNS logs. This setting is required for evaluating the statistics.":
        "Aktiviert die Aufzeichnung von DNS-Protokollen. Diese Einstellung wird für die Auswertung der Statistiken benötigt.",
    Logs: "Protokolle",
    "If this setting is set, domain names are saved for the statistics and logs. Otherwise, a placeholder is displayed.":
        "Wenn diese Einstellung gesetzt ist, werden Domain-Namen für die Statistiken und Protokolle gespeichert. Andernfalls wird ein Platzhalter angezeigt.",
    "If this setting is set, the device names for the statistics and logs are saved regardless of the client setting.":
        "Wenn diese Einstellung gesetzt ist, werden die Gerätenamen für die Statistiken und Protokolle unabhängig von der Client-Einstellung gespeichert.",
    "Activate recording of the client name.": "Aktiviert die Protokollierung des Gerätenamens.",
    "Do you really want to delete this enrollmenttoken?":
        "Wollen Sie diesen Entrollmenttoken wirklich löschen?",
    "Do you really want to delete this vpn client?":
        "Wollen Sie diesen VPN Client wirklich löschen?",
    "Request System Info": "Systeminfo anfragen",
    "Request Connection Info": "Verbindungsinfo anfragen",
    "IDN Homograph Attacks Protection": "IDN Homographischer Angriffs-Schutz",
    "Block domains that impersonate other domains by abusing the large character set provided with the introduction of Internationalized Domain Names (IDNs). With this option, only domains with ascii characters are allowed.":
        "Blockieren Sie Domains, die sich als andere Domains ausgeben, indem sie den großen Zeichensatz missbrauchen, der mit der Einführung von Internationalized Domain Names (IDNs) zur Verfügung steht. Mit dieser Option sind nur Domains mit ASCII-Zeichen zulässig.",
    "Continue editing": "Weiter bearbeiten",
    "You still have unpublished changes. Please check your changes and publish them if necessary.":
        "Sie haben noch unveröffentlichte Änderungen. Bitte überprüfen Sie Ihre Änderungen und veröffentlichen Sie diese gegebenenfalls.",
    "Only blocked requests": "Nur blockierte Anfragen",
    "Block cloaked third-party trackers": "Blockieren von getarnten Drittanbieter-Trackern",
    "Detect and block third-party trackers that masquerade as first-party vendors. This attack is also known as CNAME cloaking.":
        "Erkennen und blockieren Sie Drittanbieter-Tracker, die sich als Erstanbieter tarnen. Dieser Angriff wird auch als CNAME-Cloaking bezeichnet.",
    "Client activity": "Geräteaktivität",
    "Top Permitted Domains": "Top erlaubte Domains",
    "Top Blocked Domains": "Top blockierte Domains",
    "Top Domains": "Top Domains",
    "Top Block Reasons": "Top Blockierungsgründe",
    "Top Clients": "Top Geräte",
    "Top Request Types": "Top Anfragetypen",
    "Permitted domains": "Erlaubte Domains",
    blocked: "blockiert",
    "Total Requests": "Anzahl Anfragen",
    "Content Filter": "Content-Filter",
    "Domain Blocklist": "Domain-Blockierliste",
    "IP Blocklist": "IP-Blockierliste",
    "DNS Rebind Protection": "Schutz vor DNS-Rebind",
    "IDN Homograph Attacks": "Schutz vor homographischen Angriffen",
    "Cloaked third-party tracker": "Getarnter Drittanbieter-Tracker",
    "Prevent attackers from taking control of your local devices over the Internet by automatically blocking DNS responses that contain private IP addresses. Both private IPv4 and private IPv6 addresses are blocked.":
        "Verhindern Sie, dass Angreifer die Kontrolle über Ihre lokalen Geräte über das Internet übernehmen, indem Sie DNS-Antworten, die private IP-Adressen enthalten, automatisch blockieren. Es werden sowohl private IPv4- als auch private IPv6-Adressen blockiert.",
    "Filter results on all major search engines, including images and videos.":
        "Filtern Sie die Ergebnisse in allen wichtigen Suchmaschinen, einschließlich Bilder und Videos.",
    "Filter adult content on YouTube. This will also hide all comments.":
        "Filtern Sie Inhalte für Erwachsene auf YouTube. Dadurch werden auch alle Kommentare ausgeblendet.",
    "Set or overwrite the DNS response for any domain with a custom IP address.":
        "Definieren oder überschreiben Sie die DNS-Antwort für eine beliebige Domain. Als Antwort kann eine beliebige IP-Adresse verwendet werden.",
    "Set or overwrite the DNS response for any domain with a custom CNAME domain.":
        "Definieren oder überschreiben Sie die DNS-Antwort für eine beliebige Domain. Als Antwort kann eine beliebige andere Domain verwendet werden.",
    "Reduces the number of dns queries to the DNS server by setting the minimum TTL (Time-To-Live) for each query to 5 minutes. Larger TTLs are not overwritten.":
        "Reduziert die Anzahl der DNS-Anfragen, indem eine minimale TTL (Time-To-Live) von 5 Minuten erzwungen wird. Höhere TTLs werden nicht überschrieben.",
    "Log Domains": "Domains protokollieren",
    "Log Client Names": "Gerätenamen protokollieren",
    "YouTube Restricted Mode": "Eingeschränkter YouTube-Modus",
    "DNS Rebinding Protection": "DNS-Rebinding-Schutz",
    "Allowed IPs": "Erlaubte IPs",
    "Blocked IPs": "Blockierte IPs",
    "Allowed Hosts": "Erlaubte Hosts",
    "Allowed hosts": "Erlaubte Hosts",
    "Blocked Hosts": "Blockierte Hosts",
    "Blocked hosts": "Blockierte Hosts",
    "Enter text to add IPs": "Text eingeben, um IPs hinzuzufügen",
    "Enter text to add domains": "Text eingeben, um Domains hinzuzufügen",
    "Endpoint successfully copied": "Endpunkt erfolgreich kopiert",
    "Setup key copied to clipboard!": "Einrichtungsschlüssel in die Zwischenablage kopiert!",
    "Failed to copy setup key. Please try again.":
        "Der Einrichtungsschlüssel konnte nicht kopiert werden. Bitte versuchen Sie es erneut.",
    "Please enter the PIN to log in to the MDM.":
        "Bitte geben Sie die PIN ein, um sich am MDM anzumelden.",
    "Invalid PIN": "Ungültiger PIN",
    "Login to the MDM is PIN-protected.": "Die Anmeldung am MDM ist PIN-geschützt.",
    "If you do not know the PIN, please contact your administrator.":
        "Wenn Sie die PIN nicht kennen, wenden Sie sich bitte an Ihren Administrator.",
    "Drop UTM license (.pem) here": "UTM-Lizenz (.pem) hier per Drag & Drop ablegen oder klicken",
    "Zero Touch Profiles": "Zero-Touch-Profile",
    "Zero Touch Profile": "Zero-Touch-Profil",
    "Zero Touch Profile Details": "Zero-Touch-Profil-Details",
    "Add Zero Touch Profile": "Zero-Touch-Profil hinzufügen",
    "Edit Zero Touch Profile": "Zero-Touch-Profil bearbeiten",
    "A profile with this combination of serial number and pin already exists.":
        "Ein Profil mit dieser Kombination aus Seriennummer und Pin existiert bereits.",
    "This UTM license is not valid.": "Diese UTM-Lizenz ist nicht gültig.",
    "Name of the profile.": "Name des Profils.",
    "Serial number of the UTM": "Seriennummer der UTM",
    "Enrollment PIN": "Enrollment-PIN",
    "Enrollment PIN of the UTM": "Enrollment-PIN der UTM",
    "License of the UTM.": "Lizenz der UTM.",
    "Select a license": "Lizenz auswählen",
    "Block page": "Blockseite",
    "Display a block page as soon as a domain is blocked. In some cases, an HTTPS warning may be displayed. Remove the HTTPS warning by installing the Securepoint Certificate Authority from <a href='https://portal-dev.securepoint.cloud/sms-mgt-api/api/2.0/secure-dns/ca'>here</a>.":
        "Zeigen Sie eine Blockseite an, sobald eine Domain gesperrt wird. In einigen Fällen kann eine HTTPS-Warnung angezeigt werden. Entfernen Sie die HTTPS-Warnung, indem Sie die Securepoint-Zertifizierungsstelle von <a href='https://portal-dev.securepoint.cloud/sms-mgt-api/api/2.0/secure-dns/ca'>hier</a> herunterladen und installieren.",
    "Add IP Rewrite": "IP-Rewrite hinzufügen",
    "Add CNAME Rewrite": "CNAME-Rewrite hinzufügen",
    "Top Profiles": "Top Profile",
    Logarithmic: "Logarithmisch",
    Linear: "Linear",
    "Your input contains combinations of characters that may result in a different output in the statistics.":
        "Ihre Eingabe enthält Zeichenkombinationen, welche dazu führen können, dass die Ausgabe in den Statistiken von Ihrer Eingabe abweicht.",
    "Expiry date must not be in the past.":
        "Das Ablaufdatum darf nicht in der Vergangenheit liegen.",
    "Expiry date must not be before the start date.":
        "Das Ablaufdatum darf nicht vor dem Startdatum liegen",
    "Do you really want to delete this zero-touch profile?":
        "Möchten Sie dieses Zero-Touch-Profil wirklich löschen?",
    "Select a name": "Namen auswählen",
    "Select a seral number": "Seriennummer auswählen",
    "Select a PIN": "PIN auswählen",
    "IP address with subnet mask": "IP Adresse mit Subnetzmaske",

    //all countries
    "Faroe Islands": "Färöer-Inseln",
    "United States Minor Outlying Islands": "Kleinere abgelegene Inseln der Vereinigten Staaten",
    "United States of America": "Vereinigte Staaten von Amerika",
    Japan: "Japan",
    Seychelles: "Seychellen",
    India: "Indien",
    France: "Frankreich",
    "Federated States of Micronesia": "Föderierte Staaten von Mikronesien",
    China: "China",
    Portugal: "Portugal",
    "Serranilla Bank": "Serranilla-Bank",
    "Scarborough Reef": "Scarborough-Riff",
    Brazil: "Brasilien",
    Kiribati: "Kiribati",
    Philippines: "Philippinen",
    Mexico: "Mexiko",
    Spain: "Spanien",
    "Bajo Nuevo Bank (Petrel Is.)": "Bajo-Nuevo-Bank (Petrelinseln)",
    Maldives: "Malediven",
    "Spratly Islands": "Spratly-Inseln",
    "United Kingdom": "Vereinigtes Königreich",
    Greece: "Griechenland",
    "American Samoa": "Amerikanisch-Samoa",
    Denmark: "Dänemark",
    Greenland: "Grönland",
    Guam: "Guam",
    "Northern Mariana Islands": "Nördliche Marianen",
    "Puerto Rico": "Puerto Rico",
    "United States Virgin Islands": "Amerikanische Jungferninseln",
    Canada: "Kanada",
    "Sao Tome and Principe": "São Tomé und Príncipe",
    "Cape Verde": "Kap Verde",
    Dominica: "Dominica",
    Netherlands: "Niederlande",
    Jamaica: "Jamaika",
    Samoa: "Samoa",
    Oman: "Oman",
    "Saint Vincent and the Grenadines": "St. Vincent und die Grenadinen",
    Turkey: "Türkei",
    Bangladesh: "Bangladesch",
    "Saint Lucia": "St. Lucia",
    Nauru: "Nauru",
    Norway: "Norwegen",
    "Saint Kitts and Nevis": "St. Kitts und Nevis",
    Bahrain: "Bahrain",
    Tonga: "Tonga",
    Finland: "Finnland",
    Indonesia: "Indonesien",
    Mauritius: "Mauritius",
    Sweden: "Schweden",
    "Trinidad and Tobago": "Trinidad und Tobago",
    Malaysia: "Malaysia",
    Panama: "Panama",
    Palau: "Palau",
    Tuvalu: "Tuvalu",
    "Marshall Islands": "Marshallinseln",
    Chile: "Chile",
    Thailand: "Thailand",
    Grenada: "Grenada",
    Estonia: "Estland",
    "Antigua and Barbuda": "Antigua und Barbuda",
    Taiwan: "Taiwan",
    Barbados: "Barbados",
    Italy: "Italien",
    Malta: "Malta",
    Vanuatu: "Vanuatu",
    Singapore: "Singapur",
    Cyprus: "Zypern",
    "Sri Lanka": "Sri Lanka",
    Comoros: "Komoren",
    Fiji: "Fidschi",
    Russia: "Russland",
    Vatican: "Vatikanstadt",
    "San Marino": "San Marino",
    Kazakhstan: "Kasachstan",
    Azerbaijan: "Aserbaidschan",
    Tajikistan: "Tadschikistan",
    Lesotho: "Lesotho",
    Uzbekistan: "Usbekistan",
    Morocco: "Marokko",
    Colombia: "Kolumbien",
    "East Timor": "Osttimor",
    "United Republic of Tanzania": "Vereinigte Republik Tansania",
    Argentina: "Argentinien",
    "Saudi Arabia": "Saudi-Arabien",
    Pakistan: "Pakistan",
    Yemen: "Jemen",
    "United Arab Emirates": "Vereinigte Arabische Emirate",
    Kenya: "Kenia",
    Peru: "Peru",
    "Dominican Republic": "Dominikanische Republik",
    Haiti: "Haiti",
    "Papua New Guinea": "Papua-Neuguinea",
    Angola: "Angola",
    Cambodia: "Kambodscha",
    Vietnam: "Vietnam",
    Mozambique: "Mosambik",
    "Costa Rica": "Costa Rica",
    Benin: "Benin",
    Nigeria: "Nigeria",
    Iran: "Iran",
    "El Salvador": "El Salvador",
    "Sierra Leone": "Sierra Leone",
    "Guinea Bissau": "Guinea-Bissau",
    Croatia: "Kroatien",
    Belize: "Belize",
    "South Africa": "Südafrika",
    "Central African Republic": "Zentralafrikanische Republik",
    Sudan: "Sudan",
    "Democratic Republic of the Congo": "Demokratische Republik Kongo",
    Kuwait: "Kuwait",
    Germany: "Deutschland",
    Belgium: "Belgien",
    Ireland: "Irland",
    "North Korea": "Nordkorea",
    "South Korea": "Südkorea",
    Guyana: "Guyana",
    Honduras: "Honduras",
    Myanmar: "Myanmar",
    Gabon: "Gabun",
    "Equatorial Guinea": "Äquatorialguinea",
    Nicaragua: "Nicaragua",
    Latvia: "Lettland",
    Uganda: "Uganda",
    Malawi: "Malawi",
    Armenia: "Armenien",
    Somaliland: "Somaliland",
    Turkmenistan: "Turkmenistan",
    Zambia: "Sambia",
    "Northern Cyprus": "Nordzypern",
    Mauritania: "Mauretanien",
    Algeria: "Algerien",
    Lithuania: "Litauen",
    Ethiopia: "Äthiopien",
    Eritrea: "Eritrea",
    Ghana: "Ghana",
    Slovenia: "Slowenien",
    Guatemala: "Guatemala",
    "Bosnia and Herzegovina": "Bosnien und Herzegowina",
    Jordan: "Jordanien",
    Syria: "Syrien",
    Monaco: "Monaco",
    Albania: "Albanien",
    Uruguay: "Uruguay",
    "Cyprus No Mans Area": "Zypern Niemandsland",
    Mongolia: "Mongolei",
    Rwanda: "Ruanda",
    Somalia: "Somalia",
    Bolivia: "Bolivien",
    Cameroon: "Kamerun",
    "Republic of Congo": "Republik Kongo",
    "Western Sahara": "Westsahara",
    "Republic of Serbia": "Republik Serbien",
    Montenegro: "Montenegro",
    Togo: "Togo",
    Laos: "Laos",
    Afghanistan: "Afghanistan",
    Ukraine: "Ukraine",
    Slovakia: "Slowakei",
    "Siachen Glacier": "Siachen-Gletscher",
    Bulgaria: "Bulgarien",
    Qatar: "Katar",
    Liechtenstein: "Liechtenstein",
    Austria: "Österreich",
    Eswatini: "Eswatini",
    Hungary: "Ungarn",
    Romania: "Rumänien",
    Niger: "Niger",
    Luxembourg: "Luxemburg",
    Andorra: "Andorra",
    "Ivory Coast": "Elfenbeinküste",
    Liberia: "Liberia",
    Brunei: "Brunei",
    Iraq: "Irak",
    Georgia: "Georgien",
    Gambia: "Gambia",
    Switzerland: "Schweiz",
    Chad: "Tschad",
    Kosovo: "Kosovo",
    Lebanon: "Libanon",
    Djibouti: "Dschibuti",
    Burundi: "Burundi",
    Suriname: "Suriname",
    Israel: "Israel",
    Mali: "Mali",
    Senegal: "Senegal",
    Guinea: "Guinea",
    Zimbabwe: "Simbabwe",
    Poland: "Polen",
    "North Macedonia": "Nordmazedonien",
    Paraguay: "Paraguay",
    Belarus: "Belarus",
    "Czech Republic": "Tschechische Republik",
    "Burkina Faso": "Burkina Faso",
    Namibia: "Namibia",
    Libya: "Libyen",
    Tunisia: "Tunesien",
    Bhutan: "Bhutan",
    Moldova: "Moldawien",
    "South Sudan": "Südsudan",
    Botswana: "Botswana",
    "The Bahamas": "Bahamas",
    "New Zealand": "Neuseeland",
    Cuba: "Kuba",
    Ecuador: "Ecuador",
    Australia: "Australien",
    Venezuela: "Venezuela",
    "Solomon Islands": "Salomonen",
    Madagascar: "Madagaskar",
    Iceland: "Island",
    Egypt: "Ägypten",
    Kyrgyzstan: "Kirgisistan",
    Nepal: "Nepal",

    "Blocked countries": "Blockierte Länder",
    "Filtered countries": "Gefilterte Länder",
    Countries: "Länder",
    "Blocked country": "Blockiertes Land",
    Errors: "Fehler",
    "Filter categories": "Filterkategorien",
    "IPs that are allowed.": "IPs, die erlaubt werden sollen.",
    "IPs that aren't allowed.": "IPs, die blockiert werden sollen.",
    "Domains that are allowed. You can also enter a subdomain to allow it if you have blocked its parent domain.":
        "Domains, die erlaubt werden sollen. Es kann auch eine Subdomain hinzugefügt werden, um diese zu erlauben, wenn die zugehörige höhere Domain nicht erlaubt ist.",
    "Domains that aren't allowed. This will also block associated subdomains. To allow specific subdomains, you can explicitly add them to the allow list.":
        "Domains, die blockiert werden sollen. Dies blockiert auch alle zugehörigen Subdomains. Sollen bestimmte Subdomains erlaubt werden, können sie explizit zur Allowliste hinzugefügt werden.",
    "DNS Rewrites": "DNS-Rewrites",
    "DNS IP Rewrites": "DNS-IP-Rewrites",
    "DNS CNAME Rewrites": "DNS-CNAME-Rewrites",
    "Cache Boost": "Cache-Boost",
    "Message from device to server": "Nachricht vom Gerät zum Server",
    "Message from server to device": "Nachricht vom Server zum Gerät",
    Out: "Ausgehend",
    In: "Eingehend",
    "Automatic refresh": "Automatische Aktualisierung",
    Endpoints: "Endpunkte",
    "DNS-over-HTTPS (DoH)": "DNS-over-HTTPS (DoH)",
    "DNS-over-TLS (DoT)": "DNS-over-TLS (DoT)",
    Mobileconfig: "Mobileconfig",
    "Configure Securepoint Secure DNS by using the following endpoints:":
        "Konfigurieren Sie Securepoint Secure DNS auf Ihren Geräten mit einem der folgenden Endpunkte:",
    "Configure Securepoint Secure DNS by downloading a signed mobileconfig to your Apple devices:":
        "Konfigurieren Sie Securepoint Secure DNS auf Ihren Geräten, indem Sie eine signierte Mobileconfig für Ihre Apple-Geräte herunterladen:",
    "Identify your devices": "Identifizieren Sie Ihre Geräte",
    "Optionally, you can assign a name to identify your devices in the statistics and logs.":
        "Sie können optional einen Namen angeben, um Ihre Geräte in den Statistiken und Protokollen zu identifizieren.",
    "The above endpoints are automatically updated while you type.":
        "Die obigen Endpunkte werden entsprechend der Eingabe automatisch aktualisiert.",
    "Example: Desktop PC 1": "Beispiel: Desktop-PC 1",
    Installation: "Installation",
    "Edit Satellite": "Satellit bearbeiten",
    "Select a zone": "Wählen Sie eine Zone",
    "Missing Satellites / Roadwarrior": "Fehlende Satelliten / Roadwarrior",
    "The interface that will be used to establish the connections.":
        "Die Schnittstelle, die für den Verbindungsaufbau verwendet wird.",
    "The hostname that will be used to establish the connections.":
        "Der Hostname, der für den Verbindungsaufbau verwendet wird.",
    Removed: "Gelöscht",
    "The changes have not been published yet.":
        "Die Änderungen sind noch nicht veröffentlicht worden.",
    Added: "Hinzugefügt",
    Changed: "Geändert",
    "There are no changes to be published.": "Es gibt keine zu veröffentlichenden Änderungen.",
    "This UTM is connected to the Unified Security Console":
        "Diese UTM ist mit der Unified Security Console verbunden",
    "This UTM is disconnected from the Unified Security Console":
        "Diese UTM ist nicht mit der Unified Security Console verbunden",
    Reachable: "Erreichbar",
    Unreachable: "Unerreichbar",
    "Saved Credentials": "Benutzerdaten sind gespeichert",
    "A PIN is needed to start the connection": "Zum Starten wird ein PIN benötigt",
    "Auto-Reconnect": "",
    Data: "Daten",
    Deprecated: "Veraltet",
    "OTP deactivated": "OTP deaktiviert",
    "1 hour": "1 Stunde",
    "1 day": "1 Tag",
    "This does not appear to be a valid domain. If you'd like to enter an IP address instead, use the IP rewrite feature.":
        "Dies scheint keine gültige Domain zu sein. Wenn Sie stattdessen eine IP-Adresse nutzen möchten, verwenden Sie bitte die IP-Rewrite-Funktion.",
    "The specified transfer network overlaps with existing network ranges of another wg-interface. Delete the old wg-interface or reset this utm to factory settings":
        "Das angegebene Transfernetz überschneidet sich mit bestehenden Netzbereichen eines anderen WG-Interface. Löschen Sie das alte WG-Interface oder setzen Sie diese UTM auf Werkseinstellungen zurück",
    "Invalid request": "Ungültige Anfrage",
    "Enrollment disabled": "Enrollment deaktiviert",
    "New Folder": "Neuer Ordner",
    "No device type selected": "Kein Gerätetyp ausgewählt",
    "Add Satellite or Roadwarrior": "Satellit oder Roadwarrior hinzufügen",
    "The location of the device cannot be determined at the moment. Please try again at a later time.":
        "Der Standort des Geräts kann zur Zeit nicht ermittelt werden. Bitte probieren Sie es zu einem späteren Zeitpunkt noch einmal.",
    "Please make sure that the Securepoint MDM Toolbox application is installed on the device and the license agreement of the location plugin is accepted.":
        "Bitte stellen Sie sicher, dass die Securepoint MDM Toolbox Anwendung auf dem Gerät installiert ist und die Lizenzvereinbarung des Location Plugins akzeptiert ist.",
    "The license limit has been exceeded. Please select another license.":
        "Das Limit der Lizenz wurde überschritten. Bitte wählen Sie eine andere Lizenz.",
    "VPN-Configuration": "VPN-Konfiguration",
    "Windows Zero Touch Profiles": "Windows Zero-Touch-Profile",
    "Windows Zero Touch Profile": "Windows Zero-Touch-Profil",
    "Windows Zero Touch Profile Details": "Windows Zero-Touch-Profil-Details",
    "Add Windows Zero Touch Profile": "Windows Zero-Touch-Profil hinzufügen",
    "Edit Windows Zero Touch Profile": "Windows Zero-Touch-Profil bearbeiten",
    "You have one or more Android devices without a valid license.":
        "Sie haben mindestens ein Android-Gerät ohne eine gültige Lizenz.",
    Reply: "Antwort",
    "Windows VPN-Profile": "Windows VPN-Profil",
    "Windows VPN-Profiles": "Windows VPN-Profile",
    "Add Windows VPN-Profile": "Windows VPN-Profil hinzufügen",
    "Edit Windows VPN-Profile": "Windows VPN-Profil bearbeiten",
    "Windows VPN-Profile details": "Windows VPN-Profil details",
    Behavior: "Verhalten",
    "Behavior for blocked domains": "Verhalten für blockierte Domains",
    "Specifies the action for blocked domains.": "Gibt die Aktion für blockierte Domains an.",
    "Returns an address that cannot be routed.":
        "Gibt eine Adresse zurück, welche nicht geroutet werden kann.",
    "Replies with a set of custom IP addresses.": "Antwortet mit benutzerdefinierten IP-Adressen.",
    "Replies with a custom CNAME.": "Antwortet mit einem benutzerdefinierten CNAME.",
    "Tells the client that the requested domain does not exist.":
        "Teilt dem Client mit, dass die angefragte Domain nicht existiert.",
    "Tells the client that the DNS server refuses to fulfill its request.":
        "Teilt dem Client mit, dass der DNS-Server das Beantworten dieser Anfrage verweigert.",
    "Custom IPs": "Benutzerdefinierte IPs",
    "Custom CNAME": "Benutzerdefinierter CNAME",
    "Used as a reply when requesting a blocked domain.":
        "Wird als Antwort verwendet, wenn eine blockierte Domain angefragt wird.",
    "You can enter IPv4 and IPv6 addresses.":
        "Sie können sowohl IPv4- als auch IPv6-Adressen verwenden.",
    "Must be an IPv4 or IPv6 address.": "Muss eine IPv4- oder IPv6-Adresse sein.",
    "You have to enter at least one IP address.": "Sie müssen mindestens eine IP-Adresse eingeben.",
    "Reply (IP addresses)": "Antwort (IP-Adressen)",
    "Reply (CNAME)": "Antwort (CNAME)",
    'This does not appear to be a valid domain. If you\'d like to enter IP addresses instead, select "Custom IP".':
        'Dies scheint keine gültige Domain zu sein. Wenn Sie stattdessen IP-Adressen nutzen möchten, verwenden Sie bitte die Option "Benutzerdefinierte IPs".',

    "Client Settings": "Client-Einstellungen",
    "Connection Settings": "Verbindungs-Einstellungen",
    Connection: "Verbindung",
    "Allow creating connections": "Erstellen von Verbindungen erlauben",
    "Allow editing connections": "Bearbeiten von Verbindungen erlauben",
    "Allows the creation of new connections via the wizard or via import.":
        "Erlaubt die Erstellung neuer Verbindungen über den Wizard oder per Import.",
    "Allows you to deactivate, delete and edit connections, including exporting files and applying new certificates.":
        "Erlaubt das Deaktivieren, Löschen und Bearbeiten von Verbindungen, inkl. Dateiexport und Anwendung neuer Zertifikate.",
    "Allows you to create and edit access data.":
        "Erlaubt die Speicherung und Bearbeitung von Benutzerdaten für die Verbindung.",
    "The changes are being published.": "Die Änderungen werden veröffentlicht.",
    "The UTM will be added": "Die UTM wird hinzugefügt",
    "Allow editing credentials": "Bearbeiten von Benutzerdaten erlauben",
    "The Roadwarrior will be added": "Der Roadwarrior wird hinzugefügt",
    "The UTM will be updated": "Die UTM wird aktualisiert",
    "The Roadwarrior will be updated": "Der Roadwarrior wird aktualisiert",
    "Allow changes to the client settings": "Ändern der Client-Einstellungen erlauben",
    "Various settings can be configured on the client here. You can also specify whether these values may be changed on the client.":
        "Hier können diverse Einstellungen auf dem Client konfiguriert werden. Zusätzlich wird festgelegt, ob diese Werte auf dem Client verändert werden dürfen.",
    "No location available": "Kein Standort verfügbar",
    "Please provide as much information as possible to help locate the lost device. This information will be displayed on the device.":
        "Bitte geben Sie so viele Informationen wie möglich an, um das verlorene Gerät zu finden. Diese Informationen werden auf dem Gerät angezeigt.",
    "The reason is displayed in the audit log but not on the device.":
        "Die Begründung wird im Auditlog angezeigt, aber nicht auf dem Gerät.",
    "Add Clients": "Clients hinzufügen",
    "Lost mode activation error": "Lost-Modus-Aktivierungsfehler",
    "Phone call in lost mode": "Anruf im Lost-Modus",
    "Lost mode attempt failed": "Lost-Modus-Versuch fehlgeschlagen",
    "The following names have to be unique:": "Die folgenden Namen müssen eindeutig sein:",
    "Please choose %s for the following items:":
        "Bitte wählen Sie einen %s für die folgenden Punkte:",
    "Security Information": "Sicherheitsinformationen",
    "Configure the Securepoint Secure DNS App with the following configuration ID:":
        "Konfigurieren Sie die Securepoint Secure DNS App mit folgender Konfigurations-ID:",
    "Config ID": "Konfigurations-ID",
    "Windows Profile": "Windows Profil",
    "Windows Profiles": "Windows Profile",
    "Windows Profile details": "Windows Profil Details",
    "Edit Windows Profile": "Windows Profil bearbeiten",
    "Windows Enrollmentx": "Windows Enrollment",
    "Edit Windows enrollment token": "Windows Enrollmenttoken bearbeiten",
    "Add Windows enrollment token": "Windows Enrollmenttoken hinzufügen",
    "Add enrollment token": "Enrollmenttoken hinzufügen",
    "Enrollment token": "Enrollmenttoken",
    "enrollment token": "Enrollmenttoken",
    "Do you really want to delete this enrollment token?":
        "Möchten Sie dieses Enrollmenttoken wirklich löschen?",
    "Full domains": "Vollständige Domains",
    "Root domains": "Root-Domains",
    "Connection diagnosis": "Verbindungsdiagnose",
    "Connection logs": "Verbindungsprotokoll",
    "Start a connection": "Verbindungsaufbau",
    "Stop a connection": "Verbindungstrennung",
    "Remove a connection": "Löschen einer Verbindung",
    "Client action: state": "Client-Aktion: Status",
    "Client action: open": "Client-Aktion: Starten",
    "Client action: close": "Client-Aktion: Beenden",
    "Fetch connections": "Verbindungen anfordern",
    "Please use the native Lost mode": "Bitte verwenden Sie den nativen Lost-Modus",
    "Apply profile": "Profil anwenden",
    "Last use": "Letzte Benutzung",
    "Not used yet": "Noch nicht benutzt",
    "Integrate your new profile": "Integrieren Sie Ihr neues Profil",
    "Integrate your profile": "Integrieren Sie Ihr Profil",
    show: "anzeigen",
    "Do you really want to delete this securedns profile?":
        "Möchten Sie dieses Secure DNS-Profil wirklich löschen?",
    "Select profile": "Profil auswählen",
    "Activate Secure DNS": "Secure DNS aktivieren",
    "After settings this, you can select a Secure DNS profile and the Secure DNS app will be installed automatically.":
        "Nach dem Aktivieren können Sie ein Secure DNS-Profil auswählen und die Secure DNS-App wird automatisch installiert.",
    "After settings this, you can select a Secure DNS profile and configure a device name.":
        "Nach dem Aktivieren können Sie ein Secure DNS-Profil auswählen und einen Gerätenamen konfigurieren.",
    "Device Alias": "Geräte-Alias",
    "Use the device alias as the device name.": "Verwendet den Geräte-Alias als Gerätenamen.",
    "Use the device id as the device name.": "Verwendet die Geräte-ID als Gerätenamen.",
    "Use the assigned device username as the device name.":
        "Verwendet den zugewiesenen Benutzernamen als Gerätenamen.",
    "Color scheme": "Farbschema",
    Dark: "Dunkel",
    Light: "Hell",
    "Allowed Categories": "Erlaubte Kategorien",
    "Blocked Categories": "Blockierte Kategorien",
    "The Secure DNS profile to be used for the Secure DNS configuration.":
        "Das Secure DNS-Profil, das für die Secure DNS-Konfiguration verwendet werden soll.",
    "Select which attribute should be used as the device name to identify the devices in the Secure DNS statistics and logs.":
        "Wählen Sie aus, welches Attribut als Gerätename verwendet werden soll, um die Geräte in den Secure DNS-Statistiken und Protokollen zu identifizieren.",
    "Accept the privacy policy": "Datenschutzerklärung akzeptieren",
    "Logged in users": "Eingeloggte Benutzer",
    "Custom Blocklists": "Benutzerdefinierte Blockierlisten",
    "External Blocklists": "Externe Blockierlisten",
    "Add your own blacklists. All common formats are supported, e.g. Hosts and ABP.":
        "Fügen Sie Ihre eigenen Blocklisten hinzu. Alle gängigen Formate werden unterstützt, z.B. Hosts und ABP.",
    "Add Blocklist": "Blockierliste hinzufügen",
    "My new Blocklist": "Meine neue Blockierliste",
    names: "Namen",
    Applied: "Angewendet",
    "Retrieved on": "Abgerufen am",
    "Retrieved by": "Abgerufen von",
    "Last attempt": "Letzter Versuch",
    "State mismatch between USP and UTM. Publish to overwrite with USP state.":
        "Zustandsungleichheit zwischen USP und UTM. Veröffentlichen, um mit USP-Zustand zu überschreiben.",
    Verify: "Überprüfen",
    "Custom Blocklist": "Eigene Blockierliste",
    "A UTM tried to apply this profile but an error occurred.":
        "Eine UTM hat versucht dieses Profil anzuwenden, wobei ein Fehler aufgetreten ist.",
    "Errors when applying": "Fehler beim Anwenden",
    "VPN-Configuration - updated public key":
        "VPN-Konfiguration - öffentlicher Schlüssel aktualisiert",
    "The public key has been updated on Core-UTM $1.":
        "Der öffentliche Schlüssel wurde auf Core-UTM $1 aktualisiert.",
    "Start Date": "Startdatum",
    "Invalid serial number": "Ungültige Seriennummer",
    "Enter a serial number": "Seriennummer eingeben",
    "Enter a PIN": "PIN eingeben",
    "Add Zero-Touch Profile": "Zero-Touch-Profil hinzufügen",
    "Edit Zero-Touch Profile": "Zero-Touch-Profil bearbeiten",
    "USC Privacy Policy": "Datenschutzerklärung USC",
    "UTM Privacy Policy": "Datenschutzerklärung UTM",
    "UTM AGB": "UTM AGB",
    "license conditions": "Lizenzbedingungen akzeptieren",
    "The licence conditions can be found <a href='#add-tenant-{tenantDomain}-uscZtProfiles-agb-dialog'>here</a>.":
        "Die Lizenzbedingungen sind <a href='#add-tenant-{tenantDomain}-uscZtProfiles-agb-dialog'>hier</a> nachzulesen.",
    "The UTM privacy policy can be found <a href='#add-tenant-{tenantDomain}-uscZtProfiles-privacy-policy-dialog'>here</a>.":
        "Die Datenschutzerklärung der UTM ist <a href='#add-tenant-{tenantDomain}-uscZtProfiles-privacy-policy-dialog'>hier</a> nachzulesen.",
    "The USC privacy policy can be found <a href='https://www.securepoint.de/cloud-messaging-datenschutz' target='_blank'>here</a>.":
        "Die Datenschutzerklärung der USC ist <a href='https://www.securepoint.de/cloud-messaging-datenschutz' target='_blank'>hier</a> nachzulesen.",
    "Download token and client": "Token und Client herunterladen",
    "Send invitation Email": "Einladungs-E-Mail senden",
    "Invalid UTM configuration": "Ungültige UTM Konfiguration",
    "If your configuration is encrypted, e.g. from a cloud backup, please enter the password you have chosen.":
        "Wenn Ihre Konfiguration verschlüsselt ist, z.B. aus einem Cloud-Backup, geben Sie bitte das von Ihnen gewählte Passwort ein.",
    "Configuration password": "Konfigurationspasswort",
    "Drop UTM configuration (.utm) here":
        "UTM-Konfiguration (.utm) hier per Drag & Drop ablegen oder klicken",
    "Configuration of the UTM": "Konfiguration der UTM",
    "Your backup is encrypted. Please provide a password.":
        "Ihr Backup ist verschlüsselt. Bitte geben Sie das Passwort an.",
    "Invalid password": "Ungültiges Passwort",
    "Optional configuration of the UTM.": "Optionale Konfiguration der UTM.",
    "Optional configuration of the UTM. This can be a normal backup or an encrypted cloud backup. <b>Warning</b>: The configuration cannot be subsequently added, changed or deleted.":
        "Optionale Konfiguration der UTM. Diese kann ein normales Backup oder auch ein verschlüsseltes Cloud-Backup sein. <b>Achtung</b>: Die Konfiguration kann nachträglich nicht hinzugefügt, geändert oder gelöscht werden.",
    "Date from which the configuration is to be valid.":
        "Datum, ab dem die Konfiguration gültig sein soll.",
    "Date from which the configuration expires.": "Datum, ab dem die Konfiguration abläuft.",
    "Defines the web session pin for the UTM.": "Definiert den Webssesion PIN für die UTM.",
    "Custom Reason": "Benutzerdefinierter Grund",
    "Device loss": "Geräteverlust",
    "Employee has left": "MA ausgeschieden",
    "Other reason": "Anderer Grund",
    "This PIN does not allow increasing numbers":
        "Diese PIN darf nicht aus aufsteigenden Zahlen bestehen",
    "This PIN does not allow decreasing numbers":
        "Diese PIN darf nicht aus absteigenden Zahlen bestehen",
    "This PIN does not allow only one number":
        "Diese PIN darf nicht aus einer sich wiederholenden Zahl bestehen",
    "Do not use pi": "Verwenden Sie kein Pi",
    "Do not use multiples of two": "Verwenden Sie keine Zweierpotenzen",
    "Use at least 4 different numbers": "Verwenden Sie mindestens 4 verschiedene Zahlen",
    "Do not use insecure patterns": "Verwenden Sie keine unsicheren Muster",
    "Revoked by user": "Vom Benutzer widerrufen",
    "Network object": "Netzwerkobjekt",
    "The serial number of the UTM consists of a 2-digit prefix (SP/ZT) followed by a numerical sequence of at least 5 digits.":
        "Die Seriennummer der UTM besteht aus einem 2-stelligen Prefix (SP/ZT) gefolgt von einer mindestens 5-stelligen numerischen Ziffernfolge.",
    "The enrollment PIN consists of exactly eight characters.":
        "Die Enrollment-PIN besteht aus genau acht Zeichen.",
    "UTM License Conditions": "Lizenzbedingungen UTM",
    "Accept the license conditions": "Lizenzbedingungen akzeptieren",
    "The websession PIN must contain exactly six digits. Only ascending and descending number sequences (also in steps of two) and six-fold repetitions are not permitted as PINs.":
        "Die Websession-PIN muss genau sechs Ziffern enthalten. Ausschließlich auf- und absteigende Zahlenfolgen (auch in Zweierschritten) und Sechsfach-Wiederholungen sind als PIN nicht zugelassen.",
    "Domain to be overwritten.": "Domäne welche überschrieben werden soll.",
    "IPs that are sent in response to the overwritten domain.":
        "IPs, die als Antwort auf die überschriebene Domain gesendet werden.",
    "Profiles disabled": "Profile deaktiviert",
    "No invite/enrollment type usable": "Keine Einladungs-/Enrollmentart verwendbar",
    "Set up now": "Jetzt einrichten",
    "Network Group": "Netzwerkgruppe",
    "Select Network Group": "Netzwerkgruppe auswählen",
    "Do you really want to delete this dep profile?":
        "Wollen Sie dieses DEP-Profil wirklich löschen?",
    "Switch tenant": "Tenant wechseln",
    "An error occurred while exporting iOS devices as CSV":
        "Beim Exportieren von iOS-Geräten als CSV ist ein Fehler aufgetreten",
    "An error occurred while exporting Android devices as CSV":
        "Beim Exportieren von Android-Geräten als CSV ist ein Fehler aufgetreten",
    "Secure DNS invitation email": "Secure DNS E-Mail-Einladung",
    "Model versions mismatch! Publish again to overwrite":
        "Modellversionen stimmen nicht überein! Veröffentlichen Sie erneut um diese zu überschreiben",
    "The PIN of the core UTM $1 is required to publish the VPN configuration.":
        "Zur Veröffentlichung der VPN-Konfiguration wird die PIN der Core-UTM $1 benötigt.",
    "Configure device names": "Gerätenamen konfigurieren",
    "The profile for which an invitation is to be sent.":
        "Das Profil, für das eine Einladung verschickt werden soll.",
    "The recipients who should receive the invitation.":
        "Die Empfänger, die die Einladung erhalten sollen.",
    "You can optionally preconfigure the device names in the invitation. If no device name has been set, the device uses a default name.":
        "Sie können optional die Gerätenamen in der Einladung vorab konfigurieren. Wenn kein Gerätename gesetzt wurde, verwendet das Gerät einen Standardnamen.",
    "Device name of the user": "Gerätename des Benutzers",
    "Device name": "Gerätename",
    "Please set up an apple push certificate.": "Bitte richten Sie ein Apple Push Zertifikat ein.",
    "Please set up android enterprise functionality.":
        "Bitte richten Sie die Android Enterprise Funktionalität ein.",
    "Download device details as a list": "Gerätedetails als Liste herunterladen",
    "Scan the following QR code to setup the configuration ID in the Secure DNS app:":
        "Scannen Sie den folgenden QR-Code, um die Konfigurations-ID in der Secure DNS App einzurichten:",
    "Security / VPN": "Sicherheit / VPN",
    "The specified transfer network overlaps with configured roadwarrior address pools.":
        "Das angegebene Transfernetz überschneidet sich mit konfigurierten Roadwarrior-Adresspools.",
    "The specified transfer network overlaps with existing interface address ranges.":
        "Das angegebene Transfernetz überschneidet sich mit bestehenden Schnittstellenadressbereichen.",
    "The specified transfer network overlaps with existing system routes.":
        "Das angegebene Transfernetz überschneidet sich mit bestehenden Systemrouten.",
    "The specified transfer network contains the Core-UTM's transfer network":
        "Das angegebene Transfernetz enthält das Transfernetz der Core-UTM",
    "Error while loading Secure DNS statistics.": "Fehler beim Laden der Secure DNS Statistiken.",
    "An error has occurred": "Ein Fehler ist aufgetreten",
    "The key cannot be exported.": "Der Schlüssel kann nicht exportiert werden.",
    "Block malicious domains using a AI model based on machine learning that recognises patterns in malicious domains and blocks them.":
        "Blockieren Sie bösartige Domains mit Hilfe eines KI-Modells, das auf maschinellem Lernen basiert und Muster in bösartigen Domains erkennt und diese blockiert.",
    "Microsoft Entra ID": "Microsoft Entra ID",
    "Select licence from reseller portal": "Lizenz aus Reseller-Portal auswählen",
    "Select locally saved licence": "Lokal gespeicherte Lizenz auswählen",
    "You can either upload a licence yourself or select an existing licence. <b>Warning</b>: You can only select an existing licence if you are logged in via a reseller account.":
        "Sie können entweder selbst eine Lizenz hochladen oder eine vorhandene Lizenz auswählen. <b>Achtung</b>: Sie können nur eine vorhandene Lizenz auswählen, wenn Sie über Ihr Reseller-Konto angemeldet sind.",
    "You can only select an existing licence if you are logged in via a reseller account.":
        "Sie können nur eine vorhandene Lizenz auswählen, wenn Sie über Ihr Reseller-Konto angemeldet sind.",
    "Expiry date must not be higher than the license expiration date.":
        "Das Ablaufdatum darf nicht höher als das Lizenzablaufdatum sein.",
    "Failed to fetch license from RSP. Please try again later.":
        "Lizenz konnte nicht vom RSP abgerufen werden. Bitte versuchen Sie es später erneut.",
    "The RSP licence was added via a reseller account and cannot be edited by a local user.":
        "Die RSP-Lizenz wurde über ein Reseller-Konto hinzugefügt und kann nicht von einem lokalen Benutzer bearbeitet werden.",
    "AI Malware Filter": "KI-Malware-Filter",
    "Activate AI Malware Filter": "KI-Malware-Filter aktivieren",
    "Block malicious domains with our machine learning model, which has been trained with tens of thousands of benign and malicious domains.":
        "Blockieren Sie bösartige Domains mit unserem Machine-Learning-Modell, welches mit Zehntausenden gutartigen und bösartigen Domains trainiert wurde.",
    "Apply vpn": "VPN anwenden",
    "Credential provider": "Anmeldedatenanbieter",
    "This function controls whether an app on Android 14 and higher is allowed to act as a credential provider for managing credentials. It is useful for apps that manage authentication or login data, such as password managers or apps for multi-factor authentication.":
        "Diese Funktion steuert, ob eine App auf Android 14 und höher als Anmeldedatenanbieter für die Verwaltung von Anmeldedaten fungieren darf. Sie ist sinnvoll für Apps, die Authentifizierungs- oder Anmeldedaten verwalten, wie z.B. Passwortmanager oder Apps zur Multi-Faktor-Authentifizierung.",
    "Use credential provider default policy": "Anmeldedatenanbieter-Standardrichtlinie benutzen",
    "The credential provider default policy determines whether this app may be used as the default credential provider.":
        "Die Anmeldedatenanbieter-Standardrichtlinie bestimmt, ob diese App als Standardanmeldedatenanbieter genutzt werden darf.",
    "This app may act as a credential provider":
        "Diese App darf als Anmeldedatenanbieter fungieren",
    "This app can be used as a login data provider independently of the global app settings.":
        "Diese App kann unabhängig von den globalen App-Einstellungen als Anmeldedatenanbieter genutzt werden.",
    "Credential provider default policy": "Anmeldedatenanbieter-Standardrichtlinie",
    "Not specified (Only apps with credential provider policy)":
        "Nicht spezifiziert (Nur Apps mit Anmeldedatenanbieter-Richtlinie)",
    "Not specified. Only apps that have specified a credential provider policy.":
        "Nicht spezifiziert. Nur Apps, die eine Anmeldedatenanbieter-Richtlinie angegeben haben.",
    "Only apps with credential provider policy": "Nur Apps mit Anmeldedatenanbieter-Richtlinie",
    "Only apps with credential provider policy or OEM standard credential provider":
        "Nur Apps mit Anmeldedatenanbieter-Richtlinie oder OEM-Standardanmeldedatenanbieter",
    "Only apps that have specified a credential provider policy.":
        "Nur Apps, die eine Anmeldedatenanbieter-Richtlinie angegeben haben.",
    "Only apps that have specified a credential provider policy or OEM default credential provider apps.":
        "Nur Apps, die eine Anmeldedatenanbieter-Richtlinie angegeben haben, oder OEM-Standardanmeldedatenanbieter-Apps.",
    "Blocks all domains with a malware probability of more than 90%":
        "Blockiert alle Domains mit einer Malware-Wahrscheinlichkeit von mehr als 90%",
    "Blocks all domains with a malware probability of more than 70%":
        "Blockiert alle Domains mit einer Malware-Wahrscheinlichkeit von mehr als 70%",
    "Blocks all domains with a malware probability of more than 50%":
        "Blockiert alle Domains mit einer Malware-Wahrscheinlichkeit von mehr als 50%",
    Mode: "Modus",
    Relaxed: "Entspannt",
    Balanced: "Ausgeglichen",
    Strict: "Streng",
    Experimental: "Experimentell",
    "The profile will be assigned to all Clients with these Tags":
        "Das Profil wird allen Clients mit diesen Tags zugewiesen",
    "MDM-Profiles": "MDM-Profile",
    "Windows VPN-Client details": "Windows VPN-Client Details",
    "Public key": "Öffentlicher Schlüssel",

    "Traffic-map": "Traffic-Karte",
    "Live-data": "Live-Daten",
    "Historical live-data": "Historische Live-Daten",
    "Historical port-data": "Historische Port-Daten",
    "No Data available": "Keine Daten verfügbar",
    "Geo-IP Area": "Geo-IP Bereich",
    "Suspicious IPs": "Verdächtige IPs",
    "Attack on port": "Angriff auf Port",
    "Blocked ports": "Blockierte Ports",
    Ranking: "Rangliste",
    "United States": "Vereinigte Staaten",
    "Configuration Status": "Konfigurationsstatus",
    "Personal usage is disallowed (Userless)": "Private Nutzung ist nicht erlaubt (Benutzerlos)",
    "Your current IP Address": "Ihre aktuelle IP-Adresse",
    "DNS Configuration": "DNS Konfiguration",
    "Not Using Secure DNS": "Secure DNS wird nicht verwendet",
    "Using Secure DNS": "Secure DNS wird verwendet",
    "Device Name": "Gerätename",
    "Missing name": "Fehlender Name",
    "Invalid date": "Ungültiges Datum",
    "It seems that you haven\'t uploaded the right Apple Push Certificate. Click here to upload a new one":
        "Es scheint, dass Sie nicht das richtige Apple Push-Zertifikat hochgeladen haben. Klicken Sie hier, um ein neues hochzuladen",
    Rating: "Bewertung",
    "Specifies the rating of the AI model. The higher the rating, the higher the probability that a domain will be falsely blocked (false positive).":
        "Gibt die Bewertung des KI-Modells an. Je höher die Bewertung, desto höher ist die Wahrscheinlichkeit, dass eine Domäne fälschlicherweise gesperrt wird (falsch positiv).",
    "Something went wrong. Please try again.":
        "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.",
    "Blocks all domains that the AI model classifies as malicious with a probability of %s%%.":
        "Blockiert alle Domains, die das KI-Modell als bösartig mit einer Wahrscheinlichkeit von %s%% einstuft.",
    "Enables configuring firmware update settings":
        "Erlaubt die Konfiguration der Firmware-Update-Einstellungen",
    "Enables configuring threat intelligence filter settings":
        "Erlaubt die Konfiguration der Threat-Intelligence-Filter-Einstellungen",
    "Enables configuring privacy settings":
        "Erlaubt die Konfiguration der Datenschutz-Einstellungen",
    "Enables configuring Fail2Ban settings": "Erlaubt die Konfiguration der Fail2Ban-Einstellungen",
    "An error has occurred while %s the connection":
        "Beim %s der Verbindung ist ein Fehler aufgetreten",
    starting: "Starten",
    stopping: "Stoppen",
    "Enrollment mode": "Enrollment-Modus",
    "Account Based User Enrollment": "Account-basiertes Benutzer-Enrollment",
    "Profile Based User Enrollment": "Profilbasiertes Benutzer-Enrollment",
    "This e-mail address must be entered on the device in order to start the account-based user enrollment.":
        "Folgende E-Mail-Adresse muss auf dem Gerät eingegeben werden, um das Benutzer-Enrollment zu starten.",
    "Profile-based user enrollment is no longer available from iOS 18 and higher.":
        "Das profilbasierte Benutzer-Enrollment ist ab iOS 18 und höher nicht mehr verfügbar.",
    "Managed Apple IDs": "Verwaltete Apple-IDs",
    "Sets the behavior of a device in kiosk mode when a user presses and holds (long-presses) the Power button.":
        "Legt das Verhalten eines Geräts im Kioskmodus fest, wenn ein Benutzer die Einschalttaste lange gedrückt hält.",
    "Security policies set to the most secure values by default. To maintain the security posture of a device, we don't recommend overriding any of the default values.":
        "Die Sicherheitsrichtlinien sind standardmäßig auf die sichersten Werte eingestellt. Um die Sicherheit eines Geräts aufrechtzuerhalten, empfehlen wir, die Standardwerte nicht zu überschreiben.",
    "Click here to add an Apple Push Certificate":
        "Klicken Sie hier, um ein Apple Push-Zertifikat hinzuzufügen",
    "Location is determined. This can take 5 minutes or longer.":
        "Standort wird ermittelt. Dies kann 5 Minuten oder länger dauern.",
    "<b>We encountered an issue</b>.": "<b>Wir haben ein Problem festgestellt</b>.",
    "If the problem persists, please include the following Error ID in your support ticket to help us resolve it.":
        "Wenn das Problem weiterhin besteht, fügen Sie bitte die folgende Fehler-ID in Ihrem Support-Ticket hinzu, um uns bei der Lösung zu helfen.",
    Method: "Methode",
    "Error data": "Fehlerdaten",
    "Error ID": "Fehler-ID",
    "Internal Server Error": "Interner Serverfehler",
    "Format validation failed (Data is not an valid certificate)":
        "Formatvalidierung fehlgeschlagen (Datei ist kein gültiges Zertifikat)",
    "Format validation failed (Certificate is expired)":
        "Formatvalidierung fehlgeschlagen (Zertifikat ist abgelaufen)",
    Passwords: "Passwörter",
    "The app is installed automatically, cannot be deleted by the user and prevents the device from being set up until the app has been installed. This setting is possible for a maximum of 5 apps.":
        "Die App wird automatisch installiert, kann nicht vom Benutzer gelöscht werden und verhindert die Einrichtung des Geräts, bis die App installiert wurde. Diese Einstellung ist für maximal 5 Apps möglich.",
    "Too many required for setup apps. Maximum allowed %s.":
        "Zu viele für Setup-Anwendungen erforderlich. Maximal %s erlaubt.",
    Topology: "Topologie",
    "Add Topology": "Topologie hinzufügen",
    "The selected UTM will be used as Core-UTM for this topology":
        "Die ausgewählte UTM wird als Core-UTM für diese Topologie verwendet",
    "Device(s)": "Gerät(e)",
    "Enterprise Devices": "Enterprise Geräte",
    "Enter a company name": "Geben Sie einen Firmennamen ein",
    "Must match format: Email": "Muss dem Format entsprechen: E-Mail",
    "Enter an email": "Geben Sie eine E-Mail ein",
    "Enter a phone number": "Geben Sie eine Telefonnummer ein",
    "Enter a configuration name": "Geben Sie einen Konfigurationsnamen an",
    "The selected network group is adopted as the source":
        "Die ausgewählte Netzwerkgruppe wird als Quelle übernommen",
    "The selected network group is adopted as the destination":
        "Die ausgewählte Netzwerkgruppe wird als Ziel übernommen",
    "Type of Secure DNS profile": "Typ des Secure DNS Profils",
    "Use the default device name as the device name.":
        "Verwendet den Standardgerätenamen als Gerätenamen.",
    "Please select": "Bitte wählen",
    "Enter a private IP": "Geben Sie eine private IP ein",
    "This world map shows real-time attacks on our honeypots worldwide. Hovering over a country with the mouse displays information from the threat intelligence feed (TIF). The view can be switched to a heat map under the navigation bar. Countries from which a particularly high number of threats originate are marked.":
        "Diese Weltkarte zeigt in Echtzeit Angriffe, die weltweit auf unsere Honeypots stattfinden. Beim Hovern mit der Maus über ein Land werden Informationen aus dem Threat-Intelligence-Feed (TIF) angezeigt. Unter der Navigation lässt sich die Ansicht auf eine Heatmap umschalten. Dabei werden Länder, aus denen besonders viele Bedrohungen ausgehen Markiert.",
    "This table provides a real-time overview of registered attacks based on geo-IP data. It lists the source IP addresses, the attacked protocol and the time of the attack. This information is continuously updated.":
        "Diese Tabelle bietet eine Echtzeitübersicht über registrierte Angriffe, basierend auf Geo-IP-Daten. Sie listet die Quell-IP-Adressen, das angegriffene Protokoll sowie den Zeitpunkt des Angriffs auf. Diese Informationen werden kontinuierlich aktualisiert.",
    "Device's Own Name": "Geräte-Eigennamen",
    "This map shows the IP addresses of attacks on our honeypots worldwide in real time. These are visualised by colour-coded dots, with each dot representing an active threat. Hovering over a country with the mouse displays information from the threat intelligence feed (TIF). Here you can see the percentage of attacks on this country as well as the total number of registered attacks.":
        "Die Weltkarte zeigt in Echtzeit die IP-Adressen von Angriffen, die weltweit auf unsere Honeypots stattfinden. Diese werden durch farblich markierte Punkte visualisiert, wobei jeder Punkt eine aktive Bedrohung darstellt. Beim hovern mit der Maus über ein Land werden Informationen aus dem Threat-Intelligence-Feed (TIF) anzeigt. Hier kann man den prozentualen Anteil der Angriffe auf dieses Land sowie die Gesamtzahl der registrierten Angriffe einsehen.",
    "The table provides a real-time overview of registered attacks based on geo-IP data. It lists the source IP addresses, the attacked protocol and the time of the attack. This information is continuously updated and provides a quick insight into current threats without the need for interaction such as hovering over countries.":
        "Die Tabelle bietet eine Echtzeitübersicht über registrierte Angriffe, basierend auf Geo-IP-Daten. Sie listet die Quell-IP-Adressen, das angegriffene Protokoll sowie den Zeitpunkt des Angriffs auf. Diese Informationen werden kontinuierlich aktualisiert und ermöglichen eine schnelle Einsicht in die aktuellen Bedrohungen, ohne dass eine Interaktion wie das Hovern über Länder erforderlich ist.",
    "The bar chart shows historical data from the threat intelligence feed (TIF) and allows you to select different time periods using a slider. In addition, there is a function that allows the slider to be played automatically through the time periods by pressing the button to the right of the slider. ":
        "Das Balkendiagramm zeigt historische Daten aus dem Threat-Intelligence-Feed (TIF) und ermöglicht es, verschiedene Zeiträume mithilfe eines Sliders auszuwählen. Zusätzlich gibt es eine Funktion, mit der der Slider automatisch durch die Zeiträume gespielt werden kann, indem der Knopf rechts neben dem Slider gedrückt wird.",
    "Logged ports": "Protokollierte Ports",
    "Tabular port-data": "Tabellarische Port-Daten",
    "Start Threat-Visualizer": "Threat-Visualizer starten",
    "Tabular live-data": "Tabellarische Live-Daten",
    "Further Information": "Weitere Informationen",
    "Port attack statistics": "Port-Angriffsstatistik",
    "Port attack statistics (historical)": "Port-Angriffsstatistik (historisch)",
    "Port attack statistics (tabular)": "Port-Angriffsstatistik (tabellarisch)",
    "Country attack statistics": "Länder-Angriffsstatistik",
    "Country attack statistics (historical)": "Länder-Angriffsstatistik (historisch)",
    "Country attack statistics (tabular)": "Länder-Angriffsstatistik (tabellarisch)",
    "The TIF ranking list provides a dynamic overview of the current data from the Threat Intelligence Feed (TIF). It provides information on blocked IP addresses worldwide. This list makes it possible to analyse and monitor potential threats by geographical region.":
        "Die TIF-Rangliste bietet eine dynamische Übersicht über die aktuellen Daten aus dem Threat-Intelligence-Feed (TIF). Er liefert Informationen zu blockierten IP-Adressen weltweit. Diese Liste ermöglicht es, potenzielle Bedrohungen nach geographischen Regionen zu analysieren und zu überwachen.",
    "This histogram shows the live data from the last 100 hours. The data records can be switched through hourly using the slider. By pressing the button at the top right, the current data record will be displayed as a table.":
        "Dieses Histogramm zeigt die Live-Daten der letzten 100 Stunden. Mit dem Schieberegler können die Datensätze stündlich durchgeschalten werden. Bei einem Knopfdruck auf den Schalter oben rechts wird der aktuelle Datensatz als Tabelle angezeigt.",
    "This histogram shows the ports or protocols attacked in the last 100 hours. The data records can be switched through hourly using the slider. By pressing the button at the top right, the current data record will be displayed as a table.":
        "Dieses Histogramm zeigt die Angegriffenen Ports bzw Protokolle der letzten 100 Stunden. Mit dem Schieberegler können die Datensätze  stündlich durchgeschalten werden. Bei einem Knopfdruck auf den Schalter oben rechts wird der aktuelle Datensatz als Tabelle angezeigt.",
    "This TIF list is based on threat analyses and includes known command-and-control servers, malware distributors and other malicious infrastructures. The threat intelligence filter is used in the Securepoint services. This filter uses the TIF list to recognise, log and block connections to these IP addresses - regardless of the protocol used. In the Threat Visualiser, the threat data from the threat intelligence feed is displayed visually as a heat map on the map and as top lists in tables. The live data comes from our honeypots and is continuously fed into the threat analysis.":
        "Diese TIF-Liste basiert auf Bedrohungsanalysen und umfasst bekannte Command-and-Control-Server, Malware-Verteiler und andere schädliche Infrastrukturen. In den Securepoint-Diensten kommt der Threat-Intelligence-Filter zum Einsatz. Dieser Filter nutzt die TIF-Liste, um Verbindungen zu diesen IP-Adressen zu erkennen, zu protokollieren und zu blockieren – unabhängig vom verwendeten Protokoll. Im Threat Visualizer werden die Bedrohungsdaten aus dem Threat-Intelligence-Feed visuell als Heatmap auf der Landkarte sowie als Top-Listen in Tabellen dargestellt. Die Live-Daten stammen aus unseren Honeypots und fließen kontinuierlich in die Bedrohungsanalyse ein.",
    "The Threat Visualizer visualises the evaluation of attacks on systems operated by Securepoint. Securepoint operates systems throughout Germany that simulate lucrative targets in order to attract potential attackers. As soon as one of these so-called honeypots is attacked, useful data about the attacker, such as IP addresses and actions, is analysed and stored in our databases. In addition to the honeypots, we also operate the Securepoint Threat Intelligence Feed, a regularly updated list of IP addresses categorised as malicious.":
        "Der Threat Visualizer visualisiert die Auswertung von Angriffen auf von Securepoint betriebene Systeme. Securepoint betreibt deutschlandweit Systeme, die lohnend wirkende Angriffsziele simulieren, um potentielle Angreifer anzulocken. Sobald einer dieser sogenannten Honeypots angegriffen wird, werden nützliche Daten über den Angreifer, wie IP-Adressen und Aktionen, ausgewertet und in unseren Datenbanken gespeichert. Neben den Honeypots betreiben wir außerdem den Securepoint Threat-Intelligence-Feed, eine regelmäßig aktualisierte Liste als bösartig eingestufter IP-Adressen.",
    "Enable advanced filter settings": "Erweiterte Filtereinstellungen aktivieren",
    "All Return Codes": "Alle Rückgabecodes",
    "All Request Types": "Alle Anfragetypen",
    "All Clients": "Alle Clients",
    "All Profiles": "Alle Profile",
    "All Countries": "Alle Länder",
    "All Block Reasons": "Alle Blockierungsgründe",
    Rename: "Umbenennen",
    "Please select which mode you would like to use for enrollment.":
        "Bitte wählen Sie aus, über welchen Modus die Anmeldung erfolgen soll.",
    "Local Account": "Lokaler Account",
    "This account must be entered in the ‘E-mail’ field on the device in order to start the device login.":
        "Dieser Account muss auf dem Gerät in das Feld “E-Mail” eingegeben werden, um die Geräte-Anmeldung zu starten.",
    "Gettign default device license...": "Hole Standardgerätelizenz...",
    "No default license has been set for account based user enrollment. The device is therefore currently unable to log into the system.":
        "Es wurde keine Default-Lizenz für das Acccount-basierte Benutzer-Enrollment festgelegt. Zum jetzigen Zeitpunkt kann sich das Gerät daher nicht im System anmelden.",
    "Unknown domain": "Unbekannte Domain",
    "Add DNS": "DNS hinzufügen",
    "Edit DNS": "DNS bearbeiten",
    "Drop File (*.p7m) here": "Datei (*.p7m) hier ablegen",
    "Drop File (*.vpptoken) here": "Datei (*.vpptoken) hier ablegen",
    "Upload Token": "Token hochladen",
    "Your Mobile Security has exceeded. Please upgrade your Mobile Security License.":
        "Ihre Mobile Security-Lizenz wurde überschritten. Bitte aktualisieren Sie Ihre Mobile Security-Lizenz.",
    "No affected devices.": "Keine betroffenen Geräte.",
    "Windows vpn client enrollment aborted": "Windows VPN-Client Enrollment abgebrochen",
    "Add exception rule": "Ausnahmeregel hinzufügen",
    "Other Devices (VPN)": "Andere Geräte (VPN)",
    "Android Devices": "Android Geräte",
    "You currently do not have any valid licenses in order to use the inventory.":
        "Sie haben derzeit keine gültigen Lizenzen, um das Inventar zu nutzen",
    "No objects found": "Keine Objekte gefunden",
    "VPN-Configuration changes reverted": "Änderungen an VPN-Konfiguration verworfen",
    "VPN-Configuration: Changes Reverted": "VPN-Konfiguration: Änderungen verworfen",
    "The changes to the VPN-Configuration '%s' (%s) have been reverted due to a related change made on the UTM '%s' (%s).":
        "Die Änderungen an der VPN-Konfiguration '%s' (%s) wurden aufgrund einer entsprechenden Änderung an der UTM '%s' (%s) rückgängig gemacht.",
    "The changes to the VPN-Configuration %s have been reverted due to a related change made on the UTM %s.":
        "Die Änderungen an der VPN-Konfiguration %s wurden aufgrund einer entsprechenden Änderung an der UTM %s rückgängig gemacht.",
    "Assign as iOS default": "Als iOS-Standard zuweisen",
    "The password may not be empty": "Das Passwort darf nicht leer sein",
    "VPN-Configuration: Updated Public Key":
        "VPN-Konfiguration: Aktualisierter öffentlicher Schlüssel",
    "The public key has been updated on Core-UTM %s.":
        "Der öffentliche Schlüssel wurde auf der Core-UTM %s aktualisiert.",
    "The IP address in the VPN-Configuration %s rule is not within any interface network range of the UTM %s.":
        "Die IP-Adresse in der VPN-Konfiguration %s Regel liegt außerhalb des Netzwerkbereichs einer Schnittstelle der UTM %s",
    "VPN-Configuration: IP address outside UTM network range":
        "VPN-Konfiguration: IP-Adresse außerhalb des UTM-Netzwerkbereichs",
    "Invalid certificate format": "Falsches Zertifikatsformat",
    "Your device is using an invalid configuration ID and is therefore unprotected!":
        "Ihr Gerät verwendet eine ungültige Konfigurations-ID und ist daher ungeschützt!",
    "Wrong password": "Falsches Passwort",
    "Do not set a device name. This means that the device cannot be identified in the statistics and logs.":
        "Keinen Gerätenamen setzen. Das bedeutet, dass das Gerät in den Statistiken und Logs nicht identifiziert werden kann."
}
export default de

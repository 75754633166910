import Tooltip from "./tooltip"

// enables v-tooltip directive
const TooltipDirective = {
    tooltip: <Tooltip | undefined>undefined,
    mounted: (el: Element, binding: any) => {
        if (binding.value) {
            ;(<Tooltip>binding.dir.tooltip) = binding.value
            ;(<Tooltip>binding.dir.tooltip)?.setElement?.(el)
        }
    },
    beforeUnmount(el: any, binding: any, vnode: any) {
        ;(<Tooltip | undefined>binding.dir.tooltip)?.removeEventListeners()
        delete binding.dir.tooltip
    }
}

export default TooltipDirective

<script setup lang="ts">
import { T } from "@/classes/i18n"
import branding from "@/classes/objectTypes/unifiedReporting/branding/branding"
import jsonHelpers from "@/helpers/helpers.json"
import mixinHelpers from "@/helpers/helpers.mixins"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import useRouterStore from "@/router/routerStore"
import moment from "moment"
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue"
import buttonComponent from "../components/button/button.vue"
import loaderComponent from "../components/loader.vue"

const props = defineProps<{
    properties: {
        branding: any
    }
}>()

const activeAccountId = computed(() => {
    return mixinHelpers.getActiveAccountId()
})
const activeTenantdomain = computed(() => {
    return tenantHelpers.getTenantDomain(activeAccountId.value)
})
const activePage = computed(() => {
    return useRouterStore()?.getActivePage
})

const initialized = ref(false)
const loaderText = ref("Loading...")
const preview = ref(<{ name?: any; date?: any }>{})
const lastPreviewDate = ref(0)
const generator = ref({
    isGeneratable: false,
    loading: true
})
const download = ref({
    isPossible: false,
    loading: true
})

const formatDate = function (date: any) {
    return timeHelpers.formatDate(date, timeHelpers.getDateFormatI18n(true))
}
const update = async function (polling: boolean = false, i: number = 0) {
    let previewResult: any = await branding.getPreview(activeAccountId.value)
    preview.value = previewResult
    if (
        previewResult.date != undefined &&
        lastPreviewDate.value < moment(previewResult.date).unix()
    ) {
        lastPreviewDate.value = moment(previewResult.date).unix()
        polling = false
    }
    if (!polling && props.properties.branding.active) {
        generator.value.isGeneratable = true
        generator.value.loading = false
    }
    if (polling && activePage.value == "branding" && initialized.value) {
        setTimeout(
            function () {
                update(true, i + 1)
            },
            i == 0 ? 15000 : 5000
        )
    } else {
        initialized.value = true
    }
}
const generatePreview = async function () {
    await branding.addPreview(activeAccountId.value, props.properties.branding)
    generator.value.loading = true
    update(true, 0)
}
const downloadPreview = function () {
    branding.downloadBrandingPreview(activeTenantdomain.value)
}

onMounted(() => {
    update(false, 0)
})

onBeforeUnmount(() => {
    initialized.value = false
})

watch(lastPreviewDate, (newVal: any, oldVal: any) => {
    if (newVal > oldVal) {
        download.value.isPossible = true
        generator.value.isGeneratable = true
        download.value.loading = false
        generator.value.loading = false
    }
})
</script>
<template>
    <div>
        <template v-if="initialized && !jsonHelpers.isObjectEmpty(preview) && !generator.loading">
            <h4>{{ T("Current preview") }}</h4>
            <table>
                <template v-if="preview.hasOwnProperty('name')">
                    <tr>
                        <th>{{ T("Name") }}:</th>
                        <td>{{ preview.name }}</td>
                    </tr>
                </template>
                <template v-if="preview.hasOwnProperty('date')">
                    <tr>
                        <th>{{ T("Creation date") }}:</th>
                        <td>{{ formatDate(preview.date) }}</td>
                    </tr>
                </template>
                <template v-if="download.isPossible">
                    <tr>
                        <td colspan="2">
                            <buttonComponent
                                class="btn btn-primary btn-loader twist-in"
                                :button-options="{
                                    loading: download.loading,
                                    onClick: downloadPreview,
                                    icon: 'fal fa-fw fa-download',
                                    text: 'Download',
                                    title: 'Download'
                                }"
                            ></buttonComponent>
                        </td>
                    </tr>
                </template>
            </table>
        </template>
        <template v-if="generator.loading">
            <p style="padding: 81px; text-align: center" class="color-red">
                <loaderComponent style="font-size: 2em"></loaderComponent>
            </p>
        </template>
        <template v-if="generator.isGeneratable">
            <buttonComponent
                class="btn btn-primary btn-loader twist-in"
                :button-options="{
                    loading: generator.loading,
                    onClick: generatePreview,
                    icon: 'fal fa-fw fa-cogs',
                    text: T('Generate new Preview'),
                    title: T('Generate new Preview')
                }"
            ></buttonComponent>
        </template>
    </div>
</template>

<script setup lang="ts">
import { useVue } from "@/app"
import { T } from "@/classes/i18n"
import { useStore } from "@/store/vuex.store"
import $ from "jquery"
import { computed, onMounted, ref, watch } from "vue"
import loader from "../components/loader.vue"
import ModalObject from "../components/modals/modalClass"
import inputFile from "../inputtypes/input-file-async"

const activeAccountId = computed(() => {
    return useStore()?.getters.getActiveAccountId
})

const initialized = ref(true)
const file = ref(<any>undefined)
const fileType = ref(<string | undefined>undefined)
const name = ref("")
const password = ref("")

const mode = ref("legacy")
const hasFile = computed(() => {
    return file.value != undefined
})
const needsPassword = ref(false)

const fileError = ref(<undefined | string>undefined)
const nameError = ref(undefined)
const passwordError = ref(undefined)
const fileUploadSuccessful = ref(false)
const formElement = ref(null)

const submit = (modal: ModalObject) => {
    modal.getButton(1)?.disable()
    modal.getButton(1)?.startLoader()
    submitAsForm(modal)
}
const checkFile = (event: any) => {
    if (event.target.files.length > 0) {
        file.value = event.target.files[0]
        fileType.value = event.target.files[0].type
        if (file.value.type.indexOf("pkcs12") != -1) {
            needsPassword.value = true
        } else {
            needsPassword.value = false
        }

        fileError.value = undefined
    } else {
        needsPassword.value = false
        fileError.value = undefined
        file.value = undefined
        fileType.value = undefined
    }
}

const submitAsForm = (modal: ModalObject) => {
    initialized.value = false
    //@ts-ignore
    const form = $(formElement.value as HTMLElement)
    form.off("submit")
        .on("submit", function () {
            let frameName = "upload" + Date.now(),
                frame = $("<iframe></iframe>", {
                    name: frameName,
                    id: frameName,
                    css: {
                        display: "none"
                    }
                })
                    .on("load", function (event) {
                        event.preventDefault()
                        let data: any = $(frame).contents().find("body")[0].innerText
                        let iframe = <HTMLObjectElement>this
                        try {
                            data = JSON.parse(data)
                        } catch (e: any) {
                            /* handled elsewhere */
                        }
                        if (!iframe.parentNode) {
                            return
                        }
                        // fixing Opera 10.53
                        if (
                            iframe.contentDocument &&
                            iframe.contentDocument.body &&
                            iframe.contentDocument.body.innerHTML == "false"
                        ) {
                            return
                        }
                        setTimeout(
                            function () {
                                frame.off("load")
                                frame.remove()
                            },
                            1000 * 60 * 10
                        )
                        // prevent double action
                        if (!data) {
                            return
                        }
                        if (data?.data?.errors?.valid === false) {
                            modal.getButton(1)?.enable()
                            modal.getButton(1)?.stopLoader()
                            if (data.data.errors.errors?.indexOf("format") != -1) {
                                fileError.value = T(data.data.errors.errors)
                            } else if (data.data.errors.errors.indexOf("password") != -1) {
                                passwordError.value = T(data.data.errors.errors)
                            } else {
                                nameError.value = T(data.data.errors.errors)
                            }
                            return
                        }

                        fileUploadSuccessful.value = true
                        let button = modal.getButton(1)
                        if (button) {
                            button.enable()
                            button.stopLoader()
                            button.text = "OK"
                            button.onClick = () => {
                                modal.delete()
                            }
                            modal.buttons?.splice(0, 1)
                        }

                        setTimeout(function () {
                            useVue().$refs?.itemlist?.refresh?.()
                            modal.delete()
                        }, 1000)
                    })
                    .appendTo("body")

            form.attr("target", frameName)
            form.off("submit")
        })
        .trigger("submit")
    initialized.value = true
}

const submitAsJson = () => {}

const checkInput = () => {
    if (name.value.indexOf(" ") != -1) {
        nameError.value = T("The name may not contain spaces")
    } else {
        nameError.value = undefined
    }
    /*
    if (needsPassword.value && password.value.length == 0) {
        passwordError.value = T("The password may not be empty")
    } else {
        passwordError.value = undefined
    }
        */
    if (
        name.value.length >= 3 &&
        name.value.indexOf(" ") == -1 &&
        file.value != undefined &&
        file.value != "" &&
        passwordError.value == undefined
    ) {
        nameError.value = undefined
        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = false
    } else {
        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = true
    }
}

onMounted(() => {
    checkInput()
    initialized.value = true
})

watch(file, function () {
    checkInput()
})
watch(name, function () {
    checkInput()
})
watch(password, function () {
    checkInput()
})

defineExpose({ submit, name })
</script>
<template>
    <div class="content-2">
        <template v-if="initialized">
            <template v-if="fileUploadSuccessful">
                <p class="text-center padding-xs-y-2" style="font-size: 1.25em">
                    <i class="fal fa-check color-green"></i>
                    {{ T("The certificate was successfully uploaded") }}
                </p>
            </template>
            <template v-else>
                <form
                    ref="formElement"
                    enctype="multipart/form-data"
                    :action="
                        '/sms-mgt-api/api/2.0/tenants/' + activeAccountId + '.sms/certificates'
                    "
                    method="POST"
                    v-on:keydown.enter.prevent="submit"
                    autocomplete="false"
                >
                    <input name="type" type="hidden" v-model="fileType" />
                    <div class="row form-group validation-base border-bottom padding-xs-b">
                        <div class="col-lg-4">
                            <label class="control-label" for="add-certificate-name"> Name </label>
                        </div>
                        <div class="col-lg-8">
                            <label class="text">
                                <input
                                    autocomplete="new-name"
                                    id="add-certificate-name"
                                    name="name"
                                    type="text"
                                    class="form-control user-selection"
                                    placeholder="Name"
                                    v-model="name"
                                />
                            </label>
                            <template v-if="nameError">
                                <p class="label bg-red color-white">
                                    {{ nameError }}
                                </p>
                            </template>
                        </div>
                        <div class="col-lg-12 desc">
                            <p class="input-description">
                                {{ T("Der Name der für dieses Zertifikat verwendet wird") }}
                            </p>
                        </div>
                    </div>
                    <div class="row form-group validation-base padding-xs-t">
                        <div class="col-lg-4">
                            <label class="control-label">
                                {{ T("File") }}
                            </label>
                        </div>
                        <div class="col-lg-8">
                            <template v-if="mode == 'base64'">
                                <input-file name="certificate" returntype="base64" v-model="file" />
                            </template>
                            <template v-else>
                                <label class="file">
                                    <input
                                        id="add-certificate-file"
                                        name="certificate"
                                        type="file"
                                        class="form-control user-selection"
                                        placeholder="File"
                                        required
                                        data-validation-property="/data"
                                        v-on:change="checkFile"
                                    />
                                    <div class="uploadbox" :class="{ hasfile: hasFile }">
                                        <template v-if="file">
                                            {{ file.name }}
                                        </template>
                                    </div>
                                </label>
                            </template>
                            <template v-if="fileError">
                                <p class="label bg-red color-white">
                                    {{ fileError }}
                                </p>
                            </template>
                        </div>
                        <div class="col-lg-12 desc">
                            <p class="input-description">
                                {{ T("Das Zertifikat das hinzugefügt werden soll.") }}
                                <br />
                                {{ T("Alle gängigen Zertifikats-Dateiformate werden akzeptiert.") }}
                            </p>
                        </div>
                    </div>

                    <div
                        class="row form-group validation-base border-top padding-xs-y"
                        v-if="needsPassword"
                    >
                        <div class="col-lg-4">
                            <label class="control-label" for="certPassword">{{
                                T("Password")
                            }}</label>
                        </div>
                        <div class="col-lg-8">
                            <label class="text">
                                <input
                                    id="cert-password"
                                    name="password"
                                    type="password"
                                    class="form-control user-selection"
                                    :placeholder="T('Password')"
                                    v-model="password"
                                    autocomplete="new-password"
                                />
                            </label>
                            <template v-if="passwordError">
                                <p class="label bg-red color-white">
                                    {{ passwordError }}
                                </p>
                            </template>
                        </div>
                        <div class="col-lg-12 desc">
                            <p class="input-description">
                                {{ T("Das Passwort des Zertifikats") }}
                            </p>
                        </div>
                    </div>
                </form>
            </template>
        </template>

        <template v-else>
            <div class="text-center padding-xs-y-8 col-xs">
                <div class="text-size-3">
                    <loader class="text-size-2 color-red" />
                </div>
            </div>
        </template>
    </div>
</template>

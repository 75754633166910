<script setup lang="ts">
import { useFeatureStore } from "@/classes/featureStore"
import { T } from "@/classes/i18n"
import { type EnterpriseDevice } from "@/classes/mobileSecurity/enterpriseDevices"
import products from "@/classes/objectTypes"
import { type IosDevice } from "@/classes/objectTypes/mobileSecurity/apple/iosDevices"
import { type Vpn } from "@/classes/objectTypes/mobileSecurity/vpns/vpns"
import { type WindowsVpn } from "@/classes/objectTypes/mobileSecurity/windows/windowsVpns"
import { type UTM } from "@/classes/objectTypes/unifiedReporting/utms/usrUtms"
import { type UscUtm } from "@/classes/unifiedSecurityConsole/uscUtms"
import deviceHelpers from "@/helpers/helpers.devices"
import getterHelpers from "@/helpers/helpers.getters"
import jsonHelpers from "@/helpers/helpers.json"
import licenseHelpers from "@/helpers/helpers.license"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import router from "@/router/router"
import { ActionTypes, useStore } from "@/store/vuex.store"
import moment from "moment"
import { sprintf } from "sprintf-js"
import { computed, onMounted, ref, watch } from "vue"
import Button from "../components/button/button"
import Icon from "../components/icon/icon"
import loaderComponent from "../components/loader.vue"
import tableNext, { type TableEntryButtons, type TableEntryInfo } from "../components/tableNext.vue"
import inputVueSelect from "../inputtypes/input-vue-select.vue"

const dateFormat = timeHelpers.getDateFormatI18n(false)

/*
    Refs
*/

const initialized = ref(false)
const loading = ref(false)
const product = ref(
    <"iosDevices" | "enterpriseDevices" | "vpns" | "all" | "usrUtms" | "windowsVpns" | "uscUtms">(
        "all"
    )
)
const productType = ref(<"mobileSecurity" | "unifiedReporting" | "unifiedSecurityConsole" | "">"")

/*
    Computed
*/

const activeAccountId = computed(() => {
    return getterHelpers.useStore().getters.getActiveAccountId || ""
})
const activeTenantDomain = computed(() => {
    return tenantHelpers.getTenantDomain(activeAccountId.value)
})

/* Mobile Security Devices */

const iosDevices = computed(() => {
    let result = []
    if (productType.value == "mobileSecurity") {
        result = jsonHelpers.copyObject(
            products.mobileSecurity.iosDevices
                .useStore?.()
                .getObjectStoreObjects(activeAccountId.value) || []
        )

        result = result.filter(function (device: any) {
            return device.configured
        })
    }
    return result
})

const enterpriseDevices = computed(() => {
    let result = []
    if (productType.value == "mobileSecurity") {
        result =
            jsonHelpers.copyObject(
                products.mobileSecurity.androidDevices
                    .useStore?.()
                    .getObjectStoreObjects(activeAccountId.value)
            ) || []

        result = result.filter(function (device: any) {
            return device.consent !== "" && device.consent !== undefined
        })
    }
    return result
})

const vpns = computed(() => {
    let result = []
    if (productType.value == "mobileSecurity") {
        result = jsonHelpers.copyObject(
            products.mobileSecurity.vpns
                .useStore?.()
                .getObjectStoreObjects(activeAccountId.value || [])
        )
    }
    return result
})

/* USC Devices */
const utms = computed(() => {
    let result = []
    if (productType.value == "unifiedSecurityConsole") {
        result = jsonHelpers.copyObject(
            useStore().state.session.accounts[activeAccountId.value]?.unifiedSecurityConsole
                ?.uscUtms?.items || []
        )
    }
    return result
})

const windowsVpns = computed(() => {
    let result = []
    if (
        productType.value == "unifiedSecurityConsole" &&
        useFeatureStore().isEnabled("WINDOWS_DEVICES")
    ) {
        result = jsonHelpers.copyObject(
            products.unifiedSecurityConsole.windowsVpns
                .useStore?.()
                .getObjectStoreObjects(activeAccountId.value || [])
        )
    }
    return result
})

/* USR */

const usrUtms = computed(() => {
    let result = []
    if (productType.value == "unifiedReporting") {
        result = jsonHelpers.copyObject(
            products.unifiedReporting.usrUtms
                .useStore?.()
                .getObjectStoreObjects(activeAccountId.value) || []
        )
    }
    return result
})

/* Select Options */

const productTypeSelectOptions = computed(() => {
    let result: selectOption[] = []
    if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ["MDM", "Mobile Security"])) {
        result.push({
            id: "mobileSecurity",
            text: "Mobile Security"
        })
    }

    if (licenseHelpers.hasOneOfLicenses(activeAccountId.value, ["laas"], "valid")) {
        result.push({
            id: "unifiedReporting",
            text: "Unified Reporting"
        })
    }

    if (
        licenseHelpers.hasOneOfLicenses(activeAccountId.value, ["unifiedSecurityConsole"], "valid")
    ) {
        result.push({
            id: "unifiedSecurityConsole",
            text: "Unified Security Console"
        })
    }
    return result
})

const productSelectOptions = computed(() => {
    let result: selectOption[] = []

    switch (productType.value) {
        case "mobileSecurity":
            result.push({
                id: "all",
                text: T("All")
            })
            if (iosDevices.value.length) {
                result.push({
                    id: "iosDevices",
                    text: T("iOS Devices")
                })
            }
            if (enterpriseDevices.value.length) {
                result.push({
                    id: "enterpriseDevices",
                    text: T("Android Devices")
                })
            }
            if (vpns.value.length) {
                result.push({
                    id: "vpns",
                    text: T("Other Devices (VPN)")
                })
            }
            break

        case "unifiedReporting":
            result.push({
                id: "all",
                text: T("All")
            })
            break

        case "unifiedSecurityConsole":
            result.push({
                id: "all",
                text: T("All")
            })

            if (licenseHelpers.hasLicense(activeAccountId.value, "unifiedSecurityConsole")) {
                result.push({
                    id: "uscUtms",
                    text: "UTMs"
                })
            }
            if (
                licenseHelpers.hasOneOfLicenses(activeAccountId.value, [
                    "MDM",
                    "Mobile Security"
                ]) &&
                useFeatureStore().isEnabled("WINDOWS_DEVICES")
            ) {
                result.push({
                    id: "windowsVpns",
                    text: "Windows VPNs"
                })
            }

            break
    }

    return result
})

/* Active Items */
const activeItems = computed(() => {
    let result: any[] = []
    switch (productType.value) {
        case "mobileSecurity":
            if (product.value == "all") {
                result = result.concat(iosDevices.value, enterpriseDevices.value, vpns.value)
            } else if (product.value == "iosDevices") {
                result = iosDevices.value
            } else if (product.value == "enterpriseDevices") {
                result = enterpriseDevices.value
            } else if (product.value == "vpns") {
                result = vpns.value
            }

            break
        case "unifiedReporting":
            result = usrUtms.value
            break
        case "unifiedSecurityConsole":
            if (product.value == "all") {
                result = result.concat(utms.value, windowsVpns.value)
            } else if (product.value == "uscUtms") {
                result = utms.value
            } else if (product.value == "windowsVpns") {
                result = windowsVpns.value
            }
            break
    }
    return result
})

/* Table */

const tableOptions = computed(() => {
    let tableOptions: TableEntryInfo[] = [
        {
            property: "alias",
            text: "Alias",
            displayType: "text",
            getSortValue: (
                entry: Vpn | IosDevice | EnterpriseDevice | WindowsVpn | UscUtm | UTM
            ) => {
                let alias: string = ""

                if (productType.value == "mobileSecurity") {
                    alias = getAliasForObject(entry)
                } else if ("utmId" in entry) {
                    alias = deviceHelpers.getAliasedShortDeviceId(
                        entry.utmId,
                        entry.utmname || "",
                        false
                    )
                } else if ("enrollment" in entry) {
                    if (entry?.alias) {
                        alias = entry.alias
                    } else if (entry.enrollment?.hostname) {
                        alias = entry.enrollment.hostname
                    } else {
                        alias = deviceHelpers.getShortDeviceId(entry.id)
                    }
                }

                return alias
            },
            getValue: (entry: Vpn | IosDevice | EnterpriseDevice | WindowsVpn | UscUtm | UTM) => {
                let alias: string = ""

                if (productType.value == "mobileSecurity") {
                    alias = getAliasForObject(entry)
                } else if ("utmId" in entry) {
                    alias = deviceHelpers.getAliasedShortDeviceId(
                        entry.utmId,
                        entry.utmname || "",
                        false
                    )
                } else if ("enrollment" in entry) {
                    if (entry?.alias) {
                        alias = entry.alias
                    } else if (entry.enrollment?.hostname) {
                        alias = entry.enrollment.hostname
                    } else {
                        alias = deviceHelpers.getShortDeviceId(entry.id)
                    }
                }

                return alias
            }
        },
        {
            property: "model",
            text: () => {
                return productType.value === "mobileSecurity" ? T("Model") : "Hostname"
            },
            displayType: "text",
            getSortValue: (
                entry: Vpn | IosDevice | EnterpriseDevice | WindowsVpn | UscUtm | UTM
            ) => {
                let model = ""
                if (productType.value == "mobileSecurity") {
                    model = getModelForObject(entry)
                } else if ("model" in entry && entry.model) {
                    model = entry.model
                } else if ("enrollment" in entry && entry.enrollment?.hostname) {
                    model = entry.enrollment.hostname
                } else if (
                    "utmId" in entry &&
                    (<any>entry.messages?.["spcli-system-info"])?.hostname
                ) {
                    model = (<any>entry.messages?.["spcli-system-info"]).hostname
                }
                return model == "" ? "zzzzzzzzzzzzzzzzzzzzzzz" : model
            },
            getValue: (entry: Vpn | IosDevice | EnterpriseDevice | WindowsVpn | UscUtm | UTM) => {
                let model = ""
                if (productType.value == "mobileSecurity") {
                    model = getModelForObject(entry)
                } else if ("model" in entry && entry.model) {
                    model = entry.model
                } else if ("enrollment" in entry && entry.enrollment?.hostname) {
                    model = entry.enrollment.hostname
                } else if (
                    "utmId" in entry &&
                    (<any>entry.messages?.["spcli-system-info"])?.hostname
                ) {
                    model = (<any>entry.messages?.["spcli-system-info"]).hostname
                }
                return model
            }
        },
        {
            property: "warrantyEnd",
            text: T("Warranty until"),
            displayType: "text",
            getSortValue: (
                entry: Vpn | IosDevice | EnterpriseDevice | WindowsVpn | UscUtm | UTM
            ) => {
                let warrantyEnd = ""
                if ("inventory" in entry && entry.inventory?.warrantyEndDate) {
                    warrantyEnd = entry.inventory.warrantyEndDate
                }
                return warrantyEnd == "" ? "zzzzzzzzzzzzzzzzzzzzzzz" : warrantyEnd
            },
            getValue: (entry: Vpn | IosDevice | EnterpriseDevice | WindowsVpn | UscUtm | UTM) => {
                let warrantyEnd = ""
                if ("inventory" in entry && entry.inventory?.warrantyEndDate) {
                    warrantyEnd = timestampToDate(entry.inventory.warrantyEndDate)
                }
                return warrantyEnd
            }
        },
        {
            property: "providerName",
            text: T("Provider"),
            displayType: "text",
            getSortValue: (
                entry: Vpn | IosDevice | EnterpriseDevice | WindowsVpn | UscUtm | UTM
            ) => {
                let providerName = ""
                if ("inventory" in entry && entry.inventory?.providerName) {
                    providerName = entry.inventory.providerName
                }
                return providerName == "" ? "zzzzzzzzzzzzzzzzzzzzzzz" : providerName
            },
            getValue: (entry: Vpn | IosDevice | EnterpriseDevice | WindowsVpn | UscUtm | UTM) => {
                let providerName = ""
                if ("inventory" in entry && entry.inventory?.providerName) {
                    providerName = entry.inventory.providerName
                }
                return providerName
            }
        },
        {
            property: "providerTariff",
            text: T("Tariff"),
            displayType: "text",
            getSortValue: (
                entry: Vpn | IosDevice | EnterpriseDevice | WindowsVpn | UscUtm | UTM
            ) => {
                let providerTariff = ""
                if ("inventory" in entry && entry.inventory?.providerTariff) {
                    providerTariff = entry.inventory.providerTariff
                }
                return providerTariff == "" ? "zzzzzzzzzzzzzzzzzzzzzzz" : providerTariff
            },
            getValue: (entry: Vpn | IosDevice | EnterpriseDevice | WindowsVpn | UscUtm | UTM) => {
                let providerTariff = ""
                if ("inventory" in entry && entry.inventory?.providerTariff) {
                    providerTariff = entry.inventory.providerTariff
                }
                return providerTariff
            }
        },
        {
            property: "contractNumber",
            text: T("Contract number"),
            displayType: "text",
            getSortValue: (
                entry: Vpn | IosDevice | EnterpriseDevice | WindowsVpn | UscUtm | UTM
            ) => {
                let contractNumber = ""
                if ("inventory" in entry && entry.inventory?.contractNumber) {
                    contractNumber = entry.inventory.contractNumber
                }
                return contractNumber == "" ? "zzzzzzzzzzzzzzzzzzzzzzz" : contractNumber
            },
            getValue: (entry: Vpn | IosDevice | EnterpriseDevice | WindowsVpn | UscUtm | UTM) => {
                let contractNumber = ""
                if ("inventory" in entry && entry.inventory?.contractNumber) {
                    contractNumber = entry.inventory.contractNumber
                }
                return contractNumber
            }
        },
        {
            property: "contractEnd",
            text: T("End of contract"),
            displayType: "text",
            getSortValue: (
                entry: Vpn | IosDevice | EnterpriseDevice | WindowsVpn | UscUtm | UTM
            ) => {
                let contractEnd = ""
                if (
                    "inventory" in entry &&
                    entry.inventory?.contractDate &&
                    entry?.inventory?.contractDuration
                ) {
                    contractEnd = getAddedMonths(
                        entry.inventory.contractDate,
                        entry.inventory.contractDuration
                    )
                }
                return contractEnd == "" ? "zzzzzzzzzzzzzzzzzzzzzzz" : contractEnd
            },
            getValue: (entry: Vpn | IosDevice | EnterpriseDevice | WindowsVpn | UscUtm | UTM) => {
                let contractEnd = ""
                if (
                    "inventory" in entry &&
                    entry.inventory?.contractDate &&
                    entry?.inventory?.contractDuration
                ) {
                    contractEnd = getAddedMonths(
                        entry.inventory.contractDate,
                        entry.inventory.contractDuration
                    )
                }
                return contractEnd
            }
        },
        {
            property: "action",
            text: T("Actions"),
            displayType: "buttons",
            width: 355,
            getValue: (entry: Vpn | IosDevice | EnterpriseDevice | WindowsVpn | UscUtm | UTM) => {
                const inventoryExternalLink =
                    "inventory" in entry && entry.inventory?.inventoryExternalLink
                        ? entry.inventory.inventoryExternalLink
                        : ""

                let result: TableEntryButtons = [
                    new Button({
                        icon: new Icon({
                            class: "fal fa-edit"
                        }),
                        onClick: () => {
                            router.navigate(getFormLink(entry))
                        },
                        text: T("Edit"),
                        loading: false,
                        disabled: false,
                        title: T("Edit")
                    }),
                    new Button({
                        icon: new Icon({
                            class: "fal fa-link"
                        }),
                        onClick: () => {
                            if (inventoryExternalLink && inventoryExternalLink != "https://") {
                                window.open(inventoryExternalLink, "_blank")
                            }
                        },
                        text: T("Open external inventory link"),
                        loading: false,
                        disabled:
                            inventoryExternalLink == "" || inventoryExternalLink == "https://",
                        title:
                            inventoryExternalLink == "" || inventoryExternalLink == "https://"
                                ? T("No link is configured")
                                : ""
                    })
                ]

                return result
            }
        }
    ]
    return tableOptions
})

/*
    Functions
*/

function getModelForObject(object: any) {
    let thisModel: string = ""
    if (object?.deviceFamily) {
        thisModel = object?.deviceFamily
    } else if (useStore().state.resources.apple.deviceNames[object?.info?.productName]) {
        thisModel = useStore().state.resources.apple.deviceNames[object?.info?.productName]
    } else if (useStore().state.resources.apple.deviceNames[object?.info?.model]) {
        thisModel = useStore().state.resources.apple.deviceNames[object?.info?.model]
    } else {
        thisModel =
            object.deviceType == "IOS" ? object?.info?.productName || "IOS " + T("Device") : "vpn"
    }

    if (object.name && object.name.indexOf("enterprises") != -1) {
        thisModel = object.hardwareInfo?.manufacturer + " - " + object.hardwareInfo?.model
    } else if (object.deviceType != "IOS") {
        thisModel =
            thisModel == "vpn"
                ? T("Other Device (VPN)")
                : sprintf(
                      "%s - %s - %s",
                      object?.info?.manufacturer || "",
                      thisModel || "",
                      (object?.info?.os || "") + " " + (object?.info?.version || "")
                  ) || ""
    }
    return thisModel
}

function getAliasForObject(object: any) {
    let alias =
        object.name?.indexOf("enterprises") != -1
            ? deviceHelpers.getAliasedShortDeviceId(
                  object.alias ? object.deviceId : "",
                  object.alias || deviceHelpers.getShortDeviceId(object.deviceId),
                  false
              )
            : deviceHelpers.getAliasedShortDeviceId(
                  object.vpnId || object.deviceId,
                  object.alias || object.vpnname || "",
                  false
              )

    if (object?.vpnname || object?.vpnId) {
        alias = deviceHelpers.getAliasedShortDeviceId(object.vpnId, object.vpnname || "", false)
    }
    return alias
}

function getFormLink(object: any) {
    let result = ""
    switch (productType.value) {
        case "mobileSecurity":
            result =
                "#edit-tenant-" +
                activeTenantDomain.value +
                "-inventory-" +
                (object?.id != undefined
                    ? "enterprise-" + object.id
                    : object.deviceId != undefined
                      ? "device-" + object.deviceId
                      : "vpn-" + object.vpnId)
            break
        case "unifiedReporting":
            result =
                "#edit-tenant-" +
                activeTenantDomain.value +
                "-inventory-reportingUtm-" +
                object?.utmId
            break
        case "unifiedSecurityConsole":
            if (object?.utmId) {
                result =
                    "#edit-tenant-" +
                    activeTenantDomain.value +
                    "-inventory-usc-utm-" +
                    object.utmId
            } else if (object?.id) {
                result =
                    "#edit-tenant-" +
                    activeTenantDomain.value +
                    "-inventory-windows-vpn-" +
                    object.id
            }
            break
    }
    return result
}

function timestampToDate(timestamp: any) {
    let date: any = ""
    if (timestamp) {
        if (timestamp.length) {
            date = moment.unix(timestamp).format(dateFormat) || undefined
        }
    }
    return date
}

function getAddedMonths(timestamp: any, months: any) {
    return moment.unix(timestamp).add(months, "months").format(dateFormat)
}

async function refresh(force: boolean = false) {
    let objectTypes: string[] = []
    loading.value = true
    switch (productType.value) {
        case "mobileSecurity":
            objectTypes = []

            await products.mobileSecurity.iosDevices.queries.getObjectsFromApi(
                activeAccountId.value,
                undefined,
                [
                    { property: "props[]", value: "deviceId" },
                    { property: "props[]", value: "deviceType" },
                    { property: "props[]", value: "signedIn" },
                    { property: "props[]", value: "configured" },
                    { property: "props[]", value: "props" },
                    { property: "props[]", value: "licenseUUID" },
                    { property: "props[]", value: "inventory" },
                    { property: "props[]", value: "alias" },
                    { property: "props[]", value: "serialNumber" },
                    { property: "props[]", value: "info" },
                    { property: "props[]", value: "deviceFamily" }
                ]
            )
            await products.mobileSecurity.vpns.queries.getObjectsFromApi(activeAccountId.value)
            if (tenantHelpers.hasFunctionality(activeAccountId.value, "enterprise")) {
                await products.mobileSecurity.androidDevices.queries.getObjectsFromApi(
                    activeAccountId.value,
                    undefined,
                    [
                        { property: "props[]", value: "appliedPolicyName" },
                        { property: "props[]", value: "appliedState" },
                        { property: "props[]", value: "enrollmentTime" },
                        { property: "props[]", value: "props" },
                        { property: "props[]", value: "hardwareInfo" },
                        { property: "props[]", value: "lastStatusReportTime" },
                        { property: "props[]", value: "managementMode" },
                        { property: "props[]", value: "name" },
                        { property: "props[]", value: "ownership" },
                        { property: "props[]", value: "policyName" },
                        { property: "props[]", value: "previousDeviceNames" },
                        { property: "props[]", value: "state" },
                        { property: "props[]", value: "alias" },
                        { property: "props[]", value: "deviceId" },
                        { property: "props[]", value: "username" },
                        { property: "props[]", value: "inventory" },
                        { property: "props[]", value: "consent" },
                        { property: "props[]", value: "licenseUUID" },
                        { property: "props[]", value: "signedIn" },
                        {
                            property: "fields",
                            value: "*(name,policyName,appliedPolicyName,hardwareInfo,softwareInfo,lastStatusReportTime,previousDeviceNames,ownership,enrollmentTime,managementMode,networkInfo,nonComplianceDetails,state,appliedState)"
                        }
                    ]
                )
            }
            break
        case "unifiedReporting":
            await products.unifiedReporting.usrUtms.queries.getObjectsFromApi(
                activeAccountId.value,
                undefined,
                [
                    {
                        property: "props[]",
                        value: "dead"
                    },
                    {
                        property: "props[]",
                        value: "license"
                    },
                    {
                        property: "props[]",
                        value: "cluster"
                    },
                    {
                        property: "props[]",
                        value: "inventory"
                    },
                    {
                        property: "props[]",
                        value: "expirationDate"
                    },
                    {
                        property: "props[]",
                        value: "licensed"
                    },
                    {
                        property: "props[]",
                        value: "messages"
                    },
                    {
                        property: "props[]",
                        value: "offlineReason"
                    },
                    {
                        property: "props[]",
                        value: "spare"
                    },
                    {
                        property: "props[]",
                        value: "tags"
                    },
                    {
                        property: "props[]",
                        value: "tenantDomain"
                    },
                    {
                        property: "props[]",
                        value: "utmId"
                    },
                    {
                        property: "props[]",
                        value: "utmname"
                    }
                ]
            )
            break
        case "unifiedSecurityConsole":
            await useStore().dispatch(ActionTypes.getObjectInfos, {
                accountId: activeAccountId.value,
                objectTypes: ["uscUtms"]
            })
            if (useFeatureStore().isEnabled("WINDOWS_DEVICES")) {
                await products.unifiedSecurityConsole.windowsVpns.queries.getObjectsFromApi(
                    activeAccountId.value,
                    undefined,
                    [
                        {
                            property: "props[]",
                            value: "id"
                        },
                        {
                            property: "props[]",
                            value: "alias"
                        },
                        {
                            property: "props[]",
                            value: "inventory"
                        },
                        {
                            property: "props[]",
                            value: "enrollment"
                        }
                    ]
                )
                break
            }
    }

    initialized.value = true
    loading.value = false
}

/*
    Lifecycles
*/

onMounted(() => {
    if (
        Array.isArray(productTypeSelectOptions.value) &&
        productTypeSelectOptions.value[0] &&
        productType.value == ""
    ) {
        productType.value = productTypeSelectOptions.value[0].id as
            | "mobileSecurity"
            | "unifiedReporting"
            | "unifiedSecurityConsole"
    }
    refresh()
})

/*
    Watches
*/

watch(
    productType,
    () => {
        if (initialized.value) {
            refresh()
            product.value = "all"
        }
    },
    { deep: true }
)
</script>
<template>
    <div>
        <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
            <div class="row">
                <div class="col-xs-24">
                    <div class="box bg-white padding-xs-2">
                        <template v-if="initialized && productTypeSelectOptions.length">
                            <h3>
                                {{ T("Productselection") }}
                            </h3>
                            <div
                                class="row padding-xs-y form-group form-entry-row-search border-bottom validation-base"
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label for="productSelection" class="control-label">
                                        {{ T("Product") }}
                                    </label>
                                </div>

                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="form-field select">
                                        <inputVueSelect
                                            v-model="productType"
                                            :select-options="productTypeSelectOptions"
                                            :disabled="productTypeSelectOptions.length <= 2"
                                        ></inputVueSelect>
                                    </label>
                                </div>

                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="form-field select">
                                        <inputVueSelect
                                            v-model="product"
                                            :select-options="productSelectOptions"
                                            :disabled="productSelectOptions.length <= 2"
                                        ></inputVueSelect>
                                    </label>
                                </div>
                            </div>
                            <h3>
                                {{ T("Inventory list") }}
                            </h3>
                            <tableNext
                                :max-height="500"
                                :rows-selectable="false"
                                :min-height="500"
                                :object-list="activeItems"
                                :selectable-columns="tableOptions"
                                :is-loading="loading"
                            >
                            </tableNext>
                        </template>

                        <div
                            v-else-if="initialized == false || loading == true"
                            class="loaderbox text-center"
                        >
                            <loaderComponent class="color-red"></loaderComponent>
                        </div>

                        <div v-else>
                            {{
                                T(
                                    "You currently do not have any valid licenses in order to use the inventory."
                                )
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

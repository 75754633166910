<template>
    <div class="elementContainer">
        <div class="elementContainerTitleContainer">
            <div class="elementContainerTitle">
                <p class="elementContainerTitleText" v-if="currentDisplay">
                    <i class="fal fa-table"></i> {{ T("Port attack statistics (tabular)") }}
                </p>
                <p class="elementContainerTitleText" v-else>
                    <i class="fal fa-chart-pie"></i>
                    {{ T("Port attack statistics (historical)") }}
                </p>
                <div style="display: flex; height: 50px; line-height: 50px">
                    <p class="elementContainerTitleTime"></p>
                    <div class="ElementContainerInfoContainer hasChangeDisplayButton">
                        <i
                            @click="callPopUp()"
                            class="fal fa-info-circle gtvInfoCircle"
                            :title="T('Further Information')"
                        ></i>
                        <i
                            v-if="!currentDisplay"
                            class="fal fa-table switchDisplayIcon"
                            @click="currentDisplay = !currentDisplay"
                            :title="T('switch display')"
                        ></i>
                        <i
                            v-else
                            class="fal fa-chart-pie switchDisplayIcon"
                            @click="currentDisplay = !currentDisplay"
                            :title="T('switch display')"
                        ></i>
                    </div>
                </div>
            </div>
            <hr />
        </div>
        <template v-if="!historicalChart.loading">
            <!-- @vue-ignore -->
            <template v-if="historicalChart.chartOptions.series![0].data.length == 0">
                <div
                    style="
                        text-align: center;
                        font-size: 2em;
                        padding: 130px 0;
                        opacity: 0.7;
                        color: var(--font-Color);
                    "
                >
                    {{ T("No Data available") }}
                </div>
            </template>
            <template v-else>
                <template v-if="!currentDisplay">
                    <div class="historyMainDiv">
                        <div class="chartDiv">
                            <highcharts
                                class="highcharts-historical-port"
                                :options="historicalChart.chartOptions"
                            >
                            </highcharts>
                        </div>
                        <div class="sliderDiv">
                            <input
                                step="1"
                                type="range"
                                :min="0"
                                :max="DataRaw.length - 1"
                                class="slider"
                                id="portSlide"
                                v-model="sliderValue"
                            />
                            <button class="animationButton" @click="runAnimation">
                                <i class="fa-solid fa-play" v-if="!animationRunning"></i>
                                <i class="fa-solid fa-stop" v-else></i>
                            </button>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <table
                        class="portHistoryTableHead portHistoryTable"
                        v-bind:class="currentDisplay ? 'tableViewActive' : ''"
                    >
                        <thead>
                            <tr>
                                <th class="col-1 th" @click="sortNum('Port', portSort)">
                                    {{ T("Port") }}
                                    <i
                                        class="fal fa-sort sortPortButton"
                                        v-if="currentSortMethod == portSort"
                                    ></i>
                                    <i class="fal fa-sort sortButtonUnselected" v-else></i>
                                </th>
                                <th
                                    class="col-2 th"
                                    @click="sortNum('Logged Port', loggedPortsSort)"
                                >
                                    {{ T("Logged ports") }}
                                    <i
                                        class="fal fa-sort sortLogPortButton"
                                        v-if="currentSortMethod == loggedPortsSort"
                                    ></i>
                                    <i class="fal fa-sort sortButtonUnselected" v-else></i>
                                </th>
                                <th class="col-3 th" @click="sortNum('Percentage', percentageSort)">
                                    {{ T("Percentage") }}
                                    <i
                                        class="fal fa-sort sortPercButton"
                                        v-if="currentSortMethod == percentageSort"
                                    ></i>
                                    <i class="fal fa-sort sortButtonUnselected" v-else></i>
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <div
                        class="portHistoryTableBodyWrapper"
                        v-bind:class="currentDisplay ? 'tableViewActive' : ''"
                    >
                        <table class="portHistoryTable">
                            <tbody>
                                <tr
                                    v-for="(i, index) in portDataArray"
                                    :style="'--index: ' + index"
                                    v-bind:class="currentDisplay ? 'tableViewActive' : ''"
                                >
                                    <td class="col-1">{{ formatPortProtocol(i[0]) }}</td>
                                    <td class="col-2">{{ i[1] }}</td>
                                    <td class="col-3">
                                        {{
                                            Intl.NumberFormat("en-EN", {
                                                maximumSignificantDigits: 2
                                            }).format(Number(i[2]) * 100)
                                        }}%
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </template>
        </template>
        <template v-else>
            <div style="text-align: center; padding: 130px 0">
                <loader />
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import i18n, { T } from "@/classes/i18n"
import dialogs from "@/dialogs/dialogs"
import getterHelpers from "@/helpers/helpers.getters"
import requestHandler from "@/queries/requests"
import type { Options } from "highcharts"
import { computed, onMounted, Ref, ref, watch } from "vue"
import portData from "../assets/dictonaries/ports_protocols.json"
import config from "../config/frontendConfig.json"
import loader from "./loader.vue"

interface iPortsObj {
    portNumber: string
    totalPorts: number
    percentage: number
}

const props = defineProps({
    apiRoute: String
})
const sortedPort = ref(true)
const currentSortMethod: Ref<string> = ref("")
const portSort = ".sortPortButton"
const loggedPortsSort = ".sortLogPortButton"
const percentageSort = ".sortPercButton"
const currentDisplay: Ref<boolean> = ref(false)
const animationRunning: Ref<Boolean> = ref(false)
const fetchProp = ref()
const DataRaw = ref<any[]>([])
const sliderValue: Ref<number> = ref(0)
const chartTitle: Ref<string> = ref("")
const portArray: Ref<(number | string)[][]> = ref([])
const LANG = i18n.getLanguage()
const activeAccountId = computed(() => {
    return getterHelpers.useStore()?.getters.getActiveAccountId
})
let animationInterval
let animationIterationCount = 0
let maxSliderLength = computed(() => DataRaw.value.length - 1)

//@ts-ignore
let pieOptions = (<Highcharts.Options>{
    chart: {
        type: "pie"
    },
    accessibility: {
        enabled: false
    },
    title: {
        text: ""
    },
    series: [
        {
            colorByPoint: true,
            dataLabels: {
                enabled: true,
                formatter: function () {
                    return `${formatPortProtocol((this as any).key)} `
                },
                style: {
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#0009"
                }
            }
        }
    ],
    legend: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    tooltip: {
        enabled: true,
        formatter: function () {
            const _this = this as any
            let ret = ""
            ret += `<span style="color:var(--font-Color); font-size:12px"><b>${formatPortProtocol(_this.key)}</b></span></b><br>`
            ret += `<b><span style="color:var(--font-Color); font-size:12px">${T("Blocked ports")}:</span</b> <span style="color:var(--font-Color); font-size:12px">${new Intl.NumberFormat("de-DE").format(_this.point.y)}</span><br>`
            ret += `<b><span style="color:var(--font-Color); font-size:12px">${T("Percentage")}:</span</b> <span style="color:var(--font-Color); font-size:12px">${new Intl.NumberFormat("en-EN", { maximumSignificantDigits: 2 }).format(Number(portDataArray.value[_this.point.x][2]) * 100) + " %"}</span><br>`
            return ret
        },
        useHTML: true
    }
}) as Options & { series: (Options["series"] & { data: number[] })[] }
let historicalChart = ref({
    loading: true,
    chartOptions: pieOptions
})

try {
    DataRaw.value = await getAPIData()
    DataRaw.value.forEach((element, index) => {
        if (Object.keys(element.data).length == 0) {
            DataRaw.value.splice(index, 1)
        }
    })
} catch (err) {
    console.error(err)
}

onMounted(() => {
    sliderValue.value = 0
    updateHistoricalChart()
    getTimestamp(sliderValue.value)
    if (props.apiRoute) {
        fetchProp.value = props.apiRoute
    }
})
watch(sliderValue, (newVal) => {
    updateHistoricalChart()
    getTimestamp(newVal)
})
const portDataArray = computed(() => {
    const top20 = getData()
    portArray.value = top20.map((element) => [
        element.portNumber,
        element.totalPorts,
        element.percentage
    ])
    return portArray.value
})
async function runAnimation() {
    try {
        clearInterval(animationInterval)
        const sliderElement = document.getElementById("portSlide") as HTMLInputElement
        if (!animationRunning.value) {
            animationRunning.value = true
            animationIterationCount = 0
            animationInterval = setInterval(() => {
                if (animationIterationCount > maxSliderLength.value || currentDisplay.value) {
                    clearInterval(animationInterval)
                    animationIterationCount = 0
                    animationRunning.value = false
                } else {
                    sliderElement.value = animationIterationCount.toString()
                    sliderValue.value = animationIterationCount
                    animationIterationCount++
                }
            }, 500)
        } else {
            clearInterval(animationInterval)
            animationRunning.value = false
        }
    } catch (err) {
        console.error(err)
    } finally {
        animationIterationCount = 0
    }
}
async function getAPIData() {
    const data: any[] = await requestHandler.request("GET", config.historicalPortData)
    return data
}
function callPopUp() {
    dialogs.misc.confirmDialog(
        activeAccountId.value,
        T("Port attack statistics"),
        T(
            "This histogram shows the ports or protocols attacked in the last 100 hours. The data records can be switched through hourly using the slider. By pressing the button at the top right, the current data record will be displayed as a table."
        ),
        () => {
            return true
        },
        "",
        T("Close"),
        "",
        false,
        "fal fa-info-circle"
    )
}
function getTimestamp(dataIndex: number) {
    if (!DataRaw.value.length) return []

    const dateObj = new Date(Number(DataRaw.value[dataIndex].timeStamp))

    if (LANG == "de") {
        const year = dateObj.getFullYear()
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0")
        const day = dateObj.getDate().toString().padStart(2, "0")

        const hour = dateObj.getHours().toString().padStart(2, "0")
        const minute = dateObj.getMinutes().toString().padStart(2, "0")
        const timeDataDate: string = `${day}.${month}.${year} ${hour}:${minute}`
        chartTitle.value = timeDataDate
    } else {
        const ustime = dateObj.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true
        })
        const year = dateObj.getFullYear()
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0")
        const day = dateObj.getDate().toString().padStart(2, "0")

        const timeDataDate: string = `${day}/${month}/${year} ${ustime}`
        chartTitle.value = timeDataDate
    }
    const timeElements = document.querySelectorAll(".elementContainerTitleTime")
    timeElements.forEach((el) => {
        el.textContent = `${chartTitle.value}`
    })
}
function getData() {
    if (!DataRaw.value.length) return []
    const valueSlider = sliderValue.value
    const data = DataRaw.value[valueSlider].port

    const blockedPort = Object.entries(data).map(([port, totalPorts]) => {
        return {
            portNumber: port,
            totalPorts: totalPorts as number,
            percentage: (totalPorts as number) / data.total
        }
    })

    const sortedBlockedPort = blockedPort.sort((a, b) => b.totalPorts - a.totalPorts)

    let filteredBlockedIp = sortedBlockedPort.filter(
        (ipData) => ipData.portNumber !== "timestamp" && ipData.portNumber !== "total"
    ) // Deletes timestamp
    filteredBlockedIp.sort((a, b) => b.totalPorts - a.totalPorts)
    if (!currentDisplay.value) {
        var length = 8
        return filteredBlockedIp.slice(0, length)
    } else {
        return filteredBlockedIp
    }
}
function updateHistoricalChart() {
    if (historicalChart.value.chartOptions.series) {
        //@ts-ignore
        historicalChart.value.chartOptions.series[0].data = portDataArray.value.slice(0, 8)
        historicalChart.value.loading = false
    }
}

function formatPortProtocol(portNumber: number | string): string {
    let port = ""
    try {
        port = typeof portNumber === "string" ? portNumber : portNumber.toString()

        const portInfo = portData.find((entry) => entry.port.toString() === port)
        const description = portInfo ? portInfo.service : ""
        if (description === "") {
            return `${port}`
        }
        return `${port} \t (${description})`
    } catch {
        return `${port}`
    }
}

function sortNum(columnPortName: string, sortName: string) {
    if (columnPortName === "Port") {
        if (sortedPort.value) {
            sortedPort.value = false
            sortIcon(sortedPort.value, sortName)
            let sort1 = portArray.value.sort((a, b) => Number(a[0]) - Number(b[0]))
            return sort1
        } else {
            sortedPort.value = true
            sortIcon(sortedPort.value, sortName)
            let sort2 = portArray.value?.sort((a, b) => Number(b[0]) - Number(a[0]))
            return sort2
        }
    } else if (columnPortName === "Logged Port") {
        if (sortedPort.value) {
            sortedPort.value = false
            sortIcon(sortedPort.value, sortName)
            let sort1 = portArray.value.sort((a, b) => Number(a[1]) - Number(b[1]))
            return sort1
        } else {
            sortedPort.value = true
            sortIcon(sortedPort.value, sortName)
            let sort2 = portArray.value?.sort((a, b) => Number(b[1]) - Number(a[1]))
            return sort2
        }
    } else {
        if (sortedPort.value) {
            sortedPort.value = false
            sortIcon(sortedPort.value, sortName)
            let sort1 = portArray.value.sort((a, b) => Number(a[2]) - Number(b[2]))
            return sort1
        } else {
            sortedPort.value = true
            sortIcon(sortedPort.value, sortName)
            let sort2 = portArray.value?.sort((a, b) => Number(b[2]) - Number(a[2]))
            return sort2
        }
    }
}

function sortIcon(isSorted: boolean, columnName: string) {
    currentSortMethod.value = columnName

    let sortIconDiv: any = document.querySelector(columnName)

    const closest = sortIconDiv.closest("i")

    closest.classList.add("sortIcon")

    if (closest.classList.contains("fa-sort")) closest.classList.remove("fa-sort")

    if (isSorted) {
        if (closest.classList.contains("fa-sort-up")) closest.classList.toggle("fa-sort-up")

        closest.classList.toggle("fa-sort-down")
    } else {
        if (closest.classList.contains("fa-sort-down")) closest.classList.toggle("fa-sort-down")

        closest.classList.toggle("fa-sort-up")
    }
}
</script>

<style scoped>
.portHistoryTableBodyWrapper {
    overflow: hidden scroll;
    width: 95%;
    margin-bottom: 25px;
}

.portHistoryTableHead {
    margin: 0;
    width: 95%;
}

.portHistoryTable {
    border-collapse: collapse;
    background-color: var(--table-Background);
    color: var(--font-Color);
    margin: 0;

    td,
    th,
    tr {
        padding: 8px;
    }

    th:hover {
        cursor: pointer;
        background-color: var(--table-Background-nth);
    }
    th {
        background-color: var(--table-Background);
    }
    tr:nth-child(odd) {
        background-color: var(--table-Background-nth);
    }

    tr {
        text-align: left;
    }
}
.col-1 {
    width: 33%;
}

.col-2 {
    width: 33%;
}

.col-3 {
    width: 33%;
}
thead {
    background-color: var(--table-Background-nth);
}
.th {
    font-weight: bold;
}
p {
    font-size: 20px;
}

.historyMainDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.chartDiv {
    height: 90%;
    width: 90%;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.slider {
    grid-area: slider;
    width: 100%;
    border: none;
    z-index: 10;
}

.sliderDiv {
    display: flex;
    justify-content: space-evenly;
    margin: 0 30px;
    margin-top: 7px;
}

.sliderDiv:hover {
    .slider::-webkit-slider-thumb {
        width: 25px;
        height: 25px;
        transition-timing-function: ease;
    }

    .slider::-moz-range-thumb {
        width: 25px;
        height: 25px;
        transition-timing-function: ease;
    }
}

.slider {
    grid-area: slider;
    width: 80%;
    border: none;
    z-index: 10;
    border: 1px solid rgba(255, 255, 255, 0.125);
}

.slider:hover {
    opacity: 1;
}
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #ff5b5b;
    box-shadow: 5px 5px 20px 1px rgba(00, 00, 00, 0.15);
    border-radius: 50%;
    cursor: pointer;
}
.slider::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    border: none;
    width: 20px;
    height: 20px;
    background: #ff5b5b;
    box-shadow: 5px 5px 20px 1px rgba(00, 00, 00, 0.15);
    border-radius: 50%;
    cursor: pointer;
}
</style>

<style>
.darkmode {
    .highcharts-historical-port {
        text {
            color: rgba(255, 255, 255, 0.8) !important;
            fill: rgba(255, 255, 255, 0.8) !important;
        }
    }

    .highcharts-tooltip-box {
        fill: #26292d !important;
        color: var(--font-Color);
    }
}

.elementContainerTitleTime {
    color: var(--font-Color);
    font-weight: 400;
    margin-right: 15px;
    margin-top: 0;
    font-size: 19px;
}

.highcharts-historical-port {
    height: 280px;
    width: 95%;

    .highcharts-tooltip-box {
        stroke: none;
    }

    path.highcharts-point {
        stroke: none;
    }

    .highcharts-grid-line {
        stroke: #ccd6eb !important;
    }

    .highcharts-axis-line {
        stroke: #ccd6eb !important;
    }

    .highcharts-text-outline {
        border: none;
        stroke: none;
        fill: none;
        color: rgba(0, 0, 0, 0.8);
    }

    .highcharts-background {
        fill: none !important;
    }
}
</style>
<style scoped>
.portHistoryTableBodyWrapper {
    overflow: hidden scroll;
    width: 95%;
    margin-bottom: 25px;
}

.portHistoryTableHead {
    margin: 0;
    width: 95%;
}

.portHistoryTable {
    border-collapse: collapse;
    background-color: var(--table-Background);
    color: var(--font-Color);
    margin: 0;

    td,
    th,
    tr {
        padding: 8px;
    }

    th:hover {
        cursor: pointer;
        background-color: var(--table-Background-nth);

        .sortPortButton {
            opacity: 100%;
        }

        .sortLogPortButton {
            opacity: 100%;
        }

        .sortPercButton {
            opacity: 100%;
        }
    }
    th {
        background-color: var(--table-Background);
    }
    tr:nth-child(odd) {
        background-color: var(--table-Background-nth);
    }

    tr {
        text-align: left;
    }
}

.sortButtonUnselected {
    opacity: 20%;
    float: right;
}

.sortPortButton {
    opacity: 20%;
    float: right;
}

.sortLogPortButton {
    opacity: 20%;
    float: right;
}

.sortPercButton {
    opacity: 20%;
    float: right;
}

.sortIcon {
    opacity: 100%;
}

.col-1 {
    width: 33%;
}

.col-2 {
    width: 33%;
}

.col-3 {
    width: 33%;
}
thead {
    background-color: var(--table-Background-nth);
}
.th {
    font-weight: bold;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>

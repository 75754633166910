<script setup lang="ts">
import stringHelpers from "@/helpers/helpers.strings"
import { createPopper, preventOverflow, type Instance as PopperInstance } from "@popperjs/core"
import { computed, onBeforeUnmount, ref, watch } from "vue"

const props = defineProps<{
    isTag?: string
    tooltip: string | string[] | undefined
    htmlTooltip?: boolean
    boundary?: null | HTMLElement
    customId?: string
    removeOnBeforeUnmount?: true
}>()

const tooltipWrapper = ref(<null | HTMLElement>null)

const isHovering = ref(false)
const isVisible = ref(false)
const randomId = stringHelpers.generateUUID()
const tooltipId = computed(() => {
    return props.customId ? props.customId : randomId
})

const popperObject = ref(<PopperInstance | undefined>undefined)

watch(isHovering, () => {
    if (isHovering.value) {
        isVisible.value = true
    } else {
        isVisible.value = false
    }
})

watch(isVisible, () => {
    if (isVisible.value === true && props.tooltip) {
        let bodyEl = document.getElementsByTagName("body")[0]
        let newEl = document.createElement("span")
        newEl.classList.add("tooltipContent")
        newEl.id = "tooltip#" + tooltipId.value
        if (props.htmlTooltip) {
            newEl.innerHTML = "<span>" + props.tooltip + ' </span><span class="arrow"></span>'
        } else {
            newEl.innerHTML = '<span></span><span class="arrow"></span>'
            if (typeof props.tooltip == "string") {
                newEl.children[0].textContent = props.tooltip
            } else if (Array.isArray(props.tooltip)) {
                props.tooltip.forEach((tooltip: string) => {
                    let thisSpan = document.createElement("span")
                    let thisBr = document.createElement("br")
                    thisSpan.textContent = tooltip

                    newEl.children[0].appendChild(thisSpan)
                    newEl.children[0].appendChild(thisBr)
                })
            }
        }
        bodyEl.appendChild(newEl)

        let contentEl = document.getElementById("tooltip#" + tooltipId.value)
        if (tooltipWrapper.value && contentEl) {
            popperObject.value = createPopper(tooltipWrapper.value, contentEl, {
                placement: "bottom",
                onFirstUpdate: (e) => {
                    setTimeout(() => {
                        e.elements?.popper.classList.add("show")
                    }, 500)
                },
                modifiers: [
                    preventOverflow,
                    {
                        name: "offset",
                        options: {
                            offset: [0, 12]
                        }
                    }
                ]
            })
        }
    } else {
        let contentEl = document.getElementById("tooltip#" + tooltipId.value)
        if (contentEl) {
            contentEl.remove()
        }
    }
})

onBeforeUnmount(() => {
    if (props.removeOnBeforeUnmount) {
        let contentEl = document.getElementById("tooltip#" + tooltipId.value)
        if (contentEl) {
            contentEl.remove()
        }
    }
})
</script>
<template>
    <component
        :is="props.isTag || 'span'"
        class="betterTooltip"
        ref="tooltipWrapper"
        @mouseover="isHovering = true"
        @mouseleave="isHovering = false"
    >
        <slot> </slot>
    </component>
</template>

<style lang="scss">
.betterTooltip {
    position: relative;
    display: inline-block;
}
.tooltipContent {
    pointer-events: none;
    display: block;
    position: absolute;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.7);
    padding: 8px;
    width: max-content;
    line-height: 1em;
    border-radius: 4px;
    color: #fff;
    max-width: 400px;
    transition-delay: 0.5s;
    transition: opacity 0.5s transform 0.5s;
    opacity: 0;
    transform: scale(0, 0);
    &.show {
        opacity: 1;
        transform: scale(1, 1);
    }
}
</style>

<script setup lang="ts">
import { useVue } from "@/app"
import config from "@/classes/config"
import { GenericObjectStore } from "@/classes/genericObjectStore"
import { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import products, { allObjectTypes } from "@/classes/objectTypes"
import ObjectType from "@/classes/objectTypes/objectType"
import deviceHelpers from "@/helpers/helpers.devices"
import jsonHelpers from "@/helpers/helpers.json"
import mixinHelpers from "@/helpers/helpers.mixins"
import stringHelpers from "@/helpers/helpers.strings"
import tenantHelpers from "@/helpers/helpers.tenants"
import useRouterStore from "@/router/routerStore"
import { useStore } from "@/store/vuex.store"
import { computed, onMounted, ref, watch } from "vue"
import loader from "../components/loader.vue"

const props = withDefaults(
    defineProps<{
        scrollDistance: number
    }>(),
    {
        scrollDistance: 0
    }
)

const isEditPage = ref(false)
const titleCharLength = ref(100)

const activePage = computed(() => {
    return useRouterStore().getActivePage
})
const objectId = computed(() => {
    return useRouterStore().getObjectId
})
const context = computed(() => {
    return useRouterStore().getContext
})
const activeAccountId = computed(() => {
    return useStore().getters.getActiveAccountId
})
const activeAccountName = computed(() => {
    return useStore().getters.getAccountInfo(activeAccountId.value)?.accountname || ""
})
const pages = computed(() => {
    return useStore().state.resources.pages
})
const objectTypeStore = computed(() => {
    if ((context.value || "").includes("edit")) {
        const objectType = mixinHelpers.getObjectType() || ""
        const productType = mixinHelpers.getProductType() || ""
        let newProductType = config.canUseNewObjectType(objectType)
            ? products[productType as keyof typeof products]
            : undefined
        let newObjectType = newProductType?.[objectType as keyof typeof newProductType]

        if (newObjectType) return newObjectType as ObjectType<any>
        else if (objectStores[objectType as keyof typeof objectStores])
            return objectStores[objectType as keyof typeof objectStores] as GenericObjectStore<any>
        else return undefined
    } else return undefined
})

const activeItem = computed(() => {
    if (objectTypeStore.value == undefined) return undefined
    else if (objectTypeStore.value instanceof ObjectType)
        return objectTypeStore.value
            .useStore?.()
            .getObjectStoreObject(activeAccountId.value, objectId.value || "")
    else if (objectTypeStore.value instanceof GenericObjectStore)
        return objectTypeStore.value.getObjectFromStore(activeAccountId.value, objectId.value)
    else return undefined
})

const labels = computed(() => {
    if ((context.value || "").includes("edit") && isEditPage.value) {
        if (objectTypeStore.value instanceof ObjectType)
            return objectTypeStore.value.itemlistItem
                .getLabels(activeAccountId.value, activeItem.value)
                .filter((label) => {
                    return (
                        typeof label.class == "string" &&
                        (label.class.includes("red") ||
                            label.class.includes("green") ||
                            label.class.includes("yellow") ||
                            label.class.includes("blue"))
                    )
                })
        else if (objectTypeStore.value instanceof GenericObjectStore)
            return objectTypeStore.value.itemlist
                .getLabels(activeAccountId.value, activeItem.value)
                .filter((label) => {
                    return (
                        typeof label.class == "string" &&
                        (label.class.includes("red") ||
                            label.class.includes("green") ||
                            label.class.includes("yellow") ||
                            label.class.includes("blue"))
                    )
                })
        else return []
    } else return []
})

const breadcrumbArray = computed(() => {
    function genReversePages(currentPage: any, pages: any) {
        let reversePages: any = []
        let reversedPages: any = []
        if (pages[currentPage]) {
            reversePages.push(currentPage)
            if (pages[currentPage].parent != null) {
                let childPages: any = genReversePages(pages[currentPage].parent, pages)
                for (const childPage of childPages) {
                    if (pages[childPage].link != "#") {
                        reversePages.push(childPage)
                    }
                }
            }
            reversedPages = reversePages.reverse()
        }
        return reversedPages
    }
    return genReversePages(activePage.value, pages.value)
})

const getTitleMaxWidth = () => {
    if (objectTitle.value != undefined) {
        const textLengthRelativeToWrapper = document.querySelector(
            "[data-key='textLengthRelativeToThis']"
        )
        if (textLengthRelativeToWrapper) {
            return textLengthRelativeToWrapper.clientWidth.valueOf()
        }
        return 0
    }
    return 0
}
const getTitleWidth = () => {
    if (objectTitle.value != undefined) {
        const textLengthWrapper = document.querySelector("[data-key='textLength']")
        if (textLengthWrapper) {
            return textLengthWrapper.clientWidth.valueOf()
        }
        return 0
    }
    return 0
}

const setIsEditPage = () => {
    setTimeout(() => {
        if (useVue()?.$refs?.editpage != undefined) isEditPage.value = true
        else isEditPage.value = false
    }, 1)
}

const updateTitleCharLength = (timeout = 0, from = "unknown") => {
    setTimeout(() => {
        if ((getTitleWidth() || 0) >= (getTitleMaxWidth() || 600)) {
            titleCharLength.value = titleCharLength.value - 5
            updateTitleCharLength(0, from)
        } else if (
            objectTitle.value.length > titleCharLength.value &&
            (getTitleWidth() || 0) <= (getTitleMaxWidth() || 600) &&
            (getTitleMaxWidth() || 0) - (getTitleWidth() || 0) > 30
        ) {
            titleCharLength.value = titleCharLength.value + 1
            updateTitleCharLength(0, from)
        }
    }, timeout)
}

const objectTitle = computed(() => {
    let result: string | any = ""
    if (activePage.value && objectId.value) {
        let namePropertyInfo: any = tenantHelpers.getObjectNamePropertyForObjectType(
            activePage.value
        )

        if (
            allObjectTypes[activePage.value] != undefined &&
            config.canUseNewObjectType(activePage.value)
        ) {
            let thisObjectType = allObjectTypes[activePage.value]
            const object = thisObjectType
                .useStore?.()
                .getObjectStoreObject(activeAccountId.value, objectId.value)
            let thisTitleInfo = thisObjectType.itemlistItem.getTitle(object, undefined)
            result =
                thisTitleInfo.title + (thisTitleInfo.small ? " (" + thisTitleInfo.small + ")" : "")
        } else if (
            objectStores[activePage.value as keyof typeof objectStores] &&
            objectStores[activePage.value as keyof typeof objectStores]?.itemlist?.getTitle !=
                undefined
        ) {
            let thisObjectType = objectStores[activePage.value as keyof typeof objectStores]
            const object = useStore().getters.getObject({
                accountId: activeAccountId.value,
                objectType: activePage.value,
                objectId: objectId.value
            })
            let thisTitleInfo = thisObjectType.itemlist.getTitle(object, undefined)
            result =
                thisTitleInfo.title + (thisTitleInfo.small ? " (" + thisTitleInfo.small + ")" : "")
        } else if (namePropertyInfo) {
            let primaryNameProperty: string = namePropertyInfo.primary
            let object = useStore().getters.getObject({
                accountId: activeAccountId.value,
                objectType: activePage.value,
                objectId: objectId.value
            })

            result = object ? jsonHelpers.getObjectProperty(object, primaryNameProperty) || "" : ""
        } else if (["androidProfiles"].indexOf(activePage.value) != -1) {
            result = config.canUseNewObjectType("androidProfiles")
                ? objectId.value
                : objectId.value.replace("enterprise_", "")
        } else if (["inventory"].indexOf(activePage.value) != -1) {
            let utm: any = useStore().getters.getObject({
                accountId: activeAccountId.value,
                objectType: "uscUtms",
                objectId: objectId.value
            })
            if (!utm) {
                utm = useStore().getters.getObject({
                    accountId: activeAccountId.value,
                    objectType: "utms",
                    objectId: objectId.value
                })
            }
            const device: any = config.canUseNewObjectType("iosDevices")
                ? products.mobileSecurity.iosDevices
                      .useStore?.()
                      .getObjectStoreObject(activeAccountId.value, objectId.value)
                : useStore().getters.getObject({
                      accountId: activeAccountId.value,
                      objectType: "devices",
                      objectId: objectId.value
                  })
            const vpn: any = config.canUseNewObjectType("vpns")
                ? products.mobileSecurity.vpns
                      .useStore?.()
                      .getObjectStoreObject(activeAccountId.value, objectId.value)
                : useStore().getters.getObject({
                      accountId: activeAccountId.value,
                      objectType: "vpns",
                      objectId: objectId.value
                  })
            const enterpriseDevice: any = config.canUseNewObjectType("androidDevices")
                ? products.mobileSecurity.androidDevices
                      .useStore?.()
                      .getObjectStoreObject(activeAccountId.value, objectId.value)
                : useStore().getters.getObject({
                      accountId: activeAccountId.value,
                      objectType: "enterpriseDevices",
                      objectId: objectId.value
                  })

            if (vpn) {
                result = vpn.vpnname
            } else if (device) {
                result = deviceHelpers.getAliasedShortDeviceId(device.deviceId, device.alias, false)
            } else if (utm) {
                result = utm.utmname
            } else if (enterpriseDevice) {
                result = deviceHelpers.getAliasedShortDeviceId(
                    enterpriseDevice.deviceId,
                    enterpriseDevice.alias,
                    false
                )
            }
        }
    }
    return result.replace("((", "(").replace("))", ")").replace("undefined", "")
})

watch(objectTitle, () => {
    titleCharLength.value = 256
    updateTitleCharLength(500, "watch:objectTitle")
})
watch(activePage, () => {
    titleCharLength.value = 256
    updateTitleCharLength(0, "watch:activePage")
})
watch(objectId, () => {
    titleCharLength.value = 256
    updateTitleCharLength(0, "watch:objectId")
})
watch(context, () => {
    titleCharLength.value = 256
    updateTitleCharLength(0, "watch:context")
    setIsEditPage()
})
watch(activeAccountId, () => {
    titleCharLength.value = 256
    updateTitleCharLength(0, "watch:activeAccountId")
})
watch(activeItem, setIsEditPage, { deep: true })

onMounted(() => {
    updateTitleCharLength(0, "onMounted")
    $(window).on("resize", () => {
        updateTitleCharLength(0, "onMounted")
    })
    setIsEditPage()
})
</script>
<template>
    <header
        class="padding-xs-x padding-xs-y-3 padding-md-y-4 padding-md-x-2 padding-lg-4 padding-print-x-0 padding-print-y-0 pageheader"
        v-bind:style="'transform:translate(0,' + props.scrollDistance / 2 + 'px)'"
    >
        <div
            class="flexrow row margin-print-0"
            v-if="activePage != undefined && pages != undefined && pages[activePage] != undefined"
        >
            <div class="padding-xs-x pageheader-icon media-screen-only">
                <transition name="headerIconOnSwitch" :appear="true" :duration="200" mode="out-in">
                    <i
                        :ref="pages[activePage].appearance.icon"
                        :key="pages[activePage].appearance.icon"
                        :class="pages[activePage].appearance.icon"
                    ></i>
                </transition>
            </div>
            <div
                class="padding-xs-x padding-xs-t-0 padding-md-t padding-print-t-0 pageheader-headline"
            >
                <h1
                    id="pagetitle"
                    :title="
                        context
                            ? T(pages[activePage].text[context])
                            : T(pages[activePage].text.plural)
                    "
                    class="media-screen-only margin-xs-b-0 margin-md-b"
                    style="
                        position: relative;
                        height: 1.225em;
                        white-space: nowrap;
                        overflow: hidden;
                    "
                    data-key="textLengthRelativeToThis"
                >
                    <transition name="headerOnSwitch" :appear="true" :duration="200">
                        <span
                            :ref="pages[activePage].text.plural + context"
                            :key="pages[activePage].text.plural + context"
                            style="position: absolute"
                            data-key="textLength"
                        >
                            <span v-if="context == undefined">
                                {{ T(pages[activePage].text.plural) }}
                            </span>
                            <span v-else> {{ T(pages[activePage].text[context]) }} </span>&nbsp;
                            <small v-if="activePage == 'tenant-dashboard' && activeAccountName">
                                {{ activeAccountName }}
                            </small>
                            <template
                                v-if="
                                    [
                                        'edit',
                                        'details',
                                        'emmDetails',
                                        'editDevice',
                                        'editEnterprise',
                                        'editVPN',
                                        'editUtm',
                                        'editReportingUtm',
                                        'editUscUtm',
                                        'editWindowsVpn'
                                    ].indexOf(context || '') != -1
                                "
                            >
                                <small style="white-space: nowrap">
                                    {{
                                        objectTitle
                                            ? stringHelpers.outputStringMaxLength(
                                                  objectTitle || "",
                                                  titleCharLength,
                                                  "..."
                                              )
                                            : ""
                                    }}
                                </small>
                            </template>
                            <template v-if="labels.length > 0">
                                <small
                                    v-for="label in labels"
                                    class="label color-white margin-xs-r"
                                    :id="label.class"
                                    :class="label.class"
                                    :title="label.title"
                                    v-on:click="label.onClick ? label.onClick : null"
                                    :style="label.onClick != undefined ? 'cursor:pointer;' : ''"
                                >
                                    <template v-if="label.icon">
                                        <i :class="label.icon"></i>
                                    </template>
                                    {{ label.text }}
                                </small>
                            </template>
                        </span>
                    </transition>
                </h1>
                <transition name="headerOnSwitch" :appear="true" :duration="200">
                    <div
                        id="breadcrumb"
                        class="margin-print-t-0 margin-xs-t-0 margin-md-t"
                        v-if="breadcrumbArray != undefined && activeAccountName"
                        style="position: absolute; top: 6.5em"
                        :ref="pages[activePage].text.plural + context"
                        :key="pages[activePage].text.plural + context"
                    >
                        <ul>
                            <li v-if="activePage != undefined && activePage != 'tenant-dashboard'">
                                <a
                                    :href="
                                        pages['tenant-dashboard'].link.href.replace(
                                            '{tenantDomain}',
                                            activeAccountId + '.sms'
                                        )
                                    "
                                >
                                    <i class="fal fa-tachometer-alt"></i>&nbsp;{{ T("Dashboard") }}:
                                    <span v-if="activeAccountName"> {{ activeAccountName }}</span>
                                </a>
                            </li>
                            <li
                                v-for="breadcrumbEntry in breadcrumbArray"
                                :class="{ active: activePage != breadcrumbEntry }"
                            >
                                <template
                                    v-if="activePage != breadcrumbEntry || context != undefined"
                                >
                                    <a
                                        :href="
                                            pages[breadcrumbEntry].link.href.replace(
                                                '{tenantDomain}',
                                                activeAccountId + '.sms'
                                            )
                                        "
                                    >
                                        <i :class="pages[breadcrumbEntry].appearance.icon"></i
                                        >&nbsp;<span v-if="breadcrumbEntry != 'tenant-dashboard'">{{
                                            T(pages[breadcrumbEntry].text.plural)
                                        }}</span>
                                        <span v-else-if="activeAccountName">{{
                                            T("Dashboard") + ": " + activeAccountName
                                        }}</span>
                                    </a>
                                </template>
                                <template v-else>
                                    <span>
                                        <i :class="pages[breadcrumbEntry].appearance.icon"></i
                                        >&nbsp;<span v-if="activePage != 'tenant-dashboard'">{{
                                            T(pages[breadcrumbEntry].text.plural)
                                        }}</span>
                                        <span v-else-if="activeAccountName">{{
                                            T("Dashboard") + ": " + activeAccountName
                                        }}</span>
                                    </span>
                                </template>
                            </li>
                            <li v-if="context != undefined">
                                <span>
                                    <i :class="pages[activePage].appearance.icon"></i>&nbsp;<span>{{
                                        T(pages[activePage].text[context])
                                    }}</span
                                    >&nbsp;
                                </span>
                                <template
                                    v-if="
                                        [
                                            'edit',
                                            'details',
                                            'emmDetails',
                                            'editDevice',
                                            'editEnterprise',
                                            'editVPN',
                                            'editUtm',
                                            'editReportingUtm',
                                            'editUscUtm',
                                            'editWindowsVpn'
                                        ].indexOf(context) != -1
                                    "
                                >
                                    <span style="line-break: anywhere">{{
                                        "(" + (objectTitle ? objectTitle : "") + ")"
                                    }}</span>
                                </template>
                            </li>
                        </ul>
                    </div>
                </transition>
            </div>
        </div>
        <div class="flexrow row margin-print-0" v-else>
            <div class="padding-xs-x pageheader-icon">
                <loader class="fal" style="line-height: 3.25em"></loader>
            </div>
            <div class="padding-xs-x padding-xs-t pageheader-headline">
                <h1>&nbsp;</h1>
            </div>
        </div>
    </header>
</template>
<style lang="scss">
h1#pagetitle {
    .label {
        margin-left: 8px;
        font-size: 0.5em;
        padding: 1px 6px;
        top: -2px;
        position: relative;
        i {
            color: #fff;
        }
    }
}
</style>

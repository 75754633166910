<template>
    <div class="worldmap elementContainer">
        <div class="elementContainerTitleContainer">
            <div class="elementContainerTitle">
                <div>
                    <p class="elementContainerTitleText">
                        <i class="fal fa-map"></i>{{ T("Traffic-map") }}
                    </p>
                </div>
                <div class="ElementContainerInfoContainer" @click="callPopUp()">
                    <i
                        class="fal fa-info-circle gtvInfoCircle"
                        :title="T('Further Information')"
                    ></i>
                </div>
            </div>
            <hr />
        </div>
        <highcharts :constructorType="'mapChart'" :options="worldMap" class="worldMapHighchart" />
    </div>
</template>

<script setup lang="ts">
/* Imports */
import { T } from "@/classes/i18n"
import dialogs from "@/dialogs/dialogs"
import getterHelpers from "@/helpers/helpers.getters"
import requestHandler from "@/queries/requests"
import { computed, onMounted, ref, toRef, watch, type Ref } from "vue"
import * as worldTopology from "../assets/topo/worldMapTopology.json"

interface IcountryListData {
    blockedIps: number
    country: string
    ipPercentage: number
}

const props = defineProps(["apiRoute", "liveDataObj"])
const propAPI = ref()
propAPI.value = props.apiRoute

const heatmapIsActive = ref(false)
const topology = worldTopology
const svgElement: Ref<SVGAElement | undefined | null> = ref()
const circleRadius = ref(6)
const innerCircleRadius = ref(2)
const countryListData: Ref<IcountryListData[]> = ref([])
const bboxOffsets: Record<string, { x: number; y: number }> = {
    us: { x: -100, y: -14 },
    ru: { x: 35, y: -12 },
    ca: { x: 10, y: -25 },
    cl: { x: -1, y: 0 },
    es: { x: 5, y: 5 },
    fr: { x: -55, y: 55 },
    gb: { x: 23, y: 60 },
    hr: { x: 0, y: -5 },
    id: { x: -10, y: -5 },
    no: { x: -12, y: -18 },
    nl: { x: 38, y: 20 },
    vn: { x: 2, y: -5 },
    ec: { x: 5, y: 1 },
    hk: { x: 11.5, y: -17.5 }
}
const activeAccountId = computed(() => {
    return getterHelpers.useStore()?.getters.getActiveAccountId
})
watch(
    toRef(() => props.liveDataObj),
    (newVal) => {
        let liveData = JSON.parse(newVal.data)
        if (liveData[3] == undefined) {
            liveData[3] = [""]
        }
        if (!heatmapIsActive.value) {
            try {
                let worldmapCountryElement: any = document.querySelector(
                    `.highcharts-key-${liveData[0]}`
                )

                if (liveData[0] === "hk") {
                    worldmapCountryElement = document.querySelector(`.highcharts-key-cn`)
                }
                svgElement.value = worldmapCountryElement.parentNode

                const bbox: any = worldmapCountryElement?.getBBox()
                let x = bbox.x + bbox.width / 2
                let y = bbox.y + bbox.height / 2

                if (bboxOffsets[liveData[0]] != undefined) {
                    x = x + bboxOffsets[liveData[0]].x
                    y = y + bboxOffsets[liveData[0]].y
                }

                let svgNS = "http://www.w3.org/2000/svg"

                let grp = document.createElementNS(svgNS, "g")
                grp.setAttribute("transform", "translate(" + x + "," + y + ")")

                let circle1 = document.createElementNS(svgNS, "circle")
                circle1.setAttribute("cx", "0")
                circle1.setAttribute("cy", "0")
                circle1.setAttribute("r", `${circleRadius.value}`)
                circle1.setAttribute("class", "radar")

                let innerCircle = document.createElementNS(svgNS, "circle")
                innerCircle.setAttribute("cx", "0")
                innerCircle.setAttribute("cy", "0")
                innerCircle.setAttribute("r", `${innerCircleRadius.value}`)
                innerCircle.setAttribute("class", "innerRadar")

                grp.appendChild(circle1)
                grp.appendChild(innerCircle)

                svgElement.value!.appendChild(grp)
                setTimeout(() => {
                    grp.remove()
                }, 1400)
            } catch (err) {}
        }
    }
)
onMounted(() => {
    fetchList()
})

async function fetchList() {
    try {
        countryListData.value = await requestHandler.request("GET", propAPI.value)
    } catch (err) {
        console.error(err)
    }
}
function toggleHeatMapView() {
    heatmapIsActive.value = !heatmapIsActive.value
    return heatmapIsActive.value
}
function getBlockedIps(countryCode: string) {
    try {
        return countryListData.value[
            countryListData.value.findIndex((obj) => obj.country === countryCode)
        ].blockedIps
    } catch {
        return 0
    }
}
function getPercentage(countryCode: string) {
    try {
        let percent =
            countryListData.value[
                countryListData.value.findIndex((obj) => obj.country === countryCode)
            ].ipPercentage

        if (percent < 0.001 && percent > 0) {
            return "< 0.1 %"
        } else if (percent > 0.01) {
            return new Intl.NumberFormat("en-EN", { style: "percent" }).format(percent)
        } else {
            var percentage = percent * 100
            return (
                new Intl.NumberFormat("en-EN", { maximumSignificantDigits: 2 }).format(percentage) +
                " %"
            )
        }
    } catch {
        return "0%"
    }
}
function colorByValue(value: number, isActive: boolean) {
    if (value != null && isActive) {
        if (value >= 0 && value < 10) {
            return "rgba(111, 182, 17, 0.8)"
        } else if (value >= 10 && value < 100) {
            return "rgba(255, 154, 0, 0.8)"
        } else if (value >= 100 && value < 1000) {
            return "rgba(255, 116, 0, 0.8)"
        } else if (value >= 1000 && value < 10000) {
            return "rgba(255, 77, 0, 0.8)"
        } else if (value >= 10000 && value < 100000) {
            return "rgba(255, 46, 33, 0.8)"
        }
    } else {
        return "rgba(150, 150, 150, 0.6)"
    }
}
function callPopUp() {
    dialogs.misc.confirmDialog(
        activeAccountId.value,
        T("Traffic-map"),
        T(
            "This world map shows real-time attacks on our honeypots worldwide. Hovering over a country with the mouse displays information from the threat intelligence feed (TIF). The view can be switched to a heat map under the navigation bar. Countries from which a particularly high number of threats originate are marked."
        ),
        () => {
            return true
        },
        "",
        T("Close"),
        "",
        false,
        "fal fa-info-circle"
    )
}
//Worldmap generation
const worldMap = computed(() => ({
    chart: {
        map: topology,
        animation: false
    },
    accessibility: {
        enabled: false
    },
    mapNavigation: {
        enabled: true,
        enableDoubleClickZoom: false,
        enableMouseWheelZoom: false,
        enableDoubleClickZoomTo: false,
        enableTouchZoom: false
    },
    legend: {
        enabled: false
    },
    title: {
        text: ""
    },
    tooltip: {
        enabled: true,
        formatter: function () {
            const _this = this as any
            let text = ""
            text += `<span class="fi fi-${_this.point.id} flag"></span> <b><span style="color:var(--font-Color); font-size:12px">${T(_this.point.name)}</span></b><br>`
            text += `<b><span style="color:var(--font-Color); font-size:12px">${T("Blocked IPs")}</span></b> <span style="color:var(--font-Color); font-size:12px">${_this.point.value}</span><br>`
            text += `<b><span style="color:var(--font-Color); font-size:12px">${T("Percentage")}</span></b> <span style="color:var(--font-Color); font-size:12px">${_this.point.percentage}</span><br>`
            return text
        },
        useHTML: true
    },
    credits: {
        enabled: false
    },
    mapView: {
        maxZoom: 6,
        projection: {
            name: "Miller",
            rotation: [-10, 0, 0]
        }
    },
    series: [
        {
            data: topology.objects.default.geometries.map((feature: any) => ({
                "hc-key": feature.properties["hc-key"],
                name:
                    feature.properties["hc-key"] == "us"
                        ? feature.properties["iso-a3"]
                        : feature.properties.name,
                id: feature.properties["hc-key"] == "kv" ? "xk" : feature.properties["hc-key"],
                value: new Intl.NumberFormat("de-DE").format(
                    getBlockedIps(feature.properties["hc-key"])
                ),
                percentage: getPercentage(feature.properties["hc-key"]),
                color: colorByValue(
                    getBlockedIps(feature.properties["hc-key"]),
                    heatmapIsActive.value
                )
            })),
            dataLabels: {
                enabled: false,
                format: "{point.id}"
            },
            cursor: "pointer"
        }
    ]
}))
//@ts-ignore
worldMap.value.chart.events = {
    load() {
        this.heatMapButton = this.renderer
            .button(
                "<i class='fa-solid fa-map heatMapButtonIcon'></i>",
                10,
                66,
                function () {
                    toggleHeatMapView()
                },
                {
                    width: 12,
                    height: 12
                },
                null,
                null,
                null,
                null,
                true // Enable HTML in Highcharts button
            )
            .addClass("highcharts-button-box heatmap-box")
            .attr({
                zIndex: 4,
                useHTML: true
            })
            .add()
        const heatMapIconTitle = document.createElementNS("http://www.w3.org/2000/svg", "title")
        heatMapIconTitle.textContent = "Toggle Heatmap"
        document.getElementsByClassName("heatmap-box")[0].appendChild(heatMapIconTitle)
    }
}
</script>

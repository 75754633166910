import { useGlobalMixin } from "@/mixins/mixins.global"

import { T } from "@/classes/i18n"
import branding from "@/classes/objectTypes/unifiedReporting/branding/branding"
import encodingHelpers from "@/helpers/helpers.encoding"
import getterHelpers from "@/helpers/helpers.getters"
import jsonFormHelpers from "@/helpers/helpers.jsonForms"
import queries from "@/queries/queries"
import requestHandler from "@/queries/requests"
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store"
import devLog from "../../classes/log"
import jsonHelpers from "../../helpers/helpers.json"
import loaderComponent from "../components/loader.vue"
import jsonFormEntryComponent from "../inputtypes/jsonform-entry-async"

const pageBrandingComponent = {
    name: "sms-page-branding",
    mixins: [useGlobalMixin()],
    template: `
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
        <div class="row">
            <div class="col-xs-24 col-lg-18 col-print-24">
                <div class="box-shadow">
                    <transition name="pageLoader">
                        <div v-if="!initialized">
                            <div class="text-size-3 text-center padding-xs-t-4">
                                <loader class="color-red"></loader>
                            </div>
                            <template v-if="loaderText">
                                <div class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity:0.8">
                                    <span>
                                        {{ T(loaderText) }}
                                    </span>
                                </div>
                            </template>
                            <template v-else>
                                <div class="padding-xs-b-4"></div>
                            </template>
                        </div>
                    </transition>
                    <transition>
                        <div v-if="initialized">
                            <div class="box-content-2">
                                <h4><i class="fal fa-fw fa-paint-brush"></i>&nbsp;${T("Report branding settings")}</h4>
                                <p>
                                    {{T('These settings will be applied to all Reports for your clients. Specific client-reports can be excluded.')}}
                                </p>
                                <hr>
                                <template v-if="branding.form != undefined">
                                    <jsonform-entry
                                        :fields="branding.form.fields"
                                        :this-field="branding.form"
                                        :full-json="branding.form"
                                    >
                                    </jsonform-entry>
                                </template>
                            </div>
                            <div class="box-content-2">

                                <div class="row">
                                    <div class="col-xs-24 text-right">
                                        <a class="btn margin-md-0" v-if="branding.hasBranding" v-on:click="getBrandingPreview()">
                                            <i class="fal fa-download"></i> ${T("Download preview")}
                                        </a>
                                        &nbsp;&nbsp;
                                        <a class="btn margin-md-0 btn-loader twist-in " v-on:click="save()" :class="{ 'btn-loading': loading || branding.updated}" id="saveButton" title="Save">
                                            <span class="animate">
                                                <loader v-if="loading" style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"></loader>
                                                <i class="fal fa-check" v-if="branding.updated"></i>
                                            </span>
                                            <span><i class="fal fa-save"></i> <span class="display-xs-none display-lg-inline">${T("Save")}</span></span>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </section>
    `,
    data() {
        return {
            initialized: false,
            loading: true,
            loaderText: "Getting branding data...",
            branding: {
                hasBranding: false,
                updated: false,
                form: undefined
            },
            selectOptions: {}
        }
    },
    methods: {
        init: async function (this: any) {
            this.loading = true
            this.loaderText = "Getting form..."
            this.branding.form = JSON.parse(
                JSON.stringify(useStore().state.resources.shemas.branding)
            )

            let object = await branding.getBranding(this.activeAccountId)
            let hasBranding: boolean = false
            if (object?.active === true) {
                hasBranding = true
            }
            this.branding.hasBranding = hasBranding

            let gotSelectOptions: any = await this.getSelectOptions(
                JSON.parse(JSON.stringify(useStore().state.resources.shemas.branding))
            )
            if (gotSelectOptions) {
                this.loaderText = "Setting select field options..."
                this.setSelectOptions(this.branding.form)
            }

            await this.fillFormWithData(this.branding.form, object)
            this.loading = false
            this.initialized = true
        },
        fillFormWithData: async function (this: any, formJson: any, object: any, path: any = []) {
            for (let fieldIndex in formJson.fields) {
                let field = formJson.fields[fieldIndex]
                let fieldProperty: any = field.property || null
                let fieldPath: any = JSON.parse(JSON.stringify(path))

                if (fieldProperty) {
                    fieldPath.push(fieldProperty)
                }
                if (field.type == "tabs" || field.type == "tab" || field.type == "section") {
                    if (field.activator) {
                        let objectValue: any = jsonHelpers.getObjectProperty(
                            object,
                            fieldPath.join(".")
                        )
                        if (objectValue != undefined) {
                            field.activator.value = true
                        }
                    }
                    this.fillFormWithData(field, object, fieldPath)
                } else if (field.type == "loopentry") {
                    for (let objectIndex in object) {
                        this.fillFormWithData(field, object[objectIndex], [])
                    }
                } else if (field.type == "loop") {
                    let objectValue: any = jsonHelpers.getObjectProperty(
                        object,
                        fieldPath.join(".")
                    )
                    if (objectValue != undefined) {
                        for (let objectIndex in objectValue) {
                            let stringFields = JSON.stringify(field.template)
                            stringFields = stringFields.replace(/{index}/g, objectIndex)
                            stringFields = stringFields.replace(/{childIndex}/g, "{index}")
                            stringFields = stringFields.replace(
                                /{childChildIndex}/g,
                                "{childIndex}"
                            )
                            stringFields = stringFields.replace(
                                /{childChildChildIndex}/g,
                                "{childChildIndex}"
                            )
                            let thisTitle: string = field.loopFieldTitle
                            thisTitle = objectValue[objectIndex][field.getLoopFieldTitleFrom]
                            if (!thisTitle && field.getLoopFieldTitleFromAlt) {
                                thisTitle = objectValue[objectIndex][field.getLoopFieldTitleFromAlt]
                            }
                            field.fields.push({
                                randomID: (+new Date()).toString(36).slice(-5),
                                type: "loopentry",
                                minified: true,
                                title: thisTitle,
                                fields: JSON.parse(stringFields)
                            })
                            this.fillFormWithData(
                                field.fields[objectIndex],
                                objectValue[objectIndex],
                                []
                            )
                        }
                    }
                } else {
                    if (fieldProperty != null) {
                        let objectValue: any = jsonHelpers.getObjectProperty(
                            object,
                            fieldPath.join(".")
                        )
                        if (fieldProperty == "packageName" && field.permissions != undefined) {
                            let result: any = await queries.mobileSecurity.getEnterpriseAppInfo(
                                this.activeTenantDomain,
                                objectValue
                            )
                            let permissions: any = result.permissions
                            let appTracks: any = result.appTracks

                            if (appTracks && appTracks.length) {
                                for (let fieldIndex in this.$parent.fields) {
                                    let field: any = this.$parent.fields[fieldIndex]
                                    if (field.id == "accessibleTrackIds") {
                                        for (let i in appTracks) {
                                            let appTrack: any = appTracks[i]
                                            if (!Array.isArray(field.options)) {
                                                field.options = []
                                            }
                                            field.options.push({
                                                id: appTrack.trackId,
                                                text: appTrack.trackAlias
                                            })
                                        }
                                        field.visible = true
                                        break
                                    }
                                }
                            }

                            for (let i in permissions) {
                                let permission: any = permissions[i]
                                field.permissions.push({
                                    id: permission.permissionId,
                                    text: permission.permissionId
                                })
                            }
                        }
                        if (objectValue != undefined && objectValue != null) {
                            // SELECT2
                            if (field.select2Options != undefined) {
                                if (
                                    ["select2Tags", "select2IpMask", "select2AppSearch"].indexOf(
                                        field.select2Options
                                    ) != -1
                                ) {
                                    if (field.multiple) {
                                        // Select2 Tag (Multiple)
                                        for (let valueIndex in objectValue) {
                                            var value = objectValue[valueIndex]
                                            var hasValue = false
                                            for (let i in field.options) {
                                                if (field.options[i].id == value) {
                                                    hasValue = true
                                                }
                                            }
                                            if (!hasValue) {
                                                if (!Array.isArray(field.options)) {
                                                    field.options = []
                                                }
                                                field.options.push({
                                                    id: value,
                                                    text: value
                                                })
                                            }
                                        }
                                    } else {
                                        // Select2 Tag (Single)
                                        var value = objectValue
                                        var hasValue = false
                                        for (var i in field.options) {
                                            if (field.options[i].id == value) {
                                                hasValue = true
                                            }
                                        }
                                        if (!hasValue) {
                                            if (!Array.isArray(field.options)) {
                                                field.options = []
                                            }
                                            field.options.push({
                                                id: value,
                                                text: value
                                            })
                                        }
                                    }
                                }
                            }
                            // SET VALUE
                            if (typeof objectValue == "string" && objectValue != "") {
                                objectValue = $.parseHTML(objectValue)[0].textContent
                            }
                            if (field.format) {
                                switch (field.format) {
                                    case "unicodeToPunycode":
                                        objectValue = encodingHelpers.punycodeToUnicode(objectValue)
                                        break
                                }
                            }
                            field.value = objectValue
                            // ACTIVATE THE _USE_ TOGGLES
                            if (field.conditions != undefined && field.conditions.length) {
                                for (let conditionIndex in field.conditions) {
                                    if (field.conditions[conditionIndex].refId) {
                                        if (
                                            field.conditions[conditionIndex].refId.indexOf(
                                                "_use_"
                                            ) != -1
                                        ) {
                                            let useField: any = jsonFormHelpers.getFieldById(
                                                this.branding.form,
                                                field.conditions[conditionIndex].refId
                                            )
                                            useField.value = field.conditions[conditionIndex].value
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return true
        },
        generateObject: function (this: any, formJson: any, object: any = {}) {
            for (let fieldIndex in formJson.fields) {
                let field = formJson.fields[fieldIndex]
                let fieldProperty: any = field.property || null
                if (
                    this.areConditionsFulfilled(field.conditions || []) &&
                    this.areSendConditionsFulfilled(field.sendConditions || [], field)
                ) {
                    if (["tabs", "tab", "section"].indexOf(field.type) != -1) {
                        if (fieldProperty != null) {
                            // HAS PROPERTY
                            if (field.activator) {
                                if (field.activator.value == true) {
                                    if (!object[fieldProperty]) {
                                        object[fieldProperty] = {}
                                    }
                                    object[fieldProperty] = this.generateObject(
                                        field,
                                        object[fieldProperty]
                                    )
                                }
                            } else {
                                if (!object[fieldProperty]) {
                                    object[fieldProperty] = {}
                                }
                                object[fieldProperty] = this.generateObject(
                                    field,
                                    object[fieldProperty]
                                )
                            }
                        } else {
                            // HAS NO PROPERTY
                            if (field.activator) {
                                if (field.activator.value == true) {
                                    ;(<any>Object).assign(
                                        object,
                                        this.generateObject(field, object)
                                    )
                                }
                            } else {
                                ;(<any>Object).assign(object, this.generateObject(field, object))
                            }
                        }
                    } else if (field.type == "loop") {
                        if (fieldProperty != null && field.fields.length > 0) {
                            if (!object[fieldProperty]) {
                                object[fieldProperty] = []
                            }
                            for (let arrayIndex in field.fields) {
                                let thisObject: any = this.generateObject(
                                    field.fields[arrayIndex],
                                    {}
                                )
                                object[fieldProperty].push(thisObject)
                            }
                        }
                    } else {
                        if (fieldProperty != null) {
                            if (field.format) {
                                switch (field.format) {
                                    case "number":
                                        field.value = parseInt(field.value)
                                        break
                                    case "string":
                                        field.value = field.value.toString()
                                        break
                                    case "unicodeToPunycode":
                                        field.value = encodingHelpers.unicodeToPunycode(field.value)
                                        break
                                }
                            }
                            if (field.value != null && field.value != undefined) {
                                object[fieldProperty] = field.value
                            }
                        }
                    }
                }
            }
            return object
        },
        areConditionsFulfilled: function (this: any, conditions: any) {
            let areConditionsFulfilled: boolean = true
            if (conditions) {
                if (conditions.length) {
                    for (let conditionIndex in conditions) {
                        let condition: any = conditions[conditionIndex]
                        let inputObj: any = jsonFormHelpers.getFieldById(
                            this.branding.form,
                            conditions[conditionIndex].refId
                        )
                        if (inputObj != -1) {
                            switch (condition.operator) {
                                case "equals":
                                    if (inputObj.value != condition.value) {
                                        areConditionsFulfilled = false
                                    }
                                    break
                                case "equals_not":
                                    if (inputObj.value == condition.value) {
                                        areConditionsFulfilled = false
                                    }
                                    break
                                case "is_one_of":
                                    if (condition.value.indexOf(inputObj.value) == -1) {
                                        areConditionsFulfilled = false
                                    }
                                    break
                                case "no_fields":
                                    if (inputObj.fields.length > 0) {
                                        areConditionsFulfilled = false
                                    }
                                    break
                            }
                            if (areConditionsFulfilled == false) {
                                break
                            }
                        } else {
                            devLog.log("[formpage]", "Input was not found", condition)
                        }
                    }
                }
            }
            return areConditionsFulfilled
        },
        areSendConditionsFulfilled: function (this: any, conditions: any, field: any = null) {
            let areConditionsFulfilled: boolean = true
            if (conditions) {
                if (conditions.length) {
                    for (let conditionIndex in conditions) {
                        let condition: any = conditions[conditionIndex]
                        let inputObj: any = -1
                        let inputValue = undefined
                        if (condition.refId) {
                            inputObj = jsonFormHelpers.getFieldById(
                                this.branding.form,
                                condition.refId
                            )
                        } else {
                            if (field != null) {
                                inputObj = field
                            }
                        }
                        if (inputObj != -1) {
                            inputValue = inputObj.value
                        }
                        if (inputValue != undefined) {
                            switch (condition.operator) {
                                case "equals":
                                    if (inputValue != condition.value) {
                                        areConditionsFulfilled = false
                                    }
                                    break
                                case "equals_not":
                                    if (inputValue == condition.value) {
                                        areConditionsFulfilled = false
                                    }
                                    break
                                case "is_one_of":
                                    if (condition.value.indexOf(inputValue) == -1) {
                                        areConditionsFulfilled = false
                                    }
                                    break
                                case "no_fields":
                                    if (condition.fields.length > 0) {
                                        areConditionsFulfilled = false
                                    }
                                    break
                            }
                            if (areConditionsFulfilled == false) {
                                break
                            }
                        } else {
                            devLog.log("[formpage]", "Input was not found", condition)
                        }
                    }
                }
            }
            return areConditionsFulfilled
        },
        deleteUseLessLoopfields: function (this: any, object: any) {
            let testFields: any = {
                applications: "packageName",
                permissionGrants: "permission",
                choosePrivateKeyRules: "urlPattern",
                persistentPreferredActivities: "receiverActivity",
                policyEnforcementRules: "settingName",
                setupActions: "settingName.packageName",
                openNetworkConfiguration: {
                    NetworkConfigurations: "Name"
                }
            }

            let property: string = ""

            for (let field in testFields) {
                if (typeof testFields[field] == "string") {
                    property = testFields[field]
                    if (jsonHelpers.getObjectProperty(object, field)) {
                        let i: number = object[field].length
                        while (i--) {
                            let loopField = object[field][i]
                            if (!jsonHelpers.getObjectProperty(loopField, property)) {
                                object[field].splice(i, 1)
                            }
                        }
                    }
                } else if (typeof testFields[field] == "object") {
                    for (let childField in testFields[field]) {
                        property = testFields[field][childField]
                        if (jsonHelpers.getObjectProperty(object, field + "." + childField)) {
                            let i: number = object[field][childField].length
                            while (i--) {
                                let loopField = object[field][childField][i]
                                if (!jsonHelpers.getObjectProperty(loopField, property)) {
                                    object[field][childField].splice(i, 1)
                                }
                            }
                        }
                    }
                }
            }

            for (let field in testFields) {
                if (typeof testFields[field] == "string") {
                    property = testFields[field]
                    if (jsonHelpers.getObjectProperty(object, field)) {
                        let i: number = object[field].length
                        if (i == 0) {
                            delete object[field]
                        }
                    }
                } else if (typeof testFields[field] == "object") {
                    for (let childField in testFields[field]) {
                        property = testFields[field][childField]
                        if (jsonHelpers.getObjectProperty(object, field + "." + childField)) {
                            let i: number = object[field][childField].length
                            if (i == 0) {
                                delete object[field][childField]
                            }
                            if (jsonHelpers.isObjectEmpty(object[field])) {
                                delete object[field]
                            }
                        }
                    }
                }
            }
            return object
        },
        clearErrors: function (this: any, formObj: any) {
            if (formObj.errors) {
                formObj.errors = []
            }
            if (formObj.fields && formObj.fields.length) {
                for (let fieldIndex in formObj.fields) {
                    let thisField = formObj.fields[fieldIndex]
                    if (thisField.errors) {
                        thisField.errors = []
                    }
                    if (thisField.fields) {
                        this.clearErrors(thisField)
                    }
                }
            }
        },
        setErrors: function (
            this: any,
            formObj: any,
            errorArray: any,
            errorObject: any = null,
            objPath: string = ""
        ) {
            if (errorObject == null) {
                errorObject = {}
                for (let errorIndex in errorArray.errors) {
                    let error = errorArray.errors[errorIndex]
                    if (error.subErrors != null) {
                        error.sub = {
                            errors: error.subErrors
                        }
                        this.setErrors(formObj, error.sub)
                    }
                    let dataPathArray: any = JSON.parse(JSON.stringify(error.dataPath)).split("/")
                    let pattern = new RegExp("^[0-9]*$")
                    if (pattern.test(dataPathArray[dataPathArray.length - 1])) {
                        error.dataPath = error.dataPath.replace(
                            "/" + dataPathArray[dataPathArray.length - 1],
                            ""
                        )
                    }
                    let errorPath: string = error.dataPath
                    let errorText: string = error.message
                    errorObject[errorPath] = errorText
                }
            }
            if (formObj.fields && formObj.fields.length) {
                for (let fieldIndex in formObj.fields) {
                    let thisField = formObj.fields[fieldIndex]
                    let thisPath = objPath
                    if (thisField.property) {
                        thisPath = objPath + "/" + thisField.property
                        if (errorObject[thisPath] != undefined) {
                            if (thisField.errors != undefined) {
                                thisField.errors.push(errorObject[thisPath])
                            } else {
                                console.error(thisField.id + " has no error property")
                            }
                        }
                    }
                    if (thisField.fields) {
                        this.setErrors(thisField, errorArray, errorObject, thisPath)
                    }
                }
            }
        },
        save: async function (this: any) {
            this.loading = true
            let form: any = this.branding.form
            let object: any = this.generateObject(form)
            let result: any = null
            try {
                // UPDATE OBJECT
                result = await branding.updateBranding(this.activeAccountId, object)

                if (object.active == false) {
                    this.branding.hasBranding = false
                } else {
                    this.branding.hasBranding = true
                }

                if (
                    jsonHelpers.getObjectProperty(result, "code") == 400 ||
                    jsonHelpers.getObjectProperty(result, "status") == 400
                ) {
                    throw result
                }
                this.loading = false
            } catch (err: any) {
                if (jsonHelpers.getObjectProperty(err, "responseJSON.errors")) {
                    this.setErrors(this.branding.form, err.responseJSON.errors)
                } else if (err.errors) {
                    this.setErrors(this.branding.form, err)
                }
                this.loading = false
            }
        },
        getSelectOptions: async function (this: any, form: any) {
            let options: any = {}
            let neededSelectOptions: any = this.findNeededSelectOptions(form)
            let reportsFromAllTenantsNeeded: boolean = false
            let types: any = []

            types = ["reportsFromAllTenants"]
            for (let index in types) {
                if (neededSelectOptions.indexOf(types[index]) != -1) {
                    reportsFromAllTenantsNeeded = true
                }
            }
            if (reportsFromAllTenantsNeeded) {
                options.reportsFromAllTenants = []

                let reports: any = await requestHandler.request(
                    "GET",
                    "/sms-mgt-api/api/1.1/tenants/" +
                        this.activeTenantDomain +
                        "/laas/branding/esr/reports"
                )
                for (let i in reports) {
                    let report = reports[i]
                    options.reportsFromAllTenants.push({
                        id: report.reportId,
                        text: report.accountName + " (" + report.name + ")"
                    })
                }
            }
            this.selectOptions = options
            return true
        },
        findNeededSelectOptions: function (this: any, formJson: any) {
            let neededSelectOptions: any = []
            for (let fieldIndex in formJson.fields) {
                let field = formJson.fields[fieldIndex]
                if (field.type == "tabs" || field.type == "tab" || field.type == "section") {
                    let neededChildSelectOptions: any = this.findNeededSelectOptions(field)
                    if (neededChildSelectOptions.length) {
                        neededSelectOptions = neededSelectOptions.concat(neededChildSelectOptions)
                    }
                } else if (
                    ["select", "select2"].indexOf(field.type) &&
                    typeof field.options == "string"
                ) {
                    neededSelectOptions.push(field.options)
                }
            }
            return neededSelectOptions
        },
        setSelectOptions: function (this: any, formJson: any) {
            for (let fieldIndex in formJson.fields) {
                let field = formJson.fields[fieldIndex]
                if (field.type == "tabs" || field.type == "tab" || field.type == "section") {
                    this.setSelectOptions(field)
                } else if (
                    ["select", "select2"].indexOf(field.type) &&
                    typeof field.options == "string" &&
                    field.options != "appPermissions"
                ) {
                    field.options = JSON.parse(JSON.stringify(this.selectOptions[field.options]))
                }
            }
            return true
        },
        getBrandingPreview: function (this: any) {
            useStore().dispatch(ActionTypes.addModal, {
                id: "brandingPreview",
                accountId: this.activeAccountId,
                abortable: true,
                content: {
                    title: {
                        text: T("Branding preview"),
                        icon: "fal fa-exclamation-triangle"
                    },
                    body: {
                        use: true,
                        component: "get-branding-preview-dialog",
                        properties: {
                            branding: this.generateObject(this.branding.form)
                        }
                    }
                },
                buttons: [
                    {
                        loading: false,
                        onClick: function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal, {
                                accountId: useStore().state.session.activeAccountId
                            })
                        },
                        icon: "fal fa-times",
                        text: T("Cancel"),
                        align: "left",
                        disabled: false
                    }
                ]
            })
        }
    },
    created: function (this: any) {
        /*
        if(!this.$isTenantReseller(this.activeTenantDomain)) {
            router.navigate('show-tenant-'+this.activeTenantDomain+'-dashboard')
        }
        */
    },
    mounted() {
        let thisComponent: any = this
        thisComponent.init()
    },
    components: {
        loader: loaderComponent,
        "jsonform-entry": jsonFormEntryComponent
    }
}
export default pageBrandingComponent

<script setup lang="ts">
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import products from "@/classes/objectTypes"
import type { AuditlogEntry } from "@/classes/objectTypes/unifiedSecurity/auditlog/auditlog"
import timeHelpers from "@/helpers/helpers.time"
import miscQueries from "@/queries/misc"
import { ActionTypes, useStore } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import Icon from "@/templates/components/icon/icon"
import Label from "@/templates/components/label/label"
import { computed, onMounted, ref } from "vue"
import loaderComponent from "../components/loader.vue"
import tableNext, { type TableEntryInfo } from "../components/tableNext.vue"

const activeAccountId = computed(() => {
    return useStore().state.session.activeAccountId
})
const auditlogEntries = computed(() => {
    return activeAccountId.value
        ? products.unifiedSecurity.auditlog
              .useStore?.()
              .getObjectStoreObjects(activeAccountId.value)
        : undefined
})

const tableCollumns: TableEntryInfo[] = [
    {
        text: T("Date"),
        property: "timestamp",
        displayType: "text",
        getSortValue: (entry: AuditlogEntry) => {
            return entry.timestamp
        },
        getValue: (entry: AuditlogEntry) => {
            return timeHelpers.formatDate(entry.timestamp, timeHelpers.getDateFormatI18n(true))
        },
        width: 210
    },
    {
        text: T("User"),
        property: "user",
        displayType: "labels",
        getValue: (entry: AuditlogEntry) => {
            return [
                new Label({
                    title: entry.user.replace("carbon.super@carbon.super", T("Background process")),
                    text: entry.user.replace("carbon.super@carbon.super", T("Background process")),
                    class: entry.user == "carbon.super@carbon.super" ? "bg-grey" : "bg-blue",
                    icon: new Icon({
                        class:
                            entry.user == "carbon.super@carbon.super"
                                ? "fal fa-server"
                                : "fal fa-user"
                    })
                })
            ]
        },
        width: 250
    },
    {
        text: T("Message"),
        property: "message",
        displayType: "text",
        getValue: (entry: AuditlogEntry) => {
            return T(entry.message)
        }
    }
]

const initialized = ref(<boolean>false)
const loading = ref(<boolean>true)
const loaderInfo = ref(T("Initializing..."))
const auditlogButtons = ref([
    new Button({
        loading: false,
        icon: "fal fa-download",
        text: T("Download CSV"),
        title: T("Download CSV"),
        onClick: async function (this: any) {
            miscQueries.download(
                "/sms-mgt-api/api/2.0/tenants/" + activeAccountId.value + ".sms/auditlog?csv=true",
                "auditlog_" + activeAccountId.value + ".csv"
            )
        }
    }),
    new Button({
        loading: true,
        icon: "fal fa-sync-alt",
        text: "",
        title: "Refresh",
        onClick: function (this: any) {
            getAuditLog()
        }
    })
])

const getAuditLog = async () => {
    loading.value = true
    auditlogButtons.value[0].loading = true
    auditlogButtons.value[1].loading = true
    if (config.canUseNewObjectType("auditlog")) {
        activeAccountId.value
            ? products.unifiedSecurity.auditlog.queries.getObjectsFromApi(activeAccountId.value)
            : null
    } else {
        await useStore().dispatch(ActionTypes.getAuditLog, activeAccountId.value)
    }
    auditlogButtons.value[0].loading = false
    auditlogButtons.value[1].loading = false
    loading.value = false
    initialized.value = true
}

onMounted(async () => {
    getAuditLog()
})
</script>
<template>
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
        <div class="row">
            <div class="col-xs-24 col-print-24">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <template v-if="initialized">
                                <tableNext
                                    :selectable-columns="tableCollumns"
                                    :object-list="auditlogEntries"
                                    :buttons="auditlogButtons"
                                    :max-height="500"
                                ></tableNext>
                            </template>
                            <template v-else>
                                <div class="text-center padding-xs-y-4 col-xs">
                                    <div class="text-size-3">
                                        <loaderComponent class="text-size-2 color-red" />
                                    </div>
                                    <span v-if="loaderInfo" style="opacity: 0.8">
                                        {{ T(loaderInfo) }}
                                    </span>
                                </div>
                            </template>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

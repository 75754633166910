<template>
    <span class="animate">
        <i class="progress-circular no-progress" style="font-size: 1.5em">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 50 50"
                style="stroke-width: 4px"
            >
                <circle
                    cx="25"
                    cy="25"
                    r="20"
                    style="stroke: rgba(0, 0, 0, 0.1); fill: none"
                ></circle>
                <g transform="translate(25,25)">
                    <circle
                        stroke-dasharray="110"
                        stroke-dashoffset="-70"
                        cx="0"
                        cy="0"
                        r="20"
                        style="fill: none; stroke-linecap: round"
                        stroke="var(--header-Background)"
                        transform="rotate(14.0181)"
                    >
                        <animate
                            attributeName="stroke-dashoffset"
                            values="360;140"
                            dur="2.2s"
                            keyTimes="0;1"
                            calcMode="spline"
                            fill="freeze"
                            keySplines="0.41,0.314,0.8,0.54"
                            repeatCount="indefinite"
                        ></animate>
                    </circle>
                </g>
            </svg>
        </i>
    </span>
</template>

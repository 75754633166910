<template>
    <div class="section">
        <div class="section-header" @click="toggle">
            <i class="fal" :class="isOpen ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
            <h5>{{ title }}</h5>
        </div>
        <div v-if="isOpen" class="section-content">
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue"

const props = withDefaults(
    defineProps<{
        title: string
        defaultOpen?: boolean
    }>(),
    {
        defaultOpen: false
    }
)

const isOpen = ref(props.defaultOpen)

function toggle() {
    isOpen.value = !isOpen.value
}
</script>

<style lang="scss" scoped>
.section {
    margin-top: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    overflow: hidden;

    .section-header {
        display: flex;
        align-items: center;
        padding: 0.75rem 1rem;
        cursor: pointer;

        i {
            margin-right: 0.5rem;
            font-size: 0.875rem;
        }

        h5 {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 500;
        }
    }

    .section-content {
        display: flex;
        align-items: flex-start;
        padding: 1rem;
    }
}
</style>

<template>
    <div v-if="loading" class="text-size-2 text-center padding-xs-t-4">
        <div class="text-size-2">
            <loader class="color-red" />
        </div>
    </div>
    <div v-else class="content-1">
        <p>{{ T("Domain") }}</p>
        <input v-model="domain" type="text" />

        <p class="padding-xs-t">{{ T("Profile") }}</p>
        <input-vue-select v-model="selectedProfiles" :select-options="profileOptions" multiple />

        <p class="padding-xs-t">{{ T("Action") }}</p>
        <select v-model="action">
            <option value="allow">{{ T("Allow") }}</option>
            <option value="block">{{ T("Block") }}</option>
        </select>
    </div>
</template>

<script setup lang="ts">
import { T } from "@/classes/i18n"
import secureDns from "@/classes/objectTypes/mobileSecurity/secure-dns/secure-dns"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import { useStore } from "@/store/vuex.store"
import loader from "@/templates/components/loader.vue"
import { computed, onMounted, ref } from "vue"
import type Button from "../components/button/button"
import inputVueSelect from "../inputtypes/input-vue-select.vue"

type TDomainAction = "allow" | "block"

const props = defineProps<{
    properties: {
        entry: any
    }
}>()

const loading = ref(false)

const entry = ref(props.properties.entry?.data)
const action = ref<TDomainAction>("allow")
const domain = ref(entry.value?.domain)

const profileOptions = ref<any[]>([])
const selectedProfiles = ref<string[]>([entry.value?.profileId])

const modalButtons = computed(() => {
    return getterHelpers.useStore().getters.getActiveModal(activeAccountId.value)
        .buttons as Button[]
})

const activeAccountId = computed(() => {
    return useStore().state.session.activeAccountId
})

async function init() {
    loading.value = true

    const items = await secureDns.queries.getObjectsFromApi(activeAccountId.value || "")
    if (items instanceof Error) {
        return
    }

    profileOptions.value = items.map((item) => {
        return {
            text: item.name,
            id: item.id
        }
    })

    loading.value = false
}

async function submit() {
    modalButtons.value[1].loading = true

    for (const profileId of selectedProfiles.value) {
        try {
            await requestHandler.request(
                "PUT",
                `/sms-mgt-api/api/2.0/tenants/${tenantHelpers.getTenantDomain(activeAccountId.value || "")}/secure-dns/configs/${profileId}/domain-action`,
                [
                    {
                        domain: domain.value,
                        action: action.value
                    }
                ]
            )
        } catch (e) {}
    }

    modalButtons.value[1].loading = false
    return true
}

onMounted(() => {
    init()
})

defineExpose({
    submit
})
</script>

<template>
    <div class="row form-group">
        <div class="col-xs-24">
            <p style="text-align: justify">
                {{ T("Since your last login, there have been portal updates.") }}
            </p>
        </div>
    </div>

    <div v-for="(version, i) in versions" :key="i">
        <h2 class="padding-xs-t-2 padding-xs-b border-bottom version-header">
            <div class="version-info">
                <i v-if="i === 0" class="fa-fw fal fa-crown color-green"></i>
                <i v-else class="fa-fw fal fa-wrench color-yellow"></i>

                {{ `v${version.version}` }}

                <span v-if="i === 0" class="label bg-green">
                    <div style="font-size: 13px">
                        <i class="fa fa-check color-white"></i>
                        <span>{{ T("Current Version") }}</span>
                    </div>
                </span>
            </div>
            <small class="version-date">{{
                timeHelpers.formatDate(version.date, timeHelpers.getDateFormatI18n(false))
            }}</small>
        </h2>

        <div class="row form-group">
            <div class="col-xs-24">
                <MarkdownRenderer :src="version?.changelog" class="markdown" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import config from "@/classes/config"
import i18n, { T } from "@/classes/i18n"
import getterHelpers from "@/helpers/helpers.getters"
import mixinHelpers from "@/helpers/helpers.mixins"
import timeHelpers from "@/helpers/helpers.time"
import vue from "@/main"
import requestHandler from "@/queries/requests"
import { MutationTypes, useStore } from "@/store/vuex.store"
import { computed } from "vue"
import type { UpdateNotification } from "../../../../landingpages/src/classes/sessions"
import MarkdownRenderer from "../components/markdown-renderer.vue"

const activeAccountId = computed(() => {
    return mixinHelpers.getActiveAccountId()
})
const versions = computed(() =>
    getUpdateNotifications()
        .reverse()
        .map((versionInfo) => {
            const { message, date, version } = versionInfo

            return {
                date,
                version,
                changelog: message[i18n.getLanguage() as keyof typeof message] || "N/A"
            }
        })
)

function getUpdateNotifications(): UpdateNotification[] {
    return useStore().state.session.userInfo.updateNotification || []
}

// Exposes for Submit
async function submit() {
    vue.$refs.modals.$refs.modal.modal.buttons[0].loading = true
    vue.$refs.modals.$refs.modal.modal.buttons[0].disabled = true

    try {
        await requestHandler.request("PUT", config.mgtApiUriNext + "/user/update/notification")
    } catch (e) {
        console.error(e)
    }
    getterHelpers.useStore().commit(MutationTypes.removeModal, { accountId: activeAccountId.value })
}

defineExpose({
    submit
})
</script>

<style lang="scss">
.markdown ul li,
.markdown ol li {
    margin: 0.7em;
}

.markdown ul,
.markdown ol {
    padding: 0 0 0 0.7em;
}

.version-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.version-info {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.version-info .fa-wrench {
    margin-right: 0.5em;
}

.version-info .label {
    margin-left: 1em;
    display: flex;
    align-items: center;
}

.version-date {
    font-size: smaller;
    color: gray;
}
</style>

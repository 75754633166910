<script setup lang="ts">
import { T } from "@/classes/i18n"
import deviceHelpers from "@/helpers/helpers.devices"
import getterHelpers from "@/helpers/helpers.getters"
import numberHelpers from "@/helpers/helpers.numbers"
import useRouterStore from "@/router/routerStore"
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from "vue"

import products from "@/classes/objectTypes"
import formValidationHelpers from "@/helpers/helpers.form.validate"
import tenantHelpers from "@/helpers/helpers.tenants"
import router from "@/router/router"
import moment from "moment"

import itemlistItem from "@/templates/components/itemlist-item.vue"
import loader from "@/templates/components/loader.vue"
import inputImage64 from "@/templates/inputtypes/input-image64-async"
import InputVueSelect from "@/templates/inputtypes/input-vue-select.vue"
import tableNext from "../components/tableNext.vue"

import apis from "@/classes/apis"
import type { IosAppsKnownInstallation } from "@/classes/objectTypes/mobileSecurity/apple/iosApps"
import encodingHelpers from "@/helpers/helpers.encoding"
import timeHelpers from "@/helpers/helpers.time"
import requestHandler from "@/queries/requests"
import { ActionTypes } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import { xml } from "@codemirror/lang-xml"
import { linter } from "@codemirror/lint"
import { Codemirror } from "vue-codemirror"
import { Carousel, Navigation, Slide } from "vue3-carousel"

const activeAccountId = computed(() => {
    return getterHelpers.useStore().getters.getActiveAccountId
})

const activePage = computed(() => {
    return useRouterStore().activePage
})

const context = computed(() => {
    return useRouterStore().context
})

const objectId = computed(() => {
    return useRouterStore().getObjectId
})

const activeTenantVppEnabled = computed(() => {
    if (activeAccountId.value) {
        return tenantHelpers.hasFunctionality(activeAccountId.value, "vpp")
    } else {
        return false
    }
})

const thisApplication = computed(() => {
    return objectId.value
        ? products.mobileSecurity.iosApps
              .useStore?.()
              .getObjectStoreObject(activeAccountId.value, objectId.value)
        : undefined
})

const dontSendFieldsByProperty = ref(<string[]>[])

const switchedPage = ref(<boolean>false)
const initialized = ref(<boolean>false)
const loading = ref(<boolean>false)
const updatingThisApp = ref(<boolean>false)
const loaderText = ref("Loading...")

const managedConfigurationPlaceHolder = `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE plist PUBLIC "-//Apple//DTD PLIST 1.0//EN" "http://www.apple.com/DTDs/PropertyList-1.0.dtd">
<plist version="1.0">
	<dict>
		<key>LicenseKey</key>
		<string>AAAAAA-BBBBBB-CCCCCC-DDDDDD-123456-V3</string>
	</dict>
</plist>`

const form = ref({
    Options: {
        PurchaseMethod: <0 | 1 | "none">"none"
    },
    ManagementFlags: 0,
    appSource: "Identifier",
    package: "",
    Icon: "",
    appId: "",
    IsRemovable: true,
    URL: "",
    Label: "",
    FullScreen: false,
    IgnoreManifestScope: false,
    Precomposed: false,
    TargetApplicationBundleIdentifier: "com.apple.mobilesafari",
    name: "",
    platform: "IOS",
    devices: <string[]>[],
    users: <string[]>[],
    roles: <string[]>[],
    tags: <string[]>[],
    comment: "",
    Identifier: "",
    iTunesStoreID: <string | number>"",
    ManifestURL: "",
    uninstallPackageName: "",
    CheckboxChangeManagementState: true,
    disableAutomaticVPPLicenseAssignment: "0",
    Configuration: <undefined | any>undefined,
    managedConfiguration: ""
})

const appInfo = ref({
    assetInfo: {
        artworkUrl60: "",
        trackName: "",
        screenshotUrls: [],
        ipadScreenshotUrls: [],
        description: undefined,
        releaseNotes: undefined,
        version: undefined,
        trackId: undefined,
        bundleId: undefined,
        sellerName: undefined,
        fileSizeBytes: undefined,
        genres: [],
        minimumOsVersion: undefined,
        trackContentRating: undefined,
        advisories: [],
        currentVersionReleaseDate: undefined,
        sellerUrl: undefined
    },

    fileSize: <undefined | { value: number; unit: string }>undefined
})

const selectOptions = ref({
    devicesIos: <selectOption[]>[],
    users: <selectOption[]>[],
    roles: <selectOption[]>[],
    tags: <selectOption[]>[],
    platform: [
        { id: "IOS", text: "App" },
        { id: "WEBCLIP", text: "Webclip" }
    ],
    appSourcesIos: [
        { id: "iTunesStoreID", text: T("iTunes Store ID") },
        { id: "iTunesStoreIDCustomized", text: T("Customized App ID") },
        { id: "ManifestURL", text: T("Manifest URL") },
        { id: "Identifier", text: T("Identifier") }
    ],
    identifierIos: <selectOption[]>[],
    TargetApplicationBundleIdentifier: [{ id: "com.apple.mobilesafari", text: "Safari" }]
})

const activeTab = ref("description")
const setActiveTab = (tabname: string) => {
    activeTab.value = tabname
}

const cancel = () => {
    router.navigate("show-tenant-" + activeAccountId.value + ".sms-ios-apps-dashboard")
}

const refreshAppInfo = async () => {
    updatingThisApp.value = true
    if (objectId.value) {
        await products.mobileSecurity.iosApps.queries.getObjectFromApi(
            activeAccountId.value,
            objectId.value,
            undefined,
            undefined,
            true
        )
    }
    updatingThisApp.value = false
}
const refreshButton = ref(
    new Button({
        icon: "fal fa-sync",
        title: T("Refresh"),
        onClick: () => {
            refreshAppInfo()
        },
        loading: () => {
            return updatingThisApp.value
        }
    })
)
const updateLabel = () => {
    if (form.value.platform == "WEBCLIP" && objectId.value == undefined && !form.value.Label) {
        form.value.Label = form.value.name
    }
}

const generateObject = () => {
    let object: any = {
        platform: form.value.platform,
        Configuration: undefined,
        Options: {
            PurchaseMethod: form.value.Options.PurchaseMethod
        },
        ManagementFlags: form.value.ManagementFlags,
        appSource: form.value.appSource,
        package: form.value.package,
        "Icon:data": form.value.Icon.replace("data:image/png;base64,", ""),
        appId: form.value.appId,
        URL: form.value.URL,
        IsRemovable: form.value.IsRemovable,
        Label: form.value.Label,
        name: form.value.name,
        devices: form.value.devices,
        users: form.value.users,
        roles: form.value.roles,
        tags: form.value.tags,
        comment: form.value.comment,
        Identifier: form.value.Identifier,
        iTunesStoreID: form.value.iTunesStoreID,
        ManifestURL: form.value.ManifestURL,
        uninstallPackageName: form.value.uninstallPackageName,
        ChangeManagementState: form.value.CheckboxChangeManagementState ? "Managed" : "",
        FullScreen: form.value.FullScreen,
        IgnoreManifestScope: form.value.IgnoreManifestScope,
        Precomposed: form.value.Precomposed,
        TargetApplicationBundleIdentifier: form.value.TargetApplicationBundleIdentifier,
        disableAutomaticVPPLicenseAssignment: form.value.disableAutomaticVPPLicenseAssignment,
        managedConfiguration: undefined
    }
    if (form.value.managedConfiguration.length > 0) {
        object.managedConfiguration = btoa(form.value.managedConfiguration)
    }
    if (form.value.Configuration) {
        object.Configuration = form.value.Configuration
    }
    if (form.value.URL.length == 0) {
        dontSendFieldsByProperty.value.push("URL")
    } else if (object.URL.indexOf("https://http://") != -1) {
        object.URL.replace("https://http://", "http://")
    } else if (object.URL.indexOf("https://") == -1 && object.URL.indexOf("http://") == -1) {
        object.URL = "https://" + object.URL
    }
    if (form.value.Icon == "") {
        dontSendFieldsByProperty.value.push("Icon")
        delete object["Icon:data"]
    }
    if (object.disableAutomaticVPPLicenseAssignment === false) {
        delete object.disableAutomaticVPPLicenseAssignment
    }
    if (!form.value.appId) {
        delete object.appId
    }
    if (form.value.platform != "WEBCLIP") {
        delete object.URL
        delete object["Icon:data"]
        delete object.FullScreen
        delete object.IgnoreManifestScope
        delete object.Precomposed
        delete object.TargetApplicationBundleIdentifier
    }

    if (form.value.platform == "IOS") {
        if (object.Options.PurchaseMethod == "none") {
            delete object.Options
        }
        if (object.iTunesStoreID > 0) {
            delete object.Identifier
            delete object.ManifestURL
        } else if (object.Identifier?.length > 0) {
            delete object.iTunesStoreID
            delete object.ManifestURL
        } else if (object.ManifestURL?.length > 0) {
            delete object.iTunesStoreID
            delete object.Identifier
        }
    }
    if (form.value.platform == "WEBCLIP") {
        delete object.Options
        delete object.ManagementFlags
        delete object.appSource
        delete object.uninstallPackageName
        delete object.iTunesStoreID
        delete object.Identifier
        delete object.ManifestURL
    }
    delete object.package
    delete object.appSource
    delete object.appSourceAndroid

    if (object.ChangeManagementState == "") {
        delete object.ChangeManagementState
    }
    return object
}

const addOrUpdate = async () => {
    loading.value = true
    formValidationHelpers.clearErrors()
    let object = generateObject()
    let result: any = undefined
    dontSendFieldsByProperty.value = []
    try {
        if (objectId.value) {
            result = await products.mobileSecurity.iosApps.queries.updateObjectFromApi(
                activeAccountId.value,
                objectId.value,
                object,
                undefined,
                true
            )
        } else {
            result = await products.mobileSecurity.iosApps.queries.addObjectToApi(
                activeAccountId.value,
                object,
                undefined,
                true
            )
        }
        if (result.status == 400) {
            throw result.data
        }
        router.navigate("show-tenant-" + activeAccountId.value + ".sms-ios-apps-dashboard")
    } catch (e: any) {
        loading.value = false
        e = apis.parseApiResponse(e)

        if (e.errors?.valid === false && e.errors.error) {
            // we got managed configuration error o_o

            managedConfigErrorInputLine.value = e.errors.error.line
            managedConfigurationError.value = e.errors.error.message

            setActiveTab("managedConfiguration")
        } else if (e.errors && e.errors.valid == false) {
            formValidationHelpers.checkInputs(e.errors, $("[id^=app-]"))
        } else if (e.responseJSON.errors && e.responseJSON.errors.valid == false) {
            formValidationHelpers.checkInputs(e.responseJSON.errors, $("[id^=app-]"))
        }
    }
}

const searchIosApp = async (searchFor: string, searchString: string) => {
    try {
        if (searchString) {
            let item: any = await requestHandler.request(
                "GET",
                "https://itunes.apple.com/lookup?" +
                    escape(searchFor) +
                    "=" +
                    escape(searchString) +
                    "&media=software&entity=software,iPadSoftware&country=DE&_=" +
                    getterHelpers.useStore().state.randomSeed
            )
            if (item.resultCount == 1 && item.results[0] != undefined) {
                item.results[0].isInStore = true
                appInfo.value.fileSize = numberHelpers.formatBytes(item.results[0].fileSizeBytes)
                appInfo.value.assetInfo = item.results[0]
                if (initialized.value && form.value.name == "") {
                    form.value.name =
                        form.value.name != "" ? form.value.name : item.results[0].trackName
                }
                form.value.uninstallPackageName = item.results[0].bundleId
                if (objectId.value) {
                    products.mobileSecurity.iosApps
                        .useStore?.()
                        .setObjectTypeObjectProperty(
                            activeAccountId.value,
                            objectId.value,
                            item.results[0],
                            "assetInfo"
                        )
                }
            }
        } else {
            appInfo.value.fileSize = undefined
        }
    } catch (e) {
        console.error(e)
    }
}

const init = async (doRequests: boolean = true) => {
    updatingThisApp.value = true
    if (mounted.value) {
        let appId = objectId.value
        if (objectId.value && activeAccountId.value && doRequests) {
            try {
                await products.mobileSecurity.iosApps.queries.getObjectFromApi(
                    activeAccountId.value,
                    appId || "",
                    undefined,
                    undefined,
                    true
                )
            } catch (e) {
                console.error(e)
            }
        }
        await timeHelpers.sleep(500)
        if (activeAccountId.value && appId && thisApplication.value == undefined) {
            if (switchedPage.value == false) {
                router.navigate("404-" + appId)
            }
        } else {
            if (doRequests) {
                // get users
                await products.unifiedSecurity.users.queries.getObjectsFromApi(
                    activeAccountId.value,
                    undefined,
                    [
                        { property: "props[]", value: "username" },
                        { property: "props[]", value: "rspUserId" },
                        { property: "props[]", value: "rspLogin" }
                    ]
                )
                // get roles
                await products.unifiedSecurity.roles.queries.getObjectsFromApi(
                    activeAccountId.value,
                    undefined,
                    [{ property: "props[]", value: "rolename" }]
                )
                // get Devices
                await products.mobileSecurity.iosDevices.queries.getObjectsFromApi(
                    activeAccountId.value,
                    undefined,
                    [
                        { property: "props[]", value: "licenseUUID" },
                        { property: "props[]", value: "props" },
                        { property: "props[]", value: "profile" },
                        { property: "props[]", value: "deviceId" },
                        { property: "props[]", value: "alias" },
                        { property: "props[]", value: "deviceType" },
                        { property: "props[]", value: "info" },
                        { property: "props[]", value: "serialNumber" }
                    ]
                )

                await getterHelpers.useStore().dispatch(ActionTypes.getObjectInfos, {
                    accountId: activeAccountId.value,
                    objectTypes: ["tags"]
                })
            }

            let users =
                products.unifiedSecurity.users
                    .useStore?.()
                    ?.getObjectStoreObjects(activeAccountId.value) || []
            let roles =
                products.unifiedSecurity.roles
                    .useStore?.()
                    ?.getObjectStoreObjects(activeAccountId.value) || []
            let devices =
                products.mobileSecurity.iosDevices
                    .useStore?.()
                    ?.getObjectStoreObjects(activeAccountId.value) || []

            let allTags: any =
                getterHelpers.useStore().getters.getObjectTypeStore({
                    accountId: activeAccountId.value,
                    objectType: "tags"
                })?.items || []

            selectOptions.value.devicesIos = devices.map((device) => {
                return {
                    id: device.deviceId,
                    text: deviceHelpers.getAliasedShortDeviceId(device.deviceId, device.alias)
                }
            })

            selectOptions.value.users = users.map(function (user) {
                return {
                    id: user.username,
                    text: user?.rspUserId ? (user.rspLogin as string) : user.username
                }
            })

            selectOptions.value.roles = roles.map(function (role) {
                return { id: role.rolename, text: role.rolename }
            })

            selectOptions.value.tags = allTags.sort((tagA: selectOption, tagB: selectOption) => {
                return tagA.text.toLowerCase() > tagB.text.toLowerCase() ? 1 : -1
            })

            if (
                appId != undefined &&
                thisApplication.value &&
                thisApplication.value.platform &&
                thisApplication.value.devices &&
                thisApplication.value.users &&
                thisApplication.value.roles &&
                thisApplication.value.tags
            ) {
                form.value.appId = thisApplication.value.appId
                form.value.name = encodingHelpers.unescapeHTML(thisApplication.value.name || "")
                form.value.platform = thisApplication.value.platform
                form.value.devices = thisApplication.value.devices
                form.value.users = thisApplication.value.users
                form.value.roles = thisApplication.value.roles
                form.value.tags = thisApplication.value.tags
                form.value.comment = encodingHelpers.unescapeHTML(
                    thisApplication.value.comment || ""
                )
                form.value.uninstallPackageName = thisApplication.value.uninstallPackageName || ""
                form.value.managedConfiguration = atob(
                    thisApplication.value.managedConfiguration || ""
                )

                if (thisApplication.value.Configuration != undefined) {
                    form.value.Configuration = thisApplication.value.Configuration
                }
                if (thisApplication.value.ManagementFlags != undefined) {
                    form.value.ManagementFlags = thisApplication.value.ManagementFlags
                }
                if (thisApplication.value.Options?.PurchaseMethod != undefined) {
                    form.value.Options.PurchaseMethod = thisApplication.value.Options.PurchaseMethod
                }
                if (thisApplication.value.disableAutomaticVPPLicenseAssignment != undefined) {
                    form.value.disableAutomaticVPPLicenseAssignment =
                        thisApplication.value.disableAutomaticVPPLicenseAssignment
                }
                if (thisApplication.value.iTunesStoreID) {
                    form.value.appSource = "iTunesStoreID"
                    form.value.iTunesStoreID = thisApplication.value.iTunesStoreID
                } else if (thisApplication.value.ManifestURL) {
                    form.value.appSource = "ManifestURL"
                    form.value.ManifestURL = thisApplication.value.ManifestURL
                    setActiveTab("settings")
                } else if (thisApplication.value.Identifier) {
                    form.value.appSource = "Identifier"
                    form.value.Identifier = thisApplication.value.Identifier
                }
                if (thisApplication.value.URL) {
                    form.value.URL = thisApplication.value.URL
                }
                if (thisApplication.value["Icon:data"]) {
                    form.value.Icon = "data:image/png;base64," + thisApplication.value["Icon:data"]
                }
                if (thisApplication.value.FullScreen) {
                    form.value.FullScreen = thisApplication.value.FullScreen
                }
                if (thisApplication.value.IgnoreManifestScope) {
                    form.value.IgnoreManifestScope = thisApplication.value.IgnoreManifestScope
                }
                if (thisApplication.value.Precomposed) {
                    form.value.Precomposed = thisApplication.value.Precomposed
                }
                if (thisApplication.value.TargetApplicationBundleIdentifier) {
                    form.value.TargetApplicationBundleIdentifier =
                        thisApplication.value.TargetApplicationBundleIdentifier
                }
                if (thisApplication.value.IsRemovable !== undefined) {
                    form.value.IsRemovable = thisApplication.value.IsRemovable
                }
                if (thisApplication.value.Label) {
                    form.value.Label = thisApplication.value.Label
                }
                if (thisApplication.value.ChangeManagementState === "Managed") {
                    form.value.CheckboxChangeManagementState = true
                } else {
                    form.value.CheckboxChangeManagementState = false
                }
            }
            initialized.value = true
        }
    }
    updatingThisApp.value = false
}

function lineToNodeRange(line: number | undefined) {
    if (line === undefined) {
        return {
            from: -1,
            to: -1
        }
    }

    line -= 1

    const data = form.value.managedConfiguration
    const split = data.split("\n")

    let start = 0
    for (let i = 0; i < line; i++) {
        start += split[i].length + 1
    }

    return {
        from: start,
        to: start + (split[line]?.length || 0)
    }
}

function triggerManagedConfigurationRender() {
    //this is a hack to trigger a re-render
    form.value.managedConfiguration += " "

    nextTick(() => {
        form.value.managedConfiguration = form.value.managedConfiguration.slice(0, -1)
    })
}

const managedConfigurationError = ref("")
const managedConfigErrorInputLine = ref<number>()

const _linter = linter(
    () => {
        return [
            {
                ...lineToNodeRange(managedConfigErrorInputLine.value),
                severity: "error",
                message: managedConfigurationError.value
            }
        ]
    },
    {
        delay: 0
    }
)

const codeMirrorExtensions = [xml(), _linter]

watch(managedConfigErrorInputLine, () => triggerManagedConfigurationRender())

watch(
    () => form.value.Identifier,
    (identifier) => {
        if (objectId.value != undefined && thisApplication.value?.assetInfo != undefined) {
            ;(<any>appInfo.value).assetInfo = thisApplication.value.assetInfo
        } else {
            searchIosApp("bundleId", identifier)
        }
    }
)

watch(
    () => form.value.iTunesStoreID,
    (iTunesStoreID) => {
        if (objectId.value != undefined && thisApplication.value?.assetInfo != undefined) {
            ;(<any>appInfo.value).assetInfo = thisApplication.value.assetInfo
        } else {
            searchIosApp("id", String(iTunesStoreID))
        }
    }
)
watch([activePage, context], () => {
    if (activePage.value != "iosApps" || context.value == undefined) {
        switchedPage.value = true
    }
})
watch(thisApplication, () => {
    init(false)
})
const mounted = ref(false)
onMounted(() => {
    mounted.value = true
    switchedPage.value = false
    init()
})
onBeforeUnmount(() => {
    mounted.value = false
    switchedPage.value = true
})
</script>
<template>
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
        <div v-if="!initialized" class="page-loader">
            <div class="text-size-3 text-center padding-xs-t-4">
                <loader class="color-red"></loader>
            </div>
            <template v-if="loaderText">
                <div class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity: 0.8">
                    <span>
                        {{ T(loaderText) }}
                    </span>
                </div>
            </template>
            <template v-else>
                <div class="padding-xs-b-4"></div>
            </template>
        </div>

        <template v-if="form.platform == 'IOS' && initialized && form.appId">
            <div class="row itemlist show-details view-grid">
                <itemlist-item
                    :item="thisApplication"
                    :editable="false"
                    :show-menu="false"
                    :clickable="false"
                    class="col-xs-24 col-print-24 col-lg-12"
                ></itemlist-item>
                <div class="col-xs-24 col-print-24 col-lg-12 padding-xs"></div>
            </div>

            <div class="row">
                <div class="col-xs-24 padding-xs">
                    <div class="box box-shadow app-box app-details-box">
                        <div class="tabs">
                            <nav>
                                <ul class="nav nav-tabs hide-on-print">
                                    <li v-if="!form.ManifestURL">
                                        <a
                                            :class="{ active: activeTab == 'description' }"
                                            v-on:click="setActiveTab('description')"
                                        >
                                            <i class="fal fa-fw fa-info-circle"></i>
                                            {{ T("Description") }}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            :class="{ active: activeTab == 'settings' }"
                                            v-on:click="setActiveTab('settings')"
                                        >
                                            <i class="fal fa-fw fa-wrench"></i> {{ T("Settings") }}
                                        </a>
                                    </li>
                                    <li v-if="form.platform == 'IOS'">
                                        <a
                                            :class="{ active: activeTab == 'devices' }"
                                            v-on:click="setActiveTab('devices')"
                                        >
                                            <i class="fal fa-fw fa-mobile-alt"></i>
                                            {{ T("Known installations") }}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            :class="{ active: activeTab == 'managedConfiguration' }"
                                            v-on:click="setActiveTab('managedConfiguration')"
                                        >
                                            <i class="fal fa-fw fa-cog"></i>
                                            {{ T("Managed Configuration") }}
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <div class="tabcontent">
                                <div
                                    :class="{ hidden: activeTab != 'description' }"
                                    v-if="!form.ManifestURL"
                                >
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <template
                                                    v-if="
                                                        (appInfo.assetInfo.screenshotUrls || [])
                                                            .length ||
                                                        (appInfo.assetInfo.ipadScreenshotUrls || [])
                                                            .length
                                                    "
                                                >
                                                    <h4>Screenshots</h4>
                                                    <div>
                                                        <template
                                                            v-if="
                                                                (
                                                                    appInfo.assetInfo
                                                                        .screenshotUrls || []
                                                                ).length
                                                            "
                                                        >
                                                            <Carousel
                                                                :items-to-show="3"
                                                                :wrap-around="true"
                                                                :autoplay="5000"
                                                                :transition="1500"
                                                            >
                                                                <Slide
                                                                    v-for="url in appInfo.assetInfo
                                                                        .screenshotUrls"
                                                                    :key="url"
                                                                >
                                                                    <div class="carousel__item">
                                                                        <img :src="url" />
                                                                    </div>
                                                                </Slide>
                                                                <template #addons>
                                                                    <Navigation />
                                                                </template>
                                                            </Carousel>
                                                        </template>
                                                        <template
                                                            v-else-if="
                                                                (
                                                                    appInfo.assetInfo
                                                                        .ipadScreenshotUrls || []
                                                                ).length
                                                            "
                                                        >
                                                            <Carousel
                                                                :items-to-show="3"
                                                                :wrap-around="true"
                                                                :autoplay="5000"
                                                                :transition="1500"
                                                            >
                                                                <Slide
                                                                    v-for="url in appInfo.assetInfo
                                                                        .ipadScreenshotUrls"
                                                                    :key="url"
                                                                >
                                                                    <div class="carousel__item">
                                                                        <img :src="url" />
                                                                    </div>
                                                                </Slide>
                                                                <template #addons>
                                                                    <Navigation />
                                                                </template>
                                                            </Carousel>
                                                        </template>
                                                    </div>
                                                    <hr class="margin-xs-y-2" />
                                                </template>
                                                <h4>{{ T("Description") }}</h4>
                                                <p>{{ appInfo.assetInfo.description }}</p>
                                                <hr class="margin-xs-y-2" />
                                                <h4>{{ T("News") }}</h4>
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <p>
                                                            {{ appInfo.assetInfo.releaseNotes }}
                                                        </p>
                                                    </div>
                                                    <div class="col-xs-12">
                                                        <p class="text-right">
                                                            Version: {{ appInfo.assetInfo.version }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr class="margin-xs-y-2" />
                                                <h4>{{ T("Infos") }}</h4>
                                                <table>
                                                    <tr v-if="appInfo.assetInfo.trackId">
                                                        <td>{{ T("ID") }}</td>
                                                        <td>{{ appInfo.assetInfo.trackId }}</td>
                                                    </tr>
                                                    <tr v-if="appInfo.assetInfo.bundleId">
                                                        <td>{{ T("Bundle ID") }}</td>
                                                        <td>{{ appInfo.assetInfo.bundleId }}</td>
                                                    </tr>
                                                    <tr v-if="appInfo.assetInfo.sellerName">
                                                        <td>{{ T("Seller") }}</td>
                                                        <td>{{ appInfo.assetInfo.sellerName }}</td>
                                                    </tr>
                                                    <tr v-if="appInfo.assetInfo.fileSizeBytes">
                                                        <td>{{ T("Size") }}</td>
                                                        <td>
                                                            {{
                                                                numberHelpers.formatBytes(
                                                                    appInfo.assetInfo.fileSizeBytes,
                                                                    2
                                                                ).value +
                                                                " " +
                                                                numberHelpers.formatBytes(
                                                                    appInfo.assetInfo.fileSizeBytes,
                                                                    2
                                                                ).unit
                                                            }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="appInfo.assetInfo.genres">
                                                        <td>{{ T("Category") }}</td>
                                                        <td>
                                                            <template
                                                                v-for="(genre, index) in appInfo
                                                                    .assetInfo.genres"
                                                            >
                                                                {{ genre
                                                                }}<template
                                                                    v-if="
                                                                        appInfo.assetInfo.genres
                                                                            .length >
                                                                        index + 1
                                                                    "
                                                                    >,
                                                                </template>
                                                            </template>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="appInfo.assetInfo.minimumOsVersion">
                                                        <td>{{ T("Compatibility") }}</td>
                                                        <td>
                                                            {{
                                                                T("Requires iOS") +
                                                                " " +
                                                                appInfo.assetInfo.minimumOsVersion +
                                                                " " +
                                                                T("or later") +
                                                                "."
                                                            }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="appInfo.assetInfo.trackContentRating">
                                                        <td>{{ T("Age Rating") }}</td>
                                                        <td>
                                                            {{ appInfo.assetInfo.trackContentRating
                                                            }}<br />
                                                            <template
                                                                v-for="(advisory, index) in appInfo
                                                                    .assetInfo.advisories"
                                                            >
                                                                {{ advisory
                                                                }}<template
                                                                    v-if="
                                                                        appInfo.assetInfo.advisories
                                                                            .length > index
                                                                    "
                                                                    ><br
                                                                /></template>
                                                            </template>
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        v-if="
                                                            appInfo.assetInfo
                                                                .currentVersionReleaseDate
                                                        "
                                                    >
                                                        <td>{{ T("Copyright") }}</td>
                                                        <td>
                                                            &copy;
                                                            {{
                                                                moment(
                                                                    appInfo.assetInfo
                                                                        .currentVersionReleaseDate
                                                                ).format("YYYY") +
                                                                " " +
                                                                appInfo.assetInfo.sellerName
                                                            }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="appInfo.assetInfo.sellerUrl">
                                                        <td></td>
                                                        <td>
                                                            <a
                                                                target="_blank"
                                                                rel="noreferrer noopener"
                                                                :href="appInfo.assetInfo.sellerUrl"
                                                                >{{ T("Developer Website") }}</a
                                                            >
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    :class="{ hidden: activeTab != 'settings' }"
                                    class="padding-xs-2"
                                >
                                    <form
                                        enctype="multipart/form-data"
                                        method="POST"
                                        id="appForm"
                                        v-if="initialized"
                                    >
                                        <input
                                            type="hidden"
                                            id="app-platform"
                                            name="platform"
                                            data-validation-property="/platform"
                                            :value="form.platform"
                                            :placeholder="T('Choose a platform')"
                                        />
                                        <input
                                            type="hidden"
                                            id="app-source-ios"
                                            class="form-control"
                                            :value="form.appSource"
                                        />
                                        <input
                                            v-if="
                                                (form.appSource == 'iTunesStoreID' ||
                                                    form.appSource == 'iTunesStoreIDCustomized') &&
                                                form.platform == 'IOS'
                                            "
                                            type="hidden"
                                            id="app-iTunesStoreID-ios"
                                            name="iTunesStoreID"
                                            style="width: 100%"
                                            class="disabled"
                                            data-validation-property="/iTunesStoreID"
                                            :value="form.iTunesStoreID"
                                            readonly
                                        />
                                        <input
                                            v-if="
                                                form.appSource == 'Identifier' &&
                                                form.platform == 'IOS'
                                            "
                                            type="hidden"
                                            id="app-Identifier-ios"
                                            name="Identifier"
                                            style="width: 100%"
                                            class="disabled"
                                            data-validation-property="/Identifier"
                                            :value="form.Identifier"
                                            readonly
                                        />
                                        <input
                                            v-if="
                                                form.appSource == 'ManifestURL' &&
                                                form.platform == 'IOS'
                                            "
                                            type="hidden"
                                            id="app-ManifestURL-ios"
                                            name="ManifestURL"
                                            class="disabled"
                                            :placeholder="T('Manifest URL')"
                                            data-validation-property="/ManifestURL"
                                            :value="form.ManifestURL"
                                            readonly
                                        />

                                        <div
                                            class="row padding-xs-y form-group border-bottom all-only"
                                            :class="{ hidden: appInfo.assetInfo.trackName != '' }"
                                        >
                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                <label class="control-label inputname">{{
                                                    T("Name")
                                                }}</label>
                                            </div>
                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                <label class="form-field margin-xs-b-0">
                                                    <input
                                                        id="app-name"
                                                        name="name"
                                                        type="text"
                                                        class="form-control user-selection"
                                                        v-model="form.name"
                                                        :placeholder="T('Name')"
                                                        required
                                                        data-validation-property="/name"
                                                    />
                                                </label>
                                            </div>
                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                <p class="input-description">
                                                    {{ T("The name of the App") }}
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            class="row padding-xs-y form-group border-bottom all-only"
                                            v-if="
                                                form.appSource == 'ManifestURL' &&
                                                form.platform == 'IOS'
                                            "
                                        >
                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                <label class="control-label inputname">{{
                                                    T("Manifest URL")
                                                }}</label>
                                            </div>
                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                <label class="form-field margin-xs-b-0">
                                                    <input
                                                        v-if="
                                                            form.appSource == 'ManifestURL' &&
                                                            form.platform == 'IOS'
                                                        "
                                                        type="text"
                                                        id="app-ManifestURL-ios"
                                                        name="ManifestURL"
                                                        :placeholder="T('Manifest URL')"
                                                        data-validation-property="/ManifestURL"
                                                        v-model="form.ManifestURL"
                                                    />
                                                </label>
                                            </div>
                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                <p class="input-description">
                                                    {{ T("Enter the manifest URL") }}
                                                </p>
                                            </div>
                                        </div>

                                        <!-- invisible elements for form submit-->
                                        <input
                                            type="hidden"
                                            id="app-uninstallPackageName"
                                            name="uninstallPackageName"
                                            style="display: none"
                                        />

                                        <div
                                            class="row padding-xs-y form-group border-bottom ios-only padding-xs-y"
                                            v-if="form.platform == 'IOS'"
                                        >
                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                <label
                                                    for="app-CheckboxChangeManagementState-ios"
                                                    class="control-label float-xs-left"
                                                    ><span>{{ T("Take management") }}</span></label
                                                >
                                            </div>
                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                <label class="checkbox toggle form-field">
                                                    <input
                                                        type="checkbox"
                                                        id="app-CheckboxChangeManagementState-ios"
                                                        class="form-control user-selection"
                                                        style="width: 100%"
                                                        v-model="form.CheckboxChangeManagementState"
                                                    />
                                                    <span></span>
                                                </label>
                                            </div>
                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                <p class="input-description">
                                                    {{
                                                        T(
                                                            "Set the app as managed or take management if the user has installed it already. The managed state is necessary to uninstall the app from remote."
                                                        )
                                                    }}
                                                    {{
                                                        T(
                                                            "This value doesn’t work with the User Enrollment feature introduced in iOS 13."
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <template
                                            v-if="form.platform == 'IOS' && activeTenantVppEnabled"
                                        >
                                            <div
                                                class="row padding-xs-y form-group border-bottom ios-only padding-xs-y"
                                            >
                                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                    <label
                                                        for="app-PurchaseMethod-ios"
                                                        class="control-label float-xs-left"
                                                    >
                                                        <span>{{ T("Purchase Method") }}</span>
                                                    </label>
                                                </div>
                                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                    <label class="select form-field">
                                                        <select
                                                            id="app-PurchaseMethod-ios"
                                                            class="form-control user-selection"
                                                            style="width: 100%"
                                                            v-model="form.Options.PurchaseMethod"
                                                            name="Options[PurchaseMethod]:number"
                                                        >
                                                            <option value="none">
                                                                {{ T("Unspecified") }}
                                                            </option>
                                                            <option :value="0">
                                                                {{
                                                                    T(
                                                                        "Legacy Volume Purchase Program"
                                                                    )
                                                                }}
                                                            </option>
                                                            <option :value="1">
                                                                {{
                                                                    T(
                                                                        "Volume Purchase Program App Assignment"
                                                                    )
                                                                }}
                                                            </option>
                                                        </select>
                                                    </label>
                                                </div>
                                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                    <p class="input-description">
                                                        {{
                                                            T(
                                                                'Use "Volume Purchase Program App Assignment" to install 1st party apps (Mail, Safari, etc.) without users being logged into the iTunes Store.'
                                                            )
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                        </template>

                                        <template
                                            v-if="
                                                form.platform == 'IOS' &&
                                                activeTenantVppEnabled &&
                                                form.Options.PurchaseMethod == 1
                                            "
                                        >
                                            <div
                                                class="row padding-xs-y form-group border-bottom ios-only padding-xs-y"
                                            >
                                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                    <label
                                                        for="app-disableAutomaticVPPLicenseAssignment-ios"
                                                        class="control-label float-xs-left"
                                                    >
                                                        <span>{{
                                                            T("Automatic VPP license assignment")
                                                        }}</span>
                                                    </label>
                                                </div>
                                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                    <label class="select form-field">
                                                        <select
                                                            id="app-disableAutomaticVPPLicenseAssignment-ios"
                                                            class="form-control user-selection"
                                                            style="width: 100%"
                                                            v-model="
                                                                form.disableAutomaticVPPLicenseAssignment
                                                            "
                                                            name="app-disableAutomaticVPPLicenseAssignment-ios"
                                                        >
                                                            <option value="0">
                                                                {{ T("Enabled") }}
                                                            </option>
                                                            <option value="1">
                                                                {{ T("Disabled") }}
                                                            </option>
                                                        </select>
                                                    </label>
                                                </div>
                                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                    <p class="input-description">
                                                        {{
                                                            T(
                                                                "Choose wether the VPP app license should be automatically assigned to the device or not."
                                                            )
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                        </template>

                                        <div
                                            class="row padding-xs-y form-group border-bottom ios-only padding-xs-y"
                                            v-if="form.platform == 'IOS'"
                                        >
                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                <label
                                                    for="app-PurchaseMethod-ios"
                                                    class="control-label float-xs-left"
                                                >
                                                    <span>{{ T("Management flags") }}</span>
                                                </label>
                                            </div>
                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                <label class="select form-field">
                                                    <select
                                                        id="app-ManagementFlags-ios"
                                                        class="form-control user-selection"
                                                        style="width: 100%"
                                                        v-model="form.ManagementFlags"
                                                        name="ManagementFlags:number"
                                                    >
                                                        <option :value="0">
                                                            {{ T("Both disabled") }}
                                                        </option>
                                                        <option :value="1">
                                                            {{
                                                                T(
                                                                    "Remove app when MDM profile is removed"
                                                                )
                                                            }}
                                                        </option>
                                                        <option :value="4">
                                                            {{
                                                                T("Prevent backup of the app data")
                                                            }}
                                                        </option>
                                                        <option :value="5">
                                                            {{ T("Both enabled") }}
                                                        </option>
                                                    </select>
                                                </label>
                                            </div>
                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                <p class="input-description">
                                                    {{
                                                        T(
                                                            "The bitwise OR of the following flags: 1:Remove app when MDM profile is removed. 4:Prevent backup of the app data."
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <!-- invisible elements for form submit-->
                                        <input
                                            type="hidden"
                                            id="app-ChangeManagementState-ios"
                                            name="ChangeManagementState"
                                        />

                                        <div
                                            class="row padding-xs-y form-group border-bottom"
                                            v-if="['IOS', 'WEBCLIP'].indexOf(form.platform) != -1"
                                        >
                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                <label class="control-label inputname">{{
                                                    T("Devices")
                                                }}</label>
                                            </div>
                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                <span class="form-field margin-xs-b-0">
                                                    <input-vue-select
                                                        id="app-devices-ios"
                                                        name="devices"
                                                        :multiple="true"
                                                        style="width: 100%"
                                                        class="user-selection"
                                                        :selectOptions="selectOptions.devicesIos"
                                                        v-model="form.devices"
                                                        :placeholder="T('Add devices')"
                                                    ></input-vue-select>
                                                </span>
                                            </div>
                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                <p class="input-description">
                                                    {{
                                                        T(
                                                            "The app will be assigned to these devices"
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            class="row padding-xs-y form-group border-bottom all-only"
                                        >
                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                <label class="control-label inputname">
                                                    {{ T("Users") }}
                                                </label>
                                            </div>
                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                <span class="form-field margin-xs-b-0">
                                                    <input-vue-select
                                                        id="app-users"
                                                        name="users"
                                                        :multiple="true"
                                                        style="width: 100%"
                                                        class="user-selection"
                                                        data-validation-property="/users"
                                                        :selectOptions="selectOptions.users"
                                                        v-model="form.users"
                                                        :placeholder="T('Add users')"
                                                    ></input-vue-select>
                                                </span>
                                            </div>
                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                <p class="input-description">
                                                    {{
                                                        T(
                                                            "The app will be assigned to all devices from these users"
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            class="row padding-xs-y form-group border-bottom all-only"
                                        >
                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                <label class="control-label inputname">
                                                    {{ T("Roles") }}
                                                </label>
                                            </div>
                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                <span class="form-field margin-xs-b-0">
                                                    <input-vue-select
                                                        id="app-roles"
                                                        name="roles"
                                                        :multiple="true"
                                                        style="width: 100%"
                                                        class="user-selection"
                                                        data-validation-property="/roles"
                                                        :selectOptions="selectOptions.roles"
                                                        v-model="form.roles"
                                                        :placeholder="T('Add roles')"
                                                    ></input-vue-select>
                                                </span>
                                            </div>
                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                <p class="input-description">
                                                    {{
                                                        T(
                                                            "The app will be assigned to all devices of all users with these roles"
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            class="row padding-xs-y form-group border-bottom"
                                            v-if="form.platform == 'IOS'"
                                        >
                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                <label class="control-label inputname">
                                                    {{ T("Tags") }}
                                                </label>
                                            </div>
                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                <span class="form-field margin-xs-b-0">
                                                    <input-vue-select
                                                        id="app-tags-ios"
                                                        name="tags"
                                                        :multiple="true"
                                                        style="width: 100%"
                                                        class="user-selection"
                                                        data-validation-property="/tags/0"
                                                        v-model="form.tags"
                                                        :selectOptions="selectOptions.tags"
                                                        :placeholder="T('Add tags')"
                                                        :tags="true"
                                                        :noTranslations="true"
                                                    >
                                                    </input-vue-select>
                                                </span>
                                            </div>
                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                <p class="input-description">
                                                    {{
                                                        T(
                                                            "The app will be assigned to all devices with these tags"
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            class="row padding-xs-y form-group border-bottom all-only"
                                        >
                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                <label class="control-label inputname">{{
                                                    T("Comment")
                                                }}</label>
                                            </div>
                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                <label class="form-field margin-xs-b-0">
                                                    <textarea
                                                        id="app-comment"
                                                        name="comment"
                                                        type="text"
                                                        class="form-control user-selection"
                                                        :placeholder="T('Comment')"
                                                        data-validation-property="/comment"
                                                        v-model="form.comment"
                                                    ></textarea>
                                                </label>
                                            </div>
                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                <p class="input-description">
                                                    {{ T("Describe the app") }}
                                                </p>
                                            </div>
                                        </div>
                                    </form>
                                    <hr />
                                    <div class="box-footer padding-xs-t-2" v-if="initialized">
                                        <button
                                            v-on:click="cancel()"
                                            id="closeButton"
                                            type="button"
                                            class="btn btn-default"
                                        >
                                            {{ T("Close") }}
                                        </button>
                                        <button
                                            class="btn margin-md-0 btn-loader twist-in float-xs-right"
                                            :class="{ 'btn-loading': loading }"
                                            v-on:click="addOrUpdate()"
                                            id="saveButton"
                                            title="Save"
                                            type="submit"
                                        >
                                            <span class="animate">
                                                <loader
                                                    style="
                                                        font-size: 1.5em;
                                                        top: calc(50% - 0.375em - 1px);
                                                        position: absolute;
                                                        left: calc(50% - 0.375em - 0.5px);
                                                    "
                                                ></loader>
                                            </span>
                                            <span>
                                                <i class="fal fa-save"></i>
                                                <span class="display-xs-none display-lg-inline">{{
                                                    T("Save")
                                                }}</span></span
                                            >
                                        </button>
                                    </div>
                                </div>
                                <div
                                    :class="{ hidden: activeTab != 'managedConfiguration' }"
                                    class="padding-xs-2"
                                >
                                    <p>
                                        {{
                                            T(
                                                "By entering data in Plist format, you can create your own application configuration."
                                            )
                                        }}
                                    </p>
                                    <p
                                        v-html="
                                            T(
                                                `You can use <a href='https://wiki.securepoint.de/MS/Variablen'>user variables</a> to define a user-specific configuration.`
                                            )
                                        "
                                    ></p>

                                    <codemirror
                                        v-model="form.managedConfiguration"
                                        :placeholder="managedConfigurationPlaceHolder"
                                        :autofocus="true"
                                        :indent-with-tab="true"
                                        :tab-size="2"
                                        :extensions="codeMirrorExtensions"
                                    />
                                    <hr />
                                    <div class="box-footer padding-xs-t-2" v-if="initialized">
                                        <button
                                            v-on:click="cancel()"
                                            id="closeButton"
                                            type="button"
                                            class="btn btn-default"
                                        >
                                            {{ T("Close") }}
                                        </button>
                                        <button
                                            class="btn margin-md-0 btn-loader twist-in float-xs-right"
                                            :class="{ 'btn-loading': loading }"
                                            v-on:click="addOrUpdate()"
                                            id="saveButton"
                                            title="Save"
                                            type="submit"
                                        >
                                            <span class="animate">
                                                <loader
                                                    style="
                                                        font-size: 1.5em;
                                                        top: calc(50% - 0.375em - 1px);
                                                        position: absolute;
                                                        left: calc(50% - 0.375em - 0.5px);
                                                    "
                                                ></loader>
                                            </span>
                                            <span>
                                                <i class="fal fa-save"></i>
                                                <span class="display-xs-none display-lg-inline">{{
                                                    T("Save")
                                                }}</span></span
                                            >
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div :class="{ hidden: activeTab != 'devices' }" class="padding-xs-2">
                            <div class="box-content-2">
                                <div class="row">
                                    <div class="col-xs-24">
                                        <table-next
                                            :buttons="[refreshButton]"
                                            :has-options="false"
                                            :is-filterable="false"
                                            :object-list="thisApplication?.knownInstallations"
                                            :selectable-columns="[
                                                {
                                                    property: 'deviceId',
                                                    text: T('Device'),
                                                    displayType: 'text',
                                                    getValue: (
                                                        installation: IosAppsKnownInstallation
                                                    ) => {
                                                        const device =
                                                            products.mobileSecurity.iosDevices
                                                                .useStore?.()
                                                                .getObjectStoreObject(
                                                                    activeAccountId,
                                                                    installation.deviceId
                                                                )
                                                        return deviceHelpers.getAliasedShortDeviceId(
                                                            device?.deviceId ||
                                                                installation.deviceId,
                                                            device?.alias || undefined,
                                                            false
                                                        )
                                                    }
                                                },
                                                {
                                                    property: 'appVersion',
                                                    text: 'Version',
                                                    displayType: 'text',
                                                    getValue: (
                                                        installation: IosAppsKnownInstallation
                                                    ) => {
                                                        return installation.version || ''
                                                    }
                                                },
                                                {
                                                    property: 'lastInfoUpdate',
                                                    text: T('Last Info update'),
                                                    displayType: 'text',
                                                    getValue: (
                                                        installation: IosAppsKnownInstallation
                                                    ) => {
                                                        return moment
                                                            .utc(installation.timestamp)
                                                            .format(
                                                                timeHelpers.getDateFormatI18n(true)
                                                            )
                                                    }
                                                }
                                            ]"
                                        ></table-next>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-else-if="initialized">
            <div class="row">
                <div class="col-xs-24 padding-xs">
                    <div class="box box-shadow app-box padding-xs-x-2 app-details-box">
                        <form
                            enctype="multipart/form-data"
                            method="POST"
                            id="appForm"
                            v-if="initialized"
                        >
                            <div class="row padding-xs-y form-group border-bottom all-only">
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">{{ T("Type") }}</label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <span class="form-field margin-xs-b-0">
                                        <template v-if="form.appId != '' && form.platform != ''">
                                            <input
                                                type="text"
                                                readonly
                                                name="platform"
                                                id="app-platform"
                                                style="width: 100%"
                                                data-validation-property="/platform"
                                                v-model="form.platform"
                                            />
                                        </template>

                                        <template v-else>
                                            <input-vue-select
                                                v-if="initialized"
                                                id="app-platform"
                                                name="platform"
                                                class="form-control user-selection"
                                                style="width: 100%"
                                                data-validation-property="/platform"
                                                :selectOptions="selectOptions.platform"
                                                v-model="form.platform"
                                                :placeholder="T('Choose a Type')"
                                            >
                                            </input-vue-select>
                                        </template>
                                    </span>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{ T("App type") }}
                                    </p>
                                </div>
                            </div>

                            <div
                                class="row padding-xs-y form-group border-bottom ios-only"
                                v-if="
                                    form.platform == 'WEBCLIP' &&
                                    dontSendFieldsByProperty.indexOf('URL') == -1
                                "
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">
                                        {{ T("URL") }}
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="form-field margin-xs-b-0">
                                        <input
                                            name="URL"
                                            type="Text"
                                            v-model="form.URL"
                                            id="app-URL"
                                            data-validation-property="/URL"
                                        />
                                    </label>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{ T("The webclip URL") }}
                                    </p>
                                </div>
                            </div>

                            <div class="row padding-xs-y form-group border-bottom all-only">
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">
                                        {{ T("Name") }}
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="form-field margin-xs-b-0">
                                        <input
                                            id="app-name"
                                            name="name"
                                            type="text"
                                            class="form-control user-selection"
                                            :placeholder="T('Name')"
                                            required
                                            data-validation-property="/name"
                                            v-model="form.name"
                                            v-on:change="updateLabel()"
                                        />
                                    </label>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{ T("The name that will be displayed in the portal.") }}
                                    </p>
                                </div>
                            </div>

                            <div
                                class="row padding-xs-y form-group border-bottom ios-only"
                                v-if="form.platform == 'WEBCLIP'"
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">{{ T("Label") }}</label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="form-field margin-xs-b-0">
                                        <input
                                            name="Label"
                                            type="Text"
                                            v-model="form.Label"
                                            data-validation-property="/Label"
                                        />
                                    </label>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{
                                            T(
                                                "The name of the Web Clip as displayed on the Home screen."
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>

                            <div
                                class="row padding-xs-y form-group border-bottom ios-only"
                                v-if="
                                    form.platform == 'WEBCLIP' &&
                                    dontSendFieldsByProperty.indexOf('Icon') == -1
                                "
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">
                                        {{ T("Icon") }}
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="form-field margin-xs-b-0">
                                        <input-image64
                                            :name="'Icon'"
                                            v-model="form.Icon"
                                            :allowedTypes="['image/png']"
                                        ></input-image64>
                                    </label>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{ T("Icon") }}<br />
                                        {{ T("Filetype") }}: PNG, <br />
                                        {{ T("Max. Filesize") }}: 1 MB, <br />
                                        {{ T("Max. Resolution") }}: 400px x 400px
                                    </p>
                                </div>
                            </div>

                            <div
                                class="row padding-xs-y form-group border-bottom ios-only"
                                v-if="form.platform == 'WEBCLIP'"
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label for="IsRemovable" class="control-label float-xs-left"
                                        ><span>{{ T("Removable") }}</span></label
                                    >
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="checkbox toggle form-field">
                                        <input
                                            type="hidden"
                                            name="IsRemovable"
                                            v-model="form.IsRemovable"
                                        />
                                        <input
                                            type="checkbox"
                                            id="IsRemovable"
                                            v-model="form.IsRemovable"
                                        />
                                        <span></span>
                                    </label>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{
                                            T(
                                                "Defines wether the user can remove this webclip or not."
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>

                            <div
                                class="row padding-xs-y form-group border-bottom ios-only"
                                v-if="form.platform == 'WEBCLIP'"
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label for="FullScreen" class="control-label float-xs-left"
                                        ><span>{{ T("Fullscreen") }}</span></label
                                    >
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="checkbox toggle form-field">
                                        <input
                                            type="hidden"
                                            name="FullScreen"
                                            v-model="form.FullScreen"
                                        />
                                        <input
                                            type="checkbox"
                                            id="FullScreen"
                                            v-model="form.FullScreen"
                                        />
                                        <span></span>
                                    </label>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{
                                            T(
                                                "If true, launches the web clip as a full-screen web app."
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>

                            <div
                                class="row padding-xs-y form-group border-bottom ios-only"
                                v-if="form.platform == 'WEBCLIP'"
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label
                                        for="IgnoreManifestScope"
                                        class="control-label float-xs-left"
                                        ><span>{{ T("Ignore Manifest Scope") }}</span></label
                                    >
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="checkbox toggle form-field">
                                        <input
                                            type="hidden"
                                            name="IgnoreManifestScope"
                                            v-model="form.IgnoreManifestScope"
                                        />
                                        <input
                                            type="checkbox"
                                            id="IgnoreManifestScope"
                                            v-model="form.IgnoreManifestScope"
                                        />
                                        <span></span>
                                    </label>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{
                                            T(
                                                "If true, a full screen web clip can navigate to an external web site without showing Safari UI. Otherwise, Safari UI appears when navigating away from the web clip’s URL. This key has no effect when FullScreen is false."
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>

                            <div
                                class="row padding-xs-y form-group border-bottom ios-only"
                                v-if="form.platform == 'WEBCLIP'"
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label for="Precomposed" class="control-label float-xs-left"
                                        ><span>{{ T("Precomposed") }}</span></label
                                    >
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="checkbox toggle form-field">
                                        <input
                                            type="hidden"
                                            name="Precomposed"
                                            v-model="form.Precomposed"
                                        />
                                        <input
                                            type="checkbox"
                                            id="Precomposed"
                                            v-model="form.Precomposed"
                                        />
                                        <span></span>
                                    </label>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{
                                            T(
                                                'If true, prevents SpringBoard from adding "shine" to the icon.'
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>

                            <div
                                class="row padding-xs-y form-group border-bottom ios-only"
                                v-if="form.platform == 'WEBCLIP'"
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">{{
                                        T("Target Application BundleID")
                                    }}</label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <span class="form-field margin-xs-b-0">
                                        <input-vue-select
                                            id="TargetApplicationBundleIdentifier"
                                            name="TargetApplicationBundleIdentifier"
                                            style="width: 100%"
                                            class="user-selection"
                                            data-validation-property="/TargetApplicationBundleIdentifier"
                                            v-if="initialized"
                                            v-model="form.TargetApplicationBundleIdentifier"
                                            v-bind:value="form.TargetApplicationBundleIdentifier"
                                            key="TargetApplicationBundleIdentifier"
                                            :selectOptions="
                                                selectOptions.TargetApplicationBundleIdentifier
                                            "
                                            select2Options="select2AppSearch"
                                        ></input-vue-select>
                                    </span>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{
                                            T(
                                                "The application bundle identifier that specifies the application which opens the URL. To use this property, the profile must be installed through an MDM."
                                            )
                                        }}.
                                    </p>
                                </div>
                            </div>

                            <div
                                class="row padding-xs-y form-group border-bottom ios-only"
                                v-if="form.platform == 'IOS'"
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">{{ T("Source") }}</label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <span class="form-field margin-xs-b-0">
                                        <input-vue-select
                                            v-if="initialized"
                                            id="app-source-ios"
                                            class="form-control"
                                            v-model="form.appSource"
                                            style="width: 100%"
                                            :selectOptions="selectOptions.appSourcesIos"
                                        >
                                        </input-vue-select>
                                    </span>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{ T("App source") }}
                                    </p>
                                </div>
                            </div>

                            <div
                                class="row padding-xs-y form-group border-bottom ios-only"
                                v-if="
                                    (form.appSource == 'iTunesStoreID' ||
                                        form.appSource == 'iTunesStoreIDCustomized') &&
                                    form.platform == 'IOS'
                                "
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">{{
                                        T("iTunes Store ID")
                                    }}</label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <span class="form-field margin-xs-b-0">
                                        <template v-if="form.appSource == 'iTunesStoreID'">
                                            <input-vue-select
                                                id="app-iTunesStoreID-ios"
                                                name="iTunesStoreID"
                                                style="width: 100%"
                                                class="user-selection"
                                                data-validation-property="/iTunesStoreID"
                                                v-if="initialized"
                                                v-model="form.iTunesStoreID"
                                                v-bind:value="form.iTunesStoreID"
                                                key="storeId"
                                                :selectOptions="[]"
                                                select2Options="select2AppLookUpId"
                                            ></input-vue-select>
                                        </template>
                                        <template v-else>
                                            <input
                                                id="app-iTunesStoreID-ios"
                                                name="iTunesStoreID"
                                                style="width: 100%"
                                                class="user-selection"
                                                data-validation-property="/iTunesStoreID"
                                                v-if="initialized"
                                                v-model="form.iTunesStoreID"
                                                key="storeId"
                                                type="Text"
                                                placeholder="iTunes Store ID"
                                            />
                                        </template>
                                    </span>
                                    <template v-if="appInfo.fileSize != undefined">
                                        <p>
                                            {{ T("App filesize") }}:
                                            {{ appInfo.fileSize.value + appInfo.fileSize.unit }}
                                        </p>
                                    </template>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        <template
                                            v-if="form.appSource == 'iTunesStoreIDCustomized'"
                                        >
                                            {{
                                                T(
                                                    "Enter the itunes store ID of the customized app (eg. used in Apple VPP)."
                                                )
                                            }}
                                        </template>
                                        <template v-else>
                                            {{ T("Enter the itunes store ID") }}
                                        </template>
                                    </p>
                                </div>
                            </div>
                            <div
                                class="row padding-xs-y form-group border-bottom ios-only"
                                v-if="form.appSource == 'Identifier' && form.platform == 'IOS'"
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">{{
                                        T("Identifier")
                                    }}</label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <span class="form-field margin-xs-b-0">
                                        <input-vue-select
                                            id="app-Identifier-ios"
                                            name="Identifier"
                                            style="width: 100%"
                                            class="user-selection"
                                            data-validation-property="/Identifier"
                                            v-if="initialized"
                                            v-model="form.Identifier"
                                            v-bind:value="form.Identifier"
                                            key="identifier"
                                            :selectOptions="[]"
                                            select2Options="select2AppSearch"
                                        ></input-vue-select>
                                    </span>
                                    <template v-if="appInfo.fileSize != undefined">
                                        <p>
                                            {{ T("App filesize") }}:
                                            {{ appInfo.fileSize.value + appInfo.fileSize.unit }}
                                        </p>
                                    </template>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{ T("Enter the identifier (Or the name of the app)") }}.
                                    </p>
                                </div>
                            </div>
                            <div
                                class="row padding-xs-y form-group border-bottom ios-only padding-xs-y"
                                v-if="form.appSource == 'ManifestURL' && form.platform == 'IOS'"
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">{{
                                        T("Manifest URL")
                                    }}</label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="form-field margin-xs-b-0">
                                        <input
                                            id="app-ManifestURL-ios"
                                            name="ManifestURL"
                                            type="text"
                                            class="form-control user-selection"
                                            :placeholder="T('Manifest URL')"
                                            data-validation-property="/ManifestURL"
                                            v-model="form.ManifestURL"
                                        />
                                    </label>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{ T("Enter the manifest URL") }}
                                    </p>
                                </div>
                            </div>

                            <!-- invisible elements for form submit-->
                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                <label class="form-field margin-xs-b-0">
                                    <input
                                        type="text"
                                        id="app-uninstallPackageName"
                                        name="uninstallPackageName"
                                        style="display: none"
                                    />
                                </label>
                            </div>

                            <div
                                class="row padding-xs-y form-group border-bottom ios-only padding-xs-y"
                                :class="{ hidden: form.platform != 'IOS' }"
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label
                                        for="app-CheckboxChangeManagementState-ios"
                                        class="control-label float-xs-left"
                                        ><span>{{ T("Take management") }}</span></label
                                    >
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="checkbox toggle form-field">
                                        <input
                                            type="checkbox"
                                            id="app-CheckboxChangeManagementState-ios"
                                            class="form-control user-selection"
                                            style="width: 100%"
                                            v-model="form.CheckboxChangeManagementState"
                                        />
                                        <span></span>
                                    </label>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{
                                            T(
                                                "Set the app as managed or take management if the user has installed it already. The managed state is necessary to uninstall the app from remote."
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>
                            <template v-if="activeTenantVppEnabled">
                                <div
                                    class="row padding-xs-y form-group border-bottom ios-only padding-xs-y"
                                    :class="{ hidden: form.platform != 'IOS' }"
                                >
                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                        <label
                                            for="app-PurchaseMethod-ios"
                                            class="control-label float-xs-left"
                                        >
                                            <span>{{ T("Purchase Method") }}</span>
                                        </label>
                                    </div>
                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                        <label class="select form-field">
                                            <select
                                                id="app-PurchaseMethod-ios"
                                                class="form-control user-selection"
                                                style="width: 100%"
                                                v-model="form.Options.PurchaseMethod"
                                                name="Options[PurchaseMethod]:number"
                                            >
                                                <option value="none">{{ T("Unspecified") }}</option>
                                                <option :value="0">
                                                    {{ T("Legacy Volume Purchase Program") }}
                                                </option>
                                                <option :value="1">
                                                    {{
                                                        T("Volume Purchase Program App Assignment")
                                                    }}
                                                </option>
                                            </select>
                                        </label>
                                    </div>
                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                        <p class="input-description">
                                            {{
                                                T(
                                                    'Use "Volume Purchase Program App Assignment" to install 1st party apps (Mail, Safari, etc.) without users being logged into the iTunes Store.'
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </template>

                            <template
                                v-if="
                                    form.platform == 'IOS' &&
                                    activeTenantVppEnabled &&
                                    form.Options.PurchaseMethod == 1
                                "
                            >
                                <div
                                    class="row padding-xs-y form-group border-bottom ios-only padding-xs-y"
                                >
                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                        <label
                                            for="app-disableAutomaticVPPLicenseAssignment-ios"
                                            class="control-label float-xs-left"
                                        >
                                            <span>
                                                {{ T("Automatic VPP license assignment") }}
                                            </span>
                                        </label>
                                    </div>
                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                        <label class="select form-field">
                                            <select
                                                id="app-disableAutomaticVPPLicenseAssignment-ios"
                                                class="form-control user-selection"
                                                style="width: 100%"
                                                v-model="form.disableAutomaticVPPLicenseAssignment"
                                                name="app-disableAutomaticVPPLicenseAssignment-ios"
                                            >
                                                <option value="0">{{ T("Enabled") }}</option>
                                                <option value="1">{{ T("Disabled") }}</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                        <p class="input-description">
                                            {{
                                                T(
                                                    "Choose wether the VPP app license should be automatically assigned to the device or not."
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </template>

                            <div
                                class="row padding-xs-y form-group border-bottom ios-only padding-xs-y"
                                :class="{ hidden: form.platform != 'IOS' }"
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label
                                        for="app-PurchaseMethod-ios"
                                        class="control-label float-xs-left"
                                    >
                                        <span>{{ T("Management flags") }}</span>
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="select form-field">
                                        <select
                                            id="app-ManagementFlags-ios"
                                            class="form-control user-selection"
                                            style="width: 100%"
                                            v-model="form.ManagementFlags"
                                            name="ManagementFlags:number"
                                        >
                                            <option :value="0">{{ T("Both disabled") }}</option>
                                            <option :value="1">
                                                {{ T("Remove app when MDM profile is removed") }}
                                            </option>
                                            <option :value="4">
                                                {{ T("Prevent backup of the app data") }}
                                            </option>
                                            <option :value="5">{{ T("Both enabled") }}</option>
                                        </select>
                                    </label>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{
                                            T(
                                                "The bitwise OR of the following flags: 1:Remove app when MDM profile is removed. 4:Prevent backup of the app data."
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>
                            <!-- invisible elements for form submit-->
                            <input
                                type="text"
                                id="app-ChangeManagementState-ios"
                                name="ChangeManagementState"
                                style="display: none"
                            />

                            <div
                                class="row padding-xs-y form-group border-bottom"
                                :class="{ hidden: ['IOS', 'WEBCLIP'].indexOf(form.platform) == -1 }"
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">{{
                                        T("Devices")
                                    }}</label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <span class="form-field margin-xs-b-0">
                                        <input-vue-select
                                            v-if="initialized"
                                            id="app-devices-ios"
                                            name="devices"
                                            :multiple="true"
                                            style="width: 100%"
                                            class="user-selection"
                                            data-validation-property="/devices"
                                            :selectOptions="selectOptions.devicesIos"
                                            v-model="form.devices"
                                            :placeholder="T('Add devices')"
                                        ></input-vue-select>
                                    </span>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{ T("The app will be assigned to these devices") }}
                                    </p>
                                </div>
                            </div>

                            <div class="row padding-xs-y form-group border-bottom all-only">
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">{{ T("Users") }}</label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <span class="form-field margin-xs-b-0">
                                        <input-vue-select
                                            id="app-users"
                                            name="users"
                                            :multiple="true"
                                            style="width: 100%"
                                            class="user-selection"
                                            data-validation-property="/users"
                                            v-if="initialized"
                                            :selectOptions="selectOptions.users"
                                            v-model="form.users"
                                            :placeholder="T('Add users')"
                                        ></input-vue-select>
                                    </span>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{
                                            T(
                                                "The app will be assigned to all devices from these users"
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>
                            <div class="row padding-xs-y form-group border-bottom all-only">
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">{{ T("Roles") }}</label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <span class="form-field margin-xs-b-0">
                                        <input-vue-select
                                            id="app-roles"
                                            name="roles"
                                            :multiple="true"
                                            style="width: 100%"
                                            class="user-selection"
                                            data-validation-property="/roles"
                                            v-if="initialized"
                                            :selectOptions="selectOptions.roles"
                                            v-model="form.roles"
                                            :placeholder="T('Add roles')"
                                        ></input-vue-select>
                                    </span>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{
                                            T(
                                                "The app will be assigned to all devices of all users with these roles"
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>
                            <div
                                class="row padding-xs-y form-group border-bottom"
                                :class="{ hidden: form.platform != 'IOS' }"
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">{{ T("Tags") }}</label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <span class="form-field margin-xs-b-0">
                                        <input-vue-select
                                            id="app-tags-ios"
                                            name="tags"
                                            :multiple="true"
                                            :tags="true"
                                            style="width: 100%"
                                            class="user-selection"
                                            data-validation-property="/tags/0"
                                            v-if="initialized"
                                            v-model="form.tags"
                                            :selectOptions="selectOptions.tags"
                                            :placeholder="T('Add tags')"
                                            :noTranslations="true"
                                        >
                                        </input-vue-select>
                                    </span>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{
                                            T(
                                                "The app will be assigned to all devices with these tags"
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>
                            <div
                                class="row padding-xs-y form-group border-bottom"
                                :class="{ hidden: form.platform != 'WEBCLIP' }"
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">{{ T("Tags") }}</label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <span class="form-field margin-xs-b-0">
                                        <input-vue-select
                                            v-if="initialized"
                                            :multiple="true"
                                            :tags="true"
                                            :selectOptions="selectOptions.tags"
                                            v-model="form.tags"
                                            id="app-tags-webclip"
                                            name="tags"
                                            style="width: 100%"
                                            class="user-selection"
                                            data-validation-property="/tags/0"
                                            :placeholder="T('Add tags')"
                                            :noTranslations="true"
                                        ></input-vue-select>
                                    </span>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{
                                            T(
                                                "The app will be assigned to all devices with these tags"
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>

                            <div class="row padding-xs-y form-group border-bottom all-only">
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">{{
                                        T("Comment")
                                    }}</label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="form-field margin-xs-b-0">
                                        <textarea
                                            id="app-comment"
                                            name="comment"
                                            type="text"
                                            class="form-control user-selection"
                                            :placeholder="T('Comment')"
                                            data-validation-property="/comment"
                                            v-model="form.comment"
                                        ></textarea>
                                    </label>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{ T("Describe the app") }}
                                    </p>
                                </div>
                            </div>
                        </form>
                        <hr />
                        <div class="box-footer content-2" v-if="initialized">
                            <button
                                v-on:click="cancel()"
                                id="closeButton"
                                type="button"
                                class="btn btn-default"
                            >
                                {{ T("Close") }}
                            </button>
                            <a
                                class="btn margin-md-0 btn-loader twist-in float-xs-right"
                                :class="{ 'btn-loading': loading }"
                                v-on:click="addOrUpdate()"
                                id="saveButton"
                                title="Save"
                            >
                                <span class="animate">
                                    <loader
                                        style="
                                            font-size: 1.5em;
                                            top: calc(50% - 0.375em - 1px);
                                            position: absolute;
                                            left: calc(50% - 0.375em - 0.5px);
                                        "
                                    ></loader>
                                </span>
                                <span
                                    ><i class="fal fa-save"></i>
                                    <span class="display-xs-none display-lg-inline">{{
                                        T("Save")
                                    }}</span></span
                                >
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </section>
</template>

<template>
    <div class="row flexrow">
        <div class="col-xs-24 col-lg-16">
            <div class="box-shadow padding-xs-2" style="height: 100%">
                <h4 class="margin-xs-b-2">
                    <i class="fal fa-fw fa-globe"></i>
                    {{ T("Secure DNS") }}
                </h4>
                <p>
                    {{
                        T(
                            "Secure DNS schützt vor Phishing, Malware und unerwünschten Webseiten, indem schädliche Domains automatisch blockiert werden. Durch die zentrale Verteilung per MDM wird sichergestellt, dass alle Firmen-Smartphones einheitlich geschützt sind und Unternehmensrichtlinien zur sicheren Internetnutzung eingehalten werden. Gleichzeitig werden Tracking und Datendiebstahl reduziert, während die Verbindungsgeschwindigkeit optimiert bleibt."
                        )
                    }}
                </p>

                <p>
                    {{
                        T(
                            "Securepoint Secure DNS leitet alle DNS-Anfragen der Geräte über eigene, sichere Server, die verdächtige oder schädliche Domains anhand von regelmäßig aktualisierten Filterlisten blockieren. Die Anfragen werden dabei verschlüsselt (DNS-over-TLS/HTTPS), um Manipulationen und Abhörversuche zu verhindern. Nicht erlaubte Inhalte werden gefiltert, indem der Zugriff auf entsprechende IP-Adressen verweigert und eine Blockseite angezeigt wird."
                        )
                    }}
                </p>

                <p class="text-bold margin-xs-t-3">
                    {{ T("Ablauf der Konfiguration mit MDM") }}
                </p>

                <p>
                    {{
                        T(
                            "Um Android- und iOS-Smartphones mit Securepoint Secure DNS abzusichern, führen Sie bitte die folgenden Schritte durch:"
                        )
                    }}
                </p>

                <ol>
                    <li>
                        <b>{{ T("Erstellen eines Secure DNS Profils") }}</b
                        >:
                        {{
                            T(
                                "Legen Sie alle relevanten Einstellungen wie Filterregeln und Sicherheitsrichtlinien zentral fest."
                            )
                        }}
                    </li>
                    <li>
                        <b>{{ T("Erstellen eines Konfigurationsprofils für Geräte") }}</b
                        >:
                        {{
                            T(
                                "Erstellen Sie iOS- und Android-Geräte Konfigurationsprofile. Dabei wird das zuvor angelegte Secure DNS Profil ausgewählt und mit dem jeweiligen Konfigurationsprofil verknüpft."
                            )
                        }}
                    </li>
                    <li>
                        <b>{{ T("Verteilung auf die Smartphones") }}</b
                        >:
                        {{
                            T(
                                "Die Securepoint Secure DNS App wird automatisch auf die Smartphones verteilt und entsprechend den definierten Einstellungen konfiguriert."
                            )
                        }}
                    </li>
                </ol>

                <p>
                    {{
                        T(
                            "Nach der Einrichtung leiten die Smartphones ihre DNS-Anfragen an die Securepoint DNS-Server. Dadurch werden sie zuverlässig vor schädlichen Webseiten und unerwünschten Inhalten geschützt."
                        )
                    }}
                </p>

                <p>
                    <b>{{ T("Hinweis") }}: </b
                    >{{
                        T(
                            "Für iOS-Geräte können ggf. VPP-Lizenzen erforderlich sein, um die App automatisiert bereitzustellen."
                        )
                    }}
                </p>

                <p>
                    {{ T("Weitere Informationen finden Sie in unserem Wiki unter") }}
                    <a href="https://wiki.securepoint.de/" target="_blank">{{ T("Secure DNS") }}</a
                    >.
                </p>

                <p class="margin-xs-t-4 text-center" style="font-size: 1.15em">
                    <btn :button-options="addProfileButton" />
                </p>
            </div>
        </div>
        <div class="col-xs-24 col-lg-8">
            <div class="box-shadow padding-xs-2 text-center" style="height: 100%">
                <img
                    :src="darkmode ? diagramImageDark : diagramImage"
                    style="
                        display: inline;
                        max-width: 400px;
                        width: 100%;
                        filter: grayscale(1);
                        opacity: 0.7;
                    "
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { T } from "@/classes/i18n"
import getterHelpers from "@/helpers/helpers.getters"
import diagramImageDark from "@/img/secureDnsLanding_dark.svg"
import diagramImage from "@/img/secureDnsLanding_light.svg"
import router from "@/router/router"
import { useStore } from "@/store/vuex.store"
import { computed, ref } from "vue"
import Button from "../button/button"
import btn from "../button/button.vue"

const darkmode = computed(() => {
    return getterHelpers.useStore().state.browserSettings.darkmode == "1"
})

const accountId = computed(() => {
    return useStore().state.session.activeAccountId || ""
})

const addProfileButton = ref(
    new Button({
        icon: "fa-plus",
        text: T("Secure DNS Profil jetzt hinzufügen"),
        title: T("Secure DNS Profil jetzt hinzufügen"),
        onClick: () => {
            router.navigate(`#add-tenant-${accountId.value}.sms-secureDnsProfiles`)
        }
    })
)
</script>

<template>
    <div v-html="markdown.render(src)" />
</template>

<script setup lang="ts">
import MarkdownIt from "markdown-it"

const markdown = new MarkdownIt({
    breaks: true,
    html: false,
    linkify: true
})

var defaultRender =
    markdown.renderer.rules.link_open ||
    function (tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options)
    }

markdown.renderer.rules.link_open = function (tokens, idx, options, env, self) {
    tokens[idx].attrSet("target", "_blank")
    return defaultRender(tokens, idx, options, env, self)
}

defineProps({
    src: {
        type: String,
        default: ""
    }
})
</script>

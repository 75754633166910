<script setup lang="ts">
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import type { Connection } from "@/classes/objectTypes/mobileSecurity/windows/windowsVpns"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import { MutationTypes, useStore } from "@/store/vuex.store"
import { computed, onMounted, onUnmounted, ref } from "vue"
import loader from "../components/loader.vue"
import Tooltip from "../components/tooltip.vue"

const props = defineProps<{
    properties: {
        connection: Connection
        vpnId: string
    }
}>()

type Diagnose = {
    connectionId: string
    hostResolveSource: string
    hostResolved: string
    hostPort: number
    isReachable: boolean
    clientPublicIP: string
}

const keyToTitleMap: Record<string, string> = {
    connectionId: "",
    hostResolveSource: "Remote-Host",
    hostPort: "Remote-Port",
    hostResolved: "IP",
    isReachable: T("Erreichbar"),
    clientPublicIP: T("Öffentliche IP")
}
const keyToDecriptionMap: Record<string, string> = {
    connectionId: "",
    hostResolveSource: T("Remote-Host aus der Konfiguration"),
    hostPort: T("Remote-Port aus der Konfiguration"),
    hostResolved: T("Aufgelöste IP des Remote-Hosts"),
    isReachable: T("Remote-Host und Port sind erreichbar"),
    clientPublicIP: T("Öffentliche IP des Clients")
}

const loading = ref(false)
const takingLong = ref(false)
const diagnose = ref(<Diagnose | undefined>undefined)

const activeAccountId = computed(() => {
    return getterHelpers.useStore().getters.getActiveAccountId
})

onMounted(async () => {
    loading.value = true
    try {
        const response = await requestHandler.request(
            "POST",
            config.mgtApiUriNext +
                "/tenants/" +
                tenantHelpers.getTenantDomain(activeAccountId.value) +
                "/windows/devices/" +
                props.properties.vpnId +
                "/jobs/vpn",
            {
                name: "connection-diagnose",
                data: {
                    connectionId: props.properties.connection.id
                }
            }
        )
        setTimeout(() => {
            takingLong.value = true
        }, 90000)

        useStore().commit(MutationTypes.addSubscriptionHook, {
            accountId: activeAccountId.value,
            hookKey: "vpnConnectionDiagnosis",
            hookFunction: (message: any) => {
                const messageTopic = message?.topic || message?.data?.topic || ""
                const clientContext = message?.data?.clientContext || ""
                if (
                    typeof messageTopic == "string" &&
                    messageTopic.includes("/windows/vpn/") &&
                    clientContext == "vpn-connection-diagnose"
                ) {
                    if (message?.data?.data?.diagnose)
                        diagnose.value = message.data.data.diagnose as Diagnose

                    loading.value = false
                }
            }
        })
    } catch (e: unknown) {
        console.error(e)
    }
})

onUnmounted(() => {
    useStore().commit(MutationTypes.deleteSubscriptionHook, {
        accountId: activeAccountId.value,
        hookKey: "vpnConnectionDiagnosis"
    })
})
</script>
<template>
    <div class="box-content-2">
        <template v-if="loading == true">
            <div class="text-size-3 text-center padding-xs-t-4">
                <div class="text-size-2">
                    <loader class="color-red"></loader>
                </div>
            </div>
            <div class="text-center padding-xs-t-2" style="opacity: 0.8">
                <span>
                    {{ T("Hole Verbindungsinformationen...") }}
                </span>
            </div>
            <template v-if="takingLong == true">
                <div class="text-center color-red padding-xs-t-2" style="opacity: 0.8">
                    <span>
                        {{
                            T(
                                "Das Abrufen der Verbindungsinformationen dauert ungewöhnlich lange. Bitte überprüfen Sie den Verbindungsstatus Ihres VPNs."
                            )
                        }}
                    </span>
                </div>
            </template>
        </template>
        <template v-else-if="diagnose">
            <template v-for="(key, index) of Object.keys(diagnose)">
                <div
                    style="align-content: center"
                    :class="{
                        row: true,
                        flexrow: true,
                        'margin-xs-t-2': index > 1
                    }"
                    v-if="keyToTitleMap[key] && keyToDecriptionMap[key]"
                >
                    <div class="first col-xs-24 col-lg-4">
                        <label class="control-label inputname" :for="'form-' + key">
                            {{ keyToTitleMap[key] + ":" }}
                        </label>
                    </div>

                    <div class="input col-xs-24 col-lg-10">
                        <div v-if="key == 'isReachable'" style="padding: 7px">
                            <Tooltip
                                :tooltip="
                                    diagnose.isReachable == true ? T('Reachable') : T('Unreachable')
                                "
                                :is-tag="'span'"
                            >
                                <i
                                    class="fa fa-fw"
                                    style="font-size: 1.25em"
                                    :data-color="diagnose.isReachable == true ? 'green' : 'red'"
                                    :class="{
                                        'fa-circle-exclamation color-red':
                                            diagnose.isReachable == false,
                                        'fa-circle-check color-green': diagnose.isReachable == true
                                    }"
                                >
                                </i>
                            </Tooltip>
                        </div>
                        <div class="diagnosisValueContainer" v-else>
                            {{ diagnose[key as keyof typeof diagnose] }}
                        </div>
                    </div>

                    <div class="desc col-xs-24 col-lg-10">
                        <p class="input-description">
                            {{ keyToDecriptionMap[key] }}
                        </p>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>
<style>
.diagnosisValueContainer {
    opacity: 0.85;
    padding: 7px;
    border: 1px solid rgb(143, 143, 143);
    word-wrap: break-word;
}
</style>

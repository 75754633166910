<template>
    <div class="topdata elementContainer">
        <div class="elementContainerTitleContainer">
            <div class="elementContainerTitle">
                <p class="elementContainerTitleText">
                    <i class="fal fa-list"></i>TIF-{{ T("Ranking") }}
                </p>
                <div class="ElementContainerInfoContainer" @click="callPopUp()">
                    <i
                        class="fal fa-info-circle gtvInfoCircle"
                        :title="T('Further Information')"
                    ></i>
                </div>
            </div>
            <hr />
            <div class="rankingContainerContento">
                <table class="topDataTableo">
                    <tbody>
                        <tr>
                            <th
                                v-if="top10"
                                class="colSpan-1"
                                colspan="2"
                                @click="sortNum('Country', top10, top10Sort)"
                            >
                                {{ T("Geo-IP Area") }}
                                <i
                                    class="fal fa-sort sortTopCountryButton"
                                    v-if="currentSortMethod == top10Sort"
                                ></i>
                                <i class="fal fa-sort sortButtonUnselected" v-else></i>
                            </th>
                            <th
                                v-if="top10"
                                class="colHead-3"
                                @click="sortNum('IP', top10, IPSort)"
                            >
                                {{ T("Blocked IPs") }}
                                <i
                                    class="fal fa-sort sortIPButton"
                                    v-if="currentSortMethod == IPSort"
                                ></i>
                                <i class="fal fa-sort sortButtonUnselected" v-else></i>
                            </th>
                        </tr>
                    </tbody>
                </table>

                <template v-if="!isLoading">
                    <div class="tablewrappero">
                        <table class="topDataTableo">
                            <tbody>
                                <tr
                                    v-if="top10"
                                    v-for="countries in top10.length"
                                    :id="top10[countries - 1].country"
                                >
                                    <td class="col-1">
                                        <span
                                            style="font-size: 25px"
                                            :class="`fi fi-${top10[countries - 1].country} flag`"
                                        ></span>
                                    </td>
                                    <td class="col-2">
                                        {{ T(top10[countries - 1].countryName) }}
                                    </td>
                                    <td class="col-3">
                                        {{
                                            new Intl.NumberFormat("de-DE").format(
                                                top10[countries - 1].blockedIps
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr v-if="top10 == undefined" id="noDataRow">
                                    <td colspan="3">{{ T("No Data available") }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-else>
                    <div class="noContentContainer">
                        <div
                            style="
                                padding-top: 200px;
                                font-size: 2em;
                                opacity: 0.7;
                                align-self: center;
                                display: flex;
                                justify-content: center;
                            "
                        >
                            <loader />
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { T } from "@/classes/i18n"
import dialogs from "@/dialogs/dialogs"
import getterHelpers from "@/helpers/helpers.getters"
import requestHandler from "@/queries/requests"
import { computed, onMounted, ref } from "vue"
import countrycodes from "../assets/dictonaries/countryCodes.json"
import * as config from "../config/frontendConfig.json"
import loader from "./loader.vue"

const sortedIP = ref(true)
const currentSortMethod: Ref<string> = ref("")
const top10Sort = ".sortTopCountryButton"
const IPSort = ".sortIPButton"
const top10 = ref(<any[] | undefined>undefined)
const isLoading = ref()
const counter = ref(0)
const refreshIntervalId = setInterval(checkSocket, 1000)
const activeAccountId = computed(() => {
    return getterHelpers.useStore()?.getters.getActiveAccountId
})

onMounted(async () => {
    const data: any[] = await requestHandler.request("GET", config.top10)
    top10.value = data
    top10.value.forEach((element, index) => {
        top10.value![index].countryName = T(
            countrycodes[top10.value![index].country as keyof typeof countrycodes]
        )
    })
    checkSocket()
})

function checkSocket() {
    if (top10.value == undefined) {
        isLoading.value = true
        counter.value++
        if (counter.value == 2) {
            isLoading.value = false
            clearInterval(refreshIntervalId)
        }
    } else {
        isLoading.value = false
        clearInterval(refreshIntervalId)
    }
}
function sortNum(columnPortName: string, numArray: any[], sortName: string) {
    if (columnPortName === "Country") {
        if (sortedIP.value) {
            sortedIP.value = false
            sortIcon(sortedIP.value, sortName)
            numArray.forEach((element, index) => {
                numArray[index].countryName = T(
                    countrycodes[numArray[index].country as keyof typeof countrycodes]
                )
            })
            return numArray.sort((a, b) =>
                String(a.countryName).localeCompare(String(b.countryName))
            )
        } else {
            sortedIP.value = true
            sortIcon(sortedIP.value, sortName)
            numArray.forEach((element, index) => {
                numArray[index].countryName = T(
                    countrycodes[numArray[index].country as keyof typeof countrycodes]
                )
            })
            return numArray?.sort((a, b) =>
                String(T(b.countryName)).localeCompare(String(a.countryName))
            )
        }
    } else {
        if (sortedIP.value) {
            sortedIP.value = false
            sortIcon(sortedIP.value, sortName)
            return numArray.sort((a, b) => a.blockedIps - b.blockedIps)
        } else {
            sortedIP.value = true
            sortIcon(sortedIP.value, sortName)
            return numArray?.sort((a, b) => b.blockedIps - a.blockedIps)
        }
    }
}

function callPopUp() {
    dialogs.misc.confirmDialog(
        activeAccountId.value,
        "TIF-" + T("Ranking"),
        T(
            "The TIF ranking list provides a dynamic overview of the current data from the Threat Intelligence Feed (TIF). It provides information on blocked IP addresses worldwide. This list makes it possible to analyse and monitor potential threats by geographical region."
        ),
        () => {
            return true
        },
        "",
        T("Close"),
        "",
        false,
        "fal fa-info-circle"
    )
}
function sortIcon(isSorted: boolean, columnName: string) {
    currentSortMethod.value = columnName

    let sortIconDiv: any = document.querySelector(columnName)

    const closest = sortIconDiv.closest("i")

    closest.classList.add("sortIcon")

    if (closest.classList.contains("fa-sort")) closest.classList.remove("fa-sort")

    if (isSorted) {
        if (closest.classList.contains("fa-sort-up")) closest.classList.toggle("fa-sort-up")

        closest.classList.toggle("fa-sort-down")
    } else {
        if (closest.classList.contains("fa-sort-down")) closest.classList.toggle("fa-sort-down")

        closest.classList.toggle("fa-sort-up")
    }
}
</script>

<style scoped>
.noContentContainero {
    display: flex;
    justify-content: center;
    height: 100%;
}

.rankingContainerContento {
    padding: 16px;
    display: flex;
    flex-direction: column;
    height: 600px;
}

.tablewrappero {
    max-height: 430px;
    overflow: hidden scroll;
}

.topDataTableo {
    width: 100%;
    border-collapse: collapse;
    background-color: var(--table-Background);
    color: var(--font-Color);
    margin: 0;

    td,
    th,
    tr {
        padding: 8px;
    }

    th:hover {
        cursor: pointer;
        background-color: var(--table-Background-nth);

        .sortTopCountryButton {
            opacity: 100%;
        }
        .sortIPButton {
            opacity: 100%;
        }
    }
    th {
        background-color: var(--table-Background);
    }
    tr:nth-child(odd) {
        background-color: var(--table-Background-nth);
    }

    tr {
        text-align: left;
    }
}

#noDataRow {
    background-color: var(--table-Background-nth);
}

.col-1 {
    width: 10%;
}

.col-2 {
    width: 43%;
}

.col-3 {
    width: 40.5%;
}

.colSpan-1 {
    width: 50%;
}

.colHead-3 {
    width: 39.3%;
}

.sortButtonUnselected {
    opacity: 20%;
    float: right;
}
.sortTopCountryButton {
    opacity: 20%;
    float: right;
}
.sortIPButton {
    opacity: 20%;
    float: right;
}

.sortIcon {
    opacity: 100%;
}

tr {
    .col-1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    span {
        font-size: 30px;

        margin: -5px 0 -5px 0;
    }
}
</style>

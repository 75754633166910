<script setup lang="ts">
import NewIcon from "./newIcon"
defineOptions({
    inheritAttrs: true
})
const props = defineProps<{ iconOptions?: NewIcon }>()
const fontawesomeTypeToPrefix = {
    fontawesomeRegular: "fa",
    fontawesomeBrands: "fab",
    fontawesomeLight: "fal"
}
</script>
<template>
    <template v-if="props.iconOptions && props?.iconOptions?.iconType.indexOf('fontawesome') != -1">
        <i
            v-tooltip="props.iconOptions.tooltip"
            :title="props.iconOptions.title"
            :class="[
                fontawesomeTypeToPrefix[props.iconOptions.iconType],
                props.iconOptions?.class,
                'fa-' + props.iconOptions.iconName
            ]"
            v-on:click="props.iconOptions.onClick != undefined ? props.iconOptions.onClick() : null"
            :style="{ cursor: props.iconOptions.onClick != undefined ? 'pointer' : undefined }"
        ></i>
    </template>
</template>
<style>
.icon {
    display: inline-block;
}
</style>

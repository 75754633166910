import jsonHelpers from "@/helpers/helpers.json"
import Tooltip from "@/templates/directives/tooltip/tooltip"
import { type IconName } from "@fortawesome/fontawesome-svg-core"
type AllOptions = Partial<NewIcon>
export type IconOptions = AllOptions
export const isIcon = (obj: any) => {
    return obj instanceof NewIcon
}
export default class NewIcon {
    iconType: "fontawesomeRegular" | "fontawesomeBrands" | "fontawesomeLight" = "fontawesomeLight"
    iconName: IconName = "question"
    title?: string
    class?: string
    onClick?: () => any
    tooltip?: Tooltip
    constructor(options?: IconOptions) {
        if (options) {
            jsonHelpers.merge(this, options)
        }
    }
}

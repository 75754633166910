<script setup lang="ts">
import { T } from "@/classes/i18n"
import products from "@/classes/objectTypes"
import getterHelpers from "@/helpers/helpers.getters"
import mixinHelpers from "@/helpers/helpers.mixins"
import queries from "@/queries/queries"
import { useStore } from "@/store/vuex.store"
import { computed, onMounted, ref } from "vue"
import ModalObject from "../components/modals/modalClass"
import InputVueSelect from "../inputtypes/input-vue-select.vue"

const selectedLicenseId = ref("")
const error = ref("")

const activeAccountId = computed(() => {
    return getterHelpers.useStore()?.getters.getActiveAccountId || ""
})
const darkmode = computed(() => {
    return mixinHelpers.getDarkmode()
})

const selectedLicense = computed(() => {
    return products.unifiedSecurity.licenses
        .useStore?.()
        .getObjectStoreObject(activeAccountId.value, selectedLicenseId.value)
})

const allLicenses = computed(() => {
    return (
        products.unifiedSecurity.licenses
            .useStore?.()
            .getObjectStoreObjects(activeAccountId.value) || []
    )
})

const mdmLicenses = computed(() => {
    return allLicenses.value.filter((license) => ["Mobile Security", "MDM"].includes(license.type))
})

const mdmSelectOptions = computed(() => {
    let selectOptions: selectOption[] = []

    mdmLicenses.value.forEach((license) => {
        let licenceUser = mixinHelpers.getLicenseLimit(license)
        if (
            (license.type == "Mobile Security" || license.type == "MDM") &&
            license.uuid &&
            license.assignedUser < licenceUser &&
            !license.expired &&
            !license.dead
        ) {
            selectOptions.push({
                id: license.uuid,
                text:
                    license.name +
                    " | " +
                    license.type +
                    " [" +
                    license.assignedUser +
                    "/" +
                    licenceUser +
                    "] (" +
                    license.uuid.substring(0, 4) +
                    ")",
                disabled: license.assignedUser >= licenceUser || license.expired || license.dead
            })
        }
    })

    return selectOptions
})

onMounted(() => {
    const defaultLicenseId =
        useStore().state.session.accounts[activeAccountId.value || ""].mobileSecurity?.settings
            ?.defaultDeviceLicenseUUID || ""
    if (typeof defaultLicenseId == "string" && defaultLicenseId != "") {
        selectedLicenseId.value = defaultLicenseId
    }
})

async function submit(modal: ModalObject) {
    if (selectedLicenseId.value && modal) {
        try {
            modal.getButton(1)?.disable?.()
            modal?.getButton(1)?.startLoader?.()
            error.value = ""

            const result = await queries.unifiedSecurity.setTenantProperty(
                activeAccountId.value,
                "defaultDeviceLicenseUUID",
                selectedLicenseId.value
            )

            if (result == false) {
                error.value = T("Beim Speichern der Default Lizenz ist ein Fehler aufgetreten.")
            } else {
                useStore().state.session.accounts[
                    activeAccountId.value || ""
                ].mobileSecurity.settings.defaultDeviceLicenseUUID = selectedLicenseId.value
                modal.delete?.()
            }
        } catch (e: unknown) {
            console.error(e)
        } finally {
            modal.getButton(1)?.enable?.()
            modal?.getButton(1)?.stopLoader?.()
        }
    }
}

defineExpose({
    submit
})
</script>

<template>
    <div class="box-content-3">
        <div>
            <div v-if="selectedLicenseId == ''">
                <p class="notification" :class="{ 'color-white': darkmode == '1' }">
                    <i class="fal fa-exclamation-triangle fa-fw color-red"></i>&nbsp;&nbsp;
                    {{ T("Achtung: Es ist keine gültige iOS-Default Lizenz ausgewählt!") }}
                </p>
                <p></p>
            </div>
            <div v-else-if="selectedLicense?.expired == true || selectedLicense?.dead == true">
                <p class="notification" :class="{ 'color-white': darkmode == '1' }">
                    <i class="fal fa-exclamation-triangle fa-fw color-red"></i>&nbsp;&nbsp;
                    {{ T("Achtung: Die ausgewählte Lizenz ist abgelaufen!") }}
                </p>
                <p></p>
            </div>
        </div>
        <div class="row padding-xs">
            <div class="col-xs-24">
                {{
                    T(
                        "Hier können Sie die Default-Lizenz für das Enrollment von iOS Geräten definieren."
                    )
                }}
            </div>
        </div>
        <div class="row flexrow padding-xs" style="align-content: center; align-items: center">
            <div class="col-xs-24 col-lg-6">
                {{ "MDM / Mobile Security:" }}
            </div>
            <div class="col-xs-24 col-lg-18">
                <InputVueSelect
                    v-model="selectedLicenseId"
                    :select-options="mdmSelectOptions"
                    :placeholder="T('Wählen Sie eine Default Lizenz aus')"
                    :clearable="false"
                ></InputVueSelect>
            </div>
        </div>
        <span
            v-if="error != ''"
            class="error-bubble label bg-red margin-xs-t"
            v-html="error"
        ></span>
    </div>
</template>
